<template>
  <div>
    <div class="title-content">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>小工具</el-breadcrumb-item>
          <el-breadcrumb-item>尽调报告</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <main-header>
        <template slot="title">尽调报告</template>
      </main-header>
    </div>
    <div class="filter-body">
      <div class="inputs-wrapper">
        <div class="inputs-wraper-row">
          <div class="form-item">
            <div class="label">检测时间：</div>
            <el-date-picker
              v-model="filter.time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="searchGetAudiList"
            ></el-date-picker>
          </div>
          <div class="form-item">
            <div class="label">状态:</div>
            <el-select
              v-model="filter.showStatus"
              clearable
              placeholder="请选择"
              filterable
              @change="searchGetAudiList"
            >
              <el-option
                v-for="item in statusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <!-- <div class="btn-look-up ease">搜索</div> -->
    </div>
    <div class="addItem ease" @click="addTest" v-if="!showPwd">发起检测</div>

    <div class="content-body">
      <template>
        <el-table
          :data="tableDataList"
          :default-sort="{ prop: 'date', order: 'descending' }"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            label="序号"
            width="60"
            sortable
          ></el-table-column>
          <el-table-column
            prop="companyName"
            label="企业名称"
            min-width="19%"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="createTime"
            label="报告生成时间"
            sortable
            min-width="14%"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="zqSection"
            label="报告征期"
            sortable
            min-width="14%"
            :show-overflow-tooltip="true"
          ></el-table-column>

          <!-- <el-table-column
            prop="taxNo"
            label="纳税人识别号"
            min-width="18%"
            :show-overflow-tooltip="true"
          ></el-table-column> -->
          <el-table-column
            prop="showStatus"
            label="状态"
            :show-overflow-tooltip="true"
            min-width="10%"
          >
            <template slot-scope="scope">
              <span>{{ statusListArr[scope.row.showStatus] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="110px">
            <template slot-scope="scope">
              <router-link
                :to="{ name: 'JinzhiReport', params: { uuid: scope.row.programId } }"
                class="export"
                target="_blank"
                v-if="scope.row.showStatus == 2"
                >查看</router-link
              >
              <span
                v-if="scope.row.showStatus != 4"
                class="showDelete"
                @click="deleteAudit(scope.row.id)"
                >删除</span
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>

    <el-pagination
      class="pagination-low"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :current-page="pageno"
      layout="prev, pager, next, total, jumper, sizes"
      :total="totalNumber"
      :page-sizes="[10, 20, 50, 100]"
      :haveTotal="haveTotal"
    ></el-pagination>
    <tax-no
      :areaList="areaOptions"
      :hyList="hyList"
      @closeBox="closeBox"
      v-if="taxBox"
      @addBox="addBox"
    ></tax-no>
    <tax-pwd v-if="showPwd" @checkPwdSuccess="checkPwdSuccess"></tax-pwd>
  </div>
</template>

<script>
import {
  auditList,
  researchLoginRecord,
  researchLoginRecordDel,
  areaList,
  getTradeList,
} from "@/api/api";
import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "@/pages/components/MainHeader";
import taxNo from "./components/addTaxno";
import taxPwd from "./auditPwd";

import { cloneDeep } from "lodash";

export default {
  components: {
    MainHeader,
    BreadCrumb,
    taxNo,
    taxPwd,
  },

  data() {
    return {
      statusListArr: ["未生成", "未生成", "已生成", "生成出错", "已删除"],
      statusList: [
        {
          value: "1",
          label: "未生成",
        },
        {
          value: "2",
          label: "已生成",
        },
        {
          value: "3",
          label: "生成出错",
        },
        {
          value: "4",
          label: "已删除",
        },
      ],
      haveTotal: true,
      totalNumber: 10, //总数
      totalElements: 0,
      pageno: 1,
      filter: {
        companyName: "",
        projectManager: "",
        taxNo: "",
        showStatus: "",
        time: [],
      },
      tableDataList: null,
      taxBox: false,
      pageSize: 10,
      hyList: null,
      areaList: null,
      showPwd: false,
    };
  },

  mounted() {
    this.getAuditList();
    this.checkStatus();
    this.getAreaList();
    this.gettradeList();
  },

  computed: {
    areaOptions: function () {
      if (!this.areaList) {
        return [];
      }

      const ret = cloneDeep(this.areaList);
      function walk(data) {
        data.value = data.code;
        data.label = data.name;

        if (data.childNode && data.childNode.length > 0) {
          data.children = data.childNode;
          for (const child of data.children) {
            walk(child);
          }
        }
        delete data.childNode;
      }

      for (const areaList of ret) {
        walk(areaList);
      }

      return [...ret];
    },
  },

  methods: {
    checkPwdSuccess() {
      this.showPwd = false;
    },
    // 获取地区
    getAreaList() {
      areaList().then((res) => {
        this.areaList = res.data;
      });
    },
    // 获取行业
    gettradeList() {
      getTradeList().then((res) => {
        this.hyList = JSON.parse(res.data);
      });
    },
    deleteAudit(id) {
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          researchLoginRecordDel(id).then((res) => {
            this.$message({
              message: res.message,
              type: "warning",
            });
            this.getAuditList();
          });
        })
        .catch(() => {});
    },
    checkStatus() {
      let token = JSON.parse(localStorage.getItem("token")).token;
      researchLoginRecord(token).then((res) => {
        if (!res.data) {
          this.showPwd = true;
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getAuditList();
    },
    getTableData(row) {
      const currentDate = new Date();
      const date = new Date();
      const year = date.getFullYear();
      this.$router.push({
        name: "toolsTables",
        params: {
          bankId: "ade3fa2e-7cdc-4fb2-8f19-af5259b84ed3",
          version: "18",
          nsrsbm: row.programId,
          zq: year - 1 + "004",
          uuid: row.programId,
          dataPeriod: 0,
        },
      });
    },
    exportInvoice(row) {
      const url = getexportInvoiceURL({
        taxNo: row.taxNo,
      });
      return url;
    },

    exportInvoiceResult(row) {
      const url = getexportAnalysisResults({
        programId: row.programId,
      });
      return url;
    },
    addBox(code) {
      let taxNo = code.code;
      this.$router.push({ path: "/tools/authorization", query: { taxNo } });
    },
    closeBox() {
      this.taxBox = false;
    },
    analysis(programId) {
      this.$router.push({ path: "/tools/analysisResult", query: { programId } });
    },
    addTest() {
      this.taxBox = true;
    },
    // 翻页
    handleCurrentChange(val) {
      this.pageno = val;
      this.getAuditList();
    },
    searchGetAudiList() {
      this.pageno = 1;
      this.getAuditList();
    },
    // 获取客户列表
    getAuditList() {
      let params = {
        startDate: this.filter.time ? this.filter.time[0] : "",
        endDate: this.filter.time ? this.filter.time[1] : "",
        showStatus: this.filter.showStatus,
        pageNum: this.pageno,
        pageSize: this.pageSize,
        token: JSON.parse(localStorage.getItem("token")).token,
      };
      auditList(params).then((res) => {
        this.tableDataList = res.list;
        this.totalNumber = Number(res.pageTotal);
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.el-input .el-input__count {
  color: rgba(0, 0, 0, 0.2);
}

.filter-body >>> .el-input__inner {
  height: 36px;
  line-height: 36px;
}

.content-body {
  margin: 20px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  padding: 0 10px;
}

.filter-body {
  margin: 20px;
  padding: 24px 0 8px 24px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  .inputs-wrapper {
    width: 92%;

    .inputs-wraper-row {
      display: flex;
      flex-wrap: wrap;

      .form-item {
        margin-right: 30px;
        margin-bottom: 16px;
        display: flex;
        align-items: center;

        .label {
          white-space: nowrap;
          width: 69px;
          text-align: right;
          margin-right: 8px;
        }

        .labelCode {
          width: 100px;
        }

        display: flex;
      }

      .form-itemCode {
        width: 336px;
      }
    }
  }

  .btn-look-up {
    margin-right: 16px;
    width: 76px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    background: #2F54EB;
    border-radius: 4px;
    font-size: 14px;
    color: #FFFFFF;
  }
}

.btn-look-up:hover {
  cursor: pointer;
  background: rgba(47, 34, 235, 1);
}

.addItem {
  width: 104px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(47, 84, 235, 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  margin-left: 20px;
  cursor: pointer;
  display: inline-block;
  margin-right: 16px;
}

.addItem:hover {
  background: rgba(47, 34, 235, 1);
}

.ease {
  transition: all 0.3s;
}

.filter-body >>> .form-item .el-input__icon {
  line-height: 36px;
  height: 36px;
}

.el-icon-edit-outline {
  color: #2f54eb;
  font-size: 18px;
  margin-left: 4px;
}

.title-content {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
}

>>>.bread-crumb-wrapper {
  margin-top: 0;
}

.pagination-low {
  float: right;
  margin-right: 30px;
  margin-bottom: 20px;
}

.filter-body >>>.el-select {
  width: 240px;
}

.filter-body >>>.el-input {
  width: 240px;
}

.filter-body >>>.el-range-editor.el-input__inner {
  width: 240px;
}

.filter-body >>>.el-date-editor .el-range-separator {
  line-height: 28px;
}

.filter-body >>>.el-pagination__editor.el-input {
  width: 50px;
}

>>>.bread-crumb-wrapper {
  padding: 0;
  line-height: 20px;
  padding: 16px 0 16px 30px;
}

.filter-body >>>.el-date-editor .el-range__icon {
  margin-left: 0;
  margin-right: 6px;
}

.filter-body >>>.el-input__icon {
  width: 14px;
}

.filter-body >>>.el-range-editor.el-input__inner {
  padding: 3px 8px;
}

.filter-body >>>.el-date-editor .el-range-separator {
  line-height: 29px;
}

.filter-body >>>.form-item .el-input__icon {
  line-height: 38px;
}

.export {
  cursor: pointer;
  color: #2f22eb;
  margin-right: 12px;
}

.showDelete {
  color: red;
  cursor: pointer;
}
</style>
