<template>
  <div>
    <div v-if="data" class="china-wrapper">
      <div class="wrappers">
        <div class="map">
          <ChinaCity
            ref="map"
            @click="handleMapClick"
            :geo-coord-map="data.shAreaMap"
            :type="type"
            :value-data="valueData"
            :self="data.companyDistribution"
            v-if="hp != 2"
          ></ChinaCity>
          <div class="legends" v-if="hp != 2">
            <div class="legend-group">
              <div class="legend">
                <img
                  class="symbol"
                  src="https://shuian-file.oss-cn-hangzhou.aliyuncs.com/image/icon_triangle.png"
                />
                表示本公司地点
              </div>
            </div>
            <div class="legend-group" v-if="type == 2">
              <div class="legend legend-top">X为采购额占比</div>
              <div class="legend">
                <div class="box" style="background: #207dfb"></div>
                X≥50%
              </div>
              <div class="legend">
                <div class="box" style="background: #6eaafc"></div>
                50%＞X≥30%
              </div>
              <div class="legend">
                <div class="box" style="background: #aed0ff"></div>
                30%＞X≥20%
              </div>
              <div class="legend">
                <div class="box" style="background: #e0edff"></div>
                20%>X>0%
              </div>
            </div>
            <div class="legend-group" v-else-if="type == 1">
              <div class="legend">X为销售额占比</div>
              <div class="legend">
                <div class="box" style="background: #207dfb"></div>
                X≥50%
              </div>
              <div class="legend">
                <div class="box" style="background: #6eaafc"></div>
                50%＞X≥30%
              </div>
              <div class="legend">
                <div class="box" style="background: #aed0ff"></div>
                30%＞X≥20%
              </div>
              <div class="legend">
                <div class="box" style="background: #e0edff"></div>
                20%>X>0%
              </div>
            </div>
          </div>
        </div>
        <div class="table-wrapper" v-if="generalTableData">
          <BigPowerTable
            :data="generalTableData"
            class="table"
            v-if="generalTableData"
          ></BigPowerTable>
          <div class="sc" @click="switchScreen" v-if="generalTableData && hp != 2">
            <img src="@/assets/util/hScreen.png" alt="" />
          </div>
        </div>
      </div>
      <div class="full-sc" v-if="hp != 2">
        <el-drawer :visible.sync="horizontalScreen" direction="btt" size="100%">
          <div class="full-tab">
            <div class="table-wrapper">
              <BigPowerTable
                :data="generalTableData"
                v-if="generalTableData"
                class="table"
              ></BigPowerTable>
            </div>
          </div>
        </el-drawer>
      </div>
    </div>
    <div v-else v-loading="!data" class="china-wrapper-none"></div>
  </div>
</template>

<script>
import BigPowerTable from "big-power-table";
import ChinaCity from "./ChinaCity";
import GenericTable from "@/pages/ResultEditor/components/InvoiceIndex/components/GenericTable";
import { getAutoMiddlePageToConfig } from "@/utils/helper";
import { percentage, round } from "@/utils/format";
import { getCompanyAreaList } from "@/api/threeYears";
import { cloneDeep } from "lodash";

export default {
  name: "ChinaCityTable",
  props: {
    type: String,
    hp: [String, Number],
  },
  filters: {
    round,
  },
  mounted() {
    this.loadCompanyAreaList();
  },
  data() {
    return {
      data: null,
      areaTree: [],
      fullScreenDialogVisible: false,
      filter: {
        time: [],
        limit: 15,
      },
      firstTime: "",
      lastTime: "",
      currentProvinceName: "",
      horizontalScreen: false,
    };
  },
  components: {
    ChinaCity,
    BigPowerTable,
  },
  methods: {
    switchScreen() {
      // this.horizontalScreen = true;
      this.$router.push({
        path: "../chinaAreaHp/" + this.$route.params.uuid + "?type=" + this.type,
      });
    },
    setProvince(provinceName) {
      this.currentProvinceName = provinceName;
      this.$refs.map.highlight(provinceName);
    },
    handleMapClick(provinceName) {
      this.setProvince(provinceName);
    },
    changeTime() {
      this.loadCompanyAreaList();
    },
    async loadCompanyAreaList() {
      const params = {};
      if (this.filter.time && this.filter.time[0]) {
        params.startDate = this.filter.time[0];
        params.endDate = this.filter.time[1];
      }

      params.programId = this.$route.params.uuid;
      const res = await getCompanyAreaList(params);
      this.data = res.data;
    },
  },
  computed: {
    valueData() {
      if (this.type == 1) {
        //客户
        return this.data.customerList;
      } else {
        return this.data.supplierList; // 供应商
      }
    },
    //provinceTable
    generalTableData() {
      const body = this.valueData
        .filter((v) => {
          return !(
            v.companyNumber == 0 &&
            v.money == 0 &&
            v.invoiceNumber == 0 &&
            v.scale == 0
          );
        })
        .filter((v, index) => {
          let ret = index > 0;
          if (this.filter.limit && this.filter.limit > 0) {
            ret = ret && index < parseInt(this.filter.limit) + 1;
          }
          return ret;
        });
      //debugger

      const money = body.reduce((acc, i) => {
        const ret = round(parseFloat(acc) + parseFloat(i["money"])).toFixed(2);

        return ret;
      }, 0);

      let scale = body.reduce((acc, i) => {
        const ret = round(parseFloat(acc) + parseFloat(i["scale"]), 4);

        return ret;
      }, 0);
      scale = scale > 1 ? 1 : scale;

      const companyNumber = body.reduce((acc, i) => {
        const ret = round(parseFloat(acc) + parseFloat(i["companyNumber"]));

        return ret;
      }, 0);

      const invoiceNumber = body.reduce((acc, i) => {
        const ret = round(parseFloat(acc) + parseFloat(i["invoiceNumber"]));

        return ret;
      }, 0);
      let processedBody = body.map((v, index) => {
        const children = v.children
          ? v.children.map((v1) => {
              return [
                {
                  content: "",
                  align: "center",
                },
                {
                  content: v1["cityName"],
                  align: "left",
                },
                {
                  content: v1["money"],
                  align: "right",
                },
                {
                  content: percentage(v1["scale"]),
                  align: "right",
                },
                {
                  content: v1["companyNumber"],
                  align: "right",
                },
                {
                  content: v1["invoiceNumber"],
                  align: "right",
                },
              ];
            })
          : null;

        return [
          {
            content: index + 1,
            align: "center",
          },
          {
            content: v["cityName"],
            align: "left",
            children,
          },
          {
            content: v["money"],
            align: "right",
          },
          {
            content: percentage(v["scale"]),
            align: "right",
          },
          {
            content: v["companyNumber"],
            align: "right",
          },
          {
            content: v["invoiceNumber"],
            align: "right",
          },
        ];
      });
      processedBody = cloneDeep(processedBody);
      if (this.currentProvinceName) {
        const theRowIndex = processedBody.findIndex((row) => {
          return row[1].content == this.currentProvinceName;
        });
        if (theRowIndex > -1) {
          const theRow = processedBody[theRowIndex];
          processedBody.splice(theRowIndex, 1);
          theRow[1].showChildren = true;
          processedBody.unshift(theRow);
        }
      }
      return {
        body: processedBody,
        header: [
          [
            {
              content: "序号",
              align: "center",
              rowspan: 2,
              width: "center",
            },
            {
              content: "省",
              align: "center",
            },
            {
              content: "金额（万元）",
              align: "center",
            },
            {
              content: "金额占比",
              align: "center",
            },
            {
              content: "企业数量",
              align: "center",
            },
            {
              content: "发票数量",
              align: "center",
            },
          ],
          [
            {
              content: "",
              align: "center",
            },
            {
              content: this.valueData[0]["cityName"],
              align: "left",
            },
            {
              content: money,
              align: "right",
            },
            {
              content: percentage(scale),
              align: "right",
            },
            {
              content: companyNumber,
              align: "right",
            },
            {
              content: invoiceNumber,
              align: "right",
            },
          ],
        ],
      };
    },
    tips() {
      return "dasdsa";
    },
    subTables() {
      return null;
    },
  },
};
</script>

<style lang="stylus" scoped>
.wrappers
  width 100%

  .map

  .table
    // width 680px
    // flex-grow 0
    // border 1px solid #D9D9D9;
    position relative

    >>> .table.table {
      border 0 none

    }

    >>> .bigPowerTable {
      max-height 497px
      overflow auto
    }

    .right-toolbar
      display flex
      justify-content space-between
      position absolute
      z-index 1
      top -60px
      width 100%

    .btn-fullscreen
      font-size 14px

    .btns
      display flex;
      justify-content flex-end
      position absolute;
      top -50px
      right 0

  .legends
    margin-left: 0.16rem;

  .legend-group
    display flex
    margin-bottom 10px
    font-size: 0.14rem;


  .legend
    transition all 0.1s ease-in-out
    display flex
    align-items center;
    margin-right 0.1rem
    font-size: 0.20rem;
    color #666

    .box
      transition all 0.1s ease-in-out
      width: 0.12rem;
      height: 0.12rem;
      margin-right 0.04rem


    .symbol
      vertical-align top;
      height: 0.16rem;
      border-radius: 3px;
      margin-right 0.06rem

  .legend-top
    margin-right: 0.2rem;

>>> .el-table
  border-collapse: collapse;
  border-spacing: 0;
  margin-top: 0px;
  min-height 100%

  td, th {
    text-align: left;
    font-size: 14px;
    padding: 8px 16px;
    border: 1px solid rgba(238, 238, 238, 1);
    color: rgba(0, 0, 0, 0.6);
    vertical-align: top;
    min-width: 100px;

    .cell {
      color: rgba(0, 0, 0, 0.6);
      padding 0
    }

    .clickable {
      color: #2F54EB;
      cursor: pointer;
      text-decoration underline
    }
  }

  th {
    font-weight: 600;
    background: rgba(240, 245, 255, 1);
  }

  tr:nth-child(even) {
    td {
      background: rgba(245, 245, 245, 1);
    }
  }

  .link {
    color: rgba(47, 84, 235, 1);
  }

>>> .el-dialog__header
  border-bottom: 0 none;


.table-wrapper
  background: #FFFFFF;
  // margin 20px 0
  // margin-bottom 0
  // padding-bottom 24px
  position: relative;

  .sc {
    position: absolute;
    right: 0px;
    bottom: 0px;
    padding: 5px;

    img {
      width: 0.25rem;
      height: 0.25rem;
      background: #FFFFFF;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
      border-radius: 0.08rem;
    }
  }

  .time {
    margin 20px 16px
    font-size: 14px;
    font-weight: 400;
    color: #86909C;
  }

  .table {
    >>> .bigPowerTable {
      max-height 720px
      overflow auto
    }
  }

.china-wrapper {
  >>> table.table th, >>> table.table td {
    font-size: 0.2rem;
  }

  >>> .table-wrapper .fake {
    // height: 0.52rem !important;
  }
}

.full-sc {
  .full-tab {

    .t-w {
      display: flex;
      align-items: center;
    }

    transform-origin: center center;
    transform: rotateZ(90deg);

    .titleName {
      font-size: 0.28rem;
      font-weight: 600;
      color: #2B2F58;
      line-height: 0.36rem;
    }

    .dataText {
      font-size: 0.28rem;
      color: #40496A;
      line-height: 0.36rem;
      margin: 12px 0;
    }
  }
  >>> .el-drawer__header {
    padding: 0;
  }
  >>> .el-drawer__body {
    overflow: hidden;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  >>> .el-drawer__open .el-drawer.btt {
    border-radius: 0;
  }

  >>> .el-drawer__close-btn {
    font-size: 30px;
    position: relative;
    right: 10px;
    top: 10px;
  }

  // display: flex;
  // align-items: center;
  // justify-content: center;
  // flex-direction: column;

  .tab-s {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // flex-direction: column;
    // height: 100%;

    max-height: 220px;
    max-width: 500px;
    overflow: auto;

    .tablefirst {
      width: 100%;
      height: 100%;
      overflow: auto;
    }

    .tablefirst tr:nth-child(2n-1) td {
      background: #fff;
    }

    .tablefirst thead tr > th:first-child,
    .tablefirst tbody tr > td:first-child {
      position: -webkit-sticky;
      position: sticky;
      left: 0;
    }

    .table-screen-b {
      td, th {
        min-width: 80px;
        // max-width: 200px;
      }
    }
  }

  .table {
    >>> .bigPowerTable {
      max-height 5.04rem
      max-width 85vh
      overflow auto
    }
  }
}

.china-wrapper-none {
  width: 200px;
  height: 500px;
  margin: 0 auto;
}
</style>
