import { render, staticRenderFns } from "./Savedate.vue?vue&type=template&id=8927649c&scoped=true"
import script from "./Savedate.vue?vue&type=script&lang=js"
export * from "./Savedate.vue?vue&type=script&lang=js"
import style0 from "@/styles/index.styl?vue&type=style&index=0&id=8927649c&prod&lang=stylus&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8927649c",
  null
  
)

export default component.exports