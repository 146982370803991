<template>
  <div class="login-wrapper">
    <div class="login_left">
      <img src="@/assets/login/left1.png" alt />
    </div>
    <div class="tab_box">
      <div class="aitax">AITAX</div>
      <div class="aitax_title">—— 您的专属企业风控管理专家 ——</div>
      <el-tabs v-model="activeName" @tab-click="handleClick" id="login_tab">
        <el-tab-pane label="短信登录" name="smsMode">
          <el-form
            :model="ruleForm2"
            ref="ruleForm2"
            label-position="left"
            label-width="0px"
            class="containernew"
          >
            <el-form-item prop="account">
              <el-input
                class="usernameinput"
                type="text"
                v-model="ruleForm2.phone"
                @blur="blurphone"
                auto-complete="off"
                placeholder="请输入手机号或账号"
              ></el-input>
              <i class="baseloginicon iconshouji iconfont"></i>
            </el-form-item>
            <el-form-item prop="phonepwd">
              <el-input
                type="text"
                v-model="ruleForm2.phonepwd"
                @blur="blurphonepwd"
                auto-complete="off"
                placeholder="短信验证码"
                class="passwordinput usernameinput phoneyzm"
              ></el-input>
              <i class="baseloginicon iconmima iconfont"></i>

              <el-button
                :class="[canClick ? '' : 'active', 'btnsendcode']"
                class="btn-send-sms"
                @click="getCode('ruleForm3')"
              >{{content}}</el-button>
            </el-form-item>
            <el-form-item class="loginitem">
              <el-button
                class="loginbtn1"
                @click.native.prevent="handleSubmit3"
                :loading="logining"
              >登录</el-button>
            </el-form-item>
            <div class="loginmeg">{{dxlogin}}</div>
            <div class="userId zcuser">
              <span class="forget">
                <span>还没有账号，</span>
                <router-link :to="{name:'register'}">
                  去注册
                  <img src="@/assets/login/nextright.png" alt />
                </router-link>
              </span>
            </div>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="密码登录" name="passwordMode">
          <el-form
            :model="ruleForm2"
            ref="ruleForm2"
            label-position="left"
            label-width="0px"
            class="containernew"
          >
            <el-form-item prop="account">
              <el-input
                class="usernameinput"
                type="text"
                v-model="ruleForm2.account"
                auto-complete="off"
                @blur="blurmobile"
                placeholder="请输入手机号或账号"
              ></el-input>
              <i class="baseloginicon iconshouji iconfont"></i>
            </el-form-item>
            <el-form-item prop="checkPass">
              <el-input
                class="passwordinput usernameinput"
                type="password"
                show-password
                v-model="ruleForm2.checkPass"
                @blur="blurpassword"
                auto-complete="off"
                placeholder="请输入密码"
                @keyup.enter.native.prevent="handleSubmit2"
              ></el-input>
              <i class="baseloginicon iconyanzhengma iconfont"></i>
            </el-form-item>
            <div class="userId wjmm">
              <span>
                <el-checkbox v-model="labelchecked" size="mini" class="rememberpw">记住密码</el-checkbox>
              </span>
              <span style="float:right;" class="forget forget1">
                <router-link to="forgetPassword?isshowimg=false">忘记密码</router-link>
              </span>
            </div>
            <el-form-item class="loginitem">
              <el-button
                class="loginbtn1"
                @click.native.prevent="handleSubmit2"
                :loading="logining"
                @click="submitForm('ruleForm2')"
              >登录</el-button>
            </el-form-item>
            <div class="loginmeg">{{mmlogin}}</div>
            <div class="userId zcuser zcuser1">
              <span class="forget">
                <span>还没有账号，</span>
                <router-link :to="{name:'register'}">
                  去注册
                  <img src="@/assets/login/nextright.png" alt />
                </router-link>
              </span>
            </div>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="微信扫码" name="wechatMode" v-if="!customization">
          <wx-login v-if="activeName=='wechatMode'"></wx-login>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import { mapMutations,mapState } from "vuex";
import wxLogin from "./wxlogin";
import {
  requestLogin,
  newgetRegisterCode,
  newrequestLogin
} from "../../../api/api";
export default {
  components: { wxLogin },
  computed: {
    ...mapState(["customization"]),
  },
  data: function() {
    return {
      activeName: "passwordMode",
      dxlogin: "",
      mmlogin: "",
      content: "获取验证码",
      canClick: true,
      totalTime: 60,
      logining: false,
      labelchecked: false,
      ruleForm2: {
        account: "",
        phone: "",
        checkPass: "",
        phonepwd: ""
      }
    };
  },
  mounted() {
    this.getCookie();
    if (this.$route.query.activeName) {
      this.activeName = this.$route.query.activeName;
    }
  },
  methods: {
    ...mapMutations(["changeLogin"]),
    //失去焦点验证手机号
    blurphone() {
      if (!/^1[3|4|5|6|7|8|9]\d{9}$/.test(this.ruleForm2.phone)) {
        this.dxlogin = "请输入正确的手机号码";
        //  this.$message.error("请输入正确的手机号码");
      } else {
        this.dxlogin = "";
      }
    },
    blurmobile() {
      if (!/^1[3|4|5|6|7|8|9]\d{9}$/.test(this.ruleForm2.account)) {
        this.mmlogin = "请输入正确的手机号码";
      } else {
        this.mmlogin = "";
      }
    },
    blurphonepwd() {
      if (this.ruleForm2.phonepwd) {
        this.dxlogin = "";
      }
    },
    blurpassword() {
      if (this.ruleForm2.checkPass) {
        this.mmlogin = "";
      }
    },
    getCode: function() {
      // if (!this.isgetCode()) return;
      //如果验证码不能按直接跳过
      if (!this.canClick) return;
      //验证码发送的请求
      let param = { phone: this.ruleForm2.phone };
      if (!this.checkTel(this.ruleForm2.phone)) {
        this.dxlogin = "请输入正确的手机号码";
        //  this.$message.error("请输入正确的手机号码");

        return;
      }
      newgetRegisterCode(param.phone).then(data => {
        if (data.msgCode !== "1") {
          this.dxlogin = data.msgInfo;
          //  this.$message.error(data.msgInfo);
        }
      });
      //获取验证码倒计时
      this.canClick = false;
      this.content = this.totalTime + "s后重发";
      let clock = window.setInterval(() => {
        this.totalTime--;
        this.content = this.totalTime + "s后重发";
        if (this.totalTime < 0) {
          window.clearInterval(clock);
          this.content = "重发验证码 ";
          this.totalTime = 60;
          this.canClick = true;
        }
      }, 1000);
    },
    //获取手机验证码
    isgetCode: function() {
      if (!this.ruleForm2.phone) {
        this.dxlogin = "请输入手机号码";
        //  this.$message.error("请输入手机号码");

        return false;
      }
      if (!this.checkTel(this.ruleForm2.phone)) {
        this.dxlogin = "请输入正确的手机号码";
        //  this.$message.error("请输入正确的手机号码");

        return false;
      }
      return true;
    },
    //结束
    handleClick() {},
    checkTel: function(tel) {
      var mobile = /^1[3|4|5|6|7|8|9]\d{9}$/,
        phone = /^0\d{2,3}-?\d{7,8}$/;
      return mobile.test(tel) || phone.test(tel);
    },
    //是否记住密码
    submitForm() {
      const self = this;
      //判断复选框是否被勾选 勾选则调用配置cookie方法
      if (self.labelchecked == true) {
        // console.log("checked == true");
        //传入账号名，密码，和保存天数3个参数
        self.setCookie(self.ruleForm2.account, self.ruleForm2.checkPass, 7);
      } else {
        // console.log("清空Cookie");
        //清空Cookie
        self.clearCookie();
      }
      //与后端请求代码，本功能不需要与后台交互所以省略
      // console.log("登陆成功");
    },
    setCookie(c_name, c_pwd, exdays) {
      var exdate = new Date(); //获取时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
      //字符串拼接cookie
      window.document.cookie =
        "account" + "=" + c_name + ";path=/;expires=" + exdate.toGMTString();
      window.document.cookie =
        "checkPass" + "=" + c_pwd + ";path=/;expires=" + exdate.toGMTString();
    },
    //读取cookie
    getCookie: function() {
      if (document.cookie.length > 0) {
        var arr = document.cookie.split("; "); //这里显示的格式需要切割一下自己可输出看下
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split("="); //再次切割
          //判断查找相对应的值
          if (arr2[0] == "account") {
            this.labelchecked = true;
            this.ruleForm2.account = arr2[1]; //保存到保存数据的地方
          } else if (arr2[0] == "checkPass") {
            this.labelchecked = true;
            this.ruleForm2.checkPass = arr2[1];
          }
        }
      }
    },
    //清除cookie
    clearCookie: function() {
      this.setCookie("", "", -1); //修改2值都为空，天数为负1天就好了
    },
    //短信登录
    handleSubmit3() {
      if (!this.ruleForm2.phone) {
        this.dxlogin = "请输入手机号";
        //  this.$message.error("请输入手机号");

        return;
      }
      if (!this.ruleForm2.phonepwd) {
        this.dxlogin = "请输入验证码";
        //  this.$message.error("请输入验证码");

        return;
      }
      this.$refs.ruleForm2.validate(valid => {
        if (valid) {
          this.logining = true;
          let loginParams = {
            mobile: this.ruleForm2.phone,
            code: this.ruleForm2.phonepwd
          };
          newrequestLogin(loginParams).then(data => {
            this.logining = false;
            if (data.msgCode !== "1") {
              this.dxlogin = data.msgInfo;
              // this.$message.error(data.msgInfo);
            } else {
              let result = data.data;
              let user = result.user;
              let token = result.token;
              localStorage.setItem("user", JSON.stringify(user));
              localStorage.setItem("token", JSON.stringify(token));
              // 将用户token保存到vuex中
              this.changeLogin({ authorization: token.token });
              this.$router.push({ path: "/" });
            localStorage.setItem('islog',1)

            }
          });
          // }
        } else {
          return false;
        }
      });
    },
    //密码登录
    handleSubmit2() {
      var _this = this;
      if (!this.ruleForm2.account) {
        this.mmlogin = "请输入手机号或账号";
        //  this.$message.error("请输入手机号或账号");
        return;
      }
      if (!this.ruleForm2.checkPass) {
        this.mmlogin = "请输入密码";
        //  this.$message.error("请输入密码");

        return;
      }
      this.$refs.ruleForm2.validate(valid => {
        if (valid) {
          this.logining = true;
          let loginParams = {
            mobile: this.ruleForm2.account,
            password: this.ruleForm2.checkPass
          };
          requestLogin(loginParams).then(data => {
            this.logining = false;
            if (data.msgCode !== "1") {
              this.mmlogin = data.msgInfo;
              // this.$message.error(data.msgInfo);
            } else {
              let result = data.data;
              let user = result.user;
              let token = result.token;
              localStorage.setItem("user", JSON.stringify(user));
              localStorage.setItem("token", JSON.stringify(token));
              _this.changeLogin({ authorization: token.token });
              this.$router.push({ path: "/" });
            localStorage.setItem('islog',1)

            }
          });
          // }
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style lang="stylus" scoped>
.login_left {
  width: 560px;

  img {
    width: 464px;
    margin-left: 48px;
    margin-top: 32px;
  }
}

.tab_box {
  width: 400px;

  .aitax_title {
    text-align: center;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.4);
    line-height: 20px;
    margin-top: 4px;
    margin-bottom: 40px;
  }

  .aitax {
    text-align: center;
    height: 36px;
    font-size: 32px;
    font-family: ArialMT;
    color: rgba(0, 0, 0, 0.8);
    line-height: 37px;
  }
}

#login_tab {
  width: 400px;
}

.wjmm {
  margin-bottom: 16px;
}

.zcuser1 {
  margin-top: 23px !important;
}

.zcuser {
  margin-top: 23px;
  text-align: center;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(47, 84, 235, 1);
  line-height: 22px;

  span {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: rgba(0, 0, 0, 0.6);
  }

  img {
    width: 24px;
    margin-left: 8px;
  }
}

.phoneyzm {
  width: 210px !important;
}

.wxlogin {
  width: 124px;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
  display: inline-block;
  position: relative;

  img {
    width: 24px;
    cursor: pointer;
    height: 24px;
    position: absolute;
    top: -3px;
    right: 0;
  }
}

.passcss {
  width: 18px !important;
  height: 18px !important;
}

.btnsendcode {
  position: absolute;
  right: 0;
  top: -16px;
  background: #fff;
  border-radius: 4px;
  color: #2F54EB;
  width: 102px;
  padding: 12px 0px;
  border: 1px solid #2F54EB;

  &:hover, &:focus, &:active {
    background: #fff;
    border-color: #fff;
  }

  &.active {
    // background: #D1D1D1;
    // color: #E6E6E6;
    // border-color: #D1D1D1;
  }
}

>>> .usernameinput {
  .el-input__inner {
    height: 40px;
    background: rgba(255, 255, 255, 1);
    border-radius: 4px;
    border: 1px solid rgba(217, 217, 217, 1);
    text-indent: 10px;
  }
}

>>>.rememberpw {
  .el-checkbox__inner {
    border-color: #D9D9D9;

    &::after {
    }
  }

  .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #2F54EB;
    border-color: #2F54EB;
  }

  .el-checkbox__label {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
  }
}

.forget1 {
  a {
    font-size: 14px !important;
  }
}

.forget {
  text-align: left;
  margin-right: 10px;

  a {
    font-size: 16px;
    color: #2F54EB;
  }
}

.btn-send-sms {
  margin-top: 16px;
}

>>>.el-input__inner {
  padding-left: 30px;
}

>>>.el-tabs__nav-wrap::after {
  content: none;
}

>>>.el-tabs {
  width: 100%;
  margin: 0 auto;
}

>>>.el-tabs__nav-wrap {
  width: 100%;
  margin: 0 auto;
}

>>>.el-tabs__header {
  margin: 0;

  .el-tabs__nav {
    width: 100%;
    margin: 0 0 24px 0;

    .el-tabs__item {
      font-size: 16px;
      text-align: center;
      padding: 0 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      color: rgba(0, 0, 0, 0.6);
      height: 22px;
      line-height: 22px;
    }

    .el-tabs__item {
      border-right: 1px solid #979797;
    }

    .el-tabs__item:last-child {
      border-right: none;
    }

    .el-tabs__item.is-active {
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: rgba(47, 84, 235, 1);
    }

    .el-tabs__active-bar {
      display: none;
      background-color: #2F54EB;
    }
  }
}

.loginmeg {
  margin-top: 8px;
  text-align: center;
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(245, 34, 45, 1);
  line-height: 17px;
}

.loginitem {
  text-align: right;
  margin-bottom: 8px !important;

  .loginitemzc {
    float: left;
    text-align: left;
    color: #2F54EB;

    a {
      color: #2F54EB;
    }
  }
}

.loginbtn1 {
  background: #2F54EB;
  width: 100%;
  height: 40px;
  color: #fff;
  font-size: 16px;
  border-radius: 4px;
}

.containernew {
  width: 80%;
  margin: 0 auto;

  >>>.el-form-item {
    margin-bottom: 24px;
  }

  >>>.el-form-item:nth-child(2) {
    margin-bottom: 32px;
  }

  .baseloginicon {
    position: absolute;
    left: 16px;
    // top: 11px;
    color: #999999;
    width: 16px;
    height: 16px;
  }
}

.login-wrapper {
  display: flex;
  justify-content: space-evenly;
  height 570px;
  box-sizing border-box;
  padding:80px 0 60px;
  width: 960px;
  position: relative;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 0 auto;
  padding-top: 80px;
}

.logintitle {
  margin-bottom: 80px;

  .titletop {
    font-size: 36px;
    color: #1a1a1a;
    font-weight: bold;
    text-align: center;

    span {
      font-size: 36px;
      color: #000000;
      font-weight: normal;
    }
  }

  .titlebto {
    text-align: center;
    font-size: 16px;
    color: #808080;
    letter-spacing: 5px;
    margin-top: 3px;
  }
}
</style>
