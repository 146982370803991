<template>
  <div class="financialRisk-h5-container">
    <div class="financialRisk-h5">
      <div class="company-baseName">
        <div class="company-tip"></div>
        <div class="company-tip-base">上下游客户分析</div>
      </div>
    </div>
    <div class="PurchaseAndSale-h5">
      <div class="title">上下游客户网络</div>
      <div class="text">
        2020年1月1日至2021年10月31日，累计交易金额排名前15的上下游客户，如果风险企业不超过15家，以实际数量为准。
      </div>
      <UpDownCustomerNetWork type="1"></UpDownCustomerNetWork>
      <div class="chaseAndBuy chaseAndBuy-top" @click="toCompany">
        <div>上下游风险企业</div>
        <i class="el-icon-arrow-right"></i>
      </div>
      <div class="chaseAndBuy" @click="toCustomer">
        <div>上下游客户分布</div>
        <i class="el-icon-arrow-right"></i>
      </div>
      <div class="sc" @click="switchScreen">
        <img src="@/assets/util/hScreen.png" alt=""/>
      </div>
    </div>
    <div class="full-sc">
      <el-drawer :visible.sync="horizontalScreen" direction="btt" size="100%">
        <div class="full-tab">
          <UpDownCustomerNetWork2 type="1"></UpDownCustomerNetWork2>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import "@/pages/Result/ThreeYearResult/global.styl";
import UpDownCustomerNetWork from "@/pages/Result/H5FinancialAnalysis/UpDownCustomerNetWork";
import UpDownCustomerDistribution
  from "@/pages/Result/H5FinancialAnalysis/UpDownCustomerDistribution";
import UpDownCustomerNetWork2 from "@/pages/Result/H5FinancialAnalysis/UpDownCustomerNetWork2";

export default {
  name: "UpDownCustomer",
  components: {
    UpDownCustomerNetWork,
    UpDownCustomerDistribution,
    UpDownCustomerNetWork2,
  },

  data() {
    return {
      activeIndex: "1",
      horizontalScreen: false,
      desensitization: this.$route.query.desensitization || 0
    };
  },
  methods: {
    /**
     * 顶部菜单点击
     * @param {string} key
     * @param {array<string>}keyPath
     */
    handleTopMenuSelect(key, keyPath) {
      this.activeIndex = key;
    },
    toCompany() {
      this.$router.push({path: "../upDownCustomerCompany/" + this.$route.params.uuid+'?desensitization='+this.desensitization});
    },
    toCustomer() {
      this.$router.push({path: "../upDownCustomerCustomer/" + this.$route.params.uuid+'?desensitization='+this.desensitization});
    },
    switchScreen() {
      this.$router.push({path: "../upDownG6/" + this.$route.params.uuid+'?desensitization='+this.desensitization});
    },
  },
};
</script>

<style lang="stylus" scoped>
.financialRisk-h5-container
  border: 2px solid #6AA1FF;
  border-style: dashed;
  margin: 0.4rem;
  padding: 0.24rem;
  border-radius: 4px;
  position: relative;

  .sc {
    position: absolute;
    right: 0.24rem;
    bottom: 1.8rem;
    padding: 5px;

    img {
      width: 0.25rem;
      height: 0.25rem;
      background: #FFFFFF;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
      border-radius: 0.08rem;
    }
  }

.financialRisk-h5
  .company-baseName
    display: flex;
    align-items: center;
    color: #1D2129;
    font-size: 0.32rem;
    font-weight: 600;
    line-height: 0.33rem;
    margin-bottom: 0.16rem;

    .company-tip
      width: 0.12rem;
      height: 0.24rem;
      background: #2F54EB;
      margin-right: 0.1rem;

    .company-tip-base
      line-height: 0.33rem;

.PurchaseAndSale-h5 {
  border: 1px solid #e5e6eb;

  .title {
    font-size: 0.28rem;
    color: #1D2129;
    line-height: 0.3rem;
    margin: 0.24rem 0 0 0.24rem;
    font-weight: 600
  }

  .text {
    font-size: 0.28rem;
    color: #4E5969;
    line-height: 0.3rem;
    margin: 0.16rem 0.1rem 0.16rem 0.24rem;
    text-align: justify
    line-height 1.4
  }

  .chaseAndBuy {
    display: flex;
    align-items: center;
    line-height: 0.78rem;
    border-top: 1px solid #E5E6EB;
    color: #1D2129;
    font-size: 0.28rem;
    justify-content: space-between;
    padding: 0 0.24rem;

    .el-icon-arrow-right {
      font-size: 0.32rem;
    }
  }
}

.full-sc {
  .full-tab {
    transform-origin: center center;
    transform: rotateZ(90deg);
  }

  >>> .el-drawer__header {
    padding: 0;
  }

  >>> .el-drawer__body {
    overflow: hidden;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  >>> .el-drawer__open .el-drawer.btt {
    border-radius: 0;
  }

  >>> .el-drawer__close-btn {
    font-size: 30px;
    position: relative;
    right: 10px;
    top: 10px;
  }

  .table {
    >>> .bigPowerTable {
      max-height 5.8rem
      max-width 85vh
      overflow auto
    }

    >>> table.table th, >>> table.table td {
      font-size: 0.2rem;
    }
  }
}

.chaseAndBuy-top {
  margin-top: 0.16rem;
}
</style>
