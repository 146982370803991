<template>
  <div>
    <div class="overallNode-wrapper">
      <!-- <HeadLevel0 :indexes="['overall']"></HeadLevel0> -->
      <SectionNode
        :title="overall.titleName"
        :level="2"
        :overall="overall"
        :isEditable="isEditable"
      >
        <template v-slot:default="slotProps">
          <div class="textarea-wrapper" v-if="slotProps.isEditMode">
            <div class="editable-wrapper">
              <div class="label">结果:</div>
              <el-input
                type="textarea"
                placeholder="请输入内容"
                v-model="overallPDFData"
                maxlength="1500"
                :autosize="{ minRows: 8, maxRows: 20 }"
                show-word-limit
                class="textarea"
              ></el-input>
            </div>
          </div>
          <div class="textarea-wrapper" v-else>
            <div class="wrapper-text">
              {{ overallPDFData }}
            </div>
          </div>
        </template>
      </SectionNode>
    </div>

    <div
      class="section-wrapper-0"
      v-for="(node0, index0) in treeData"
      :key="node0.id"
      :id="'node' + node0.id"
    >
      <HeadLevel0 :indexes="[index0]"></HeadLevel0>

      <div v-if="node0.children && node0.children.length > 0">
        <!--二级节点-->
        <SectionNode
          v-for="(node1, index1) in node0.children"
          :key="node1.id"
          :title="`${node1.titleName}`"
          :indexes="[index0, index1]"
          :level="1"
        >
          <!--编辑-->
          <template v-slot:default="slotProps">
            <div v-if="node1.children && node1.children.length > 0">
              <!--三级节点-->
              <SectionNode
                v-for="(node2, index2) in node1.children"
                :key="node2.id"
                :indexes="[index0, index1, index2]"
                :title="`${node2.titleName}`"
                :level="2"
              >
                <template v-slot:default="slotProps">
                  <div v-if="node2.children && node2.children.length > 0">
                    <!--四级节点-->
                    <!-- <SectionNode
                      v-for="(node3, index3) in node2.children"
                      :key="node3.id"
                      :indexes="[index0, index1, index2, index3]"
                      :title="`${node3.titleName}`"
                      :level="3"
                    >
                      <template v-slot:default="slotProps">
                        <RiskProblem
                          :indexes="[index0, index1, index2, index3]"
                          v-if="node3.type == 4"
                        ></RiskProblem>
                      </template>
                    </SectionNode> -->
                    <!--四级节点-->
                  </div>
                  <div v-else>
                    <InvoiceIndex
                      v-if="node2.type == 1"
                      :indexes="[index0, index1, index2]"
                      :isReadOnly="!slotProps.isEditMode"
                    ></InvoiceIndex>
                    <RiskProblem
                      :indexes="[index0, index1, index2]"
                      v-if="node2.type == 2"
                      :isReadOnly="!slotProps.isEditMode"
                    ></RiskProblem>
                    <TaxIndex
                      v-if="node2.type == 4"
                      :indexes="[index0, index1, index2]"
                      :isReadOnly="!slotProps.isEditMode"
                    ></TaxIndex>
                    <!-- <Riskfenlei
                      :indexes="[index0, index1, index2]"
                      v-if="node2.type == 3"
                    ></Riskfenlei> -->
                    

                    <!--优惠政策-->
                    <!-- <DiscountPolicy
                      v-if="node2.type == 5"
                      :indexes="[index0, index1, index2]"
                    ></DiscountPolicy> -->
                    <!--/优惠政策-->
                  </div>
                </template>
              </SectionNode>
              <template v-if="isEditable&&node1.contrastKey != 'SWHighRisk'">
                <NodeAppender
                  :node="node1"
                  :key="node1.id"
                  v-if="node1.contrastKey != 'RiskBT'"
                >
                </NodeAppender>
                <RiskNodeAppender
                  :node="node1"
                  :key="node1.id"
                  v-else
                ></RiskNodeAppender>
              </template>

              <!--/三级节点-->
            </div>
            <div v-else></div>
          </template>
          <!--/编辑-->
        </SectionNode>
        <!--优惠添加组件-->
        <DiscountPolicyAppend
          v-if="node0.children[node0.children.length - 1].type == 5"
          :indexes="[index0]"
        ></DiscountPolicyAppend>
        <!--/优惠添加组件-->
        <!--/二级节点-->
      </div>
    </div>
  </div>
</template>

<script>
import "@/pages/SimpleResultEditor/global.styl";

import OverallNode from "./OverallNode";
import NodeAppender from "./NodeAppender";
import RiskNodeAppender from "./RiskNodeAppender";

import { cloneDeep } from "lodash";
import SectionNode from "./SectionNode";
import InvoiceIndex from "./InvoiceIndex/";
import TaxIndex from "./TaxIndex/";
import Riskfenlei from "./Riskfenlei";
import DiscountPolicy from "./DiscountPolicy/DiscountPolicy";
import DiscountPolicyAppend from "./DiscountPolicy/DiscountPolicyAppend";
import RiskProblem from "./RiskProblem/";
import HeadLevel0 from "./HeadLevel0";
import mixin from "@/pages/ResultEditor/mixin.js";

import { mapState, mapGetters } from "vuex";

export default {
  mixins: [mixin],
  filters: {
    textarea: function (value) {
      return value.replace(/\n/g, "<br/>");
    },
  },
  components: {
    NodeAppender,
    RiskNodeAppender,
    SectionNode,
    InvoiceIndex,
    TaxIndex,
    Riskfenlei,
    DiscountPolicy,
    DiscountPolicyAppend,
    RiskProblem,
    HeadLevel0,
  },
  computed: {
    textarea: function () {
      return value.replace(/\n/g, "<br/>");
    },
    ...mapState("ResultEditor", ["treeData", "overall"]),
    overallPDFData: {
      get() {
        return this.$store.state.ResultEditor.overall.pdfData;
      },
      set(value) {
        const overall = cloneDeep(this.$store.state.ResultEditor.overall);
        overall.pdfData = value;
        this.$store.commit("ResultEditor/setOverall", { data: overall });
      },
    },
  },
  data: function () {
    return {};
  },
};
</script>

<style lang="stylus" scoped>
.overallNode-wrapper {
  margin-top: 40px;
  box-sizing: border-box;
  margin: 32px 31px;
}

.textarea-wrapper {
  display: flex;
  box-sizing: border-box;

  .editable-wrapper {
    display: flex;
    align-items: flex-start;
    width: 100%;
    color: rgba(0, 0, 0, 0.6);

    .label {
      font-weight: bold;
      color: rgba(0, 0, 0, 0.6);
      font-size: 16px;
      margin-right: 5px;
      margin-left: 16px;
      margin-top: 20px;
    }

    .textarea {
      >>> textarea {
        box-sizing: border-box;
        background: #FBFBFB;
        font-size: 14px;
        line-height: 30px;
      }

      >>> .el-textarea__inner {
        border-radius: 16px;
      }

      width: auto;
      display: block;
      margin: 16px;
      margin-left: 15px;
      border-radius: 16px;
      box-sizing: border-box;
      line-height: 1.5;
      color: rgba(0, 0, 0, 0.8);
      font-size: 14px;
      flex-grow: 1;
    }
  }
}

.section-wrapper-0 {
}

.wrapper-text {
  display: block;
  margin: 0;
  white-space: pre-line;
  overflow: hidden;
  padding: 16px 27px;
  line-height: 2.2;
  color: rgba(0, 0, 0, 0.8);
  flex-grow: 0;
  font-size: 14px;
}

.append-wrapper {
  margin-top: 20px;

  .append-btns {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
