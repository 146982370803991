<template>
  <div class="full-sc-key" v-if="keyFinancialData">
    <div class="close" @click="close">
      <img src="@/assets/test/part-close.png" alt="" />
    </div>
    <div class="table-wrapper-1">
      <BigPowerTable
        :data="keyFinancialData"
        v-if="keyFinancialData"
        class="table"
      ></BigPowerTable>
    </div>
  </div>
</template>

<script>
import BigPowerTable from "big-power-table";
import "@/pages/Result/ThreeYearResult/global.styl";
import KeyTaxRate from "@/pages/Result/ThreeYearResult/financialAnalysis/KeyTaxRate";
import { getKeyFinancialRatios } from "@/api/threeYears";

export default {
  components: {
    KeyTaxRate,
  },
  name: "KeyFinanceRatio",
  async mounted() {
    this.loaded = false;
    await this.loadKeyFinancialRatios();
    this.loaded = true;
    // this.stop();
  },
  data() {
    return {
      activeIndex: "1",
      keyFinancialData: null,
      loaded: false,
      horizontalScreen: true,
    };
  },
  methods: {
    // switchScreen() {
    //   this.horizontalScreen = true;
    // //   this.stop();
    // },
    async loadKeyFinancialRatios() {
      const res = await getKeyFinancialRatios({
        programId: this.$route.params.uuid,
      });
      let keyFinancialData = res.data;
      this.keyFinancialData = {};
      this.keyFinancialData.header = keyFinancialData.data[0].data.header;
      this.keyFinancialData.body = keyFinancialData.data[0].data.body.concat(
        keyFinancialData.data[1].data.body
      );
    },
    /**
     * 顶部菜单点击
     * @param {string} key
     * @param {array<string>}keyPath
     */
    handleTopMenuSelect(key, keyPath) {
      this.activeIndex = key;
    },
    stop() {
      var mo = function (e) {
        e.preventDefault();
      };
      // document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", mo, false); //禁止页面滑动
    },
    /***取消滑动限制***/
    move() {
      var mo = function (e) {
        e.preventDefault();
      };
      // document.body.style.overflow = ""; //出现滚动条
      document.removeEventListener("touchmove", mo, false);
    },
    close() {
      this.$router.back();
    },
  },
};
</script>

<style lang="stylus" scoped>
.full-sc-key {
  position: fixed;
	width: 100vh;
	height: 100vw;
	top: 0;
	left: 100vw;
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
	transform-origin: 0% 0%;
  background: #fff;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  >>> .table .bigPowerTable {
    max-height: 5.5rem;
    overflow auto
    max-width: 10rem;
  }

  >>> table.table th, >>> table.table td {
    font-size: 0.2rem;
  }

  .close {
    position: absolute;
    top: 10px;
    left: 10px;

    img {
      padding: 10px;
      width: 15px;
      height: 15px;
    }
  }
}
</style>
