var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[[_c('div',{class:`section-wrapper-${_vm.level}`,attrs:{"id":'node' + _vm.node.id}},[_c('div',{class:[
          _vm.showEditForm || (_vm.node.children && _vm.node.children.length > 0)
            ? ''
            : 'head-level-editable-style',
          'head-level-' + _vm.level,
        ]},[(!_vm.showEditForm)?[_c('div',{staticClass:"title-wrapper",on:{"click":function($event){_vm.toolTipVisible = true && _vm.isEditable && !_vm.isSystemDiscountPolicy}}},[_c('span',{class:{
                bold: _vm.node.children && _vm.node.children.length > 0,
                removed: !_vm.node.isDisplay,
              }},[_vm._v(_vm._s(_vm.node.orderNumber)+" "+_vm._s(_vm.title)+" ")]),(_vm.isModifiedDiscountPolicy)?_c('div',{staticClass:"badge yellow"},[_vm._v(" 已修改 ")]):(_vm.isInputModified)?_c('div',{staticClass:"badge yellow"},[_vm._v("已修改")]):_vm._e(),(_vm.isModifiedDiscountPolicyByMyself)?_c('div',{staticClass:"badge red"},[_vm._v(" 已修改 ")]):(_vm.isInputModifiedByMyself)?_c('div',{staticClass:"badge red"},[_vm._v(" 已修改 ")]):_vm._e(),_c('WarningBadge',{attrs:{"id":_vm.node.riskLevel}}),(_vm.node.label)?_c('span',{staticClass:"label-box"},[_vm._v(_vm._s(_vm.node.label))]):_vm._e()],1)]:[_c('div',{staticClass:"editable-title"},[_c('div',{staticClass:"label"},[_vm._v("标题：")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.titleInput),expression:"titleInput"}],staticClass:"input",attrs:{"type":"text"},domProps:{"value":(_vm.titleInput)},on:{"input":function($event){if($event.target.composing)return;_vm.titleInput=$event.target.value}}}),(!_vm.isOverallNode)?_c('div',{staticClass:"warningLevelSelector-wrapper"},[_c('div',{staticClass:"label"},[_vm._v("风险程度:")]),_c('WarningLevelSelector',{attrs:{"selectedValue":_vm.node.riskLevelId},on:{"change":_vm.handleWarningLevelSelectorChanged}})],1):_vm._e()])],(!(_vm.node.children && _vm.node.children.length > 0))?[(_vm.isEditable)?[(_vm.showEditForm)?[(_vm.isEditable)?_c('div',{staticClass:"btns-wrapper"},[_c('button',{staticClass:"btn btn-primary",on:{"click":_vm.handleBtnSaveClick}},[_vm._v(" 保存 ")])]):_vm._e()]:[(_vm.isEditable)?_c('div',{staticClass:"btns-wrapper"},[(_vm.isDisplay)?_c('button',{staticClass:"btn btn-primary btn-rounded",on:{"click":_vm.handleMaskEditClick}},[_vm._v(" 编辑 ")]):_vm._e(),(_vm.isDisplay && !_vm.isOverallNode && !_vm.node.userData)?_c('router-link',{staticClass:"btn btn-primary btn-rounded",attrs:{"to":{
                    name: 'resultDetail',
                    params: {
                      type: _vm.node.type,
                      contrastKey: _vm.node.contrastKey,
                      indexes: _vm.indexes.join(','),
                      nsrsbh: this.$route.params.nsrsbh,
                      uuid: this.$route.params.uuid,
                      action: this.$route.params.action,
                    },
                    query: {
                      title: `${_vm.node.orderNumber} ${_vm.title}`,
                    },
                  },"target":"_blank"}},[_vm._v("查看详情 ")]):_vm._e(),(
                    _vm.isDisplay &&
                    !_vm.isDiscountPolicy &&
                    (!_vm.node.children ||
                      _vm.node.children.length == 0 ||
                      this.isOverallNode)
                  )?_c('button',{staticClass:"btn btn-default btn-rounded",on:{"click":_vm.handleBtnResetClick}},[_vm._v(" 重置 ")]):_vm._e(),(_vm.isDisplay && _vm.isUserAddedData)?_c('button',{staticClass:"btn btn-default btn-rounded",on:{"click":_vm.handleBtnDeleteClick}},[_vm._v(" 删除 ")]):_vm._e(),(_vm.isDisplay)?_c('button',{staticClass:"btn btn-default btn-rounded",on:{"click":_vm.handleBtnDisplayToggleClick}},[_vm._v(" "+_vm._s(_vm.toggleBtnLabel)+" ")]):_c('button',{staticClass:"btn btn-default btn-rounded btn-toggle-show",on:{"click":_vm.handleBtnDisplayToggleClick}},[_c('i',{staticClass:"iconfont iconyincang"}),_vm._v(" "+_vm._s(_vm.toggleBtnLabel)+" ")])],1):_vm._e()]]:[(_vm.isDisplay && !_vm.isOverallNode && !_vm.node.userData)?_c('router-link',{staticClass:"btn btn-primary btn-rounded",attrs:{"to":{
                name: 'resultDetail',
                params: {
                  type: _vm.node.type,
                  contrastKey: _vm.node.contrastKey,
                  indexes: _vm.indexes.join(','),
                  nsrsbh: this.$route.params.nsrsbh,
                  uuid: this.$route.params.uuid,
                  action: this.$route.params.action,
                },
                query: {
                  title: `${_vm.node.orderNumber} ${_vm.title}`,
                },
              },"target":"_blank"}},[_vm._v("查看详情 ")]):_vm._e()]]:_vm._e()],2),_c('transition',{attrs:{"name":"slide"}},[(_vm.isDisplay)?_c('div',{staticClass:"section-body"},[_vm._t("default",null,{"isEditMode":_vm.isEditable && _vm.showEditForm})],2):_vm._e()])],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }