<template>
  <div>
    <div class="mask" @click="close"></div>
    <div class="toast">
      <div class="title">
        <span>联系我们</span>
        <img
          class="close"
          @click="close"
          src="@/assets/test/part-close.png"
          alt=""
        />
      </div>
      <div class="box">
        <div class="name field">姓名：</div>
        <div><el-input v-model="name" placeholder="请输入"></el-input></div>
      </div>
      <div class="box">
        <div class="name field">联系方式：</div>
        <div><el-input v-model="phone" placeholder="请输入"></el-input></div>
      </div>
      <div class="box box-short">
        <div class="name name-short">需求描述：</div>
        <div>
          <el-input
            v-model="detail"
            type="textarea"
            :rows="2"
            placeholder="请输入"
          ></el-input>
        </div>
      </div>
      <div class="info">工作人员会在1个工作日内给您回复</div>
      <div class="bottom">
        <div class="sumbit" @click="addcontactMsg">提交</div>
      </div>
    </div>
  </div>
</template>

<script>
import { addContactMsg } from "../../../api/api";

export default {
  props: {
    buyItem: Object,
    orderId: String,
  },
  data() {
    return {
      name: "",
      phone: "",
      detail: "",
    };
  },
  methods: {
    addcontactMsg() {
      if (!this.name) {
        this.$message({
          message: "请输入姓名",
          type: "warning",
        });
        return;
      }
      if (!this.phone) {
        this.$message({
          message: "请输入联系方式",
          type: "warning",
        });
        return;
      }
      let params = {
        name: this.name,
        phone: this.phone,
        detail: this.detail,
      };
      addContactMsg(params).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "提交成功！",
            type: "success",
          });
          setTimeout(() => {
            this.$emit("close");
          }, 800);
        }
      });
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="stylus" scoped>
.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1001;
}

.toast {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -250px;
  margin-left: -240px;
  background: #ffffff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 1002;
  width: 480px;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px 0 24px;
    height: 54px;
    line-height: 54px;
    background: #ffffff;
    box-shadow: 0px 1px 0px 0px #eeeeee;
    // border-radius: 8px 8px 0px 0px;
    box-sizing: border-box;
    margin-bottom: 32px;

    .close {
      cursor: pointer;
      padding: 10px 10px;
    }
  }

  .box {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.8);
    margin-left: 48px;
    margin-bottom: 16px;

    .name {
      width: 85px;
      text-align: right;
    }

    .field:before {
      content: '*';
      color: red;
      position: relative;
      right: 4px;
    }

    >>>.el-input {
      width: 300px;
      height: 36px;
    }

    >>>.el-textarea {
      width: 300px;
    }
  }

  .box-short {
    align-items: flex-start;

    .name-short {
      position: relative;
      top: 6px;
    }
  }

  .info {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.4);
    line-height: 20px;
    margin-left: 130px;
    margin-top: -12px;
  }

  .bottom {
    height: 68px;
    background: #FFFFFF;
    box-shadow: 0px -1px 0px 0px #EEEEEE;
    border-radius: 8px 8px 0px 0px;
    margin-top: 32px;

    .sumbit {
      width: 76px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      color: #fff;
      background: #2F54EB;
      float: right;
      margin: 16px 24px 0 0;
      font-size: 14px;
      font-weight: 600;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
</style>