<template>
  <div class="pdf-mode">
    <div v-for="grp in node.children" :key="grp.id" v-if="grp.isDisplay">
      <div class="pdf-page-wrapper pdf-page" :id="'node'+grp.id">
        <div class="header">
          <div class="title">{{ node.orderNumber|chineseNumber }}、{{ node.titleName }}</div>
          <div class="border"></div>
        </div>
        <div class="grp">
          <div class="grp-title" :class="{displayNone: grp.titleName==''}">
            <span class="red">{{ grp.orderNumber }} {{ grp.titleName }}</span>
          </div>
          <div v-for="(node,index) of grp.children" :key="node.id" v-if="node.isDisplay">
            <!--发票-->
            <div v-if="node.type == 1" :id="'node'+node.id" >
              <div class="title3">
                {{ node.orderNumber }} {{ node.titleName }}

                <WarningBadge mode="pdf" :id="node.riskLevel"></WarningBadge>
              </div>
              <div>
                <InvoiceIndex :node="node" mode="pdf"></InvoiceIndex>
              </div>
            </div>
            <!--/发票-->
            <!--税务-->
            <div v-if="node.type == 2" :id="'node'+node.id" >
              <div class="title3">
                {{ node.orderNumber }} {{ node.titleName }}

                <WarningBadge mode="pdf" :id="node.riskLevel"></WarningBadge>
              </div>
              <div>
                <RiskProblemList :node="node" mode="pdf"></RiskProblemList>
              </div>
            </div>
            <!--/税务-->
            <!--财务-->
            <!-- <div v-if="node.type == 4" :id="'node'+node.id"> -->
              <div v-if="node.type == 4" :id="'node'+node.id" :class="(index%3!=0||index==0)?'':'qiangzhi'" >
              <div class="title3">
                {{ node.orderNumber }} {{ node.titleName }}

                <WarningBadge mode="pdf" :id="node.riskLevel"></WarningBadge>
              </div>
              <div>
                <TaxIndex mode="pdf" :node="node"></TaxIndex>
              </div>
            </div>
            <!--/财务-->
            <!--财务-->
            <div v-if="node.type == 5" :id="'node'+node.id">
              <div class="title3">
                {{ node.orderNumber }} {{ node.titleName }}

                <WarningBadge mode="pdf" :id="node.riskLevel"></WarningBadge>
              </div>
              <div>
                <SimpleTextContent mode="pdf" :node="node"></SimpleTextContent>
              </div>
            </div>
            <!--/财务-->
          </div>
        </div>
        <div class="footer">
          <!-- <div class="page">{{ grp.page|padNumber }}</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RiskProblemList from "@/pages/Result/components/RiskProblemList";
import TaxIndex from "@/pages/ResultEditor/components/TaxIndex";
import InvoiceIndex from "@/pages/ResultEditor/components/InvoiceIndex/InvoiceIndex";
import DropToggle from "@/pages/Result/components/DropToggle";
import WarningBadge from "@/pages/Result/components/compents/WarningBadge";
import SimpleTextContent from "@/pages/ResultEditor/components/SimpleTextContent";

export default {
  components: {
    DropToggle,
    InvoiceIndex,
    WarningBadge,
    RiskProblemList,
    TaxIndex,
    SimpleTextContent
  },
  props: {
    node: Object,
  }
};
</script>

<style lang="stylus" scoped>
@import "~@/pages/pdfFrontGenerator/pdf.styl"
.pdf-page-wrapper {
  padding 33px 0
}

.grp {
  padding 0px 67px

  .grp-title {
    font-size: 33px;
    display inline-block
    font-weight: bold;
    color: #1D2129;
    line-height: 50px;
    border-bottom 6px solid #3196FF;
  }


}
</style>
