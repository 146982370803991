<template>
  <div class="forgetPassword clearfix">
    <div class="forget_left">
      <img src="@/assets/login/left2.png" alt="">
    </div>
    <div class="right">
      <el-form :model="form"
               :rules="rules"
               ref="form" class="form">
        <div class="li_input liwjmm">
          忘记密码
        </div>
        <el-form-item prop="mobile">
          <div class="li_input">
            <!-- <img src="@/assets/login/phone.png" alt=""> -->
            <el-input v-model="form.mobile" placeholder="请输入手机号码">
              <i slot="prefix" class="baseloginicon iconshouji iconfont"></i>
            </el-input>
          </div>
        </el-form-item>
        <div class="slider-wrapper" v-if="VueHuakuai == false">
          <VerifySlider :class="[sildesucc ? 'active' : '', 'drag']" @success="successHandler"
                        tips="按住滑块，拖到最右边"></VerifySlider>
        </div>
        <el-form-item v-if="VueHuakuai == true" prop="code">
          <div class="li_input">
            <!-- <img src="@/assets/login/email.png" alt=""> -->

            <el-input v-model="form.code" placeholder="请输入验证码" class="phoneyzm">
              <i slot="prefix" class="baseloginicon iconmima iconfont"></i>
            </el-input>
            <el-button :class="[canClick ? '' : 'active', 'btnsendcode']" @click="getCode()">
              {{ content }}
            </el-button>
          </div>
        </el-form-item>
        <el-form-item prop="passwd">
          <div class="li_input newli-input">
            <!-- <img src="@/assets/login/密码icon.png" alt=""> -->

            <el-input v-model="form.passwd" placeholder="请输入新密码">
              <i slot="prefix" class="baseloginicon iconyanzhengma iconfont"></i>
            </el-input>
          </div>
        </el-form-item>
        <div class="li_input li_input1">
          <el-button type="primary" @click="jump">确定</el-button>
        </div>
<!--        <div class="loginmeg">{{ newmsg }}</div>-->
        <div class="loginitemzc">
          <router-link :to="{name:'login'}">去登录<img src="@/assets/login/nextright.png" alt="">
          </router-link>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import {VerifySlider} from "vue-verify-slider";
import {forgetpw, getRestpwdCode} from "@/api/api";

export default {
  components: {VerifySlider},
  data() {
    return {
      VueHuakuai: false,
      sildesucc: false,
      canClick: true,
      content: "获取验证码",
      totalTime: 60,
      newmsg: '',
      form: {
        mobile: "",
        code: "",
        passwd: ""
      },
      rules: {
        code: [{
          required: true,
          message: "请输入校验码",
          trigger: "blur"
        }],
        mobile: [
          {
            validator: (rule, value, callback) => {
              if (/^1[\d]{10}$/.test(value)) {
                callback();
              } else {
                callback(new Error('请输入正确的手机号码'));

              }
            }, trigger: 'blur'
          }
        ],
        passwd: [{
          validator:(rule, value, callback) => {
            if (/(?!^\d+$)(?!^[A-Za-z]+$)(?!^[^A-Za-z0-9]+$)(?!^.*[\u4E00-\u9FA5].*$)^\S{6,14}$/.test(value)) {
              callback();
            } else {
              callback(new Error('请输入6-14位，字母/数字/标点符号至少2种组合的密码'));

            }
          }, trigger: 'blur'
        }]
      }
    }
  },
  methods: {
    jump1() {
      this.$router.push({name: 'login'})
    },
    //滑动成功回调
    successHandler() {
      this.VueHuakuai = true;
      this.sildesucc = true;
    },
    //获取手机验证码
    isgetCode() {
      if (!this.VueHuakuai) {
        this.newmsg = "请先滑动滑块至最右侧，再获取验证码";
        return false;
      }
      return true;
    },
    jump() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let params = {
            passwd: this.form.passwd,
            code: this.form.code,
            mobile: this.form.mobile
          };
          forgetpw(params).then(data => {
            if (data.msgCode == -1) {
              this.newmsg = data.msgInfo;
            } else {
              this.$router.push({
                name: "login",
              });
            }
          });
        }
      })
    },
    checkTel(tel) {
      return /^1[\d]{10}$/.test(tel)
    },
    getCode: function () {
      //如果验证码不能按直接跳过
      if (!this.canClick) return;

      //验证码发送的请求

      if (!this.checkTel(this.form.mobile)) {
        this.$alert("请输入正确的手机号码")
        return;
      }
      if (!this.isgetCode()) return;

      let param = {phone: this.form.mobile};
      getRestpwdCode(param.phone).then(data => {
        if (data.msgCode !== "1") {
          // this.$alert(data.msgInfo)
          return;
        }
      });
      //获取验证码倒计时
      this.canClick = false;
      this.content = this.totalTime + "s后重发";
      let clock = window.setInterval(() => {
        this.totalTime--;
        this.content = this.totalTime + "s后重发";
        if (this.totalTime < 0) {
          window.clearInterval(clock);
          this.content = "重发验证码 ";
          this.totalTime = 60;
          this.canClick = true;
        }
      }, 1000);
    },
  }
}
</script>
<style lang="stylus" scoped>
.liwjmm {
  height: 33px !important;
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 33px;
  text-align: center !important;
  margin-bottom 30px
}

.slider-wrapper {
  margin-bottom 22px
  width 100%

  >>> .drag {
    width 100%
  }

}

.phoneyzm {
  width: 210px !important;
}

.li_input1 {
  text-align: center !important;
}

.forgetPassword {
  display: flex;
  justify-content: space-evenly;
  width: 960px;
  position: relative;
  box-sizing border-box;
  height: 560px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 0 auto;
  padding-top: 80px;

  .forget_left {
    width: 560px;

    img {
      width: 464px;
      margin-left: 48px;
      margin-top: 32px;
    }
  }

  .right {
    margin: 0 auto;
    width: 400px;

    .forget {
      font-size: 30px;
      color: #2F54EB;
      width: 75%;
      margin: 56px auto 50px auto;
    }

    .form {
      width: 80%;
      margin: 0 auto;

      .li_input {
        position: relative;
        height: 40px;
        text-align left;

        .baseloginicon {
          position: relative;
          left: 8px;
          z-index: 2;
          color: #999;
        }

        >>> .el-input {
          .el-input__inner {
            height: 40px;
            background: rgba(255, 255, 255, 1);
            border-radius: 4px;
            border: 1px solid rgba(217, 217, 217, 1);
            text-indent: 10px;
            padding-left: 30px;
          }
        }

        >>> .drag {
          background: #fafafa;
          width: 100% !important;
          margin: 20px 0 10px 0;
          border-radius: 4px !important;

          &.active {
            .drag_text {
              color: #fff !important;
            }
          }

          .drag_bg {
            background: #2F54EB;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }

          .drag_text {
            font-size: 12px;
            color: #808080;
            border-color: #e6e6e6;
            border: none !important;
          }

          .handler_bg {
            background-image: url('../../../assets/login/loginhk.png');
          }

          .handler_ok_bg {
            background-image: url('../../../assets/login/loginright.png');
          }

          .handler {
            width: 60px;
            height: 40px;
          }

          .handler_ok_bg ~ .drag_text {
            color: #fff !important;
          }
        }

        .btnsendcode {
          position: absolute;
          right: 0px;
          background: #fff;
          border-radius: 4px;
          border: 1px solid #2F54EB;
          color: #2F54EB;
          padding: 12px 16px;

          &:hover, &:focus, &:active {
            background: #fff;
            border-color: #fff;
          }

          &.active {
            // background: #D1D1D1;
            // color: #E6E6E6;
            // border-color: #D1D1D1;
          }
        }


      }

      .li_input1 {
        top: 16px;

        .el-button {
          height: 40px;
          width: 100%;
          border-radius: 4px;
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          padding: 0;
          background: #2F54EB;
          border-color: #2F54EB;
        }
      }
    }
  }
}

.loginmeg {
  margin-top: 8px;
  text-align: center;
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(245, 34, 45, 1);
  line-height: 17px;
}

.loginitemzc {
  margin-top: 23px;
  text-align: center;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(47, 84, 235, 1);
  line-height: 22px;

  span {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: rgba(0, 0, 0, 0.6);
  }

  img {
    width: 24px;
    margin-left: 8px;
  }

  a {
    color: #2F54EB;
  }
}

</style>
