<template>
  <div>
    <div
      class="wrapper"
      v-loading="!dataLoaded"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 0.1)"
    >
      <ToolBar
        @btnSaveClick="handleBtnSaveClick"
        @switchTab="handleSwitchTab"
        v-if="dataLoaded"
      ></ToolBar>
      <template v-if="tabId == 0 && dataLoaded">
<!--        <div class="nestedTree-wrapper">-->
<!--          <perfect-scrollbar>-->
<!--            <div class="nestedTree" v-if="dataLoaded">-->
<!--              <div class="treeTitle-wrapper">-->
<!--                <div class="treeTitle">综合风险评估</div>-->
<!--                <MenuTree-->
<!--                  :data="treeData"-->
<!--                  target="treeData"-->
<!--                  :key="0"-->
<!--                ></MenuTree>-->
<!--              </div>-->
<!--            </div>-->
<!--          </perfect-scrollbar>-->
<!--        </div>-->

        <ResultContent class="resultContent"></ResultContent>
      </template>
      <template v-if="tabId == 1 && dataLoaded">
        <div class="nestedTree-wrapper">
          <perfect-scrollbar>
            <div class="nestedTree" v-if="dataLoaded">
              <div class="treeTitle-wrapper">
                <div class="treeTitle">优惠政策提醒</div>
                <MenuTree
                  :data="discountPolicyTree"
                  target="discountPolicyTree"
                  :key="1"
                ></MenuTree>
              </div>
            </div>
          </perfect-scrollbar>
        </div>

        <DiscountPolicyTab class="resultContent"></DiscountPolicyTab>
      </template>


    </div>
    <VueLoadingOverlay :active.sync="isLoading" ></VueLoadingOverlay>
    <!--联系我们-->
    <ContactUs ref="ContactUs"></ContactUs>
    <!--联系我们-->
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { mapState,mapMutations } from "vuex";
import MenuTree from "./components/MenuTree";
import ToolBar from "./components/ToolBar";
import ResultContent from "./components/ResultContent";

import DiscountPolicyTab from "./components/DiscountPolicyTab";
import VueLoadingOverlay from "vue-loading-overlay";
//在加载的样式
import "vue-loading-overlay/dist/vue-loading.css";
import ContactUs from "@/pages/Result/components/ContactUs";

export default {
  data: function () {
    return {
      data: [],
      tabId: 0,
      isShowLoading:true
    };
  },
  computed: {
    ...mapState("ResultEditor", ["treeData", "discountPolicyTree","isLoading"]),

    dataLoaded() {
      //加载完成
      return Object.keys(this.treeData).length > 0;
      //return !this.isLoading
    },
  },
  components: {
    VueLoadingOverlay,
    MenuTree,
    ToolBar,
    ResultContent,
    ContactUs,
    DiscountPolicyTab,
  },
  async mounted() {
    // const loading = this.$loading({
    //     lock: true,
    //     text: "正在提交数据！",
    //     spinner: "el-icon-loading",
    //     background: "rgba(0, 0, 0, 0.7)",
    //   });
    this.setIsLoading(false);
    await this.loadData();
    setTimeout(()=>{
      window.renderFinished = true
    },1000)
    //this.setIsLoading(false);
  },
  methods: {
     ...mapMutations("ResultEditor",["setIsLoading"]),
    async loadData() {
      await this.$store.dispatch("ResultEditor/loadPDFEditorData", {
        nsrsbh: this.$route.params.nsrsbh,
        uuid: this.$route.params.uuid,
        action: this.$route.params.action,
        isSimple:1
      });
    },
    handleBtnSaveClick() {},
    handleSwitchTab(tabId) {
      this.tabId = tabId;
    },
    // sortedData(data) {
    //   const ret = [];

    //   const sort = data => {
    //     data.forEach((aData, index) => {
    //       if (aData.children) {
    //         data[index].children = sort(aData.children);
    //       }
    //     });

    //     return data.sort((a, b) => a.sort - b.sort);
    //   };

    //   return sort(data);
    // }
  },
  destroyed() {
    this.$store.commit("reset");
  },
};
</script>

<style lang="stylus" scoped>
.wrapper {
  background: #f8fafb;
  padding-top: 61px;
  display: flex;
  min-height: 100vh;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08),
    0px 0px 4px 0px rgba(0, 0, 0, 0.02);
  .nestedTree-wrapper {
    position: fixed;
    width: 260px;

    .ps {
      height: calc(100vh - 61px);
    }
    .nestedTree {
      z-index: 2;

      flex: 0 0 auto;
      .treeTitle-wrapper {
        padding: 24px 0;
        box-sizing: border-box;
        min-height: calc(100vh - 61px);

        padding-right: 5px;
        padding-bottom: 30px;
        .treeTitle {
          padding-left: 35px;

          color: rgba(0, 0, 0, 0.8);
          font-weight: 600;
          line-height: 1.5;
          font-size: 14px;
        }
      }
    }
  }

  .resultContent {
    flex: 1 1 auto;
    margin-left: 0px;
    max-width: 1920px;
    margin: 0 auto;
  }
}
</style>
