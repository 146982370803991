<template>
  <div class="section-box">
    <div class="title">快捷入口</div>
    <div class="shotcuts">
      <router-link :to="item.outUrl" class="item" v-for="(item,index) in utilList" :key="index">
        <img :src="item.imgUrl" class="pic" />
        <div class="text">{{item.name}}</div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    utilList: Array
  }
};
</script>
<style lang="stylus" scoped>
.shotcuts {
  display: flex;
  margin: 26px 0;

  .item {
    margin-right: 102px;
    display: flex;
    align-items: center;
    color: #2F54EB !important;

    .pic {
      margin-right: 10px;
      width: 24px;
    }

    .text {
      font-size: 14px;
      color: #333;
    }
  }

  .item .text:hover {
    color: #2F54EB;
  }
}
</style>