<template>
  <div>
    <div class="msg-title">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ name: 'goods' }"
            >产品中心</el-breadcrumb-item
          >
          <el-breadcrumb-item :to="{ name: 'order' }"
            >我的订单</el-breadcrumb-item
          >
          <el-breadcrumb-item>订单详情</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <div class="qymc">订单详情</div>
    </div>
    <div class="order" v-if="orderList">
      <div class="title">
        订单状态：
        <span v-if="orderList.orderStatus == -1">已取消</span>
        <span v-if="orderList.orderStatus == 2">已支付</span>
        <span v-if="orderList.payType == 0">（支付宝）</span>
        <span v-if="orderList.payType == 1">（微信）</span>
      </div>
      <span class="item">订单编号：{{ orderList.orderNo }}</span>
      <span class="item"
        >创建时间：{{ formatDateTes(orderList.createTime) }}</span
      >
      <span class="item"
        >支付时间：{{ formatDateTes(orderList.updateTime) }}</span
      >
      <div class="version">购买企业：{{ orderList.companyName }}</div>
      <div class="version">版本名称：{{ orderList.goodsName }}</div>
      <div class="version">实际支付：{{ orderList.totalAmount }}元</div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import { findOrderPayStatus } from "../../api/api";
export default {
  components: {
    BreadCrumb,
  },
  data() {
    return {
      status: this.$route.query.status,
      isActive: true,
      id: this.$route.query.id,
      orderList: null,
      goodList: null,
      orderId: null,
      priceDiscount: 0,
      discountMethod: 0,
      satisfy: 0,
      price: 0,
      discounts: 0,
    };
  },
  created() {
    this.vieworder();
  },
  methods: {
    vieworder() {
      let params = {
        orderNo: this.id,
      };
      findOrderPayStatus(params).then((res) => {
        this.orderList = res.data;
      });
    },
    formatDateTes(timestamp) {
      var date = new Date(timestamp);
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + " " + h + m + s;
    },
    sum(arr) {
      return eval(arr.join("+"));
    },
  },
};
</script>
<style lang="stylus" scoped>
.submit-title {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.8);
  padding: 8px 0 0 30px;
  font-weight: 500;
}

.order {
  height: 232px;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  margin: 16px 30px;
  font-size: 14px;
  font-family: ArialMT;
  color: rgba(0, 0, 0, 0.6);
  line-height: 16px;

  .title {
    height: 54px;
    line-height: 54px;
    background: #FFFFFF;
    box-shadow: 0px 1px 0px 0px #EEEEEE;
    border-radius: 8px 8px 0px 0px;
    padding-left: 24px;
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
  }

  .item {
    display: inline-block;
    margin: 24px 80px 34px 25px;
  }

  .version {
    line-height: 20px;
    margin-bottom: 8px;
    margin-left: 25px;
  }
}

.msg-title {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 0px 0px rgba(238, 238, 238, 1);

  .qymc {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    // font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    line-height: 28px;
    margin: 8px 0 0 30px;
  }
}

>>>.bread-crumb-wrapper {
  margin-top: 0;
}
</style>