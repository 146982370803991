import "@babel/polyfill";


import Vue from "vue";
import "@/prototype";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "normalize.css/normalize.css";
import "./styles/global.styl";
import {ValidationProvider, ValidationObserver} from "@/Validator";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VideoPlayer from 'vue-video-player'
import BigPowerTable from "big-power-table"
import Print from './utils/print';
Vue.use(Print);
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);
Vue.use(BigPowerTable)

Vue.use(VideoPlayer);

import './config/rem'
import './config/common.css'

Vue.prototype.$eventBus = new Vue()

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('BigPowerLoading',BigPowerLoading)
Vue.component('BigPowerInput',BigPowerInput)
//滚动条样式美化
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";

Vue.use(PerfectScrollbar);

//滚动跳滚到.
var VueScrollTo = require('vue-scrollto');
// You can also pass in the default options
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: -100,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

import {dateFormat} from "@/filters/date";
//引入iconfont symbol图标
import "./assets/fonts/iconfont";
// 引入drogzone.js样式
import "../node_modules/dropzone/dist/dropzone.css";

import "@/directives/maskers.js";

Vue.use(ElementUI);

Vue.config.productionTip = false;

import 'animate.css';
import {percentage, toFixed, formatText, chineseNumber, padNumber} from "@/utils/format";
import BigPowerLoading from "@/pages/Result/ThreeYearResult/components/BigPowerLoading";
import BigPowerInput from "@/pages/Result/ThreeYearResult/components/BigPowerInput";
//路由守卫鉴权
const whiteList = [
  "login",
  "register",
  "companyApply",
  "forgetPassword",
  "wxlogin",
  "thirdPartAuth",
  "thirdPartRegister",
  "freeEditionIntroduction",
  "resultFromAdmin",
  "AdminUploadedTables",
  "admin-manual-fill-table",
  "result-head",
  "result-share",
  "result-head-share",
  "result-head-middle-page-share",
  "result",
  "result-head-middle-page",
  "SimpleResultEditor",
  "pdfResult",
  "result-head-always",
  "always-result-head-middle-page",
  "always-result",
  "h5-result-always",
  "shandong",
  "h5-result",
  "h5-result-share",
  "result-oem",
  "test-InvoiceUpload-oem",
  "UploadTable-oem",
  "UploadTable-oem-v2",
  "UploadTable-oem-v3",
  "JinzhiReport-share",
  "view-oem-tables",
  "boss-watch-report"
]; //不需要验证的路由name

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem("authorization");
  if (token) {
    next();
  } else {
    if (whiteList.indexOf(to.name) !== -1||1===1) {//有token了 去掉路由白名单
      // 在免登录白名单，直接进入
      next();
    } else {
      //next('/passport/login');
      next({name: "login", query: {redirect: to.path}}); // 否则全部重定向到登录页
    }
  }
});
Vue.filter("timeFormat", function (timeStamp) {
  if (!timeStamp) {
    return "";
  }
  let date = new Date(timeStamp);
  return dateFormat.formatDate(date, "yyyy年MM月d日");
});

Vue.filter("dateFormat", function (timeStamp) {
  if (!timeStamp) {
    return "";
  }
  let date = new Date(timeStamp);
  return dateFormat.formatDate(date, "yyyy年MM月d日");
});

Vue.filter("toFixed", toFixed);
Vue.filter("percentage", percentage);
Vue.filter("formatText", formatText);
Vue.filter("chineseNumber", chineseNumber);
Vue.filter("padNumber", padNumber);

//根据domain设置自定义版本
const debug_customization = ''//调试用

const domain = window.location.hostname.toLowerCase();

if (debug_customization == 'hy.ai-tax.cn' || domain == 'hy.ai-tax.cn') {
  store.commit('setCustomization', {customization: 'hy'})
} else if (debug_customization == 'xr.ai-tax.cn' || domain == 'xr.ai-tax.cn') {
  store.commit('setCustomization', {customization: 'xr'})
} else if (debug_customization == 'nmhz.ai-tax.cn' || domain == 'nmhz.ai-tax.cn') {
  store.commit('setCustomization', {customization: 'nmhz'})
} else {
  store.commit('setCustomization', {customization: ''})
}


(function() {

  if (typeof WeixinJSBridge == "object" && typeof WeixinJSBridge.invoke == "function") {
    handleFontSize();
  } else {
    if (document.addEventListener) {
      document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
    } else if (document.attachEvent) {
      document.attachEvent("WeixinJSBridgeReady", handleFontSize);
      document.attachEvent("onWeixinJSBridgeReady", handleFontSize);  }
  }
  function handleFontSize() {
    // 设置网页字体为默认大小
    WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize' : 0 });
    // 重写设置网页字体大小的事件
    WeixinJSBridge.on('menu:setfont', function() {
      WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize' : 0 });
    });
  }
})();

window.store = store;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

const tableOfContentWithPageNo=[
  {
    "level": 0,
    "title": "1. 企业发票风险分析演示",
    "pageNo": 1
  },
];

setTimeout(()=>{
  store.commit('PdfResult/setToc', tableOfContentWithPageNo);
},1000)

