<template>
  <div class="financialRisk-h5-container">
    <div class="financialRisk-h5">
      <div class="company-baseName">
        <div class="company-tip"></div>
        <div class="company-tip-base">关键财税比率</div>
      </div>
      <div class="table-wrapper">
        <BigPowerTable
          :data="keyFinancialData"
          v-if="keyFinancialData"
          class="table"
        ></BigPowerTable>
        <div class="sc" @click="switchScreen" v-if="keyFinancialData">
          <img src="@/assets/util/hScreen.png" alt="" />
        </div>
        <div
          v-if="!keyFinancialData"
          class="processedTable"
          v-loading="!keyFinancialData"
        ></div>
      </div>
    </div>
    <!-- <div class="full-sc">
      <el-drawer :visible.sync="horizontalScreen" direction="btt" size="100%">
        <div class="full-tab">
          <div class="table-wrapper">
            <BigPowerTable
              :data="keyFinancialData"
              v-if="keyFinancialData"
              class="table"
            ></BigPowerTable>
          </div>
        </div>
      </el-drawer>
    </div> -->

    <div class="full-sc-1" v-if="horizontalScreen">
      <div class="close" @click="close">
        <img src="@/assets/test/part-close.png" alt="" />
      </div>
      <div class="table-wrapper-1">
        <BigPowerTable
          :data="keyFinancialData"
          v-if="keyFinancialData"
          class="table"
        ></BigPowerTable>
      </div>
    </div>
  </div>
</template>

<script>
import BigPowerTable from "big-power-table";
import "@/pages/Result/ThreeYearResult/global.styl";
import KeyTaxRate from "@/pages/Result/ThreeYearResult/financialAnalysis/KeyTaxRate";
import { getKeyFinancialRatios } from "@/api/threeYears";

export default {
  components: {
    KeyTaxRate,
  },
  name: "KeyFinanceRatio",
  async mounted() {
    this.loaded = false;
    await this.loadKeyFinancialRatios();
    this.loaded = true;
    // this.stop();
  },
  data() {
    return {
      activeIndex: "1",
      keyFinancialData: null,
      loaded: false,
      horizontalScreen: false,
    };
  },
  methods: {
    switchScreen() {
      this.$router.push({ path: "../keyFinancialDataHp/"+this.$route.params.uuid });
      // this.horizontalScreen = true;
      // this.stop();
    },
    async loadKeyFinancialRatios() {
      const res = await getKeyFinancialRatios({
        programId: this.$route.params.uuid,
      });
      let keyFinancialData = res.data;
      this.keyFinancialData = {};
      this.keyFinancialData.header = keyFinancialData.data[0].data.header;
      this.keyFinancialData.body = keyFinancialData.data[0].data.body.concat(
        keyFinancialData.data[1].data.body
      );
    },
    /**
     * 顶部菜单点击
     * @param {string} key
     * @param {array<string>}keyPath
     */
    handleTopMenuSelect(key, keyPath) {
      this.activeIndex = key;
    },
    stop() {
      var mo = function (e) {
        e.preventDefault();
      };
      // document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", mo, false); //禁止页面滑动
    },
    /***取消滑动限制***/
    move() {
      var mo = function (e) {
        e.preventDefault();
      };
      // document.body.style.overflow = ""; //出现滚动条
      document.removeEventListener("touchmove", mo, false);
    },
    close() {
      this.move();
      this.horizontalScreen = false;
    },
  },
};
</script>

<style lang="stylus" scoped>
.financialRisk-h5-container
  border: 2px solid #6AA1FF;
  border-style: dashed;
  margin: 0.4rem;
  padding: 0.24rem;
  border-radius: 4px;

.financialRisk-h5
  .company-baseName
    display: flex;
    align-items: center;
    color: #1D2129;
    font-size: 0.32rem;
    font-weight: 600;
    line-height: 0.33rem;
    margin-bottom: 0.16rem;

    .company-tip
      width: 0.12rem;
      height: 0.24rem;
      background: #2F54EB;
      margin-right: 0.1rem;

    .company-tip-base
      line-height: 0.33rem;

  >>> table.table th, >>> table.table td {
    font-size: 0.2rem;
  }

.table-wrapper
  background: #FFFFFF;
  position: relative;

  .sc {
    position: absolute;
    right: 0px;
    bottom: 0px;
    padding: 5px;

    img {
      width: 0.25rem;
      height: 0.25rem;
      background: #FFFFFF;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
      border-radius: 0.08rem;
    }
  }

  // .table {
  //   >>> .bigPowerTable {
  //     max-height 4.04rem
  //     overflow auto
  //   }
  // }

.full-sc {
  .full-tab {
    transform-origin: center center;
    transform: rotateZ(90deg);
  }
  >>> .el-drawer__header {
    padding: 0;
  }
  >>> .el-drawer__body {
    overflow: hidden;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  >>> .el-drawer__open .el-drawer.btt {
    border-radius: 0;
  }
  >>> .el-drawer__close-btn {
    font-size: 30px;
    position: relative;
    right: 10px;
    top: 10px;
  }
  .table {
    >>> .bigPowerTable {
      max-height 5.8rem
      max-width 85vh
      overflow auto
    }
    >>> table.table th, >>> table.table td {
      font-size: 0.2rem;
    }
  }
}

.processedTable {
  width: 5rem;
  margin: 0 auto;
  height: 500px;
}

.full-sc-1 {
  position: fixed;
	width: 100vh;
	height: 100vw;
	top: 0;
	left: 100vw;
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
	transform-origin: 0% 0%;
  background: #fff;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  .close {
    position: absolute;
    top: 10px;
    left: 10px;

    img {
      padding: 10px;
      width: 15px;
      height: 15px;
    }
  }

  .table-wrapper-1 {
    width: 10rem;
    max-height: 5.8rem;
    overflow: auto;
  }
}
</style>
