<template>
  <!-- <div class="financialRisk-h5-container">
    <div class="PurchaseAndSale-h5">
      <UpDownCustomerNetWork type="2"></UpDownCustomerNetWork>
    </div>
  </div> -->
  <div class="full-sc-1-com1" v-if="companyInfo.taxNo">
    <div class="close" @click="close">
      <img src="@/assets/test/part-close.png" alt="" />
    </div>
    <div class="table-wrapper-1">
      <PurchaseAndSaleCategoryCompare
        :taxNo="companyInfo.taxNo"
        :zq="companyInfo.zq"
        :type="1"
        :hp="2"
      ></PurchaseAndSaleCategoryCompare>
    </div>
  </div>
</template>

<script>
import "@/pages/Result/ThreeYearResult/global.styl";
import PurchaseAndSaleMoney from "@/pages/Result/H5FinancialAnalysis/purchaseAndSale/PurchaseAndSaleMoney";
import { getCompanyInfo } from "@/api/threeYears";
import PurchaseAndSaleCategoryCompare from "@/pages/Result/H5FinancialAnalysis/purchaseAndSale/PurchaseAndSaleCategoryCompare";
export default {
  components: {
    PurchaseAndSaleMoney,
    PurchaseAndSaleCategoryCompare,
  },
  name: "PurchaseAndSale",
  data() {
    return {
      activeIndex: "1",
      loaded: false,
      companyInfo: {},
    };
  },
  async mounted() {
    this.loaded = false;
    await this.loadCompanyInfo();
    this.loaded = true;
  },
  methods: {
    close() {
      this.$router.back();
    },
    async loadCompanyInfo() {
      const res = await getCompanyInfo({ programId: this.$route.params.uuid });
      this.companyInfo = res.data;
    },
    /**
     * 顶部菜单点击
     * @param {string} key
     * @param {array<string>}keyPath
     */
    handleTopMenuSelect(key, keyPath) {
      this.activeIndex = key;
    },
  },
};
</script>

<style lang="stylus" scoped>
.full-sc-1-com1 {
  position: fixed;
  width: 100vh;
  height: 100vw;
  top: 0;
  left: 100vw;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  transform-origin: 0% 0%;
  background: #fff;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  >>> .table .bigPowerTable {
    max-height: 5.8rem;
    overflow: auto;
    max-width: 12rem;
  }

  >>> table.table th, >>> table.table td {
    font-size: 0.2rem;
  }

  .close {
    position: absolute;
    top: 10px;
    left: 10px;

    img {
      padding: 10px;
      width: 15px;
      height: 15px;
    }
  }

  .table-wrapper-1 {
    // width: 10rem;
    // height: 5.8rem;
    // overflow: auto;
  }
}
</style>
