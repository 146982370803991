<template>
  <div id="tableListId">

    <el-tabs v-model="activeName" @tab-click="handleClick" v-if="tables">
      <el-tab-pane
        :label="`${group}(${table.uploadNum}/${table.totalNum})`"
        :name="index.toString()"
        v-for="(table, group, index) in filtedTables"
        :key="group"

      >

        <el-table
          :data="table.list"
          row-key="id"
          :default-sort="{ prop: 'status', order: 'ascending' }"
          style="width: 100%;margin-top: 5px"
          :tree-props="{children: 'children'}"
          v-if="table.uploadNum != '余额表'"
        >
          <el-table-column width="24"> </el-table-column>
          <el-table-column
            type="index"
            label="序号"
            width="80"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="报表名称"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope"
            >{{ scope.row.tableNum }} {{ scope.row.name }}
            </template
            >
          </el-table-column>
          <el-table-column prop="status" label="状态" width="180" sortable>
            <template slot-scope="scope" v-if="scope.row.children.length==0">
              <span v-if="scope.row.status === '已上传'"
              ><span class="circle"></span><span>已上传</span></span
              >
              <span
                v-else-if="scope.row.status === '待修改'"
                class="status-error"
              ><span class="circle circle-eidt"></span
              ><span>待修改</span></span
              >
              <span v-else
              ><span class="circle circle-no"></span
              ><span>{{ scope.row.status }}</span></span
              >
            </template>
          </el-table-column>
          <el-table-column label="操作" width="130">
            <template slot-scope="scope" v-if="scope.row.children.length==0">
              <template v-if="scope.row.status === '已上传'">
                <router-link
                  :to="{
                    name: manualTableRouteName,
                    params: { templateID: scope.row.templateId, from: manner,productID: product.id? product.id:191,productName: product.name||'无',zqLocation },
                    query: {
                      tableName: scope.row.name,
                      zq: scope.row.zq,
                      productID: product.id? product.id:191,
                      productName: product.name,
                      bankID: product.bankID,
                      activeName: activeName,
                      redirectTo: $route.fullPath
                    },
                  }"
                  class="btn btn-view-edit"
                  v-if="scope.row.name.indexOf('余额表') == -1"
                >
                  <i class="el-icon-edit-outline"></i>
                </router-link>
                <span
                  class="btn btn-view-edit"
                  @click="showYeb(scope.row.templateId, scope.row.zq)"
                  v-if="scope.row.name.indexOf('余额表') != -1"
                >
                  <i class="el-icon-edit-outline"></i>
                </span>
                <button
                  class="btn btn-remove"
                  @click="handleBtnRemove(scope.row.name, scope.row.templateId,scope.row.zq)"
                >
                  <i class="el-icon-delete"></i>
                </button>
              </template>
              <template v-else-if="scope.row.status === '待修改'">
                <router-link
                  :to="{
                    name: manualTableRouteName,
                    params: { templateID: scope.row.templateId, from: manner,productID: product.id? product.id:191,productName: product.name||'无',zqLocation },
                    query: {
                      tableName: scope.row.name,
                      zq: scope.row.zq,
                      productID: product.id,
                      productName: product.name,
                      bankID: product.bankID,
                      redirectTo: $route.fullPath,
                    },
                  }"
                  class="btn btn-edit"
                >
                  <i class="el-icon-edit-outline"></i>
                </router-link>
                <button
                  class="btn btn-remove"
                  @click="handleBtnRemove(scope.row.name, scope.row.templateId,scope.row.zq)"
                >
                  <i class="el-icon-delete"></i>
                </button>
              </template>
              <template v-else>
                <router-link
                  :to="{
                    name: manualTableRouteName,
                    params: { templateID: scope.row.templateId, from: manner,productID: product.id? product.id:191,productName: product.name||'无',zqLocation },
                    query: {
                      tableName: scope.row.name,
                      zq: scope.row.zq,
                      productID: product.id,
                      productName: product.name,
                      bankID: product.bankID,
                      activeName: activeName,
                      redirectTo: $route.fullPath,
                    },
                  }"
                  class="btn btn-view-edit"
                  v-if="scope.row.name.indexOf('余额表') == -1"
                >
                  <i class="el-icon-edit-outline"></i>
                </router-link>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {deleteTable} from "@/api/table";
import {mapState} from "vuex";
import {cloneDeep} from "lodash";

export default {
  props: {
    tables: Object,
    manner: [String],
    backTableIndex: [Number, String]
  },
  data() {
    return {

      activeName: "0",
    };
  },
  async mounted() {
    if (this.$route.query.activeName) {
      this.activeName = this.$route.query.activeName;
    }
    await this.$store.cache.dispatch("loadCompanyInfo");
    // if(this.backTableIndex) {
    this.activeName = sessionStorage.getItem("activeNameBack") || "0"
    // }
  },
  computed: {
    ...mapState("UploadTable", ["zq", "product", "SDSSelection"]),
    ...mapState(["companyInfo", "authorization"]),
    zqLocation() {
      return this.$route.params['zqLocation'] || this.$route.params.zq
    },
    manualTableRouteName() {
      return this.manner == 'readonly' ? "admin-manual-fill-table" : "manual-fill-table"
    },
    filtedTables() {
      const tables = cloneDeep(this.tables);
      const sdsTables = tables.所得税数据;
      if (sdsTables) {
        if (this.SDSSelection == null) {
          tables.所得税数据.list = [];
        } else {
          tables.所得税数据.list = sdsTables.list.filter((table) => {
            return (
              table.status !== "未上传" ||
              this.findInSDStablesByTemplateId(table.templateId)
            );
          });
        }
      }

      return tables;
    },
  },
  methods: {

    showYeb(id, zq) {
      this.$emit("showDetailBalance", id, zq);
    },
    //是否已经被选中显示的所得税表格
    findInSDStablesByTemplateId(templateId) {
      return this.SDSSelection.find((SDStable) => {
        return SDStable.templateId == templateId;
      });
    },
    handleClick(tab, event) {
      // console.log(tab, event);
      // if(this.backTableIndex) {
      sessionStorage.setItem("activeNameBack", tab.index);
      // }
    },
    //删除文件
    handleBtnRemove(filename, templateId, zq) {
      const _this = this;
      this.$confirm(`${filename} 将被删除, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          //   _this.companyInfo.nsrsbm,
          //   _this.zq,
          //   templateId,
          //   _this.authorization
          //   console.log(_this.authorization);
          // debugger;

          const result = await deleteTable(
            _this.companyInfo.nsrsbm,
            zq,
            templateId,
            _this.authorization,
            this.$route.params.uuid
          );

          if (result.code == 200) {
            _this.$emit("listChanged");
            _this.$message({
              type: "success",
              message: "删除成功!",
            });
          } else {
            _this.$message.error(result.message);
          }
        })
        .catch(() => {
        });
    },
  },
};
</script>

<style lang="stylus" scoped>
.scroll-area {
  width: 100%;
  height: 400px;
}

>>> .el-tabs__nav-wrap::after {
  background: #fff
}

>>> .el-tabs__item.is-active {
  color: rgba(47, 84, 235, 1)

}

>>> .el-tabs__active-bar {
  background-color: rgba(47, 84, 235, 1)
}

.btn-view-edit {
  text-align: center;
  width: auto;
  color: #2f54eb;
}

.btn-view-edit:hover {
  color: rgb(10, 55, 238);

  text-decoration: underline;
}

.btn-remove {
  margin-left: 10px;
  color: rgba(0, 0, 0, 0.4);
}

.btn-remove:hover {
  color: rgba(0, 0, 0, 0.6);
  text-decoration: underline;
}

.btn-edit {
  /* text-align: center;
  height: 24px;
  line-height: 24px;
  display: inline-block;
  border-radius: 15px;
  padding: 0 10px;
  width: 80px;
  color: #fff;
  background: #ff6464; */
}

.btn-edit:hover {
  /* background: rgb(252, 77, 77); */
}

.status-success {
  color: #53dc91;
}

.status-error {
  color: #ff6464;
}

>>> .el-tabs__nav-scroll {
  display: flex;
  justify-content: flex-start;
  position: relative;
  left: 35px;
}

>>> .el-tabs__item {
  padding: 0 30px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.4000);
}

#tableListId >>> .btn {
  padding: 0;
}

.el-icon-edit-outline {
  color: #2f54eb;
  font-size: 18px;
  margin-left: 4px;
}

.el-icon-delete {
  font-size: 18px;
  margin-left: 24px;
}

>>> .el-tabs__header {
  margin: 0;
}

.circle {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #52c41a;
  border-radius: 50%;
  margin-right: 8px;
}

.circle-no {
  background: #d9d9d9;
}

.circle-eidt {
  background: #f5222d;
}
</style>
