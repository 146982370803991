<template>
  <div>
    <div class="title-content">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/tools/toolsAudit' }"
            >小工具</el-breadcrumb-item
          >
          <el-breadcrumb-item :to="{ path: '/tools/toolsAudit' }"
            >尽调报告</el-breadcrumb-item
          >
          <el-breadcrumb-item>发起检测</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <main-header>
        <template slot="title">尽调报告</template>
      </main-header>
    </div>
    <div class="containerSuccess" v-show="authorSuccess">
      <img class="invoice-img" src="@/assets/result/no-invoice.png" alt />
      <div class="invoice-text">检测报告生成需T+1个工作日完成，请耐心等待</div>
      <div class="invoice-upload">报告生成后可在“检测记录”内查看下载</div>
      <div @click="backIndex" class="backIndex">返回检测记录</div>
    </div>
    <div class="iframe-container" v-show="!authorSuccess">
      <iframe class="iframe" ref="iframe" :src="baseUrl"></iframe>
    </div>
  </div>
</template>

<script>
// import { getCustomListNew, resultSuccess } from "@/api/api";
import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "@/pages/components/MainHeader";

export default {
  components: {
    MainHeader,
    BreadCrumb,
  },

  data() {
    return {
      //   taxNo: this.$route.query.taxNo,
      taxNo:
        "T3BIcGVnVW54ZCswd2R3TC95UXVBdEVvOGZNNDg0UWhLVXVWc3EwZjkyWVJXWGdLVzFhbzU5Q1RVc1hnSXpyOGpZa2lzVnQ1V0pFOTl3RXZwNUUvY05hY3VqMU9vQ3p5Wk54NmFPeHpONFZCOXY4K09RdDRnVExvMXBrVVg2RFBrdlpvWWhOVGwxM0p6RUI2UkN5bmNRPT0%3D",
      baseUrl: "",
      authorSuccess: false,
      openIframe: false,
      haveTotal: true,
      dataList: null,
      hyList: [],
      totalNumber: 10, //总数
      current: {},
      currentEdit: {},
      input: "",
      isEdit: false,
      dialogTableVisible: false,
      totalElements: 0,
      pageno: 0,
      filter: {
        name: "",
        status: "",
        time: [],
        source: "",
        salesman: "",
        industry: "",
        contactNumber: "",
        contact: "",
      },
      showDetail: false,
      loading: true,
      tableDataList: null,
      customItem: {},
      conversionArea: "",
      cmd: false,
    };
  },

  mounted() {
    // this.taxNo =
    //   "T3BIcGVnVW54ZCswd2R3TC95UXVBdEVvOGZNNDg0UWhLVXVWc3EwZjkyWVJXWGdLVzFhbzU5Q1RVc1hnSXpyOGpZa2lzVnQ1V0pFOTl3RXZwNUUvY05hY3VqMU9vQ3p5Wk54NmFPeHpONFZCOXY4K09RdDRnVExvMXBrVVg2RFBrdlpvWWhOVGwxM0p6RUI2UkN5bmNRPT0%3D";
    this.taxNo = this.$route.query.taxNo;
    var callbackUrl = "http%3A%2F%2Ftest.firm.ai-tax.cn%2Ftools%2FanalysisSuccess";
    // console.log(process.env);
    // console.log(process.env.VUE_APP_API_ROOT_Callback);
    callbackUrl = process.env.VUE_APP_API_ROOT_Callback;
    // if (process.env.NODE_ENV == "development") {
    //   callbackUrl = "http%3A%2F%2Fdev.firm.ai-tax.cn%2Ftools%2FanalysisSuccess";
    // } else if (process.env.NODE_ENV == "test") {
    //   callbackUrl = "http%3A%2F%2Ftest.firm.ai-tax.cn%2Ftools%2FanalysisSuccess";
    // } else {
    //   callbackUrl = "http%3A%2F%2Ff.ai-tax.cn%2Ftools%2FanalysisSuccess";
    // }

    var callbackTaxNo = process.env.VUE_APP_API_ROOT_CallbackTaxNo;

    this.baseUrl =
      "https://www.wfq2020.com/auth/#/authorization?webAuthorizeChannelCode=" +
      callbackTaxNo +
      "&cburl=" +
      callbackUrl +
      "&message=" +
      this.taxNo;
    // this.getcustomList();
    window.addEventListener("message", (e) => {
      this.cmd = e.data.cmd;
      if (this.cmd) {
        this.$router.push({ name: "toolsAudit" });
        // this.authorSuccess = true;
        // let params = {
        //   encryptionString: this.taxNo,
        // };
        // resultSuccess(params).then((res) => {
        //   console.log(res);
        // });
      }
    });
  },

  methods: {
    backIndex() {
      console.log(this);
      this.$router.push({ name: "toolsAudit" });
    },
    addTest() {
      this.$router.push({ name: "authorization" });
      //   this.openIframe = !this.openIframe;
    },
    // 翻页
    handleCurrentChange(val) {
      this.pageno = val - 1;
      this.getcustomList();
    },
    // 搜索
    search() {
      this.pageno = 0;
      this.getcustomList();
    },
    changeName() {
      if (!this.filter.name) {
        this.pageno = 0;
        this.getcustomList();
      }
    },
    changeContact() {
      if (!this.filter.contact) {
        this.pageno = 0;
        this.getcustomList();
      }
    },
    changeNumber() {
      if (!this.filter.contactNumber) {
        this.pageno = 0;
        this.getcustomList();
      }
    },
    changeSalesman() {
      if (!this.filter.salesman) {
        this.pageno = 0;
        this.getcustomList();
      }
    },
    // 获取客户列表
    getcustomList() {
      let params = {
        companyName: this.filter.name.trim(),
        industry: this.filter.industry,
        contact: this.filter.contact.trim(),
        contactNumber: this.filter.contactNumber.trim(),
        startTime: this.filter.time ? this.filter.time[0] : "",
        endTime: this.filter.time ? this.filter.time[1] : "",
        salesman: this.filter.salesman.trim(),
        pageSize: 10,
        pageNo: this.pageno,
      };
      getCustomListNew(params, localStorage.getItem("authorization")).then((res) => {
        if (res.data.msgCode == 1) {
          this.tableDataList = res.data.data.content;
          this.totalNumber = res.data.data.totalElements;
        } else {
          this.$message({
            showClose: true,
            message: res.msgInfo,
            type: "error",
          });
        }
      });
    },
  },

  watch: {
    message(newVal, oldVal) {
      console.log(newVal);
      console.log(oldVal);
    },
  },
};
</script>

<style lang="stylus" scoped>
.el-input .el-input__count {
  color: rgba(0, 0, 0, 0.2);
}

>>> .el-input__inner {
  height: 36px;
  line-height: 36px;
}

.content-body {
  margin: 20px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  padding: 0 10px;
}

.filter-body {
  margin: 20px;
  padding: 24px 0 8px 24px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  .inputs-wrapper {
    width: 92%;

    .inputs-wraper-row {
      display: flex;
      flex-wrap: wrap;

      .form-item {
        margin-right: 30px;
        margin-bottom: 16px;
        display: flex;
        align-items: center;

        .label {
          white-space: nowrap;
          width: 69px;
          text-align: right;
          margin-right: 8px;
        }

        .labelCode {
          width: 100px;
        }

        display: flex;
      }

      .form-itemCode {
        width: 336px;
      }
    }
  }

  .btn-look-up {
    margin-right: 16px;
    width: 76px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    background: #2F54EB;
    border-radius: 4px;
    font-size: 14px;
    color: #FFFFFF;
  }
}

.btn-look-up:hover {
  cursor: pointer;
  background: rgba(47, 34, 235, 1);
}

.addItem {
  width: 104px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(47, 84, 235, 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  margin-left: 20px;
  cursor: pointer;
  display: inline-block;
  margin-right: 16px;
}

.addItem:hover {
  background: rgba(47, 34, 235, 1);
}

.ease {
  transition: all 0.3s;
}

>>> .form-item .el-input__icon {
  line-height: 36px;
  height: 36px;
}

.el-icon-edit-outline {
  color: #2f54eb;
  font-size: 18px;
  margin-left: 4px;
}

.title-content {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
}

>>>.bread-crumb-wrapper {
  margin-top: 0;
}

.pagination-low {
  float: right;
  margin-right: 30px;
  margin-bottom: 20px;
}

>>>.el-select {
  width: 240px;
}

>>>.el-input {
  width: 240px;
}

>>>.el-range-editor.el-input__inner {
  width: 240px;
}

>>>.el-date-editor .el-range-separator {
  line-height: 28px;
}

>>>.el-pagination__editor.el-input {
  width: 50px;
}

>>>.bread-crumb-wrapper {
  padding: 0;
  line-height: 20px;
  padding: 16px 0 16px 30px;
}

>>>.el-date-editor .el-range__icon {
  margin-left: 0;
  margin-right: 6px;
}

>>>.el-input__icon {
  width: 14px;
}

>>>.el-range-editor.el-input__inner {
  padding: 3px 8px;
}

>>>.el-date-editor .el-range-separator {
  line-height: 29px;
}

>>>.form-item .el-input__icon {
  line-height: 38px;
}

.containerSuccess {
  text-align: center;
  position: fixed;
  background: #fff;
  z-index: 9999;
  top: 156px;
  left: 186px;
  right: 4px;
  bottom: 0;
}

.iframe-container {
//   position: fixed;
//   top: 80px;
//   left: 50%;
//   margin-left: -300px;
//   width: 600px;
  height: 800px;
  padding: 8px 8px;
  z-index: 9998;

  .iframe {
    width: 100%;
    height: 100%;
  }
}

.iframeImg {
  position: fixed;
  right: 500px;
  top: 60px;
  width: 50px;
  height: 50px;
  z-index: 9999;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
}

.export {
  cursor: pointer;
  color: #2f22eb;
  margin-right: 8px;
}

.backIndex {
  width: 120px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: #2f54eb;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 10px;
}

.invoice-img {
  margin-top: 80px;
}

.invoice-text {
  margin-top: 24px;
  font-size: 18px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.invoice-upload {
  margin-top: 12px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}
</style>
