<template>
  <div class="wrapper">
    <div class="chart-wrapper">
      <PieChart
        :colors="colors"
        :data="chartData"
        :key="JSON.stringify(chartData)"
      ></PieChart>
    </div>

    <div class="legends">
      <div class="legend" v-for="(item, index) in chartData" :key="index">
        <div class="title">
          <div class="box" :style="{ background: colors[index % colors.length] }"></div>
          <div class="item-text">
            {{ item.name }}
          </div>
        </div>
        <div class="value">
          {{ (Math.round(item.value * 100 * 10000) / 10000).toFixed(2) }}%
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PieChart from "./PieChart";

export default {
  name: "pie",
  props: {
    data: Object,
    height: {
      type: String,
      default: "362px",
    },
    colors: {
      type: Array,
      default() {
        return [
          "#EC5757",
          "#BCA88D",
          "#2588ED",
          "#F7A659",
          "#95A5CD",
          "#FF409A",
          "#B8D4F9",
          "#ABDE8A",
          "#EED9BE",
          "#6293F8",
          "#878787",
        ];
      },
    },
  },
  components: {
    PieChart,
  },
  computed: {
    chartData() {
      const ret = [];
      for (const i in this.data.xLabels) {
        const label = this.data.xLabels[i];
        const value = this.data.series[0].data[i];
        ret.push({ name: label, value });
      }
      return ret;
    },
  },
};
</script>

<style lang="stylus" scoped>
.wrapper {
  // display: flex;
  width: 100%;
  padding: 0 0.55rem;

  .chart-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .legends {
    flex-shrink: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    font-size 0.2rem

    .legend {
      width: 100%;
      color: #666;
      display: flex;
      justify-content: space-between;
      vertical-align: top;
      margin-top 0.24rem;
      align-items: center;

      .box {
        display: inline-block;
        width: 0.28rem;
        height: 0.28rem;
        background: red;
        margin-right: 0.05rem;
      }

      .title {
        vertical-align: top;
        display: flex;
        align-items: center;
        font-size: 0;

        .item-text {
          font-size 0.2rem
        }
      }

      .value {
        vertical-align: top;
      }
    }
  }
}
</style>
