<template>
  <div>
    <div ref="chartColumn" class="chartColumn"></div>
  </div>
</template>

<script>
// 进销项
import { mapState } from 'vuex';
import echarts from 'echarts';

export default {
  props: {
    data: Object,
    width: String,
    height: String,
  },
  async mounted() {
    this.showMap();
  },
  methods: {
    // echart
    showMap() {
      if (this.width) {
        this.$refs.chartColumn.style.width = this.width;
      }

      if (this.height) {
        this.$refs.chartColumn.style.height = this.height;
      }

      this.chartColumn = echarts.init(this.$refs.chartColumn,null, {devicePixelRatio:4,renderer:'svg'});

      const option = {
        tooltip: {
          trigger: 'axis',
          formatter: `{a0}:{c0}${this.unit}`,
        },
        legend: {
          x: 'center',
          bottom: '0%',
          data: this.headers,
        },
        grid: {
          left: '3%',
          right: '5%',
          top: '10%',
          bottom: '20%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.data.xLabels,
          splitLine: {
            show: true,
            lineStyle: {
              color: '#EEEEEE',
              width: 1,
              type: 'solid',
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#666',
            },
          },
          axisLabel: {
            // rotate: 35,
            interval: 0,
            textStyle: {
              fontSize: 11,
              fontFamily: 'PingFangSC-Regular,PingFang SC',
              color: 'rgba(0,0,0,0.6)',
            },
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#666',
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#EEEEEE',
              width: 1,
              type: 'dashed',
            },
          },
          axisLabel: { show: true, formatter: `{value} ${this.unit}` },
        },
        series: this.series,
      };
      this.chartColumn.setOption(option);
    },
  },
  computed: {
    ...mapState(['Result']),
    unit() {
      return this.data.unit || '万元';
    },
    headers() {
      return this.data.series.map((value) => value.name);
    },
    series() {
      const ret = [];

      const colors = ['#2F54EB', '#FAAD14', '#52C41A'];

      for (let headersIndex = 0; headersIndex < this.headers.length; headersIndex++) {
        const headerName = this.headers[headersIndex];
        const color = colors[colors.length - headersIndex - 1];

        const { data } = this.data.series[headersIndex];

        const theSeries = {
          name: headerName,
          type: 'line',
          data,
          color,
          symbol: 'circle',
          symbolSize: 7,
        };
        ret.push(theSeries);
      }
      return ret;
    },
  },
};
</script>

<style lang="stylus" scoped>
.chartColumn {
  width: 533px;
  height: 300px;

  >>> div:first-child {
    width: 100% !important;
  }
}
</style>
