<template>
  <div>
    <div class="msg-title">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ name: 'homePage' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>产品购买</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <div class="qymc">产品购买</div>
    </div>
    <div v-if="versionList.length">
      <div class="product-version-c">
        <div class="product-version" v-for="(item, index) in versionList" :key="index">
          <div class="product-version-t" :style="{ background: bgColors[index] }">
            <el-select
              class="product-select"
              v-model="contactUsId"
              placeholder="请选择"
              v-if="item.contact_us"
              @change="itemChange"
            >
              <el-option
                v-for="item in contact_us"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <div class="product-version-title" :class="{ 'icon-right': item.contact_us }">
              {{ item.name }}
              <i class="el-icon-caret-bottom" v-if="item.contact_us"></i>
            </div>
            <div class="product-version-title2">{{ item.remark }}</div>
          </div>
          <div class="introductions">
            <template v-if="versionList[index].introduce">
              <div
                class="version-flex"
                v-for="(citem, index) in versionList[index].introduce.split('\n')"
                :key="index"
              >
                <div class="version-flexdiv" v-if="citem">- {{ citem }} -</div>
              </div>
            </template>
          </div>

          <div class="version-flex price-wrapper">
            <div class="version-price">
              <span v-if="!item.contact_us">￥</span>
              <span class="version-pricespan">{{ item.price }}</span>
            </div>
            <button
              class="btn concatUs btn-buy ease"
              @click="conTactUs"
            >
              联系我们
            </button>
          </div>
        </div>
      </div>
    </div>
    <pro-introduction
      class="pro-introduction"
      :proIntroduction="proIntroduction"
      :proIntroductionTr="proIntroductionTr"
      v-if="proIntroduction"
    ></pro-introduction>
    <pay-toast
      v-if="payToast"
      @close="payToast = false"
      :buyItem="buyItem"
      :orderId="orderId"
    ></pay-toast>
    <concact-toast v-if="conactUs" @close="conactUs = false"></concact-toast>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import payToast from "./components/payToast";
import concactToast from "./components/concactUs";
import proIntroduction from "./components/proIntroduction";
import { getVersion, createBuyOrder, findEdition } from "../../api/api";

export default {
  components: {
    BreadCrumb,
    payToast,
    proIntroduction,
    concactToast,
  },
  data() {
    return {
      bgColors: [
        "linear-gradient(90deg, #5C8DF7 0%, #2F54EB 100%)",
        "linear-gradient(90deg, #FC7D54 0%, #FB4A3C 100%)",
        "linear-gradient(270deg, #3F00FF 0%, #0091FF 100%)",
        "linear-gradient(90deg, #3F00FF 0%, #BE61F2 100%)",
        "linear-gradient(90deg, #FCE254 0%, #FB963C 100%)",
      ],
      versionList: [],
      version: "",
      payToast: false,
      buyItem: {},
      orderId: "",
      findEditionId: null,
      proIntroduction: null,
      contactUsIndex: 0,
      contactUsId: "",
      findUsEditionId: [],
      contact_us: null,
      conactUs: false,
    };
  },
  created() {
    this.allversion();
    // console.log(this.$store.state);
  },
  methods: {
    conTactUs() {
      this.conactUs = true;
    },
    groupArr(array, subGroupLength) {
      let index = 0;
      let newArray = [];
      while (index < array.length) {
        newArray.push(array.slice(index, (index += subGroupLength)));
      }
      return newArray;
    },
    getEdition() {
      findEdition(this.findEditionId).then((res) => {
        this.proIntroduction = res.data;
        this.proIntroductionTr = this.groupArr(
          this.proIntroduction.body,
          this.proIntroduction.header.length
        );
      });
    },
    openingVersion(item) {
      this.buyItem = item;
      let params = {
        goodsCount: 1,
        goodsId: this.buyItem.id,
        token: JSON.parse(localStorage.getItem("token")).token,
      };
      createBuyOrder(params).then((res) => {
        if (res.msgCode != 1) return;
        this.orderId = res.data;
        this.payToast = true;
      });
    },
    // 所有版本信息
    allversion() {
      let params = {
        showChannel: 0,
      };
      getVersion(params).then((res) => {
        this.versionList = res.data.goods;
        this.findUsEditionId = res.data.contact_us.map((item) => item.id);
        this.contact_us = this.addIndex(res.data.contact_us);
        if (this.contact_us && this.contact_us.length > 0) {
          this.versionList = this.versionList.concat(
            this.contact_us[this.contactUsIndex]
          );
        }
        this.findEditionId = this.versionList.map((item) => item.id);
        this.getEdition();
      });
    },
    addIndex(data) {
      for (let item in data) {
        data[item].contact_us = 1;
      }
      return data;
    },
    itemChange() {
      var index = this.findUsEditionId.findIndex((item) => {
        return item == this.contactUsId;
      });
      this.contactUsIndex = index;
      this.allversion();
    },
  },
};
</script>
<style lang="stylus" scoped>
.header-wrapper {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 0px 0px rgba(238, 238, 238, 1);

  .submit-title {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.8);
    padding: 8px 0 0 30px;
    font-weight: 500;
  }
}

.pro-introduction {
  // float: left;
  margin: 0 30px;
}

.product-version-c {
  margin: 0 auto;
  margin-left: 30px;
  // display: flex;
  // width: 94%;
  // flex-wrap: wrap;
  text-align: center;

  .product-version {
    vertical-align: top;
    display: inline-block;
    margin-top: 24px;
    position: relative;
    width: 288px;
    height: 590px;
    flex-shrink: 0;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    margin-right: 14px;

    .product-version-t {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: 100px;
      background: rgba(24, 144, 255, 1);
      border-radius: 8px 8px 0px 0px;

      .product-select {
        opacity: 0;
        position: absolute;
        top: 30px;
        left: 45px;
      }

      .icon-right {
        margin-left: 12px;
      }

      .product-version-title {
        cursor: pointer;
        font-size: 24px;
        color: #ffffff;
        margin-bottom: 7px;

        .icon-right {
          position: relative;
          left: 12px;
        }
      }

      .product-version-title2 {
        color: #fff;
        font-size: 14px;
      }
    }
  }
}

.price-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;

  .version-price {
    margin-top: 24px;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
    text-align center
  }

  .version-pricespan {
    font-size: 32px;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
  }

  .version-price2 {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.4);
    margin: 2px 0 24px 22px;
  }

  .btn-buy {
    padding: 8px 0;
    margin: 16px auto 24px;
    width: 240px;
  }

  .concatUs {
    background: #2F54EB;
    color: #fff;
    border: 1px solid #2f54eb;
    border-radius: 6px;
    box-sizing: border-box;
  }
}

.introductions {
  margin-top: 24px;

  .version-flex {
    display: flex;
    align-items: left;
    padding-left: 40px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    line-height: 22px;

    .version-flexdiv {
      margin-bottom: 8px;
      color: rgba(0, 0, 0, 0.6);
      font-size: 16px;
    }
  }
}

.ease {
  transition: all 0.3s;
}

.title2 {
  color: rgba(24, 144, 255, 1);
}

.msg-title {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 0px 0px rgba(238, 238, 238, 1);

  .qymc {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    // font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    line-height: 28px;
    margin: 8px 0 0 30px;
  }
}

>>>.bread-crumb-wrapper {
  margin-top: 0;
}
</style>
