<template>
  <div @click="handleEnterBtnClick(buyStatus)" class="card" :tabindex="1">
    <div class="card-head">

      <img :src="faceImg" width="100%" class="icon"/>


      <div class="title">{{ name }}</div>
    </div>
  </div>

</template>

<script>
import {checkProductPermission, getUUID} from "@/api/api";
import {mapState} from "vuex";

export default {
  props: {
    name: String,
    remark: String,
    faceImg: String,
    id: Number,
    bankId: String,
    specs: Array,
    buyStatus: Number,
    data: Object,
    isShanDong: Number
  },
  computed: {
    ...mapState(["companyInfo", "authorization"])
  },
  data: function () {
    return {
      uuid: null
    };
  },
  async mounted() {
    await this.loadUUID();
  },
  methods: {
    handleEnterUtil(row) {
      this.$router.push(row);
    },
    async loadUUID() {
      const uuidData = await getUUID();
      if (uuidData.msgCode == 1) {
        this.uuid = uuidData.data.uuid;
      } else {
        alert(uuidData.msgInfo);
      }
    },
    async handleEnterBtnClick(buyStatus) {
      //const specId = this.specs[0].id;
      const permission = await checkProductPermission(
        this.authorization,
        this.id,
        // buyStatus == "Y" ? null : "onTrain" //如果已经购买 则不传onTrain
        buyStatus == "Y" //如果已经购买 则不传onTrain
      );

      if (permission && permission.msgCode == 1) {
        //设置permissionID
        this.$store.commit("UploadTable/setPermissionID", {
          permissionID: permission.data.id
        });

        this.$emit("click", {
          id: this.id,
          data: this.data,
          routes: {
            name: "choose-table",

            params: {
              productID: this.id,
              // bankId: this.bankId,
              productName: this.name,
              uuid: this.uuid,
              version: this.data.edition,
              isShanDong: this.isShanDong
            }
          }
        })
      } else if (permission.msgCode == "1002") {
        this.$store.commit("UploadTable/setPermissionID", {
          permissionID: ""
        });
        this.$confirm("请先完善企业信息", "提示", {
          confirmButtonText: "完善信息",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          this.$router.push({name: "companyApply"});
        });
      } else if (permission.msgCode == "1007") {
        this.$confirm(permission.msgInfo, {
          confirmButtonText: "前往完善",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            this.$router.push({
              path: "/setUser"
            });
          })
          .catch(() => {
          });
      } else {
        this.$store.commit("UploadTable/setPermissionID", {
          permissionID: ""
        });
        this.$alert(permission.msgInfo, "错误", {
          type: "error"
        });
      }
    }
  }
}
;
</script>

<style lang="stylus" scoped>
.card {
  flex-shrink: 0;
  display: block;
  padding: 30px 27px;
  background: #FBFBFB;
  border-radius: 8px;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;

  .card-head {
    display: flex;
    align-items: center;

    .icon {
      width: 100px;
      height: 90px;
      flex-shrink: 0;
    }

    .title {
      font-size: 24px;
      line-height: 33px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
      margin-left: 24px;
    }
  }

}

</style>
