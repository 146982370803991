<template>
  <el-dialog title="提示" :visible.sync="dialogVisible" width="480px">
    <!--包含发票和其他-->
    <div class="body">
      <div class="list-wrapper" v-for="(feature, index) in features" :key="index">
        <template v-if="feature === '申报数据/余额表采集'">
          <div class="title">
            <i class="circle">{{ index + 1 }}</i
            >申报数据/余额表采集
          </div>
          <div class="list">
            <div class="item">
              <div class="item-title">方法一：</div>
              <div class="item-text">
                用户在系统内输入电子税务局的账号和密码，系统会自动提取申报数据。
              </div>
            </div>
            <div class="item">
              <div class="item-title">方法二：</div>
              <div class="item-text">
                下载AI税务师提供报表模板，填写完成后上传至系统内。
              </div>
            </div>
            <div class="item">
              <div class="item-title">方法三：</div>
              <div class="item-text">
                系统内置报表填写功能，用户可在系统内手动填写报表。
              </div>
            </div>
            <div class="item">
              <div class="item-title">方法四：</div>
              <div class="item-text">系统支持上传Excel/HTML类型文件。</div>
            </div>
          </div>
        </template>
        <template v-if="feature === '发票数据采集'">
          <div class="title">
            <i class="circle">{{ index + 1 }}</i
            >发票数据采集
          </div>
          <div class="list">
            <div class="item">
              <!-- <div class="item-title">
                方法一：
              </div> -->
              <div class="item-text">
                如果用户使用的税盘是“百旺”的盘，用户可在任意电脑下载安装发票取值插件，提取发票数据。
              </div>
            </div>
            <div class="item">
              <!-- <div class="item-title">
                方法二：
              </div> -->
              <div class="item-text">
                如果用户使用的税盘是“航信”的盘，用户需要在开票电脑内下载安装发票取值插件，提取发票数据。
              </div>
            </div>
            <div class="item">
              <!-- <div class="item-title">
                方法二：
              </div> -->
              <div
                class="item-text item-text-stress"
                v-if="selectedEvaluateCard && selectedEvaluateCard.data.edition == 5"
              >
                提示：山东地区（除青岛）纳税企业，若选择自动取数方式，无需使用以上插件方式另取发票数据。
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!--/包含发票和其他-->

    <span slot="footer" class="dialog-footer">
      <a
        href="http://shuian-file.oss-cn-hangzhou.aliyuncs.com/goods/AI%E7%A8%8E%E5%8A%A1%E5%B8%88.zip"
        target="_blank"
        class="btn btn-primary"
        v-if="features.includes('发票数据采集')"
        >下载发票插件</a
      >
      <button class="btn btn-default" @click="handleBtnStartCheck">开始检测</button>

      <button
        v-if="selectedEvaluateCard && selectedEvaluateCard.data.edition == 5"
        class="btn btn-default"
        @click="handleBtnStartCheckSd"
      >
        山东地区
      </button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    features: Array,
    selectedEvaluateCard: [Object, Array],
  },
  name: "DialogOfIncludeInvoice",
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    handleBtnStartCheck() {
      this.$emit("startCheck");
    },
    open() {
      this.dialogVisible = true;
    },
    handleBtnStartCheckSd() {
      this.$emit("startCheckSd");
    },
  },
};
</script>

<style lang="stylus" scoped>
.list-wrapper {
  margin-bottom 24px

  .list {

    .item {
      margin-bottom 8px

      .item-title {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.4);
        line-height: 20px;
      }

      .item-text {
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        font-size: 14px;
        line-height: 20px;
      }

      .item-text-stress {
        color: red;
      }
    }
  }
}

.title {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 22px;
  text-align center
  margin-bottom 16px
}

.circle {
  margin-right 5px;
  background #FAAD14;
  border-radius 25px
  display inline-block
  color #fff;
  height 20px;
  text-align center;
  line-height 20px
  width 20px
  font-style normal
}

>>> .el-dialog {
  .el-dialog__footer {
    border-top: 1px solid #EEEEEE;
    padding: 16px
    text-align center
  }
}
</style>
