<template>
  <scroll-bar>
    <router-link :to="{name:'homePage'}" class="logo">
      <img v-if="icon" :src="icon" class="logo-pic" />
    </router-link>

    <div class="sider-bar-menu-wrapper">
      <template v-for="(item,index) in asideMenuConfig">
        <div
          class="item"
          :class="{actived:!!item.routeNames&&item.routeNames.includes($route.name) }"
          v-if="!item.children"
          :key="index"
        >
          <router-link :to="item.path">
            <i class="icon" :class="item.icon"></i>
            {{item.name}}
          </router-link>
        </div>

        <div class="group" :key="index" v-else>
          <div class="title">{{item.name}}</div>
          <template v-for="(item2,index2) in item.children">
            <div
              class="item"
              :class="{actived:!!item2.routeNames&&item2.routeNames.includes($route.name) }"
              :key="index2"
            >
              <router-link :to="item2.path" >
                <i class="icon" :class="item2.icon"></i>
                {{item2.name}}
              </router-link>
            </div>
          </template>
          <!-- <div class="item">
            <router-link to="/">
              <i class="icon el-icon-house"></i> 风险检测
            </router-link>
          </div>-->
        </div>
      </template>
    </div>
  </scroll-bar>
</template>

<script>
import { asideMenuConfig } from "@/config/menu";
import ScrollBar from "./ScrollBar";
import { checkProductPermission, getUUID, mateGoods } from "@/api/api";

export default {
  name: "SideBar",
  components: { ScrollBar },
  props: ["icon"],
  data() {
    return {
      asideMenuConfig,
      uuid: "",
      loading: ""
    };
  },
  mounted() {
    this.$route.name;
    // debugger;
  },
  methods: {

    // mategoods() {
    //   let params = {
    //     token: this.$store.state.authorization,
    //   };
    //   mateGoods(params).then((res) => {
    //     if (res.msgCode == 1) {
    //       this.$store.commit("setGoods", res.data);
    //     } else {
    //       this.$message({
    //         message: res.msgInfo,
    //         type: "error",
    //       });
    //     }
    //   });
    // },
  },

  destroyed() {
    this.loading && this.loading.close();
  },
};
</script>

<style lang="stylus" scoped>
.sidebar-container {
  display: flex;
  flex-direction: column;
  background: #fff;
  .logo {
    display: flex;
    width: 100%;
    position: relative;
    left: 0px;
    justify-content: center;
    align-items: center;
    line-height: 64px;
    background: #fff;
    color: #333;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    height: 60px;
    display: flex;
    align-items: center;
    flex-shrink: 0;

    .logo-pic {
      width: 122px;
    }
  }

  .sider-bar-menu-wrapper {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08),
      0px 0px 4px 0px rgba(0, 0, 0, 0.02);

    padding-top: 16px;
    flex-grow: 1;
    color: #555;
    font-size: 14px;
    line-height: 1.5;
    .item {
      height: 36px;
      display: flex;
      align-items: center;
      padding-left: 24px;
      .icon {
        padding-right: 5px;
      }
    }
    .item.actived {
      background: #2f54eb;
      color: #fff;
    }
    .group {
      .title {
        font-size: 16px;
        color: #333;
        font-weight: 500;
        padding: 16px 0 16px 16px;
      }
    }
  }

  // box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  transition: width 0.28s;
  width: 180px;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  // overflow: hidden;
  a {
    display: inline-block;
    width: 100%;
  }
}

.item-btn {
  display: inline-block;
  cursor: pointer;
  width: 100%;
}
</style>
