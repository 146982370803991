<template>
  <div class="section-box">
    <div class="title">使用流程图</div>
    <div class="steps">
      <div class="step">
        <img src="@/assets/home/step0@2x.png" class="pic" />
        <div class="title">模型匹配</div>
        <div class="desc">自动匹配风险模型库</div>
        <!-- <div class="hover">系统针对不同类型/规模/行业/地区的企业设置多种风险库供企业用户选择</div> -->
      </div>
      <div class="step-arrow">
        <img src="@/assets/home/steps_arrow.png" class="pic" />
      </div>
      <div class="step">
        <img src="@/assets/home/step1@2x.png" class="pic" />
        <div class="title">财税数据采集</div>
        <div class="desc">录入企业财税数据</div>
        <!-- <div class="hover">
          系统需要用户上传企业的财务数据和税务数据，点击“
          <router-link to="#">财税数据上传指引</router-link>”
        </div> -->
      </div>
      <div class="step-arrow">
        <img src="@/assets/home/steps_arrow.png" class="pic" />
      </div>
      <div class="step" v-if="versionPurchased>3">
        <img src="@/assets/home/step2@2x.png" class="pic" />
        <div class="title">发票数据采集</div>
        <div class="desc">录入企业发票数据</div>
        <!-- <div class="hover">
          系统需要用户上传企业的发票数据，点击“
          <router-link to="#">发票数据上传指引</router-link>”
        </div> -->
      </div>
      <div class="step-arrow" v-if="versionPurchased>3">
        <img src="@/assets/home/steps_arrow.png" class="pic" />
      </div>
      <div class="step">
        <img src="@/assets/home/step3@2x.png" class="pic" />
        <div class="title">预警报告</div>
        <div class="desc">在线报告/PDF报告</div>
        <!-- <div class="hover">系统根据用户上传的数据使用风险库进行分析计算，出具风险报告</div> -->
      </div>
      <div class="step-arrow">
        <img src="@/assets/home/steps_arrow.png" class="pic" />
      </div>
      <div class="step">
        <img src="@/assets/home/step4@2x.png" class="pic" />
        <div class="title">税务筹划</div>
        <div class="desc">在线人工/专业税务师</div>
        <!-- <div class="hover">平台提供专业的税务筹划，线上筹划与线下筹划</div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    versionPurchased: [Number, String]
  }
};
</script>

<style lang="stylus" scoped>
.steps {
  display: flex;
  justify-content: space-around;

  .step-arrow {
    // margin: 0 24px;
    display: flex;
    align-items: center;
    flex-shrink: 1;

    .pic {
      width: 48px;
    }
  }

  .step {
    position: relative;
    overflow: hidden;

    // .hover {
    //   transform: translateY(-149px);
    //   height: 149px;
    //   position: absolute;
    //   left: 0;
    //   top: 0;
    //   background: rgba(0, 0, 0, 0.8);
    //   // bottom: 0;
    //   // right: 0px;
    //   color: #fff;
    //   padding: 24px;
    //   font-size: 14px;
    //   line-height: 20px;
    //   box-sizing: border-box;

    //   a {
    //     color: #1890FF;
    //   }

    //   a:hover {
    //     color: hsla(209, 100%, 60%, 1);
    //   }
    // }

    margin-top: 16px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 160px;
    height: 149px;
    background: rgba(3, 52, 255, 0.04);
    border-radius: 8px;
    flex-direction: column;
    flex-shrink: 0;

    .pic {
      width: 48px;
      display: block;
    }

    .title {
      margin-top: 16px;
      flex-wrap: wrap;
      color: #333;
      font-size: 14px;
      line-height: 20px;
    }

    .desc {
      font-size: 12px;
      color: #999;
      line-height: 17px;
    }
  }

  .step:hover {
    .hover {
      transform: translateY(0);
      transition: transform 0.5s;
    }
  }
}
</style>