<template>
  <div class="financialRisk-h5-container" v-if="loaded">
    <div class="financialRisk-h5">
      <div class="company-baseName">
        <div class="company-tip"></div>
        <div class="company-tip-base">历史纳税情况</div>
      </div>
      <!-- <div class="table-wrapper">
        <TaxCreditLevel :data="data.data[0]"></TaxCreditLevel>
        <TaxCreditLevel :data="data.data[1]"></TaxCreditLevel>
      </div> -->
      <div>
        <div class="time">{{ data.data[0].name }}</div>
        <div class="stats-wrapper">
          <div class="stats">
            <div class="stats-item" v-for="item in bodyT.data" :key="item.index">
              <div style="position: absolute;bottom:0;right:0;width:74px;height: 82px;">
                <img class="backImg" src="@/assets/util/img_shuiyin_h5.png" alt="" />
              </div>
              <div class="card">
                <div class="year">{{ item.year }}年</div>
                <div style="">
                  <div class="creditRating">
                    <div class="numS">{{ item.creditLevel }}</div>
                    <div class="cName">信用等级</div>
                  </div>
                  <div class="grade">
                    <div class="numS">{{ item.creditPoint }}</div>
                    <div class="cName">信用评分</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="time" style="margin-bottom: 14px">{{ data.data[1].name }}</div>
        <div v-if="!bodyL.data || bodyL.data == [] || bodyL.data == ''" class="IllegalInformation"
          style="min-height: 280px">
          <img class="backImgNull" src="@/assets/util/无数据插画.png" alt="" />
          <div class="Illegal">无税收违法信息</div>
        </div>
        <div class="IllegalInformation" style="padding: 16px 20px" v-else>
          <!-- <div class="stats">
            <div class="stats-item" v-for="item in bodyL.data" :key="item.id">
              <div class="statsYear">{{ item.publishTime }}</div>
              <div class="caseType">案件性质: {{ item.caseType }}</div>
              <div class="caseInfo">
                主要违法事实、法律依据及处理处罚情况：
                {{ item.caseInfo }}
              </div>
              <div class="statsLine"></div>
            </div>
          </div> -->
        <TaxCreditLevel :data="data.data[1]" :isShowName="false"></TaxCreditLevel>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TaxCreditLevel from "@/pages/Result/H5FinancialAnalysis/historyTaxCondition/TaxCreditLevel";
import { getTaxPayment } from "@/api/threeYears";

export default {
  name: "HistoryTaxCondition",
  components: {
    TaxCreditLevel,
  },
  async mounted() {
    this.loaded = false;
    await this.loadTaxPayment();
    this.loaded = true;
  },
  data() {
    return {
      activeIndex: "1",
      loaded: false,
      data: null,
      bodyT: null,
      bodyL: null,
    };
  },
  methods: {
    async loadTaxPayment() {
      const res = await getTaxPayment({ programId: this.$route.params.uuid });
      this.data = res.data;
      this.bodyT = res.data.data[0];
      this.bodyL = res.data.data[1];
    },
    /**
     * 顶部菜单点击
     * @param {string} key
     * @param {array<string>}keyPath
     */
    handleTopMenuSelect(key, keyPath) {
      this.activeIndex = key;
    },
  },
};
</script>

<style lang="stylus" scoped>
.IllegalInformation{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #F1F6FE;
  border-radius: 4px 4px 4px 4px;
  .backImgNull{
    //margin: 60px 460px 0;
  }
  .Illegal{
    //margin: 0 481px 60px;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #1D2129;
    line-height: 22px;
  }
  .stats{
    .statsYear{
      font-size: 16px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #1D2129;
    }
    .caseType,.caseInfo{
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #1D2129;
      line-height: 22px;
      margin-top:10px;
    }
    .statsLine{
      border-bottom:2px dashed #e6e6e6;
      margin: 10px 0;
    }
  }
}
.stats-wrapper
  margin 20px 0;

  .stats
    display flex
    justify-content: space-between
    margin-top 24px
    margin-bottom: 40px;

    .stats-item
      position: relative;
      overflow:hidden;
      background: linear-gradient(134deg, #1A9EFF 0%, #2663F6 100%);
      width: 30.3%;
      height: 210px;
      display flex;
      border-radius: 15px 15px 15px 15px;
      padding: 12px 20px;
      box-sizing: border-box;
      img{
        width:74px;height: 82px;
      }
      .number
        //font-size: 30px;
        font-weight: 600;
        color: #1D2129;
        line-height: 42px;
      .card
        display: flex
        flex-direction column
        width:100%
        .year
          width: 68px;
          height: 28px;
          font-size: 18px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          //color: #FFFFFF;
          
          color: rgba(255,255,255,0.8);
        .creditRating
          display: flex
          flex-direction column
          align-items: center
        .grade
          margin-top :15px;
          display: flex
          flex-direction column
          align-items: center
        .numS
          font-size: 28px;
          font-family: DIN Alternate-Bold, DIN Alternate;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 50px;
          padding-top: 2px
        .cName
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;

    .stats-item:last-child{
      margin-right: 0px
    }

.time{
  font-weight: bold;
  color: #1D2129;
  margin-top: 20px
}
.table-wrapper
  // display flex
  // justify-content space-between
  width 100%

  >>> .bigPowerTable {
    max-height 480px
    overflow auto
  }
.financialRisk-h5-container
  border: 2px solid #6AA1FF;
  border-style: dashed;
  margin: 0.4rem;
  padding: 0.24rem;
  border-radius: 4px;

.financialRisk-h5
  .company-baseName
    display: flex;
    align-items: center;
    color: #1D2129;
    font-size: 0.32rem;
    font-weight: 600;
    line-height: 0.33rem;
    margin-bottom: 0.16rem;

    .company-tip
      width: 0.12rem;
      height: 0.24rem;
      background: #2F54EB;
      margin-right: 0.1rem;

    .company-tip-base
      line-height: 0.33rem;

  >>> table.table th, >>> table.table td {
    font-size: 0.2rem;
  }
</style>
