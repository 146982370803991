<template>
  <div>
    <bread-crumb>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item
          :to="{ path: '/util/abnormal_tax?name='+$route.query.name+'&page='+$route.query.page+'&number=0' }"
        >异常纳税人查询</el-breadcrumb-item>
        <el-breadcrumb-item>{{$route.query.params.ycnsr_hz.nsrmc}}</el-breadcrumb-item>
      </el-breadcrumb>
    </bread-crumb>
    <div id="setuser">
      <div class="qymc">{{$route.query.params.ycnsr_hz.nsrmc}}</div>
      <el-card class="box-card" v-if="list.ycnsr_hz">
        <div class="header">纳税人信息</div>
        <el-row>
          <el-col :span="12" class="title">纳税人电子档案号：{{list.ycnsr_hz.nsrdzdah}}</el-col>
          <el-col :span="12" class="title">公司名称：{{list.ycnsr_hz.nsrmc}}</el-col>
          <el-col :span="12" class="title">社会统一信用代码：{{list.ycnsr_hz.nsrsbh}}</el-col>
          <el-col :span="12" class="title">法定代表人：{{list.ycnsr_hz.fddbrmc}}</el-col>
        </el-row>
      </el-card>
      <el-card class="box-card" v-if="list.ycnsr_fzc_hz && list.ycnsr_fzc_hz.length">
        <div class="header">非正常户信息</div>
        <el-row v-for="(item,index) in list.ycnsr_fzc_hz" :key="index">
          <el-col :span="12" class="title">非正常户认定原因：{{item.fzchrdyy}}</el-col>
          <el-col :span="2"></el-col>
          <el-col :span="10" class="title">非正常户认定时间：{{item.fzchrdsj}}</el-col>
        </el-row>
      </el-card>
      <el-card class="box-card" v-if="list.ycnsr_qsqcmx && list.ycnsr_qsqcmx.length">
        <div class="header">欠税清册</div>
        <el-row v-for="(item,index) in list.ycnsr_qsqcmx" :key="index">
          <el-col :span="12" class="title">所属时间起：{{item.sssqq}}</el-col>
          <el-col :span="12" class="title">欠税金额（元）：{{item.se}}</el-col>
          <el-col :span="12" class="title">所属时间止：{{item.sssqz}}</el-col>
          <el-col :span="12" class="title">缴款期限：{{item.jkqx}}</el-col>
        </el-row>
      </el-card>
      <el-card class="box-card" v-if="list.ycnsr_wfaj && list.ycnsr_wfaj.length">
        <div class="header">税收违法案件</div>
        <el-row>
          <el-col :span="20" class="title">非正常户认定原因： {{list.ycnsr_wfaj.zywfxf}}</el-col>
        </el-row>
      </el-card>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
export default {
  components: {
    BreadCrumb,
  },
  data() {
    return {
      list: {},
    };
  },
  mounted() {
    this.list = this.$route.query.params;
  },
};
</script>
<style lang="stylus" scoped>
.qymc {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  line-height: 33px;
  margin-bottom: 21px;
}

#setuser {
  padding: 10px;
  margin: 0px auto 30px auto;
  width: 96%;
}

.title {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
  padding: 8px 0;
}

.header {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  line-height: 25px;
  padding: 8px 0;
}

.box-card {
  margin-bottom: 16px;
}
</style>