<template>
  <el-radio-group
    v-model="selectedWarningLevelId"
    size="mini"
    @change="handleChange"
  >
    <el-radio-button
      :label="warningLevelIdOption.value"
      v-for="warningLevelIdOption in warningLevelIdOptions"
      :key="warningLevelIdOption.value"
      :class="`c${warningLevelIdOption.value}`"
      >{{ warningLevelIdOption.label }}</el-radio-button
    >
  </el-radio-group>
</template>

<script>
export default {
  props: {
    selectedValue: Number,
  },
  mounted() {
    this.selectedWarningLevelId = this.selectedValue;
  },
  data: function () {
    return {
      selectedWarningLevelId: "", //当前选中的风险
      warningLevelIdOptions: [
        {
          label: "高风险",
          value: 3,
        },
        {
          label: "中低风险",
          value: 4,
        },
      ],
    };
  },
  methods: {
    handleChange(value) {
      this.$emit("change", value);
    },
  },
};
</script>

<style lang="stylus" scoped>
>>> .el-radio-button__inner {
  color: rgba(0, 0, 0, 0.4);
  background: #F9F9F9;
  border-radius: 0px 4px 4px 0px;
  border: 1px solid #D9D9D9;
}
>>>.el-radio-button__orig-radio:checked+.el-radio-button__inner{
    box-shadow: -1px 0 0 0 #F9F9F9;
}
.c3.is-active {
  >>> .el-radio-button__inner {
    background: rgba(245, 34, 45, 0.08);
    color: #F5222D;
    border-color: #F5222D;
  }
}

.c4.is-active {
  >>> .el-radio-button__inner {
    background: rgba(250, 173, 20, 0.08);
    color: #FAAD14;
    border-color: #FAAD14;
  }
}
</style>