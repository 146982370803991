<template>
  <div class="PurchaseAndSaleCom">
    <!-- <div class="tip">
      按不含税金额占比排序，前
      <BigPowerInput
        type="number"
        :min="1"
        v-model="filter.limit"
        size="mini"
        style="width: 60px"
        @change="loadGXCategoryAnalysis"
      ></BigPowerInput
      >的购销对比如下：
    </div> -->
    <div class="table-wrapper table-wrapper-buy" v-if="type == 1">
      <BigPowerTable :data="data" class="table" v-if="data"></BigPowerTable>
      <div class="sc" @click="switchScreen" v-if="data&&hp!=2">
        <img src="@/assets/util/hScreen.png" alt="" />
      </div>
      <div v-if="data == null" v-loading="loading" class="loadingType"></div>
    </div>
    <div v-if="type == 2">
      <PurchaseAndSaleTab :taxNo="taxNo" :limit="filter.limit"></PurchaseAndSaleTab>
    </div>
    <div class="full-sc">
      <el-drawer :visible.sync="horizontalScreen" direction="btt" size="100%">
        <div class="full-tab">
          <div class="table-wrapper">
            <BigPowerTable :data="data" v-if="data" class="table"></BigPowerTable>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import "@/pages/Result/ThreeYearResult/global.styl";
import BigPowerTable from "big-power-table";
import { getGXCategoryAnalysis } from "@/api/threeYears";
import PurchaseAndSaleTab from "@/pages/Result/H5FinancialAnalysis/purchaseAndSale/PurchaseAndSaleTab";

export default {
  components: {
    BigPowerTable,
    PurchaseAndSaleTab,
  },
  props: {
    taxNo: String,
    zq: [String, Number],
    type: [String, Number],
    hp: [String, Number],
  },
  name: "PurchaseAndSaleCategoryCompare",
  async mounted() {
    await this.loadGXCategoryAnalysis();
  },
  data() {
    return {
      data: null,
      filter: {
        limit: 15,
      },
      horizontalScreen: false,
      loading: true,
    };
  },
  methods: {
    async loadGXCategoryAnalysis() {
      const res = await getGXCategoryAnalysis({
        taxNo: this.taxNo,
        zq: this.zq,
        topCount: this.filter.limit,
        programId: this.$route.params.uuid,
      });
      this.data = res.data;
      this.loading = false;
    },
    switchScreen() {
      // this.horizontalScreen = true;
      this.$router.push({ path: "../buySaleH5/"+this.$route.params.uuid });
    },
  },
};
</script>

<style lang="stylus" scoped>
.tip
  margin-top 29px
  font-size: 14px;
  font-weight: 400;
  color: #86909C;
  line-height: 20px;
.table
  // margin-top 21px

.table-wrapper
  background: #FFFFFF;
  // margin 20px 0
  margin-bottom 0
  // padding-bottom 24px
  position: relative;

  .sc {
    position: absolute;
    right: 0px;
    bottom: 0px;
    padding: 5px;

    img {
      width: 0.25rem;
      height: 0.25rem;
      background: #FFFFFF;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
      border-radius: 0.08rem;
    }
  }

  .time {
    margin 20px 16px
    font-size: 14px;
    font-weight: 400;
    color: #86909C;
  }

  .table {
    >>> .bigPowerTable {
      max-height 720px
      overflow auto
    }
  }

.PurchaseAndSaleCom
  >>> table.table th, >>> table.table td {
    font-size: 0.2rem;
  }

.full-sc {
  .full-tab {

    .t-w {
      display: flex;
      align-items: center;
    }

    transform-origin: center center;
    transform: rotateZ(90deg);

    .titleName {
      font-size: 0.28rem;
      font-weight: 600;
      color: #2B2F58;
      line-height: 0.36rem;
    }

    .dataText {
      font-size: 0.28rem;
      color: #40496A;
      line-height: 0.36rem;
      margin: 12px 0;
    }
  }
  >>> .el-drawer__header {
    padding: 0;
  }
  >>> .el-drawer__body {
    overflow: hidden;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  >>> .el-drawer__open .el-drawer.btt {
    border-radius: 0;
  }

  >>> .el-drawer__close-btn {
    font-size: 30px;
    position: relative;
    right: 10px;
    top: 10px;
  }

  // display: flex;
  // align-items: center;
  // justify-content: center;
  // flex-direction: column;

  .tab-s {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // flex-direction: column;
    // height: 100%;

    max-height: 220px;
    max-width: 500px;
    overflow: auto;

    .tablefirst {
      width: 100%;
      height: 100%;
      overflow: auto;
    }

    .tablefirst tr:nth-child(2n-1) td {
      background: #fff;
    }

    .tablefirst thead tr > th:first-child,
    .tablefirst tbody tr > td:first-child {
      position: -webkit-sticky;
      position: sticky;
      left: 0;
    }

    .table-screen-b {
      td, th {
        min-width: 80px;
        // max-width: 200px;
      }
    }
  }
  .table {
    >>> .bigPowerTable {
      max-height 5rem;
      max-width: 85vh;
      overflow auto
    }
  }
}

.loadingType {
  width: 5rem;
  height: 500px;
  margin: 0 auto;
}

.table-wrapper-buy {
  .table {
    >>> .bigPowerTable {
      max-height 10rem
      overflow auto
    }
  }
}
</style>
