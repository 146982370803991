<template>
  <div>
    <div class="containerMask" @click="closeBox"></div>
    <div class="container" v-loading="loadingItem" id="projectBoxs">
      <div class="addTitle">以下信息将影响报告准确性，请正确填写:</div>
      <div class="project-close" @click="closeBox">×</div>
      <div class="overflow-xx overflow-xy">
        <div class="project-name">
          <span> <span class="project-named">*</span>税号</span>
        </div>
        <div class="project-input">
          <el-input placeholder="请输入" v-model.trim="taxNo"></el-input>
        </div>
        <div class="project-name">
          <span> <span class="project-named">*</span>地区</span>
        </div>
        <div class="project-input">
          <el-cascader :options="areaList" v-model="area"></el-cascader>
        </div>
        <div class="project-name">
          <span> <span class="project-named">*</span>行业</span>
        </div>
        <div class="project-input">
          <el-select v-model="hy" filterable placeholder="请选择">
            <el-option
              v-for="item in hyList"
              :key="item.hyDm"
              :label="item.hymc"
              :value="item.hyDm"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="project-btn ease" @click="createProject">提交</div>
    </div>
  </div>
</template>

<script>
import { auditRuleListAdd } from "@/api/api";
export default {
  props: {
    areaList: Array,
    hyList: Array,
  },
  data() {
    return {
      loadingItem: false,
      taxNo: "",
      area: "",
      hy: "",
    };
  },
  methods: {
    // 关闭新增弹窗
    closeBox() {
      this.$emit("closeBox");
    },

    // 创建项目
    createProject() {
      if ([this.hy, this.taxNo, this.area].includes("")) {
        this.$message({
          message: "缺少参数！",
          type: "warning",
        });
        return;
      }
      let area = this.area.toString();
      let token = JSON.parse(localStorage.getItem("token")).token;
      let params = {
        industry: this.hy,
        region: area,
        taxNo: this.taxNo,
        token: token,
      };
      auditRuleListAdd(params).then((res) => {
        if (res.code == 200) {
          let code = res.data;
          this.$emit("addBox", { code: code });
        } else {
          this.$message({
            message: res.message,
            type: "warning",
          });
        }
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.container {
  position: absolute;
  left: 50%;
  margin-left: -240px;
  top: 15vh;
  width: 480px;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  z-index: 1003;
  overflow: hidden;
}

>>> #projectBoxs .project-input .el-input {
  width: 416px;
  height: 36px;
  margin: 0 0 16px 32px;
}

.addTitle {
  // text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.45);
  line-height: 28px;
  padding: 24px 0 20px 0;
  margin-left: 30px;
}

.project-close {
  position: absolute;
  cursor: pointer;
  font-size: 35px;
  color: rgba(0, 0, 0, 0.4);
  padding: 10px;
  top: 6px;
  right: 20px;
}

.overflow-xx {
  margin-bottom: 90px;
}

.overflow-x {
  max-height: 375px;
  overflow-x: hidden;
}

.overflow-xx {
  margin-bottom: 90px;
}

.project-name {
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 20px;
  margin: 0 0 4px 32px;
}

.project-named {
  position: relative;
  top: 2px;
  margin-right: 4px;
  color: #F5222D;
}

>>> .project-input .el-input__icon {
  line-height: 36px;
}

>>> #form-items .project-input .el-input {
  height: 36px;
}

>>> #form-items .el-input__inner {
  height: 36px;
}

>>> #projectBoxs .project-input .el-input {
  width: 416px;
  height: 36px;
  margin: 0 0 16px 32px;
}

>>> #projectBoxs .project-input .el-input .el-input__inner {
  height: 36px;
}

.project-addTaxno {
  text-align: center;
}

.project-btn:hover {
  background: rgba(47, 34, 235, 1);
}

.project-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 68px;
  line-height: 68px;
  background: rgba(47, 84, 235, 1);
  box-shadow: 0px -1px 0px 0px rgba(47, 84, 235, 1);
  z-index: 1004;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.containerMask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.4);
  z-index: 1002;
}
</style>
