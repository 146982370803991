<template>
  <div class="company_msg">
    <el-card class="usercard">
      <div class="box">
        <div class="qyname">当前角色：</div>
        <div class="name">
          <img
            class="name-logo"
            v-if="userStatus == 1"
            src="@/assets/test/usericon.png"
            alt
          />
          <img
            class="name-logo"
            v-if="userStatus == 2"
            src="@/assets/test/financeicon.png"
            alt
          />
          <span class="font-600" v-if="userStatus == 1">企业主</span>
          <span class="font-600" v-if="userStatus == 2">财务人员</span>
        </div>
        <el-button type="text" class="code_btn" @click="checkPart">
          <i class="el-icon-refresh"></i>切换角色
        </el-button>
      </div>
      <div class="box">
        <div class="qyname field">企业名称：</div>
        <div class="name">{{ FormInfo.companyName }}</div>
        <el-button type="text" class="code_btn" @click="checkCompanyClick">
          <i class="el-icon-refresh"></i>切换企业
        </el-button>
      </div>
      <div class="box">
        <div class="qyname field">用户ID：</div>
        <div class="name">{{ FormInfo.id }}</div>
      </div>
      <div class="box box1">
        <div class="qyname field">所属地区：</div>
        <div class="name">
          <el-cascader
            filterable
            :options="city"
            v-model="filters.dq"
            @change="handleChange"
          ></el-cascader>
        </div>
      </div>
      <template v-if="showMsg">
        <div class="box box1">
          <div class="qyname">所属1级行业：</div>
          <div class="name">
            <el-select v-model="filters.hy1" placeholder="请选择" @change="basehy">
              <el-option
                v-for="item in hyList1"
                :key="item.hyDm"
                :label="item.hyMc"
                :value="item.hyDm"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="box box1">
          <div class="qyname">所属2级行业：</div>
          <div class="name">
            <el-select v-model="filters.hy" placeholder="请选择" filterable>
              <el-option
                v-for="item in morehylist"
                :key="item.hyDm"
                :label="item.dmMc"
                :value="item.hyDm"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="box box1">
          <div class="qyname">会计制度：</div>
          <div class="name">
            <el-select v-model="filters.kjzd" placeholder="请选择">
              <el-option
                v-for="item in kjzd"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="box box1">
          <div class="qyname">资产总额平均值：</div>
          <div class="name">
            <el-input
              maxlength="9"
              placeholder="请输入资产总额平均值"
              type="text"
              v-model="filters.all"
            >
              <template slot="append">万元</template>
            </el-input>
          </div>
        </div>
        <div class="box box1">
          <div class="qyname">从业人数平均值：</div>
          <div class="name">
            <el-input
              placeholder="请输入资产总额平均值"
              type="text"
              v-model="filters.average"
            >
              <template slot="append">个</template>
            </el-input>
          </div>
        </div>
        <div class="box box1">
          <div class="qyname">企业类型：</div>
          <div class="name">
            <div>
              <span>高新技术企业：</span>
              <span>
                <el-radio v-model="filters.com1" label="1">是</el-radio>
                <el-radio v-model="filters.com1" label="0">否</el-radio>
              </span>
              <span class="nameleft nameleftP">存在外资投资股东：</span>
              <span class="nameleftP">
                <el-radio v-model="filters.com2" label="1">是</el-radio>
                <el-radio v-model="filters.com2" label="0">否</el-radio>
              </span>
            </div>
          </div>
        </div>
        <div class="box box1">
          <div class="qyname"></div>
          <div class="name">
            <div>
              <span>小型微利企业：</span>
              <span>
                <el-radio v-model="filters.com3" label="1">是</el-radio>
                <el-radio v-model="filters.com3" label="0">否</el-radio>
              </span>
              <span class="nameleft">从事国家限制或禁止行业：</span>
              <span>
                <el-radio v-model="filters.com4" label="1">是</el-radio>
                <el-radio v-model="filters.com4" label="0">否</el-radio>
              </span>
            </div>
          </div>
        </div>
      </template>
      <div class="box-more" v-if="!showMsg">
        <el-button type="text" class="code_btn" @click="showMsg = true">
          <span class="card-row">
            <i class="el-icon-d-arrow-right"></i>
          </span>
          选填选项
        </el-button>
      </div>
      <div class="box-more" v-if="showMsg">
        <el-button type="text" class="code_btn" @click="showMsg = false">
          <span class="card-row card-row-t">
            <i class="el-icon-d-arrow-right"></i>
          </span>
          点击收起
        </el-button>
      </div>
      <el-button type="primary" class="save" @click="btn">保存</el-button>
      <el-button type="text" class="before" @click="addCompany">新增企业</el-button>
    </el-card>
    <!-- 切换企业弹框 -->
    <el-dialog :visible.sync="checkCompany" id="auto2dialog" :show-close="true">
      <div class="check_company">切换企业</div>
      <div class="check_companybg">
        <el-radio-group
          v-model="filters.companyvalue"
          class="auto_radio"
          v-for="(item, index) in companyList"
          :key="index"
        >
          <el-radio :label="item.companyId">
            <span>{{ item.companyId }}</span>
            {{ item.companyName }}
          </el-radio>
        </el-radio-group>
      </div>
      <div class="autotabbtn">
        <el-button type="primary" @click="changecomp">确定</el-button>
      </div>
    </el-dialog>
    <!-- 切换角色弹窗 -->
    <el-dialog :visible.sync="checkParts" id="partdialog" :show-close="false">
      <div class="part-check font-600">
        <span>角色选择</span>
        <img
          class="part-close"
          src="@/assets/test/part-close.png"
          alt
          @click="checkParts = false"
        />
      </div>
      <div class="part-icon">
        <span class="part-user part-userR">
          <span class="part-circle" :class="{ partactive: act == 1 }" @click="act = 1">
            <img class="part-iconl" src="@/assets/test/part-u.png" alt />
          </span>
          <span class="part-checktext" :class="{ partactivet: act == 1 }">企业主</span>
        </span>
        <span class="part-user">
          <span class="part-circle" :class="{ partactive: act == 2 }" @click="act = 2">
            <img src="@/assets/test/part-c.png" alt />
          </span>
          <span class="part-checktext" :class="{ partactivet: act == 2 }">财务人员</span>
        </span>
      </div>
      <div>
        <div class="part-btn ease" @click="checkSubmit">确定</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  findhydm,
  getHy,
  getCompanyId,
  getbasicinfo,
  dmHyMore,
  saveCompanyInfo,
  changecompanyselect,
  changecompany,
  changekjzd,
  changehyaix,
} from "@/api/api";
import SectionTitle from "@/components/SectionTitle";
import { regionData } from "element-china-area-data";

export default {
  components: {
    SectionTitle,
  },
  data() {
    return {
      input2: "",
      city: regionData,
      companyList: [],
      filters: {
        dq: [],
        hy: "",
        hy1: "",
        kjzd: "",
        companyvalue: "",
        all: "",
        average: "",
        com1: "1",
        com2: "1",
        com3: "1",
        com4: "1",
      },
      province1: "",
      city1: "",
      checkCompany: false,
      checkParts: false,
      radio: "",
      value: "",
      hasPwd: false,
      FormInfo: {},
      morehylist: [],
      hyList1: [],
      hyList2: [],
      Form: {},
      kjzd: [
        { value: "qykjzd", label: "企业会计制度" },
        { value: "qykjzz", label: "企业会计准则" },
        { value: "xqykjzz", label: "小企业会计准则" },
      ],
      hyDm: "",
      hyDm1: "",
      res: [],
      act: 1,
      userStatus: 0,
      showMsg: false,
    };
  },
  mounted() {
    this.basicinfo();
  },
  methods: {
    checkSubmit() {
      this.userStatus = this.act;
      this.checkParts = false;
    },
    checkPart() {
      this.checkParts = true;
    },
    async deleteCache() {
      this.$store.cache.delete("loadCompanyRenzheng"); //删除认证缓存
      this.$store.cache.delete("loadCompanyInfo"); //删除企业信息
      await this.$store.cache.dispatch("loadCompanyRenzheng");
      await this.$store.cache.dispatch("loadCompanyInfo");
    },
    addCompany() {
      this.$router.push("/companyApply?addnewcompanystatus=true");
    },
    //获取企业相关信息
    basicinfo() {
      let token = JSON.parse(localStorage.getItem("token")).token;
      getCompanyId(token).then(async (data) => {
        if (data.msgCode == 1) {
          this.res = data.data;
          localStorage.setItem("hasPwd", data.data.hasPwd);
          this.hasPwd = data.data.hasPwd;

          if (this.res) {
            getbasicinfo(this.res.companyId).then(async (data) => {
              //修改localstorage里的user里的内容
              const user = JSON.parse(localStorage.getItem("user"));
              user.channalId = data.data.channalId;
              user.channalUserId = data.data.channalUserId;
              user.companyId = data.data.id;
              user.nsrsbm = data.data.nsrsbm;
              this.userStatus = data.data.type;
              localStorage.setItem("user", JSON.stringify(user));
              await this.deleteCache();

              this.FormInfo = data.data;
              this.filters.kjzd = this.FormInfo.kjzd;
              this.filters.dq = this.FormInfo.dq.split(",");
              this.filters.average = this.FormInfo.number;
              this.filters.all = this.FormInfo.generalAssets;
              this.filters.com1 = this.FormInfo.highNewTechnology;
              this.filters.com2 = this.FormInfo.foreignInvestment;
              this.filters.com3 = this.FormInfo.miniature;
              this.filters.com4 = this.FormInfo.restrictedIndustries;

              for (let k in this.city) {
                // if (this.city[k].label == this.filters.dq[0]) {
                //   this.filters.dq[0] = this.city[k].value;
                // }
                for (let k1 in this.city[k].children) {
                  if (this.city[k].children[k1].value == this.filters.dq) {
                    this.filters.dq = [
                      this.city[k].value,
                      this.city[k].children[k1].value,
                    ];
                  }
                }
              }
              this.Form = data.data;
              let params = {
                hyDm: data.data.hy,
              };
              if (data.data.hy) {
                findhydm(params).then((res) => {
                  // this.filters.hy = res.data.hyMc;
                  this.filters.hy1 = res.data.mlhyDm;
                  this.basehy(res.data.mlhyDm, res.data.hyDm);
                });
              }
              getHy().then((data) => {
                let hyList = data.data;
                this.hyList1 = data.data;
              });
            });
          } else {
            //console.log("未认证");
          }
        }
      });
    },
    // 获取2级行业
    basehy(e, dm) {
      this.filters.hy = "";
      let params = {
        hy: e,
        hycode: "",
      };
      dmHyMore(params).then((res) => {
        if (res.msgCode == 1) {
          this.morehylist = res.data;
          if (dm) {
            for (var i in this.morehylist) {
              if (this.morehylist[i].hyDm == dm) {
                this.hydm1 = dm;
                this.filters.hy = this.morehylist[i].hyDm;
              }
            }
          }
        }
      });
    },
    //省市触发
    handleChange(value) {
      // this.province1_code = value[0];
      // for (let key in value) {
      //   for (let k in this.city) {
      //     if (this.city[k].value == value[key]) {
      //       this.province1 = this.city[k].label;
      //     }
      //     for (let k1 in this.city[k].children) {
      //       if (this.city[k].children[k1].value == value[key]) {
      //         this.city1 = this.city[k].children[k1].label;
      //       }
      //     }
      //   }
      // }
    },
    //企业下拉框
    changecompanyselect() {
      let user = JSON.parse(localStorage.getItem("user")).id;
      let params = {
        id: user,
      };
      changecompanyselect(params).then((res) => {
        if (res.msgCode == "1") {
          this.companyList = res.data;
        }
      });
    },
    //点击切换企业显示弹框
    checkCompanyClick() {
      this.checkCompany = true;
      this.changecompanyselect();
    },
    //确认切换企业
    changecomp() {
      if (!this.filters.companyvalue) {
        this.$message.error({
          message: "请选择需要切换的企业",
        });
        return;
      }
      let params = {
        companyId: this.filters.companyvalue,
        userId: JSON.parse(localStorage.getItem("user")).id,
      };
      changecompany(params).then(async (res) => {
        if (res.msgCode == "1") {
          this.$message.success({
            message: "切换企业成功",
            duration: 1500,
          });

          this.basicinfo();
          this.FormInfo.companyName = this.companyvalue;
          this.checkCompany = false;
        } else {
          this.$message.success({
            message: res.msgInfo,
            duration: 1500,
          });
          return;
        }
      });
    },
    changekjzd() {
      let token = JSON.parse(localStorage.getItem("token")).token;
      let params = {
        companyId: this.res.companyId,
        kjzd: this.filters.kjzd,
        token,
      };
      changekjzd(params).then(async (res) => {
        if (res.msgCode == 1) {
          this.$message.success({
            message: "切换成功",
            duration: 1500,
          });
          await this.deleteCache();
          //this.basicinfo();
        }
      });
    },
    changehy() {
      let token = JSON.parse(localStorage.getItem("token")).token;
      let params = {
        token,
        hyDm: this.filters.hy,
      };
      changehyaix(params).then(async (res) => {
        if (res.msgCode == "1") {
          this.$message.success({
            message: "修改成功",
            duration: 1500,
          });
          await this.deleteCache();
        } else {
          this.$message.error({
            message: "行业缺少必要选项",
            duration: 1500,
          });
        }
      });
    },
    //保存
    btn() {
      // this.changekjzd();
      // this.changehy();
      // this.basicinfo();
      let token = JSON.parse(localStorage.getItem("token")).token;
      let para = {
        companyId: this.res.companyId,
        kjzd: this.filters.kjzd,
        hyDm: this.filters.hy,
        dq: this.filters.dq.toString(),
        userToken: token,
        number: this.filters.average,
        generalAssets: this.filters.all,
        highNewTechnology: this.filters.com1,
        miniature: this.filters.com3,
        foreignInvestment: this.filters.com2,
        restrictedIndustries: this.filters.com4,
        type: this.userStatus,
      };
      // if (!this.checkItem(para)) {
      //   this.$message.error({
      //     message: "企业类型不能为空!"
      //   });
      //   return;
      // }
      saveCompanyInfo(para).then((res) => {
        this.$message.success({
          message: "修改成功",
          duration: 1500,
        });
        this.basicinfo();
      });
    },
    checkItem(para) {
      if (!para.dq) {
        this.$message.error({
          message: "地区不能为空!",
        });
        return false;
      } else if (
        para.highNewTechnology == null ||
        para.miniature == null ||
        para.foreignInvestment == null ||
        para.restrictedIndustries == null
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
<style src="@/styles/index.styl" lang="stylus" scoped></style>

<style lang="stylus" scoped>
.autotabbtn {
  height: 54px;
  width: 100%;

  .el-button--text {
    color: #007AFF;
  }

  .el-button--primary {
    width: 100%;
    height: 54px;
    font-size: 16px;
    background-color: #2F54EB;
    margin-top: 40px;
    border-radius: 0px 0px 8px 8px;
    border: none;
  }
}

>>>#auto2dialog {
  .el-dialog {
    width: 480px;
    border-radius: 10px;

    .el-dialog__header {
      // display: none;
    }

    .el-dialog__body {
      padding: 0;
    }
  }
}

// 全局css
.company_dq {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(107, 120, 151, 1);
  margin-right: 35px;
}

.msg_input {
  margin-left: 67px !important;
}

.compant_mdf {
  margin-bottom: 33px;
  min-height: 40px;
}

.company_kjzd {
  padding-bottom: 39px;
}

.date_hy {
  margin-top: 20px;
}

// 全局css结束
.company_msg {
  >>>.date_selet_dq .el-input .el-input__suffix {
    top: 5px;

    i:before {
      position: relative;
      top: -4px;
    }
  }

  .check_company {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    height: 92px;
    line-height: 92px;
    margin-top: -30px;
    text-align: center;
  }

  .check_companybg {
  }

  .msg_input {
    margin-left: 67px !important;
  }

  .compant_mdf {
    margin-bottom: 33px;
    min-height: 100px;
  }

  .company_kjzd {
    margin-bottom: 39px;
  }

  .date1 {
    width: 42%;
    margin-left: 99px;

    .company_dq {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(107, 120, 151, 1);
      margin-right: 35px;
    }

    .company_hy {
      margin-right: 10px;
    }
  }
}

.nameleft {
  margin-left: 80px;
}

.nameleftP {
  position: relative;
  left: 42px;
}

.field:before {
  content: '*';
  color: red;
  position: relative;
  right: 4px;
}

.el-icon-refresh {
  margin-right: 6px;
}

.name-logo {
  position: relative;
  top: 5px;
  margin-right: 4px;
}

>>> #partdialog .el-dialog {
  width: 400px;
  height: 304px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

>>> #partdialog .el-dialog__header {
  padding: 0;
}

>>> #partdialog .el-dialog__body {
  padding: 0;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
}

.part-check {
  width: 100%;
  height: 54px;
  line-height: 54px;
  border-bottom: 1px solid #EEEEEE;
  padding-left: 24px;
  box-sizing: border-box;
  align-item: center;
}

.part-close {
  position: relative;
  top: 18px;
  float: right;
  padding-right: 24px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.part-icon {
  display: flex;
  justify-content: center;
  height: 180px;
  text-align: center;
  padding-top: 30px;
  border-bottom: 1px solid #EEEEEE;
  box-sizing: border-box;
}

.part-iconl {
  width: 74px;
  height: 89px;
}

.part-btn {
  position: relative;
  right: 24px;
  top: 16px;
  width: 76px;
  height: 36px;
  line-height: 36px;
  background: rgba(47, 84, 235, 1);
  border-radius: 4px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  float: right;
  cursor: pointer;
}

.part-btn:hover {
  background: rgba(47, 60, 235, 1);
}

.ease {
  transition: all 0.3s;
}

.part-circle {
  display: inline-block;
  width: 88px;
  height: 88px;
  background: rgba(255, 255, 255, 1);
  border: 2px solid rgba(217, 217, 217, 1);
  border-radius: 50%;
  margin-bottom: 10px;
}

.part-circle:hover {
  transform: scale(1.1);
}

.part-user {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.part-userR {
  margin-right: 64px;
}

.part-check {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
}

.partactive {
  background: rgba(240, 245, 255, 1);
  border: 2px solid rgba(47, 84, 235, 1);
}

.partactivet {
  color: rgba(47, 84, 235, 1);
}

.name-right {
  margin-right: 9px;
}

.box-more {
  margin-left: 118px;
  margin-top: 22px;
}

.card-row {
  display: inline-block;
  transform: rotate(90deg);
}

.card-row-t {
  display: inline-block;
  transform: rotate(270deg);
}
</style>
