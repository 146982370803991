<template>
  <el-dialog :visible.sync="dialogVisible" width="750px">
    <el-carousel height="525px" direction="vertical" :autoplay="true">
      <el-carousel-item v-for="item in pics" :key="item.adOutUrl">
        <a :href="item.adOutUrl" v-if="item.adType==1">
          <img :src="item.adImgUrl" :alt="item.adName" class="img" />
        </a>
        <a :href="item.adOutUrl" target="blank" v-else-if="item.adType==2">
          <img :src="item.adImgUrl" :alt="item.adName" class="img" />
        </a>
        <img :src="item.adImgUrl" :alt="item.adName" class="img" v-else />
      </el-carousel-item>
    </el-carousel>
  </el-dialog>
</template>

<script>
import { adInHomePage } from "@/api/api";

export default {
  async mounted() {
    // const isHomeAdShowed = sessionStorage.getItem("isHomeAdShowed");
    // if (!isHomeAdShowed) {
    //   const adsRes = await adInHomePage();
    //   if (adsRes.msgCode == "1") {
    //     if (adsRes.data.length > 0) {
    //       this.pics = adsRes.data;
    //       this.dialogVisible = true;
    //       sessionStorage.setItem("isHomeAdShowed", true);
    //     }
    //   }
    // }
  },
  data() {
    return {
      dialogVisible: false,
      pics: []
    };
  }
};
</script>

<style lang="stylus" scoped>
.img {
  width: 100%;
}

>>> .el-dialog__header {
  padding: 0;
}

>>> .el-dialog {
  border-radius: 8px;
  overflow: hidden;
}

>>> .el-dialog__body {
  padding: 0px;
}

>>> .el-dialog__headerbtn {
  z-index: 9;

  .el-dialog__close {
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 5px;
    position: relative;
    top: -8px;
    right: -5px;
  }

  .el-dialog__close:hover {
    color: #fff;
    background: rgba(0, 0, 0, 0.6);
  }
}
</style>