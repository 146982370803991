<template>
  <div class="wxloginwx">
    <div class="box">
      <div
        class="imgbox"
        v-loading="loading"
        element-loading-text="二维码加载中"
        element-loading-spinner="el-icon-loading"
      >
        <img :src="QRbase64" v-if="QRbase64" class="qrImg" />
      </div>
      <div class="title">
        打开微信
        <img src="@/assets/login/wx.png" />扫一扫登录
      </div>
    </div>
  </div>
</template>
<script>
import WxLogin from "@/utils/wxLogin";
import { getWxQRCode, checkScanLogin } from "@/api/auth";

export default {
  data() {
    return {
      QRbase64: "",
      sceneStr: "",
      timer: null,
      loading: true
    };
  },
  async mounted() {
    const redirect_uri_base = process.env.VUE_APP_WECHAT_CALLBACK_ROOT;
    let redirect_uri = `${redirect_uri_base}/thirdUser/wxScanLoginCallBack`;
    //redirect_uri = "https://ai-tax.cn/thirdUser/wxScanLoginCallBack";
    //debugger
    redirect_uri = encodeURIComponent(redirect_uri);

    //let rootURL =  window.location.protocol+'//' + window.location.host;
    const resQR = await getWxQRCode();
    if (resQR.code == 200) {
      this.QRbase64 = resQR.data.qrCode;
      this.sceneStr = resQR.data.sceneStr;
      this.loading = false;
    }

    this.timer = setInterval(async () => {

      const scanRes = await checkScanLogin(this.sceneStr);
      //console.log("0");
      //console.log("a",scanRes.data.redirectUrl);
      if (scanRes.data.redirectUrl) {

        this.$message('微信扫码成功');
        clearInterval(this.timer);

        setTimeout(()=>{
          location.href = scanRes.data.redirectUrl;
        },1000)

      }
    }, 1500);
  },
  destroyed() {
    clearInterval(this.timer);
  }
};
</script>
<style lang="stylus" scoped>
.wxloginwx {
  margin: 0 auto;
  width: 100%;
  text-align: center;

  .box {
    margin: 0 auto;
    position: relative;
    left: -10px;
    top: -10px;

    .smdl {
      height: 24px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 24px;
      margin-bottom: 32px;
    }

    .imgbox {
      margin 0 auto;
      width: 200px;
      height: 200px;

      .qrImg {
        width: 200px;
        height: 200px;
      }
    }

    .title {
      height: 22px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      line-height: 22px;
      margin-top: 0px;
      margin-bottom: 53px;

      img {
        width: 16px;
        position: relative;
        top: 3px;
        margin: 0 8px;
      }
    }
  }
}
</style>
