<template>
  <div class="financialRisk-h5">
    <div class="up-down">
      <div class="up" :class="{ down: filter.companyType == 2 }" @click="upBtn">
        上游风险企业
      </div>
      <div
        class="up"
        :class="{ down: filter.companyType == 1 }"
        @click="downBtn"
      >
        下游风险企业
      </div>
    </div>
    <div class="table-wrapper">
      <BigPowerTable
        :data="processedTable"
        v-if="processedTable"
        class="table"
      ></BigPowerTable>
      <div class="sc" @click="switchScreen" v-if="processedTable && close!=2">
        <img src="@/assets/util/hScreen.png" alt="" />
      </div>
      <div
        v-if="processedTableLoading"
        class="processedTable"
        v-loading="processedTableLoading"
      ></div>
    </div>
    <div class="PurchaseAndSale-h5-title">
      *进入PC端查看更多风险详情及解决方案。
    </div>
    <div class="full-sc">
      <el-drawer :visible.sync="horizontalScreen" direction="btt" size="100%">
        <div class="full-tab">
          <div class="table-wrapper">
            <BigPowerTable
              :data="processedTable"
              v-if="processedTable"
              class="table"
            ></BigPowerTable>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import { cloneDeep, debounce } from "lodash";
import { areaList } from "@/api/api";
import BigPowerTable from "big-power-table";
import { getCompanyRiskList, getRiskCompanyList } from "@/api/threeYears";
import CompanyRiskDialog from "@/pages/Result/ThreeYearResult/financialAnalysis/companyRisk/CompanyRiskDialog";

export default {
  components: {
    BigPowerTable,
    CompanyRiskDialog,
  },
  name: "CompanyRiskLookup",
  props: {
    close: [String, Number]
  },
  data() {
    return {
      processedTableLoading: false,
      horizontalScreen: false,
      companyLoading: false,
      areaTree: [],
      customerTypeOptions: [
        {
          label: "上游企业",
          value: 2,
        },
        {
          label: "下游企业",
          value: 1,
        },
      ],
      filterOrigin: {},
      filter: {
        companyType: 2,
        companyName: "",
        time: [],
        startMoneyRanking: "",
        endMoneyRanking: "",
        startMoney: "",
        endMoney: "",
        companyArea: [],
        showCompanyRisk: false,
        judicialRiskList: ["不限"],
        taxationRiskList: ["不限"],
        managementRiskList: ["不限"],
      },
      judicialRiskListPrev: ["不限"],
      taxationRiskListPrev: ["不限"],
      managementRiskListPrev: ["不限"],
      companyNameOptions: [],
      diquPlaceHolder: "请选择",
      table: null,
    };
  },
  mounted() {
    this.filterOrigin = cloneDeep(this.filter);

    this.getAreaList();
    this.loadCompanyRiskList();
  },
  computed: {
    number() {
      if (this.table == null) {
        return 0;
      } else {
        return this.table.body.length;
      }
    },
    processedTable() {
      if (this.table == null) {
        return null;
      }
      for (let i = 0; i < this.table.header.length; i++) {
        this.table.header[i].splice(4, 1);
        this.table.header[i].splice(-1, 1);
      }
      for (let i = 0; i < this.table.body.length; i++) {
        this.table.body[i].splice(4, 1);
        this.table.body[i].splice(-1, 1);
      }
      const header = this.table.header.map((row) => {
        const widths = [
          "240px",
          "136px",
          "120px",
          "120px",
          "160px",
          "320px",
          "64px",
        ];
        return row.map((cell, index) => {
          if (widths[index]) {
            cell.width = widths[index];
          }
          return cell;
        });
      });

      const body = this.table.body.map((row) => {
        return row.map((cell) => {
          if (cell.detail) {
            cell.click = (cell) => {
              this.openCompanyRiskDetailDialog(cell.detail);
            };
          }
          return cell;
        });
      });
      return {
        header: header,
        body: body,
      };
    },
    areaOptions: function () {
      if (!this.areaTree) {
        return [];
      }

      const ret = cloneDeep(this.areaTree);

      function walk(data, level) {
        data.value = data.code;
        data.label = data.name;
        if (level < 1 && data.childNode && data.childNode.length > 0) {
          data.children = data.childNode;
          for (const child of data.children) {
            walk(child, level + 1);
          }
        }
        delete data.childNode;
      }

      for (const areaTree of ret) {
        walk(areaTree, 0);
      }

      return [...ret];

      // return [
      //   {
      //     value: -9999999,
      //     label: "地区",
      //     children: [...ret],
      //   },
      // ];
    },
  },
  methods: {
    switchScreen() {
      // this.horizontalScreen = true;
      this.$router.push({ path: "../companyHp/" + this.$route.params.uuid });
    },
    upBtn() {
      this.handleCompanyTypeChange(2);
    },
    downBtn() {
      this.handleCompanyTypeChange(1);
    },
    handleCompanyTypeChange(val) {
      this.filter = cloneDeep(this.filterOrigin);
      this.judicialRiskListPrev = ["不限"];
      this.taxationRiskListPrev = ["不限"];
      this.managementRiskListPrev = ["不限"];
      this.filter.companyType = val;
      // console.log(this.filter);
      this.loadCompanyRiskList();
    },
    clearCompanyNameAndLoadCompanyRiskList() {
      this.clearCompanyName();
      this.loadCompanyRiskList();
    },
    handleMoneyRankingChange() {
      this.filter.startMoney = "";
      this.filter.endMoney = "";
      this.clearCompanyName();
      this.loadCompanyRiskList();
    },
    handleMoneyChange() {
      this.filter.startMoneyRanking = "";
      this.filter.endMoneyRanking = "";
      this.clearCompanyName();
      this.loadCompanyRiskList();
    },
    clearCompanyName() {
      this.filter.companyName = "";
    },
    handleJudicialRiskListClick(val) {
      this.clearCompanyName();
      if (val.includes("不限") && !this.judicialRiskListPrev.includes("不限")) {
        this.filter.judicialRiskList = ["不限"];
      } else {
        const findIndex = this.filter.judicialRiskList.findIndex(
          (v) => v == "不限"
        );
        if (findIndex >= 0) {
          this.filter.judicialRiskList.splice(findIndex, 1);
        }
      }
      this.judicialRiskListPrev = cloneDeep(this.filter.judicialRiskList);
      this.loadCompanyRiskList();
    },
    handleTaxationRiskListClick(val) {
      this.clearCompanyName();
      if (val.includes("不限") && !this.taxationRiskListPrev.includes("不限")) {
        this.filter.taxationRiskList = ["不限"];
      } else {
        const findIndex = this.filter.taxationRiskList.findIndex(
          (v) => v == "不限"
        );
        if (findIndex >= 0) {
          this.filter.taxationRiskList.splice(findIndex, 1);
        }
      }
      this.taxationRiskListPrev = cloneDeep(this.filter.taxationRiskList);
      this.loadCompanyRiskList();
    },
    handleManagementRiskListClick(val) {
      this.clearCompanyName();
      if (
        val.includes("不限") &&
        !this.managementRiskListPrev.includes("不限")
      ) {
        this.filter.managementRiskList = ["不限"];
      } else {
        const findIndex = this.filter.managementRiskList.findIndex(
          (v) => v == "不限"
        );
        if (findIndex >= 0) {
          this.filter.managementRiskList.splice(findIndex, 1);
        }
      }
      this.managementRiskListPrev = cloneDeep(this.filter.managementRiskList);
      this.loadCompanyRiskList();
    },
    async getRemoteCompanyList(query) {
      if (query !== "") {
        this.loading = true;

        const res = await getRiskCompanyList({
          companyType: this.filter.companyType,
          programId: this.$route.params.uuid,
          companyName: query,
        });

        setTimeout(async () => {
          if (res.code == 200) {
            this.companyNameOptions = res.data.map((v) => {
              return {
                value: v,
                label: v,
              };
            });
            this.loading = false;
          }
        }, 200);
      } else {
        this.companyNameOptions = [];
      }
      //companyNameOptions
    },
    openCompanyRiskDetailDialog(taxNo) {
      this.$refs["CompanyRiskDialog"].open(taxNo);
    },
    loadList() {},
    // 获取地区
    getAreaList() {
      areaList().then((res) => {
        this.areaTree = res.data;
      });
    },
    handleBtnCompanyNameChange(val) {
      const companyType = this.filter.companyType;

      this.filter = cloneDeep(this.filterOrigin);
      this.filter.companyType = companyType;
      this.filter.companyName = val;
      this.loadCompanyRiskList();
    },
    /**
     * 得到表格数据
     * @returns {Promise<void>}
     */
    async loadCompanyRiskList() {
      let judicialRiskList = cloneDeep(this.filter.judicialRiskList);
      if (judicialRiskList.includes("不限")) {
        judicialRiskList = [
          "法律诉讼",
          "被执行人",
          "失信人",
          "严重违法",
          "行政处罚",
          "限制消费令",
        ];
      }

      let taxationRiskList = cloneDeep(this.filter.taxationRiskList);
      if (taxationRiskList.includes("不限")) {
        taxationRiskList = ["税收违法", "欠税", "纳税信用等级低"];
      }

      let managementRiskList = cloneDeep(this.filter.managementRiskList);
      if (managementRiskList.includes("不限")) {
        managementRiskList = [
          "经营状态异常",
          "经营异常",
          "社保人数异常",
          "成立不满6个月",
          "个人独资企业",
          "个体工商户",
        ];
      }

      this.processedTableLoading = true;
      console.log(this.processedTableLoading)

      const params = {
        programId: this.$route.params.uuid,
        companyType: this.filter.companyType,
        companyName: this.filter.companyName,
        companyArea: this.filter.companyArea,
        endMoney: this.filter.endMoney,
        endMoneyRanking: this.filter.endMoneyRanking,
        judicialRiskList: judicialRiskList,
        managementRiskList: managementRiskList,
        startMoney: this.filter.startMoney,
        startMoneyRanking: this.filter.startMoneyRanking,
        taxationRiskList: taxationRiskList,
        desensitizationStatus: this.$route.query.desensitization
      };

      if (this.filter.time && this.filter.time[0]) {
        params.startDate = this.filter.time[0];
        params.endDate = this.filter.time[1];
      }

      const res = await getCompanyRiskList(params);
      this.table = res.data;
      this.processedTableLoading = false;
    },
  },
};
</script>

<style lang="stylus" scoped>
>>> .el-select .el-input__inner {
  // padding-right 0
}

.section-title {
  height: 22px;
  color: #1D2129;
  line-height: 22px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #F2F3F5;
  padding-bottom: 10px;
  margin-top: 48px;

  .title {
    font-size: 16px;
    font-weight: 600;
  }
}

.section-toolbar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 24px;
  margin-bottom: 16px;

  .checkBox-row-wrapper {
    border-top: 1px solid #F2F3F5;
    padding-top: 18px;
  }

  .row {
    display: flex;
    width: 100%;
    margin-bottom: 22px;

    >>> .el-form-item {
      display: flex;

      .el-form-item__label {
        line-height: 1.5;
        display: flex;
        align-items: center;
      }

      .el-form-item__content {
        line-height: 1.5;
      }
    }

    .tip {
      position: relative;
      top: 2px;
    }
  }
}

.table-wrapper {
  background: #FFFFFF;
  // margin 0 0.24rem
  position: relative;

  .sc {
    position: absolute;
    right: 0px;
    bottom: 0px;
    padding: 5px;

    img {
      width: 0.25rem;
      height: 0.25rem;
      background: #FFFFFF;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
      border-radius: 0.08rem;
    }
  }

  .time {
    margin: 20px 16px;
    font-size: 14px;
    font-weight: 400;
    color: #86909C;
  }

  .table {
    >>> .bigPowerTable {
      max-height: 11.8rem;
      overflow: auto;
    }
  }
}

.table-wrapper-24 {
  margin: 0 0.24rem;
  margin-bottom: 0.16rem;
}

.financialRisk-h5 {
  >>> table.table th, >>> table.table td {
    font-size: 0.2rem;
  }
}

.PurchaseAndSale-h5-title {
  font-size: 0.2rem;
  color: #F53F3F;
  line-height: 0.3rem;
  margin: 0.16rem 0 0 0.24rem;
}

.up-down {
  display: flex;
  align-items: center;
  font-size: 0.24rem;
  margin: 0.16rem 0 0.16rem 0.24rem;

  .up {
    width: 1.52rem;
    height: 0.4rem;
    line-height: 0.4rem;
    background: #F7F8FA;
    border-radius: 2px;
    margin-right: 0.24rem;
    text-align: center;
    padding: 2px 4px;
  }

  .down {
    color: #4080FF;
    background: #E8F3FF;
    font-weight: 600;
  }
}

.full-sc {
  .full-tab {
    .t-w {
      display: flex;
      align-items: center;
    }

    transform-origin: center center;
    transform: rotateZ(90deg);

    .titleName {
      font-size: 0.28rem;
      font-weight: 600;
      color: #2B2F58;
      line-height: 0.36rem;
    }

    .dataText {
      font-size: 0.28rem;
      color: #40496A;
      line-height: 0.36rem;
      margin: 12px 0;
    }
  }

  >>> .el-drawer__header {
    padding: 0;
  }

  >>> .el-drawer__body {
    overflow: hidden;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  >>> .el-drawer__open .el-drawer.btt {
    border-radius: 0;
  }

  >>> .el-drawer__close-btn {
    font-size: 30px;
    position: relative;
    right: 10px;
    top: 10px;
  }

  // display: flex;
  // align-items: center;
  // justify-content: center;
  // flex-direction: column;
  .tab-s {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // flex-direction: column;
    // height: 100%;
    max-height: 220px;
    max-width: 500px;
    overflow: auto;

    .tablefirst {
      width: 100%;
      height: 100%;
      overflow: auto;
    }

    .tablefirst tr:nth-child(2n-1) td {
      background: #fff;
    }

    .tablefirst thead tr > th:first-child, .tablefirst tbody tr > td:first-child {
      position: -webkit-sticky;
      position: sticky;
      left: 0;
    }

    .table-screen-b {
      td, th {
        min-width: 80px;
        // max-width: 200px;
      }
    }
  }

  .table {
    >>> .bigPowerTable {
      max-height: 5.04rem;
      max-width: 85vh;
      overflow: auto;
    }
  }
}

.processedTable {
  position: fixed;
  top: 150px;
  bottom: 30px;
  right: 30px;
  left: 30px;
}
</style>
