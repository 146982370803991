<template>
  <!-- 高风险概述 -->
  <div>

    <div class="zd1">

      <div class="box1">
        <div class="desc">
          <div class="item " v-if="pdfData.ruleRiskStatement">
            <div class="title red">问题描述</div>
            {{ pdfData.ruleRiskStatement }}
          </div>
          <div class="item" v-if="pdfData.ruleAdvise">
            <div class="title blue">解决方案</div>
            {{ pdfData.ruleAdvise }}
          </div>
          <div class="item" v-if="pdfData.policy">
            <div class="title blue">法规</div>
            {{ pdfData.policy }}
          </div>

          <div class="item" v-if="pdfData.caseText">
            <div class="title blue">案例</div>
            {{ pdfData.caseText }}
          </div>

        </div>
        <div
          :class="node.showContent ? 'leftnew' : 'left'"
          v-if="
              pdfData.ruleParamsTemplete &&
              !isAllContentEmpty(pdfData.ruleParamsTemplete)
            "
        >


          <RiskProblemEchart
            :list="pdfData.ruleParamsTemplete"
            v-if="pdfData.ruleParamsTemplete"
          ></RiskProblemEchart>

<!--          <template v-if="charts">-->
<!--            <div v-for="(chart,index) in charts" :key="index">-->
<!--              <ColumnChart :data="chart" v-if="chart.imageType=='histogram'||chart.imageType=='twoHistogram'"></ColumnChart>-->
<!--              <LineChart :data="chart" v-if="chart.imageType=='brokenLine'"></LineChart>-->
<!--            </div>-->
<!--          </template>-->
<!--          <template v-else>-->
<!--            <RiskProblemEchart-->
<!--              :list="pdfData.ruleParamsTemplete"-->
<!--              v-if="pdfData.ruleParamsTemplete"-->
<!--            ></RiskProblemEchart>-->
<!--          </template>-->


        </div>
      </div>
    </div>

  </div>
</template>
<script>
import {mapState} from "vuex";
import {merge, cloneDeep} from "lodash";

import RiskProblemEchart from "./RiskProblemEchart";
import ColumnChart from "@/pages/Result/components/chats/ColumnChart";
import LineChart from "@/pages/Result/components/chats/LineChart";

export default {
  components: {
    RiskProblemEchart,
    ColumnChart,
    LineChart
  },
  computed: {
    ...mapState("Result", ["resultData"]),
    typeClass() {
      return this.node.contrastKey == "SWHighRisk" ? "red" : "yellow";
    },
    pdfData() {
      return JSON.parse(this.node.pdfData)
    },
    charts() {
      const data = [{
        "imageType": "brokenLine",
        "series": [
          {"data": ["85.49", "33.23", "70.42"], "name": "产成品"},
        ],
        "xLabels": ["4月", "5月", "6月"]
      }, {
        "imageType": "histogram",
        "series": [
          {"data": ["85.49", "33.23", "70.42"], "name": "产成品"},
          {"data": ["33.23", "82.00", "22.42"], "name": "原材料投入"}
        ],
        "xLabels": ["4月", "5月", "6月"]
      }, {
        "imageType": "brokenLine",
        "series": [
          {"data": ["85.49", "33.23", "70.42"], "name": "产成品"},
          {"data": ["33.23", "82.00", "22.42"], "name": "原材料投入"}
        ],
        "xLabels": ["4月", "5月", "6月"]
      }, {
        "imageType": "histogram",
        "series": [
          {"data": ["82.36", "67.46", "60.06"], "name": "原材料占比"}
        ],
        "xLabels": ["4月", "5月", "6月"],
        "unit": "%"
      },
        {
          "imageType": "twoHistogram",
          "series": [
            {"data": ["85.49", "70.42"], "name": "4月"},
            {"data": ["33.23", "22.42"], "name": "5月"},
          ],
          "xLabels": ["产成品", "原材料投入"]
        }, {
          "imageType": "histogram",
          "series": [
            {
              "data": [
                "85.49",
                "33.23",
                "82.00"
              ],
              "name": "产成品"
            },
            {
              "data": [
                "70.42",
                "22.42",
                "49.25"
              ],
              "name": "原材料投入"
            }
          ],
          "xLabels": [
            "2021年04月",
            "2021年05月",
            "2021年06月"
          ]
        }];

      return data;

    }
  },
  props: {
    node: Object,
  },
  data() {
    return {
      activeNames: "",
      num: 0,
      list: [],
      istrue: false,
      topshow: false,
    };
  },
  mounted() {

  },
  methods: {
    isAllContentEmpty(ruleParamsTemplete) {
      // debugger
      if (!ruleParamsTemplete) {
        return true;
      } else {
        const arr = JSON.parse(ruleParamsTemplete);
        // debugger
      }
    },

  },
};
</script>
<style lang="stylus" scoped>
@import '~@/styles/result.styl';

.grp-title {
  font-size: 18px;
  padding: 16px 24px;
  font-weight: bold;
  background: rgba(255, 255, 255, 1);
  border-bottom: 1px solid rgb(217, 217, 217);
  border-radius: 8px 8px 0px 0px;

  .red {
    color: #F5222D;
  }

  .yellow {
    color: #FAAD14;
  }
}

.risk-problem-wrapper {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 8px 8px 0px 0px;
  background: #fff;

  .bttitle {
    background: rgba(255, 255, 255, 1);
    border-radius: 4px 4px 0px 0px;
    border-bottom: 1px solid #D9D9D9;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    padding: 16px 24px;
  }
}

.zd1 {
  width: 100%;

  .box1 {
    will-change: height;
    box-sizing: border-box;

    .leftnew {
      padding: 24px 24px 0;
      flex-grow: 1;
    }

    .desc {
      padding: 24px;
      width: 100%;
      box-sizing: border-box;
      background: rgba(47, 84, 235, 0.04);
      font-size: 14px;
      line-height: 1.5;
      flex-grow: 0;
      color: #666;

      .item {
        margin-bottom: 24px;

        .title {

        }

        .title.red {
          color: #F5222D;
        }

        .title.blue {
          color: #2F54EB;
        }
      }
    }
  }


}


.tab-content {
  padding: 20px 10px;
}

.riskgay {
  margin: 0 auto;

  .right_c {
    margin: 0 auto;
    background: #f5f7fc;
    overflow: hidden;
    width: 96%;

    .bgimg {
      margin-top: 30px;
      z-index: 1;
      margin-left: 15px;
      width: 96%;
    }

    .section {
      background: #fff;
      position: relative;
      top: -60px;
      margin-left: 30px;
      z-index: 2;
      // margin: 15px;
      border-radius: 8px;
      padding: 20px;
      width: 91.5%;

      .header {
        font-size: 22px;
        color: #062340;
        border-bottom: 1px solid #D4E7FF;
        padding-bottom: 15px;
      }

      .tab-wrapper {
        position: relative;

        >>> .el-tabs {
          .el-tabs__header {
            min-height: 250px;
            max-height: 500px;
            overflow-y: scroll;
            scrollbar-width: none; /* firefox */
            -ms-overflow-style: none; /* IE 10+ */
          }

          .el-tabs__header::-webkit-scrollbar {
            display: none; /* Chrome Safari */
          }

          .el-tabs__item {
            text-align: left;
          }
        }
      }
    }
  }
}

>>> .el-tabs__header {
  background: #F5F9FD;
  min-height: 400px;

  .el-tabs__item {
    line-height: 1.5;
    width: 320px;
    white-space: normal;
    padding: 15px 20px 15px 10px;
    font-size: 16px;
    box-sizing: border-box;
    height: auto;
  }
}

.problem-list {
  color: #6B7897;

  .title {
    font-weight: bold;
    margin: 20px 0;
  }
}
</style>
