<template>
  <div>
    <div ref="theChart" class="echart" :style="{height: height}"></div>
  </div>
</template>

<script>
import city from 'echarts/map/json/china.json'
//import city from 'echarts/map/json/china-cities.json'
import echarts from 'echarts'
import {getAutoMiddlePageToConfig} from "../../../../utils/helper";


const zunyiData = [
  {
    "lat": 27.535288,
    "lng": 106.831668,
    "name": "遵义县",
    "level": "district",
    "parent": "遵义市"
  },
]

function randomData() {
  return Math.random() * 1;
}

export default {
  props: {
    type: String,// 2 供应商 1 客户
    geoCoordMap: Object,
    valueData: Array,
    self: Object,
    height: {
      default :"450px",
      type: String
    }
  },
  data() {
    return {
      myChartChina:null,
      provinceNames: [
        "北京",
        "天津",
        "海南",
        "上海",
        "重庆",
        "河北",
        "河南",
        "云南",
        "辽宁",
        "黑龙江",
        "湖南",
        "安徽",
        "山东",
        "新疆",
        "江苏",
        "浙江",
        "江西",
        "湖北",
        "广西",
        "甘肃",
        "山西",
        "内蒙古",
        "陕西",
        "吉林",
        "福建",
        "贵州",
        "广东",
        "青海",
        "西藏",
        "四川",
        "宁夏",
        "台湾",
        "香港",
        "澳门",]
    };
  },
  mounted() {

    this.drawLine()
  },
  computed: {

    selfData() {
      return [{
        name: this.self.cityName,
        value: [this.self.lng,this.self.lat]
      }]
    },
    seriesData() {
      let data = this.valueData.map(value => {
        const ret = {
          ...value,
          name: value.cityName,
          value: value.scale
        };

        return ret
      }).filter(v=>v.name!="合计")

      //把省名整理成地图插件能识别的省名
      this.standardizeProvinces(data)

      return data;
    },
    riskSeriesData() {
      return this.seriesData.filter(v => v.isRisk)
    },
    noRiskSeriesData() {
      return this.seriesData.filter(v => !v.isRisk)
    }
  },

  methods: {
    highlight(city){
      this.myChartChina.dispatchAction({
        type: 'downplay', // 取消高亮指定的数据图形
        seriesIndex: 0
      })
      this.myChartChina.dispatchAction({
        type: 'highlight', // 高亮指定的数据图形。通过seriesName或者seriesIndex指定系列。如果要再指定某个数据可以再指定dataIndex或者name。
        seriesIndex: 0,
        name: city
      })
    },
    /**
     * 标准化省名
     * @param provinceData 单个省的数据
     * @returns {*}
     */
    standardizeProvinceName(provinceData) {
      const theProvinceName = this.provinceNames.find(provinceName => provinceData.name.includes(provinceName))
      provinceData.name = theProvinceName;
      return provinceData;
    },
    /**
     * 标准化省名
     * @param provinceData 所有省的数据
     * @returns {*}
     */
    standardizeProvinces(data){
      data.forEach(provinceData=>{
        this.standardizeProvinceName(provinceData)
      })
    },
    drawLine() {

      echarts.registerMap('china', city)
      var myChartContainer = this.$refs['theChart']; //绑定div容器

      var resizeMyChartContainer =  () =>{
        myChartContainer.style.width = 100 + "%";
        myChartContainer.style.height = this.height;
      };

      resizeMyChartContainer();

      this.myChartChina = echarts.init(myChartContainer);


      // 绘制图表
      var optionMap = {
        toolbox: {
          show: true
        },
        tooltip: {
          trigger: "item",
          formatter: (params) => {
            if (params.data&&params.data.isSelf) {
              return "本公司所在地";
            }else{
              const name = params.name;
              const province = this.seriesData.find(v=>v.name==name)
              let label = "不含税金额";
              if(this.type==1){ //客户
                label = "不含税销售额"
              }else{//供应商
                label = "不含税采购额"
              }

              if(province){

                return `
                <div>${params.name}:</div>
                <div>${label}：${province.money}万元</div>
                <div>企业数量：${province.companyNumber}</div>
                  `;
              }else{
                return `
                <div>${params.name}:</div>
                <div>${label}：0万元</div>
                <div>企业数量：0</div>
                  `;
              }

            }

          }
        },

        geo: {
          map: 'china',
          itemStyle: {
            areaColor: '#EEEEEE',
            borderColor: '#efefef',
          },
          zoom: 1, //当前视角的缩放比例
          roam: false, //是否开启平游或缩放
          scaleLimit: { //滚轮缩放的极限控制
            min: 1,
            max: 10
          },
          emphasis: {
            itemStyle: {
              areaColor: '#efefef'
            },
            label: {
              show: false
            }
          }
        },

        //左侧小导航图标

        visualMap: {
          show: false,
          x: "left",
          y: "center",

          //改变地图区域颜色
          pieces: [
            {gt: 0.5, color: "#207DFB"},
            {gt: 0.3, lte: 0.5, color: "#6EAAFC"},
            {gt: 0.2, lte: 0.3, color: "#AED0FF"},
            {gt: 0,lt: 0.2, color: "#E0EDFF"}
          ]

        },


        //配置属性

        series: [
          {

            name: "数据",

            type: "map",

            mapType: "china",

            roam: false,

            label: {

              normal: {

                show: false, //省份名称

              },

              emphasis: {

                show: false,

              },

            },

            data: this.seriesData, //数据

          },
          {
            name: '本公司地点',
            type: "scatter",
            coordinateSystem: "geo",
            symbolSize: 10,
            label: {
              borderType: 'solid',
              position: ['50%', '50%'],
              normal: {
                show: true,
                position: 'right',
                formatter: '{b}',
                color: '#EC5757',
                fontWeight: 'bolder',
                fontSize: '12',
                backgroundColor: '#fff'
              }
            },
            //symbol: 'image://' + 'https://shuian-file.oss-cn-hangzhou.aliyuncs.com/image/icon_triangle.png',
            data: this.selfData.map(item => {
              return {
                name: item.name,
                value: [parseFloat(item.value[0]), parseFloat(item.value[1]) + 0.1],
                isSelf: 1
              };
            })

          }

        ],

      };


      this.myChartChina.setOption(optionMap);


      this.myChartChina.on('click', (params) => {
        var city = params.name;

        if(city){
          this.$emit("click",city);
        }else{
          this.$emit("click",null);
        }
        this.highlight(city)
        //this.$router.push(getAutoMiddlePageToConfig(this.$route.params.uuid, this.type, this.$route, {area: city.replace("①", "").replace("②", "").replace("③", "")}));
      });


    }

  }

};
</script>

<style lang="stylus" scoped>
.echart
  height 100%
</style>
