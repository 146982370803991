import {ext} from '@antv/matrix-util';
import * as G6 from "@antv/g6";

const transform = ext.transform;

G6.registerNode('companyRisk', {
  drawShape: (cfg, group) => {
    const rect = group.addShape('text', {
      attrs: {
        text: cfg.companyName,
        x: 0,
        y: 2,
        fontSize: 12,
        textAlign: cfg.textAlign || 'left',
        textBaseline: 'middle',
        fill: '#000000',
      },
      // must be assigned in G6 3.3 and later versions. it can be any value you want
      name: 'company-risk-shape',
      draggable: true,
    });
    if (cfg.risk && cfg.risk.length > 0) {
      group.addShape('text', {
        attrs: {
          text: `(${cfg.risk})`,
          x: 0,
          y: 22,
          fontSize: 12,
          textAlign: cfg.textAlign || 'left',
          textBaseline: 'middle',
          fill: 'rgb(234, 108, 91)',
        },
        // must be assigned in G6 3.3 and later versions. it can be any value you want
        name: 'company-risk-shape',
        draggable: true,
      })
    }

    return rect
  },
})
