import {isDesensitizationUuid} from "@/utils/helper";

let mixin = {
  computed: {
    //是否是脱敏模式
    isDesensitizationMode(){
      return isDesensitizationUuid(this.uuid)
    },
  }
};

export default mixin;
