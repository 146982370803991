<template>
  <div class="h5-wrapper">
    <div class="title">企业财税风险分析</div>
    <div class="content-sections">
      <div class="content-section">
        <div class="title0">风险一</div>
        <div class="title1">其他应付款与负债比例异常预警</div>
        <div class="badge">风险说明</div>
        <div class="text">其他应付款通常情况用来核算与主营业务无关的应支付项目，比如：尚未支付的内部员工报销、收到押金或者保证金、其他零星采购等，其绝对额不会过大。贵公司其他应付款相对于负债的比例过高，则可能存在通过暂估应付项目控制利润达到少交或缓交企业所得税的目的。同时还设置了应付账款、应付职工薪酬的相关指标对暂估应付项目的风险进行“围剿”。</div>
        <div class="badge gray">应对方案</div>
        <div class="text">其他应付款通常情况用来核算与主营业务无关的应支付项目，比如：尚未支付的内部员工报销、收到押金或者保证金、其他零星采购等，其绝对额不会过大。贵公司其他应付款相对于负债的比例过高，则可能存在通过暂估应付项目控制利润达到少交或缓交企业所得税的目的。同时还设置了应付账款、应付职工薪酬的相关指标对暂估应付项目的风险进行“围剿”。</div>
      </div>
      <div class="content-section">
        <div class="title0">风险一</div>
        <div class="title1">其他应付款与负债比例异常预警</div>
        <div class="badge">风险说明</div>
        <div class="text">其他应付款通常情况用来核算与主营业务无关的应支付项目，比如：尚未支付的内部员工报销、收到押金或者保证金、其他零星采购等，其绝对额不会过大。贵公司其他应付款相对于负债的比例过高，则可能存在通过暂估应付项目控制利润达到少交或缓交企业所得税的目的。同时还设置了应付账款、应付职工薪酬的相关指标对暂估应付项目的风险进行“围剿”。</div>
        <div class="badge gray">应对方案</div>
        <div class="text">其他应付款通常情况用来核算与主营业务无关的应支付项目，比如：尚未支付的内部员工报销、收到押金或者保证金、其他零星采购等，其绝对额不会过大。贵公司其他应付款相对于负债的比例过高，则可能存在通过暂估应付项目控制利润达到少交或缓交企业所得税的目的。同时还设置了应付账款、应付职工薪酬的相关指标对暂估应付项目的风险进行“围剿”。</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TaxRiskAnalysis"
}
</script>

<style lang="stylus" scoped>
.h5-wrapper {
  padding 20px

  >.title {
    font-size: 24px;
    font-weight: 600;
    color: #1D2129;
    line-height: 36px;
    letter-spacing: 2px;
  }

  .content-sections {
    margin-top: 20px

    .content-section {
      width: 100%
      background: #E5F0FF;
      border-radius: 16px;
      padding 20px;
      box-sizing border-box
      margin-bottom 20px
      .title0{
        font-size: 16px;
        font-weight: 400;
        color: #1D2129;
        line-height: 24px;
      }
      .title1{
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #E86452;
        line-height: 21px;
        text-align center
        margin-top 20px
      }
      .badge{
        background: #A7C1FC;
        border-radius: 16px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        display flex
        align-items center
        justify-content center
        width: 80px;
        line-height 32px
        margin-top 20px
        margin-bottom 10px
      }
      .badge.gray{
        background: #7585A2;
      }
      .text{
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4E5969;
        line-height: 21px;
        letter-spacing: 1px;
      }
    }
  }

}
</style>
