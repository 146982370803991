<template>
  <!-- <div class="full-sc">
    <el-drawer :visible.sync="horizontalScreen" direction="btt" size="100%">
      <div class="full-tab">
        <UpDownCustomerNetWork2 type="1"></UpDownCustomerNetWork2>
      </div>
    </el-drawer>
  </div> -->
  <div class="full-sc-1">
    <div class="close" @click="close">
      <img src="@/assets/test/part-close.png" alt="" />
    </div>
    <div class="table-wrapper-1">
      <UpDownCustomerNetWork2 type="1"></UpDownCustomerNetWork2>
    </div>
  </div>
</template>

<script>
import "@/pages/Result/ThreeYearResult/global.styl";
import UpDownCustomerNetWork2 from "@/pages/Result/H5FinancialAnalysis/UpDownCustomerNetWork2";

export default {
  name: "UpDownCustomer",
  components: {
    UpDownCustomerNetWork2,
  },

  data() {
    return {};
  },
  methods: {
    close() {
      this.$router.back();
    },
    stop() {
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", mo, false); //禁止页面滑动
    },
  },
};
</script>

<style lang="stylus" scoped>
.full-sc-1 {
  position: fixed;
	width: 100vh;
	height: 100vw;
	// top: 0;
	// left: 100vw;
	// -webkit-transform: rotate(90deg);
	// -moz-transform: rotate(90deg);
	// -ms-transform: rotate(90deg);
	// transform: rotate(90deg);
	// transform-origin: 0% 0%;
  // background: #fff;
  // z-index: 9999;
  // display: flex;
  // align-items: center;
  // justify-content: center;

  .close {
    position: absolute;
    top: 10px;
    left: 10px;

    img {
      padding: 10px;
      width: 15px;
      height: 15px;
    }
  }

  .table-wrapper-1 {
    // width: 10rem;
    // max-height: 5.8rem;
    // overflow: auto;
  }
}
</style>
