<template>
  <!-- 风险分类 -->
  <div class="riskfl">
    <div class="box-card">
      <div class="wrapper">
        <div class="title">风险等级分类</div>
        <div class="dengji">
          <div class="right" v-if="chartData[0]">
            <div class="thermometers">
              <div class="item">
                <div class="text high">{{chartData[0].value}} 个高风险</div>
                <div class="thermometer-wrapper">
                  <img
                    :src="'https://node.ai-tax.cn/thermometer?format=png&orientation=horizontal&progress='+chartData[0].value/total*100+'&scale=1&type=high'"
                    class="thermometer"
                  />
                </div>
              </div>
              <div class="item">
                <div class="text mid">{{chartData[1].value}} 个中风险</div>
                <div class="thermometer-wrapper">
                  <img
                    :src="'https://node.ai-tax.cn/thermometer?format=png&orientation=horizontal&progress='+chartData[1].value/total*100+'&scale=1&type=mid'"
                    class="thermometer"
                  />
                </div>
              </div>
              <div class="item">
                <div class="text low">{{chartData[2].value}} 个低风险</div>
                <div class="thermometer-wrapper">
                  <img
                    :src="'https://node.ai-tax.cn/thermometer?format=png&orientation=horizontal&progress='+chartData[2].value/total*100+'&scale=1&type=low'"
                    class="thermometer"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import echarts from "echarts";
import { mapGetters, mapMutations } from "vuex";
import { cloneDeep } from "lodash";

export default {
  props: {
    indexes: Array
  },
  computed: {
    ...mapGetters("ResultEditor", ["findTheNodeByIndexes"]),
    data() {
      return this.findTheNodeByIndexes(this.indexes);
    },
    pdfData() {
      return JSON.parse(this.data.pdfData);
    },
    chartData() {
      const ret = [{ value: 0 }, { value: 0 }, { value: 0 }];
      Object.entries(this.pdfData).forEach(([key, val]) => {
        ret[key - 1] = { value: val, name: key };
      });

      return ret;
    },
    total() {
      const total = this.chartData.reduce((prev, current) => {
        return prev + current.value;
      }, 0);

      //如果合计为0 就返回1
      return total || 1;
    }
  }
};
</script>
<style lang="stylus" scoped>
@import '~@/styles/result.styl';

.riskfl {
  box-sizing: border-box;
  margin-bottom: 16px;

  .thermometers {
    margin-top: 32px;
    width: 100%;

    .item {
      display: flex;
      align-items: center;
      height: 41px;
      margin-bottom: 32px;

      .text {
        width: 150px;
        text-align: right;
        flex: 0 0 auto;
      }

      .text.high {
        font-weight: bold;
        color: rgba(245, 34, 45, 1);
        line-height: 36px;
        font-size: 24px;
      }

      .text.mid {
        font-size: 20px;
        font-weight: 500;
        color: rgba(250, 173, 20, 1);
        line-height: 30px;
      }

      .text.low {
        font-size: 16px;
        font-weight: 500;
        color: rgba(82, 196, 26, 1);
        line-height: 24px;
      }

      .thermometer-wrapper {
        margin-left: 22px;
      }
    }
  }
}

.box-card {
  background: none;
  border: none;
  box-shadow: none;
}

.title {
  border-bottom: 1px solid #eee;
  padding: 16px 24px;
  font-weight: 600;
  background: #fff;
  border-radius: 8px 8px 0px 0px;
  font-size: 18px;
  color: #333;
  line-height: 25px;
}

.dengji {
  display: flex;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  background: #fff;

  .left {
    margin-right: 16px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    padding: 24px;
    background: #fff;
    flex-basis: 1;

    .content {
      padding-right: 0;
    }
  }

  .right {
    width: 100%;
    padding: 24px;
    flex-basis: 1;
  }
}
</style>
