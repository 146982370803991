<template>
  <div class="page-wrapper">
    <div class="title-back">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ name: 'homePage' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ name: 'test-hub' }">风险检测</el-breadcrumb-item>
          <el-breadcrumb-item>发票采集</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>

      <div class="mainheader">
        <main-header>
          <template slot="title">数据采集流程</template>
        </main-header>
        <div class="processContainer" v-if="version != 5">
          <span>
            <img class="processLImg" src="./images/1.png" alt />
          </span>
          <span class="process1">申报数据读取</span>
          <span class="process-status"></span>
          <span>
            <img class="processLImg" src="./images/2.png" alt />
          </span>
          <span class="process1">余额表上传</span>
          <span class="process-status"></span>
          <span>
            <img class="processLImg" src="./images/3.png" alt />
          </span>
          <span class="process1">发票数据采集</span>
        </div>
        <div class="rightProcess"></div>
      </div>
    </div>

    <div class="section-box">
      <ValidationObserver ref="validationObserver">
        <div class="titleFix">发票数据采集</div>
        <div class="form-wrapper">
          <div class="left-wrapper left-100">
            <div class="item">
              <ValidationProvider name="纳税人识别号：" rules="required|taxpayerID">
                <div slot-scope="{ classes, errors }">
                  <div class="label">
                    <span class="required-icon">*</span> 纳税人识别号：
                  </div>
                  <div class="input-wrapper">
                    <input
                      type="text"
                      class="input"
                      placeholder="请输入"
                      :class="classes"
                      :disabled="!!invoiceNsrsbhFromApi"
                      v-model.trim="taxPayerID"
                    />
                    <p class="validate-error">{{ errors[0] }}</p>
                  </div>
                </div>
              </ValidationProvider>
            </div>
          </div>
        </div>
      </ValidationObserver>
    </div>
    <!--发票提取-->
    <InvoiceExtractGuide></InvoiceExtractGuide>
    <!--/发票提取-->

    <!--数据上传组件-->
    <TestDataUploader
      ref="testDataUploader"
      :bankId="bankId"
      :productID="productID"
      :productName="productName"
      :uuid="uuid"
      :zq="zq"
      :version="version"
      :inVoiceMobile="mobile"
      :inVoiceTaxPayerID="taxPayerID"
      :inVoiceEmail="email"
      :inVoiceCompanyName="companyName"
    ></TestDataUploader>
    <!--/数据上传组件-->

    <loading
      :active.sync="isLoading"
      backgroundColor="#000"
      :can-cancel="true"
      :is-full-page="fullPage"
      loader="Dots"
      color="#2f54eb"
    ></loading>

    <sa-footer>
      <div class="footer">
        <div @click="toPrevious" class="btn btn-default btn-next btn-right-none">
          上一步
        </div>
        <button @click="handleBtnNext2" class="btn btn-primary btn-next">完成提交</button>
      </div>
    </sa-footer>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import { mapState } from "vuex";
import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "@/pages/components/MainHeader";
import ProgressDiagram from "@/pages/components/ProgressDiagram";
import InvoiceExtractGuide from "./components/InvoiceExtractGuide";
import Footer from "../ChooseTable/components/Footer";
import TestDataUploader from "../Components/TestDataUploader";

import { localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";

localize("zh_CN", zh_CN);

//在加载的样式
import "vue-loading-overlay/dist/vue-loading.css";

import { genTestReport, getbasicinfo } from "@/api/api";
import { async } from "q";

export default {
  components: {
    Loading,
    BreadCrumb,
    MainHeader,
    ProgressDiagram,
    InvoiceExtractGuide,
    saFooter: Footer,
    TestDataUploader,
  },
  props: {
    bankId: String,
    productID: [String, Number],
    productName: String,
    zqLocation: [String, Number],
    uuid: String,
    zq: String,
    version: [Number, String],
    bill: [Number],
    isShanDong: [Number, String],
  },
  data: function () {
    return {
      deleteDataType: this.$route.query.deleteDataType,  //数据保留14天
      invoiceNsrsbhFromApi: null, //来自企业接口的invoiceNsrsbh
      isLoading: false,
      fullPage: true,
      companyName: "",
      email: "",
      taxPayerID: "",
      mobile: "",
      productid: "",
      bankid: "",
    };
  },
  computed: {
    ...mapState(["companyInfo", "authorization"]),
    ...mapState("UploadTable", ["permissionID"]),
  },
  mounted() {
    //获取nsrsmb
    let user = JSON.parse(localStorage.getItem("user"));
    if (!user) return;
    let id = user.companyId;
    getbasicinfo(id).then((res) => {
      if (res.msgCode == 1) {
        this.invoiceNsrsbhFromApi = res.data.nsrsbh; //公司接口的nsrsbh就是invoiceNsrsbh
        this.taxPayerID = this.invoiceNsrsbhFromApi;
      }
    });

    this.isLoading = true;

    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  },
  methods: {
    // 返回上一页以及浏览器禁止返回 可以优化
    toPrevious() {
      if (this.version == 5) {
        this.$router.push({
          name: "choose-table",
        });
        return;
      }
      let status = sessionStorage.getItem("method");

      if (status == 1) {
        this.$router.push({
          name: "test-Balance",
          query: { deleteDataType: this.deleteDataType },
        });
      } else {
        this.$router.push({
          name: "upload-table",
          query: { deleteDataType: this.deleteDataType },
        });
      }
    },

    // 拉表走check接口
    checkOld() {
      let data = this.$store.state;
      let getDataWay = sessionStorage.getItem("method") == 2 ? 1 : 2;
      if (this.version == 5) {
        getDataWay = 1;
      }
      genTestReport({
        appId: localStorage.getItem("appid_3rd") || "",
        token: data.authorization,
        nsrsbh: data.companyInfo.nsrsbm,
        tag: this.version,
        uuid: this.uuid,
        zq: this.zq,
        invoiceNsrsbh: this.taxPayerID && this.taxPayerID.trim(),
        getDataWay: getDataWay,
        deleteDataType: this.deleteDataType,
        nameless:sessionStorage.getItem('isAnonymous')==='true'
      }).then((res) => {
        if (res.msgCode == 1) {
          localStorage.removeItem("taxSiteUsername");
          this.loading.close();
          this.$router.push({
            name: "test-InvoiceUpload",
          });
        } else if (res.msgCode == -1) {
          this.loading.close();
          //如果遇到"该服务暂不可用"就弹出错误信息并返回首页
          this.$alert(res.msgInfo, "错误", {
            confirmButtonText: "确定",
            type: "error",
            callback: (action) => {
              this.$router.push({ name: "homePage" });
            },
          });
        } else {
          this.loading.close();
          this.$message({
            message: res.msgInfo,
            type: "error",
            duration: 5000,
            callback: (action) => {
              alert("dasdsa");
            },
          });
        }
      });
    },

    handleBtnNext2() {
      this.$refs["validationObserver"].validate().then((success) => {
        if (!success) {
          return;
        }
        this.loading = this.$loading({
          lock: true,
          text: "正在提交数据！",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        this.checkOld();
      });
    },
  }
};
</script>

<style lang="stylus" scoped>
.page-wrapper {
  padding-bottom: 100px;
}

.form-wrapper {
  display: flex;
  margin-top: 24px;
  padding: 0 24px 24px 24px;

  .left-wrapper {
    width: 50%;
  }

  .item {
    color: #666;
    font-size: 14px;

    .required-icon {
      color: #F5222D;
      margin-right: 4px;
    }

    .label {
      width: 150px;
      display: inline-block;
      text-align: right;
      margin-right: 8px;
      vertical-align: top;
      line-height: 36px;
    }

    .input {
      border: 1px solid #D9D9D9;
      width: 400px;
      height: 36px;
      line-height: 36px;
      padding: 8px 16px;
      box-sizing: border-box;
      border-radius: 3px;
      outline: none;
    }

    .input-wrapper {
      display: inline-block;
    }
  }

  .info {
    margin-left: 50px;
    margin-top: 25px;
  }
}

.btn-right-none {
  margin-right: 16px !important;
}

.form-wrapper .left-100 {
  width: 100%;
}

.title-back {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 0px 0px rgba(238, 238, 238, 1);
}

>>> .bread-crumb-wrapper {
  margin-top: 0;
}

.process1 {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.processLImg {
  position: relative;
  bottom: 1px;
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.processContainer {
  display: flex;
  align-item: center;
  position: relative;
  top: 14px;
}

.mainheader {
  display: flex;
  justify-content: space-between;
}

.process-status {
  width: 120px;
  height: 2px;
  background: rgba(0, 0, 0, 0.8);
  position: relative;
  top: 8px;
  margin: 0 24px;
}

.rightProcess {
  width: 10%;
  margin-left: 50px;
}

.titleFix {
  height: 54px;
  line-height: 54px;
  background: rgba(255, 255, 255, 1);
  border-bottom: 1px solid rgba(238, 238, 238, 1);
  border-radius: 8px 8px 0px 0px;
  padding-left: 16px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}
</style>
