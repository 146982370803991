<template>
  <div></div>
</template>

<script>
import { mapMutations } from "vuex";
import { getUserByMobile } from "@/api/auth";

export default {
  async mounted() {
    const mobile = this.$route.params.mobile;
    if (mobile) {
      const data = await getUserByMobile(mobile);
      if (data.msgCode == "1") {
        this.login(data.data);
      }
    }
  },
  methods: {
    ...mapMutations(["changeLogin"]),
    login(data) {
      let user = data.user;
      let token = data.token;
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", JSON.stringify(token));
      // 将用户token保存到vuex中
      this.changeLogin({ authorization: token.token });
      this.$router.push({ path: "/" });
    }
  }
};
</script>

<style>
</style>