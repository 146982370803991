<template>
  <!-- <div v-if="loaded">
    <div class="financialRisk-h5-container">
      <div class="financialRisk-h5">
        <div class="company-baseName">
          <div class="company-tip"></div>
          <div class="company-tip-base">财务分析</div>
        </div>
        <FinanceAnalyze :data="data.data[0].data"></FinanceAnalyze>
      </div>
    </div>
  </div> -->

  <div v-if="data">
    <div class="full-sc-any" v-if="data && data.data[0]">
      <div class="close" @click="close">
        <img src="@/assets/test/part-close.png" alt="" />
      </div>
      <div class="table-wrapper-1" v-if="data">
        <BigPowerTable
          v-if="data"
          :data="data.data[0].data.data"
          class="table"
        ></BigPowerTable>
      </div>
    </div>
  </div>
</template>

<script>
import "@/pages/Result/ThreeYearResult/global.styl";
import PurchaseAndSaleMoney from "@/pages/Result/H5FinancialAnalysis/purchaseAndSale/PurchaseAndSaleMoney";
import { getCompanyInfo, getDupont } from "@/api/threeYears";
import PurchaseAndSaleCategoryCompare from "@/pages/Result/H5FinancialAnalysis/purchaseAndSale/PurchaseAndSaleCategoryCompare";
import FinanceAnalyze from "@/pages/Result/H5FinancialAnalysis/financeAndDuban/FinanceAnalyze";
import DubanAnalyze from "@/pages/Result/H5FinancialAnalysis/financeAndDuban/DubanAnalyze";

export default {
  components: {
    FinanceAnalyze,
    DubanAnalyze,
  },
  name: "FinanceAndDuban",
  data() {
    return {
      activeIndex: "1",
      loaded: false,
      data: null,
      companyInfo: null,
    };
  },
  async mounted() {
    this.loaded = false;
    await this.loadDupont();
    await this.loadCompanyInfo();
    this.loaded = true;
  },
  methods: {
    async loadCompanyInfo() {
      const res = await getCompanyInfo({ programId: this.$route.params.uuid });
      this.companyInfo = res.data;
    },
    async loadDupont() {
      const res = await getDupont({ programId: this.$route.params.uuid });
      this.data = res.data;
    },
    /**
     * 顶部菜单点击
     * @param {string} key
     * @param {array<string>}keyPath
     */
    handleTopMenuSelect(key, keyPath) {
      this.activeIndex = key;
    },
    close() {
      this.$router.back();
    },
  },
};
</script>

<style lang="stylus" scoped>
.full-sc-any {
  position: fixed;
  width: 100vh;
  height: 100vw;
  top: 0;
  left: 100vw;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  transform-origin: 0% 0%;
  background: #fff;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  >>> .table .bigPowerTable {
    max-height: 5.5rem;
    overflow: auto;
    max-width: 10rem;
  }

  >>> table.table th, >>> table.table td {
    font-size: 0.2rem;
  }

  .close {
    position: absolute;
    top: 10px;
    left: 10px;

    img {
      padding: 10px;
      width: 15px;
      height: 15px;
    }
  }
}
</style>
