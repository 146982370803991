<template>
  <div>
    <div class="whiteStyle"></div>
    <div style="padding: 12px; font-size: 14px" class="h5-wrapper">
      <div v-for="(v, k) of detailsList" :key="k">
        <div v-for="(a, b) of v.children" :key="b">
          <div v-for="(value, index) of a.children" :key="index" class="single">
            <!-- <span>{{ value.orderNumber }}</span> -->
            <div class="title" :id="'title' + value.id">{{ value.titleName }}</div>
            <!-- 发票模型开始 -->
            <div class="shuoming" v-if="value.type == 1">分析结果</div>
            <div v-if="value.type == 1" class="fontStyle">{{ value.dataText }}</div>
            <GenericTable :data="value.table" class="table" v-if="value.type == 1"></GenericTable>
            <!-- 发票模型结束 -->
            <!-- 税务开始 -->
            <ColumnChart
              v-if="value.type == 2 && value.pdfDatas.ruleParamsTemplete"
              :data="JSON.parse(value.pdfDatas.ruleParamsTemplete)"
            >
            </ColumnChart>
            <div class="shuoming" v-if="value.type == 2">风险说明</div>
            <div v-if="value.type == 2" class="fontStyle">
              {{ value.pdfDatas.ruleRiskStatement }}
            </div>
            <div class="fangan green" v-if="value.type == 2">应对方案</div>
            <div v-if="value.type == 2" class="fontStyle">{{ value.pdfDatas.ruleAdvise }}</div>
            <div class="fangan" v-if="value.type == 2 && value.pdfDatas.caseText != ''">
              法规案例
            </div>
            <div v-if="value.type == 2" class="fontStyle">{{ value.pdfDatas.caseText }}</div>
            <!-- 税务结束 -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getRiskDetailList } from "@/api/bossWatchReport";
import GenericTable from "./components/GenericTable.vue";
import ColumnChart from "./components/ColumnChart.vue";

export default {
  components: {
    GenericTable,
    ColumnChart,
  },
  computed: {},
  data() {
    return {
      detailsList: [],
    };
  },
  watch: {
    $route(val) {
      console.log(val);
      if (val.name == "ReportDetails") {
        let name = this.$route.params.name;
        let titles = document.querySelectorAll(".title");
        let value = Array.from(titles).find((i) => i.innerText === name);
        console.log(Array.from(titles));
        console.log(value);

        if (value) {
          document.getElementById(value.id).scrollIntoView();
        }
      }
    },
  },
  async mounted() {
    this.getData();
    setTimeout(() => {
      let name = this.$route.params.name;
      let titles = document.querySelectorAll(".title");
      let value = Array.from(titles).find((i) => i.innerText == name);
      if (value) {
        document.getElementById(value.id).scrollIntoView();
      }
    }, 500);
  },

  methods: {
    generalTableData(pdfData) {
      if (!pdfData) {
        return null;
      }

      if (!pdfData.body) {
        return null;
      }
      const ret = [];

      for (let i = 0; i < pdfData.body.length; i++) {
        ret[i] = [];
        if (pdfData.pdfHeader) {
          pdfData.pdfHeader.forEach((key) => {
            ret[i].push(pdfData.body[i][key]);
          });
        } else {
          pdfData.header.forEach((key) => {
            ret[i].push(pdfData.body[i][key]);
          });
        }
      }

      return {
        data: ret,
        labels: pdfData.pdfHeader ? pdfData.pdfHeader : pdfData.header,
        //anchorPointType: pdfData.anchorPointType,
      };
    },
    async getData() {
      const params = {
        uuid: this.$route.params.uuid,
      };
      const res = await getRiskDetailList(params);
      let list = res.data;
      console.log(list);
      list.forEach((i) => {
        i.children.forEach((item) => {
          item.children.forEach((v) => {
            if (v.pdfData) {
              v.pdfDatas = JSON.parse(v.pdfData);
              v.table = this.generalTableData(JSON.parse(v.pdfData));
            }
          });
        });
      });
      this.detailsList = list;
    },
  },
};
</script>
<style lang="stylus" scoped>
.title{
    text-align: center;
    color:#E86452;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    margin 20px auto;
}
.single{
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0px 1px 10px 2px rgba(0,0,0,0.1);
    border-radius: 16px;
    margin-bottom 12px
}
.shuoming{
    width:80px;
    height:32px;
    background: #A7C1FC;
    border-radius: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 32px;
    text-align: center;
    font-size 14px;
    margin-bottom:20px;
    margin-top: 20px;

}
.fangan{
    width:80px;
    height:32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    background: #26609A;
    border-radius: 16px;
    color: #FFFFFF;
    line-height: 32px;
    text-align: center;
    font-size 14px;
    margin-top: 20px;
    margin-bottom:20px;
}
.green{
  background: #6FC1AF;
  margin-top: 20px;
  margin-bottom:20px;
}
.fontStyle{
color: #4E5969
font-size: 14px;
letter-spacing: 2px;
line-height: 21px;
}
@media screen and (min-width:1300px) and (max-width: 2000px){
  .whiteStyle{
    height 40px;
   }
  .h5-wrapper {
    width: 375px;
    margin:0px auto;
    height:800px;
    overflow: auto;
    padding-bottom 0px
    box-shadow: 3px 2px 11px 2px #dedede;
  }
}

@media screen and (min-width:600px) and (max-width: 1300px){
  .whiteStyle{
    height 10px;
  }
  .h5-wrapper {
  margin:0px auto;
  width: 375px;
    height:600px;
    overflow: auto;
    padding-bottom 0px
    box-shadow: 3px 2px 11px 2px #dedede;
}
}
</style>
