import { render, staticRenderFns } from "./processStatus.vue?vue&type=template&id=0fb84e88&scoped=true"
import script from "./processStatus.vue?vue&type=script&lang=js"
export * from "./processStatus.vue?vue&type=script&lang=js"
import style0 from "./processStatus.vue?vue&type=style&index=0&id=0fb84e88&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fb84e88",
  null
  
)

export default component.exports