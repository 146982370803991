<template>
  <div>
    <div class="whiteStyle"></div>

    <div>
      <div class="h5-wrapper h5">
        <div class="card-wrapper">
          <div class="info-card">
            <div class="text0">{{ keyFinancialData.industry }}</div>
            <div class="text1">所属行业</div>
          </div>
          <div class="info-card">
            <div class="text0">{{ keyFinancialData.area }}</div>
            <div class="text1">所属地区</div>
          </div>
        </div>
        <div class="content-section" v-if="keyFinancialData">
          <template v-for="(chart, index) in keyFinancialData.data">
            <bossTaxBearingRate
              :key="index"
              :data="chart"
              class="chart"
              height="300px"
              width="300px"
            ></bossTaxBearingRate>
            <div class="line"></div>
          </template>
        </div>
        <!-- <div class="btnOrder">
          <BtnOrder></BtnOrder>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import "../bossWatchReport.styl";
import BtnOrder from "../component/BtnOrder.vue";
import bossTaxBearingRate from "../component/bossTaxBearingRate.vue";
import { getIndustryCompareAPI } from "../../../../api/bossWatchReport";

export default {
  name: "IndustryComparison",
  components: { BtnOrder, bossTaxBearingRate },
  data() {
    return {
      keyFinancialData: null,
    };
  },
  methods: {
    async load() {
      const params = {
        uuid: this.$route.params.uuid,
      };
      const res = await getIndustryCompareAPI(params);
      this.keyFinancialData = res.data;
    },
  },
  async mounted() {
    this.load();
  },
};
</script>

<style lang="stylus" scoped>
.h5-wrapper {
  //padding 20px 20px 35px;
  //position: relative
  .card-wrapper{
    display flex
    justify-content space-between
    padding 20px 20px 0;
    .info-card{
      width: 43vw;
      height: 18vw;
      //background: #E5F0FF;
      border-radius: 4px;
      padding 12px;
      box-sizing border-box
      box-shadow: 0px 1px 10px 2px rgba(0,0,0,0.1);
      .text0{
        margin-bottom 8px
        font-size: 14px;
        font-weight: 400;
        color: #4E5969;
        line-height: 20px;
        font-weight: bold;
      }
      .text1{
        font-size: 12px;
        font-weight: 400;
        color: #4E5969;
        line-height: 18px;
        font-weight: bold;
      }
    }
  }
}

.content-section {
  padding: 0 20px 8px
  background: #FFFFFF;

  .title {
    display flex
    align-items center
    font-size: 16px;
    font-weight: 400;
    color: #1D2129;
    line-height: 24px;
  }

  .title::before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 14px;
    background: #1D2129;
    border-radius: 2px;
    margin-right: 5px;
  }
}
.line{
    //width: 89.3vw;
    border-bottom: 2px dashed #7585a247;
    margin 20px auto;
}

@media screen and (min-width:1300px) and (max-width: 2000px){
  .whiteStyle{
    height 40px;
   }
  .h5-wrapper {
    width: 375px;
    margin:0px auto;
    height:800px;
    overflow: auto;
    padding-bottom 0px
    box-shadow: 3px 2px 11px 2px #dedede;
    .card-wrapper{
      display flex
      justify-content space-between
      .info-card{
        width: 47%;
        height: 10vh;
        border-radius: 4px;
        padding 12px;
        box-sizing border-box
        box-shadow: 0px 1px 10px 2px rgba(0,0,0,0.1);
        .text0{
          margin-bottom 8px
          font-size: 14px;
          font-weight: 400;
          color: #4E5969;
          line-height: 20px;
          font-weight: bold;
        }
        .text1{
          font-size: 12px;
          font-weight: 400;
          color: #4E5969;
          line-height: 18px;
          font-weight: bold;
        }
      }
    }
  }
  >>>.btn-order{
    position: fixed
    top:827px;
  }

>>>.content-section .danger{
  width: 136px;
}
}
@media screen and (min-width:600px) and (max-width: 1300px){

  .whiteStyle{
    height 10px;
   }
  .h5-wrapper {
    width: 375px;
    margin:0px auto;
    height:600px;
    overflow: auto;
    padding-bottom 0px
    box-shadow: 3px 2px 11px 2px #dedede;
    .card-wrapper{
      display flex
      justify-content space-between
      .info-card{
        width: 47%;
        height: 10vh;
        border-radius: 4px;
        padding 12px;
        box-sizing border-box
        box-shadow: 0px 1px 10px 2px rgba(0,0,0,0.1);
        .text0{
          margin-bottom 8px
          font-size: 14px;
          font-weight: 400;
          color: #4E5969;
          line-height: 20px;
          font-weight: bold;
        }
        .text1{
          font-size: 12px;
          font-weight: 400;
          color: #4E5969;
          line-height: 18px;
          font-weight: bold;
        }
      }
    }
  }
  >>>.btn-order{
    position: fixed
    top 596px
  }

>>>.content-section .danger{
  width: 136px;
}
}
@media screen and (min-width:100px) and (max-width: 600px){
  .btnOrder{
    position: fixed
    top 673px
    left: -9px;
  }
}
</style>
