<template>
  <el-dialog
    title="风险企业详情"
    :visible.sync="dialogVisible"
    width="1200px"
  >
    <div
      v-loading="!loaded">
      <div class="info">
        <div>企业：{{ data.companyName }}</div>
        <div>风险项：{{ data.riskList && data.riskList.join("、") }}</div>

      </div>
      <div class="item" v-for="(table,index) in data.dataTableList" v-if="data.dataTableList"
           :key="index">
        <div class="title">{{ table.tableTitle }}</div>
        <BigPowerTable :data="table" class="table"></BigPowerTable>
      </div>
    </div>

  </el-dialog>
</template>

<script>
import BigPowerTable from "big-power-table"
import {getCompanyRiskInfo} from "@/api/threeYears";

export default {
  components: {
    BigPowerTable
  },
  name: "CompanyRiskDialog",
  data() {
    return {
      dialogVisible: false,
      data: {},
      loaded: false,
    };
  },
  methods: {
    async open(taxNo) {
      this.data={}
      this.loaded = false
      this.dialogVisible = true;
      await this.loadCompanyRiskInfo(taxNo)
      this.loaded = true
    },
    async loadCompanyRiskInfo(taxNo) {
      const res = await getCompanyRiskInfo({
        taxNo,//: '913301055714561592' //税号写死,记得替换一下
        desensitizationStatus:this.$route.query.desensitization
      })
      if (res.code == 200) {
        this.data = res.data
      } else {
        this.data = {}
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
>>> .el-dialog__header
  padding 24px 20px 16px
  border-bottom 0 none;

>>> .el-dialog__body
  padding 0px 20px 20px 20px

.info {
  width 100%
  padding 12px 16px
  background: rgba(148, 191, 255, 0.1);
  border-radius: 2px;
  box-sizing border-box
  font-size: 14px;
  font-weight: 400;
  color: #4E5969;
  line-height: 20px;
  margin-bottom 16px
}

.item {
  .title {
    font-size: 14px;
    font-weight: 400;
    color: #4E5969;
    line-height: 20px;
    padding 16px 0
  }

}

>>> .table.table {
  border 0 none
}

>>> .bigPowerTable {
  max-height 710px
}
</style>
