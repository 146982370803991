import { render, staticRenderFns } from "./authorization.vue?vue&type=template&id=6f85c806&scoped=true"
import script from "./authorization.vue?vue&type=script&lang=js"
export * from "./authorization.vue?vue&type=script&lang=js"
import style0 from "./authorization.vue?vue&type=style&index=0&id=6f85c806&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f85c806",
  null
  
)

export default component.exports