<template>
  <div class="full-sc-1-area full-sc-flex" v-if="data">
    <div class="close" @click="close">
      <img src="@/assets/test/part-close.png" alt="" />
    </div>
    <div class="up-down">
      <div
        class="up"
        :class="{ down: activeIndex == 1 }"
        @click="activeIndex = 1"
      >
        上游客户
      </div>
      <div
        class="up"
        :class="{ down: activeIndex == 2 }"
        @click="activeIndex = 2"
      >
        下游客户
      </div>
    </div>
    <div v-if="activeIndex == '1'">
      <ChinaCityTable :data="data" :type="'2'" :key="2" hp="2"></ChinaCityTable>
      <div v-if="!data" class="processedTable" v-loading="!data"></div>
    </div>
    <div v-if="activeIndex == '2'">
      <ChinaCityTable :data="data" :type="'1'" :key="1" hp="2"></ChinaCityTable>
    </div>
  </div>
</template>

<script>
import ChinaCityTable from "@/pages/Result/H5FinancialAnalysis/ChinaCityTable";
import { getCompanyAreaList } from "@/api/threeYears";

export default {
  name: "UpDownCustomerDistribution",
  components: {
    ChinaCityTable,
  },
  data() {
    return {
      activeIndex: "1",
      upStreamfilter: {
        time: [],
        limit: 15,
      },
      data: null,
    };
  },
  async mounted() {
    await this.loadCompanyAreaList();
  },
  methods: {
    close() {
      this.$router.back();
    },
    async loadCompanyAreaList() {
      const res = await getCompanyAreaList({
        programId: this.$route.params.uuid,
      });
      this.data = res.data;
    },
  },
};
</script>

<style lang="stylus" scoped>
.up-down {
  display: flex;
  align-items: center;
  // align-self: flex-start;
  font-size: 0.24rem;
  // margin: 0.16rem 0 0.16rem 0.24rem;
  position: absolute;
  top: 6px;
  left: 50%;
  margin-left: -80px;

  .up {
    width: 1.12rem;
    height: 0.4rem;
    line-height: 0.4rem;
    background: #F7F8FA;
    border-radius: 2px;
    margin-right: 0.24rem;
    text-align: center;
    padding: 2px 4px;
  }

  .down {
    color: #4080FF;
    background: #E8F3FF;
    font-weight: 600;
  }
}

.full-sc-1-area {
  position: fixed;
	width: 100vh;
	height: 100vw;
	top: 0;
	left: 100vw;
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
	transform-origin: 0% 0%;
  background: #fff;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  >>> .table .bigPowerTable {
    max-height: 5.5rem;
    overflow auto
    max-width: 12rem;
  }

  .close {
    position: absolute;
    top: 10px;
    left: 10px;

    img {
      padding: 10px;
      width: 15px;
      height: 15px;
    }
  }

  .table-wrapper-1 {
    // width: 10rem;
    // height: 5.8rem;
    // overflow: auto;
  }

}

.full-sc-flex {
  display flex
  flex-direction column
}
</style>
