<template>
  <div class="app-wrapper">
    <side-bar :icon="icon" class="sidebar-container"/>
    <div class="main-container">
      <nav-bar/>
      <app-main/>
    </div>
    <el-dialog title="通知" :visible.sync="dialogVisible" width="30%" >
      <span>{{ notice.noticeId?notice.content:'' }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="noPrompts">不再提醒</el-button>
        <el-button type="primary" @click="dialogVisible = false">已知晓</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {SideBar, AppMain} from "./components";
import NavBar from "./components/NavBar/NavBar";
import {getAgent, getLogoAndInfo, getLatestNotice,noPrompt} from "@/api/api";

export default {
  name: "Layout",
  data() {
    return {
      icon: "",
      dialogVisible: false,
      notice:{}

    };
  },
  components: {
    NavBar,
    SideBar,
    AppMain
  },
  mounted() {
    if (this.customization) {
      this.getLogoInfo(this.customization)
    } else {
      if(this.$route.query.fromHouTai == 'houtai') return;
      // this.getagent();
    }
    if(localStorage.getItem('islog')){
      this.getNotice()
    }
    window.addEventListener('beforeunload', e => this.destroy())

  },
  destroyed(){
    this.destroy()
  },
  computed: {
    ...mapState(["customization"]),
  },
  methods: {
    destroy(){
      localStorage.removeItem('islog')
      
    },
    async noPrompts(){
     const res= await noPrompt(this.notice.noticeId)
     if(res.code==200){
      this.dialogVisible=false
     }
    },
    async getNotice() {
      const res = await getLatestNotice()
      console.log(res);
       if(res.data.noticeId!==null){
        this.notice=res.data
        this.dialogVisible=true
       }
    },
    getLogoInfo(customization) {
      let appUrl = "";
      if (customization == 'hy') {
        appUrl = "http://hy.ai-tax.cn"
      } else if (customization == 'xr') {
        appUrl = "http://xr.ai-tax.cn"
      }else if (customization == 'nmhz') {
        appUrl = "http://nmhz.ai-tax.cn"
      }
      let params = {
        appUrl
      }
      getLogoAndInfo(params).then(res => {
        this.icon = res.data.backgroundColorImage;
        localStorage.setItem("wx_logo", this.icon);
        localStorage.setItem("index_logo", this.icon);
        localStorage.setItem("appid_3rd", res.data.appId);
        this.$store.commit("setContactUs",{data:res.data.helpline})
      })
    },
    getagent() {
      let params = {
        token: this.$store.state.authorization
      };
      getAgent(params).then(res => {
        if (res) {
          this.icon = res.backgroundColorImage;
          localStorage.setItem("wx_logo", res.contactQrCode);
          localStorage.setItem("index_logo", res.backgroundColorImage);
        }
      });
    }
  }
};
</script>

<style lang="stylus" scoped>
.app-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 1280px;

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.main-container {
  min-height: 100vh;
  overflow: hidden;
  transition: margin-left 0.28s;
  margin-left: 180px;
  background-color: #fbfbfb;
}
</style>
