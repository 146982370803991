<template>
  <div>
    <div ref="chartColumn" class="chartColumn"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import echarts from "echarts";
import { toFixed } from "@/utils/format";

export default {
  mounted() {
    this.showMap();
    console.log(this.total);
  },
  props: {
    colors: Array,
    data: Array,
    title: String,
    total: {
      default: "",
    },
  },
  methods: {
    //echart
    showMap() {
      this.$refs["chartColumn"].style.width = "250px"; //window.innerWidth * 0.5 + "px";
      this.chartColumn = echarts.init(this.$refs["chartColumn"]);

      const option = {
        tooltip: {
          trigger: "item",
          formatter: (data) => {
            return data.name + ":" + toFixed(data.percent) + "%";
          },
        },
        legend: {
          show: false,
        },
        graphic: {
          type: "text",
          left: "center",
          top: "40%",
          style: {
            // text: this.title ? this.title : "总数" + "\n" + "\n" + this.total,
            text: this.title
              ? this.title + "\n" + "\n" + this.total
              : "总数" + "\n" + "\n" + this.total,
            textAlign: "center",
            fill: "#333",
            fontSize: 14,
            fontWeight: 600,
          },
        },
        series: [
          {
            name: "金额",
            type: "pie",
            radius: ["45%", "80%"],
            avoidLabelOverlap: false,
            color: this.colors,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "12",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: this.data,
          },
        ],
      };

      this.chartColumn.setOption(option);
    },
  },
};
</script>

<style lang="stylus" scoped>
.chartColumn {
  width: 250px;
  height: 250px;
}
</style>
