<template>
  <div>
    <div>
      <NetWorkChartWindow v-if="type == 1"></NetWorkChartWindow>
      <CompanyRiskLookup v-if="type == 2" :close="close"></CompanyRiskLookup>
    </div>
    <div></div>
  </div>
</template>

<script>
import CompanyRiskLookup from "@/pages/Result/H5FinancialAnalysis/companyRisk/CompanyRiskLookup";
import { getCompanyAreaList, getCompanyRiskImage } from "@/api/threeYears";
import NetWorkChartWindow from "@/pages/Result/H5FinancialAnalysis/networkChart/NetWorkChartWindow";

export default {
  name: "UpDownCustomerNetWork",
  components: {
    CompanyRiskLookup,
    NetWorkChartWindow,
  },
  props: {
    type: [String, Number],
    close: [String, Number]
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="stylus" scoped></style>
