<template>
  <div>
    <div class="bar" @click="handleFixedBarClick" v-if="!showDialog">
      <i class="icon iconfont iconjihuoma"></i>新手指引
    </div>

    <el-dialog :visible.sync="showDialog" width="570px">
      <div class="content-wrapper">
        <img src="@/assets/undraw_setup_wizard_r6mr@2x.png" class="img" />
        <div class="title">欢迎使用AI税务师智能税务风险检测系统</div>
        <div class="desc">一键智能检测企业的税务风险，帮助企业提前发现涉税隐患，降低涉税风险，同时提供解决方案</div>
        <el-button type="primary" class="btn" @click="handleTryButtonClick">开始试用</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      showDialog: false
    };
  },
  mounted() {
    // const doesGuidanceShown = sessionStorage.getItem("doesGuidanceShown");
    // if (!doesGuidanceShown) {
    //   this.showDialog = true;
    //   sessionStorage.setItem("doesGuidanceShown",true);
    // }
  },
  methods: {
    handleFixedBarClick() {
      this.showDialog = true;
    },
    handleTryButtonClick() {
      this.showDialog = false;
      this.$router.push({
        name: "demo-choose-table",
        params: {
          productID: 191,
          bankId: "2223f9d4515844e589597070d8847631",
          productName: "2019通用规则测评",
          uuid: "d043a8b4fc014ee082c835c3b10171ed"
        }
      });
    }
  }
};
</script>

<style lang="stylus" scoped>
.bar {
  font-size: 14px;
  width: 40px;
  height: 144px;
  background: #2F54EB;
  color: #fff;
  text-align: center;
  position: fixed;
  right: 0;
  top: 60%;
  transform: translateY(-50%);
  writing-mode: vertical-lr;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 5px;
  cursor: pointer;
  border-radius: 8px 0px 0px 8px;

  .icon {
    margin-bottom: 8px;
  }
}

>>> .el-dialog__header {
  padding: 0;
}

.content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .img {
    width: 240px;
    height: 180px;
    display: block;
  }

  .title {
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    line-height: 25px;
    font-size: 18px;
    margin: 48px auto 8px;
  }

  .desc {
    color: rgba(0, 0, 0, 0.6);
    line-height: 20px;
    font-size: 14px;
    width: 440px;
  }

  .btn {
    margin: 48px 0 25px;
    display: block;
    text-align: center;
    line-height: 36px;
    color: #fff;
    width: 400px;
    height: 36px;
    background: #2f54eb;
    border-radius: 4px;
    padding: 0;
  }
}
</style>