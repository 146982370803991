<template>
  <div>
    <div class="wrapper item-body">

      <!--新模型发票模型-->
      <template
      >
        <div>
          <el-alert
            type="warning"
            v-for="(tip,index) in tips"
            :key="index"
            :closable="false"
            class="tip"
          >
            <i class="el-icon-warning-outline"></i> {{tip.text}}
          </el-alert>
        </div>
        <div class="desc">
          <div class="title">分析结果</div>
          <p>{{ dataText }}</p>
        </div>
        <div class="graph">

          <template>

            <template v-if="ruleOfInvoiceImageList&&ruleOfInvoiceImageList.length>0">
              <div>
                <div v-for="(chart,index) in ruleOfInvoiceImageList"
                             :key="index">
                  <ColumnChart :data="chart"
                               v-if="chart.imageType=='histogram'||chart.imageType=='twoHistogram'"
                               width="756px" height="435px"></ColumnChart>
                  <LineChart :data="chart" v-if="chart.imageType=='brokenLine'" width="756px"
                             height="435px"></LineChart>
                  <Pie :data="chart"
                       v-if="chart.imageType=='annulus'"
                       width="756px" height="435px"></Pie>
                </div>
                  <GenericTable
                    :limit="pdfData.pdfShowNumber"
                    v-if="generalTableData"
                    :data="generalTableData"
                    :tips="tips"
                    :subTables="subTables"
                  ></GenericTable>
              </div>
            </template>
            <template v-else>

              <GenericTable
                :limit="pdfData.pdfShowNumber"
                v-if="generalTableData"
                :data="generalTableData"
                :tips="tips"
                :subTables="subTables"
              ></GenericTable>

            </template>
          </template>
          <!--          <GenericTable-->
          <!--            v-if="generalTableData"-->
          <!--            :data="generalTableData"-->
          <!--            :tips="tips"-->
          <!--            :subTables="subTables"-->
          <!--          ></GenericTable>-->

          <!--                      <TableWithDrawer-->
          <!--            v-if="invoiceCustomerLimitingConsumptionRisk"-->
          <!--            :data="invoiceCustomerLimitingConsumptionRisk"-->
          <!--          >-->
          <!--            <template v-slot="slotProps">-->
          <!--              <GenericTable-->
          <!--                v-if="slotProps.drawerData.drawer"-->
          <!--                :data="-->
          <!--                  caseOfinvoiceCustomerLimitingConsumptionRisk(-->
          <!--                    slotProps.drawerData.drawer-->
          <!--                  )-->
          <!--                "-->
          <!--              ></GenericTable>-->
          <!--            </template>-->
          <!--          </TableWithDrawer>-->
        </div>
      </template>
      <!--/新模型发票模型-->
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {cloneDeep} from "lodash";
import Pie from "./components/Pie";
import GenericTable from "./components/GenericTable";
import mixin from "@/pages/ResultEditor/mixin.js";
import ColumnChart from "../chats/ColumnChart";
import LineChart from "../chats/LineChart";

export default {
  mixins: [mixin],
  mounted() {
  },
  components: {
    ColumnChart,
    LineChart,
    GenericTable,
    Pie
  },
  methods: {
    ...mapMutations("ResultEditor", ["setTreeDataByIndexes"]),
    chartTypeName(imageTypeId) {
      if (imageTypeId == 'histogram' || imageTypeId == 'twoHistogram') {
        return "柱形图"
      } else if (imageTypeId == 'brokenLine') {
        return "折线图"
      }else if (imageTypeId == 'annulus') {
        return "环形图"
      }


    },
    handleCompanyTableVisibilityToggle(index, isDisplay) {
      const nodeData = cloneDeep(this.data);
      const pdfData = JSON.parse(nodeData.pdfData);
      pdfData[index].isDisplay = isDisplay ? 1 : 0;
      nodeData.pdfData = JSON.stringify(pdfData);

      this.setTreeDataByIndexes({
        data: nodeData,
        indexes: this.indexes,
      });
    },

  },
  props: {
    node: Object,
  },

  computed: {
    ...mapGetters("ResultEditor", ["findTheNodeByIndexes"]),

    subTables() {
      return this.pdfData&&this.pdfData.subTables && this.pdfData.subTables.map(v => {
        v.pos = [v.row != null ? v.row : -1, v.col != null ? v.col : -1]
        return v
      });
    },
    tips() {
      return this.pdfData&&this.pdfData.tips && this.pdfData.tips.map(v => {
        return {
          pos: [v.row != null ? v.row : -1, v.col != null ? v.col : -1],
          text: v.text
        }
      })
    },
    data() {
      return this.node;
    },
    pdfData() {
      return JSON.parse(this.data.pdfData);
    },
    //共输入框使用
    dataText: {
      get() {
        return this.data.dataText;
      },
      set(value) {
        const nodeData = this.data;
        nodeData.dataText = value; //给节点设置新的dataText
        this.setTreeDataByIndexes({
          data: nodeData,
          indexes: this.indexes,
        });
      },
    },
    histogramType() {
      return this.pdfData ? this.pdfData.imageType : '';
    },
    //循环展示的表格
    ruleOfInvoiceImageList() {
      return this.pdfData ? this.pdfData.ruleOfInvoiceImageList : [];
    },
    //通用表格
    generalTableData() {

      if (!this.pdfData) {
        return null;
      }

      if (!this.pdfData.body) {
        return null;
      }
      const ret = [];
      const header = this.pdfData.pdfHeader||this.pdfData.header

      for (let i = 0; i < this.pdfData.body.length; i++) {
        ret[i] = [];

        header.forEach(key => {

          ret[i].push(this.pdfData.body[i][key])
        })

      }

      const widths = this.pdfData.width

      return {
        data: ret,
        labels: header,
        widths
      };
    },
    isInputModified() {
      let ret = false;

      if (this.$route.params.action == "edit") {
        ret = this.data.lastCensorSnapshot
          ? this.data.lastCensorSnapshot.includes("TextChange")
          : false;
      } else if (this.$route.params.action == "censor") {
        ret = this.data.lastEditSnapshot
          ? this.data.lastEditSnapshot.includes("TextChange")
          : false;
      }
      return ret;
    },
    isInputModifiedByMyself() {
      let ret = false;

      if (this.$route.params.action == "edit") {
        ret = this.data.editSnapshot
          ? this.data.editSnapshot.includes("TextChange")
          : false;
      } else if (this.$route.params.action == "censor") {
        ret = this.data.censorSnapshot
          ? this.data.censorSnapshot.includes("TextChange")
          : false;
      }
      return ret;
    },
    popperClass() {
      let ret = "";
      if (this.isInputModifiedByMyself) {
        ret = "modified-tooltip-red";
      } else if (this.isInputModified) {
        ret = "modified-tooltip-yellow";
      }
      return ret;
    },


  },

  data: function () {
    return {
      inputTextArea: "",
      showDetails: false,
    };
  },
};
</script>

<style lang="stylus" scoped>
.wrapper {
  padding: 0;
  background: RGBA(251, 251, 251, 1);

  .title {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.8);
    line-height: 1.5;
  }

  .textarea-wrapper {
    margin: 16px;

    >>> .el-textarea__inner {
      height: 100px;
    }

    .isModified.textarea {
      >>> .el-textarea__inner {
        border: 1px solid #faad14 !important;
        border-color: #faad14 !important;
      }

      border-radius: 4px;
    }

    .isModifiedByMyself.textarea {
      >>> .el-textarea__inner {
        border: 1px solid rgba(245, 34, 45, 1) !important;
        border-color: rgba(245, 34, 45, 1) !important;
      }

      border-radius: 4px;
    }
  }

  .graph {
    padding: 24px 17px;
    box-sizing: border-box;

    .tableWrapper {
      margin-top: 22px;

      .table-title {
        margin-bottom: -18px;
        color: rgba(0, 0, 0, 0.4);
        line-height: 22px;
      }

      .table {
        margin-top: 0;
      }
    }

    .diagram {
      width: 50%;
      height: 435px;
    }

    >>> .el-tabs__header {
      margin: 0;
      border-bottom: none;

      .el-tabs__item {
        border-radius: 0px;
        border: 1px solid rgba(47, 84, 235, 1);
        color: rgba(0, 0, 0, 0.4);
        margin: 0;
        border: 1px solid #D9D9D9;
        border-right: none;
        font-size: 14px;
        padding: 0px 16px;
        line-height: 36px;
        height: 36px;
      }

      .el-tabs__item.is-active {
        background: rgba(240, 245, 255, 1);
        color: #2F54EB;
        border: 1px solid #2F54EB !important;
      }

      .el-tabs__item:first-child {
        border-radius: 4px 0px 0px 4px;
      }

      .el-tabs__item:last-child {
        border-radius: 0px 4px 4px 0px;
        border-right: 1px solid #D9D9D9;
      }
    }

    >>> .el-tabs__content {
      overflow: visible;
    }
  }
}

.item-body {
  .tip{
    margin-bottom 10px
  }


  .graph {
    padding: 24px 17px;
    box-sizing: border-box;

    .diagram {
      width: 50%;
      height: 435px;
    }

    >>> .el-tabs__header {
      margin: 0;

      .el-tabs__item {
        border-radius: 0px;
        border: 1px solid rgba(47, 84, 235, 1);
        color: rgba(0, 0, 0, 0.4);
        margin: 0;
        border: 1px solid #D9D9D9;
        border-right: none;
        font-size: 14px;
        padding: 0px 16px;
      }

      .el-tabs__item.is-active {
        background: rgba(240, 245, 255, 1);
        color: #2F54EB;
        border: 1px solid #2F54EB !important;
      }

      .el-tabs__item:first-child {
        border-radius: 4px 0px 0px 4px;
      }

      .el-tabs__item:last-child {
        border-radius: 0px 4px 4px 0px;
        border-right: 1px solid #D9D9D9;
      }
    }

    >>> .el-tabs__content {
      overflow: visible;
    }

    .tableWrapper {
      margin-top: 22px;

      .table-title {
        margin-bottom: -18px;
        color: rgba(0, 0, 0, 0.4);
        line-height: 22px;
      }

      .table {
        margin-top: 0;
      }
    }
  }
}
</style>
