import axios, {
  get,
  post
} from "@/axios";

const base = process.env.VUE_APP_API_ROOT;

// 获取预检测信息
export const getPreInspectResult = async ({
                                            accessToken,
                                            preInspectId
                                          }) => {
  const url = `${base}/open/commomInspect/getPreInspectResult`;
  const res = await get(url, {
    accessToken,
    preInspectId
  });
  return res.data;
};

// 授权之前获取省份
// export const getProvince = (params) => post(`${base}/daqc/breeze/getProvince`, params)
//   .then((res) => res.data);


/**
 * 获取税局是否已经对接
 * @param {{taxNo:String}}taxNo
 * @returns {Promise<*>}
 */
export const getTaxAreaBureauServiceInfoOem = async ({
                                                       taxNo,from
                                                     }) => {
  const url = `${base}/daqc/thirdAuth/getAreaMsg`;
  const res = await get(url, {
    taxNo,from
  });
  return res.data;
}


/**
 * 获取DF编辑器数据
 * @param {{programId}} programId
 * @returns {Promise<*>}
 */
export const getPdfCatalogData = async ({programId}) => {
  const url = `${base}/open/pdfData/getPdfCatalogData`;
  const res = await get(url, {programId});
  return res.data;
};


/**
 * 生成测评结果页
 * @param {{
 *     getDataWay,
 *     isAutoData,
 *     preInspectId,
 *     zq
 *   }}params
 * @returns {Promise<*>}
 */
export const genTestReportOpen = async (params) => {
  const url = `${base}/open/commomInspect/inspect`;
  const {
    getDataWay,
    isAutoData,
    preInspectId,
    zq
  } = params;

  const res = await post(url, {
    getDataWay,
    isAutoData,
    preInspectId,
    zq
  });
  return res.data;
};


// 获取所有表格
export const getUserTables = async (
  goodsId,
  nsrsbh,
  zq,
  uuid,
  onUploaded = false,
) => {
  const url = `${base}/admin/excelInfo/getListV2`;
  const data = {zq, programId: uuid, tag: goodsId};
  if (onUploaded) {
    data.type = '1';
  }
  const res = await get(url, data);
  return res.data;
};

export const loadTableData = async (zq, templateId, uuid) => {
  const url = `${base}/admin/excelInfo/getReportDataInfo?excelTableName=${templateId}&programId=${uuid}&zq=${zq}`
  const res = await get(url);

  if (res.data.code == 200 && res.data.data && res.data.data.dataJson) {
    res.data.data.dataJson = JSON.parse(res.data.data.dataJson);
  }
  return res.data;
};

export const clearUploadedTableforTest = async (nsrsbh, zq, token, uuid) => {
  const url = base + "/admin/excelInfo/delReportDataBatch?programId=" + uuid + "&zq=" + zq;


  const res = await get(url, {nsrsbh, zq, uuid});
  return res.data;
};
