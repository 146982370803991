<template>
  <div>
    <div class="mask" @click="close"></div>
    <div class="toast">
      <div class="title">
        <span>提交订单</span>
        <img
          class="close"
          @click="close"
          src="@/assets/test/part-close.png"
          alt=""
        />
      </div>
      <div class="info">
        <div class="item">购买企业：{{this.$store.state.companyInfo.companyName}}</div>
        <div class="item">版本名称：{{ buyItem.name }}</div>
        <div class="item">实际支付：{{ buyItem.price }}元</div>
      </div>
      <div class="pay-img">
        <div class="pay-m">
          <span
            class="wechat"
            :class="{ act: method == 1 }"
            data-index="1"
            @click="select"
          >
            <img class="icon" src="@/assets/auto/wpay.png" alt="" />
            <span class="select" v-if="method == 1"></span>
            <img class="go" src="@/assets/auto/pay-go.png" alt="" />
            <span class="text">微信支付</span>
          </span>
          <span
            class="wechat"
            :class="{ act: method == 2 }"
            data-index="2"
            @click="select"
          >
            <img class="icon" src="@/assets/auto/apay.png" alt="" />
            <span class="select" v-if="method == 2"></span>
            <img class="go" src="@/assets/auto/pay-go.png" alt="" />
            <span class="text">支付宝支付</span>
          </span>
        </div>
        <div class="pay">
          <div class="scanCode" v-if="code">
            <img :src="code" alt="" />
            <div v-if="method == 1" class="tips">
              打开手机端微信<br />扫一扫进行付款
            </div>
            <div v-if="method == 2" class="tips">
              打开手机端支付宝<br />扫一扫进行付款
            </div>
          </div>
          <div v-else class="sanMethod">请选择支付方式</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createPing, findOrderPayStatus } from "../../../api/api";

export default {
  props: {
    buyItem: Object,
    orderId: String,
    silent:{
      default:false,
      type:Boolean
    }
  },
  data() {
    return {
      method: 0,
      paymentMethod: ["1", "wx_pub_qr", "alipay_qr"],
      code: "",
      index: 1,
      locked: 1,
    };
  },
  methods: {
    select(e) {
      if (this.index == 1) {
        var that = this;
        let n = setTimeout(function cb() {
          let params = {
            orderNo: that.orderId,
          };
          findOrderPayStatus(params).then((res) => {
            if (res.msgCode == -1) {
              that.$message({
                type: "error",
                message: "未知网络错误，请检查网络或咨询客服！",
                duration: 5000,
              });
              that.$router.push({
                path: "/",
              });
              return;
            }
            if (res.data.orderStatus == 2) {
              if(that.silent){
                that.$emit("bought");
              }else{
                that.$router.push({ name: "paySuccess" });
              }

            } else {
              that.price = true;
              if (that.locked) {
                n = setTimeout(cb, 2000);
              }
            }
          });
        }, 2000);
      }

      this.index++;
      this.method = e.currentTarget.dataset.index;
      let params = {
        channel: this.paymentMethod[this.method],
        imageHeight: 200,
        imageWidth: 200,
        orderNo: this.orderId,
      };
      createPing(params).then((res) => {
        // console.log(res);
        if (res.msgCode == 1 && res.data) {
          this.code = res.data.image;
        } else {
          this.code = "";
          this.$message({
            type: "error",
            message: "生成支付二维码失败，请咨询客服！",
          });
        }
      });
    },
    close() {
      this.$emit("close");
    },
  },
  destroyed() {
    this.locked = 0;
  },
};
</script>

<style lang="stylus" scoped>
.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1001;
}

.toast {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -243px;
  margin-left: -400px;
  width: 800px;
  height: 486px;
  background: #ffffff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 1002;

  .info {
    width: 752px;
    height: 142px;
    background: #FBFBFB;
    border-radius: 4px;
    margin: 16px 0 16px 24px;
    padding-top: 24px;
    box-sizing: border-box;

    .item {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.4);
      line-height: 20px;
      margin-bottom: 16px;
      margin-left: 24px;
    }
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px 0 24px;
    height: 54px;
    line-height: 54px;
    background: #ffffff;
    box-shadow: 0px 1px 0px 0px #eeeeee;
    border-radius: 8px 8px 0px 0px;
    box-sizing: border-box;

    .close {
      cursor: pointer;
      padding: 10px 10px;
    }
  }

  .wechat {
    position: relative;
    display: inline-block;
    width: 158px;
    height: 48px;
    line-height: 48px;
    background: #F0F5FF;
    border-radius: 4px;
    border: 1px solid #D9D9D9;
    margin: 0 16px 8px 24px;
    overflow: hidden;

    .text {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
    }

    .icon {
      width: 32px;
      height: 32px;
      vertical-align: middle;
      margin: 0 8px 0 24px;
    }

    .select {
      position: absolute;
      width: 23px;
      height: 23px;
      background: #2F54EB;
      transform: rotate(45deg);
      bottom: -11px;
      right: -12px;
    }

    .go {
      position: absolute;
      bottom: -2px;
      right: -2px;
      width: 14px;
      height: 14px;
    }
  }

  .act {
    border: 1px solid #2F54EB;
  }

  .pay-img {
    display: flex;

    .pay-m {
      display: flex;
      flex-direction: column;
    }

    .pay {
      position: relative;
      display: inline-block;
      width: 578px;
      height: 234px;
      background: #F0F5FF;
      border-radius: 4px;

      .scanCode {
        position: absolute;
        width: 138px;
        height: 138px;
        top: 24px;
        left: 160px;

        .tips {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.4);
          line-height: 20px;
          margin-top: 8px;
          text-align: center;
        }

        // background: #f0f0f0;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .sanMethod {
        margin: 20px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}
</style>
