<template>
  <el-dialog
    title
    :visible.sync="dialogVisible"
    width="480px"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="main-wrapper">
      <div class="left-wrapper">
        <i class="icon el-icon-success"></i>
      </div>
      <div class="right-wrapper" v-if="activationData">
        <div class="title">兑换成功</div>
        <div class="text">
          {{activationData.data.verName}}
          <span
            class="small-text"
          >（到期时间：{{activationData.data.verInfo}}）</span>
        </div>
        <!-- <div class="text">
          {{activationData.data.goodsName}}
          <span class="small-text">（剩余次数：{{activationData.data.goodsInfo}}次）</span>
        </div>-->
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleBtnUse">立即使用</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  inject: ["reload"],
  props: {
    activationData: Object,
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    handleBtnUse() {
      this.dialogVisible = false;
      if (this.$route.name == "homePage") {
        this.reload();
      } else {
        this.$router.push({ path: "/" });
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.icon {
  font-size: 22px;
  color: #52C41A;
  vertical-align: top;
}

.main-wrapper {
  padding: 0 10px;
  display: flex;
}

>>> .el-dialog {
  border-radius: 8px;
}

>>> .el-dialog__header {
  padding: 0;
}

>>> .el-dialog__footer {
  padding: 0 20px 20px;
}

>>> .el-button {
  background: hsla(228, 82%, 55%, 1);
  height: 32px;
  background: rgba(47, 84, 235, 1);
  border-radius: 4px;
  border: 0 none;
  padding: 0 24px;
  line-height: 32px;
  font-size: 14px;
}

>>> .el-button:hover {
  background: hsla(228, 82%, 45%, 1);
}

.right-wrapper {
  margin-left: 16px;
  color: #666;

  .title {
    vertical-align: top;
    font-weight: 600;
    color: #333;
    line-height: 22px;
    font-size: 16px;
  }

  .text {
    line-height: 20px;
    margin-top: 8px;
  }

  .small-text {
    color: #aaa;
  }
}
</style>