<template>
  <div class="section-title">
      <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="stylus" scoped>
.section-title{
    color: #062340;
    font-size:18px;
    padding-left: 30px;
    border-left: 6px solid #76B4FF;
    height: 24px;
    line-height: 24px;
}
</style>
