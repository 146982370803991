<template>
  <div class="container">
    <el-table
      ref="multipleTable"
      :data="tables"
      tooltip-effect="dark"
      @selection-change="handleSelectionChange"
      style="width: 100%"
      height="500"
    >
      <el-table-column prop="templateId" label="表单编号" width="120"></el-table-column>
      <el-table-column prop="name" label="表单名称" show-overflow-tooltip></el-table-column>
      <el-table-column type="selection" width="55"></el-table-column>
    </el-table>
    <div class="footer">
      <button class="btn btn-primary btn-next" @click="handleBtnNext">下一步</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tables: [Array]
  },
  data() {
    return {
      multipleSelection: []
    };
  },
  mounted() {
    this.toggleSelection(this.tables);
    //this.multipleSelection = cloneDeep(this.tables);
  },

  methods: {
    handleBtnNext() {
      this.multipleSelection;
      this.$store.commit("UploadTable/setSDSSelection", {
        SDSlist: this.multipleSelection
      });

      this.$emit("onDialogSubmit");
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    }
  }
};
</script>

<style lang="stylus" scoped>
.container {
  padding-top: 16px;
  border-top: 1px solid #eee;
  margin-top: 13px;
  padding: 24px;
  box-sizing: border-box;
}

>>>thead {
  th {
    background: #FBFBFB;
    box-shadow: 0px -1px 0px 0px rgba(217, 217, 217, 1), 0px 1px 0px 0px rgba(217, 217, 217, 1);
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 0 0;
  box-sizing: border-box;

  .btn-next {
    width: 90px;
    height: 36px;
    line-height: 36px;
    border-radius: 4px;
  }
}
</style>