<template>
  <!-- <div> -->
  <!--    <iframe-->
  <!--      src="http://192.168.0.137:8083/9c99002f913e42ec87827ec40c2cdfb8/b4916cc3f2e84fa89b023955245b1173/"-->
  <!--      class="third-party-iframe"/>-->

  <div class="page-wrapper">
    <div class="title-content">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ name: 'homePage' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>风险检测</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <div class="qymc" v-if="product">{{ product.name }}</div>
    </div>

    <div class="section-box dali-style">
      <loading
        :active="loading"
        backgroundColor="#fff"
        :can-cancel="true"
        :is-full-page="true"
        loader="Dots"
        color="#2f54eb"
      ></loading>
      <div v-show="!loading">
        <div class="title fix-title">
          基本信息
          <!--          <span class="fix-title-tips"-->
          <!--            >当前检测的期间，检测时需要上传两期数据，检测本期和上期数据</span-->
          <!--          >-->
        </div>

        <el-form
          class="pickers"
          :model="form"
          :rules="rules"
          ref="form"
          label-width="110px"
        >
          <div class="tip">以下信息将影响检测结果的准确性，请正确填写</div>
          <el-form-item class="item" label="纳税人识别号:" prop="nsrsbh">
            <el-tooltip class="item" effect="dark" placement="top-start">
              <div slot="content">
                纳税人识别号是税务登记证上的号码，通常简称为“税号”，<br />每个企业的纳税人识别号都是唯—的。<br />由15位、17位、18或者20位码(字符型)组成。
              </div>
              <i
                class="el-icon-warning"
                style="
                  position: absolute;
                  left: -130px;
                  top: 11px;
                  font-size: 18px;
                  color: #d9d9d9;
                "
              ></i>
            </el-tooltip>
            <el-input
              placeholder="请输入"
              v-model.trim="form.nsrsbh"
              class="input"
              @blur="handleNsrsbhBlur"
              :disabled="companyInfo.status == 1 || isAnonymous"
            ></el-input>
            <template
              v-if="getLocalAbilities($route.params.version).includes('报税表检测流程')"
            >
              <div class="switcher">
                <button
                  class="btn"
                  type="button"
                  @click="handleUnanonymousClick"
                  :disabled="!isAnonymous"
                  :class="{ active: !isAnonymous }"
                >
                  实名
                </button>
                <button
                  class="btn"
                  type="button"
                  @click="handleAnonymousClick"
                  :disabled="this.form.nsrsbh == '匿名'"
                  :class="{ active: this.form.nsrsbh == '匿名' }"
                >
                  匿名
                </button>
              </div>
            </template>
          </el-form-item>
          <el-form-item class="item" label="企业名称:" prop="companyName">
            <el-input
              placeholder="请输入"
              v-model="form.companyName"
              :disabled="true"
              class="input"
            ></el-input>
          </el-form-item>
          <el-form-item class="item" label="所属区域:" prop="diqu">
            <el-cascader
              :options="areaOptions"
              v-model="form.diqu"
              @change="handleChange"
              :placeholder="diquPlaceHolder"
              @visible-change="handleAreaFocus"
              filterable
              class="input"
            ></el-cascader>
          </el-form-item>
          <el-form-item class="item" label="所属行业:" prop="morehy">
            <el-select
              v-model="form.morehy"
              placeholder="请选择"
              filterable
              class="input"
            >
              <el-option
                v-for="item in hyListData"
                :key="item.hyDm"
                :label="item.hymc"
                :value="item.hyDm"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="item" label="征期类型:">
            <el-select
              v-model="form.tableType"
              @change="tabTypeChange"
              placeholder="请选择"
              class="input"
            >
              <el-option
                v-for="item in zqOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <!--            <div v-if="form.tableType === 1">-->
            <!--              <span class="fix-title-tips"-->
            <!--              >月度检测请选择手动上传方式，并且上传余额表！</span-->
            <!--              >-->
            <!--            </div>-->
          </el-form-item>
          <el-form-item class="item" id="itemYear" label="征期:">
            <!--            <el-tooltip class="item" effect="dark" placement="top-start">-->
            <!--              <div slot="content">-->
            <!--                指检测的时间区间，可以选择历史年份或当前年份（当年 5 月 31 后，可选<br/>-->
            <!--                当前年份对应的具体月份）。举例说明：征期为 2021 年，则检测区间主要为 2021<br/>-->
            <!--                完整年度，同时对比 2020 年度同期数据； 征期为 2022 年 7 月，则检测区间主要<br/>-->
            <!--                为 2022 年 1 月至 7 月，同时对比 2021 年度同期数据。-->
            <!--              </div>-->
            <!--              <i class="el-icon-warning"-->
            <!--                 style="position: absolute;left: -85px;top: 11px;font-size: 18px;color: #D9D9D9;"></i>-->
            <!--            </el-tooltip>-->
            <template v-if="form.tableType != ''">
              <div class="picker">
                <template v-if="form.tableType === 1">
                  <el-date-picker
                    v-model="monthPicker.value"
                    type="month"
                    placeholder="选择月"
                    :picker-options="monthPicker.options"
                    :clearable="false"
                  ></el-date-picker>
                </template>
                <template v-else-if="form.tableType === 2">
                  <quanter-picker
                    v-model="quanterPicker.value"
                    class="input"
                  ></quanter-picker>
                </template>
                <template v-else-if="form.tableType === 4">
                  <el-select
                    v-model="yearPicker.value"
                    placeholder="请选择年"
                    prefix="11"
                    @change="zqYchange"
                  >
                    <el-option
                      v-for="item in yearPicker.options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </template>
              </div>
            </template>
          </el-form-item>
          <!-- 新增参数20221013 -->
          <el-form-item
            class="item"
            label="是否进出口企业："
            prop="taxRateCalculation"
          >
            <el-tooltip
              class="item"
              effect="dark"
              placement="top-start"
              :open-delay="300"
            >
              <div slot="content">
                出口企业：增值税税负率=（当期销项-当期进项+进项转出+免抵退出口销售×适用税率+简易征收应纳税额）÷（适用税率销售+简易计税销售+免抵退出口销售）数据来源企业增值税申报表<br />非出口企业：增值税税负率=应纳税额÷适用税率销售收入
              </div>
              <i
                class="el-icon-warning"
                style="
                  position: absolute;
                  left: -130px;
                  top: 11px;
                  font-size: 18px;
                  color: #d9d9d9;
                "
              ></i>
            </el-tooltip>
            <el-select v-model="form.taxRateCalculation" placeholder="请选择">
              <el-option
                v-for="item in ratioTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- 新增参数20221013 -->
          <div class="form-bottom">
            <div class="form-bottom-item">
              <el-checkbox v-model="agree">
                <div class="text">我已阅读并同意</div>
              </el-checkbox>

              <div class="toc" @click="openLicense">《授权协议》</div>
            </div>
            <div class="form-bottom-item">
              <el-checkbox v-model="form.deleteDataType">底稿数据保留14天</el-checkbox>
            </div>
          </div>
        </el-form>
      </div>

      <sa-footer>
        <!-- <router-link
          v-if="zqInfoLoaded&&versionRadio>0"
          :to="{ name: 'upload-table', params: { id:tableCards[0].id,zq:tableCards[0].zq,zqLocation:zqs[0],dataPeriod:0,version:this.versionRadio,user:this.userNum}}"
          class="btn btn-primary btn-next"
        >下一步</router-link> -->
        <div
          v-if="zqInfoLoaded && versionRadio > 0"
          class="btn btn-primary btn-next"
          @click="toUploadNextHaveVersion"
        >
          下一步
        </div>
        <div
          v-if="zqInfoLoaded && versionRadio < 1"
          class="btn btn-primary btn-next"
          @click="toUploadNext"
        >
          下一步
        </div>
      </sa-footer>
    </div>

    <el-dialog id="status_upload" :visible.sync="showCheckports" width="70%">
      <SectionTitle>报表确认</SectionTitle>
      <el-tabs class="headercss">
        <el-tab-pane :label="item.zqZw" v-for="(item, key) in uploadList.data" :key="key">
          <el-tabs tab-position="left" class="status_upload1">
            <el-tab-pane
              :label="index + '(' + item1.number + '/' + item1.length + ')'"
              v-if="typeof item1 == 'object'"
              v-for="(item1, index) in item"
              :key="index"
            >
              <div class="table-container">
                <table class="table">
                  <tr>
                    <th v-if="index == '所得税表'">表单编号</th>
                    <th>表单名称</th>
                    <th>状态</th>
                  </tr>
                  <tr v-for="(v, index1) in item1" :key="index1">
                    <td v-if="index == '所得税表'">{{ v.tableNum }}</td>
                    <td>{{ v.tableNameCn }}</td>
                    <td v-if="v.uploadStatus == true" class="statusup statusup1">
                      已上传
                    </td>
                    <td v-else class="statusup">未上传</td>
                  </tr>
                </table>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer">
        <div class="redfont">*请仔细检查您上传的报表是否完整</div>
        <el-button type="primary" @click="handleBtnStartClick" v-loading="loading1"
          >生成测评报告</el-button
        >
      </span>
    </el-dialog>
    <!-- <div v-if="showService">
      <to-service @closeTip="showService=false"></to-service>
    </div>-->
    <licen-dialog ref="opendialog"></licen-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import SectionTitle from "@/components/SectionTitle";
import QuanterPicker from "@/components/QuanterPicker";
import BreadCrumb from "@/components/BreadCrumb";
import Footer from "./components/Footer";
import { zq2obj } from "@/utils/helper";
// import toService from "./components/toService";
import Saalert from "@/plugins/saalert";
import {
  areaList,
  genTestReport,
  getAvailableZqTypesByEdition,
  getbasicinfo,
  getCompanyId,
  getCompanyMsgByTaxNo,
  getProductById,
  getTradeList,
  saveCompanyInfo,
} from "@/api/api";
import { mapMutations, mapState } from "vuex";
import { cloneDeep } from "lodash";
import { getLocalAbilities } from "@/utils/abilities";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import licenDialog from "@/pages/Test/UploadTable/components/licen-dialog";

Vue.use(Saalert);

export default {
  components: {
    BreadCrumb,
    SectionTitle,
    QuanterPicker,
    SaFooter: Footer,
    Loading,
    licenDialog,
  },
  props: {
    bankId: String,
    productID: [String, Number],
    productName: String,
    zqLocation: [String, Number],
    uuid: String,
    version: [String, Number],
    isShanDong: [Number, String],
  },
  computed: {
    ...mapState(["companyInfo", "authorization"]),
    isAnonymous() {
      return (
        getLocalAbilities(this.$route.params.version).includes("报税表检测流程") &&
        this.form.nsrsbh == "匿名"
      );
    },
    areaOptions: function () {
      if (!this.areaTree) {
        return [];
      }

      const ret = cloneDeep(this.areaTree);

      function walk(data) {
        data.value = data.code;
        data.label = data.name;

        if (data.childNode && data.childNode.length > 0) {
          data.children = data.childNode;
          for (const child of data.children) {
            walk(child);
          }
        }
        delete data.childNode;
      }

      for (const areaTree of ret) {
        walk(areaTree);
      }

      return [...ret];

      // return [
      //   {
      //     value: -9999999,
      //     label: "地区",
      //     children: [...ret],
      //   },
      // ];
    },
  },
  data() {
    return {
      //
      taxRateCalculation: "",
      ratioTypeList: [
        {
          label: "是",
          value: 2,
        },
        {
          label: "否",
          value: 1,
        },
      ],
      //
      hyListData: null,
      hyListDataShow: null,
      areaTree: [],
      agree: false,
      form: {
        nsrsbh: "",
        companyName: "",
        diqu: [],
        morehy: "",
        tableType: null,
        deleteDataType: true,
        //
        taxRateCalculation: "",
      },
      diquDuplicated: [],
      diquPlaceHolder: "请选择",
      nsrsbhOriginal: "",
      companyNameOriginal: "",

      rules: {
        nsrsbh: [
          {
            required: true,
            message: "请输入纳税人识别号",
            trigger: "blur",
          },
          {
            validator: async (rule, value, callback) => {
              if (
                getLocalAbilities(this.$route.params.version).includes(
                  "报税表检测流程"
                ) &&
                this.form.nsrsbh == "匿名"
              ) {
                callback();
                return;
              }
              const res = await getCompanyMsgByTaxNo({
                taxNo: this.form.nsrsbh,
              });
              if (res.data && res.data.name) {
                if (this.form.companyName != "匿名") {
                  this.form.companyName = res.data.name;
                }
              } else {
                this.form.companyName = "";
              }

              if (res.msgInfo) {
                if (res.msgInfo == "成功") {
                  callback();
                } else {
                  callback(res.msgInfo);
                }
              }
            },
            trigger: "blur",
          },
        ],

        companyName: [{ required: true, message: "请输入企业名称", trigger: "blur" }],
        diqu: [{ required: true, message: "请选择所属地区", trigger: "blur" }],
        morehy: [{ required: true, message: "请选择所属行业", trigger: "blur" }],
        tableType: [{ required: true, message: "请选择征期类型", trigger: "blur" }],
        taxRateCalculation: [
          { required: true, message: "请选择是否进出口企业", trigger: "blur" },
        ],
      },

      zqValue: {
        year: [],
        month: [],
        quarter: [],
      },
      retTimeList: [],
      zqOptions: [
        {
          value: 4,
          label: "年度",
        },
        {
          value: 1,
          label: "月度",
        },
        {
          value: 2,
          label: "季度",
        },
      ],
      product: null, //商品详情信息
      showService: false,
      userNum: 0,
      uploadMethod: sessionStorage.getItem("method") || 1,
      highestVersionPurchased: 0,
      zqInfoLoaded: false,
      newuploadList: [],
      uploadList: [],
      loading1: false,
      loading: true,
      zqs: [],
      versionRadio: 0,
      showCheckports: false,
      tableType: {
        options: [
          // {
          //   value: 1,
          //   label: "月度报表"
          // },
          // {
          //   value: 2,
          //   label: "季度报表"
          // },
          {
            value: 4,
            label: "年度报表",
          },
        ],
      },

      yearPicker: {
        options: [
          {
            value: "2022",
            label: "2022年",
          },
          {
            value: "2021",
            label: "2021年",
          },
          {
            value: "2020",
            label: "2020年",
          },
          {
            value: "2019",
            label: "2019年",
          },
          {
            value: "2018",
            label: "2018年",
          },
          // {
          //   value: "2017",
          //   label: "2017年",
          // },
          // {
          //   value: "2016",
          //   label: "2016年",
          // },
          // {
          //   value: "2015",
          //   label: "2015年",
          // },
        ],
        value: null,
      },
      monthPicker: {
        value: "",
        options: {
          disabledDate: (time) => {
            let currentDate = new Date();
            const date = new Date();
            const year = date.getFullYear();
            let month = date.getMonth() + 1;
            if (month >= 1 && month <= 9) {
              month = "0" + month;
            }

            const currentdate = year.toString() + month.toString();
            const timeyear = time.getFullYear();
            let timemonth = time.getMonth() + 1;
            if (timemonth >= 1 && timemonth <= 9) {
              timemonth = "0" + timemonth;
            }
            const timedate = timeyear.toString() + timemonth.toString();
            return currentdate <= timedate || time.getFullYear() <= 2017;

            const isNotDisable =
              time.getFullYear() >= 2015 && time.getFullYear() <= timedate;
            return !isNotDisable;
          },
        },
      },
      quanterPicker: {
        value: {
          //year: new Date().getFullYear(),//2020
          year: "2019",
          quanter: 1,
        },
      },
      tableCards: [
        {
          total: 0,
          uploaded: 0,
          title: "",
          productID: this.productID,
          zq: null,
        },
        {
          total: 0,
          uploaded: 0,
          title: "",
          productID: this.productID,
          zq: null,
        },
      ],
    };
  },
  watch: {
    ["monthPicker.value"]: async function (val) {
      await this.onMonthPickerChanged(val);
    },
    ["quanterPicker.value"]: {
      handler: async function (val) {
        await this.onQuarterPickerChanged(val);
      },
      deep: true,
    },
    ["yearPicker.value"]: async function (val) {
      await this.onYearPickerChanged(val);
    },
  },
  async mounted() {
    this.loading = true;
    localStorage.setItem("zqY", 2022);
    sessionStorage.setItem("method", 1);

    this.gettradeList();
    this.getAreaList();
    // const {
    //   theHighestVersion,
    //   defaultVersion,
    //   versionArr,
    // } = await getHighestVersionPurchased();
    // this.versionArr = versionArr;
    // if (theHighestVersion) {
    //   this.highestVersionPurchased = parseInt(theHighestVersion);
    // } else {
    //   // this.highestVersionPurchased = defaultVersion;
    // }

    const res = await getProductById({ productId: this.productID });
    this.product = res.data;
    this.versionRadio = res.data.edition;
    const availableZqTypesRes = await getAvailableZqTypesByEdition({
      edition: this.versionRadio,
    });
    this.zqOptions = availableZqTypesRes.data.map((v) => {
      const ret = {};
      if (v.zqType == 1) {
        ret.label = "月度";
      } else if (v.zqType == 2) {
        ret.label = "季度";
      } else if (v.zqType == 3) {
        ret.label = "半年度";
      } else if (v.zqType == 4) {
        ret.label = "年度";
      }
      ret.value = v.zqType;
      this.retTimeList.push(ret);
      return ret;
    });

    // 默认征期
    if (this.retTimeList.length > 0) {
      this.form.tableType = this.retTimeList[0].value;
    }
    if (this.form.tableType == 1) {
      let dataNumber = this.mGetDate();
      var myDate = new Date();
      this.monthPicker.value = new Date(
        myDate.getTime() - (Number(dataNumber) + 1) * 24 * 3600 * 1000
      );

      // const year = this.monthPicker.value.getFullYear();
      // const month = (this.monthPicker.value.getMonth() + 1).toString().padStart(2, 0);

      // this.zqs = [];
      // this.zqs.push(`${year}${month}1`);
      // this.tableCards[0].zq = `${year}${month}1`;

      // const secYear = year - 1;
      // this.zqs.push(`${secYear}${month}1`);
      // this.tableCards[1].zq = `${secYear}${month}1`;
    }
    if (this.form.tableType == 4) {
      this.yearPicker.value = "2022";
    }
    if (this.form.tableType == 2) {
      this.quanterPicker.value = {
        year: "2023",
        quanter: 1,
      };

      // const year = "2021";
      // const quanter = 1;
      // this.zqs = [];
      // this.zqs.push(`${year}${quanter}2`);
      // this.tableCards[0].zq = `${year}${quanter}2`;

      // const secYear = year - 1;
      // this.zqs.push(`${secYear}${quanter}2`);
      // this.tableCards[1].zq = `${year}${quanter}2`;
    }
    // 默认征期

    this.showUploadmodules();
    //如果没有permission id就退出
    if (!this.$store.state.UploadTable.permissionID) {
      //this.$alert("权限校验已过期，请重新操作").then(() => {
      this.$router.push({ name: "homePage" });
      //});
    }

    //企业信息
    this.$store.cache.delete("loadCompanyRenzheng"); //删除认证缓存
    this.$store.cache.delete("loadCompanyInfo"); //删除企业信息
    await this.$store.cache.dispatch("loadCompanyInfo");
    //根据征期显示对应的选项
    const currentZq = this.zqLocation;
    // debugger;
    if (currentZq) {
      this.showCurrentZq(currentZq);
    } else {
      //初始化显示为本年年度报表
      const current = new Date();

      // this.form.tableType = 4;
      // this.yearPicker.value = "2020";

      // if (this.version == 15) {
      //   this.monthPicker.value = new Date();
      //   this.form.tableType = 1;
      // } else {
      //   this.form.tableType = 4;
      //   this.yearPicker.value = "2020";
      // }
      //this.yearPicker.value = current.getFullYear().toString(); //2020
    }

    //清楚store中的选择税数据信息
    this.$store.commit("UploadTable/setSDSSelection", {
      SDSlist: null,
    });

    this.form.nsrsbh = this.companyInfo.nsrsbh;
    this.form.companyName = this.companyInfo.companyName;
    this.form.diqu = this.companyInfo.dq.split(",");
    this.form.morehy = this.companyInfo.hy;

    const basicConfig = sessionStorage.getItem("basicConfig");
    if (basicConfig) {
      this.form.deleteDataType = JSON.parse(basicConfig).deleteDataType;
      this.yearPicker.value = JSON.parse(basicConfig).yearPicker;
      this.agree = JSON.parse(basicConfig).agree;
    }

    this.loading = false;
  },
  methods: {
    handleAreaFocus(isDropDownVisible) {
      if (isDropDownVisible) {
        // this.diquDuplicated = [...this.form.diqu]
        // this.diquPlaceHolder = [...this.diquDuplicated].join("/")
        // this.form.diqu=[]
      }
    },
    openLicense() {
      this.$refs.opendialog.openDialog();
    },
    getLocalAbilities(tag) {
      return getLocalAbilities(tag);
    },
    /**
     * 匿名检测
     */
    handleAnonymousClick() {
      this.nsrsbhOriginal = this.form.nsrsbh;
      this.companyNameOriginal = this.form.companyName;

      this.form.nsrsbh = "匿名";
      this.form.companyName = "匿名";
    },
    handleUnanonymousClick() {
      this.form.nsrsbh = this.nsrsbhOriginal;
      this.form.companyName = this.companyNameOriginal;
    },

    //获取企业相关信息
    basicinfo() {
      this.showCompany = true;
      let token = JSON.parse(localStorage.getItem("token")).token;
      getCompanyId(token).then(async (data) => {
        if (data.msgCode == 1) {
          this.res = data.data;
          localStorage.setItem("hasPwd", data.data.hasPwd);
          this.hasPwd = data.data.hasPwd;
          if (this.res) {
            getbasicinfo(this.res.companyId).then(async (data) => {
              this.showCompany = false;
              //修改localstorage里的user里的内容
              const user = JSON.parse(localStorage.getItem("user"));

              // 这些不知道什么意思,但是功能正常
              user.channalId = data.data.channalId;
              user.channalUserId = data.data.channalUserId;
              user.companyId = data.data.id;
              user.nsrsbm = data.data.nsrsbm;
              localStorage.setItem("user", JSON.stringify(user));
              await this.deleteCache();
            });
          } else {
            //console.log("未认证");
          }
        }
        // } else {
        //   this.$router.push("/companyApply");
        //   this.showCompany = false;
        // }
      });
    },
    async handleNsrsbhBlur() {},
    async deleteCache() {
      this.$store.cache.delete("loadCompanyRenzheng"); //删除认证缓存
      this.$store.cache.delete("loadCompanyInfo"); //删除企业信息
      await this.$store.cache.dispatch("loadCompanyRenzheng");
      await this.$store.cache.dispatch("loadCompanyInfo");
    },
    async tipIfCompanyInfoDifference() {
      const differentInfoLabels = [];

      if (
        this.form.nsrsbh != this.companyInfo.nsrsbh &&
        !(
          getLocalAbilities(this.$route.params.version).includes("报税表检测流程") &&
          this.form.nsrsbh == "匿名"
        )
      ) {
        differentInfoLabels.push("纳税人识别号");
      }

      if (
        this.form.companyName != this.companyInfo.companyName &&
        !(
          getLocalAbilities(this.$route.params.version).includes("报税表检测流程") &&
          this.form.companyName == "匿名"
        )
      ) {
        differentInfoLabels.push("企业名字");
      }

      if (this.form.diqu.join(",") != this.companyInfo.dq.split(",").join(",")) {
        differentInfoLabels.push("所属地区");
      }

      if (this.form.morehy != this.companyInfo.hy) {
        differentInfoLabels.push("所属行业");
      }

      if (differentInfoLabels.length > 0) {
        await this.$alert(
          `当前页面填写的${differentInfoLabels.join("、")}跟【企业信息】页面的不一致，
为保证各页面信息一致性，检测完成后【企业信息】页内容将同步更新`
        );
      }
    },
    //省市触发
    handleChange(value) {
      this.province1_code = value[0];
      for (let key in value) {
        for (let k in this.city) {
          if (this.city[k].value == value[key]) {
            this.province1 = this.city[k].value;
          }
          for (let k1 in this.city[k].children) {
            if (this.city[k].children[k1].value == value[key]) {
              this.city1 = this.city[k].children[k1].value;
            }
          }
        }
      }
    },
    // 获取地区
    getAreaList() {
      areaList().then((res) => {
        // console.log(res);
        this.areaTree = res.data;
      });
    },
    // 获取行业
    gettradeList() {
      getTradeList().then((res) => {
        if (res.msgCode == 1) {
          let hyList = JSON.parse(res.data);
          let dataList = {};
          for (let i = 0; i < hyList.length; i++) {
            hyList[i].hymc = hyList[i].hyDm + hyList[i].hymc;
            dataList[hyList[i].hyDm] = hyList[i].hymc;
          }
          this.hyListDataShow = dataList;
          this.hyListData = hyList;
        } else {
          this.$message.error(res.msgInfo);
        }
      });
    },
    mGetDate() {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var d = new Date(year, month, 0);
      return d.getDate();
    },
    tabTypeChange() {
      // 减掉当前月份的天数+1
      let dataNumber = this.mGetDate();
      var myDate = new Date();
      this.monthPicker.value = new Date(
        myDate.getTime() - (Number(dataNumber) + 1) * 24 * 3600 * 1000
      );
      if (this.form.tableType == 2) {
        this.quanterPicker.value = {
          year: "2023",
          quanter: 1,
        };
      }
    },
    doHandleDate() {
      var myDate = new Date();
      var tYear = myDate.getFullYear();
      var tMonth = myDate.getMonth();

      var m = tMonth + 1;
      if (m.toString().length == 1) {
        m = "0" + m;
      }
      return tYear + "-" + m;
    },
    changeCompanyInfo() {
      this.$router.go(0);
    },
    zqYchange() {
      localStorage.setItem("zqY", this.yearPicker.value);
    },
    closeTip() {
      this.showService = false;
    },
    toService() {
      this.showService = true;
    },
    /**
     * 更新企业信息
     * @param {Boolean} includeBasic
     * @returns {Promise<void>}
     */
    async updateCompanyInfo(includeBasic) {
      let token = JSON.parse(localStorage.getItem("token")).token;
      let para = {
        companyId: this.companyInfo.id,
        //kjzd: this.companyInfo.kjzd,
        hyDm: this.form.morehy,
        dq: this.form.diqu.toString(),
        userToken: token,
        type: 0,
        scale: 112,
      };

      if (includeBasic) {
        para.taxNo = this.form.nsrsbh;
        para.companyName = this.form.companyName;
      }
      const res = await saveCompanyInfo(para);
      if (res.msgCode == 1) {
        await this.basicinfo();
      }
    },
    typeNameYear() {
      if (this.form.tableType == 1) {
        this.tableCards[0].zq = this.zqValue.month[0];
        this.zqs[0] = this.zqValue.month[0];
      }
      if (this.form.tableType == 2) {
        this.tableCards[0].zq = this.zqValue.quarter[0];
        this.zqs[0] = this.zqValue.quarter[0];
      }
      if (this.form.tableType == 4) {
        this.tableCards[0].zq = this.zqValue.year[0];
        this.zqs[0] = this.zqValue.year[0];
      }

      let zaNameValue = "";
      let zqLast = this.zqs[0].substr(this.zqs[0].length - 1, 1);

      if (zqLast == 1) {
        zaNameValue =
          this.zqs[0].slice(0, 4) + "年" + this.zqs[0].slice(4, 6) + "月";
        return zaNameValue;
      }

      if (zqLast == 4) {
        let nextYear = this.zqs[0].slice(0, 4);
        zaNameValue = this.zqs[0].slice(0, 4) + "年";
        return zaNameValue;
      }

      if (zqLast == 2) {
        let year = this.zqs[0].slice(0, 4);
        let quer = this.zqs[0].slice(5, 6);
        zaNameValue = year + "年" + quer + "季度";
        return zaNameValue;
      }
    },
    async toUploadNextHaveVersion() {
      if (!this.agree) {
        this.$alert("请先同意《授权协议》");
        return;
      }

      // 提示用户当前的征期选择
      let typeName = this.typeNameYear();
      this.$confirm("当前选中的征期为" + typeName, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "重新选择",
        type: "warning",
      })
        .then(() => {
          this.$refs["form"].validate(async (valid) => {
            if (valid) {
              if (this.companyInfo.status == 0) {
                //匿名不保存企业信息
                if (
                  !(
                    getLocalAbilities(this.$route.params.version).includes(
                      "报税表检测流程"
                    ) && this.form.nsrsbh == "匿名"
                  )
                ) {
                  await this.updateCompanyInfo(true);
                } else {
                  await this.updateCompanyInfo(false);
                }
              } else {
                await this.updateCompanyInfo(false);
              }

              await this.tipIfCompanyInfoDifference();

              if (this.form.tableType == 1) {
                this.tableCards[0].zq = this.zqValue.month[0];
                this.zqs[0] = this.zqValue.month[0];
              }
              if (this.form.tableType == 2) {
                this.tableCards[0].zq = this.zqValue.quarter[0];
                this.zqs[0] = this.zqValue.quarter[0];
              }
              if (this.form.tableType == 4) {
                this.tableCards[0].zq = this.zqValue.year[0];
                this.zqs[0] = this.zqValue.year[0];
              }
              // const data = JSON.stringify(this.form);
              // sessionStorage.setItem("test-basic-info", data);
              sessionStorage.setItem(
                "isAnonymous",
                getLocalAbilities(this.$route.params.version).includes(
                  "报税表检测流程"
                ) && this.form.nsrsbh === "匿名"
              );
              // 发票版本跳发票检测
              if (this.versionRadio == 5 && !this.isShanDong) {
                this.$router.push({
                  name: "test-invoice",
                  params: {
                    id: this.tableCards[0].id,
                    zq: this.tableCards[0].zq,
                    zqLocation: this.zqs[0],
                    dataPeriod: 0,
                    version: this.versionRadio,
                    user: this.userNum,
                  },
                  query: {
                    deleteDataType: this.form.deleteDataType ? "1" : "2",
                    taxRateCalculation: this.form.taxRateCalculation,
                  },
                });
              } else {
                sessionStorage.setItem(
                  "basicConfig",
                  JSON.stringify({
                    deleteDataType: this.form.deleteDataType,
                    yearPicker: this.yearPicker.value,
                    agree: this.agree,
                  })
                );

                this.$router.push({
                  name: "upload-table",
                  params: {
                    id: this.tableCards[0].id,
                    zq: this.tableCards[0].zq,
                    zqLocation: this.zqs[0],
                    dataPeriod: 0,
                    version: this.versionRadio,
                    user: this.userNum,
                  },
                  query: {
                    deleteDataType: this.form.deleteDataType ? "1" : "2",
                    taxRateCalculation: this.form.taxRateCalculation,
                  },
                });
              }
            }
          });
        })
        .catch(() => {});
      // 提示用户当前的征期选择
    },

    // 未购买点击下一步提示弹窗
    toUploadNext() {
      this.$confirm(`请在基础设置内选择版本！`, "温馨提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        showCancelButton: false,
      })
        .then(() => {})
        .catch(() => {});
      return;
    },

    setUsername(val) {
      this.userNum = val;
    },

    async handleBtnStartClick() {
      // this.tableCards.reduce(uploaded=>{
      //   return uploaded+uploaded;
      // },0)
      this.loading1 = true;
      let uploadedCount = 0;
      this.tableCards.forEach((card) => {
        uploadedCount += card.uploaded;
      });

      if (uploadedCount === 0) {
        this.$alert("没有任何已经上传的数据,无法检测,请先上传数据", "错误", {
          type: "error",
        });
      } else if (!this.$store.state.UploadTable.permissionID) {
        //this.$alert("权限校验已过期，请重新操作").then(() => {
        this.$router.push({ name: "homePage" });
        //});
      } else {
        // const uuidRes = await getUUID();
        // const uuid = uuidRes.data.uuid;
        const zq = Math.max(...this.zqs).toString();
        const gentestReportRes = await genTestReport({
          appId: localStorage.getItem("appid_3rd") || "",
          bankId: this.bankId,
          goodsId: this.productID,
          kjzd: this.companyInfo.kjzd,
          token: this.authorization,
          nsrsbh: this.companyInfo.nsrsbm,
          tag: 2,
          id: this.$store.state.UploadTable.permissionID,
          uuid: this.uuid,
          type: "",
          zq,
          deleteDataType: this.form.deleteDataType,
          nameless: sessionStorage.getItem("isAnonymous") === "true",
        });

        if (gentestReportRes.msgCode == 1) {
          this.loading1 = false;
          //设置permissionID
          this.$store.commit("UploadTable/setPermissionID", {
            permissionID: "",
          });

          this.$router.push({
            name: "result",
            params: { uuid: this.uuid },
            query: {
              bankId: this.bankId,
              zq,
              uuid: this.uuid,
              nsrsbh: this.companyInfo.nsrsbm,
              name: this.$route.params.productName,
            },
          });
          this.showCheckports = false;
        } else {
          this.$alert(gentestReportRes.msgInfo);
          this.loading1 = false;
        }
      }
    },
    showCurrentZq(zq) {
      const info = zq2obj(zq);
      this.form.tableType = info.type;
      if (info.type === 1) {
        this.monthPicker.value = info.value;
      } else if (info.type === 2) {
        this.quanterPicker.value = info.value;
      } else if (info.type === 4) {
        this.yearPicker.value = info.value;
      }
    },
    ...mapMutations("UploadTable", ["reset"]),
    //显示上传模块
    showUploadmodules() {
      // const para = {
      //   bankId: this.bankId,
      //   qykjzd: this.companyInfo.kjzd
      // };
      // showModules(para).then(() => {
      //   //console.log(para)
      // });
    },
    async loadZQinfo(params) {
      this.zqInfoLoaded = true;
      //上传的表格信息
      // const uploadedTableData = await confirmUploadedTable(params);
      // //生成的弹框显示报表
      // this.uploadList = uploadedTableData;
      // for (var i in this.uploadList.data) {
      //   for (var j in this.uploadList.data[i]) {
      //     if (typeof this.uploadList.data[i][j] == "object") {
      //       this.newuploadList.push(this.uploadList.data[i][j]);
      //       let aa = 0;
      //       for (var m in this.uploadList.data[i][j]) {
      //         if (this.uploadList.data[i][j][m].uploadStatus == true) {
      //           aa += 1;
      //         }
      //       }
      //       this.uploadList.data[i][j].number = aa;
      //     }
      //   }
      // }
      // if (uploadedTableData) {
      //   this.zqInfoLoaded = true;
      //   this.tableCards = Object.entries(uploadedTableData.data).map(
      //     ([, zqInfo]) => {
      //       return {
      //         total: zqInfo.totalNum,
      //         uploaded: zqInfo.uploadNum,
      //         title: zqInfo.zqZw,
      //         productID: this.productID,
      //         zq: zqInfo.zqId
      //       };
      //     }
      //   );
      // }

      // return uploadedTableData;
    },
    async onMonthPickerChanged(val) {
      const year = val.getFullYear();
      const month = (val.getMonth() + 1).toString().padStart(2, 0);

      this.zqs = [];
      this.zqs.push(`${year}${month}1`);
      this.tableCards[0].zq = `${year}${month}1`;

      const secYear = year - 1;
      this.zqs.push(`${secYear}${month}1`);
      this.tableCards[1].zq = `${secYear}${month}1`;

      this.zqValue.month = [];
      this.zqValue.month[0] = `${year}${month}1`;
      this.zqValue.month[1] = `${secYear}${month}1`;

      await this.loadZQinfo({
        bankId: this.bankId,
        qykjzd: this.companyInfo.kjzd,
        nsrsbh: this.companyInfo.nsrsbm,
        zqList: this.zqs,
        goodsId: this.productID,
      });
    },
    async onQuarterPickerChanged(val) {
      const year = val.year;
      const quanter = val.quanter.toString().padStart(2, 0);

      let nextQuer;
      let nextYear;
      if (quanter == 1) {
        nextYear = year - 1;
        nextQuer = 4;
      } else {
        nextYear = year;
        nextQuer = quanter - 1;
      }
      nextQuer = "0" + nextQuer;

      this.zqs = [];
      this.zqs.push(`${year}${quanter}2`);
      this.tableCards[0].zq = `${year}${quanter}2`;

      const secYear = year - 1;
      this.zqs.push(`${nextYear}${nextQuer}2`);
      this.tableCards[1].zq = `${nextYear}${nextQuer}2`;

      this.zqValue.quarter = [];
      this.zqValue.quarter[0] = `${year}${quanter}2`;
      this.zqValue.quarter[1] = `${nextYear}${nextQuer}2`;

      await this.loadZQinfo({
        bankId: this.bankId,
        qykjzd: this.companyInfo.kjzd,
        nsrsbh: this.companyInfo.nsrsbm,
        zqList: this.zqs,
        goodsId: this.productID,
      });
    },
    async onYearPickerChanged(val) {
      const year = val;
      this.zqs = [];

      this.tableCards[0].zq = `${year}004`;
      this.zqs.push(`${year}004`);

      const secYear = year - 1;
      this.tableCards[1].zq = `${secYear}004`;
      this.zqs.push(`${secYear}004`);

      this.zqValue.year = [];
      this.zqValue.year[0] = `${year}004`;
      this.zqValue.year[1] = `${secYear}004`;

      await this.loadZQinfo({
        bankId: this.bankId,
        qykjzd: this.companyInfo.kjzd,
        nsrsbh: this.companyInfo.nsrsbm,
        zqList: this.zqs,
        goodsId: this.productID,
      });
    },
    async handleTableTypeChanged() {
      // let currentDate = new Date(); 2020
      let currentDate = new Date("2020");
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;
      switch (this.form.tableType) {
        case 1:
          //月
          if (!this.monthPicker.value) {
            this.monthPicker.value = currentDate;
          } else {
            await this.onMonthPickerChanged(this.monthPicker.value);
          }
          break;
        case 2:
          //季度
          if (!this.quanterPicker.value.year) {
            this.quanterPicker.value = {
              year: currentYear,
              quanter: Math.floor(currentMonth / 3),
            };
          } else {
            await this.onQuarterPickerChanged(this.quanterPicker.value);
          }
          break;
        case 3:
          //年
          if (!this.yearPicker.value) {
            this.yearPicker.value = currentYear.toString();
          } else {
            await this.onYearPickerChanged(this.yearPicker.value);
          }
          break;
      }
    },
  },
};
</script>
<style src="@/styles/index.styl" lang="stylus" scoped></style>
<style lang="stylus" scoped>
>>>.el-form-item__label:before{
  content: '*';
  color: #F56C6C;
  margin-right: 4px;
}
.page-wrapper {
  padding-bottom: 84px;
}

#status_upload {
  padding: 0;

  >>> .el-dialog__footer {
    text-align: center;

    .redfont {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(241, 58, 76, 1);
      margin: 38px 0 19px 0;
    }

    .el-button {
      border: none;
      background: #007AFF;
      font-size: 22px;
      font-family: Microsoft YaHei;
      padding: 18px 50px;
      margin-bottom: 30px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
    }
  }

  .headercss {
    >>> .el-tabs__header {
      .el-tabs__nav-wrap {
        .el-tabs__nav-scroll {
          .el-tabs__item {
            color: #7F9BC9;
            font-size: 18px;
          }

          .el-tabs__nav {
            left: 205px;

            .el-tabs__active-bar {
              height: 4px;
              background: #007AFF;
            }
          }

          .el-tabs__item.is-active {
            color: #062340;
            font-weight: bold;
          }
        }
      }
    }
  }

  >>> .el-dialog__body {
    padding: 0;
    overflow: hidden;
  }

  >>> .status_upload1 {
    .el-tabs__header .el-tabs__nav {
      left: 0px !important;
    }

    .el-tabs__item {
      height: 60px;
      font-size: 14px !important;
      line-height: 60px;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .el-tabs__header.is-left {
      margin-right: 0;
      background: #eaedf7;
      font-size: 14px;
    }

    .el-tabs__item.is-left {
      text-align: left;
      padding: 0 20px;
      color: #7f9bc9;
    }

    .el-tabs__item.is-active {
      background: #fff;
      color: #007aff !important;
    }

    .el-tabs__active-bar {
      width: 0 !important;
    }

    .el-tabs__content {
    }

    .el-tabs__nav-wrap::after {
      content: none;
    }
  }
}

.table-container {
  max-height: 450px;
  overflow-y: auto;

  .table {
    width: 90%;
    margin: 0 auto;
    border-spacing: 0;

    tr {
      th {
        background: #EAEDF7;
        color: #7F9BC9;
        font-weight: normal;
        padding: 15px;
      }

      td {
        padding: 15px;
        border-left: 1px solid #EAEDF7;
      }

      td:last-child {
        border-right: 1px solid #EAEDF7;
      }
    }

    tr:nth-child(odd) {
      td {
        background: #F5F7FC;
      }
    }

    tr:last-child {
      td {
        border-bottom: 1px solid #F5F7FC;
      }
    }

    .statusup {
      text-align: center;
    }

    .statusup1 {
      color: #56D756;
      font-weight: bold;
    }
  }
}

.section-box {
  position: relative;
  padding-bottom: 10px;
  padding: 0;

  .fix-title-tips {
    font-size: 14px;
    color: #F5222D;
    margin-left: 16px;
  }

  .loading {
    position absolute;
    left 0;
    right: 0;
    top: 0;
    bottom 0
    height 533px
  }
}

.btn-test {
  font-size: 18px;
}

.content-body {
  margin: 20px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  min-height: 500px;
  padding: 30px 0 0;
}
.pickers {
  padding 24px 48px 48px

  .switcher{
    display inline-block
    margin-left 40px
    .btn{
      border-radius 0
      margin 0
      background #eee
      color #999
      padding 8px 16px
    }
    .btn.active{
      background  #2f54eb
      color #fff !important
      cursor auto
      padding 8px 36px
    }
  }

}

#itemYear {
  margin: 6px 0;
  margin-bottom: 24px;
}

.cards {
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  flex-wrap: wrap;

  .card {
    flex: auto 0 0%;
    width: 250px;
    margin: 30px auto;
    transition: 0.3s all;
  }

  .card:hover {
    transform: translateY(-5px);
  }

  .placeholder {
    width: 250px;
    height: 0px;
    margin: 0px auto;
  }
}

.progressdis {
  width: 90%;
  margin: 24px auto;
}

.title-content {
  // width: 1260px;
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 0px 0px rgba(238, 238, 238, 1);
}

>>> .bread-crumb-wrapper {
  margin-top: 0;
}

.page-title-wrapper {
  padding: 0;
  padding-left: 30px;
  line-height: 28px;
}

.fix-title {
  height: 54px;
  line-height: 54px;
  background: rgba(255, 255, 255, 1);
  // box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
  border-radius: 8px 8px 0px 0px;
  border-bottom: 1px solid #D9D9D9;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  padding-left: 24px;
}

>>> .el-radio {
  margin-right: 24px;
}

>>> .el-radio__label {
  padding-left: 6px;
}





#itemYear >>> .el-input__inner {
  //padding-left: 35px;
}

#itemYear {
  position: relative;
}

#itemYear .el-icon-date {
  position: absolute;
  left: 100px;
  z-index: 2003;
}

.showVersion {
  position: relative;
  bottom: 2px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(47, 84, 235, 1);
  cursor: pointer;
  margin-left: 56px;
}

.iconjieshao {
  position: relative;
  top: 1px;
  right: 2px;
  font-size: 16px;
}

.company-box {
  margin-bottom: 20px;
}

.qymc {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  // font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 28px;
  margin: 8px 0 0 30px;
}

.form-bottom {
  display flex
  flex-direction row
  margin-left 110px

  .form-bottom-item {
    margin-right 45px
    color rgba(0, 0, 0, 0.6000);
    .toc {
      color: #2f54eb;
      display inline-block
      cursor pointer
      font-size 14px
    }

    >>> .el-checkbox__input.is-checked + .el-checkbox__label {
      color: rgba(0,0,0,0.6) !important;
    }

    .text {
      color rgba(0, 0, 0, 0.6000);
      display inline
    }
  }

}
</style>
