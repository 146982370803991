<template>
  <div class="financialRisk-h5-container" v-if="loaded">
    <div class="financialRisk-h5">
      <div class="company-baseName">
        <div class="company-tip"></div>
        <div class="company-tip-base">购销金额分析</div>
      </div>
    </div>
    <div class="PurchaseAndSale-h5">
      <div class="title">购销金额波动</div>
      <PurchaseAndSaleMoney
        :taxNo="companyInfo.taxNo"
        :zq="companyInfo.zq"
      ></PurchaseAndSaleMoney>
      <div class="chaseAndBuy" @click="toChaseAndBuy">
        <div>购销金额配比</div>
        <i class="el-icon-arrow-right"></i>
      </div>
      <div class="chaseAndBuy" @click="toBuy">
        <div>采购分析</div>
        <i class="el-icon-arrow-right"></i>
      </div>
      <div class="chaseAndBuy" @click="toSale">
        <div>销售分析</div>
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
    <div class="PurchaseAndSale-h5-title">*进入PC端查看更多风险详情及解决方案。</div>
    <!-- <div v-if="activeIndex == 1">
      <PurchaseAndSaleMoney
        :taxNo="companyInfo.taxNo"
        :zq="companyInfo.zq"
      ></PurchaseAndSaleMoney>
    </div>
    <div v-if="activeIndex == 2">
      <PurchaseAndSaleCategoryCompare
        :taxNo="companyInfo.taxNo"
        :zq="companyInfo.zq"
      ></PurchaseAndSaleCategoryCompare>
    </div> -->
  </div>
</template>

<script>
import "@/pages/Result/ThreeYearResult/global.styl";
import PurchaseAndSaleMoney from "@/pages/Result/H5FinancialAnalysis/purchaseAndSale/PurchaseAndSaleMoney";
import { getCompanyInfo } from "@/api/threeYears";
import PurchaseAndSaleCategoryCompare from "@/pages/Result/H5FinancialAnalysis/purchaseAndSale/PurchaseAndSaleCategoryCompare";
export default {
  components: {
    PurchaseAndSaleMoney,
    PurchaseAndSaleCategoryCompare,
  },
  name: "PurchaseAndSale",
  data() {
    return {
      activeIndex: "1",
      loaded: false,
      companyInfo: {},
    };
  },
  async mounted() {
    this.loaded = false;
    await this.loadCompanyInfo();
    this.loaded = true;
  },
  methods: {
    async loadCompanyInfo() {
      const res = await getCompanyInfo({ programId: this.$route.params.uuid });
      this.companyInfo = res.data;
    },
    /**
     * 顶部菜单点击
     * @param {string} key
     * @param {array<string>}keyPath
     */
    handleTopMenuSelect(key, keyPath) {
      this.activeIndex = key;
    },
    toChaseAndBuy() {
      this.$router.push({ path: "../purchaseAndSaleType/"+this.$route.params.uuid });
    },
    toBuy() {
      this.$router.push({ path: "../purchaseType/"+this.$route.params.uuid });
    },
    toSale() {
      this.$router.push({ path: "../purSaleType/"+this.$route.params.uuid });
    },
  },
};
</script>

<style lang="stylus" scoped>
.financialRisk-h5-container
  border: 2px solid #6AA1FF;
  border-style: dashed;
  margin: 0.4rem;
  padding: 0.24rem;
  border-radius: 4px;

.financialRisk-h5
  .company-baseName
    display: flex;
    align-items: center;
    color: #1D2129;
    font-size: 0.32rem;
    font-weight: 600;
    line-height: 0.33rem;
    margin-bottom: 0.16rem;

    .company-tip
      width: 0.12rem;
      height: 0.24rem;
      background: #2F54EB;
      margin-right: 0.1rem;

    .company-tip-base
      line-height: 0.33rem;

.PurchaseAndSale-h5
  border: 1px solid #E5E6EB;

  .title
    font-size: 0.28rem;
    color: #1D2129;
    line-height: 0.3rem;
    margin: 0.24rem 0 0 0.24rem;
    font-weight 600

  .chaseAndBuy
    display: flex;
    align-items: center;
    line-height: 0.78rem;
    border-top: 1px solid #E5E6EB;
    color: #1D2129;
    font-size: 0.28rem;
    justify-content: space-between;
    padding: 0 0.24rem;

    .el-icon-arrow-right
      font-size: 0.32rem;

.PurchaseAndSale-h5-title
  font-size: 0.2rem;
  color: #F53F3F;
  line-height: 0.3rem;
  margin: 0.16rem 0 0 0.24rem;
</style>
