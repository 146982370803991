var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.data)?_c('div',{staticClass:"china-wrapper"},[_c('div',{staticClass:"wrappers"},[_c('div',{staticClass:"map"},[(_vm.hp != 2)?_c('ChinaCity',{ref:"map",attrs:{"geo-coord-map":_vm.data.shAreaMap,"type":_vm.type,"value-data":_vm.valueData,"self":_vm.data.companyDistribution},on:{"click":_vm.handleMapClick}}):_vm._e(),(_vm.hp != 2)?_c('div',{staticClass:"legends"},[_vm._m(0),(_vm.type == 2)?_c('div',{staticClass:"legend-group"},[_c('div',{staticClass:"legend legend-top"},[_vm._v("X为采购额占比")]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4)]):(_vm.type == 1)?_c('div',{staticClass:"legend-group"},[_c('div',{staticClass:"legend"},[_vm._v("X为销售额占比")]),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)]):_vm._e()]):_vm._e()],1),(_vm.generalTableData)?_c('div',{staticClass:"table-wrapper"},[(_vm.generalTableData)?_c('BigPowerTable',{staticClass:"table",attrs:{"data":_vm.generalTableData}}):_vm._e(),(_vm.generalTableData && _vm.hp != 2)?_c('div',{staticClass:"sc",on:{"click":_vm.switchScreen}},[_c('img',{attrs:{"src":require("@/assets/util/hScreen.png"),"alt":""}})]):_vm._e()],1):_vm._e()]),(_vm.hp != 2)?_c('div',{staticClass:"full-sc"},[_c('el-drawer',{attrs:{"visible":_vm.horizontalScreen,"direction":"btt","size":"100%"},on:{"update:visible":function($event){_vm.horizontalScreen=$event}}},[_c('div',{staticClass:"full-tab"},[_c('div',{staticClass:"table-wrapper"},[(_vm.generalTableData)?_c('BigPowerTable',{staticClass:"table",attrs:{"data":_vm.generalTableData}}):_vm._e()],1)])])],1):_vm._e()]):_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(!_vm.data),expression:"!data"}],staticClass:"china-wrapper-none"})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legend-group"},[_c('div',{staticClass:"legend"},[_c('img',{staticClass:"symbol",attrs:{"src":"https://shuian-file.oss-cn-hangzhou.aliyuncs.com/image/icon_triangle.png"}}),_vm._v(" 表示本公司地点 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legend"},[_c('div',{staticClass:"box",staticStyle:{"background":"#207dfb"}}),_vm._v(" X≥50% ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legend"},[_c('div',{staticClass:"box",staticStyle:{"background":"#6eaafc"}}),_vm._v(" 50%＞X≥30% ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legend"},[_c('div',{staticClass:"box",staticStyle:{"background":"#aed0ff"}}),_vm._v(" 30%＞X≥20% ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legend"},[_c('div',{staticClass:"box",staticStyle:{"background":"#e0edff"}}),_vm._v(" 20%>X>0% ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legend"},[_c('div',{staticClass:"box",staticStyle:{"background":"#207dfb"}}),_vm._v(" X≥50% ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legend"},[_c('div',{staticClass:"box",staticStyle:{"background":"#6eaafc"}}),_vm._v(" 50%＞X≥30% ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legend"},[_c('div',{staticClass:"box",staticStyle:{"background":"#aed0ff"}}),_vm._v(" 30%＞X≥20% ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legend"},[_c('div',{staticClass:"box",staticStyle:{"background":"#e0edff"}}),_vm._v(" 20%>X>0% ")])
}]

export { render, staticRenderFns }