// 菜单配置
// headerMenuConfig：头部导航配置
// asideMenuConfig：侧边导航配置

const headerMenuConfig = [];

const asideMenuConfig = [{
    path: "/",
    name: "首页",
    icon: "iconfont iconshouye",
    routeNames: ["homePage"]
  },

  {
    path: "/",
    name: "检测中心",
    children: [{
        path: "/test/products",
        name: "风险检测",
        routeNames: [
          "test-hub",
          "choose-table",
          "upload-table",
          "result",
          "test-invoice"
        ],
        icon: "iconfont iconjiancezhongxin"
      },
      {
        path: "/test/test-log",
        name: "检测记录",
        routeNames: ["test-log"],
        icon: "iconfont iconlishishenbaobiao"
      },
      {
        path: "/setUserCompany",
        name: "企业信息",
        routeNames: ["setUserCompany","companyApply"],
        icon: "iconfont iconqiye"
      }
    ]
  },
  {
    path: "/",
    name: "产品中心",
    children: [{
        path: "/product/goods",
        name: "服务订购",
        icon: "iconfont iconchanpinzhongxin",
        routeNames: ["goods"]
      },
      // {
      //     path: "/product/shenbaobiao",
      //     name: "申报表",
      //     icon: "el-icon-c-scale-to-original",
      //     routeNames: ["shenbaobiao1","shenbaobiao2","ShenBaoBiaoResult"]
      // },
      {
        path: "/product/record",
        name: "我的订单",
        icon: "iconfont icondingdan",
        routeNames: ["order"]
      },
      {
        path: "/product/agent",
        name: "兑换记录",
        icon: "iconfont icondingdan",
        routeNames: ["agent"]
      },
      // {
      //     path: "/product/discount",
      //     name: "优惠券",
      //     icon: "iconfont iconyouhuiquan",
      //     routeNames: ["discount"]
      // },

    ]
  },

  {
    path: "/",
    name: "工具箱",
    icon: "el-icon-data-line",
    children: [{
        path: "/tools/toolsAudit",
        name: "尽调报告",
        icon: "iconfont iconzhanghu",
        routeNames: ["toolsAudit", "authorization", "analysisResult", "analysisTable",
          "toolsTables"
        ]
      },
      {
        path: "/util/calculator_tax",
        name: "个税计算器",
        icon: "iconfont icongeshuijisuanqi",
        routeNames: ["calculator_tax"]
      },
      {
        path: "/util/abnormal_tax",
        name: "异常纳税企业",
        icon: "iconfont iconyichangnashuiqiye",
        routeNames: ["abnormal_tax"]
      },
      {
        path: "/util/individual_tax",
        name: "个税申报检测",
        icon: "iconfont icongeshuishenbaojiance",
        routeNames: ["individual_tax"]
      },
      {
        path: "/util/yinHuaShuiUploader",
        name: "印花税合同分类工具",
        icon: "iconfont iconyinhuashuihetonggongju-weixuanzhong",
        routeNames: ["util-yinHuaShui-uploader", "util-yinHuaShui"]
      }
    ]
  },

  {
    path: "/",
    name: "用户设置",
    children: [{
      path: "/setUser",
      name: "用户设置",
      routeNames: ["setUser"],
      icon: "iconfont iconshezhi"
    }]
  }
];

export {
  headerMenuConfig,
  asideMenuConfig
};
