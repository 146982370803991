<template>
  <div>
    <div class="container">
      <div class="title">
        <div>采集数据进度</div>
        <div class="close" @click="closeStatus">×</div>
      </div>
      <div v-if="uploadStatus && showStatusPro">
        <div class="content contentTop">
          <span v-if="uploadStatus.sb == 0">申报数据读取失败</span>
          <span v-if="uploadStatus.sb == 1">申报数据读取中...</span>
          <span v-if="uploadStatus.sb == 2">申报数据读取完成</span>
          <span v-if="uploadStatus.sb == 3">申报数据已上传</span>
          <span v-if="uploadStatus.sb == 4">申报数据未上传</span>
        </div>
        <div class="statusC statusB">
          <div class="status">
            <div v-if="uploadStatus.sb == 0" class="status-info0"></div>
            <div
              v-if="uploadStatus.sb == 1"
              class="processStatus"
              :style="'width:' + classObject + '%'"
            ></div>
            <div v-if="uploadStatus.sb == 2" class="status-info2"></div>
            <div
              v-if="uploadStatus.sb == 3"
              class="status-info2"
              :style="'width:100%'"
            ></div>
          </div>
          <span>
            <span v-if="uploadStatus.sb == 0">
              <i class="el-icon-error"></i>
            </span>
            <span v-if="uploadStatus.sb == 1" class="uploading">
              <span>{{ classObject > 90 ? 90 : classObject }}%</span>
            </span>
            <span v-if="uploadStatus.sb == 2">
              <i class="el-icon-success"></i>
            </span>
            <span v-if="uploadStatus.sb == 3" class="uploading">
              <i class="el-icon-success"></i>
            </span>
            <span v-if="uploadStatus.sb == 4" class="gray">
              <span>0%</span>
            </span>
          </span>
        </div>
        <div class="falseStatus">
          <span v-if="uploadStatus.sb == 0">
            <span class="redC">申报数据读取失败</span>请更换
            <span class="grayC">“手动上传”</span>或
            <span class="grayC">联系客服</span>
          </span>
        </div>
        <div class="content">
          <span v-if="uploadStatus.yeb == 0">余额表数据未上传</span>
          <span v-if="uploadStatus.yeb == 1">余额表上传成功</span>
        </div>
        <div class="statusC">
          <div class="status">
            <div v-if="uploadStatus.yeb == 0"></div>
            <div v-if="uploadStatus.yeb == 1" class="status-info2"></div>
          </div>
          <span>
            <span v-if="uploadStatus.yeb == 0" class="gray">0%</span>
            <span v-if="uploadStatus.yeb == 1">
              <i class="el-icon-success"></i>
            </span>
          </span>
        </div>
        <div class="content">
          <span v-if="uploadStatus.fp == 0">发票数据读取失败</span>
          <span v-if="uploadStatus.fp == 1">发票数据读取中...</span>
          <span v-if="uploadStatus.fp == 2">发票数据读取完成</span>
        </div>
        <div class="statusC statusB">
          <div class="status">
            <div v-if="uploadStatus.fp == 0" class="status-info0"></div>
            <div
              v-if="uploadStatus.fp == 1"
              class="processStatus"
              :style="'width:' + classObject + '%'"
            ></div>
            <div v-if="uploadStatus.fp == 2" class="status-info2"></div>
          </div>
          <span>
            <span v-if="uploadStatus.fp == 0">
              <i class="el-icon-error"></i>
            </span>
            <span v-if="uploadStatus.fp == 1" class="uploading">
              <span>{{ classObject > 90 ? 90 : classObject }}%</span>
            </span>
            <span v-if="uploadStatus.fp == 2">
              <i class="el-icon-success"></i>
            </span>
          </span>
        </div>
        <div class="falseStatus">
          <span v-if="uploadStatus.fp == 0">
            <span class="redC">发票数据读取失败</span>请通过
            <span class="grayC">“发票取值插件”</span>重试或
            <span class="grayC">联系客服</span>
          </span>
        </div>
        <!-- 采集成功 -->
        <div
          class="processBtn"
          v-if="uploadStatus.sb == 2 && uploadStatus.fp == 2"
        >
          <div class="processText">
            数据采集成功，检测报告生成需T+1个工作日完成，请耐心等待！
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { progressBar } from "@/api/api";
export default {
  props: {
    uploadStatus: [Object],
    classObject: [Object, String, Number],
    processInfoStatus: [Boolean],
    processFalse: [Boolean],
    showStatusPro: [Boolean],
  },
  data() {
    return {};
  },
  methods: {
    closeStatus() {
      this.$emit("closeProcess");
    },
    toSuccess() {
      this.$router.push({
        name: "test-InvoiceUpload",
      });
    },
    toFix() {
      this.$emit("toInvoiceFix");
    },
    toReport() {
      this.$emit("toReportFix");
    },
    toBoth() {
      this.$router.push({
        name: "homePage",
      });
    },
  },
};
</script>

<style scoped>
.container {
  width: 800px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  line-height: 54px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
  border-radius: 8px 8px 0px 0px;
  padding: 0 14px 0 24px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(238, 238, 238, 1);
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.content {
  color: rgba(0, 0, 0, 0.6);
  line-height: 22px;
  margin-bottom: 6px;
  margin-left: 80px;
}

.contentTop {
  margin-top: 48px;
}

.close {
  font-size: 24px;
  padding: 10px;
  cursor: pointer;
}

.statusC {
  margin-bottom: 50px;
  margin-left: 80px;
}

.status {
  width: 560px;
  height: 12px;
  background: rgba(238, 238, 238, 1);
  border-radius: 8px;
  display: inline-block;
  margin-right: 16px;
  overflow: hidden;
}

.el-icon-success {
  color: #52c41a;
}

.el-icon-error {
  color: #f5222d;
}

.gray {
  color: rgba(0, 0, 0, 0.4);
}

.uploading {
  color: #2f54eb;
}

.status-info0 {
  width: 100%;
  height: 100%;
  background: #f5222d;
}

.status-info2 {
  width: 100%;
  height: 100%;
  background: #52c41a;
}

.processStatus {
  background: #2f54eb;
  height: 100%;
  border-radius: 8px;
}
.processBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 800px;
  height: 68px;
  background: rgba(255, 255, 255, 1);
  border-top: 1px solid rgba(238, 238, 238, 1);
}

.processBtns {
  width: 132px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(47, 84, 235, 1);
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  border-radius: 4px;
  cursor: pointer;
  margin-right: 30px;
}

.processBtns:hover {
  background: rgba(47, 44, 235, 1);
}

.ease {
  transition: all 0.3s;
}

.processText {
  float: left;
  line-height: 80px;
  color: rgba(0, 0, 0, 0.6);
  margin-left: 30px;
}

.statusB {
  margin-bottom: 0;
}

.falseStatus {
  margin-bottom: 30px;
  margin-top: 6px;
  margin-left: 80px;
  height: 18px;
  line-height: 18px;
  font-size: 12px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.4);
}

.redC {
  color: #f5222d;
  margin-right: 16px;
}

.grayC {
  color: rgba(0, 0, 0, 0.8);
}
</style>