<template>
  <div>
    <el-card class="usercard usercardx">
      <div class="checkPhone">绑定手机</div>
      <div class="box box-check1">
        <div class="qyname">手机号：</div>
        <div class="name">{{m1}}****{{m2}}</div>
      </div>
      <div class="box box1">
        <div class="qyname">验证码：</div>
        <div class="name">
          <el-input placeholder="请输入验证码" v-model="msgCode" class="mdf_input phone_input"></el-input>
        </div>
        <el-button
          type="text"
          class="code_btn"
          @click="getCode"
          :class="{disabled: !this.canClick}"
        >{{content}}</el-button>
      </div>
      <el-button type="primary" class="save" @click="next()">下一步</el-button>
    </el-card>
  </div>
</template>
<script>
import { getRestmobileCode, resetmobile } from "@/api/api";
export default {
  data() {
    return {
      content: "获取验证码",
      msgCode: "",
      mobile: "",
      totalTime: 60,
      canClick: true,
      m1: "",
      m2: "",
    };
  },
  mounted() {
    this.mobile = JSON.parse(localStorage.getItem("user")).mobile;
    this.m1 = this.mobile.substr(0, 3);
    this.m2 = this.mobile.substr(this.mobile.length - 4, 4);
  },
  methods: {
    getCode: function () {
      if (!this.canClick) return;
      let mobile = this.newphone;
      var para = {
        mobile: this.mobile,
      };
      getRestmobileCode(para).then((data) => {
        if (data.msgCode != -1) {
          this.$message.success("请注意查收短信验证码");
        } else {
          this.$message.error(data.msgInfo);
        }
      });
      this.canClick = false;
      this.content = this.totalTime + "s后重新发送";
      let clock = window.setInterval(() => {
        this.totalTime--;
        this.content = this.totalTime + "s后重新发送";
        if (this.totalTime < 0) {
          window.clearInterval(clock);
          this.content = "重新发送验证码";
          this.totalTime = 60;
          this.canClick = true;
        }
      }, 1000);
    },
    next() {
      if (!this.msgCode) {
        this.$message.error("请先输入验证码");
        return;
      }
      let token = JSON.parse(localStorage.getItem("token")).token;
      const para = {
        token: token,
        code: this.msgCode,
      };
      resetmobile(para).then((res) => {
        if (res.msgCode == 1) {
          this.$emit("listenmsg", "false");
        } else {
          this.$message.error(res.msgInfo);
        }
      });
    },
  },
};
</script>
<style src="@/styles/index.styl" lang="stylus" scoped></style>
<style scoped>
.usercardx {
  padding: 0;
}

.usercard >>> .el-card__body {
  padding: 0;
  height: auto;
  padding-bottom: 54px;
}

.checkPhone {
  height: 54px;
  line-height: 54px;
  background: rgba(255, 255, 255, 1);
  border-bottom: 1px solid rgba(217, 217, 217, 1);
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  padding-left: 24px;
}

.save {
  padding-bottom: 94px;
}

.box-check1 {
  padding-top: 8px;
}
</style>
