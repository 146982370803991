<template>
  <div>
    <div class="title">目录</div>
    <div class="toc">
      <div class="toc-item" v-for="(item,index) in toc" :key="index">
        <div class="label">{{item.title}}</div>
        <div class="pageNo">{{item.pageNo}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "TableOfContent",
  mounted() {
  },
  computed:{
  ...mapState("PdfResult", ["toc"])
  }
}
</script>

<style lang="stylus" scoped>
.title
  font-size: 32px;
  text-align: center;
  color: #030852;
  line-height: 42px;
.toc
  margin-top 100px
  .toc-item
    width 100%
    display flex
    line-height 1.5
    margin 10px 0
    font-size 16px
    justify-content space-between
    color #333
    background-image url("~@/assets/result/pdf/dot.png")
    background-repeat repeat-x
    background-size auto 4px
    background-position 50% 50%
    line-height 25px
    .label
      padding 0 10px
      background #fff
    .pageNo
      background #fff
      padding 0 10px

</style>
