<template>
  <div class="full-sc-1">
    <div class="close" @click="close">
      <img src="@/assets/test/part-close.png" alt="" />
    </div>
    <div class="table-wrapper-1">
      <HpLine2Chart
        class="column2Charthp"
        :chartData="data.jsonData"
        v-if="data && data.jsonData"
      ></HpLine2Chart>
    </div>
  </div>
</template>

<script>
import { getGXAmountCompare } from "@/api/threeYears";
import LineChart from "@/pages/Result/components/chats/LineChart";
import Line2Chart from "@/pages/Result/H5FinancialAnalysis/purchaseAndSale/Line2Chart";

import HpLine2Chart from "@/pages/Result/H5FinancialAnalysis/purchaseAndSale/hengpingLine2";

export default {
  components: {
    Line2Chart,
    HpLine2Chart,
  },
  name: "PurchaseAndSaleMoney",
  props: {
    taxNo: String,
    zq: [String, Number],
  },
  async mounted() {
    await this.loadGXAmountCompare();
  },
  data() {
    return {
      data: null,
    };
  },
  methods: {
    async loadGXAmountCompare() {
      const res = await getGXAmountCompare({
        taxNo: this.taxNo,
        zq: this.zq,
        programId: this.$route.params.uuid,
      });
      this.data = res.data || "";
    },
    close() {
      this.$router.back();
    },
  },
};
</script>

<style lang="stylus" scoped>
.HpLine2Chart {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
//   z-index: 9998;
}

.column2Charthp {
  width: 12rem;
  height: 5.8rem
  z-index: 1;
}

.column2Chart
  width: 100%;
  height: 6.3rem
  margin-top 16px
  margin-bottom: 10px

.noData {
  width: 100%;
  text-align: center;
  line-height: 60px;
  color: #1d2129;
  font-size: 0.28rem;
}

.column2Chart-c {
  position: relative;

  .sc {
    position: absolute;
    left: 5px;
    bottom: 0;
    padding: 5px;

    img {
      width: 0.25rem;
      height: 0.25rem;
      background: #FFFFFF;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
      border-radius: 0.08rem;
    }
  }
}

.full-sc {
  .full-tab {
    transform-origin: center center;
    transform: rotateZ(90deg);
  }
  >>> .el-drawer__header {
    padding: 0;
  }
  >>> .el-drawer__body {
    overflow: hidden;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  >>> .el-drawer__open .el-drawer.btt {
    border-radius: 0;
  }
  >>> .el-drawer__close-btn {
    font-size: 30px;
    position: relative;
    right: 10px;
    top: 10px;
  }
  .table {
    >>> .bigPowerTable {
      max-height 5.8rem
      max-width 85vh
      overflow auto
    }
    >>> table.table th, >>> table.table td {
      font-size: 0.2rem;
    }
  }
}

.full-sc-1 {
  position: fixed;
  width: 100vh;
  height: 100vw;
  top: 0;
  left: 100vw;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  transform-origin: 0% 0%;
  background: #fff;
//   z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;

  .close {
    position: absolute;
    top: 10px;
    left: 10px;

    img {
      padding: 10px;
      width: 15px;
      height: 15px;
    }
  }

  .table-wrapper-1 {
    // width: 12rem;
    // max-height: 5.8rem;
    // overflow: auto;
  }
}
</style>
