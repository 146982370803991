<template>
  <div class="pay">
    <div class="pay-container">
      <div class="pay-title">
        <span>购买报税体检</span>
        <span class="pay-span" @click="closePay">
          <img class="pay-icon" src="@/assets/test/part-close.png" alt />
        </span>
      </div>
      <div class="pay-content">
        <div class="pay-item pay-item24">
          <span class="pay-gray">购买企业：</span>税安科技（杭州）有限公司
        </div>
        <div class="pay-item">
          <span class="pay-gray">产品名称：</span>报税体检
        </div>
        <div class="pay-item">
          <span class="pay-gray">购买数量：</span>
          {{num}}个
        </div>
        <div class="pay-item">
          <span class="pay-gray">实际支付：</span>
          <span class="pay-thouth">{{OldPrice*num}}元</span>
          <span class="totalAmount">￥{{totalAmount}}</span>
        </div>
      </div>
      <div class="pay-img">
        <div>
          <div class="pay-method" :class="{actMethod:method==1}" @click="changePay">
            <img class="pay_pay" src="../img/wetchat_pay.png" alt />
            <span>微信支付</span>
          </div>
          <div class="pay-method" :class="{actMethod:method==2}" @click="changeWe">
            <img class="pay_pay" src="../img/al_pay.png" alt />
            <span>支付宝支付</span>
          </div>
        </div>
        <div class="pay-qr">
          <div class="pay-qrImg" v-if="wetch_imgs">
            <img :src="wetch_imgs" alt />
          </div>
          <div class="pay-qrTxt">文字</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      method: 1,
    };
  },
  props: {
    num: [Number, String],
    OldPrice: [Number, String],
    totalAmount: [Number, String],
    wetch_imgs: [Number, String],
  },
  methods: {
    closePay() {
      this.$emit("closePays");
    },
    changePay() {
      this.method = 1;
      this.$emit("changePay");
    },
    changeWe() {
      this.method = 2;
      this.$emit("changeWe");
    },
  },
};
</script>

<style scoped>
.pay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3000;
  background: rgba(0, 0, 0, 0.4);
}

.pay-container {
  width: 800px;
  height: 520px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  position: fixed;
  top: 15vh;
  left: 50%;
  margin-left: -400px;
}

.pay-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  line-height: 54px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
  border-radius: 8px 8px 0px 0px;
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  border-bottom: 1px solid rgba(238, 238, 238, 1);
  padding: 0 4px 0 24px;
}

.pay-span {
  padding: 5px 20px;
  cursor: pointer;
}

.pay-icon {
  width: 14px;
  height: 14px;
}

.pay-content {
  width: 752px;
  height: 176px;
  background: rgba(251, 251, 251, 1);
  border-radius: 4px;
  margin: 16px 0 16px 24px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
}

.pay-item {
  padding-top: 16px;
  padding-left: 24px;
}

.pay-item24 {
  padding-top: 24px;
}

.pay-gray {
  color: rgba(0, 0, 0, 0.4);
}

.pay-thouth {
  text-decoration: line-through;
}

.totalAmount {
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  line-height: 1;
  color: rgba(245, 34, 45, 1);
  margin-left: 16px;
}

.pay-img {
  display: flex;
}

.pay-qr {
  width: 578px;
  height: 234px;
  background: rgba(240, 245, 255, 1);
  border-radius: 4px;
}

.pay-method {
  display: flex;
  align-items: center;
  width: 158px;
  height: 48px;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  border: 1px solid rgba(217, 217, 217, 1);
  margin: 0 16px 8px 24px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}

.pay_pay {
  margin: 0 8px 0 24px;
}

.actMethod {
  background: rgba(240, 245, 255, 1);
  border: 1px solid rgba(47, 84, 235, 1);
}

.pay-qrImg {
  width: 138px;
  height: 138px;
  background: #f0f0f0;
  margin: 24px 0 8px 160px;
}

.pay-qrImg img {
  width: 100%;
  height: 100%;
}

.pay-qrTxt {
  margin-left: 160px;
}
</style>