<template>
  <div class="pay-success">
    <img class="icon" src="@/assets/auto/Icon-success.png" alt="" />
    <div class="title">支付成功</div>
    <div class="btn-c">
      <span class="btns order" @click="toOrder">查看订单</span>
      <!-- 跳产品选择 -->
      <span class="btns test" @click="toTest">立即检测</span>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toOrder() {
      this.$router.push({ name: "order" });
    },
    toTest() {
      this.$router.push({ name: "test-hub" });
    },
  },
};
</script>

<style lang="stylus" scoped>
.pay-success {
  height: 770px;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  margin: 24px 30px 30px 30px;
  text-align: center;

  .icon {
    width: 48px;
    height: 48px;
    margin-top: 244px;
  }

  .title {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
    margin-top: 8px;
  }

  .btn-c {
    font-size: 0;
    margin-top: 48px;

    .btns {
      display: inline-block;
      width: 104px;
      height: 36px;
      line-height: 36px;
      border: 1px solid #2F54EB;
      text-align: center;
      font-size: 14px;
      cursor: pointer;
    }

    .order {
      color: #2F54EB;
      margin-right: 24px;
    }

    .test {
      background: #2F54EB;
      color: #fff;
    }
  }
}
</style>