<template>
  <div class="full-sc-1-combuy  full-sc-flex" v-if="this.zq">
    <div class="close" @click="close">
      <img src="@/assets/test/part-close.png" alt="" />
    </div>
    <div class="yearsOption">
      <div
        class="yearsOption-items"
        :class="{ 'yearsOption-items-active': activeIndex == year }"
        v-for="(year, index) in yearsOption"
        @click="changeYearsOption(year)"
      >
        <div>{{ year }}年</div>
      </div>
    </div>
    <!-- <BigPowerSwitcher v-model="showType" class="switcher"></BigPowerSwitcher> -->
    <!--  -->
    <div class="table-wrapper-1">
      <BigPowerTable
        v-if="showType == 'table' && data && data.dataTable"
        :data="data.dataTable"
        class="table"
      ></BigPowerTable>
    </div>
  </div>
</template>

<script>
import "@/pages/Result/ThreeYearResult/global.styl";
import ChinaCityTable from "@/pages/Result/ThreeYearResult/financialAnalysis/ChinaCityTable";
import {
  getCompanyAreaList,
  getCompanyTopClassRisk,
  getCompanyInfo,
} from "@/api/threeYears";
import BigPowerTable from "big-power-table";
import BigPowerSwitcher from "@/pages/Result/H5FinancialAnalysis/components/BigPowerSwitcher";
import Pie from "@/pages/Result/H5FinancialAnalysis/components/Pie";

export default {
  name: "PurchaseAndSaleTabItem",
  props: {
    // zq: String,
    // taxNo: String,
    // companyType: String,
  },
  components: {
    Pie,
    BigPowerTable,
    BigPowerSwitcher,
  },
  data() {
    return {
      activeIndex: "",
      showType: "table",
      filter: {
        time: [],
        limit: 15,
      },
      data: null,
      horizontalScreen: false,
      loading: true,
      zq: "",
      taxNo: "",
      companyType: "",
    };
  },
  watch: {
    showType(value) {
      if (value !== "table") {
        this.loadCompanyTopClassRisk();
      }
    },
    activeIndex() {
      if (this.data) {
        this.data.ruleOfInvoiceImage = null;
        this.loadCompanyTopClassRisk();
      }
    },
    async companyType() {
      if (this.data) {
        this.data.ruleOfInvoiceImage = null;
        this.loadCompanyTopClassRisk();
      }
    },
  },
  activated() {
    this.loadCompanyInfo();
  },
  async mounted() {
    await this.loadCompanyInfo();
    // await this.loadCompanyTopClassRisk();
  },
  computed: {
    // theYear() {
    //   console.log(this.zq)
    //   return parseInt(this.zq.replace("004"));
    // },
    /**
     * 可选的年份
     * @returns {(number|number)[]}
     */
    yearsOption() {
      const zq = parseInt(this.zq.replace("004"));
      const ret = [zq, zq - 1, zq - 2];
      return ret;
    },
  },
  methods: {
    async loadCompanyInfo() {
      const res = await getCompanyInfo({ programId: this.$route.params.uuid });
      this.companyInfo = res.data;
      console.log(this.companyInfo);
      this.zq = this.companyInfo.zq;
      this.taxNo = this.companyInfo.taxNo;
      this.companyType = this.$route.query.companyType;
      this.theYear = parseInt(this.zq.replace("004"));
      this.activeIndex = this.theYear;
      this.loadCompanyTopClassRisk();
    },
    close() {
      this.$router.back();
    },
    changeYearsOption(val) {
      this.activeIndex = val;
      this.loadCompanyTopClassRisk();
    },
    switchScreen() {
      this.horizontalScreen = true;
    },
    async loadCompanyTopClassRisk() {
      this.data = null;
      const limit = this.showType == "table" ? this.filter.limit : 10;
      const res = await getCompanyTopClassRisk({
        taxNo: this.taxNo,
        year: this.activeIndex,
        showSize: limit,
        companyType: this.companyType,
        zq: this.zq,
      });
      this.data = res.data || [];
      this.$emit("loadCompanyTopClassRisk");
    },
  },
};
</script>

<style lang="stylus" scoped>
.toolbar {
  margin-top: 0.16rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toolbar-h5 {
  >>> .el-radio-button .el-radio-button__inner {
    width: 0.97rem;
    height: 0.4rem;
    line-height: 0.4rem;
    border-radius: 2px;
    font-size: 0.24rem;
  }

  >>> .el-radio-button__inner, .el-radio-group {
    display: inline;
  }
}

.tip {
  margin-top: 21px;
  margin-bottom: 23px;
  font-size: 14px;
  font-weight: 400;
  color: #86909C;
  line-height: 20px;
}

>>> .el-radio-button {
  margin-right: 10px;

  .el-radio-button__inner {
    border-radius: 2px;
    padding: 6px 16px;
    border: 0 none;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #86909C;
    box-shadow: none;
  }
}

>>> .el-radio-button.is-active {
  .el-radio-button__inner {
    background: rgba(22, 93, 255, 0.1);
    color: #165DFF;
    font-weight: bold;
  }
}

.tipBox {
  width: 100%;
  padding: 12px 16px;
  background: rgba(148, 191, 255, 0.1);
  border-radius: 2px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  color: #4E5969;
  line-height: 20px;
  margin-bottom: 16px;
  margin-top: 30px;
}

.switcher {
  margin-top: 0.16rem;
}

.chart-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.financialRisk-h5-container {
  border: 2px solid #6AA1FF;
  border-style: dashed;
  margin: 0.4rem;
  padding: 0.24rem;
  border-radius: 4px;

  .tip {
    font-size: 0.24rem;
    color: #4E5969;
    line-height: 0.3rem;
    margin: 0.16rem 0;
    line-height: 1.4;
  }
}

.financialRisk-h5 {
  .company-baseName {
    display: flex;
    align-items: center;
    color: #1D2129;
    font-size: 0.32rem;
    font-weight: 600;
    line-height: 0.33rem;
    margin-bottom: 0.16rem;

    .company-tip {
      width: 0.12rem;
      height: 0.24rem;
      background: #2F54EB;
      margin-right: 0.1rem;
    }

    .company-tip-base {
      line-height: 0.33rem;
    }
  }
}

.PurchaseAndSaleCom {
  >>> table.table th, >>> table.table td {
    font-size: 0.2rem;
  }
}

.table-wrapper {
  background: #FFFFFF;
  margin-top: 0.16rem;
  position: relative;

  .sc {
    position: absolute;
    right: 0px;
    bottom: 0px;
    padding: 5px;
    z-index: 300;

    img {
      width: 0.25rem;
      height: 0.25rem;
      background: #FFFFFF;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
      border-radius: 0.08rem;
    }
  }

  .table {
    >>> .bigPowerTable {
      max-height: 13rem;
      overflow: auto;
    }
  }
}

.PurchaseAndSale-h5-title {
  font-size: 0.2rem;
  color: #F53F3F;
  line-height: 0.3rem;
  margin: 0.16rem 0 0 0.24rem;
}

.full-sc {
  .full-tab {
    .t-w {
      display: flex;
      align-items: center;
    }

    transform-origin: center center;
    transform: rotateZ(90deg);

    .titleName {
      font-size: 0.28rem;
      font-weight: 600;
      color: #2B2F58;
      line-height: 0.36rem;
    }

    .dataText {
      font-size: 0.28rem;
      color: #40496A;
      line-height: 0.36rem;
      margin: 12px 0;
    }
  }

  >>> .el-drawer__header {
    padding: 0;
  }

  >>> .el-drawer__body {
    overflow: hidden;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  >>> .el-drawer__open .el-drawer.btt {
    border-radius: 0;
  }

  >>> .el-drawer__close-btn {
    font-size: 30px;
    position: relative;
    right: 10px;
    top: 10px;
  }

  // display: flex;
  // align-items: center;
  // justify-content: center;
  // flex-direction: column;
  .tab-s {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // flex-direction: column;
    // height: 100%;
    max-height: 220px;
    max-width: 500px;
    overflow: auto;

    .tablefirst {
      width: 100%;
      height: 100%;
      overflow: auto;
    }

    .tablefirst tr:nth-child(2n-1) td {
      background: #fff;
    }

    .tablefirst thead tr > th:first-child, .tablefirst tbody tr > td:first-child {
      position: -webkit-sticky;
      position: sticky;
      left: 0;
    }

    .table-screen-b {
      td, th {
        min-width: 80px;
        // max-width: 200px;
      }
    }
  }

  .table {
    >>> .bigPowerTable {
      max-height: 5.04rem;
      max-width: 85vh;
      overflow: auto;
    }

    >>> table.table th, >>> table.table td {
      font-size: 0.2rem;
    }
  }
}

.loadingType {
  width: 5rem;
  height: 500px;
  margin: 0 auto;
}

.yearsOption {
  display: flex;
  align-items: center;

  .yearsOption-items {
    width: 1.1rem;
    height: 0.49rem;
    line-height: 0.49rem;
    background: #F7F8FA;
    border-radius: 2px;
    text-align: center;
    font-size: 0.24rem;
    font-weight: 600;
    color: #4E5969;
    margin-right: 0.24rem;
  }

  .yearsOption-items-active {
    color: #165DFF;
    background: rgba(22, 93, 255, 0.1);
  }
}

.full-sc-1-combuy {
  position: fixed;
	width: 100vh;
	height: 100vw;
	top: 0;
	left: 100vw;
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
	transform-origin: 0% 0%;
  background: #fff;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  >>> .table .bigPowerTable {
    max-height: 5rem;
    overflow auto
    max-width: 12rem;
  }

  >>> table.table th, >>> table.table td {
    font-size: 0.2rem;
  }

  .close {
    position: absolute;
    top: 10px;
    left: 10px;

    img {
      padding: 10px;
      width: 15px;
      height: 15px;
    }
  }

  .table-wrapper-1-combuy {
    width: 10rem;
    max-height: 5.8rem;
    overflow: auto;
  }
}

.full-sc-flex {
  display flex
  flex-direction column
}
</style>
