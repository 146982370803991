<template>
  <div class="compare-wrapper">
    <table class="compare-table">
      <thead>
        <tr>
          <th v-for="(item, index) in proIntroduction.header" :key="index">
            {{ item }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in proIntroductionTr" :key="index">
          <td v-for="(citem, index) in proIntroductionTr[index]" :key="index">
            <span v-if="citem=='Y'">
              <img src="@/assets/auto/right.png" alt="">
            </span>
            <span v-else>{{ citem }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    proIntroduction: Object,
    proIntroductionTr: Array,
  },
};
</script>

<style lang="stylus" scoped>
.compare-wrapper {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);

  .compare-table {
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);
    margin: 24px auto;
    width: 100%;
    max-width: 1505px;
    border-radius: 8px;
    overflow: hidden;
    background: #fff;
    border-right: none;

    thead {
      th {
        text-align: center;
        padding: 44px 20px;
        border-right: 1px solid #d9d9d9;
        border-bottom: 1px solid #d9d9d9;
      }

      th:first-child {
        text-align: left;
        width: 20%;
        font-size: 24px;
      }

      th:last-child {
        border-right: none;
      }
    }

    tr:nth-child(odd) {
      td {
        background: #fbfbfb;
      }
    }

    tr:hover {
      td {
        background: #f6f6f6;
      }
    }

    tbody {
      tr {
        td {
          border-right: 1px solid #d9d9d9;
          padding: 26px 20px;
          text-align: center;

          .icon-checked {
            font-size: 22px;
            font-weight: bold;
          }
        }

        td:last-child {
          border-right: none;
        }

        td:first-child {
          text-align: left;
          width: 20%;
        }
      }
    }
  }
}
</style>
