<template>
  <div>
    <div class="pdf-mode" v-if="mode === 'pdf'">

      <template v-for="(year, index) in yearsOption">
        <div v-if="activeIndex == year" :key="index">
          <div class="title3">
            {{ orderNumber }} {{ year }}年{{ companyTypeLabel }}分析
          </div>
          <div class="desc" v-if="data && data.textList">
            <div v-for="(text, index) in data.textList" :key="index">{{ text }}</div>
            <div>
              按{{ year }}年不含税金额占比排序，前15 的{{ companyTypeLabel }}品类如下：
            </div>
          </div>
        </div>
      </template>
      <div v-loading="loading">

        <div class="no-data" v-if="!data">暂无数据</div>

        <BigPowerTable v-if="showType == 'table' && data && data.dataTable" :data="data.dataTable" class="table">
        </BigPowerTable>

        <template v-else-if="data && data.ruleOfInvoiceImage">
          <div class="chart-wrapper">
            <Pie :data="data.ruleOfInvoiceImage" :key="activeIndex" v-if="data.ruleOfInvoiceImage.imageType == 'annulus'"
              width="756px" height="500px"></Pie>
          </div>

        </template>
      </div>

    </div>
    <div v-else>
      <BigPowerSwitcher v-model="showType" class="switcher"></BigPowerSwitcher>
      <div class="toolbar">
        <el-radio-group v-model="activeIndex" @change="loadCompanyTopClassRisk">
          <el-radio-button :label="year" v-for="(year, index) in yearsOption" :key="index">{{ year }}年
          </el-radio-button>
        </el-radio-group>
      </div>
      <template v-for="(year, index) in yearsOption">
        <div v-if="activeIndex == year" :key="index">
          <div class="tipBox" v-if="data && data.textList">
            <div v-for="(text, index) in data.textList" :key="index">{{ text }}</div>
          </div>
          <div class="tip" v-if="showType == 'table'">
            采购金额排序：按{{ year }}年不含税金额占比排序，前
            <BigPowerInput type="number" :min="1" v-model="filter.limit" size="mini" style="width: 80px; margin: 0 10px"
              @input="loadCompanyTopClassRisk" :max="50"></BigPowerInput>
            的采购品类如下：
          </div>
        </div>
      </template>
      <div v-loading="loading">
        <BigPowerTable v-if="showType == 'table' && data && data.dataTable" :data="data.dataTable" class="table">
        </BigPowerTable>

        <template v-else-if="data && data.ruleOfInvoiceImage">
          <div class="chart-wrapper">
            <Pie :data="data.ruleOfInvoiceImage" :key="activeIndex" v-if="data.ruleOfInvoiceImage.imageType == 'annulus'"
              width="1060px" height="474px"></Pie>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import "@/pages/Result/ThreeYearResult/global.styl"
import ChinaCityTable from "@/pages/Result/ThreeYearResult/financialAnalysis/ChinaCityTable";
import { getCompanyAreaList, getCompanyTopClassRisk } from "@/api/threeYears";
import BigPowerTable from "big-power-table";
import BigPowerSwitcher from "@/pages/Result/ThreeYearResult/components/BigPowerSwitcher";
import Pie from "@/pages/ResultEditor/components/InvoiceIndex/components/Pie";

export default {
  name: "PurchaseAndSaleTabItem",
  props: {
    zq: String,
    taxNo: String,
    companyType: String,
    mode: String,
    orderNumber: String,
  },
  components: {
    Pie,
    BigPowerTable,
    BigPowerSwitcher
  },
  data() {
    return {
      activeIndex: '',
      showType: "table",
      filter: {
        time: [],
        limit: 15
      },
      loading: true,
      data: null,
      loading: true

    };
  },
  watch: {
    showType(value) {
      if (value !== 'table') {
        this.loading = true
        this.loadCompanyTopClassRisk()
      }
    },
    activeIndex() {
      if (this.data) {
        this.loading = true

        this.data.ruleOfInvoiceImage = null;
        this.loading = true
        this.loadCompanyTopClassRisk()
      }


    },
    async companyType() {
      if (this.data) {
        this.loading = true

        this.data.ruleOfInvoiceImage = null;
        this.loading = true
        this.loadCompanyTopClassRisk()
      }
    }
  },
  async mounted() {
    this.activeIndex = this.theYear
    await this.loadCompanyTopClassRisk();

  },
  computed: {
    companyTypeLabel() {
      return this.companyType == 2 ? '采购' : '销售'
    },
    theYear() {
      return parseInt(this.zq.replace("004"))
    },
    /**
     * 可选的年份
     * @returns {(number|number)[]}
     */
    yearsOption() {
      const zq = parseInt(this.zq.replace("004"))
      const ret = [zq, zq - 1, zq - 2]
      return ret
    }
  },
  methods: {
    async loadCompanyTopClassRisk() {
      this.data = null
      const limit = this.showType == 'table' ? this.filter.limit : 10;
      const res = await getCompanyTopClassRisk({
        taxNo: this.taxNo,
        year: this.activeIndex,
        showSize: limit,
        companyType: this.companyType,
        zq: this.zq
      });
      this.data = res.data
      this.$emit('loadCompanyTopClassRisk')
      this.loading = false
    }
  }
}
</script>

<style lang="stylus" scoped>
.toolbar
  margin-top 20px
  display flex
  justify-content space-between
  align-items center

.tip{
  margin-top 20px
  margin-bottom 20px
  font-size: 14px;
  font-weight: 400;
  color: #86909C;
  line-height: 20px;
}
>>> .el-radio-button {
  margin-right: 10px;

  .el-radio-button__inner {
    border-radius: 2px;
    padding: 6px 16px;
    border:0!important;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #86909C;
    box-shadow: none;
  }
}

>>> .el-radio-button.is-active {
  .el-radio-button__inner {
    background: rgba(22, 93, 255, 0.1);
    color: #165DFF;
    font-weight bold
  }
}

.tipBox {
  width 100%
  padding 16px 20px
  background:#F1F6FE;
  border-radius: 2px;
  box-sizing border-box
  font-size: 14px;
  font-weight: 400;
  color: #1D2129;
  line-height: 20px;
  margin-bottom 16px
  margin-top 20px
  div{
    margin-bottom: 10px;
  }

}
.tipBox div:last-child{
  margin-bottom:0;
}
.switcher
  position absolute;
  top 15px
  right 0

.chart-wrapper
  display flex
  justify-content center
  margin-top 10px

//选中
>>>.el-radio-button.is-active .el-radio-button__inner {
    background: #F1F6FE;
    color: #165dff;
    font-weight: bold;
    padding: 8px 16px;
    border-radius: 4px 4px 4px 4px;
}
//未选
>>>.el-radio-button .el-radio-button__inner {
  border-radius: 2px;
  padding: 8px 16px;
  border: 0 none;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #1D2129;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #F6F6F6;
  border-radius: 4px 4px 4px 4px;
}
>>>.el-radio-button .el-radio-button__inner:hover {
  background: #F1F6FE;
  padding: 8px 16px;
  border-radius: 4px 4px 4px 4px;

}
//表格样式
.table {
  border-bottom:1px solid #e5e6eb;
  >>>table.table th {
    background: #F1F6FE;
    padding: 13px 12px;
    font-weight: bold;
  }
  >>>table.table td{
    padding: 13px 12px;
    font-size:14px;
    color: #1D2129;
    font-weight: 500;
  }
  /* 滚动条thumb的背景色 */
  ::-webkit-scrollbar-thumb {
    width: 8px;
    background: #8D8F93;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
  }
}
//只显示两行
>>>td .content[data-v-10dcf266]{
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
}
</style>
