<template>
  <el-row class="container helpcon">
    <nav-bar />
    <el-col :span="24" class="main">
      <div class="sidecss">
        <scroll-bar>
          <div class="logo" @click="toindex()">
            <img :src="indexlogo" class="logo-pic" />
          </div>
          <div class="sider-bar-menu-wrapper">
            <template>
              <div v-for="(item,index) in showRightdetail" :key="index">
                <div :index="index.toString()">
                  <span class="leftnavitem">{{item.cateName}}</span>
                  <div
                    v-for="(items,indexs) in item.childList"
                    :index="index+'_'+indexs.toString()"
                    :key="indexs"
                    class="item"
                    @click="detailhelp(items)"
                    :class="{actived:!!items.title&&items.auto==false}"
                  >{{items.title}}</div>
                </div>
              </div>
            </template>
          </div>
        </scroll-bar>
      </div>

      <section class="content-container">
        <div class="grid-content bg-purple-light">
          <bread-crumb class="nav1">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/' }">帮助中心</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/' }">操作手册</el-breadcrumb-item>
            <el-breadcrumb-item>{{name}}</el-breadcrumb-item>
          </bread-crumb>
          <el-col :span="24" class="content-wrapper helpdetailcon">
            <div class="qymc">{{name}}</div>
            <div class="detail">
              <div class="helptitle">
                {{detail.title}}
                <p class="helptitletime">{{formatDateTime(detail.createTime)}}</p>
              </div>
              <div v-html="detail.detail" class="helpdetail"></div>
            </div>
          </el-col>
        </div>
      </section>
    </el-col>
  </el-row>
</template>

<script>
const base_content = process.env.VUE_APP_API_ROOT;
import NavBar from "@/layouts/HeaderAsideLayout/components/NavBar/NavBar";
import ScrollBar from "@/layouts/HeaderAsideLayout/components/ScrollBar";
import BreadCrumb from "@/components/BreadCrumb";
import axios from "axios";
export default {
  data() {
    return {
      showRightdetail: "",
      collapsed: false,
      index: "",
      detailid: "",
      defactive: this.$route.query.index + "_" + this.$route.query.indexs,
      detail: "",
      name: "",
      indexlogo: localStorage.getItem("index_logo") || null
    };
  },
  components: { NavBar, ScrollBar, BreadCrumb },
  methods: {
    formatDateTime(inputTime) {
      let date = new Date(inputTime);
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let minute = date.getMinutes();
      let second = date.getSeconds();
      minute = minute < 10 ? "0" + minute : minute;
      second = second < 10 ? "0" + second : second;
      return y + "/" + m + "/" + d + "    " + h + ":" + minute + ":" + second;
    },
    detailhelp(row) {
      this.name = row.title;
      for (var i in this.showRightdetail) {
        for (var j in this.showRightdetail[i].childList) {
          this.showRightdetail[i].childList[j].auto = true;
          if (this.showRightdetail[i].childList[j].id == row.id) {
            this.showRightdetail[i].childList[j].auto = false;
          }
        }
      }
      let that = this;
      axios
        .get(`${base_content}/news/loadById/` + row.id, {
          page: 1,
          size: 10
        })
        .then(function(res) {
          that.detail = res.data.data;
        });
    },
    handleopen() {},
    toindex() {
      this.$router.push("/");
    },
    handleclose() {},
    //折叠导航栏
    collapse: function() {
      this.collapsed = !this.collapsed;
    },
    handleselect: function(a, b) {},
    basicinfo() {
      let that = this;
      // 获取列表（右侧导航栏）
      axios
        .post(`${base_content}/category/loadByPage`, {
          page: 1,
          size: 10,
          type: "1"
        })
        .then(function(res) {
          let arrNew = [];
          let odlarr = res.data.data.content;
          for (var i = 0; i < odlarr.length; i++) {
            if (odlarr[i].cateName == "公告") {
              // arrNew.unshift(odlarr[i]);
            } else {
              arrNew.push(odlarr[i]);
            }
          }
          that.showRightdetail = arrNew;
          that.showRightdetail[0].childList[0].auto = false;
          that.name = that.showRightdetail[0].childList[0].title;

          if (that.$route.query.id == undefined) {
            that.detailid = arrNew[0].childList[0].id;
          } else {
            that.detailid = that.$route.query.id;
          }
        })
        .then(res => {
          // 文章详情
          let that = this;
          axios
            .get(`${base_content}/news/loadById/` + this.detailid, {
              page: 1,
              size: 10
            })
            .then(function(res) {
              that.detail = res.data.data;
            });
        });
    }
  },
  mounted() {
    this.basicinfo();
  }
};
</script>

<style scoped lang="stylus">
.qymc {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  line-height: 28px;
  margin-bottom: 21px;
}

.sidecss {
  width: 180px;
  z-index: 99;

  >>>.scroll-container .scroll-wrapper {
    width: 180px;
  }

  .logo {
    display: flex;
    width: 100%;
    position: relative;
    left: 0px;
    justify-content: center;
    align-items: center;
    height: 60px;
    line-height: 64px;
    background: #fff;
    color: #333;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    overflow: hidden;

    .logo-pic {
      width: 122px;
      margin-top: 10px;
    }
  }

  .sider-bar-menu-wrapper {
    margin-top: 16px;
    flex-grow: 1;
    font-size: 14px;
    line-height: 1.5;

    .item {
      cursor: pointer;
      height: 36px;
      display: flex;
      align-items: center;
      padding-left: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
    }

    .item.actived {
      background: #2f54eb;
      color: #fff;
    }

    .leftnavitem {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.8);
      margin-left: 16px;
      height: 38px;
      line-height: 38px;
    }

    .item.actived {
      background: #2f54eb;
      color: #fff;
    }

    .group {
      .title {
        font-size: 16px;
        color: #333;
        font-weight: 500;
        padding: 16px 0 16px 16px;
      }
    }
  }
}

.grid-content {
  height: 100%;
  background: #f5f5f5;

  .nav1 {
    >>> .bread-crumb-wrapper {
      margin-top: 0;
      padding: 24px 30px;
    }
  }
}

.helpdetailcon {
  width: 95%;
  margin: 0 auto;
  float: none;
  background-color: #f5f5f5 !important;
}

.detail {
  text-align: center;
  padding: 20px 10%;
  background: #fff;
  margin: 0 auto;
  padding-top: 50px;

  .helptitle {
    font-size: 24px;
    color: #1A1A1A;
    font-weight: bold;
    padding-bottom: 40px;
    border-bottom: 1px solid #e4e2e2;
    margin-bottom: 40px;

    .helptitletime {
      font-size: 12px;
      color: #A0A0A0;
      font-weight: normal;
      margin-top: 15px;
    }
  }

  .helpdetail {
    text-align: left;

    img {
      width: 100%;
    }
  }
}

.dropdownnav {
  a {
    color: #606266;
  }

  img {
    vertical-align: middle;
    display: inline-block;
    margin-right: 5px;
  }
}

.logoright {
  font-size: 18px;
  color: #808080;
  display: inline-block;
  margin-left: 40px;
  vertical-align: top;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 34px;
    background: #d1d1d1;
    left: -24px;
    top: 28px;
  }
}

.loginsure {
  text-align: center;

  p {
    margin-top: 25px;
    font-size: 18px;
    color: #808080;
  }
}

.useravt {
  display: inline-block;
  margin-left: 40px;
  position: relative;
  height: 32px;
  line-height: 32px;

  img {
    &.avt {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }

    &.rzimg {
      position: absolute;
      bottom: -4px;
      right: 4px;
    }
  }
}

.qyrz {
  display: inline-block;
  font-size: 18px;
  color: #808080;
  margin-left: 20px;
  position: relative;
  cursor: pointer;

  &::after {
    position: absolute;
    content: '';
    height: 20px;
    width: 1px;
    background: #d1d1d1;
    left: -30px;
    top: 35px;
  }

  a {
    font-size: 18px;
    color: #808080;
  }

  img {
    vertical-align: middle;
  }

  .qyrzbtn {
    display: inline-block;
    cursor: pointer;
    line-height: 30px;
    border-radius: 15px;
    background: #2644f7;
    padding: 23px 5px;
    padding: 0px;
    font-size: 14px;
    color: #ffffff;
    text-decoration: none;
    padding: 0 23px;
    margin-left: 15px;
  }
}

.el-menu-vertical-demo {
  padding-top: 5px;

  i {
    margin-right: 10px;
    color: rgb(60, 120, 234);
  }
}

.el-menu-item:hover {
  color: #2644f7;
}

.sunanlogo {
  width: 100px;
  height: 30px;
  position: absolute;
  top: 600px;
  left: 36px;
}

.container {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;

  .main {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0px;
    overflow: hidden;

    aside {
      flex: 0 0 180px;
      width: 180px;
      z-index: 100;
      box-shadow: 0 0 5px 1px #eee;

      >>> .el-menu {
        border-right: none;
        height: 100%;
        background: none;

        >>>.el-submenu .is-active {
          background: #4E9AEF;
          color: #ffffff;
        }
      }

      >>>.collapsed {
        width: 60px;

        .item {
          position: relative;
        }

        >>>.submenu {
          position: absolute;
          top: 0px;
          left: 60px;
          z-index: 99999;
          height: auto;
          display: none;
        }
      }
    }

    >>> .menu-collapsed {
      flex: 0 0 60px;
      width: 60px;
    }

    >>> .menu-expanded {
      flex: 0 0 180px;
      width: 180px;
      box-shadow: none;
      background: #062340;

      >>> .el-menu {
        width: 100% !important;
      }

      >>> .el-submenu .el-menu-item {
        min-width: 256px !important;
        padding-left: 48px !important;
        background-color: #fff !important;

        &:hover {
          color: #333 !important;
        }
      }

      .el-menu-item, .el-submenu .el-menu-item {
        &.is-active {
          background-color: #fff !important;
          color: #333 !important;
        }
      }
    }

    .content-container {
      flex: 1;
      overflow-y: scroll;
      margin-top: 60px;
      z-index: 8;

      .breadcrumb-container {
        .title {
          width: 200px;
          float: left;
          color: #475669;
        }

        .breadcrumb-img {
          width: 80%;
          margin: 0 auto;

          img {
            width: 100%;
          }
        }

        .breadcrumb-inner {
          padding-top: 5px;
          width: 80%;
          margin: 0 auto;
        }
      }

      .content-wrapper {
        background-color: #fff;
        box-sizing: border-box;
      }
    }
  }
}

.helpcon>>> .el-menu--inline {
  background: #f4f7fe;
}

.helpcon >>> .el-menu--inline li {
  padding-left: 70px;
  font-size: 18px;
  color: #808080;
}

.helpcon >>> .el-submenu__title {
  font-size: 18px;
  padding-left: 50px !important;
  padding-right: 0px !important;
  color: #ffffff;
}

.helpcon >>> .el-menu--inline {
  background: #324E87;
}

.helpcon >>> .el-submenu__title:hover {
  background: #4E9AEF;
}

.helpcon >>> .el-menu--inline li {
  color: #ffffff;
}

.helpcon >>> .el-submenu__title i {
  display: none;
}

.helpcon >>> .el-menu-item:hover {
  background: #4E9AEF;
  color: #ffffff !important;
}

.helpcon>>> .el-menu-item {
  min-width: 0px !important;
  padding-left: 24px !important;
  padding-right: 0px !important;
}

.helpcon >>> .el-menu-item.is-active {
  font-size: 18px !important;
}

.helpcon >>> .el-message-box {
  border-radius: 10px;
  text-align: center;
}

.helpcon >>> .el-message-box__headerbtn>>> .el-message-box__close {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #1a1a1a;
  border-radius: 50%;
  color: #1a1a1a;
  font-size: 23px;
}

.helpcon>>> .el-dialog {
  background: #ffffff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.helpcon>>> .el-dialog__footer {
  text-align: center;
  padding-bottom: 60px;
  padding-top: 25px;
}

.helpcon >>> .dialog-footer>>> .el-button.el-button--primary {
  width: 200px;
  height: 55px;
  background: #2644f7;
  border-radius: 32.5px;
  font-size: 18px;
  color: #ffffff;
  font-weight: bold;
}

.helpcon >>> .dialog-footer>>> .el-button.el-button--default {
  border: 2px solid #2644f7;
  border-radius: 32.5px;
  box-sizing: border-box;
  width: 200px;
  height: 55px;
  font-size: 18px;
  color: #2644f7;
  font-weight: bold;
}

.helpcon >>> .dialog-footer>>> button.active {
  background: #2644f7;
  color: #ffffff !important;
  width: 360px !important;
}

.helpcon>>> .el-dialog__headerbtn>>> .el-dialog__close {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 24px;
  border: 1px solid #1a1a1a;
  border-radius: 50%;
  color: #1a1a1a;
}

.helpcon >>> .el-dialog__headerbtn>>> .el-dialog__close:hover {
  color: #2644f7;
  border-color: #2644f7;
}

.helpcon>>> .el-button + .el-button {
  margin-left: 20px;
}

.helpcon >>> .el-dialog__title {
  text-align: center;
  font-size: 24px;
  color: #1a1a1a;
  font-weight: bold;
}

.helpcon>>> .el-dialog__header {
  text-align: center;
  padding-top: 40px;
}

.helpcon>>> .el-dialog__headerbtn {
  top: 30px;
  right: 30px;
}

.helpcon>>> .qyrzdialog>>> .el-dialog__body {
  padding: 30px 80px;
}

/* .helpcon .el-icon-arrow-down:before{
  content: '';
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 6px solid #ffffff;
}
.helpcon>>> .el-icon-arrow-down{
  margin-top: 10px;
} */
.helpcon {
  .el-menu {
    li {
      font-size: 18px;

      .naviconbg {
        display: inline-block;
        width: 32px;
        height: 32px;
        padding-left: 0px !important;
        background-repeat: no-repeat;
        background-position: 2px center;
        position: absolute;
        left: 20px;
        top: 13px;
      }
    }
  }
}
</style>

