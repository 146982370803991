import axios, {get, post} from "@/axios";

const base = process.env.VUE_APP_API_ROOT;
const basePlat = `${base}/plat`;
const baseWechat = process.env.VUE_APP_API_ROOT_WECHAT;

//第三方登录后注册
export const regirster3rdPartyAuth = async params => {
  const {mobile, verifyCode, openId, nickName, headImgUrl, unionId, regCode = ""} = params;
  const url = `${basePlat}/thirdUser/wxRegister`;
  const res = await post(url, {
    mobile,
    verifyCode,
    openId,
    unionId,
    regCode,
    nickName,
    headImgUrl
  });
  return res.data;
};

//根据userId获取用户信息
export const getUserByMobile = async mobile => {
  const url = `${basePlat}/thirdUser/getUserByMobile`;
  const res = await post(url, {mobile});
  return res.data;
};

//发送短信验证码
export const getSMSCode = params => {
  return get(`${basePlat}/bCompanyUser/code/WXREGISTER/` + params).then(res => res.data);
};

//发送切换绑定验证码
export const getBindSMSCode = mobile => {
  return get(`${basePlat}/bCompanyUser/code/CHANGEWX/` + mobile).then(res => res.data);
};

//微信切换校验验证码
export const verifyCodeForWx = async params => {
  const {mobile, verifyCode} = params;
  const url = `${basePlat}/thirdUser/verifyCodeForWx`;
  const res = await post(url, {mobile, verifyCode});
  return res.data;
};

//检查手机号绑定微信
export const checkIsBindWx = async mobile => {
  const url = `${basePlat}/thirdUser/checkIsBindWx`;
  const res = await post(url, {mobile});
  return res.data;
};

//微信公众号登录
export const getWxQRCode = async () => {
  const url = `${baseWechat}/weixin/getQRCode`;
  const res = await get(url);
  return res.data;
};

//微信登录轮询
export const checkScanLogin = async sceneStr => {
  const url = `${baseWechat}/weixin/checkScanLogin`;
  const res = await get(url, {sceneStr: sceneStr});
  return res.data;
};

//检查用户密码是否存在
export const getUserPwd = async (token) => {
  const url = `${basePlat}/bCompanyUser/getUserPwd`;
  const res = await get(url,{token});
  return res.data;
};

