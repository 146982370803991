<template>
  <div>
    <div class="msg-title">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ name: 'homePage' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>检测记录</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <main-header>
        <template slot="title">检测记录</template>
      </main-header>
    </div>
    <div class="filter-body">
      <div class="inputs-wrapper">
        <div class="inputs-wraper-row">
          <!-- <div class="form-item">
            <div class="label">企业名称：</div>
            <el-input
              v-model="filter.name"
              placeholder="请输入企业名称"
            ></el-input>
          </div> -->
          <div class="form-item">
            <div class="label">检测时间：</div>
            <el-date-picker
              v-model="filter.time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="changeTime"
            ></el-date-picker>
          </div>
          <div class="form-item">
            <div class="label">检测版本：</div>
            <el-select
              v-model="filter.source"
              clearable
              placeholder="请选择版本"
              @change="changeVer"
            >
              <el-option
                v-for="item in versionList"
                :key="item.edition"
                :label="item.name"
                :value="item.edition"
              ></el-option>
            </el-select>
          </div>
          <div class="form-item">
            <div class="label">状态：</div>
            <el-select
              v-model="filter.status"
              clearable
              placeholder="请选择状态"
              @change="changeStatus"
            >
              <el-option
                v-for="item in sources"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <!-- <button
        class="btn btn-primary btn-look-up"
        data-index="100"
        @click="search"
      >
        查询
      </button>
      <button class="btn btn-primary btn-look-up" @click="reset">重置</button> -->
    </div>
    <div class="content-body">
      <template>
        <el-table
          :data="tableData"
          :default-sort="{ prop: 'date', order: 'descending' }"
          style="width: 100%"
        >
          <!-- <el-table-column prop="id" label="编号" width="70px"></el-table-column> -->
          <el-table-column
            prop="exeTime"
            label="检测时间"
            sortable
            min-width="15%"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="companyName"
            label="企业名称"
            min-width="18%"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column prop="zqId" label="征期类型" min-width="10%">
            <template slot-scope="scope">
              <span v-if="scope.row.zqId.slice(-1) == 4">年度</span>
              <span v-if="scope.row.zqId.slice(-1) == 2">季度</span>
              <span v-if="scope.row.zqId.slice(-1) == 1">月度</span>
            </template>
          </el-table-column>

          <el-table-column prop="zqMc" label="报表征期" min-width="10%"></el-table-column>
          <el-table-column prop="tagName" label="测评版本" min-width="10%"> </el-table-column>

          <el-table-column label="报告状态" min-width="10%">
            <template slot-scope="scope">
              <span v-if="scope.row.showStatus == 1"> <span class="done"></span>已生成 </span>
              <span v-if="scope.row.showStatus == 2"> <span class="pedding"></span>生成中 </span>
              <span v-if="scope.row.showStatus == 3"> <span class="delete"></span>已删除 </span>
              <span v-if="scope.row.showStatus == 4"> <span class="perfect"></span>待完善 </span>
              <span v-if="scope.row.showStatus == 5"> <span class="perfect"></span>生成出错 </span>
              <span v-if="scope.row.showStatus == 6"> <span class="pedding"></span>生成中 </span>
            </template>
          </el-table-column>
          <el-table-column prop="viewReport" label="操作" width="160px">
            <template slot-scope="scope">
              <router-link
                v-if="scope.row.showStatus == 5 && scope.row.invoiceAccessStatus == 2010"
                tag="a"
                :to="{
                  name: 'test-noData',
                }"
                class="btn-view btn-blue"
                >报告</router-link
              >
              <span
                @click="checkReason(scope)"
                class="btn-view btn-blue"
                v-if="scope.row.showStatus == 5 && scope.row.invoiceAccessStatus != 2010"
                >原因</span
              >
              <!-- <span
                @click.prevent="
                  dowmpdf(scope.row.uuid, scope.row.zqId, scope.row.bankid)
                "
                class="btn-view btn-blue"
                target="_blank"
                v-if="scope.row.showStatus == 1"
                >下载</span
              > -->

              <router-link
                tag="a"
                target="_blank"
                v-if="scope.row.showStatus == 1 && scope.row.tagName == '老板看报告'"
                :to="{
                  name: 'boss-watch-report',
                  params: { uuid: scope.row.uuid, nsrsbh: scope.row.nsrsbh },
                }"
                class="btn-view btn-blue"
                >查看{{
              }}</router-link>

              <router-link
                tag="a"
                target="_blank"
                v-if="scope.row.showStatus == 1 && scope.row.tagName != '老板看报告'"
                :to="{
                  name: 'detect',
                  params: { uuid: scope.row.uuid, nsrsbh: scope.row.nsrsbh },
                }"
                class="btn-view btn-blue"
                >查看</router-link
              >

              <!--              <span v-if="scope.row.showStatus == 1 && scope.row.tag != 20">-->
              <!--                <router-link-->
              <!--                  tag="a"-->
              <!--                  target="_blank"-->
              <!--                  :to="{-->
              <!--                    name: 'result-head',-->
              <!--                    params: { uuid: scope.row.uuid },-->
              <!--                    query: {-->
              <!--                      bankId: scope.row.bankid,-->
              <!--                      zq: scope.row.zqId,-->
              <!--                      uuid: scope.row.uuid,-->
              <!--                      nsrsbh: scope.row.nsrsbh,-->
              <!--                      name: scope.row.bankMc,-->
              <!--                    },-->
              <!--                  }"-->
              <!--                  class="btn-view btn-blue"-->
              <!--                  >查看</router-link-->
              <!--                >-->
              <!--              </span>-->

              <!--              <span v-if="scope.row.showStatus == 1 && scope.row.tag == 20">-->
              <!--                <router-link-->
              <!--                  tag="a"-->
              <!--                  target="_blank"-->
              <!--                  :to="{-->
              <!--                    name: 'ThreeYearResult',-->
              <!--                    params: { uuid: scope.row.uuid },-->
              <!--                    query: {-->
              <!--                      bankId: scope.row.bankid,-->
              <!--                      zq: scope.row.zqId,-->
              <!--                      zqLocation: scope.row.zqId,-->
              <!--                      uuid: scope.row.uuid,-->
              <!--                      nsrsbh: scope.row.nsrsbh,-->
              <!--                      taxNo: scope.row.invoiceNsrsbh,-->
              <!--                      name: scope.row.bankMc,-->
              <!--                      version: scope.row.tag,-->
              <!--                      companyName: scope.row.companyName,-->
              <!--                    },-->
              <!--                  }"-->
              <!--                  class="btn-view btn-blue"-->
              <!--                  >查看</router-link-->
              <!--                >-->
              <!--              </span>-->

              <span
                class="btn-view btn-blue"
                v-if="scope.row.showStatus != 3"
                @click="deletes(scope.row.uuid, scope.row.nsrsbh)"
                >删除</span
              >

              <span class="btn-blue" @click="retry()" v-if="scope.row.showStatus == 4">重试</span>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <div class="paginations">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageno + 1"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pagesize"
        layout="prev, pager, next, jumper"
        :total="totalElements"
      ></el-pagination>
    </div>
    <supple
      :checkParts="checkParts"
      :taxAccessStatus="taxAccessStatus"
      @cancel="cancel"
      @submit="submit"
    ></supple>
    <div class="process-statusC" v-if="locks">
      <process-status
        v-loading="coverLoading"
        class="process-statuss"
        :uploadStatus="uploadStatus"
        :classObject="classObject"
        :processInfoStatus="processInfoStatus"
        :processFalse="processFalse"
        @closeProcess="closeProcess"
        :showStatusPro="showStatusPro"
      ></process-status>
    </div>
    <reminder
      @closeToast="closeToast"
      v-if="reminderToast"
      class="reminder-toast"
      :option="option"
    ></reminder>
  </div>
</template>

<script>
import { downPDFurl, retryMethods } from "@/api/api";
import {
  getHistoryReports,
  getAPIBase,
  deleteRule,
  getBoGoodsEdition,
  getCheckReason,
} from "@/api/api";
import { mapState } from "vuex";
import { zq2obj } from "@/utils/helper";
import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "@/pages/components/MainHeader";
import supple from "./supplement";
import processStatus from "./components/processStatus";
import reminder from "@/layouts/HeaderAsideLayout/components/NavBar/components/reminder";

export default {
  components: {
    MainHeader,
    BreadCrumb,
    supple,
    processStatus,
    reminder,
  },
  async mounted() {
    this.getFilterList();
    localStorage.removeItem("policy");
    await this.$store.cache.dispatch("loadCompanyInfo");
    this.search();
    sessionStorage.removeItem("threeActiveIndex");
    sessionStorage.removeItem("dataPeriod");
  },
  computed: {
    ...mapState(["companyInfo"]),
  },
  data() {
    return {
      reminderToast: false,
      option: {
        cancel: false,
        close: true,
        submit: "确定",
        type: 2,
        content: "",
        icon: 2,
        title: "生成失败",
      },
      showStatusPro: false,
      checkParts: false,
      dataVal: null,
      uploadStatus: null,
      classObject: "",
      processInfoStatus: false,
      processFalse: false,
      isSuccessUpload: sessionStorage.getItem("isSuccessUpload") || false,
      timestamp: "",
      locks: false,

      totalElements: 0,
      taxAccessStatus: "",
      firstTime: "2018-11-13",
      lastTime: "2099-12-30",
      pageno: 0,
      pagesize: 10,
      tag: "",
      goodsId: "",
      filter: {
        name: "",
        status: "",
        time: [],
        source: "",
      },
      APIBase: getAPIBase(),
      tableData: [],
      sources: [
        {
          value: "1",
          label: "已生成",
        },
        {
          value: "2",
          label: "生成中",
        },
        {
          value: "3",
          label: "已删除",
        },
        {
          value: "4",
          label: "待完善",
        },
        {
          value: "5",
          label: "生成出错",
        },
      ],
      versionList: [],
    };
  },
  methods: {
    closeToast() {
      this.reminderToast = false;
    },
    checkReason(scope) {
      let params = {
        uuid: scope.row.uuid,
        nsrsbh: scope.row.nsrsbh,
      };
      getCheckReason(params).then((res) => {
        this.reminderToast = true;
        if (res.code == 200) {
          this.option.content = res.data;
        } else {
          this.option.content = "未知错误，请联系客服。";
        }
      });
    },
    getFilterList() {
      getBoGoodsEdition().then((res) => {
        this.versionList = res.data;
      });
    },
    handleSizeChange(val) {
      this.pagesize = val;
      this.search();
    },
    handleCurrentChange(val) {
      this.pageno = val - 1;
      this.search();
    },
    submit(data) {
      this.params = {
        hy: data.morehy,
        kjzd: data.zhidu,
      };
      retryMethods(this.params).then((res) => {
        if (res.msgCode == 1) {
          this.$message({
            type: "success",
            message: "重试提交成功，请耐心等待报告结果！",
          });
          this.checkParts = false;
          this.pageno = 0;
          this.search();
        } else {
          this.$message({
            type: "error",
            message: "重试提交失败，请联系客服！",
          });
        }
      });
    },
    cancel() {
      this.checkParts = false;
    },
    retry() {
      this.checkParts = true;
    },
    deletes(uuid, nsrsbh) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            uuid,
            nsrsbh,
          };
          deleteRule(params).then((res) => {
            if (res.msgCode == 1) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.pageno = 0;
              this.search();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async searchItem(params) {
      const data = await getHistoryReports(
        params.nsrsbm,
        params.name,
        params.firstTime,
        params.lastTime,
        params.pageno,
        params.pagesize,
        params.tag,
        params.status,
        params.goodsId
      );
      if (data.msgCode == 1) {
        this.tableData = data.data.content;
        this.totalElements = data.data.totalElements;
        this.tableData.forEach((item) => {
          item.exeTime = this.formatDateTes(item.exeTime);
        });
      }
    },
    changeVer() {
      this.pageno = 0;
      this.search();
    },
    changeStatus() {
      this.pageno = 0;
      this.search();
    },
    changeTime() {
      this.pageno = 0;
      if (this.filter.time) {
        [this.firstTime, this.lastTime] = [this.filter.time[0], this.filter.time[1]];
        this.search();
      } else {
        this.firstTime = "2018-11-13";
        this.lastTime = "2099-12-30";
        this.search();
      }
    },
    search() {
      let params = {
        nsrsbm: this.companyInfo.nsrsbm,
        name: this.filter.name,
        firstTime: this.firstTime,
        lastTime: this.lastTime,
        pageno: this.pageno,
        pagesize: this.pagesize,
        tag: this.filter.source,
        status: this.filter.status,
        goodsId: this.goodsId,
      };
      this.searchItem(params);
    },
    // pdf下载
    dowmpdf(uuid, zq, bankId) {
      let loading = this.$loading({
        lock: true,
        text: "拼命加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let token = JSON.parse(localStorage.getItem("token")).token;
      const para = {
        uuid: uuid,
        bankId: bankId,
        nsrsbh: this.companyInfo.nsrsbm,
        token,
        zq: zq,
      };
      downPDFurl(para)
        .then((res) => {
          if (res.msgCode == 1) {
            this.pdfurl = res.data;

            var a = document.createElement("a");
            a.href = this.pdfurl;
            a.download = "";
            // a.click()
            a.dispatchEvent(
              new MouseEvent("click", {
                bubbles: true,
                cancelable: true,
                view: window,
              })
            );
          } else {
            this.$alert(res.msgInfo, "提示", {
              type: "error",
            });
          }
        })
        .finally(() => {
          loading.close();
        });
    },
    formatDateTes(timestamp) {
      var date = new Date(timestamp);
      var Y = date.getFullYear() + "-";
      var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
      var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
      var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + " " + h + m + s;
    },
  },
  destroyed() {
    this.locks = false;
    sessionStorage.removeItem("timestamp");
    sessionStorage.removeItem("taxpayerIdProcess");
    clearTimeout(this.n);
  },
};
</script>

<style lang="stylus" scoped>
.pagination {
  padding-top: 20px;
  text-align: right;
  margin-top: 20px;
}

.content-body {
  margin: 20px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  // min-height: 80vh;
  padding: 0 10px;
}

.btn-view {
  line-height: 28px;
  height: 28px;
  color: #2f54eb;
  margin-right: 10px;
  display: inline-block;
}

.btn-view:hover {
  color: rgb(8, 47, 202);
}

.filter-body {
  margin: 20px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .inputs-wrapper {
    .inputs-wraper-row {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      .form-item {
        margin-right: 40px;
        margin-bottom: 16px;
        display: flex;
        align-items: center;

        .label {
          white-space: nowrap;
          width: 80px;
          text-align: right;
        }

        // flex-basis: 30%;
        display: flex;
      }
    }
  }

  .btn-look-up {
    margin-bottom: 16px;
    width: 70px;
    margin-right: 5px;
  }
}

.btn-blue {
  color: rgba(47, 84, 235, 1);
  cursor: pointer;
}

.done, .pedding, .delete, .perfect {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 7px;
  margin-bottom: 2px;
}

.done {
  background: rgba(82, 196, 26, 1);
}

.pedding {
  background: rgba(217, 217, 217, 1);
}

.delete {
  background: rgba(245, 34, 45, 1);
}

.perfect {
  background: rgba(250, 173, 20, 1);
}

.btn-yellow {
  color: yellow;
}

.paginations {
  float: right;
  margin-right: 20px;
  margin-bottom: 30px;
}

.msg-title {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 0px 0px rgba(238, 238, 238, 1);
}

>>> .bread-crumb-wrapper {
  margin-top: 0;
}

>>> .filter-body .el-select {
  width: 240px;
}

>>> .filter-body .el-input {
  width: 240px;
}

>>> .el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
  width: 240px;
}

>>> .el-range-editor.el-input__inner {
  padding-right: 6px;
}

>>> .el-date-editor .el-range-separator {
  padding: 0 3px;
}

>>> .el-date-editor .el-range-input {
  width: 44%;
}

.process-statuss {
  position: fixed;
  top: 20vh;
  left: 50%;
  margin-left: -400px;
}

.process-statusC {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 2001;
}
</style>
