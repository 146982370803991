import axios,{get,post} from "@/axios";

const base = process.env.VUE_APP_API_ROOT;
const basePlat = `${base}/plat`

//获取项目经理PDF编辑器数据
export const getEditPdfCatalogData = async ({ nsrsbh, uuid,isSimple=0 }) => {
  const url = `${base}/open/pdfData/getPdfCatalogDataRecord`;
  const res = await get(url, { nsrsbh, uuid,isSimple });
  return res.data;
};

export const getSencorPdfCatalogData = ({uuid, desensitizationStatus}) => {
  return axios
    .get(`${base}/open/pdfData/getPdfCatalogData?programId=${uuid}&desensitizationStatus=${desensitizationStatus}`)
    .then(res => res.data);
};

//获取审核老师PDF编辑器数据
// export const getSencorPdfCatalogData = async ({ nsrsbh, programId,isSimple=0,desensitizationStatus }) => {
//   const url = `${base}/open/pdfData/getPdfCatalogData`;
//   const res = await get(url, { nsrsbh, programId,isSimple,desensitizationStatus });
//   return res.data;
// };


//获取重置数据
export const getRevokePdfCatalogData = async ({ pdfId }) => {
  const url = `${basePlat}/pdfData/revokePdfCatalogData?PdfId=${pdfId}`;
  const res = await get(url);
  return res.data;
};

//保存
export const updatePdfCatalogData = async (params, action) => {
  const url = `${basePlat}/pdfData/updatePdfCatalogData?action=${action}`;
  const res = await post(url, params);
  return res.data;
};

//生成
export const createPdf = async ({ nsrsbh, uuid,isSimple=0 }) => {
  const url = `${basePlat}/pdfData/createPdf`;
  const res = await get(url, { nsrsbh, uuid,isSimple  });
  return res.data;
};

//删除
export const delectPdfCatalogData = async ({ pdfCatalogDataId, nsrsbh, uuid,isSimple=0 }) => {
  const url = `${basePlat}/pdfData/delectPdfCatalogData`;
  const res = await get(url, {
      nsrsbh,
      uuid,
      pdfCatalogDataId,
      isSimple

  });
  return res.data;
};

//新增节点
export const addNode = async params => {
  const url = `${basePlat}/pdfData/addPdfCatalogData`;
  const res = await post(url, params);
  return res.data;
};

//修改PDF状态
export const updateReviewStatus = async ({ nsrsbh, uuid, status, action,isSimple=0 }) => {
  const url = `${basePlat}/pdfData/updateReviewStatus?action=${action}&nsrsbh=${nsrsbh}&uuid=${uuid}&reviewStatus=${status}&isSimple=${isSimple}`;
  const res = await get(url);
  return res.data;
};


//无需审核修改PDF状态
export const updateUnReviewStatus = async ({ nsrsbh, uuid, status, action,isSimple=0 }) => {
  const url = `${basePlat}/pdfData/updateUnReviewStatus?action=${action}&nsrsbh=${nsrsbh}&uuid=${uuid}&reviewStatus=${status}&isSimple=${isSimple}`;
  const res = await get(url);
  return res.data;
};


//修改PDF状态
export const getCheckLogList = async ({ nsrsbh, uuid }) => {
  const url = `${basePlat}/pdfData/getCheckLogList?nsrsbh=${nsrsbh}&uuid=${uuid}`;
  const res = await get(url);
  return res.data;
};


