<template>
  <div>
    <div class="wrapper">
      <div class="textarea-wrapper">
        <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
          <el-tooltip
            :popper-class="popperClass"
            :manual="true"
            :value="isInputModified || isInputModifiedByMyself"
            content="此内容已修改"
            placement="top-end"
          >
            <div
              :class="{
                isModified: isInputModified,
                isModifiedByMyself: isInputModifiedByMyself,
              }"
              class="textarea textarea-text"
            >
              {{ dataText }}
            </div>
          </el-tooltip>
        </div>
        <div class="editable-wrapper" v-else>
          <div class="label">结果:</div>
            <el-input
              type="textarea"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              :autosize="{ minRows: 3, maxRows: 6 }"
              class="textarea"
              :disabled="!isEditable"
            ></el-input>
        </div>
      </div>
      <div class="chart-wrapper" v-if="showChart">
        <div class="chart">
          <div class="chartColumn" id="chartColumn" ref="chart"></div>
        </div>
        <div class="chart" v-if="yeb">
          <GenericTable :data="yeb"></GenericTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GenericTable from "../InvoiceIndex/components/GenericTable";
import { mapGetters, mapMutations } from "vuex";
import { cloneDeep } from "lodash";
import mixin from "@/pages/ResultEditor/mixin.js";

import echarts from "echarts";
export default {
  mixins: [mixin],
  components: {
    GenericTable,
  },
  props: {
    indexes: Array,
    isReadOnly: Boolean,

    isForceShowDetail: Boolean,
  },
  data: function () {
    return {
      title: "",
      desc: [],
      x: [],
      rects: [],
      lines: new Array(10),
      showChart: true,
      warningId: null,
      yeb: null,
    };
  },
  computed: {
    ...mapGetters("ResultEditor", ["findTheNodeByIndexes"]),
    //行业参考值的名字
    industryReferenceValueLabel(){
      return this.pdfData.header[2];
    },
    //主要的指标名字
    mainBarLabel(){
      return this.pdfData.header[1];
    },
    data() {
      const ret = this.findTheNodeByIndexes(this.indexes);

      return ret;
    },
    dataText: {
      get() {
        return this.data.dataText;
      },
      set(value) {
        const nodeData = cloneDeep(this.data);
        nodeData.dataText = value; //给节点设置新的dataText
        this.setTreeDataByIndexes({
          data: nodeData,
          indexes: this.indexes,
        });

        // this.findTheNodeByIndexes({
        //   dataText: value
        // });
        // this.$parent.setTreeNodeAttribute({
        //   dataText: value
        // });
      },
    },
    popperClass() {
      let ret = "";
      if (this.isInputModifiedByMyself) {
        ret = "modified-tooltip-red";
      } else if (this.isInputModified) {
        ret = "modified-tooltip-yellow";
      }
      return ret;
    },
    pdfData: function () {
      return JSON.parse(this.data.pdfData);
    },
    isInputModified() {
      let ret = false;

      if (this.$route.params.action == "edit") {
        ret = this.data.lastCensorSnapshot
          ? this.data.lastCensorSnapshot.includes("TextChange")
          : false;
      } else if (this.$route.params.action == "censor") {
        ret = this.data.lastEditSnapshot
          ? this.data.lastEditSnapshot.includes("TextChange")
          : false;
      }
      return ret;
    },
    isInputModifiedByMyself() {
      let ret = false;

      if (this.$route.params.action == "edit") {
        ret = this.data.editSnapshot
          ? this.data.editSnapshot.includes("TextChange")
          : false;
      } else if (this.$route.params.action == "censor") {
        ret = this.data.censorSnapshot
          ? this.data.censorSnapshot.includes("TextChange")
          : false;
      }
      return ret;
    },
  },
  mounted() {
    this.getData(this.data.titleName);
    //debugger
    //如果是用户添加的内容 则不显示图表
    if (this.data.userData) {
      this.showChart = false;
    } else {
      //如果没有强制显示图表 则不显示
      if (!this.isForceShowDetail) {
        this.showChart = false;
      }
    }

    if (this.showChart) {
      this.showMap();
    }
  },
  methods: {
    ...mapMutations("ResultEditor", ["setTreeDataByIndexes"]),
    getData(name) {
      this.title = name;
      const _this = this;
      //     {
      //   MLL_AVG: this.pdfData.MLL_AVG,
      //   MLL: this.pdfData.MLL,
      //   MLL_SQJE: this.pdfData.MLL_SQJE,
      //   ZZSSFL_AVG: this.pdfData.ZZSSFL_AVG,
      //   ZZSSFL: this.pdfData.ZZSSFL,
      //   ZZSSFL_SQJE: this.pdfData.ZZSSFL_SQJE,
      //   SDSFL_AVG: this.pdfData.SDSFL_AVG,
      //   SDSFL: this.pdfData.SDSFL,
      //   SDSFL_SQJE: this.pdfData.SDSFL_SQJE,
      //   YYSJJFJL_AVG: this.pdfData.YYSJJFJL_AVG,
      //   YYSJJFJL: this.pdfData.YYSJJFJL,
      //   YYSJJFJL_SQJE: this.pdfData.YYSJJFJL_SQJE
      // }

      this.x = this.pdfData.body.map(v=>v[""]);

      this.rects =this.pdfData.body.map(v=>v[this.mainBarLabel])
      this.lines[0] = this.pdfData.body[0][this.industryReferenceValueLabel];
      this.lines.fill(this.pdfData.body[1][this.industryReferenceValueLabel], 1);


      this.showChart = true;
    },
    //echart
    showMap() {
      this.chartColumn = echarts.init(this.$refs["chart"]);
      const option = {
        color: ["#3398DB"],
        tooltip: {
          formatter: (params) => {
            const text = params[0].seriesName.includes("行业")
              ? ""
              : `${params[0].seriesName}:${params[0].value}%`;

            return text;
          },
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        legend: {
          x: "center",
          bottom: "5%",
          data: [this.mainBarLabel, this.industryReferenceValueLabel],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "15%",
          top: "15%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [
              this.x[0],
              this.x[1],
              " ",
              " ",
              " ",
              " ",
              " ",
              " ",
              " ",
              " ",
            ],
            splitLine: {
              show: true,
              lineStyle: {
                color: "#EEEEEE",
                width: 1,
                type: "solid",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#666",
              },
            },
            axisLabel: {
              // rotate: 35,
              interval: 0,
              textStyle: {
                fontSize: 11,
                fontFamily: "PingFangSC-Regular,PingFang SC",
                color: "rgba(0,0,0,0.6)",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            max: () => {
              let max = Math.max(...this.lines, ...this.rects);
              if (max > 0) {
                max = Math.ceil((max * 100) / 100);
              } else {
                max = Math.floor((max * 100) / 100);
              }

              return max < 0 ? 0 : max;
            },
            min: () => {
              let min = Math.min(...this.lines, ...this.rects);

              if (min > 0) {
                min = Math.ceil((min * 100) / 100);
              } else {
                min = Math.floor((min * 100) / 100);
              }

              min = Math.ceil((min * 100) / 100);
              return min > 0 ? 0 : min;
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#666",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#EEEEEE",
                width: 1,
                type: "dashed",
              },
            },
            axisLabel: { show: true, formatter: "{value} %" },
          },
          {
            type: "value",
            name: "",
            max: () => {
              let max = Math.max(...this.lines, ...this.rects);
              if (max > 0) {
                max = Math.ceil((max * 100) / 100);
              } else {
                max = Math.floor((max * 100) / 100);
              }
              return max < 0 ? 0 : max;
            },
            min: () => {
              let min = Math.min(...this.lines, ...this.rects);
              if (min > 0) {
                min = Math.ceil((min * 100) / 100);
              } else {
                min = Math.floor((min * 100) / 100);
              }
              return min > 0 ? 0 : min;
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#666",
              },
            },
            axisTick: {
              // 坐标轴 刻度
              show: false, // 是否显示
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "#EEEEEE",
                width: 1,
                type: "dashed",
              },
            },
            axisLabel: { show: false },
          },
        ],
        series: [
          {
            name: this.mainBarLabel,
            type: "bar",

            center: ["50%", "1%"],
            color: ["#597EF7"],
            barWidth: "30",
            data: this.rects,
          },
          {
            name: this.industryReferenceValueLabel,
            type: "line",
            yAxisIndex: 1,
            color: ["#D6B35B"],
            data: this.lines,
          },
        ],
      };

      this.chartColumn.setOption(option);
    },
  },
};
</script>



<style lang="stylus" scoped>
.wrapper {
  width: 100%;
  box-sizing: border-box;
  margin: 10px 0 0;
  padding-bottom: 0px;

  .textarea-wrapper {
    margin: 0;

    .editable-wrapper {
      padding-left: 0px;
      display: flex;
      align-items: flex-start;
      width: 100%;
      color: rgba(0, 0, 0, 0.6);

      .label {
        font-weight: bold;
        color: rgba(0, 0, 0, 0.6);
        font-size: 16px;
        margin-right: 5px;
        margin-left: 16px;
        margin-top: 20px;
      }

      .textarea {
        >>> textarea {
          box-sizing: border-box;
          font-size: 14px;
          line-height: 30px;
        }

        >>> .el-textarea__inner {
          border-radius: 16px;
        }

        width: auto;
        display: block;
        margin: 16px;
        margin-left: 15px;
        border-radius: 16px;
        box-sizing: border-box;
        line-height: 1.5;
        color: rgba(0, 0, 0, 0.8);
        font-size: 14px;
        flex-grow: 1;
      }
    }

    .isModified.textarea-text {
      padding: 5px;
      box-sizing: border-box;
      border: 1px solid #faad14 !important;
      border-color: #faad14 !important;
    }

    .isModifiedByMyself.textarea-text {
      padding: 5px;
      box-sizing: border-box;
      border: 1px solid rgba(245, 34, 45, 1) !important;
      border-color: rgba(245, 34, 45, 1) !important;
    }

    .isModified.textarea {
      >>> .el-textarea__inner {
        padding: 5px;
        box-sizing: border-box;
        border: 1px solid #faad14 !important;
        border-color: #faad14 !important;
      }

      border-radius: 4px;
    }

    .isModifiedByMyself.textarea {
      >>> .el-textarea__inner {
        padding: 5px;
        box-sizing: border-box;
        border: 1px solid rgba(245, 34, 45, 1) !important;
        border-color: rgba(245, 34, 45, 1) !important;
      }

      border-radius: 4px;
    }
  }
}

.TaxIndex {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 8px 8px 0px 0px;
  margin-bottom: 16px;
}

.title {
  border-bottom: 1px solid #eee;
  padding: 16px 24px;
  font-weight: 600;
  background: #fff;
  border-radius: 8px 8px 0px 0px;
  font-size: 18px;
  color: #333;
  line-height: 25px;
  display: flex;
}

.body-wrapper {
  display: flex;
  background: rgba(47, 84, 235, 0.04);

  .desc {
    padding: 16px 24px;
    width: 380px;
    box-sizing: border-box;
    flex-grow: 0;
    font-size: 14px;
    color: #666;

    p {
      font-size: 14px;
      color: #666;
      line-height: 20px;
    }
  }

  .chart-wrapper {
    background: #fff;
    flex-grow: 1;

    .chart {
      width: 764px;
      height: 261px;
      background: #fff;
    }
  }
}

.chart-wrapper {
  background: #fff;

  .chart {
    width: 764px;

    .chartColumn {
      height: 261px;
      width: 100%;

      >>>div:first-child {
        width: 100% !important;
      }
    }
  }
}
</style>

<style lang="stylus">
.modified-tooltip-yellow {
  border: 1px solid #faad14;
  background: #faad14 !important;
  width: 140px;
  font-size: 15px;
  height: 30px;
  padding: 0px;
  text-align: center;
  line-height: 25px;
  filter: drop-shadow(0 0 2px rgba(250, 173, 20, 0.5));
}

.modified-tooltip-yellow[x-placement^=top] .popper__arrow {
  border-top-color: #faad14;
  filter: drop-shadow(0 0 2px rgba(250, 173, 20, 0.5));
}

.modified-tooltip-yellow[x-placement^=top] .popper__arrow::after {
  border-top-color: #faad14;
  filter: drop-shadow(0 0 2px rgba(250, 173, 20, 0.5));
}

.el-tooltip__popper[x-placement^=bottom] .popper__arrow {
  border-bottom-color: #faad14;
}

.el-tooltip__popper[x-placement^=bottom] .popper__arrow::after {
  border-bottom-color: #faad14;
}

.modified-tooltip-red {
  border: 1px solid rgba(245, 34, 45, 1);
  background: rgba(245, 34, 45, 1) !important;
  color: #fff;
  width: 140px;
  font-size: 15px;
  height: 30px;
  padding: 0px;
  text-align: center;
  line-height: 25px;
  filter: drop-shadow(0 0 2px rgba(245, 34, 45, 1));
}

.modified-tooltip-red[x-placement^=top] .popper__arrow {
  border-top-color: rgba(245, 34, 45, 1);
  filter: drop-shadow(0 0 2px rgba(245, 34, 45, 1));
}

.modified-tooltip-red[x-placement^=top] .popper__arrow::after {
  border-top-color: rgba(245, 34, 45, 1);
  filter: drop-shadow(0 0 2px rgba(245, 34, 45, 1));
}

.el-tooltip__popper[x-placement^=bottom] .popper__arrow {
  border-bottom-color: #faad14;
}

.el-tooltip__popper[x-placement^=bottom] .popper__arrow::after {
  border-bottom-color: #faad14;
}
</style>
