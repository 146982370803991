<template>
  <div class="btns">
    <button
      class="no-style switcher switcher-left"
      :class="{ actived: this.value == 'table' }"
      @click="handleItemClick('table')"
    >
      表格
    </button>
    <button
      class="no-style switcher switcher-right"
      :class="{ actived: this.value == 'chart' }"
      @click="handleItemClick('chart')"
    >
      图形
    </button>
  </div>
</template>

<script>
import "@/pages/Result/ThreeYearResult/global.styl";

export default {
  name: "TinySwitcher",
  props: {
    value: String,
  },
  methods: {
    handleItemClick(key) {
      this.$emit("input", key);
    },
  },
};
</script>

<style lang="stylus" scoped>
.btns
  .switcher
    cursor pointer
    color #E7E6EB
    font-size 0.24rem
    width 1.2rem
    height 0.6rem
    border: 1px solid #E5E6EB;

  .switcher.actived
    color #1762FF
    border: 1px solid #1762FF;

  .switcher-left
    border-radius: 4px 0px 0px 4px;

  .switcher-right
    border-radius: 0px 4px 4px 0px;
</style>
