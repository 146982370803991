<template>
  <div class="page-wrapper">
    <div class="msg-title">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>风险检测</el-breadcrumb-item>
          <el-breadcrumb-item>开始检测</el-breadcrumb-item>
          <el-breadcrumb-item>数据读取</el-breadcrumb-item>
          <el-breadcrumb-item>报告生成</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>

      <main-header>
        <template slot="title">报告生成</template>
      </main-header>
    </div>
    <!-- <ProgressDiagram class="progressdis" :userInfo="userInfo" :progress="5" :version="version"></ProgressDiagram> -->

    <div class="invoice-content">
      <div v-if="!customization">
        <img src="https://shuian-file.oss-cn-hangzhou.aliyuncs.com/image/qrcode_for_gh_cc017f82c39a_258.jpg" alt="" class="invoice-img"  />
        <!--      <img class="invoice-img" src="@/assets/result/no-invoice.png" alt />-->
        <div class="invoice-text">检测报告预计{{estimatedTime}}内生成，请耐心等待</div>
        <div class="invoice-upload">关注微信公众号，帮您快速了解报告生成状态，报告生成后将会同步微信公众号内</div>
      </div>
      <div v-else>
        <div class="invoice-text">检测报告生成需T+1个工作日完成，请耐心等待</div>
      </div>

      <div class="toTestLog ease" @click="toTest">前往检测记录</div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import { mapState } from "vuex";
import { zq2name } from "@/utils/helper";
import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "@/pages/components/MainHeader";
import ProgressDiagram from "@/pages/components/ProgressDiagram";
// import InvoiceExtractGuide from "./components/InvoiceExtractGuide";
import Footer from "../ChooseTable/components/Footer";
import TestDataUploader from "../Components/TestDataUploader";

import { localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_CN", zh_CN);

//在加载的样式
import "vue-loading-overlay/dist/vue-loading.css";
import {getLocalAbilities} from "@/utils/abilities";

export default {
  components: {
    Loading,
    BreadCrumb,
    MainHeader,
    ProgressDiagram,
    // InvoiceExtractGuide,
    saFooter: Footer,
    TestDataUploader,
  },
  props: {
    bankId: String,
    productID: [String, Number],
    productName: String,
    zqLocation: [String, Number],
    uuid: String,
    zq: String,
    version: [Number, String],
  },
  data: function () {
    return {
      isLoading: false,
      fullPage: true,
      companyName: "",
      email: "",
      taxPayerID: "",
      mobile: "",
      userInfo: sessionStorage.getItem("method") || 1,
    };
  },
  computed: {
    ...mapState(["companyInfo", "authorization"]),
    ...mapState("UploadTable", ["permissionID"]),
    ...mapState(["customization"]),
    zqName: function () {
      return zq2name(this.zq);
    },
    estimatedTime(){
      if(getLocalAbilities(this.$route.params.version).includes("报税表检测流程")){
        return "5分钟"
      }else {

      }return "2小时"
    }
  },
  mounted() {
    sessionStorage.removeItem("isShowBase");
    history.pushState(null, null, document.URL);
    window.addEventListener("popstate", function () {
      history.pushState(null, null, document.URL);
    });
    this.isLoading = true;

    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  },
  methods: {
    handleBtnNext2() {
      this.$refs["validationObserver"].validate().then((success) => {
        if (!success) {
          return;
        } else {
          this.$refs["testDataUploader"].handleBtnNext2Click();
        }
      });
    },

    toTest() {
      this.$router.push({ name: "test-log" });
    },
    // genTestReport({
    //       bankId: this.bankId,
    //       goodsId: this.productID,
    //       kjzd: this.companyInfo.kjzd,
    //       token: this.authorization,
    //       nsrsbh: this.companyInfo.nsrsbm,
    //       tag: this.version,
    //       id: this.$store.state.UploadTable.permissionID,
    //       uuid: this.uuid,
    //       zq,
    //       invoice_nsrsbh: this.inVoiceTaxPayerID,
    //       invoice_email: this.inVoiceEmail,
    //       invoice_company_name: this.inVoiceCompanyName,
    //       invoice_phone: this.inVoiceMobile
    //     });
  },
};
</script>

<style lang="stylus" scoped>
.page-wrapper {
  padding-bottom: 100px;
}

.form-wrapper {
  display: flex;
  margin-top: 24px;

  .left-wrapper {
    width: 50%;
  }

  .item {
    color: #666;
    font-size: 14px;
    margin-top: 16px;

    .required-icon {
      color: #F5222D;
      margin-right: 4px;
    }

    .label {
      width: 150px;
      display: inline-block;
      text-align: right;
      margin-right: 8px;
      vertical-align: top;
      line-height: 36px;
    }

    .input {
      border: 1px solid #D9D9D9;
      width: 400px;
      height: 36px;
      line-height: 36px;
      padding: 8px 16px;
      box-sizing: border-box;
      border-radius: 3px;
      outline: none;
    }

    .input-wrapper {
      display: inline-block;
    }

    .big {
      font-size: 24px;
      margin: 16px auto;
      color: #333;
    }
  }

  .info {
    margin-left: 50px;
    margin-top: 25px;
  }
}

.progressdis {
  width: 90%;
  margin: 24px auto;
}

.invoice-content {

  padding-top 180px
  padding-bottom 150px
  text-align: center;
  height: 606px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 0 30px;
  margin-top: 16px;
}
.invoice-content div{
  text-align center
}
.invoice-img {
}

.invoice-text {
  margin-top: 24px;
  font-size: 18px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.invoice-upload {
  margin-top: 12px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.msg-title {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 0px 0px rgba(238, 238, 238, 1);
}

>>>.bread-crumb-wrapper {
  margin-top: 0;
}

.toTestLog {
  width: 170px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(47, 84, 235, 1);
  border-radius: 4px;
  margin: 0 auto;
  margin-top: 24px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
}

.toTestLog:hover {
  background: rgba(47, 54, 235, 1);
}

.ease {
  transition: all 0.3s;
}
</style>
