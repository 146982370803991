<template>
  <div>
    <div class="whiteStyle"></div>

    <div class="h5-wrapper">
      <van-tabs v-model="active" @change="tabschange" swipeable>
        <van-tab title="进项发票" name="entry">
          <div class="content-section">
            <div class="title">发票类型</div>
            <stateType ref="typebutton1_1" @statTypechange="statTypechange($event)"></stateType>
            <RingBarTabs :data="typeData" v-if="typeData" :active="active"></RingBarTabs>
          </div>
          <div class="content-section">
            <div class="title">发票状态</div>
            <stateType ref="typebutton2_1" @statTypechange="statTypechange2($event)"></stateType>
            <HistogramTabs :data="stateData" v-if="stateData"></HistogramTabs>
          </div>
          <div class="content-section">
            <div class="title">特殊发票</div>
            <stateType ref="typebutton3_1" @statTypechange="statTypechange3($event)"></stateType>
            <HistogramTabs :data="specialData" v-if="specialData"></HistogramTabs>
            <router-link
              :to="{ name: 'SpecialInvoiceDetail', params: { txType: active } }"
              class="btn btn-special-invoice"
              >特殊发票明细</router-link
            >
          </div>
          <div class="content-section mb40">
            <div class="title">可能存在风险的发票</div>
            <stateType ref="typebutton4_1" @statTypechange="statTypechange4($event)"></stateType>

            <HistogramTabs :data="riskData" v-if="riskData"></HistogramTabs>
          </div>
        </van-tab>
        <van-tab title="销项发票" name="sale">
          <div class="content-section">
            <div class="title">发票类型</div>
            <stateType ref="typebutton1_2" @statTypechange="statTypechange($event)"></stateType>
            <RingBarTabs :txType="active" :data="typeData" v-if="typeData"></RingBarTabs>
          </div>
          <div class="content-section">
            <div class="title">发票状态</div>
            <stateType ref="typebutton2_2" @statTypechange="statTypechange2($event)"></stateType>
            <HistogramTabs :data="stateData" v-if="stateData"></HistogramTabs>
          </div>
          <div class="content-section">
            <div class="title">特殊发票</div>
            <stateType ref="typebutton3_2" @statTypechange="statTypechange3($event)"></stateType>
            <HistogramTabs :data="specialData" v-if="specialData"></HistogramTabs>
            <router-link
              :to="{ name: 'SpecialInvoiceDetail', params: { txType: active } }"
              class="btn btn-special-invoice"
              >特殊发票明细</router-link
            >
          </div>
          <div class="content-section mb40">
            <div class="title">可能存在风险的发票</div>
            <stateType ref="typebutton4_2" @statTypechange="statTypechange4($event)"></stateType>

            <HistogramTabs :data="riskData" v-if="riskData"></HistogramTabs>
          </div>
        </van-tab>
      </van-tabs>
      <!-- <div class="btnOrder">
        <BtnOrder></BtnOrder>
      </div> -->
    </div>
  </div>
</template>

<script>
import "../bossWatchReport.styl";
import BtnOrder from "../component/BtnOrder.vue";
import RingBarTabs from "./components/RingBarTabs.vue";
import HistogramTabs from "./components/HistogramTabs.vue";

import stateType from "./components/statTypeButton.vue";
import {
  getInvoiceTypeCard,
  getInvoiceStatusCard,
  getSpecialInvoiceCard,
  getRiskInvoiceCard,
} from "@/api/bossWatchReport";

export default {
  name: "OneKeyWatchInvoice",
  components: { BtnOrder, RingBarTabs, stateType, HistogramTabs },
  data() {
    return {
      active: "entry",
      typeData: null,
      stateData: null,
      specialData: null,
      riskData: null,
    };
  },

  async mounted() {
    this.$nextTick(() => {
      this.getTypeData();
      this.getStateData();
      this.getRiskData();
      this.getSpecialData();
    });
  },

  methods: {
    //获取风险发票
    async statTypechange4() {
      await this.getRiskData();
    },
    async getRiskData() {
      this.riskData = null;
      const params = {
        uuid: this.$route.params.uuid,
        txType: this.active,
        statType:
          this.active === "entry"
            ? this.$refs.typebutton4_1.statType
            : this.$refs.typebutton4_2.statType,
      };
      let res = await getRiskInvoiceCard(params);
      if (res.data) {
        this.riskData = res.data;
      }
    },
    //获取特殊发票
    async statTypechange3() {
      await this.getSpecialData();
    },
    async getSpecialData() {
      this.specialData = null;

      const params = {
        uuid: this.$route.params.uuid,
        txType: this.active,
        statType:
          this.active === "entry"
            ? this.$refs.typebutton3_1.statType
            : this.$refs.typebutton3_2.statType,
      };
      let res = await getSpecialInvoiceCard(params);
      if (res.data) {
        this.specialData = res.data;
      }
    },

    //获取状态发票
    async statTypechange2() {
      await this.getStateData();
    },
    async getStateData() {
      this.stateData = null;

      const params = {
        uuid: this.$route.params.uuid,
        txType: this.active,
        statType:
          this.active === "entry"
            ? this.$refs.typebutton2_1.statType
            : this.$refs.typebutton2_2.statType,
      };
      let res = await getInvoiceStatusCard(params);
      if (res.data) {
        this.stateData = res.data;
      }
    },
    tabschange(e) {
      console.log(e);
      this.$nextTick(() => {
        this.getTypeData();
        this.getStateData();
        this.getRiskData();
        this.getSpecialData();
      });
    },
    //获取类型发票
    async statTypechange() {
      await this.getTypeData();
    },
    async getTypeData() {
      const params = {
        uuid: this.$route.params.uuid,
        txType: this.active,
        statType:
          this.active === "entry"
            ? this.$refs.typebutton1_1.statType
            : this.$refs.typebutton1_2.statType,
      };
      let res = await getInvoiceTypeCard(params);
      if (res.data) {
        this.typeData = res.data;
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.content-section.mb40{
  margin-bottom 40px
}
>>>.van-tabs__line{
  width 64px
}
>>>.van-tabs__nav--line .van-tab{
  font-weight bold;
  font-size: 20px
}
>>>.van-tabs__nav--line .van-tab--active{
  color #1677FF;

}
>>>.van-tab__pane .van-tabs__content>.van-tab__pane{
  padding:0px;
  height: auto

  overflow:visible;

}
>>>.van-tab__pane{
  height: 95vh;
  padding:20px;
  padding-bottom:0px;
  padding-top:0px;
  //overflow-y: auto
}

>>>.van-tabs__line{
  background-color:#1677FF
}
.h5-wrapper {
  padding 00px

  .btn-special-invoice {
    width: 100%;
    box-sizing border-box
    padding 0
    height: 35px;
    background: #E5F0FF;
    border-radius: 2px;
    display flex
    justify-content center
    align-items center
    font-size: 14px;
    font-weight: 600;
    color: #1677FF;
    line-height: 18px;
    margin: 0px;
    margin-bottom 20px
  }
}

.content-section {
  padding: 0px
  background: #FFFFFF;
  margin-bottom: 20px;
  border-bottom 1px dashed #7585A2;


  .title {
    display flex
    align-items center
    font-size: 20px;
    font-weight: bold;
    color: #1D2129;
    line-height: 24px;
    margin:12px 0px
  }

  .title::before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 14px;
    background: #1D2129;
    border-radius: 2px;
    margin-right: 5px;
  }
}
@media screen and (min-width:1300px) and (max-width: 2000px){
  .whiteStyle{
   height 40px;
  }
  .h5-wrapper {
    padding 00px
    width: 375px;
    margin:0px auto;
    height:800px;
    overflow: auto;
    padding-bottom 0px
    box-shadow: 3px 2px 11px 2px #dedede;
    .btn-special-invoice {
      width: 100%;
      box-sizing border-box
      padding 0
      height: 35px;
      background: #E5F0FF;
      border-radius: 2px;
      display flex
      justify-content center
      align-items center
      font-size: 14px;
      font-weight: 600;
      color: #1677FF;
      line-height: 18px;
      margin: 0px;
      margin-bottom 20px
    }
    >>>.btn-order{
      top: 820px;
    }
  }
}
@media screen and (min-width:600px) and (max-width: 1300px){
  .whiteStyle{
   height 10px;
  }
  .h5-wrapper {
    padding 00px
    width: 375px;
    margin:0px auto;
    height:600px;
    overflow: auto;
    padding-bottom 0px
    box-shadow: 3px 2px 11px 2px #dedede;
    .btn-special-invoice {
      width: 100%;
      box-sizing border-box
      padding 0
      height: 35px;
      background: #E5F0FF;
      border-radius: 2px;
      display flex
      justify-content center
      align-items center
      font-size: 14px;
      font-weight: 600;
      color: #1677FF;
      line-height: 18px;
      margin: 0px;
      margin-bottom 20px
    }
    >>>.btn-order{
      top: 596px;
    }
  }
}
@media screen and (min-width:300px) and (max-width: 500px){
  .btnOrder{
    position: fixed
    top 673px
    left: -9px;
  }
}
</style>
