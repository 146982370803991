<template>
  <div class="financialRisk-h5-container">
    <div class="financialRisk-h5" style="padding-bottom: 0" v-if="loaded">
      <div class="company-baseName">
        <div class="company-tip"></div>
        <div class="company-tip-base">财税风险</div>
      </div>
      <div>
        <div class="stats-wrapper">
          <div class="time">检测区间：{{ data.period }}</div>
          <div class="stats">
            <div class="stats-item">
              <div class="number">{{ data.highRisk }}</div>
              <div class="text">高风险</div>
            </div>
            <div class="stats-item stats-item-front">
              <div class="number">{{ data.middleRisk }}</div>
              <div class="text">中风险</div>
            </div>
            <div class="stats-item">
              <div class="number">{{ data.lowRisk }}</div>
              <div class="text">低风险</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="table-wrapper">
          <BigPowerTable :data="dataTable" v-if="dataTable" class="table"></BigPowerTable>
        </div>
      </div>
    </div>
    <div style="padding-bottom: 0" v-else>
      <BigPowerLoading height="790px"></BigPowerLoading>
    </div>
  </div>
</template>

<script>
import "@/pages/Result/ThreeYearResult/global.styl";
import BigPowerSwitcher from "@/pages/Result/ThreeYearResult/components/BigPowerSwitcher";
import BigPowerLegends from "@/pages/Result/ThreeYearResult/components/BigPowerLegends";
import { getAutoRoutePushTo, getReportRoute } from "@/utils/helper";
import Ciyun from "@/pages/Result/ThreeYearResult/financialAnalysis/Ciyun";
import BigPowerTable from "big-power-table";
import { getFinancialRisk } from "@/api/threeYears";

export default {
  name: "FinancialRisk",
  components: {
    BigPowerTable,
    BigPowerSwitcher,
    BigPowerLegends,
    Ciyun,
  },
  async mounted() {
    this.loaded = false;
    await this.loadFinancialRisk();
    this.loaded = true;
  },
  data() {
    return {
      showType: "chart",
      data: {},
      loaded: false,
    };
  },
  methods: {
    switchScreen() {},
    handleCiyunClick(data) {
      this.$eventBus.$emit("switchToDetailTab", { anchor: data.id });
      //this.$router.push(getReportRoute(this.uuid,  {anchor: data.id}));
      //const fullURL = new URL(props.href, window.location.href).href;
      //location.href=fullURL;
    },
    async loadFinancialRisk() {
      const res = await getFinancialRisk({
        programId: this.$route.params.uuid,
      });
      this.data = res.data.data;
    },
  },
  computed: {
    dataTable() {
      if (this.data.dataTable) {
        this.data.dataTable.body.forEach((row, index) => {
          if (row[2]) {
            if (row[2].content === "高") {
              row[2].color = "#F53F3F";
            } else if (row[2].content === "中") {
              row[2].color = "#FF7D00";
            } else if (row[2].content === "低") {
              row[2].color = "#00B42A";
            }
          }
        });
      }
      return this.data.dataTable;
    },
  },
};
</script>

<style lang="stylus" scoped>
.financialRisk-h5-container
  border: 2px solid #6AA1FF;
  border-style: dashed;
  margin: 0.4rem;
  padding: 0.24rem;
  border-radius: 4px;

.financialRisk-h5
  .company-baseName
    display: flex;
    align-items: center;
    color: #1D2129;
    font-size: 0.32rem;
    font-weight: 600;
    line-height: 0.33rem;
    margin-bottom: 0.16rem;

    .company-tip
      width: 0.12rem;
      height: 0.24rem;
      background: #2F54EB;
      margin-right: 0.1rem;

    .company-tip-base
      line-height: 0.33rem;

  .stats-wrapper
    .time
      font-size: 0.28rem;
      color: #86909C;
      line-height: 0.3rem;
      margin-bottom: 0.16rem;
      margin-left: 0.25rem;

    .stats
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border: 1px solid #C9CDD4;
      padding: 0.26rem 0;

      .stats-item
        flex: 1;

        .number
          font-size: 0.36rem;
          font-weight: 600;
          color: #4E5969;
          line-height: 0.3rem;
          text-align: center;

        .text
          font-size: 0.24rem;
          color: #4E5969;
          line-height: 0.3rem;
          margin-top: 0.02rem;
          text-align: center;

      .stats-item-front {
        border-right: 1px solid #E5E6EB;
        border-left: 1px solid #E5E6EB;
      }

      // .stats-item-front:after {
      //   position: absolute;
      //   content: "";
      //   right: 0.7rem;
      //   top: 0.24rem;
      //   width: 2px;
      //   height: 0.66rem;
      //   background-color: #E5E6EB;
      // }


  >>> table.table th, >>> table.table td {
    font-size: 0.2rem;
  }

  >>> .table-wrapper .fake {
    // height: 0.52rem !important;
  }


.ciyun-wrapper
  .toolbar
    display flex
    justify-content flex-end

.table-wrapper
  background: #FFFFFF;
  margin 0.16rem 0
  margin-bottom 0
  position: relative;

  .sc {
    position: absolute;
    right: 4px;
    bottom: 4px;
  }

  .table {
    >>> .bigPowerTable {
      max-height 9rem
      overflow auto
    }
  }
</style>
