import { render, staticRenderFns } from "./SpecialInvoiceDetail.vue?vue&type=template&id=44d5438a&scoped=true"
import script from "./SpecialInvoiceDetail.vue?vue&type=script&lang=js"
export * from "./SpecialInvoiceDetail.vue?vue&type=script&lang=js"
import style0 from "./SpecialInvoiceDetail.vue?vue&type=style&index=0&id=44d5438a&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44d5438a",
  null
  
)

export default component.exports