<template>
  <div>
    <div>
      <NetworkChart :data="data" ref="networkChart"></NetworkChart>
    </div>
  </div>
</template>

<script>
import NetworkChart from "@/pages/Result/H5FinancialAnalysis/networkChart/NetworkChart";
import { getCompanyRiskImage } from "@/api/threeYears";

export default {
  name: "NetWorkChartWindow",
  components: {
    NetworkChart,
  },
  data() {
    return {
      data: null,
      fullScreenDialogVisible: false,
      filter: {
        time: [],
        fromAmount: "",
        toAmount: "",
        limit: 15,
        showCompanyRisk: false,
      },
    };
  },
  mounted() {
    this.loadCompanyAreaList();
  },
  methods: {
    handleFullscreen() {
      this.fullScreenDialogVisible = true;
      setTimeout(() => {
        this.$refs["networkChart2"].render();
      }, 500);
    },
    changeTime() {
      this.loadCompanyAreaList();
    },
    async loadCompanyAreaList() {
      const params = {};
      if (this.filter.time && this.filter.time[0]) {
        params.startDate = this.filter.time[0];
        params.endDate = this.filter.time[1];
      }

      params.programId = this.$route.params.uuid;
      params.startMoney = this.filter.fromAmount;
      params.endMoney = this.filter.toAmount;
      params.showRiskListSize = this.filter.limit;
      params.showCompanyRisk = this.filter.showCompanyRisk;
      params.desensitizationStatus = this.$route.query.desensitization
      const res = await getCompanyRiskImage(params);
      this.data = res.data;
    },
  },
};
</script>

<style lang="stylus" scoped>
.toolbar
  margin-top 24px
  display flex
  justify-content space-between
  align-items center

  .btn-fullscreen
    font-size 14px

>>> .el-dialog__header
  border-bottom: 0 none;
  padding 0
</style>
