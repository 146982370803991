<template>
  <div>
    <el-dialog
      v-loading="adding"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      id="supple"
      :show-close="false"
    >
      <div class="part-check">
        <span
          >基础信息表单
          <span class="filed">以下信息都为必填项</span>
        </span>
        <img
          class="part-close"
          src="@/assets/test/part-close.png"
          alt
          @click="cancel"
        />
      </div>
      <div class="content">
        <div class="box">
          <div class="name field">项目名称：</div>
          <div class="info">
            <el-input
              v-model.trim="info.projectName"
              placeholder="请输入"
            ></el-input>
          </div>
        </div>
        <div class="box">
          <div class="name field">项目详细坐落地址：</div>
          <div class="info">
            <el-input
              v-model.trim="info.projectAddress"
              placeholder="请输入"
            ></el-input>
          </div>
        </div>
        <div class="box">
          <div class="name field">是否甲供材：</div>
          <div class="info">
            <el-radio v-model="info.armorForMaterials" label="1">是</el-radio>
            <el-radio v-model="info.armorForMaterials" label="0">否</el-radio>
          </div>
        </div>
        <div class="box">
          <div class="name field">备案单价（区间）：</div>
          <div class="info">
            <div class="bettow">
              <div>
                <el-input
                  v-model.trim="info.projectMinMoney"
                  placeholder="请输入"
                ></el-input>
              </div>
              <div class="horizontal">-</div>
              <div>
                <el-input
                  v-model.trim="info.projectMaxMoney"
                  placeholder="请输入"
                ></el-input>
              </div>
            </div>
          </div>
        </div>
        <div class="box">
          <div class="name field">计税方式：</div>
          <div class="info">
            <el-radio v-model="info.taxCode" label="1">一般计税</el-radio>
            <el-radio v-model="info.taxCode" label="2">简易计税</el-radio>
          </div>
        </div>
        <div class="box box-area">
          <div class="name field">项目所属区域：</div>
          <div class="info area">
            <el-radio v-model="info.projectArea" label="1"
              >开发项目位于省/自治区/直辖市和计划单列市人民政府所在地城市城区和郊区</el-radio
            >
            <el-radio v-model="info.projectArea" label="2"
              >开发项目位于地级市城区及郊区</el-radio
            >
            <el-radio v-model="info.projectArea" label="3"
              >开发项目位于其他地区</el-radio
            >
            <el-radio v-model="info.projectArea" label="4"
              >属于经记适用房/限价房和危改房</el-radio
            >
          </div>
        </div>
      </div>
      <div class="base-Btn">
        <div class="info" v-if="tips">提示：您有信息尚未填写</div>
        <div v-else class="tips">1</div>
        <div class="btn" @click="cancel">取消</div>
        <div class="btn submit" @click="submit">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getInfoByCompanyNsrsbm, addInfoBy, editInfoBy } from "@/api/api";
export default {
  data() {
    return {
      info: {
        armorForMaterials: "",
        taxCode: "",
        projectArea: "",
      },
      adding: false,
      type: 1,
      //   projectName: "",
      //   projectAddress: "",
      //   armorForMaterials: "0",
      //   projectMinMoney: "",
      //   projectMaxMoney: "",
      //   taxCode: "0",
      //   projectArea: "0",
      tips: false,
      areaList: [
        "开发项目位于省/自治区/直辖市和计划单列市人民政府所在地城市城区和郊区",
        "开发项目位于地级市城区及郊区",
        "开发项目位于其他地区",
        "于经记适用房/限价房和危改房",
      ],
    };
  },
  mounted() {
    this.getBaseInfo();
  },
  props: ["dialogVisible", "nsrsbm", "uuid", "zq"],
  methods: {
    getBaseInfo() {
      let params = {
        nsrsbm: this.nsrsbm,
      };
      getInfoByCompanyNsrsbm(params).then((res) => {
        // console.log(res);
        this.info = res.data;
        if (res.data.id) {
          this.type = 2;
        } else {
          this.type = 1;
        }
      });
    },
    checkAll() {
      this.tips = false;
      if (!this.info.projectName) {
        this.tips = true;
        return false;
      }
      if (!this.info.projectAddress) {
        this.tips = true;
        return false;
      }
      if (!this.info.armorForMaterials) {
        this.tips = true;
        return false;
      }
      if (!this.info.projectMinMoney) {
        this.tips = true;
        return false;
      }
      if (!this.info.projectMaxMoney) {
        this.tips = true;
        return false;
      }
      if (
        Number(this.info.projectMinMoney) >= Number(this.info.projectMaxMoney)
      ) {
        this.$message.error("请注意区间大小");
        // this.tips = true;
        return false;
      }
      if (!this.info.taxCode) {
        this.tips = true;
        return false;
      }
      if (!this.info.projectArea) {
        this.tips = true;
        return false;
      }
      return true;
    },
    submit() {
      if (!this.checkAll()) {
        return;
      }
      this.adding = true;
      if (this.type == 1) {
        this.add();
      } else {
        this.edit();
      }
    },
    add() {
      let params = {
        armorForMaterials: this.info.armorForMaterials,
        companyNsrsbm: this.nsrsbm,
        projectAddress: this.info.projectAddress,
        projectArea: this.info.projectArea,
        projectMaxMoney: this.info.projectMaxMoney,
        projectMinMoney: this.info.projectMinMoney,
        projectName: this.info.projectName,
        taxCode: this.info.taxCode,
        programId: this.uuid,
        zq: this.zq,
      };
      addInfoBy(params).then((res) => {
        this.adding = false;
        if (res.code == 200) {
          this.$emit("closeBase");
          this.$message({
            message: "保存成功！",
            type: "success",
          });
        }
      });
    },
    edit() {
      let params = {
        armorForMaterials: this.info.armorForMaterials,
        companyNsrsbm: this.nsrsbm,
        id: this.info.id,
        projectAddress: this.info.projectAddress,
        projectArea: this.info.projectArea,
        projectMaxMoney: this.info.projectMaxMoney,
        projectMinMoney: this.info.projectMinMoney,
        projectName: this.info.projectName,
        taxCode: this.info.taxCode,
        programId: this.uuid,
        zq: this.zq,
      };
      editInfoBy(params).then((res) => {
        this.adding = false;
        if (res.code == 200) {
          this.$emit("closeBase");
          this.$message({
            message: "保存成功！",
            type: "success",
          });
        }
      });
    },
    cancel() {
      this.$emit("cancelBaseinfo", this.dialogVisible);
    },
  },
};
</script>

<style lang="stylus" scoped>
.content {
  .box {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    .name {
      width: 150px;
      text-align: right;
      font-size: 14px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.6);
    }

    .info {
      >>> .el-input__inner {
        height: 36px;
        width: 360px;
      }

      .bettow {
        display: flex;
        align-items: center;

        >>> .el-input__inner {
          height: 36px;
          width: 165px;
        }

        .horizontal {
          margin: 0 11px;
        }
      }
    }

    .area {
      display: flex;
      flex-direction: column;

      >>> .el-radio__label {
        text-overflow: ellipsis;
        white-space: normal;
        vertical-align: top;
        display: inline-block;
        width: 335px;
      }

      >>> .el-radio {
        margin-bottom: 10px;
      }
    }
  }

  .box-area {
    align-items: flex-start;
  }

  .box:first-child {
    margin-top: 20px;
  }
}

>>> #supple .el-dialog {
  width: 540px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

>>> #supple .el-dialog__header {
  padding: 0;
}

>>> #supple .el-dialog__body {
  padding: 0;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
}

.part-check {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  width: 100%;
  border-bottom: 1px solid #eeeeee;
  padding-left: 24px;
  box-sizing: border-box;
  align-item: center;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-weight: bold;

  .filed {
    font-size: 6px;
    color: rgba(0, 0, 0, 0.4);
  }
}

.part-close {
  padding-right: 32px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.field:before {
  content: '*';
  color: red;
  position: relative;
  right: 4px;
  top: 1px;
}

.base-Btn {
  display: flex;
  align-items: center;
  height: 68px;
  border-top: 1px solid #eeeeee;

  .tips {
    color: transparent;
    flex: 1;
  }

  .info {
    color: red;
    font-size: 8px;
    flex: 1;
    margin-left: 30px;
  }

  .btn {
    width: 56px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #2F54EB;
    color: #2F54EB;
    cursor: pointer;
  }

  .submit {
    border: 1px solid #2F54EB;
    background: #2F54EB;
    color: #fff;
  }
}
</style>
