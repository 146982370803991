<template>
    <div>
        <div class="selectButton">
            <div>
              <span :class="statType=='quantity'?'selected':''" @click="statTypechange('quantity')">发票数量</span>
              <span :class="statType=='amount'?'selected2':''" @click="statTypechange('amount')">含税金额</span>
            </div>
          </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
        statType:"quantity",

        }
    },
    methods:{
        statTypechange(e){
            this.statType=e
            this.$emit('statTypechange',e)
        }
    }
}
</script>
<style lang="stylus" scoped>
.selectButton{
  margin-bottom 16px
  >div{
    font-size 12px
    text-align: right;
    font-family: PingFangSC-Regular, PingFang SC;
    >span{
      text-align: center
      display: inline-block;
      width: 64px;
      color: #86909C;
      height: 24px;
      line-height:24px
      background: #FFFFFF;
      border-radius: 0px 2px 2px 0px;
      border: 1px solid #E5E6EB;
    }
    >span.selected{
      background: #FFFFFF;
      border: 1px solid #1677FF;
      color: #1677FF;
      border-radius: 2px 0px 0px 2px;
    }
    >span.selected2{
      background: #FFFFFF;
      border: 1px solid #1677FF;
      color: #1677FF;
      border-radius: 0px 2px 2px 0px;
    }
  }
}
</style>