<template>
  <div>
    <div class="wrapper">
      <!-- <div class="title">分析结果</div> -->
      <!--近12个月发票进销抵扣分析-->
      <template v-if="data.contrastKey == 'IncomingSales'">
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable || isReadOnly"
              :placeholder="'请输入内容'"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 4, maxRows: 6 }"
            ></el-input>
          </div>
        </div>
        <div class="graph" style="min-height: 523px" v-if="isShowHistogram">
          <el-tabs type="card">
            <el-tab-pane label="折线图">
              <div>
                <JxxChart v-if="pdfData" :data="pdfData"></JxxChart>
              </div>
            </el-tab-pane>
            <el-tab-pane label="表格">
              <GenericTable
                v-if="JxxtableData"
                :data="JxxtableData"
              ></GenericTable>
            </el-tab-pane>
          </el-tabs>
        </div>
      </template>
      <!--/近12个月发票进销抵扣分析-->
      <!--近 12 个月未抵扣发票提醒-->
      <template v-else-if="data.contrastKey == 'NonDeductionIncomingSales'">
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
            ></el-input>

          </div>
        </div>
        <div class="graph" v-if="nonDeductedJxjeTableData&&isShowHistogram">
          <GenericTable :data="nonDeductedJxjeTableData"></GenericTable>
        </div>
      </template>
      <!--/近 12 个月未抵扣发票提醒-->
      <!--近6个月进销项金额差异分析-->
      <template v-else-if="data.contrastKey == 'IncomingSalesInNearJune'">
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
            ></el-input>

          </div>
        </div>
        <div class="graph" v-if="InvoiceCountTaxTable&&isShowHistogram">
          <GenericTable :data="InvoiceCountTaxTable"></GenericTable>
        </div>
      </template>
      <!--/近6个月进销项金额差异分析-->
      <!--有进项无销项风险分析-->
      <template v-else-if="data.contrastKey == 'WithInputAndNoOutput'">
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
            ></el-input>

          </div>
        </div>
        <div class="graph" v-if="invoiceYjxWxxTable&&isShowHistogram">
          <GenericTable :data="invoiceYjxWxxTable"></GenericTable>
        </div>
      </template>
      <!--/有进项无销项风险分析-->
      <!--前十大采购品类分析图标-->
      <template v-else-if="data.contrastKey == 'EntryTOP10'">
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
            ></el-input>

          </div>
        </div>

        <div
          class="graph"
          style="min-height: 523px"
          v-if="isShowHistogram"
        >
          <el-tabs type="card">
            <el-tab-pane label="环形图">
              <div v-if="invoiceJxTop10Chart">
                <Pie :data="invoiceJxTop10Chart"></Pie>
              </div>
            </el-tab-pane>
            <el-tab-pane label="表格" v-if="invoiceJxTop10Table">
              <GenericTable :data="invoiceJxTop10Table"></GenericTable>
            </el-tab-pane>
          </el-tabs>
        </div>
      </template>
      <!--/前十大采购品类分析图标-->
      <!--前十大销售品类分析-->
      <template v-else-if="data.contrastKey == 'SalesTOP10'">
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
            ></el-input>

          </div>
        </div>

        <div
          class="graph"
          style="min-height: 523px"
          v-if="isShowHistogram"
        >
          <el-tabs type="card">
            <el-tab-pane label="环形图">
              <div>
                <Pie v-if="SalesTOP10Chart" :data="SalesTOP10Chart"></Pie>
              </div>
            </el-tab-pane>
            <el-tab-pane label="表格">
              <GenericTable
                v-if="SalesTOP10Table"
                :data="SalesTOP10Table"
              ></GenericTable>
            </el-tab-pane>
          </el-tabs>
        </div>
      </template>
      <!--/前十大销售品类分析-->
      <!--公司名称与税号匹配分析-->
      <template v-else-if="data.contrastKey == 'invoiceErrorNameTaxNo'">
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
            ></el-input>

          </div>
        </div>
        <div class="graph" v-if="isShowHistogram">
          <GenericTable
            v-if="invoiceErrorNameTaxNo"
            :data="invoiceErrorNameTaxNo"
          ></GenericTable>
        </div>
      </template>
      <!--/公司名称与税号匹配分析-->
      <!--采购高风险商品风险分析-->
      <template v-else-if="data.contrastKey == 'HighRisk'">
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
            ></el-input>

          </div>
        </div>
        <div class="graph" v-if="isShowHistogram">
          <GenericTable v-if="invoiceMgsp" :data="invoiceMgsp"></GenericTable>
        </div>
      </template>
      <!--/采购高风险商品风险分析-->
      <!--从高风险地区采购商品分析-->
      <template v-else-if="data.contrastKey == 'EntryDiscount'">
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
            ></el-input>

          </div>
        </div>
        <div class="graph" v-if="isShowHistogram">
          <GenericTable v-if="invoiceSsyh" :data="invoiceSsyh"></GenericTable>
        </div>
      </template>
      <!--/从高风险地区采购商品分析-->
      <!--从成立不满 6 个月的企业采购商品分析-->
      <template v-else-if="data.contrastKey == 'EntryLessThan6MonthGoods'">
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
            ></el-input>

          </div>
        </div>
        <div class="graph" v-if="isShowHistogram">
          <GenericTable
            v-if="EntryLessThan6MonthGoodsText"
            :data="EntryLessThan6MonthGoodsText"
          ></GenericTable>
        </div>
      </template>
      <!--/从成立不满 6 个月的企业采购商品分析-->
      <!--从外省采购大宗商品风险分析-->
      <template v-else-if="data.contrastKey == 'EntryGoods'">
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
            ></el-input>

          </div>
        </div>
        <div class="graph" v-if="isShowHistogram">
          <GenericTable
            v-if="invoiceCgzwsbl"
            :data="invoiceCgzwsbl"
          ></GenericTable>
        </div>
      </template>
      <!--/从外省采购大宗商品风险分析-->
      <!--从外省采购大宗商品详细分析-->
      <template v-else-if="data.contrastKey == 'InvoiceNumber'">
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
            ></el-input>

          </div>
        </div>
        <div class="graph" v-if="isShowHistogram">
          <GenericTable
            v-if="invoiceLzwsfp"
            :data="invoiceLzwsfp"
          ></GenericTable>
        </div>
      </template>
      <!--/从外省采购大宗商品详细分析-->
      <!--取得大量货物名称为生活用品之类的发票-->
      <template v-else-if="data.contrastKey == 'invoiceSpecialLife'">
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
            ></el-input>

          </div>
        </div>
        <div class="graph" v-if="isShowHistogram">
          <GenericTable
            v-if="invoiceSpecialLife"
            :data="invoiceSpecialLife"
            :showDetails.sync="showDetails"
          ></GenericTable>

          <div class="tableWrapper" v-if="showDetails">
            <div class="table-title">详细信息（开票金额（含税）前十家）</div>
            <GenericTable
              v-if="invoiceSpecialLifeDetails"
              :data="invoiceSpecialLifeDetails"
            ></GenericTable>
          </div>
        </div>
      </template>
      <!--/取得大量货物名称为生活用品之类的发票-->

      <!--外地劳务进项发票风险-->
      <template v-else-if="data.contrastKey == 'invoiceSpecialLabor'">
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
            ></el-input>

          </div>
        </div>
        <div class="graph" v-if="isShowHistogram">
          <GenericTable
            v-if="invoiceSpecialLabor"
            :data="invoiceSpecialLabor"
            :showDetails.sync="showDetails"
          ></GenericTable>

          <div class="tableWrapper" v-if="showDetails">
            <div class="table-title">详细信息（开票金额（含税）前十家）</div>
            <GenericTable
              v-if="invoiceSpecialLaborDetails"
              :data="invoiceSpecialLaborDetails"
            ></GenericTable>
          </div>
        </div>
      </template>
      <!--/外地劳务进项发票风险-->

      <!--异地取得大量服务类发票风险-->
      <template v-else-if="data.contrastKey == 'invoiceSpecialService'">
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
            ></el-input>

          </div>
        </div>
        <div class="graph" v-if="isShowHistogram">
          <GenericTable
            v-if="invoiceSpecialService"
            :data="invoiceSpecialService"
            :showDetails.sync="showDetails"
          ></GenericTable>

          <div class="tableWrapper" v-if="showDetails">
            <div class="table-title">详细信息（开票金额（含税）前十家）</div>
            <GenericTable
              v-if="invoiceSpecialServiceDetails"
              :data="invoiceSpecialServiceDetails"
            ></GenericTable>
          </div>
        </div>
      </template>
      <!--/异地取得大量服务类发票风险-->

      <!--获得代开发票风险分析-->
      <template v-else-if="data.contrastKey == 'InvoiceGeneration'">
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
            ></el-input>

          </div>
        </div>
        <div class="graph" v-if="isShowHistogram">
          <GenericTable
            v-if="InvoiceGenerationText"
            :data="InvoiceGenerationText"
          ></GenericTable>
        </div>
      </template>
      <!--/获得代开发票风险分析-->
      <!--收到顶额发票风险分析-->
      <template v-else-if="data.contrastKey == 'OverheadEntryInvoice'">
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
            ></el-input>

          </div>
        </div>
        <div class="graph" v-if="isShowHistogram">
          <GenericTable
            v-if="invoiceEntryDekpqk"
            :data="invoiceEntryDekpqk"
          ></GenericTable>
        </div>
      </template>
      <!--/收到顶额发票风险分析-->
      <!--进项发票异常-->
      <template v-else-if="data.contrastKey == 'invoiceEntryException'">
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
            ></el-input>

          </div>
        </div>
        <div class="graph" v-if="isShowHistogram">
          <GenericTable
            v-if="invoiceEntryException"
            :data="invoiceEntryException"
          ></GenericTable>
        </div>
      </template>
      <!--/进项发票异常-->
      <!--零税率进项发票风险-->
      <template v-else-if="data.contrastKey == 'invoiceZerotax'">
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
            ></el-input>

          </div>
        </div>
        <div class="graph" v-if="isShowHistogram">
          <GenericTable
            v-if="invoiceZerotax"
            :data="invoiceZerotax"
          ></GenericTable>
        </div>
      </template>
      <!--/零税率进项发票风险-->
      <!--进项红字发票异常风险-->
      <template v-else-if="data.contrastKey == 'invoiceRed'">
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
            ></el-input>

          </div>
        </div>
        <div class="graph" v-if="isShowHistogram">
          <GenericTable v-if="invoiceRed" :data="invoiceRed"></GenericTable>
        </div>
      </template>
      <!--/进项红字发票异常风险-->
      <!--向外省销售大宗商品风险分析-->
      <template v-else-if="data.contrastKey == 'SalesGoodsInOtherProvinces'">
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
            ></el-input>

          </div>
        </div>
        <div class="graph" v-if="isShowHistogram">
          <GenericTable
            v-if="invoiceXsdwsbl"
            :data="invoiceXsdwsbl"
          ></GenericTable>
        </div>
      </template>
      <!--/向外省销售大宗商品风险分析-->
      <!--向外省销售大宗商品详细分析-->
      <template v-else-if="data.contrastKey == 'InvoiceNumberInOtherProvinces'">
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
            ></el-input>

          </div>
        </div>
        <div class="graph" v-if="isShowHistogram">
          <GenericTable
            v-if="invoiceKwwsfpje"
            :data="invoiceKwwsfpje"
          ></GenericTable>
        </div>
      </template>
      <!--/向外省销售大宗商品详细分析-->
      <!-- 顶额开票风险分析-->
      <template v-else-if="data.contrastKey == 'OverheadInvoice'">
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
            ></el-input>

          </div>
        </div>
        <div class="graph" v-if="isShowHistogram">
          <GenericTable
            v-if="invoiceDekpqk"
            :data="invoiceDekpqk"
          ></GenericTable>
        </div>
      </template>
      <!--/ 顶额开票风险分析-->
      <!-- 公司名称风险分析-->
      <template v-else-if="data.contrastKey == 'CompanyNameRisk'">
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
            ></el-input>

          </div>
        </div>
      </template>
      <!--/ 公司名称风险分析-->
      <!-- 作废发票风险检测-->

      <template v-else-if="data.contrastKey == 'invoiceNullify'">
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
            ></el-input>

          </div>
        </div>
        <div class="graph" v-if="isShowHistogram">
          <GenericTable
            v-if="invoiceNullify"
            :data="invoiceNullify"
          ></GenericTable>
        </div>
      </template>

      <!--/ 作废发票风险检测-->
      <!-- 互相开票风险分析-->
      <template v-else-if="data.contrastKey == 'MutualInvoice'">
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
            ></el-input>

          </div>
        </div>
        <div class="graph" v-if="isShowHistogram">
          <GenericTable
            v-if="invoiceHxkpqk"
            :data="invoiceHxkpqk"
          ></GenericTable>
        </div>
      </template>
      <!--/ 互相开票风险分析-->
      <!-- 涉及税务违法案例的供应商风险-->
      <template v-else-if="data.contrastKey == 'EntryMoneyInNearDecember'">
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
            ></el-input>

          </div>
        </div>
        <div class="graph" v-if="invoiceFzcGys&&isShowHistogram">
          <GenericTable :data="invoiceFzcGys.table"></GenericTable>
          <div v-if="invoiceFzcGys.drawer" class="company-table-wrapper">
            <CompanyTable
              v-for="(conpanyInfo, index) in invoiceFzcGys.drawer"
              :key="conpanyInfo.id"
              :index="index"
              :isDisplay="!!conpanyInfo.isDisplay"
              @toggleVisible="handleCompanyTableVisibilityToggle"
            >
              <tr>
                <td>公司名称</td>
                <td>{{ conpanyInfo.companyName }}</td>
              </tr>
              <tr>
                <td>纳税人识别号</td>
                <td>{{ conpanyInfo.nsrsbh }}</td>
              </tr>
              <tr>
                <td>注册资本</td>
                <td>{{ conpanyInfo.zczb }}</td>
              </tr>
              <tr>
                <td>工商注册时间</td>
                <td>{{ conpanyInfo.zcsj }}</td>
              </tr>
              <tr>
                <td>主要经营范围</td>
                <td>{{ conpanyInfo.jyfw }}</td>
              </tr>
              <tr>
                <td>与贵公司交易情况</td>
                <td>{{ conpanyInfo.jyje }}</td>
              </tr>
              <tr>
                <td>已公布风险情况</td>
                <td>{{ conpanyInfo.fxqk }}</td>
              </tr>
            </CompanyTable>
          </div>
        </div>
      </template>
      <!--/ 涉及税务违法案例的供应商风险-->
      <!--1.5.3供应商公司相关的法律诉讼风险-->
      <template
        v-else-if="data.contrastKey == 'invoiceSupplierLegalLitigationRisk'"
      >
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
            ></el-input>

          </div>
        </div>
        <div class="graph" v-if="isShowHistogram">
          <TableWithDrawer
            v-if="invoiceSupplierLegalLitigationRisk"
            :drawerTriggerIndex="3"
            :data="invoiceSupplierLegalLitigationRisk"
          >
            <template v-slot="slotProps">
              <GenericTable
                v-if="slotProps.drawerData.drawer"
                :data="
                  caseOfinvoiceSupplierLegalLitigationRisk(
                    slotProps.drawerData.drawer
                  )
                "
              ></GenericTable>
            </template>
          </TableWithDrawer>
        </div>
      </template>
      <!--/1.5.3供应商公司相关的法律诉讼风险-->
      <!--1.5.4供应商公司被执行人风险-->
      <template v-else-if="data.contrastKey == 'invoiceSupplierExecutorRisk'">
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
            ></el-input>

          </div>
        </div>
        <div class="graph" v-if="isShowHistogram">
          <TableWithDrawer
            v-if="invoiceSupplierExecutorRisk"
            :drawerTriggerIndex="2"
            :data="invoiceSupplierExecutorRisk"
          >
            <template v-slot="slotProps">
              <GenericTable
                v-if="slotProps.drawerData.drawer"
                :data="
                  caseOfinvoiceSupplierExecutorRisk(slotProps.drawerData.drawer)
                "
              ></GenericTable>
            </template>
          </TableWithDrawer>
        </div>
      </template>
      <!--/1.5.4供应商公司被执行人风险-->
      <!--1.5.5供应商公司失信人风险-->
      <template v-else-if="data.contrastKey == 'invoiceSupplierDishonestRisk'">
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
            ></el-input>

          </div>
        </div>
        <div class="graph" v-if="isShowHistogram">
          <TableWithDrawer
            v-if="invoiceSupplierDishonestRisk"
            :drawerTriggerIndex="2"
            :data="invoiceSupplierDishonestRisk"
          >
            <template v-slot="slotProps">
              <GenericTable
                v-if="slotProps.drawerData.drawer"
                :data="
                  caseOfinvoiceSupplierDishonestRisk(
                    slotProps.drawerData.drawer
                  )
                "
              ></GenericTable>
            </template>
          </TableWithDrawer>
        </div>
      </template>
      <!--/1.5.5供应商公司失信人风险-->
      <!--1.5.6供应商公司限制消费令风险-->
      <template
        v-else-if="data.contrastKey == 'invoiceSupplierLimitingConsumptionRisk'"
      >
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
            ></el-input>

          </div>
        </div>
        <div class="graph" v-if="isShowHistogram">
          <TableWithDrawer
            v-if="invoiceSupplierLimitingConsumptionRisk"
            :drawerTriggerIndex="2"
            :data="invoiceSupplierLimitingConsumptionRisk"
          >
            <template v-slot="slotProps">
              <GenericTable
                v-if="slotProps.drawerData.drawer"
                :data="
                  caseOfinvoiceSupplierLimitingConsumptionRisk(
                    slotProps.drawerData.drawer
                  )
                "
              ></GenericTable>
            </template>
          </TableWithDrawer>
        </div>
      </template>
      <!--/1.5.6供应商公司限制消费令风险-->
      <!-- 1.5.7税务违法案例的客户风险-->
      <template v-else-if="data.contrastKey == 'SalesMoneyInNearDecember'">
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
            ></el-input>

          </div>
        </div>
        <div class="graph" v-if="isShowHistogram">
          <TableWithDrawer
            v-if="invoiceFzcKh"
            :drawerTriggerIndex="1"
            :data="invoiceFzcKh"
          >
            <template v-slot="slotProps">
              <CompanyTable v-if="slotProps.drawerData.drawer">
                <tr>
                  <td>公司名称</td>
                  <td>{{ slotProps.drawerData.drawer.companyName }}</td>
                </tr>
                <tr>
                  <td>纳税人识别号</td>
                  <td>{{ slotProps.drawerData.drawer.nsrsbh }}</td>
                </tr>
                <tr>
                  <td>注册资本</td>
                  <td>{{ slotProps.drawerData.drawer.zczb }}</td>
                </tr>
                <tr>
                  <td>工商注册时间</td>
                  <td>{{ slotProps.drawerData.drawer.zcsj }}</td>
                </tr>
                <tr>
                  <td>主要经营范围</td>
                  <td>{{ slotProps.drawerData.drawer.jyfw }}</td>
                </tr>
                <tr>
                  <td>与贵公司交易情况</td>
                  <td>{{ slotProps.drawerData.drawer.jyje }}</td>
                </tr>
                <tr>
                  <td>已公布风险情况</td>
                  <td>{{ slotProps.drawerData.drawer.fxqk }}</td>
                </tr>
              </CompanyTable>
            </template>
          </TableWithDrawer>
        </div>
      </template>
      <!--/ 1.5.7税务违法案例的客户风险-->
      <!--1.5.8客户公司相关的法律诉讼风险-->
      <template
        v-else-if="data.contrastKey == 'invoiceCustomerLegalLitigationRisk'"
      >
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
            ></el-input>

          </div>
        </div>
        <div class="graph" v-if="isShowHistogram">
          <TableWithDrawer
            v-if="invoiceCustomerLegalLitigationRisk"
            :data="invoiceCustomerLegalLitigationRisk"
          >
            <template v-slot="slotProps">
              <GenericTable
                v-if="slotProps.drawerData.drawer"
                :data="
                  caseOfinvoiceCustomerLegalLitigationRisk(
                    slotProps.drawerData.drawer
                  )
                "
              ></GenericTable>
            </template>
          </TableWithDrawer>
        </div>
      </template>
      <!--/1.5.8客户公司相关的法律诉讼风险-->
      <!--1.5.9 客户公司被执行人风险-->
      <template v-else-if="data.contrastKey == 'invoiceCustomerExecutorRisk'">
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
            ></el-input>

          </div>
        </div>
        <div class="graph" v-if="isShowHistogram">
          <TableWithDrawer
            v-if="invoiceCustomerExecutorRisk"
            :data="invoiceCustomerExecutorRisk"
          >
            <template v-slot="slotProps">
              <GenericTable
                v-if="slotProps.drawerData.drawer"
                :data="
                  caseOfinvoiceCustomerExecutorRisk(slotProps.drawerData.drawer)
                "
              ></GenericTable>
            </template>
          </TableWithDrawer>
        </div>
      </template>
      <!--/1.5.9 客户公司被执行人风险-->
      <!--1.5.10 客户公司失信人风险-->
      <template v-else-if="data.contrastKey == 'invoiceCustomerDishonestRisk'">
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
            ></el-input>

          </div>
        </div>
        <div class="graph" v-if="isShowHistogram">
          <TableWithDrawer
            v-if="invoiceCustomerDishonestRisk"
            :data="invoiceCustomerDishonestRisk"
          >
            <template v-slot="slotProps">
              <GenericTable
                v-if="slotProps.drawerData.drawer"
                :data="
                  caseOfinvoiceCustomerDishonestRisk(
                    slotProps.drawerData.drawer
                  )
                "
              ></GenericTable>
            </template>
          </TableWithDrawer>
        </div>
      </template>
      <!--/1.5.10 客户公司失信人风险-->
      <!--1.5.11 客户公司限制消费令风险-->
      <template
        v-else-if="data.contrastKey == 'invoiceCustomerLimitingConsumptionRisk'"
      >
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
            ></el-input>

          </div>
        </div>
        <div class="graph" v-if="isShowHistogram">
          <TableWithDrawer
            v-if="invoiceCustomerLimitingConsumptionRisk"
            :data="invoiceCustomerLimitingConsumptionRisk"
          >
            <template v-slot="slotProps">
              <GenericTable
                v-if="slotProps.drawerData.drawer"
                :data="
                  caseOfinvoiceCustomerLimitingConsumptionRisk(
                    slotProps.drawerData.drawer
                  )
                "
              ></GenericTable>
            </template>
          </TableWithDrawer>
        </div>
      </template>
      <!--/1.5.11 客户公司限制消费令风险-->
      <!--新模型发票模型-->
      <template
        v-else
      >
        <div class="textarea-wrapper">
          <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
            <el-tooltip
              :popper-class="popperClass"

              :key="'aa'+data.contrastKey + !isEditable || isReadOnly"
              :manual="true"
              :value="isInputModified || isInputModifiedByMyself"
              content="此内容已修改"
              placement="top-end"
            >
              <div
                :class="{
                  isModified: isInputModified,
                  isModifiedByMyself: isInputModifiedByMyself,
                }"
                class="textarea textarea-text"
              >
                {{ dataText }}
              </div>
            </el-tooltip>
          </div>
          <div class="editable-wrapper" v-else>
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :disabled="!isEditable"
              placeholder="请输入内容"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
            ></el-input>

          </div>
        </div>
        <div class="graph" v-if="isShowHistogram">
          <template v-if="histogramType=='brokenLine'">
            <el-tabs type="card">
              <el-tab-pane label="折线图">
                <div>
                  <GeneralLineChart v-if="pdfData" :data="pdfData"></GeneralLineChart>
                </div>
              </el-tab-pane>
              <el-tab-pane label="表格">
                <GenericTable
                  v-if="generalTableData"
                  :data="generalTableData"
                  :tips="tips"
                  :subTables="subTables"
                ></GenericTable>
              </el-tab-pane>
            </el-tabs>
          </template>
          <template v-else>
            <GenericTable
              v-if="generalTableData"
              :data="generalTableData"
              :tips="tips"
              :subTables="subTables"
            ></GenericTable>
          </template>


        </div>
      </template>
      <!--/新模型发票模型-->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {mapGetters, mapMutations} from "vuex";
import {cloneDeep} from "lodash";
import {percentage, dateRange} from "@/utils/format";
import JxxChart from "./components/JxxChart";
import Pie from "./components/Pie";
import GenericTable from "@/pages/ResultEditor/components/InvoiceIndex/components/GenericTable.vue";
import TableWithDrawer from "./components/TableWithDrawer";
import CompanyTable from "./components/CompanyTable";
import mixin from "@/pages/ResultEditor/mixin.js";
import GeneralLineChart from "@/pages/ResultEditor/components/InvoiceIndex/components/GeneralLineChart";

export default {
  mixins: [mixin],
  mounted() {
  },

  components: {
    JxxChart,
    GenericTable,
    Pie,
    TableWithDrawer,
    CompanyTable,
    GeneralLineChart
  },
  methods: {
    ...mapMutations("ResultEditor", ["setTreeDataByIndexes"]),
    handleCompanyTableVisibilityToggle(index, isDisplay) {
      const nodeData = cloneDeep(this.data);
      const pdfData = JSON.parse(nodeData.pdfData);
      pdfData[index].isDisplay = isDisplay ? 1 : 0;
      nodeData.pdfData = JSON.stringify(pdfData);

      this.setTreeDataByIndexes({
        data: nodeData,
        indexes: this.indexes,
      });
    },
    //1.5.3供应商公司相关的法律诉讼风险的详细案例列表
    caseOfinvoiceSupplierLegalLitigationRisk(cases) {
      let ret = [];
      const data = cases;
      if (!data) {
        return null;
      }

      ret = data.map((item, index) => {
        let table = [];
        table[0] = index + 1;
        table[1] = item.judgetime;
        table[2] = item.title;
        table[3] = item.casereason;
        table[4] = item.plaintiffs;
        table[5] = item.caseno;
        table[6] = `<a href="${item.url}" target="_blank">${item.url}</a>`;
        return table;
      });

      const labels = [
        "序号",
        "日期",
        "案件名称",
        "案由",
        "案件身份",
        "案号",
        "链接（原文链接地址）",
      ];

      return {
        data: ret,
        labels,
      };
    },
    //1.5.4供应商公司被执行人风险详细
    caseOfinvoiceSupplierExecutorRisk(cases) {
      let ret = [];
      const data = cases;
      if (!data) {
        return null;
      }

      ret = data.map((item, index) => {
        let table = [];
        table[0] = index + 1;
        table[1] = item.execMoney;
        table[2] = item.caseCode;
        table[3] = item.execCourtName;
        return table;
      });

      const labels = ["序号", "执行标的金额（元）", "案号", "执行法院"];

      return {
        data: ret,
        labels,
      };
    },
    //1.5.5供应商公司失信人风险
    caseOfinvoiceSupplierDishonestRisk(cases) {
      let ret = [];
      const data = cases;
      if (!data) {
        return null;
      }

      ret = data.map((item, index) => {
        let table = [
          index + 1,
          item.publishdate,
          item.regdate,
          item.casecode,
          item.courtname,
          item.performance,
          item.gistid,
          item.disruptTypeName,
        ];

        return table;
      });

      const labels = [
        "序号",
        "发布日期",
        "立案日期",
        "案号",
        "执行法院",
        "履行情况",
        "执行依据文号",
        "失信被执行人行为具体情形",
      ];

      return {
        data: ret,
        labels,
      };
    },
    //1.5.6 供应商公司限制消费令风险
    caseOfinvoiceSupplierLimitingConsumptionRisk(cases) {
      let ret = [];
      const data = cases;
      if (!data) {
        return null;
      }

      ret = data.map((item, index) => {
        let table = [
          index + 1,
          item.caseCode,
          item.alias,
          item.xname,
          `<a href="${item.filePath}" target="_blank">${item.filePath}</a>`,
        ];

        return table;
      });

      const labels = ["序号", "案号", "别名", "限制消费者名称", "pdf地址"];

      return {
        data: ret,
        labels,
      };
    },
    //1.5.8客户公司相关的法律诉讼风险
    caseOfinvoiceCustomerLegalLitigationRisk(cases) {
      let ret = [];
      const data = cases;
      if (!data) {
        return null;
      }

      ret = data.map((item, index) => {
        let table = [
          index + 1,
          item.judgetime,
          item.title,
          item.casereason,
          `${item.plaintiffs}<br/>${item.defendants}`,
          item.caseno,
          `<a href="${item.url}" target="_blank">${item.url}</a>`,
        ];

        return table;
      });

      const labels = [
        "序号",
        "日期",
        "案件名称",
        "案由",
        "案件身份",
        "案号",
        "链接（原文链接地址）",
      ];

      return {
        data: ret,
        labels,
      };
    },
    //1.5.9客户公司相关的法律诉讼风险
    caseOfinvoiceCustomerExecutorRisk(cases) {
      let ret = [];
      const data = cases;
      if (!data) {
        return null;
      }

      ret = data.map((item, index) => {
        let table = [
          index + 1,
          item.execMoney,
          item.caseCode,
          item.execCourtName,
        ];

        return table;
      });

      const labels = ["序号", "执行标的金额（元）", "案号", "执行法院"];

      return {
        data: ret,
        labels,
      };
    },
    //1.5.10 客户公司失信人风险
    caseOfinvoiceCustomerDishonestRisk(cases) {
      let ret = [];
      const data = cases;
      if (!data) {
        return null;
      }

      ret = data.map((item, index) => {
        let table = [
          index + 1,
          item.publishdate,
          item.regdate,
          item.casecode,
          item.gistunit,
          item.performance,
          item.gistid,
          item.disruptTypeName,
        ];

        return table;
      });

      const labels = [
        "序号",
        "发布日期",
        "立案日期",
        "案号",
        "执行法院（做出执行的依据单位）",
        "履行情况",
        "执行依据文号",
        "失信被执行人行为具体情形",
      ];

      return {
        data: ret,
        labels,
      };
    },
    //1.5.11 客户公司限制消费令风险
    caseOfinvoiceCustomerLimitingConsumptionRisk(cases) {
      let ret = [];
      const data = cases;
      if (!data) {
        return null;
      }

      ret = data.map((item, index) => {
        let table = [
          index + 1,
          item.caseCode,
          item.alias,
          item.xname,
          `<a href="${item.filePath}" target="_blank">${item.filePath}</a>`,
        ];

        return table;
      });

      const labels = ["序号", "案号", "别名", "限制消费者名称", "pdf地址"];

      return {
        data: ret,
        labels,
      };
    },
  },
  props: {
    indexes: Array,
    isReadOnly: Boolean,
    isShowDetail: Boolean,
  },

  computed: {
    ...mapGetters("ResultEditor", ["findTheNodeByIndexes"]),
    histogramType() {
      return this.pdfData ? this.pdfData.imageType : '';
    },
    subTables() {
      return this.pdfData.subTables && this.pdfData.subTables.map(v => {
        v.pos = [v.row != null ? v.row : -1, v.col != null ? v.col : -1]
        return v
      });
    },
    tips() {
      return this.pdfData.tips && this.pdfData.tips.map(v => {
        return {
          pos: [v.row != null ? v.row : -1, v.col != null ? v.col : -1],
          text: v.text
        }
      })
    },
    isShowHistogram() {
      const allowedContrastKey = [
        'IncomingSales',
        'EntryTOP10',
        'SalesTOP10'
      ]
      return !this.data.userData && (this.isShowDetail || allowedContrastKey.includes(this.data.contrastKey))

    },
    data() {
      return this.findTheNodeByIndexes(this.indexes);
    },
    pdfData() {
      return JSON.parse(this.data.pdfData);
    },
    //共输入框使用
    dataText: {
      get() {
        return this.data.dataText;
      },
      set(value) {
        const nodeData = this.data;
        nodeData.dataText = value; //给节点设置新的dataText
        this.setTreeDataByIndexes({
          data: nodeData,
          indexes: this.indexes,
        });
      },
    },
    isInputModified() {
      let ret = false;

      if (this.$route.params.action == "edit") {
        ret = this.data.lastCensorSnapshot
          ? this.data.lastCensorSnapshot.includes("TextChange")
          : false;
      } else if (this.$route.params.action == "censor") {
        ret = this.data.lastEditSnapshot
          ? this.data.lastEditSnapshot.includes("TextChange")
          : false;
      }
      return ret;
    },
    isInputModifiedByMyself() {
      let ret = false;

      if (this.$route.params.action == "edit") {
        ret = this.data.editSnapshot
          ? this.data.editSnapshot.includes("TextChange")
          : false;
      } else if (this.$route.params.action == "censor") {
        ret = this.data.censorSnapshot
          ? this.data.censorSnapshot.includes("TextChange")
          : false;
      }
      return ret;
    },
    popperClass() {
      let ret = "";
      if (this.isInputModifiedByMyself) {
        ret = "modified-tooltip-red";
      } else if (this.isInputModified) {
        ret = "modified-tooltip-yellow";
      }
      return ret;
    },
    //通用表格
    generalTableData() {
      if (!this.pdfData) {
        return null;
      }
      const ret = [];

      for (let i = 0; i < this.pdfData.body.length; i++) {
        ret[i] = [];

        this.pdfData.header.forEach(key => {

          ret[i].push(this.pdfData.body[i][key])
        })

      }


      return {
        data: ret,
        labels: this.pdfData.header,
      };
    },

    //近12个月发票进销抵扣分析
    JxxtableData() {
      let ret = [];
      const data = this.pdfData;
      if (data) {
        ret = data.map((item, index) => {
          let ret = [];
          const date = `${item.year}.${item.month}`;
          ret[0] = date;
          ret[1] = item.jxje;
          ret[2] = item.deductedJxje;
          ret[3] = item.xxje;

          return ret;
        });
      }

      const labels = [
        "月份/项目（万元）",
        "进项税额",
        "进项税额（已抵扣）",
        "销项税额",
      ];

      return {
        data: ret,
        labels,
      };
    },
    //近 12 个月未抵扣发票提醒表格
    nonDeductedJxjeTableData() {
      let ret = [];

      const data = this.pdfData;

      if (!data) {
        return null;
      }

      const isAllEmpty = data.every((item) => {
        return item.nonDeductedJxje == 0;
      });

      if (!isAllEmpty) {
        ret = data.map((item, index) => {
          let ret = [];
          const date = `${item.year}.${item.month}`;
          ret[0] = date;
          ret[1] = item.nonDeductedJxje;
          return ret;
        });
      }

      const labels = ["月份/项目（万元）", "进项税额（未抵扣）"];
      return {
        data: ret,
        labels,
      };
    },

    //近 6 个月进销金额差异分析表格
    InvoiceCountTaxTable() {
      let ret = [];

      const data = this.pdfData;
      if (!data) {
        return null;
      }
      const item = [];
      item[0] = data.date;
      item[1] = data.jxse;
      item[2] = data.xxse;
      ret[0] = item;

      const labels = ["期间", "进项税额（万元）", "销项税额（万元）"];
      return {
        data: ret,
        labels,
      };
    },
    //有进项无销项
    invoiceYjxWxxTable() {
      let ret = [];

      const data = this.pdfData;
      if (!data) {
        return null;
      }
      ret = data.map((item, index) => {
        let ret = [];
        ret[0] = item.date;
        ret[1] = item.jxje;
        ret[2] = item.xxje;
        return ret;
      });

      const labels = ["期间", "进项税额（万元）", "销项税额（万元）"];
      return {
        data: ret,
        labels,
      };
    },
    //前十大采购品类分析图标
    invoiceJxTop10Chart() {
      let ret = [];

      const data = this.pdfData;
      if (!data) {
        return null;
      }
      ret = data.data.map((item, index) => {
        return {
          name: item.hwlwmc,
          value: item.zzjxjebl, //占总采购金额比例
        };
      });

      const sum = data.cgjeHz;

      //如果其他大于0 则添加其他
      if (1 - data.zzjxjeblHz) {
        ret.push({
          name: "其他",
          value: 1 - data.zzjxjeblHz,
        });
      }

      return ret;
    },
    //前十大采购品类分析表格
    invoiceJxTop10Table() {
      let ret = [];

      const data = this.pdfData;
      if (!data) {
        return null;
      }
      ret = data.data.map((item, index) => {
        let ret = [];
        ret[0] = index + 1;
        ret[1] = item.hwlwmc;
        ret[2] = item.productNumber;
        ret[3] = item.productUnit;
        ret[4] = item.cgje;
        ret[5] = item.jxje;
        ret[6] = percentage(item.zzjxjebl);
        return ret;
      });

      //汇总
      const aggregation = [];

      aggregation[0] = "";
      aggregation[1] = "前十品类采购汇总";
      aggregation[2] = "/";
      aggregation[3] = "/";
      aggregation[4] = data.cgjeHz;
      aggregation[5] = data.jxjeHz;
      aggregation[6] = percentage(data.zzjxjeblHz || 0);

      ret.push(aggregation);

      const labels = [
        "序号",
        "货品和劳务名称",
        "数量",
        "单位",
        "采购金额(万元)",
        "进项税额(万元)",
        "占总采购额比例",
      ];
      return {
        data: ret,
        labels,
      };
    },
    //前十大销售品类分析
    SalesTOP10Chart() {
      let ret = [];

      const data = this.pdfData;
      if (!data) {
        return null;
      }
      ret = data.data.map((item, index) => {
        return {
          name: item.hwlwmc,
          value: item.zzxxjebl, //占总采购金额比例
        };
      });

      //如果其他大于0 则添加其他
      if (1 - data.zzxxjeblHz) {
        ret.push({
          name: "其他",
          value: 1 - data.zzxxjeblHz,
        });
      }

      return ret;
    }, //前十大销售品类表格
    SalesTOP10Table() {
      let ret = [];

      const data = this.pdfData;
      if (!data) {
        return null;
      }
      ret = data.data.map((item, index) => {
        let ret = [];
        ret[0] = index + 1;
        ret[1] = item.hwlwmc;
        ret[2] = item.productNumber;
        ret[3] = item.productUnit;
        ret[4] = item.xsje;
        ret[5] = item.xxje;
        ret[6] = percentage(item.zzxxjebl);
        return ret;
      });

      //汇总
      const aggregation = [];

      aggregation[0] = "";
      aggregation[1] = "前十品类销售汇总";
      aggregation[2] = "/";
      aggregation[3] = "/";
      aggregation[4] = data.xsjeHz;
      aggregation[5] = data.xxjeHz;
      aggregation[6] = percentage(data.zzxxjeblHz);

      ret.push(aggregation);

      const labels = [
        "序号",
        "货品和劳务名称",
        "数量",
        "单位",
        "销售金额（万元）",
        "销项税额（万元）",
        "占总销售额比例",
      ];
      return {
        data: ret,
        labels,
      };
    },
    //进项发票名称与税号匹配分析
    invoiceErrorNameTaxNo() {
      let ret = [];

      const data = this.pdfData;
      if (!data) {
        return null;
      }
      //debugger
      ret = data.map((item, index) => {
        let ret = [
          item.invoiceType,
          item.invoiceCode,
          item.invoiceNumber,
          item.entryName,
          item.salesName,
          item.companyTaxno,
          item.taxAmount,
        ];
        return ret;
      });

      const labels = [
        "发票类型",
        "发票代码",
        "发票号码",
        "购方名称",
        "销方名称",
        "公司税号",
        "开票金额（万元）",
      ];
      return {
        data: ret,
        labels,
      };
    },

    //采购高风险商品风险分析
    invoiceMgsp() {
      let ret = [];

      const data = this.pdfData;
      if (!data) {
        return null;
      }
      ret = data.map((item, index) => {
        let ret = [];
        ret[0] = item.date;
        ret[1] = item.spmc;
        ret[2] = item.xsje;
        ret[3] = item.hzje;
        return ret;
      });

      const labels = [
        "期间",
        "高风险商品前三品类",
        "高风险商品采购额（万元）",
        "采购额汇总（万元）",
      ];
      return {
        data: ret,
        labels,
      };
    },
    //从高风险地区采购商品分析
    invoiceSsyh() {
      let ret = [];

      const data = this.pdfData;
      if (!data) {
        return null;
      }
      ret = data.map((item, index) => {
        let ret = [];
        ret[0] = index + 1;
        ret[1] = item.gysName;
        ret[2] = item.gysAddress;
        ret[3] = item.yhdq;
        ret[4] = item.cgpl;
        ret[5] = item.jwncgje;
        return ret;
      });

      const labels = [
        "序号",
        "供应商名称",
        "供应商地址",
        "隶属风险地区",
        "采购品类",
        "近 1 年内采购金额（万元）",
      ];
      return {
        data: ret,
        labels,
      };
    },
    //从成立不满 6 个月的企业采购商品分析
    EntryLessThan6MonthGoodsText() {
      let ret = [];

      const data = this.pdfData;
      if (!data) {
        return null;
      }
      ret = data.map((item, index) => {
        let ret = [];
        ret[0] = index + 1;
        ret[1] = item.gysName;
        ret[2] = item.gysclsj;
        ret[3] = item.cgfssj;
        ret[4] = item.jwncgje;
        return ret;
      });

      const labels = [
        "序号",
        "供应商名称",
        "供应商成立时间",
        "采购发生时间",
        "近 1 年采购金额（万元）",
      ];
      return {
        data: ret,
        labels,
      };
    },
    //从外省采购大宗商品风险分析
    invoiceCgzwsbl() {
      let ret = [];

      const data = this.pdfData;
      if (!data) {
        return null;
      }
      ret = data.map((item, index) => {
        let ret = [];
        ret[0] = item.goods;
        ret[1] = item.xsje;
        ret[2] = percentage(item.bl);
        return ret;
      });

      const labels = [
        "大宗商品名称",
        "采购自外省金额（万元）",
        "采购自外省比例",
      ];
      return {
        data: ret,
        labels,
      };
    },
    //从外省采购大宗商品详细分析
    invoiceLzwsfp() {
      let ret = [];

      const data = this.pdfData;
      if (!data) {
        return null;
      }
      ret = data.map((item, index) => {
        let ret = [];
        ret[0] = item.wssf;
        ret[1] = item.goods;
        ret[2] = item.gyssl;
        ret[3] = item.xsje;
        return ret;
      });

      const labels = [
        "外省省份",
        "主要采购物资",
        "供应商数量（个）",
        "采购金额（万元）",
      ];
      return {
        data: ret,
        labels,
      };
    },
    //取得大量货物名称为生活用品之类的发票
    invoiceSpecialLife() {
      let ret = [];

      const data = this.pdfData;

      if (!data) {
        return null;
      }
      const item = [
        dateRange(data.fromDate, data.toDate),
        data.number,
        data.taxAmount,
        percentage(data.proportion),
        "button{detail}",
      ];
      ret[0] = item;

      const labels = [
        "期间",
        "生活发票数量",
        "生活发票金额（含税）（万元）",
        "占总采购额占比",
        "详情",
      ];
      return {
        data: ret,
        labels,
      };
    },
    //取得大量货物名称为生活用品之类的发票详情
    invoiceSpecialLifeDetails() {
      let ret = [];

      const data = this.pdfData;
      if (!data) {
        return null;
      }
      if (data) {
        ret = data.invoiceSpecialLifeEntryList.map((item, index) => {
          let ret = [
            dateRange(item.fromDate, item.toDate),
            item.salesName,
            item.topThreeProductName,
            item.taxAmount,
            percentage(item.proportion),
          ];
          return ret;
        });
      }
      const labels = [
        "开票期间",
        "开票单位",
        "前三大品类",
        "开票金额(万元)",
        "占总采购比例",
      ];
      return {
        data: ret,
        labels,
      };
    },
    //外地劳务进项发票风险
    invoiceSpecialLabor() {
      let ret = [];

      const data = this.pdfData;
      if (!data) {
        return null;
      }
      const item = [];
      item[0] = dateRange(data.fromDate, data.toDate);
      item[1] = data.number;
      item[2] = data.taxAmount;
      item[3] = percentage(data.proportion);
      item[4] = "button{detail}";
      ret[0] = item;

      const labels = [
        "期间",
        "外地劳务发票数量",
        "外地劳务发票金额（含税）",
        "占总采购额比例",
        "详情",
      ];
      return {
        data: ret,
        labels,
      };
    },
    //外地劳务进项发票风险详情
    invoiceSpecialLaborDetails() {
      let ret = [];

      const data = this.pdfData;

      if (!data) {
        return null;
      }

      if (data) {
        ret = data.invoiceSpecialLaborEntryList.map((item, index) => {
          let ret = [
            dateRange(item.fromDate, item.toDate),
            item.salesName,
            item.topThreeProductName,
            item.taxAmount,
            percentage(item.proportion),
          ];
          return ret;
        });
      }
      const labels = [
        "开票期间",
        "开票单位",
        "前三大品类",
        "开票金额(万元)",
        "占总采购比例",
      ];
      return {
        data: ret,
        labels,
      };
    },
    //异地取得大量服务类发票风险
    invoiceSpecialService() {
      let ret = [];

      const data = this.pdfData;

      if (!data) {
        return null;
      }
      const item = [];
      item[0] = dateRange(data.fromDate, data.toDate);
      item[1] = data.number;
      item[2] = data.taxAmount;
      item[3] = percentage(data.proportion);
      item[4] = "button{detail}";
      ret[0] = item;

      const labels = [
        "期间",
        "外地服务发票数量",
        "外地服务发票金额（含税）",
        "占总采购额比例",
        "详情",
      ];
      return {
        data: ret,
        labels,
      };
    },
    //异地取得大量服务类发票风险详情
    invoiceSpecialServiceDetails() {
      let ret = [];

      const data = this.pdfData;

      if (!data) {
        return null;
      }

      if (data) {
        ret = data.invoiceSpecialServiceEntryList.map((item, index) => {
          let ret = [
            dateRange(item.fromDate, item.toDate),
            item.salesName,
            item.topThreeProductName,
            item.taxAmount,
            percentage(item.proportion),
          ];
          return ret;
        });
      }
      const labels = [
        "开票期间",
        "开票单位",
        "前三大品类",
        "开票金额(万元)",
        "占总采购额比例",
      ];
      return {
        data: ret,
        labels,
      };
    },
    //进项发票异常
    invoiceEntryException() {
      let ret = [];

      const data = this.pdfData;

      if (!data) {
        return null;
      }

      if (data) {
        ret = data.map((item, index) => {
          let ret = [];
          ret[0] = item.salesName;
          ret[1] = item.number;
          ret[2] = item.taxAmount;
          ret[3] = percentage(item.proportion);
          return ret;
        });
      }

      const labels = [
        "开票单位",
        "开票数量",
        "开票金额(含税)(万元)",
        "金额占进项发票数量的比例",
      ];
      return {
        data: ret,
        labels,
      };
    },
    //零税率进项发票风险
    invoiceZerotax() {
      let ret = [];

      const data = this.pdfData;
      if (!data) {
        return null;
      }

      if (data) {
        ret = data.map((item, index) => {
          let ret = [];

          ret[0] = item.billingDate;
          ret[1] = item.number;
          ret[2] = item.taxAmount;
          ret[3] = percentage(item.proportion);
          return ret;
        });
      }
      const labels = [
        "月份",
        "零税额发票数量",
        "零税额发票金额(含税)(万元)",
        "占总发票比例",
      ];
      return {
        data: ret,
        labels,
      };
    },
    //进项红字发票异常风险
    invoiceRed() {
      let ret = [];

      const data = this.pdfData;
      if (!data) {
        return null;
      }
      if (data) {
        ret = data.map((item, index) => {
          let ret = [];
          ret[0] = item.billingDate;
          ret[1] = item.number;
          ret[2] = item.amountTax;
          return ret;
        });
      }
      const labels = ["期间", "红字发票数量", "红字发票金额(含税)(万元)"];
      return {
        data: ret,
        labels,
      };
    },
    //获得代开发票风险分析
    InvoiceGenerationText() {
      let ret = [];

      const data = this.pdfData;
      if (!data) {
        return null;
      }
      if (data) {
        ret = data.invoiceJxfpdk.map((item, index) => {
          let ret = [];
          ret[0] = item.dkjg;
          ret[1] = item.cgje;
          ret[2] = item.jxse;
          ret[3] = percentage(item.jxsezb);
          return ret;
        });

        //汇总
        const aggregation = [];

        aggregation[0] = "代开发票汇总";
        aggregation[1] = this.pdfData.cgjeHz;
        aggregation[2] = this.pdfData.jxseHz;
        aggregation[3] = percentage(this.pdfData.jxseZbHz);

        ret.push(aggregation);
      }
      const labels = [
        "代开机构",
        "采购金额(含税)(万元）",
        "进项税额（万元）",
        "采购金额占比",
      ];
      return {
        data: ret,
        labels,
      };
    },
    //收到顶额发票风险分析
    invoiceEntryDekpqk() {
      let ret = [];
      const data = this.pdfData;
      if (!data) {
        return null;
      }
      ret = data.map((item, index) => {
        let ret = [];
        ret[0] = item.timeRange;
        ret[1] = item.kpsl;
        ret[2] = item.topBillingSum;
        ret[3] = item.customerName;
        ret[4] = item.kpzs;
        return ret;
      });

      const labels = [
        "期间",
        "收到顶额发票数量（张）",
        "收到顶额发票金额（万元）",
        "开票方",
        "收到总发票数量（张）",
      ];
      return {
        data: ret,
        labels,
      };
    },
    //向外省销售大宗商品风险分析
    invoiceXsdwsbl() {
      let ret = [];

      const data = this.pdfData;
      if (!data) {
        return null;
      }
      ret = data.map((item, index) => {
        let ret = [];
        ret[0] = item.goods;
        ret[1] = item.xsje;
        ret[2] = percentage(item.bl);
        return ret;
      });

      const labels = [
        "大宗商品名称",
        "销售到外省金额（万元）",
        "销售自外省比例",
      ];
      return {
        data: ret,
        labels,
      };
    },
    //向外省销售大宗商品详细分析
    invoiceKwwsfpje() {
      let ret = [];

      const data = this.pdfData;
      if (!data) {
        return null;
      }
      ret = data.map((item, index) => {
        let ret = [];

        ret[0] = item.goods;
        ret[1] = item.wssf;
        ret[2] = item.khsl;
        ret[3] = item.xsje;
        return ret;
      });

      const labels = ["销售品类", "外省省份", "客户数量", "销售金额（万元）"];
      return {
        data: ret,
        labels,
      };
    },
    //顶额开票风险分析
    invoiceDekpqk() {
      let ret = [];
      if (this.pdfData) {
        const data = this.pdfData;
        if (!data) {
          return null;
        }
        ret = data.map((item, index) => {
          let ret = [];
          ret[0] = item.timeRange;
          ret[1] = item.kpsl;
          ret[2] = item.topBillingSum;
          ret[3] = item.customerName;
          ret[4] = item.kpzs;
          return ret;
        });
      } else {
        return null;
      }

      const labels = [
        "期间",
        "顶额开票数量（张）",
        "顶额开票金额（万元）",
        "受票方",
        "总开票数量（张）",
      ];
      return {
        data: ret,
        labels,
      };
    },
    //作废发票风险检测
    invoiceNullify() {
      let ret = [];

      const data = this.pdfData;
      if (!data) {
        return null;
      }

      if (data) {
        ret = data.map((item, index) => {
          let ret = [];
          ret[0] = item.billingDate;
          ret[1] = item.number;
          ret[2] = item.nullifyTaxAmount;
          return ret;
        });
      }
      const labels = ["月份", "作废发票数量", "作废发票金额(含税)(万元)"];
      return {
        data: ret,
        labels,
      };
    },
    //互相开票风险分析
    invoiceHxkpqk() {
      let ret = [];

      const data = this.pdfData;
      if (!data) {
        return null;
      }
      ret = data.map((item, index) => {
        let ret = [];
        ret[0] = item.kpCompanyName;
        ret[1] = item.cgcpmc;
        ret[2] = item.cgje;
        ret[3] = item.xscpmc;
        ret[4] = item.xsje;
        return ret;
      });

      const labels = [
        "公司名称",
        "采购货物名称",
        "采购金额汇总（万元）",
        "销售货物名称",
        "销售金额汇总（万元）",
      ];
      return {
        data: ret,
        labels,
      };
    },
    //涉及税务违法案例的供应商风险
    invoiceFzcGys() {
      let ret = [];

      const data = this.pdfData;
      if (!data) {
        return null;
      }
      ret = data.map((item, index) => {
        let table = [];
        table[0] = index + 1;
        table[1] = item.fzcqymc;
        table[2] = item.cgje;
        return table;
      });

      const labels = ["序号", "非正常企业名称", "采购金额(含税)(万元）"];
      const table = {
        data: ret,
        labels,
      };

      const drawer = data.map((item, index) => {
        return {...item.qyxx, isDisplay: item.isDisplay};
      });

      return {
        table,
        drawer,
      };
    },
    //1.5.3供应商公司相关的法律诉讼风险
    invoiceSupplierLegalLitigationRisk() {
      let ret = [];

      const data = this.pdfData;
      if (!data) {
        return null;
      }

      ret = data.map((item, index) => {
        let table = [];
        table[0] = index + 1;
        table[1] = item.name;
        table[2] = item.total;

        table[3] = "button{detail}";
        return {table, drawer: item.legalLitigationRiskVoList};
      });

      const labels = [
        "序号",
        "存在法律诉讼的供应商名称",
        "涉案案件次数",
        "详细司法风险",
      ];
      return {
        data: ret,
        labels,
      };
    },
    //1.5.4供应商公司被执行人风险
    invoiceSupplierExecutorRisk() {
      let ret = [];

      const data = this.pdfData;
      if (!data) {
        return null;
      }

      ret = data.map((item, index) => {
        let table = [];
        table[0] = index + 1;
        table[1] = item.name;
        table[2] = "button{detail}";
        return {table, drawer: item.invoiceExecutorRiskVoList};
      });

      const labels = [
        "序号",
        "存在被执行人风险的供应商名称",
        "详细司法风险",
      ];

      return {
        data: ret,
        labels,
      };
    },
    //1.5.5供应商公司失信人风险
    invoiceSupplierDishonestRisk() {
      let ret = [];

      const data = this.pdfData;
      if (!data) {
        return null;
      }

      ret = data.map((item, index) => {
        let table = [];
        table[0] = index + 1;
        table[1] = item.name;
        table[2] = "button{detail}";
        return {table, drawer: item.riskVoList};
      });

      const labels = ["序号", "存在失信人风险的供应商名称", "操作"];

      return {
        data: ret,
        labels,
      };
    },
    //1.5.6供应商公司限制消费令风险
    invoiceSupplierLimitingConsumptionRisk() {
      let ret = [];

      const data = this.pdfData;
      if (!data) {
        return null;
      }

      ret = data.map((item, index) => {
        let table = [index + 1, item.name, "button{detail}"];
        return {table, drawer: item.riskVoList};
      });

      const labels = ["序号", "供应商名称", "限制消费详细情况"];

      return {
        data: ret,
        labels,
      };
    },

    //1.5.7税务违法案例的客户风险
    invoiceFzcKh() {
      let ret = [];

      const data = this.pdfData;
      if (!data) {
        return null;
      }
      ret = data.map((item, index) => {
        let table = [];
        table[0] = index + 1;
        table[1] = item.fzcqymc;
        table[2] = item.xsje;
        return {table, drawer: item.qyxx};
      });

      const labels = ["序号", "非正常客户名称", "销售金额（万元）"];
      return {
        data: ret,
        labels,
      };
    },
    //1.5.8客户公司相关的法律诉讼风险
    invoiceCustomerLegalLitigationRisk() {
      let ret = [];

      const data = this.pdfData;
      if (!data) {
        return null;
      }
      ret = data.map((item, index) => {
        let table = [index + 1, item.name, item.total, "button{detail}"];
        return {table, drawer: item.legalLitigationRiskVoList};
      });

      const labels = [
        "序号",
        "存在法律诉讼的客户名称",
        "涉案案件次数",
        "详细司法风险",
      ];

      return {
        data: ret,
        labels,
      };
    },
    //1.5.9客户公司相关的法律诉讼风险
    invoiceCustomerExecutorRisk() {
      let ret = [];

      const data = this.pdfData;
      if (!data) {
        return null;
      }

      ret = data.map((item, index) => {
        let table = [index + 1, item.name, "button{detail}"];
        return {table, drawer: item.invoiceExecutorRiskVoList};
      });

      const labels = [
        "序号",
        "存在被执行人风险的客户名称",
        "详细司法风险",
      ];

      return {
        data: ret,
        labels,
      };
    },
    //1.5.10客户公司相关的法律诉讼风险
    invoiceCustomerDishonestRisk() {
      let ret = [];

      const data = this.pdfData;
      if (!data) {
        return null;
      }

      ret = data.map((item, index) => {
        let table = [index + 1, item.name, "button{detail}"];
        return {table, drawer: item.riskVoList};
      });

      const labels = ["序号", "存在失信人风险的客户名称", "操作"];

      return {
        data: ret,
        labels,
      };
    },
    //1.5.11 客户公司限制消费令风险
    invoiceCustomerLimitingConsumptionRisk(cases) {
      let ret = [];

      const data = this.pdfData;
      if (!data) {
        return null;
      }

      ret = data.map((item, index) => {
        let table = [index + 1, item.name, "button{detail}"];
        return {table, drawer: item.riskVoList};
      });

      const labels = ["序号", "客户名称", "限制消费详细情况"];

      return {
        data: ret,
        labels,
      };
    },
  },

  data: function () {
    return {
      inputTextArea: "",
      showDetails: false,
    };
  },
};
</script>

<style lang="stylus" scoped>
.wrapper {
  padding: 0;

  .title {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.8);
    line-height: 1.5;
    font-weight: bold;
  }

  .textarea-wrapper {
    margin: 0;

    .editable-wrapper {
      padding-left: 0px;
      display: flex;
      align-items: flex-start;
      width: 100%;
      color: rgba(0, 0, 0, 0.6);

      .label {
        font-weight: bold;
        color: rgba(0, 0, 0, 0.6);
        font-size: 16px;
        margin-right: 5px;
        margin-left: 16px;
        margin-top: 20px;
      }

      .textarea {
        >>> textarea {
          box-sizing: border-box;
          font-size: 14px;
          line-height: 30px;
        }

        >>> .el-textarea__inner {
          border-radius: 16px;
        }

        width: auto;
        display: block;
        margin: 16px;
        margin-left: 15px;
        border-radius: 16px;
        box-sizing: border-box;
        line-height: 1.5;
        color: rgba(0, 0, 0, 0.8);
        font-size: 14px;
        flex-grow: 1;
      }
    }

    .textarea-text {
      padding: 0 5px;
    }

    >>> .el-textarea__inner {
      height: 100px;
    }

    .isModified.textarea-text {
      padding: 5px;
      box-sizing: border-box;
      border: 1px solid #faad14 !important;
      border-color: #faad14 !important;
    }

    .isModifiedByMyself.textarea-text {
      padding: 5px;
      box-sizing: border-box;
      border: 1px solid rgba(245, 34, 45, 1) !important;
      border-color: rgba(245, 34, 45, 1) !important;
    }

    .isModified.textarea {
      >>> .el-textarea__inner {
        padding: 5px;
        box-sizing: border-box;
        border: 1px solid #faad14 !important;
        border-color: #faad14 !important;
      }

      border-radius: 4px;
    }

    .isModifiedByMyself.textarea {
      >>> .el-textarea__inner {
        padding: 5px;
        box-sizing: border-box;
        border: 1px solid rgba(245, 34, 45, 1) !important;
        border-color: rgba(245, 34, 45, 1) !important;
      }

      border-radius: 4px;
    }
  }

  .graph {
    padding: 24px 17px;
    box-sizing: border-box;

    .tableWrapper {
      margin-top: 22px;

      .table-title {
        margin-bottom: -18px;
        color: rgba(0, 0, 0, 0.4);
        line-height: 22px;
      }

      .table {
        margin-top: 0;
      }
    }

    .diagram {
      width: 50%;
      height: 435px;
    }

    >>> .el-tabs__header {
      margin: 0;
      border-bottom: none;

      .el-tabs__item {
        border-radius: 0px;
        border: 1px solid rgba(47, 84, 235, 1);
        color: rgba(0, 0, 0, 0.4);
        margin: 0;
        border: 1px solid #D9D9D9;
        border-right: none;
        font-size: 14px;
        padding: 0px 16px;
        line-height: 36px;
        height: 36px;
      }

      .el-tabs__item.is-active {
        background: rgba(240, 245, 255, 1);
        color: #2F54EB;
        border: 1px solid #2F54EB !important;
      }

      .el-tabs__item:first-child {
        border-radius: 4px 0px 0px 4px;
      }

      .el-tabs__item:last-child {
        border-radius: 0px 4px 4px 0px;
        border-right: 1px solid #D9D9D9;
      }
    }

    >>> .el-tabs__content {
      overflow: visible;
    }
  }
}
</style>
<style lang="stylus">
.modified-tooltip-yellow {
  border: 1px solid #faad14;
  background: #faad14 !important;
  width: 140px;
  font-size: 15px;
  height: 30px;
  padding: 0px;
  text-align: center;
  line-height: 25px;
  filter: drop-shadow(0 0 2px rgba(250, 173, 20, 0.5));
}

.modified-tooltip-yellow[x-placement^=top] .popper__arrow {
  border-top-color: #faad14;
  filter: drop-shadow(0 0 2px rgba(250, 173, 20, 0.5));
}

.modified-tooltip-yellow[x-placement^=top] .popper__arrow::after {
  border-top-color: #faad14;
  filter: drop-shadow(0 0 2px rgba(250, 173, 20, 0.5));
}

.el-tooltip__popper[x-placement^=bottom] .popper__arrow {
  border-bottom-color: #faad14;
}

.el-tooltip__popper[x-placement^=bottom] .popper__arrow::after {
  border-bottom-color: #faad14;
}

.modified-tooltip-red {
  border: 1px solid rgba(245, 34, 45, 1);
  background: rgba(245, 34, 45, 1) !important;
  color: #fff;
  width: 140px;
  font-size: 15px;
  height: 30px;
  padding: 0px;
  text-align: center;
  line-height: 25px;
  filter: drop-shadow(0 0 2px rgba(245, 34, 45, 1));
}

.modified-tooltip-red[x-placement^=top] .popper__arrow {
  border-top-color: rgba(245, 34, 45, 1);
  filter: drop-shadow(0 0 2px rgba(245, 34, 45, 1));
}

.modified-tooltip-red[x-placement^=top] .popper__arrow::after {
  border-top-color: rgba(245, 34, 45, 1);
  filter: drop-shadow(0 0 2px rgba(245, 34, 45, 1));
}

.el-tooltip__popper[x-placement^=bottom] .popper__arrow {
  border-bottom-color: #faad14;
}

.el-tooltip__popper[x-placement^=bottom] .popper__arrow::after {
  border-bottom-color: #faad14;
}
</style>
