<template>
  <div class="indiv_tax">
    <div class="msg-title">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ name: 'homePage' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>个税申报检测</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <div class="title">个税申报检测</div>
    </div>
    <div id="setuser">
      <!-- <div class="qymc">个税申报检测</div> -->
      <el-card>
        <div class="mdf_content compant_mdf">
          <div class="mdf_c1">
            <span>年度工资费用总数：</span>
            <el-input
              v-model="form.gongzi"
              :maxlength="20"
              placeholder="请输入"
              class="mdf_input com_input"
            ></el-input>
            <span>个税申报薪资总数：</span>
            <el-input
              v-model="form.shenbao"
              :maxlength="20"
              placeholder="请输入"
              class="mdf_input com_input"
            ></el-input>
            <el-button type="primary" class="nowbtn nowbtn1" @click="submitForm('ruleForm')">马上查询</el-button>
          </div>
        </div>
      </el-card>
      <el-card class="topjl" v-if="result !== ''">
        <div class="jsjg">计算结果</div>
        <div class="endS">
          <div class="nosearch nosearch1" v-if="result>=0.9 ">
            <img src="@/assets/util/indiv2.png" alt />
            <div>工资费用与个税申报薪金差异过大</div>
            <div
              class="divodd"
            >工资费用与社保申报基数差异过大 、 通常企业工资费用即为企业发放的工资，和社保申报基数应当相同，如果差异过大，可能虚列工资费用或少缴纳社保费用</div>
          </div>
          <div class="nosearch nosearch1" v-if="result<0.9">
            <img src="@/assets/util/indiv1.png" alt />
            <div>工资费用与个税申报薪金差异正常</div>
            <div>企业工资费用即企业发放给员工的工资（包括三险一金），和社保申报基数相同</div>
          </div>
        </div>
      </el-card>
      <el-card class="topjl" v-else>
        <div class="nosearch" v-if="!result">
          <img src="@/assets/util/wsearcg.png" alt />
          <div>您还没有进行搜索哦～</div>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import gz_2017 from "./gz_2017";
import gz_2016 from "./gz_2016";
export default {
  components: {
    BreadCrumb,
  },
  data() {
    var check = (rule, value, callback) => {
      if (value != "") {
        if (
          /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/.test(value) == false
        ) {
          this.$message.error("请填写数字，并且大于0");
        } else {
          callback();
        }
      } else {
        this.$message.error("内容不能为空");
      }
    };
    return {
      activeName: "first",
      result: "",
      form: {
        gongzi: "",
        shenbao: "",
      },
      gz_2017: gz_2017,
      gz_2016: gz_2016,
      showgz: false,
      rules: {
        gongzi: [{ validator: check }],
        shenbao: [{ validator: check }],
      },
    };
  },
  methods: {
    handleClick(tab, event) {},
    submitForm(formName) {
      if (this.form.gongzi != "" && this.form.shenbao != "") {
        if (
          /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/.test(
            this.form.shenbao
          ) == false &&
          /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/.test(
            this.form.gongzi
          ) == false
        ) {
          this.$message.error("请填写数字，并且大于0");
          return;
        } else {
          this.result = (
            parseFloat(this.form.gongzi) / parseFloat(this.form.shenbao) -
            1
          ).toFixed(2);
        }
      } else {
        this.$message.error("内容不能为空");
        return;
      }
    },
  },
};
</script>
<style src="@/styles/index.styl" lang="stylus" scoped>
</style>
<style lang="stylus" scoped>
.nosearch1 {
  img {
    width: 259px;
    height: 259px;
  }
}

.jsjg {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  line-height: 25px;
}

.indiv_tax {
  #setuser {
    padding: 16px;
    margin: 0px auto 30px auto;
    width: 96%;
  }

  .compant_mdf {
    height: 44px;
    min-height: 44px;
    margin-left: 20px;
  }

  .sbjcresult {
    width: 90%;
    margin: 36px auto 80px auto;
    text-align: left;

    .sbjcresultmain {
      height: 260px;
      padding-left: 300px;
      position: relative;
      overflow: hidden;
      display: inline-block;
      margin-left: 50px;

      img {
        position: absolute;
        left: 0px;
        top: 25px;
        width: 230px;
      }
    }

    .sbjcresultmaincon {
      text-align: left;
      display: flex;
      width: 500px;
      height: 260px;
      align-items: center;
      flex-wrap: wrap;

      p {
        width: 100%;
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: rgba(255, 100, 100, 1);
        margin-bottom: 29px;
      }

      section {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
        line-height: 25px;
      }
    }
  }
}

.msg-title {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 0px 0px rgba(238, 238, 238, 1);
}

>>>.bread-crumb-wrapper {
  margin-top: 0;
}

.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  // font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 28px;
  margin: 8px 0 0 30px;
}
</style>
