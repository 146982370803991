import { render, staticRenderFns } from "./KeyTaxRate.vue?vue&type=template&id=4b7b7dc7&scoped=true"
import script from "./KeyTaxRate.vue?vue&type=script&lang=js"
export * from "./KeyTaxRate.vue?vue&type=script&lang=js"
import style0 from "./KeyTaxRate.vue?vue&type=style&index=0&id=4b7b7dc7&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b7b7dc7",
  null
  
)

export default component.exports