<template>
  <div class="wrapper">
    <i class="icon el-icon-warning"></i>
    试用版本仅支持试用部分功能，解锁全部功能请前往购买
    <router-link :to="{name:'goods'}" class="right">立即购买</router-link>
  </div>
</template>

<script>
export default {};
</script>

<style lang="stylus" scoped>
.wrapper {
  padding: 0 16px;

  .icon {
    color: #F5222D;
  }

  .right {
    color: #F5222D;
    float: right;
  }

  .right:hover {
    text-decoration: underline;
  }

  box-sizing: border-box;
  font-size: 14px;
  line-height: 36px;
  width: 486px;
  height: 36px;
  background: rgba(245, 34, 45, 0.1);
  border-radius: 3px;
  border: 1px solid rgba(245, 34, 45, 0.48);
  color: #666;
}
</style>