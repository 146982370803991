<template>
  <div>
    <div class="column2Chart-c">
      <Line2Chart
        class="column2Chart"
        :chartData="data.jsonData"
        v-if="data && data.jsonData"
      ></Line2Chart>
      <!--    <LineChart :data="chart" v-if="chart.imageType=='brokenLine'" width="849px"-->
      <!--               height="435px"></LineChart>-->
      <div v-if="data == ''" class="noData">暂无数据</div>
      <div class="sc" @click="switchScreen" v-if="data && data.jsonData">
        <img src="@/assets/util/hScreen.png" alt="" />
      </div>
    </div>
    <div class="full-sc">
      <el-drawer :visible.sync="horizontalScreen" direction="btt" size="100%">
        <div class="full-tab">
          <HpLine2Chart
            class="column2Charthp"
            :chartData="data.jsonData"
            v-if="data && data.jsonData"
          ></HpLine2Chart>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import { getGXAmountCompare } from "@/api/threeYears";
import LineChart from "@/pages/Result/components/chats/LineChart";
import Line2Chart from "@/pages/Result/H5FinancialAnalysis/purchaseAndSale/Line2Chart";

import HpLine2Chart from "@/pages/Result/H5FinancialAnalysis/purchaseAndSale/hengpingLine2";

export default {
  components: {
    Line2Chart,
    HpLine2Chart,
  },
  name: "PurchaseAndSaleMoney",
  props: {
    taxNo: String,
    zq: [String, Number],
  },
  async mounted() {
    await this.loadGXAmountCompare();
  },
  data() {
    return {
      data: null,
      horizontalScreen: false,
    };
  },
  methods: {
    switchScreen() {
      this.$router.push({
        path:
          "../purchaseHp/" +
          this.$route.params.uuid + '/' +
          this.taxNo + '/' +
          this.$route.query.zq,
      });
    },
    async loadGXAmountCompare() {
      const res = await getGXAmountCompare({
        taxNo: this.taxNo,
        zq: this.zq,
        programId: this.$route.params.uuid,
      });
      this.data = res.data || "";
    },
  },
};
</script>

<style lang="stylus" scoped>
.HpLine2Chart {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 9998;
}

.column2Charthp {
  width: 9rem;
  height: 5.8rem
  z-index: 9999;
}

.column2Chart
  width: 100%;
  height: 6.3rem
  margin-top 16px
  margin-bottom: 10px

.noData {
  width: 100%;
  text-align: center;
  line-height: 60px;
  color: #1d2129;
  font-size: 0.28rem;
}

.column2Chart-c {
  position: relative;

  .sc {
    position: absolute;
    left: 5px;
    bottom: 0;
    padding: 5px;

    img {
      width: 0.25rem;
      height: 0.25rem;
      background: #FFFFFF;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
      border-radius: 0.08rem;
    }
  }
}

.full-sc {
  .full-tab {
    transform-origin: center center;
    transform: rotateZ(90deg);
  }
  >>> .el-drawer__header {
    padding: 0;
  }
  >>> .el-drawer__body {
    overflow: hidden;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  >>> .el-drawer__open .el-drawer.btt {
    border-radius: 0;
  }
  >>> .el-drawer__close-btn {
    font-size: 30px;
    position: relative;
    right: 10px;
    top: 10px;
  }
  .table {
    >>> .bigPowerTable {
      max-height 5.8rem
      max-width 85vh
      overflow auto
    }
    >>> table.table th, >>> table.table td {
      font-size: 0.2rem;
    }
  }
}
</style>
