import axios, {
  get,
  post
} from "@/axios";
import store from "@/store";

const base = process.env.VUE_APP_API_ROOT;

/**
 * 获取公司经营信息
 * @param uuid
 * @returns {*}
 */
export const getBusinessInfo = uuid => {
  return get(`${base}/open/boss/report/index/getBusinessInfo`,{uuid})
    .then(res => res.data);
};
/**
 * 获取公司风险信息
 * @param uuid
 * @returns {*}
 */
export const getRiskInfo = (uuid, desensitizationStatus) => {
  return get(`${base}/open/boss/report/index/getRiskInfo`,{uuid, desensitizationStatus})
    .then(res => res.data);
};
/**
 * 获取公司风险列表
 * @param uuid string
 * @param active bool
 * @returns {*}
 */
export const getRiskList = ({uuid,active}) => {
  const params = {
    uuid
  }
  if(active!=undefined){
    params.active = active
  }
  return get(`${base}/open/boss/report/index/getRiskList`,params)
    .then(res => res.data);
};
/**
 * 获取公司风险详情
 * @param params string
 * @returns {*}
 */
export const getRiskDetailList = (params) => {

  return get(`${base}/open/boss/report/index/getRiskDetailList`,params)
    .then(res => res.data);
};

/**
 * 获取一键看票发票类型饼图
 * @param params object
 * @returns {*}
 */
export const getInvoiceTypeCard = params => {
  return get(`${base}/open/boss/report/invoice/getInvoiceTypeCard`,params)
    .then(res => res.data);
};
/**
 * 获取一键看票发票状态柱状图
 * @param params object
 * @returns {*}
 */
export const getInvoiceStatusCard = params => {
  return get(`${base}/open/boss/report/invoice/getInvoiceStatusCard`,params)
    .then(res => res.data);
};
/**
 * 获取一键看票特殊发票柱状图
 * @param params object
 * @returns {*}
 */
export const getSpecialInvoiceCard = params => {
  return get(`${base}/open/boss/report/invoice/getSpecialInvoiceCard`,params)
    .then(res => res.data);
};
/**
 * 获取一键看票风险发票柱状图
 * @param params object
 * @returns {*}
 */
export const getRiskInvoiceCard = params => {
  return get(`${base}/open/boss/report/invoice/getRiskInvoiceCard`,params)
    .then(res => res.data);
};
/**
 * 获取一键看票特殊发票详情
 * @param params object
 * @returns {*}
 */
export const getSpecialInvoiceDetail = params => {
  return get(`${base}/open/boss/report/invoice/getSpecialInvoiceDetail`,params)
    .then(res => res.data);
};




export const getCoreFinancialData = ({uuid}) => {
  const params = {
    uuid
  }
  return get(`${base}/open/boss/report/finance/coreFinancialData`,params)
    .then(res => res.data);
};
/**
 * 收入类型分析
 * @param uuid
 * @returns {*}
 */
export const getRevenueTypeAnalysis = ({uuid}) => {
  const params = {
    uuid
  }
  return get(`${base}/open/boss/report/finance/revenueTypeAnalysis`,params)
    .then(res => res.data);
};
/**
 * 成本费用分析
 * @param uuid
 * @returns {*}
 */
export const getCostAnalysis = ({uuid}) => {
  const params = {
    uuid
  }
  return get(`${base}/open/boss/report/finance/costAnalysis`,params)
    .then(res => res.data);
};

/**
 * 偿债能力分析
 * @param uuid
 * @returns {*}
 */
export const getSolvencyAnalysis = ({uuid}) => {
  const params = {
    uuid
  }
  return get(`${base}/open/boss/report/finance/solvencyAnalysis`,{uuid})
    .then(res => res.data);
};


/**
 * 纳税分析-信用等级
 * @param uuid
 * @returns {*}
 */
export const getTaxCreditRating = (params) => {
  return get(`${base}/open/boss/report/tax/getTaxCreditRating`,params)
    .then(res => res.data);
};


/**
 * 纳税分析-历年纳税
 * @param uuid
 * @returns {*}
 */
export const getTaxYearSituation = (params) => {
  return get(`${base}/open/boss/report/tax/getTaxYearSituation`,params)
    .then(res => res.data);
};

/**
 * 纳税分析-纳税资金流出情况
 * @param uuid
 * @returns {*}
 */
export const getTaxOverFlowSituation = (params) => {
  return get(`${base}/open/boss/report/tax/getTaxOverFlowSituation`,params)
    .then(res => res.data);
};

/**
 * 纳税分析-增资税申报情况
 * @param uuid
 * @returns {*}
 */
export const getVatDeclaration = (params) => {
  return get(`${base}/open/boss/report/tax/getVatDeclaration`,params)
    .then(res => res.data);
};
/**行业对比 */
// export const getIndustryCompareAPI = ({uuid}) => {
//   const params = {
//     uuid:uuid
//   }
//   return get(`${base}/open/boss/report/finance/industryCompare`,params)
//     .then(res => res.data);
// };
export const getIndustryCompareAPI = ({uuid}) => {
  return get(`${base}/open/boss/report/finance/industryCompare`,{uuid})
    .then(res => res.data);
};
