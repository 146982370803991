<template>
  <div>
    <div class="wrapper">
      <img src="@/assets/result/pdf/face.png" class="image"/>
    </div>

  </div>
</template>

<script>
export default {
  name: "FirstPage"
}
</script>

<style lang="stylus" scoped>
.wrapper
  .image
    width 100%
    display block
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside avoid
    position relative

</style>
