<template>
  <div>
    <div class="pc-device" v-if="!this.isMobileDevice">
      <el-dialog
        :visible.sync="dialogVisible"
        width="480px"
        height="302px"
        :close-on-click-modal="false"
      >
        <div class="wrapper">
          <div class="icon el-icon-s-opportunity"></div>
          <el-form :rules="rules" ref="form" label-position="left" :model="form"
                   class="content" label-width="60px" :hide-required-asterisk="true">
            <div class="title">安全提示</div>
            <div class="text">检测报告涉及企业隐私数据，请谨慎分享，分享后对方查看报告需要输入校验码。</div>
            <el-form-item label="验证码有效期：" label-width="110px" prop="expireHours">
              <el-input
                class="input"
                v-model.trim.number="form.expireHours"
                placeholder="请输入1-48的整数"
              >
                <div slot="suffix">小时</div>
              </el-input>
            </el-form-item>
            <div class="tuomin">
              <el-checkbox v-model="form.desensitization" v-if="desensitizationAvailable">
                对报告中关键的公司名称、税号、地址进行脱敏
              </el-checkbox>
            </div>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <button
              @click="handleConfirmClick"
              class="btn btn-ok"
            >
              复制链接及验证码
            </button>
          </span>
      </el-dialog>
      <!--    <div v-if="isMobile" class="mobileShareCodeBtnWrapper">-->
      <!--      <img src="@/assets/result/mobile-share-report.png" class="shareCodeBtn"-->
      <!--           @click="handleShareCodeBtnWrapperClick"/>-->
      <!--    </div>-->
      <!--    <template v-else>-->
      <!--      <div class="verticleShareCodeBtnWrapper" v-if="vertical">-->
      <!--        <img src="@/assets/result/verticle-share-report.png" class="shareCodeBtn"-->
      <!--             @click="handleShareCodeBtnWrapperClick"/>-->
      <!--      </div>-->

      <!--      <div class="shareCodeBtnWrapper" v-else>-->
      <!--        <img src="@/assets/result/share-report.png" class="shareCodeBtn"-->
      <!--             @click="handleShareCodeBtnWrapperClick"/>-->
      <!--      </div>-->
      <!--    </template>-->
    </div>
    <div v-else class="mobile-device">
      <van-popup v-model="dialogVisible" closeable :style="{ width: '90%' }">
        <van-form ref="form2" validate-first class="content">
          <div class="title">安全提示</div>
          <div class="text">检测报告涉及企业隐私数据，请谨慎分享，分享后对方查看报告需要输入校验码。</div>
          <div
            class="form-control"
          >
            <van-field
              name="验证码有效期"
              label="验证码有效期"
              :rules="[{ validator: rules2.expireHours, message: '请输入1-48的整数' }]"
              v-model.number="form.expireHours"
              :border="true"
            >
            </van-field>
            <div class="unit">
              小时
            </div>

          </div>

          <div class="tuomin">
            <van-checkbox v-model="form.desensitization" shape="square"
                          v-if="desensitizationAvailable">对报告中关键的公司名称、税号、地址进行脱敏
            </van-checkbox>

          </div>
          <div class="dialog-footer">
            <textarea v-model="shareText" id="input" class="textarea"/>
            <button
              class="btn btn-ok"
              @click="handleConfirmClick"
            >
              复制链接及验证码
            </button>
          </div>
        </van-form>

      </van-popup>
    </div>
  </div>

</template>


<script>
import {
  checkShareCode,
  createShareCode, fetchCompanyInfo,
  getDesensitizationInfo, getDesensitizationStatus, getResultBasicInfo, h5GetResultBasicInfo,
  updateDesensitizationInfo
} from "../../../../api/api";
import copy from 'copy-to-clipboard';
import {getAutoRoutePushTo} from "@/utils/helper";

export default {
  name: "ThreeShowShareCodeDialog",
  props: {
    uuid: String,
    vertical: Boolean,
    isMobile: Boolean
  },
  watch: {
    form: {
      handler() {
        this.shareText = "点击下方按钮获取分享链接"
      },
      deep: true

    },
  },
  data() {
    return {
      shareText: "点击下方按钮获取分享链接",
      checkList: [],
      dialogVisible: false,
      form: {
        desensitization: false,
        shareCode: '',
        expireHours: 1
      },
      rules: {
        expireHours: [
          {required: true, message: '请输入验证码有效期', trigger: 'blur'},
          {
            validator: (rule, value, callback) => {
              if (Number.isInteger(value) && value >= 1 && value <= 48) {
                return callback();
              } else {
                return callback(new Error('请输入1-48的整数'));
              }
            }, trigger: 'blur'
          }
        ],
      },
      rules2: {
        expireHours(value) {
          return Number.isInteger(value) && value >= 1 && value <= 48;
        }
      },
      error: false,
      desensitizationAvailable: false,
      basicInfo: {}
    };
  },
  async mounted() {
    // 来之后台不掉下面接口
    if(this.$route.query.fromHouTai == 'houtai') return;
    const token = localStorage.getItem("authorization");
    const resBasicInfo = await h5GetResultBasicInfo(this.$route.params.uuid)
    this.basicInfo = resBasicInfo.data

    // const res = await getDesensitizationInfo({token}); //是否修改过报告 修改过就不能下载脱敏报告

    //脱敏报告是否可用
    // const desensitizationRes = await getDesensitizationStatus({uuid: this.uuid});
    // if (desensitizationRes.data) {
    //   this.desensitizationAvailable = true
    //   this.form.desensitization = !!res.data.desensitizationStatus
    // } else {
    //   this.form.desensitization = false
    //   this.desensitizationAvailable = false
    // }

  },
  computed: {
    isMobileDevice() {
      return window.innerWidth < 600;
    },
    nsrsbh() {
      return this.$route.query.nsrsbh
    },
    detailRouterObject() {
      return getAutoRoutePushTo(this.uuid, this.$route, 0)
    }
  },
  methods: {
    handleDetailReportClick() {
      this.$router.push(this.detailRouterObject)
    },

    handleShareCodeBtnWrapperClick() {
      this.dialogVisible = true;
    },

    async share() {
      const token = localStorage.getItem("authorization");
      const res = await createShareCode(this.uuid, this.nsrsbh, this.form.expireHours)
      // await updateDesensitizationInfo({
      //   token, desensitizationStatus: this.form.desensitization ? 1 : 0
      // })
      let props = this.$router.resolve({
        name: 'detect-share',
        params: {
          uuid: this.$route.params.uuid,
          checkId: res.data.urlCheck
        },
        query: {

          desensitization: this.form.desensitization ? 1 : 0
        }
      });

      const sharedUrl = new URL(props.href, window.location.href).href;
      const companyName = this.form.desensitization ? this.basicInfo.desensitizationCompanyName : this.basicInfo.companyName

      if (res.msgCode == 1) {
        const text = `报告名称：${companyName}-企业财税分析报告
报告地址：${sharedUrl}
校验码：${res.data.checkCode}
有效期截止：${res.data.effectiveDate}`;
        copy(text);
        this.dialogVisible = false
        this.$message('复制成功');
      } else {
        this.error = true;
      }
    },

    async handleConfirmClick() {
      if (this.isMobileDevice) {
        try {
          await this.$refs.form2.validate();
          this.share();
        } catch (e) {
          return
        }
      } else {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            this.share();
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }


    }
  }
};
</script>
<style lang="stylus" scoped>
.pc-device {
  .wrapper {
    display: flex;
    width 100%

    .icon {
      color: rgb(250, 173, 20);
      font-size: 32px;
    }

    .content {
      margin-left: 16px;
      margin-right 30px

      .title {
        font-weight: 600;
        color: rgba(0, 0, 0, 0.65);
        line-height: 22px;
        font-size: 16px;
      }

      .text {
        margin-top: 8px;
        font-size: 14px;
        color: #666;
        line-height: 1.5;
        margin-bottom 24px
      }

      .tuomin {
        margin-top 10px
        margin-bottom 10px

        .el-checkbox {
          margin-bottom 5px
        }

        .text {
          margin-bottom 10px
        }
      }

      .error {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #C30505;
        line-height: 20px;
        margin-top 16px
      }
    }
  }

  .dialog-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    padding 0
    margin 0;

    .btn-ok {
      width 100%
      flex-grow: 0;
      font-size: 14px;
      font-weight: 600;
      color: #FFFFFF;
      margin 0
      border-radius 0
      line-height: 68px;
      padding 0
      background: #2F54EB;
    }
  }

  >>> .el-dialog__header {
    padding: 0;
  }

  >>> .el-dialog__body {
    margin-bottom 0;
    padding-bottom 10px;
  }

  >>> .el-dialog__footer {
    padding 0
  }

  .mobileShareCodeBtnWrapper {
    position fixed;
    right 0;
    top 55px;
    cursor pointer;
    display flex;
    justify-content center;
    align-items center;
    flex-direction column
    color #2F54EB
    font-weight bold;

    .shareCodeBtn {
      width 54px
    }
  }

  .verticleShareCodeBtnWrapper {
    position fixed;
    right 0;
    top 50%;
    cursor pointer;
    display flex;
    justify-content center;
    align-items center;
    flex-direction column
    color #2F54EB
    font-weight bold;

    .shareCodeBtn {
      transform translateY(-180px)
      width 40px
    }
  }

  .shareCodeBtnWrapper {
    position fixed;
    right 1%;
    top 50%;
    transform translateY(-50%)
    width 160px
    cursor pointer;
    display flex;
    justify-content center;
    align-items center;
    flex-direction column
    color #2F54EB
    font-weight bold;

    .shareCodeBtn {
      width 100%;
      margin-top 58px
      transition all 0.5s ease-in-out
    }

    .shareCodeBtn:first-child {
      margin-top 0
    }

    .shareCodeBtn:hover {
      //transform: rotate(360deg);
      width 100%;
    }
    font-size 16px
  }
}

.mobile-device {
  .content {
    padding: 39px;
    padding-top: 17px;
    padding-bottom: 25px;
  }


  .title {
    height: 24px;
    font-size: 0.3rem;
    text-align: center;
    font-weight: 600;
    color: #000;
    line-height: 24px;
  }

  .text {
    margin-top: 8px;
    font-size: 14px;
    color: #666;
    line-height: 1.5;
    margin-bottom: 24px;
  }

  .tuomin {
    margin-top 17px
    margin-bottom 10px
  }


  display flex;
  justify-content center;
  align-items center;
  flex-direction column

  .form-control {
    position: relative;
    display: flex;
    align-items center

    >>> .van-field__control {
      border: 1px solid #ccc;
      padding: 0 5px;
      margin-right: 5px;
      flex-grow 1
    }

    .unit {
      width: 100px;
      line-height: 24px;
      font-size: 14px;
      color: #646566;
    }

    .van-cell {
      padding 0
    }
  }

  >>> .van-checkbox {
    .van-checkbox__icon--checked .van-icon {
      background rgba(47, 84, 235, 1);
    }


    .van-checkbox__label {
      color: rgba(47, 84, 235, 1);
    }

  }

  .dialog-footer {
    justify-content center
    width 100%
    display flex
    flex-direction column
    justify-content center;
    align-items center
    margin-top 17px

    .textarea {
      font-weight lighter !important
      margin-bottom 30px;
      border 1px solid #eee
      width 100%
      height 50px
    }

    .btn-ok {
      width: 182px;
      height: 40px;
      background: linear-gradient(90deg, #5C8DF7 0%, #2F54EB 100%);
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.13);
      border-radius: 7px;
      color #fff;
    }
  }


  .shareCodeBtn {
    width 54px
  }

}
</style>
