<template>
  <div class="nav">
    <div class="navmain">
      <div class="navtitle">
        <div>
          <a target="_blank" href="http://www.ai-tax.cn/" v-if="!customization">进入官网</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data: function() {
    return {};
  },
  computed: {
    ...mapState(["customization"]),
  },
};
</script>
<style scoped lang="stylus">
.nav {
  position: relative;
  height: 80px;
  line-height: 80px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  .navmain {
    width: 1200px;
    margin: 0 auto;
    img {
      margin-top: 15px;
      height: 50px;
    }
    .navtitle {
      float: right;
      div {
        display: inline-block;
        vertical-align: middle;
        color: #2f54eb;
        a {
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: rgba(255, 255, 255, 1);
        }
      }
    }
  }
}
</style>
