export const veriFiItem = (person) => {
  if (!person.companyName) {
    let limit = {
      code: 0,
      status: 0
    }
    return limit;
  }
  if (!person.nsrsbh) {
    let limit = {
      code: 1,
      status: 0
    }
    return limit;
  }
  if (!person.scale) {
    let limit = {
      code: 2,
      status: 0
    }
    return limit;
  }
  if (!person.area) {
    let limit = {
      code: 3,
      status: 0
    }
    return limit;
  }
  if (!person.kjzd) {
    let limit = {
      code: 4,
      status: 0
    }
    return limit;
  }
  // if (!person.contact) {
  //     let limit = {
  //         code: 5,
  //         status: 0
  //     }
  //     return limit;
  // }
  // if (!person.contactNumber) {
  //     let limit = {
  //         code: 6,
  //         status: 0
  //     }
  //     return limit;
  // }
  let limit = {
    code: 100,
    status: 1
  }
  return limit;
}

// 校验修改客户信息
export const veriFiCustom = (person) => {
  if (!person.companyName) {
    let limit = {
      code: 0,
      status: 0
    }
    return limit;
  }
  if (!person.nsrsbh) {
    let limit = {
      code: 1,
      status: 0
    }
    return limit;
  }
  if (!person.scale) {
    let limit = {
      code: 2,
      status: 0
    }
    return limit;
  }
  if (!person.cityCode) {
    let limit = {
      code: 3,
      status: 0
    }
    return limit;
  }
  if (!person.kjzd) {
    let limit = {
      code: 4,
      status: 0
    }
    return limit;
  }
  let limit = {
    code: 100,
    status: 1
  }
  return limit;
}

// 校验修改客户信息
export const veriFiItemAdd = (person) => {
  if (!person.companyName) {
    let limit = {
      code: 0,
      status: 0
    }
    return limit;
  }
  if (!person.nsrsbh) {
    let limit = {
      code: 1,
      status: 0
    }
    return limit;
  }
  if (!person.scale) {
    let limit = {
      code: 2,
      status: 0
    }
    return limit;
  }
  console.log(person.area)
  if (!person.area) {
    let limit = {
      code: 3,
      status: 0
    }
    return limit;
  }
  console.log(person.kjzd)
  if (!person.kjzd) {
    let limit = {
      code: 4,
      status: 0
    }
    return limit;
  }
  if (!person.industry) {
    let limit = {
      code: 5,
      status: 0
    }
    return limit;
  }

  let limit = {
    code: 100,
    status: 1
  }
  return limit;
}

// 地区转换
export const area = (city, cityData) => {
  if (!city) {
    let info = "暂无地区信息";
    return info;
  }
  let info = {};
  for (var i = 0; i < cityData.length; i++) {
    if (city[0] == cityData[i].value) {

      info.pro = cityData[i].label

      if (cityData[i].children) {
        for (var j = 0; j < cityData[i].children.length; j++) {

          if (city[1] == cityData[i].children[j].value) {

            info.city = cityData[i].children[j].label

            if (cityData[i].children[j].children) {
              for (var k = 0; k < cityData[i].children[j].children.length; k++) {
                if (city[2] == cityData[i].children[j].children[k].value) {
                  info.district = cityData[i].children[j].children[k].label
                }
              }
            } else { //有些地区没有三级
              info.district = info.city
            }

          }

        }
      }else{ //有些地区没有二级
        info.city = info.pro
        info.district = info.pro
      }

    }
  }
  return info.pro + "/" + info.city + "/" + info.district;
}
