<template>
  <!-- <div class="financialRisk-h5-container">
    <div class="PurchaseAndSale-h5">
      <UpDownCustomerNetWork type="2"></UpDownCustomerNetWork>
    </div>
  </div> -->
  <div class="full-sc-1-com">
    <div class="close" @click="close">
      <img src="@/assets/test/part-close.png" alt="" />
    </div>
    <div class="table-wrapper-1">
      <UpDownCustomerNetWork type="2" close="2"></UpDownCustomerNetWork>
    </div>
  </div>
</template>

<script>
import "@/pages/Result/ThreeYearResult/global.styl";
import UpDownCustomerNetWork from "@/pages/Result/H5FinancialAnalysis/UpDownCustomerNetWork";
import UpDownCustomerDistribution from "@/pages/Result/H5FinancialAnalysis/UpDownCustomerDistribution";

import { getInvoiceSection } from "@/api/api";

const moment = require("moment");

export default {
  name: "UpDownCustomer",
  components: {
    UpDownCustomerNetWork,
    UpDownCustomerDistribution,
  },

  props: {
    uuid: [String, Number],
  },

  data() {
    return {
      activeIndex: "1",
      rangeTimes: {
        start: "",
        end: "",
      },
    };
  },
  mounted() {
    this.getRange();
  },
  methods: {
    close() {
      this.$router.back();
    },
    /**
     * 顶部菜单点击
     * @param {string} key
     * @param {array<string>}keyPath
     */
    handleTopMenuSelect(key, keyPath) {
      this.activeIndex = key;
    },
    toCompany() {},
    toCustomer() {},
    getRange() {
      getInvoiceSection(this.uuid).then((res) => {
        if (!res.data) return;
        this.rangeTimes.start = res.data.startDate
          ? moment(res.data.startDate).format("YYYY-MM-DD")
          : "";
        this.rangeTimes.end = res.data.endDate
          ? moment(res.data.endDate).format("YYYY-MM-DD")
          : "";
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.full-sc-1-com {
  position: fixed;
	width: 100vh;
	height: 100vw;
	top: 0;
	left: 100vw;
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
	transform-origin: 0% 0%;
  background: #fff;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  >>> .table .bigPowerTable {
    max-height: 5rem;
    overflow auto
    max-width: 12rem;
  }

  >>> table.table th, >>> table.table td {
    font-size: 0.2rem;
  }

  .close {
    position: absolute;
    top: 10px;
    left: 10px;

    img {
      padding: 10px;
      width: 15px;
      height: 15px;
    }
  }

  .table-wrapper-1 {
    // width: 10rem;
    // height: 5.8rem;
    // overflow: auto;
  }
}
</style>
