// UploadTable

// initial state

const SDSSelection = JSON.parse(sessionStorage.getItem("SDSSelection")) || null;

const state = {
  zq: null, //征期
  tables4correctionData: [], //上传后数据出错需要纠错的表格
  product: {
    name: "",
    id: "",
    bankID: ""
  },
  correctDialogVisible: false, //控制手动修正的表的弹窗
  permissionID: sessionStorage.getItem("permissionID"),
  SDSSelection: SDSSelection, //所得税列表过滤
  zqList: null
};

// mutations
const mutations = {
  setZq(state, { zq }) {
    state.zq = zq;
  },
  setZqList(state, { zq }) {
    state.zqList = zq;
  },
  setCorrectionTableData(state, { tables4correctionData }) {
    state.tables4correctionData = tables4correctionData;
  },
  setProduct(state, { name, id, bankID }) {
    state.product = { name, id, bankID };
  },
  reset() {
    //console.log(state);
  },
  setPermissionID(state, { permissionID }) {
    state.permissionID = permissionID;
    sessionStorage.setItem("permissionID", permissionID);
  },
  setSDSSelection(state, { SDSlist }) {
    state.SDSSelection = SDSlist;
    sessionStorage.setItem("SDSSelection", JSON.stringify(SDSlist));
  },
  setCorrectDialogVisible(state, isVisible) {
    state.correctDialogVisible = isVisible;
  }
};

export default {
  namespaced: true,
  state: { ...state },
  mutations
};
