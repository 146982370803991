<template>
  <div v-if="isloaded" class="dali-style">
    <div class="page-wrapper" :key="actualZq" v-if="actualZq">
      <div class="title-content">
        <bread-crumb>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ name: 'homePage' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>风险检测</el-breadcrumb-item>
          </el-breadcrumb>
        </bread-crumb>
        <div class="mainHeader">
          <main-header>
            <template slot="title" v-if="productName">{{ productName }}</template>
          </main-header>
          <template v-if="uploadMethod == 1 && isTaxAutoProcessing == 1">
            <div
              class="tip"
              v-if="
                getLocalAbilities(this.$route.params.version).includes('报税表检测流程')
              "
            >
              您可以选择
              <button class="btn link" @click="handleSkip">跳过</button>
              此步骤，手动提交报表
            </div>
          </template>
          <div class="text-status" v-if="version != baseVersion"></div>
          <div
            class="baseVersion"
            @click="showBase"
            v-if="version == baseVersion || version == baseVersion21"
          >
            基础信息表单
          </div>
        </div>
      </div>
      <div class="section-box">
        <div v-if="uploadMethod == 2">
          <div class="title fix-title">数据补充</div>
          <div class="tip" v-if="requiredTables != ''">
            为了检测结果更加全面准确，请上传本期和上期的{{ requiredTables }}
          </div>
          <div class="tab-wrapper">
            <div class="uploadMethodText">
              <div
                class="uploadMethodItem"
                v-if="dataPeriod == 0"
                :class="{ zqLocation: dataPeriod == 0 }"
              >
                {{ zaNameValue[0] }}
              </div>
              <div v-if="dataPeriod == 1">
                <router-link
                  :to="{
                    name: 'upload-table',
                    params: { zq, zqLocation, dataPeriod: 0 },
                    query: { deleteDataType: this.deleteDataType },
                  }"
                >
                  <div class="uploadMethodItem">{{ zaNameValue[0] }}</div>
                </router-link>
              </div>
              <div
                class="uploadMethodItem"
                v-if="dataPeriod == 1"
                :class="{ zqLocation: dataPeriod == 1 }"
              >
                {{ zaNameValue[1] }}
              </div>
              <div v-if="dataPeriod == 0">
                <div class="uploadMethodItem" @click="handleBtnNext">
                  {{ zaNameValue[1] }} <span v-if="dataPeriod == 0">建议上传</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="content-body"
          :class="{ nomargin: uploadMethod == 1, noPadding: haveUploads }"
        >
          <div v-if="uploadMethod == 2">
            <div class="fix-flex" v-show="haveUploads == false">
              <div v-if="dataPeriod == 0">{{ zaNameValue[0] }}报表上传</div>
              <div v-if="dataPeriod == 1">{{ zaNameValue[1] }}报表上传</div>
              <div class="fix-flexs">
                <div class="acknowlege" @click="acknowledgeDialogVisible = true">
                  查看上传须知
                </div>
                <a
                  href="//shuian-file.oss-cn-hangzhou.aliyuncs.com/user/%E6%8A%A5%E8%A1%A8%E6%A8%A1%E6%9D%BF.zip"
                  class="btn-download fix-flexs1"
                  download
                  >下载模板</a
                >
              </div>
            </div>

            <div :class="{ 'upload-text': haveUploads }">
              <div
                class="fix-flexsL"
                :class="{
                  'fix-flexsLr': haveUploads == false,
                  'flex-max-widh': haveUploads,
                }"
              >
                <div class="fix-flexs drog_texts" v-if="haveUploads">
                  <div class="acknowlege" @click="acknowledgeDialogVisible = true">
                    查看上传须知
                  </div>
                  <a
                    href="//shuian-file.oss-cn-hangzhou.aliyuncs.com/user/%E6%8A%A5%E8%A1%A8%E6%A8%A1%E6%9D%BF.zip"
                    class="btn-download fix-flexs1"
                    download
                    >下载模板</a
                  >
                </div>
                <uploader
                  @showDetailBox="showDetailBox"
                  :checkHand="haveUploads"
                  @onUpload="onUpload"
                  :uuid="uuid"
                  v-if="actualZq"
                  :tag="version"
                  @changeLength="changeLength"
                  @overUpload="overUpload"
                ></uploader>
              </div>

              <table-list
                v-show="haveUploads"
                :tables="tables"
                @listChanged="listChanged"
                manner="upload"
                class="table-list fix-flexsR"
                :class="{ 'table-lists': haveUploads }"
                @showDetailBalance="showDetailBalance"
              ></table-list>
            </div>
          </div>

          <template v-if="uploadMethod == 1">
            <!-- <report-method
              :companyInfo="$store.state.companyInfo"
              @openlicense="openlicense"
              @setName="changeName"
              class="report-method"
              v-if="!isTaxAutoProcessing"
              ref="showReport"
              @skip="handleSkip"
              :thirdDataSource="thirdDataSource"
              :zq="zq"
              :uuid="uuid"
            ></report-method> -->
            <div v-if="isTaxAutoProcessing == 1">
              <iframe
                @skip="handleSkip"
                class="fetchBaseUrl"
                :src="fetchBaseUrl"
              ></iframe>
            </div>
            <div v-else>
              <div class="data-loading-wrapper">
                <img src="@/assets/test/data-loading.png" style="width: 465px" />
                <div v-if="isTaxAutoProcessing == 2" class="text">当前项目已授权，点击下一步</div>
                <div v-if="isTaxAutoProcessing == 3" class="text">当前税号已授权</div>
              </div>
            </div>
          </template>

          <sa-footer>
            <div class="footer">
              <template v-if="uploadMethod == 2">
                <button @click="handleBtnNext2" class="btn btn-primary btn-next">
                  提交
                </button>
              </template>
              <template v-if="isTaxAutoProcessing == 3 && uploadMethod == 1">
                <button @click="handleBtnNextHomePage" class="btn btn-primary btn-next">
                  返回首页
                </button>
              </template>
              <template v-if="isTaxAutoProcessing == 2 && uploadMethod == 1">
                <button @click="switchToManualUploader" class="btn btn-primary btn-next">
                  下一步
                </button>
              </template>
            </div>
          </sa-footer>
        </div>
      </div>
      <!--上传错误提示弹出框-->
      <el-dialog
        :visible.sync="correctDialogVisible"
        width="80%"
        :show-close="true"
        class="correctDialog"
        top="1vh"
        @close="handleCorrectDialogClose"
      >
        <SectionTitle>报表匹配</SectionTitle>
        <div class="modify-table-wrapper">
          <modify-table></modify-table>
        </div>
      </el-dialog>
      <!--/上传错误提示弹出框-->
      <!--纠错弹出框-->
      <el-dialog
        :visible.sync="uploadErrorDialogVisible"
        width="800px"
        :show-close="true"
        top="25vh"
        title="抱歉,以下表无法解析"
      >
        <el-table :data="uploadErrors" style="width: 100%">
          <el-table-column prop="userFileName" label="表名"></el-table-column>
          <el-table-column prop="reason" label="异常原因"></el-table-column>
        </el-table>
      </el-dialog>
      <!--/纠错弹出框-->
      <!--上传须知提示弹出框-->
      <el-dialog
        :visible.sync="acknowledgeDialogVisible"
        width="62.5%"
        :show-close="true"
        class="correctDialog"
      >
        <SectionTitle>上传须知</SectionTitle>
        <div class="modify-table-wrapper">
          <acknowledage-tab
            v-on:acknowledgeDialogVisible="clickbtn"
            :bankId="bankId"
          ></acknowledage-tab>
        </div>
      </el-dialog>
      <!--/上传须知提示弹出框-->

      <!--数据上传组件-->
      <TestDataUploader
        ref="testDataUploader"
        :bankId="bankId"
        :productID="productID"
        :productName="productName"
        :uuid="uuid"
        :zq="zq"
        :version="version"
        :deleteDataType="deleteDataType"
      ></TestDataUploader>
      <!--/数据上传组件-->

      <loading
        :active.sync="isLoading"
        backgroundColor="#fff"
        :can-cancel="true"
        :is-full-page="fullPage"
        loader="Dots"
        color="#2f54eb"
      ></loading>

      <!--所得税填报选择-->
      <el-dialog
        :visible.sync="showSDSDialog"
        :close-on-click-modal="false"
        width="800px"
        :show-close="false"
        class="correctDialog"
      >
        <SectionTitle>所得税填报选择</SectionTitle>
        <SDSFilter
          :tables="SDStables"
          @onDialogSubmit="handleSDSDialogSubmit"
        ></SDSFilter>
      </el-dialog>
      <!--/所得税填报选择-->

      <!-- 基础信息表 -->
      <base-info
        :zq="zq"
        :uuid="uuid"
        @closeBase="closeBase"
        :nsrsbm="this.$store.state.companyInfo.nsrsbm"
        v-if="dialogVisible"
        @cancelBaseinfo="cancelBaseinfo"
        :dialogVisible="dialogVisible"
      ></base-info>
      <!-- 基础信息表 -->
      <licen ref="opendialog"></licen>
      <div v-if="showTables" class="containertables">
        <div class="tables">
          <div class="title">{{ balanceName }}</div>
          <div class="closes" @click="deleteTable">×</div>
          <div class="content">
            <el-table
              :data="tableData"
              style="width: 100%"
              height="620"
              row-key="subBsDm"
              :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            >
              <el-table-column
                prop="subBsDm"
                label="科目编码"
                width="160"
              ></el-table-column>
              <el-table-column
                prop="subBsMc"
                label="科目名称"
                width="150"
              ></el-table-column>
              <el-table-column label="年初余额" align="center">
                <el-table-column
                  prop="ncBorrow"
                  label="借方"
                  align="right"
                  width="140"
                ></el-table-column>
                <el-table-column
                  prop="ncLoan"
                  label="贷方"
                  align="right"
                  width="140"
                ></el-table-column>
              </el-table-column>
              <el-table-column label="期初余额" align="center">
                <el-table-column
                  prop="beforeBorrow"
                  label="借方"
                  align="right"
                  width="140"
                ></el-table-column>
                <el-table-column
                  prop="beforeLoan"
                  label="贷方"
                  align="right"
                  width="140"
                ></el-table-column>
              </el-table-column>
              <el-table-column label="本期发生额" align="center">
                <el-table-column
                  prop="currentBorrow"
                  label="借方"
                  align="right"
                  width="140"
                ></el-table-column>
                <el-table-column
                  prop="currentLoan"
                  label="贷方"
                  align="right"
                  width="140"
                ></el-table-column>
              </el-table-column>
              <el-table-column label="本年累计" align="center">
                <el-table-column
                  prop="bnljBorrow"
                  label="借方"
                  align="right"
                  width="140"
                ></el-table-column>
                <el-table-column
                  prop="bnljLoan"
                  label="贷方"
                  align="right"
                  width="140"
                ></el-table-column>
              </el-table-column>
              <el-table-column label="期末余额" align="center">
                <el-table-column
                  prop="afterBorrow"
                  label="借方"
                  align="right"
                  width="140"
                ></el-table-column>
                <el-table-column
                  prop="afterLoan"
                  label="贷方"
                  align="right"
                  width="140"
                ></el-table-column>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div class="progressUpload" v-if="progressUpload">
        <div class="progressBox">
          <img
            class="undraw_upload"
            src="@/assets/result/undraw_upload_87y9@2x.png"
            alt
          />
          <el-progress
            type="circle"
            :percentage="progressUploadStatus"
            :width="286"
            :status="processTexted ? 'success' : null"
          ></el-progress>
          <div class="processText" v-if="processTexting">上传中...</div>
          <div class="processTexted processTextedNo" v-if="processTexted">上传成功</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import {
  getInfoByCompanyNsrsbm,
  useAuthorize,
  checkCompany,
  checkNew,
  breezeOuter,
  showBalanceDetail,
  genTestReport,
  addReportValue,
  isFindNeedExcel,
  findFinalSettlementCondition,
  findTaxAutoStatusByUuid,
  getTaxAreaBureauServiceInfo,
  isFindNeedExcel2,
  isFindNeedExcel3,
  //
  getAuthStatus,
} from "@/api/api";
import { loadTableData } from "@/api/table";
import { cloneDeep } from "lodash";
import BreadCrumb from "@/components/BreadCrumb";
import Uploader from "./components/Uploader";
import TableList from "./components/TableList";
import toTip from "./components/totip";
import ModifyTable from "./components/ModifyTable";
import SectionTitle from "@/components/SectionTitle";
import AcknowledageTab from "./components/AcknowledageTab";
import SDSFilter from "./components/SDSFilter";
import textStatus from "./components/text-status";
import MainHeader from "@/pages/components/MainHeader";
import ProgressDiagram from "@/pages/components/ProgressDiagram";
import Footer from "../ChooseTable/components/Footer";
import TestDataUploader from "../Components/TestDataUploader";
import { getUserTables, clearUploadedTableforTest } from "@/api/table";
import { zq2name, previousZq, zq2obj } from "@/utils/helper";
import reportMethod from "../../Test/ChooseTable/components/report-method";
import licen from "./components/licen-dialog";

import baseInfo from "./components/baseInfo";

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import { getLocalAbilities } from "@/utils/abilities";

export default {
  data() {
    return {
      thirdDataSource: "", //山东取数的方式
      requiredTables: [],
      isAutoDataNum: 0,
      zjhm: "",
      czydm: "",
      zaNameValue: [],
      nameListArr: null,
      nameInfoList: "",
      identityValue: "",
      jsmobileCode: "",
      jscookies: "",
      jsinfo: "",
      dialogVisible: false,
      isloaded: false,
      processTexting: true,
      processTexted: false,
      progressUpload: false,
      progressUploadStatus: "0",
      tables: {},
      fullPage: true,
      isLoading: false,
      acknowledgeDialogVisible: false,
      uploadErrorDialogVisible: false, //控制无法识别的表的弹窗
      uploadErrors: [], //错误的表名及原因
      hasItemsInList: false,
      actualZq: 0,
      zqs: [],
      showSDSDialog: false,
      uploadMethod: sessionStorage.getItem("method") || 1,
      code: 9996,
      index: -1,
      step2Msg: null,
      valueMsg: "",
      area: "",
      bizNo: "",
      radioIndex: "",
      passwordInput: "",
      clickQr: "",
      index1Data: "",
      indexJylsh: "",
      mobile: "",
      mobileCode: "",
      haveUploads: false,
      showTables: false,
      balanceName: "",
      tableData: [],
      taxSiteUsername: this.$store.state.companyInfo.nsrsbh || "",
      batchNo: "",
      isEnableAutoGetData: true, // 汇算清缴是否已经结束
      isTaxAutoProcessing: 1, //自动取数进行中
      isVersionList: [9, 11, 15],
      baseVersion: 12, //房地产tag 需要基础信息表
      baseVersion21: 21, //房地产tag 需要基础信息表
      checkBaseInfoData: "",
      deleteDataType: this.$route.query.deleteDataType,
      //
      fetchBaseUrl: "",
      postMsg: 100,
      postMsgToUpload: 100,
      addreportAndCheck: false,
    };
  },
  components: {
    Loading,
    BreadCrumb,
    Uploader,
    TableList,
    SectionTitle,
    ModifyTable,
    AcknowledageTab,
    MainHeader,
    ProgressDiagram,
    saFooter: Footer,
    TestDataUploader,
    SDSFilter,
    reportMethod,
    licen,
    textStatus,
    toTip,
    baseInfo,
  },
  props: {
    zq: [Number, String],
    productName: String,
    productID: [Number, String],
    zqLocation: [Number, String],
    bankId: String,
    uuid: String,
    dataPeriod: [Number, String],
    version: [Number, String],
    isShanDong: [Number, String],
  },
  computed: {
    ...mapState(["companyInfo", "authorization"]),
    ...mapState("UploadTable", ["permissionID", "SDSSelection"]),

    correctDialogVisible: {
      get: function () {
        return this.$store.state.UploadTable.correctDialogVisible;
      },
      set: function (value) {
        this.setCorrectDialogVisible(value);
      },
    },
    zqName: function () {
      return zq2name(this.actualZq);
    },
    progress: function () {
      let ret = 2;
      if (this.dataPeriod == 0) {
        ret = 2;
      } else if (this.dataPeriod == 1) {
        ret = 3;
      }
      return ret;
    },
    SDStables() {
      let ret = [];
      if (this.tables.所得税表) {
        ret = this.tables.所得税表.list;
      }
      return ret;
    },
    tablesList() {
      const ret = [];
      if (!this.tables) {
        return ret;
      }

      for (const tableGrp of Object.values(this.tables)) {
        for (const table of tableGrp.list) {
          ret.push(table);
        }
      }
      return ret;
    },
  },

  async mounted() {
    this.isloaded = false;
    localStorage.removeItem("policy");
    const nsrsbm = JSON.parse(localStorage.getItem("user")).nsrsbm;

    // this.isTaxAutoProcessing = false;

    await this.init();
    await this.showAutoOrManual();
    this.zqNameValue();
    //是否已经完成汇算清缴
    await findFinalSettlementCondition(this.zq).then((res) => {
      this.isEnableAutoGetData = res.data;
      if (!this.isEnableAutoGetData) {
        this.tipsFornoAutoGetData();
      }
    });

    this.isloaded = true;

    // 判断是否需要补全信息
    if (this.uploadMethod == 2) {
      this.checkcompany();
    }
    if (!sessionStorage.getItem("isShowBase")) {
      this.showBaseInfo();
    }

    const method = sessionStorage.getItem("method");
    if (method == 2) {
      this.switchToManualUploader();
    }
    // iframe跳转方法
    this.iframeListener();
    this.fetchUrl();
  },
  methods: {
    //
    fetchUrl() {
      let baseEnv = process.env.VUE_APP_PDF_ROOT_FETCH;
      let data = {
        companyName: this.companyInfo.companyName,
        companyNo: this.companyInfo.nsrsbm,
        companyTaxNo: this.companyInfo.nsrsbh,
        hydm: this.companyInfo.hy,
        preInspectId: this.uuid,
      };
      this.fetchBaseUrl =
        baseEnv +
        "oemV2/" +
        this.zq +
        "/auth?version=2&info=" +
        JSON.stringify(data) +
        "&tag=" +
        this.version;
    },
    //
    iframeListener() {
      window.addEventListener("message", (e) => {
        if (e.data == "v2-submit-success") {
          this.postMsg = 101;
          // if(getLocalAbilities(this.$route.params.version).includes('报税表检测流程')) {
          //   this.handleBtnNext21();
          // } else {
          //   this.switchToManualUploader();
          // }
        }
        if (e.data == "upload-submit-success") {
          this.postMsgToUpload = 101;
          // this.handleSkip();
        }
      });
    },
    //
    clearBrowserNavigatorHistory() {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", function () {
        history.pushState(null, null, document.URL);
      });
    },
    async loadRequiredTables() {
      const res = await isFindNeedExcel3(
        this.uuid,
        this.zq,
        this.version,
        this.$store.state.companyInfo.nsrsbm,
        this.$store.state.companyInfo.nsrsbh
      );
      this.requiredTables = res.data;
    },
    getLocalAbilities(tag) {
      return getLocalAbilities(tag);
    },
    handleSkip() {
      // 体验版 报税版 有一个跳过自动直接到手动的按钮
      // 跳过就不走授权，直接走addreport和check
      this.addreportAndCheck = true;
      this.switchToManualUploader();
    },
    //显示自动取数还是手动上传
    async showAutoOrManual() {
      // 如果是匿名 只有手动
      if (sessionStorage.getItem("isAnonymous") === "true") {
        this.uploadMethod = 2; // 2手动 1自动
        sessionStorage.setItem("method", this.uploadMethod);
        this.haveUploads = true;
        return;
      }

      //如果税局未对接则手动填写报表
      const res = await getTaxAreaBureauServiceInfo({
        taxNo: this.$store.state.companyInfo.nsrsbh,
      });

      // 如果未对接税局 只能走手动
      if (!res.data.available) {
        this.uploadMethod = 2; // 2手动 1自动
        sessionStorage.setItem("method", this.uploadMethod);
        this.haveUploads = true;
      } else {
        this.uploadMethod = 1;
      }

      this.thirdDataSource = res.data.thirdDataSource;
      // this.thirdDataSource = "DB_KUANGU";
    },
    zqNameValue() {
      let zaNameValue = [];
      let zqLast = this.zqLocation.substr(this.zqLocation.length - 1, 1);

      if (zqLast == 1) {
        let nextMonth = "";
        let nextYear = "";
        if (this.zqLocation.slice(4, 6) == "01") {
          nextMonth = "12";
          nextYear = Number(this.zqLocation.slice(0, 4)) - 1;
        } else {
          nextMonth = (Number(this.zqLocation.slice(4, 6)) - 1).toString();
          nextYear = this.zqLocation.slice(0, 4);
        }
        if (nextMonth.length == 1) {
          nextMonth = "0" + nextMonth;
        }
        zaNameValue[0] =
          this.zqLocation.slice(0, 4) + "年" + this.zqLocation.slice(4, 6) + "月";
        zaNameValue[1] = nextYear + "年" + nextMonth + "月";
        this.zaNameValue = zaNameValue;
      }

      if (zqLast == 4) {
        let nextYear = this.zqLocation.slice(0, 4);
        zaNameValue[0] = this.zqLocation.slice(0, 4) + "年";
        zaNameValue[1] = Number(nextYear) - 1 + "年";
        this.zaNameValue = zaNameValue;
      }

      if (zqLast == 2) {
        let year = this.zq.slice(0, 4);
        let quer = this.zq.slice(5, 6);
        let nextQuer;
        let nextYear;
        if (quer == 1) {
          nextYear = year - 1;
          nextQuer = 4;
        } else {
          nextYear = year;
          nextQuer = quer - 1;
        }

        zaNameValue[0] = year + "年" + quer + "季度";
        zaNameValue[1] = nextYear + "年" + nextQuer + "季度";
        this.zaNameValue = zaNameValue;
      }
    },

    closeBase() {
      sessionStorage.setItem("isShowBase", 100);
      this.dialogVisible = false;
    },

    showBase() {
      this.dialogVisible = true;
    },

    cancelBaseinfo() {
      sessionStorage.setItem("isShowBase", 100);
      this.dialogVisible = false;
    },

    showBaseInfo() {
      if (this.version == this.baseVersion || this.version == this.baseVersion21) {
        this.dialogVisible = true;
      }
    },

    ...mapMutations("UploadTable", ["setCorrectDialogVisible"]),
    //不能自动取数的提示
    tipsFornoAutoGetData() {
      if (this.uploadMethod == 1 && !this.isTaxAutoProcessing) {
        this.$confirm(
          this.zaNameValue[0] +
            "汇算清缴未结束，自动取数提取数据有限，建议您使用手动上传",
          "提示",
          {
            confirmButtonText: "切换手动",
            cancelButtonText: "知道了",
            type: "warning",
          }
        ).then(() => {
          this.switchToManualUploader();
          //this.uploadMethod=2;
        });
      }
    },
    overUpload() {
      this.progressUploadStatus = 100;
      this.processTexted = true;
      this.processTexting = false;
      setTimeout(() => {
        this.progressUpload = false;
        this.processTexted = false;
        this.processTexting = true;
      }, 1000);
    },

    changeLength(val) {
      this.progressUpload = true;
      this.progressUploadStatus = Number(((val.status / val.length) * 100).toFixed(2));
      if (this.progressUploadStatus >= 100) {
        this.progressUploadStatus = 100;
      }
    },
    // 查看余额表
    showDetailBalance(id, zq) {
      let token = JSON.parse(localStorage.getItem("token")).token;
      loadTableData(zq, id, token, this.uuid).then((res) => {
        if (res.msgCode == -1) {
          this.$message({
            message: res.msgInfo,
            type: "warning",
          });
          return;
        }
        this.tableData = res.data.dataJson;
      });
      this.balanceName = this.zaNameValue[this.dataPeriod] + "余额表";
      this.detailZq = zq;
      this.showTables = true;
    },
    // 关闭查看余额表
    deleteTable() {
      this.showTables = false;
    },

    showDetailBox() {
      this.haveUploads = true;
    },

    // 打开授权协议
    openlicense() {
      this.$refs.opendialog.openDialog();
    },
    //根据会计制度获取不同的报表弹窗
    toast() {
      if (this.dataPeriod == 0 && this.uploadMethod == 2) {
        this.showSDSFilterDialog();
      }
    },

    async deleteCache() {
      this.$store.cache.delete("loadCompanyRenzheng"); //删除认证缓存
      this.$store.cache.delete("loadCompanyInfo"); //删除企业信息
      await this.$store.cache.dispatch("loadCompanyRenzheng");
      await this.$store.cache.dispatch("loadCompanyInfo");
      await this.loadList();
      await this.toast();
    },
    // 判断是否需要补全信息，如果是返回1，走checkAmount
    checkcompany() {
      this.checkAmount(1);
    },
    // 如果不需要补全信息，则请求表格接口，然后显示表格信息
    async checkAmount(res) {
      if (res == 1) {
        this.uploadMethod = 2;
        sessionStorage.setItem("method", this.uploadMethod);
        if (this.dataPeriod == 0 && this.uploadMethod == 2) {
          await this.loadList();
          await this.showSDSFilterDialog();
        }
      }
    },
    switchToManualUploader() {
      this.clearBrowserNavigatorHistory();
      this.loadRequiredTables();
      this.uploadMethod = 2;
      this.haveUploads = true;
      sessionStorage.setItem("method", this.uploadMethod);
    },
    // 切换上传方式
    async editUser() {
      // 如果初始化默认是自动取数，切换的时候则判断是否需要补全信息，补全之后再切换为手动方式
      if (this.uploadMethod == 1) {
        await this.checkcompany();
      } else {
        this.uploadMethod == 1 ? (this.uploadMethod = 2) : (this.uploadMethod = 1);
        sessionStorage.setItem("method", this.uploadMethod);
      }
    },
    handleShowAllSDSTablesBtnClick() {
      this.$store.commit("UploadTable/setSDSSelection", {
        SDSlist: this.tables.所得税表.list,
      });
    },
    handleSDSDialogSubmit() {
      this.showSDSDialog = false;
    },
    checkIfAllFiltedTableFilled() {
      //把所有列表展平
      const tables = [];
      for (const key in this.tables) {
        if (this.tables.hasOwnProperty(key)) {
          const tablesInkey = this.tables[key].list;
          for (const table of tablesInkey) {
            tables.push(table);
          }
        }
      }
      return this.SDSSelection.every((aSDSSelection) => {
        const templateId = aSDSSelection.templateId;
        const found = tables.find((table) => {
          return templateId == table.templateId && table.status != "未上传";
        });
        return !!found;
      });
    },
    //
    handleBtnNextHomePage() {
      console.log(123);
      this.$router.push({ name: "homePage" });
    },
    //
    async checkBaseToNext() {
      //如果自动取数进行中 直接进入下一步
      if (this.isTaxAutoProcessing && this.uploadMethod == 1) {
        await this.switchToManualUploader(); // 切换上传方式
        return false;
      }

      // 如果是报税版流程 则立刻生成报告
      let appid_3rd = localStorage.getItem("appid_3rd") || "";
      if (appid_3rd == "null") {
        appid_3rd = "";
      }
      //
      if (
        this.uploadMethod == 1 &&
        getLocalAbilities(this.$route.params.version).includes("报税表检测流程")
      ) {
        localStorage.setItem("taxSiteUsername", this.taxSiteUsername);
        // 新的check接口
        let params = {
          token: this.$store.state.authorization,
          appId: appid_3rd,
          nsrsbh: this.$store.state.companyInfo.nsrsbm,
          tag: this.version,
          uuid: this.uuid,
          zq: this.zq,
          invoiceNsrsbh: this.taxSiteUsername,
          getDataWay: sessionStorage.getItem("method") == 2 ? 1 : 2,
          deleteDataType: this.deleteDataType,
          nameless: sessionStorage.getItem("isAnonymous") === "true",
          taxRateCalculation: this.$route.query.taxRateCalculation,
        };
        genTestReport(params).then((res) => {
          if (res.msgCode == 1) {
            this.$router.push({
              name: "test-InvoiceUpload",
            });
          } else {
            //如果遇到"该服务暂不可用"就弹出错误信息并返回首页
            this.$alert(res.msgInfo, "错误", {
              confirmButtonText: "确定",
              type: "error",
              callback: (action) => {
                this.$router.push({ name: "homePage" });
              },
            });
          }
        });
        return;
      }
      // 其他情况,自动跳手动
      if (
        this.uploadMethod == 1 &&
        this.isVersionList.indexOf(Number(this.version)) == -1
      ) {
        // this.useauthorize(() => {
        localStorage.setItem("taxSiteUsername", this.taxSiteUsername);
        this.switchToManualUploader();
        // });
        return;
      }

      //跳到下一期
      this.$router.push({
        name: "upload-table",
        params: { zq: this.zq, zqLocation: this.zqLocation, dataPeriod: 1 },
        query: { deleteDataType: this.deleteDataType },
      });
    },
    //自动取数上一步
    handlePreviousClick() {
      const params = {
        productID: this.productID,
        // bankId: this.bankId,
        productName: this.productName,
        uuid: this.uuid,
        version: this.version,
        isShanDong: this.isShanDong,
      };
      this.$router.push({
        name: "choose-table",
        params,
      });
    },
    //本期的下一步按钮
    handleBtnNext() {
      if (this.version == this.baseVersion || this.version == this.baseVersion21) {
        let params = {
          nsrsbm: this.$store.state.companyInfo.nsrsbm,
        };
        getInfoByCompanyNsrsbm(params).then((res) => {
          this.checkBaseInfoData = res.data;
          // 判断是不是房地产版本
          if (!this.checkBaseInfoData || !this.checkBaseInfoData.projectName) {
            this.dialogVisible = true;
          } else {
            this.checkBaseToNext();
          }
        });
      } else {
        this.checkBaseToNext();
      }
    },
    //提交按钮
    async handleBtnNext2() {
      // 如果是房地产版本
      if (this.version == this.baseVersion || this.version == this.baseVersion21) {
        let params = {
          nsrsbm: this.$store.state.companyInfo.nsrsbm,
        };
        getInfoByCompanyNsrsbm(params).then((res) => {
          this.checkBaseInfoData = res.data;
          // 判断是不是房地产版本
          if (!this.checkBaseInfoData || !this.checkBaseInfoData.projectName) {
            this.dialogVisible = true;
            return;
          } else {
            this.addreportAndChecks();
          }
        });
      } else {
        this.addreportAndChecks();
      }
      //
    },

    // 提交
    async addreportAndChecks() {
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      //报税版和体验版匿名可以手动上传
      //如果是手动上传,最少需上传本期与上期财务表
      if (this.uploadMethod == 2) {
        const res = await isFindNeedExcel2(
          this.uuid,
          this.zq,
          this.version,
          this.$store.state.companyInfo.nsrsbm,
          this.$store.state.companyInfo.nsrsbh
        );
        if (res.msgCode != 1) {
          this.loading.close();
          try {
            await this.$confirm(res.msgInfo, "\n" + "温馨提示", {
              confirmButtonText: "跳过",
              cancelButtonText: "补充数据",
            });
          } catch (e) {
            return;
          }
        }
      }

      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      let params = {
        nsrsbh: this.$store.state.companyInfo.nsrsbm,
        zq: this.zq,
        uuid: this.uuid,
      };
      addReportValue(params).then((res) => {
        if (res.code != 200) {
          this.loading.close();
          this.$message.error(res.message);
          return;
        }
      });
      localStorage.setItem("taxSiteUsername", this.$store.state.companyInfo.nsrsbh);

      //
      let appid_3rd = localStorage.getItem("appid_3rd") || "";
      if (appid_3rd == "null") {
        appid_3rd = "";
      }
      //

      // 新的check接口
      const params2 = {
        token: this.$store.state.authorization,
        appId: appid_3rd,
        nsrsbh: this.$store.state.companyInfo.nsrsbm,
        tag: this.version,
        uuid: this.uuid,
        zq: this.zq,
        invoiceNsrsbh: this.$store.state.companyInfo.nsrsbh,
        getDataWay: sessionStorage.getItem("method") == 2 ? 1 : 2,
        deleteDataType: this.deleteDataType,
        nameless: sessionStorage.getItem("isAnonymous") === "true",
        taxRateCalculation: this.$route.query.taxRateCalculation,
      };
      genTestReport(params2).then((res) => {
        this.loading.close();
        if (res.msgCode == 1) {
          this.$router.push({
            name: "test-InvoiceUpload",
          });
        } else {
          //如果遇到"该服务暂不可用"就弹出错误信息并返回首页
          this.$alert(res.msgInfo, "错误", {
            confirmButtonText: "确定",
            type: "error",
            callback: (action) => {
              this.$router.push({ name: "homePage" });
            },
          });
        }
      });
    },

    // 报税版体验版提交按钮
    async handleBtnNext21() {
      //如果是手动上传,最少需上传本期与上期财务表
      if (this.uploadMethod == 2) {
        const res = await isFindNeedExcel2(
          this.uuid,
          this.zq,
          this.version,
          this.$store.state.companyInfo.nsrsbm,
          this.$store.state.companyInfo.nsrsbh
        );
        if (res.msgCode != 1) {
          try {
            await this.$confirm(res.msgInfo, "\n" + "温馨提示", {
              confirmButtonText: "跳过",
              cancelButtonText: "补充数据",
            });
          } catch (e) {
            return;
          }
        }
      }
      // 报税版/体验版直接跳手动  this.addreportAndCheck
      // 匿名提交 isAnonymous
      // 未对接税局 this.haveUploads
      if (
        this.addreportAndCheck ||
        sessionStorage.getItem("isAnonymous") === "true" ||
        this.haveUploads
      ) {
        let params = {
          nsrsbh: this.$store.state.companyInfo.nsrsbm,
          zq: this.zq,
          uuid: this.uuid,
        };
        addReportValue(params).then((res) => {
          if (res.code != 200) {
            this.$message.error(res.message);
            return;
          }
        });
        localStorage.setItem("taxSiteUsername", this.$store.state.companyInfo.nsrsbh);

        //
        let appid_3rd = localStorage.getItem("appid_3rd") || "";
        if (appid_3rd == "null") {
          appid_3rd = "";
        }
        //

        // 新的check接口
        const params2 = {
          token: this.$store.state.authorization,
          appId: appid_3rd,
          nsrsbh: this.$store.state.companyInfo.nsrsbm,
          tag: this.version,
          uuid: this.uuid,
          zq: this.zq,
          invoiceNsrsbh: this.$store.state.companyInfo.nsrsbh,
          getDataWay: sessionStorage.getItem("method") == 2 ? 1 : 2,
          deleteDataType: this.deleteDataType,
          nameless: sessionStorage.getItem("isAnonymous") === "true",
          taxRateCalculation: this.$route.query.taxRateCalculation,
        };
        genTestReport(params2).then((res) => {
          if (res.msgCode == 1) {
            this.$router.push({
              name: "test-InvoiceUpload",
            });
          } else {
            //如果遇到"该服务暂不可用"就弹出错误信息并返回首页
            this.$alert(res.msgInfo, "错误", {
              confirmButtonText: "确定",
              type: "error",
              callback: (action) => {
                this.$router.push({ name: "homePage" });
              },
            });
          }
        });
      } else {
        let params = {
          nsrsbh: this.$store.state.companyInfo.nsrsbm,
          zq: this.zq,
          uuid: this.uuid,
        };
        addReportValue(params).then((res) => {
          if (res.code != 200) {
            this.$message.error(res.message);
            return;
          }
        });
        localStorage.setItem("taxSiteUsername", this.$store.state.companyInfo.nsrsbh);

        //
        let appid_3rd = localStorage.getItem("appid_3rd") || "";
        if (appid_3rd == "null") {
          appid_3rd = "";
        }
        //

        // 新的check接口
        const params2 = {
          token: this.$store.state.authorization,
          appId: appid_3rd,
          nsrsbh: this.$store.state.companyInfo.nsrsbm,
          tag: this.version,
          uuid: this.uuid,
          zq: this.zq,
          invoiceNsrsbh: this.$store.state.companyInfo.nsrsbh,
          getDataWay: sessionStorage.getItem("method") == 2 ? 1 : 2,
          deleteDataType: this.deleteDataType,
          nameless: sessionStorage.getItem("isAnonymous") === "true",
          taxRateCalculation: this.$route.query.taxRateCalculation,
        };
        genTestReport(params2).then((res) => {
          if (res.msgCode == 1) {
            this.$router.push({
              name: "test-InvoiceUpload",
            });
          } else {
            //如果遇到"该服务暂不可用"就弹出错误信息并返回首页
            this.$alert(res.msgInfo, "错误", {
              confirmButtonText: "确定",
              type: "error",
              callback: (action) => {
                this.$router.push({ name: "homePage" });
              },
            });
          }
        });
      }
    },

    async init() {
      //如果没有permission id就退出
      if (!this.$store.state.UploadTable.permissionID) {
        this.$router.push({ name: "homePage" });
      }

      this.isLoading = true;

      if (this.dataPeriod == 1) {
        //上个征期
        this.actualZq = previousZq(this.zq);
        this.zqs.push(this.zq);
        this.zqs.push(this.actualZq);
      } else if (this.dataPeriod == 0) {
        //当前征期
        this.actualZq = this.zq;
      }

      this.$store.commit("UploadTable/setZq", { zq: this.actualZq });
      this.$store.commit("UploadTable/setZqList", { zq: this.zq });

      await this.$store.cache.dispatch("loadCompanyInfo");
      await this.loadList();
      this.$store.commit("UploadTable/setProduct", {
        name: this.productName,
        id: this.productID,
        bankID: this.bankId,
      });

      //
      // 是否自动取数中
      if (this.uploadMethod == 1) {
        getAuthStatus(this.$store.state.companyInfo.nsrsbh, this.uuid).then((res) => {
          if (res.code == 200) {
            this.isTaxAutoProcessing = res.data.authorized;
          }
        });
      }

      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    },

    clickbtn() {
      this.acknowledgeDialogVisible = false;
    },
    listChanged() {
      this.loadList();
    },
    //清空所有已经上传的报表
    handleBtnPurgeDataClicker() {
      this.$confirm(`所有文件将被删除, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const result = await clearUploadedTableforTest(
          this.companyInfo.nsrsbm,
          this.actualZq,
          this.authorization,
          this.uuid
        );

        if (result.code == 200) {
          this.$emit("listChanged");
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.loadList();
        } else {
          this.$message.error(result.message);
        }
      });
    },
    //修正表格的弹出框
    showCorrectDialog() {
      this.correctDialogVisible = true;
    },
    //错误原因弹出框
    showUploadErrorDialog() {
      this.uploadErrorDialogVisible = true;
    },
    submitData() {
      this.correctDialogVisible = true;
    },
    async loadList() {
      //bankId, qykjzd, nsrsbh, zq
      const tables = await getUserTables(
        this.bankId,
        this.version,
        this.companyInfo.nsrsbm,
        this.actualZq,
        this.uuid,
        false
      );

      // 如果有上传的数据就展开表格

      if (tables.data && tables.msgCode != -1) {
        for (let i in tables.data) {
          // console.log(i);
          if (tables.data[i].uploadNum > 0) {
            this.haveUploads = true;
          }
        }
      }

      // 报税版隐藏余额表
      if (this.version == 3 && tables.data) {
        for (let i in tables.data) {
          if (i == "余额表") {
            delete tables.data[i];
          }
        }
      }

      //过滤未上传的表格
      this.tables = {};
      let i = 0;
      for (const grpName in tables.data) {
        i++;
        const grpData = tables.data[grpName];
        const newGrp = [];

        for (const table of grpData.tableInfo) {
          i++;
          //if (table.uploadStatus != "未上传") {
          const {
            tableNameCn,
            tableNameEn: templateId,
            uploadStatus: status,
            tableNum,
            zq,
            children,
          } = table;

          //整理一下children的格式
          const thechildren = children.map((child) => {
            i++;
            const {
              tableNameCn,
              tableNameEn: templateId,
              uploadStatus: status,
              tableNum,
              zq,
              children,
            } = child;
            return {
              name: `${tableNameCn}`,
              status,
              zq,
              templateId,
              tableNum,
              children,
              id: i,
            };
          });
          i++;
          const item = {
            name: `${tableNameCn}`,
            status,
            zq,
            templateId,
            tableNum,
            id: i,
            children: thechildren,
          };

          newGrp.push(item);
          //}
        }

        if (newGrp.length > 0) {
          this.tables[grpName] = {
            list: newGrp,
            totalNum: grpData.totalNum,
            uploadNum: grpData.uploadNum,
          };
        }
      }

      if (Object.keys(this.tables).length > 0) {
        this.hasItemsInList = true;
      } else {
        this.hasItemsInList = false;
      }

      // 是否已经上传必要表格
      // isFindNeedExcel(this.uuid, this.zq).then((res) => {
      //   this.isRequiredTableUploaded = res.data;
      // });
    },
    async onUpload(file, data) {
      //如果有错误的表格则弹出弹出框
      if (data.data.errorData.length > 0) {
        this.uploadErrors = data.data.errorData;
        this.showUploadErrorDialog();
      }

      const groups = data.data.succData;

      //根据上传的excel构建本地对应的数组

      //上传文件后需要纠错的表格信息
      const tables4correction = [];

      for (const groupName in groups) {
        const tables = groups[groupName]; //分组里包含的表

        for (const table of tables) {
          const status = table.uploadStatus;
          const { templateId, sysFileName, userFileName } = table;

          if (status === "待修改") {
            table.data = {};
            table.data.templateId = templateId;
            table.data.groupName = groupName;
            table.data.sysFileName = sysFileName;
            table.data.userFileName = userFileName;

            tables4correction.push(table.data);
          }
        }
      }
      if (tables4correction.length > 0) {
        this.$store.commit("UploadTable/setCorrectionTableData", {
          tables4correctionData: tables4correction,
        });
        this.showCorrectDialog();
      }
      this.loadList();
    },
    handleCorrectDialogClose() {
      this.loadList();
    },
  },
  watch: {
    $route: function (to, from) {
      window.scrollTo(0, 0);
      this.init();
      // 拿到目标参数 to.query.id 去再次请求数据接口
      //this.loadPageData(to.query.id);
      //this.dataPeriod;
      //debugger;
    },
    // uploadMethod:{
    //   handler:function(val){
    //     this.tipsFornoAutoGetData()
    //   }
    // }
    postMsg(newdatapostMsg, olddatapostMsg) {
      if (getLocalAbilities(this.$route.params.version).includes("报税表检测流程")) {
        this.handleBtnNext21();
      } else {
        this.switchToManualUploader();
      }
    },
    postMsgToUpload(newdatapostMsg, olddatapostMsg) {
      this.handleSkip();
    },
  },
};
</script>
<style src="@/styles/index.styl" lang="stylus" scoped></style>
<style lang="stylus" scoped>
.tip{
  margin 0 48px  !important
  width auto !important
}

.data-loading-wrapper {
  // height: 722px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 60px;

  .text {
    margin-top: 47px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    line-height: 25px;
  }
}

.modify-table-wrapper {
  margin-top: 30px;
}

.correctDialog {
  padding: 0;

  >>> .el-dialog__body {
    padding: 0;
    overflow: hidden;
  }
}

.tipDialog {
  .inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    box-sizing: border-box;

    .img {
      width: 174px;
      flex-shrink: 0;
    }

    .right-wrapper {
      margin-left: 50px;

      .title {
        font-weight: bold;
        font-size: 18px;
        color: #062340;
      }

      .desc {
        margin-top: 16px;
        color: #6b7897;
        font-size: 16px;
        line-height: 1.5;
      }

      .btn-i-get {
        height: 40px;
        line-height: 40px;
        margin-top: 17px;
      }
    }
  }
}

.the-title {
  margin: 30px 30px 30px 0;
}

.content-body {
  margin: 20px;
  background: #fff;
  border-radius: 8px;
  padding: 0;
}

.upload-footer {
  margin-top: 16px;

  .tip {
    float: left;
    color: #FAAD14;
    font-size: 14px;
  }

  .acknowlege, .btn-download {
    background: #fff;
    font-size: 14px;
    float: right;
    color: #2F54EB;
    vertical-align: middle;

    .icon {
      vertical-align: middle;
      font-size: 22px;
    }
  }

  .acknowlege {
    margin-right: 48px;
    cursor: pointer;
    float: right;
  }

  .acknowlege:hover, .btn-download:hover {
  }
}

.table-list {
  margin-top: 24px;
}

.footer {
  position: relative;
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;

  .btn {
    margin: 0 10px;
  }

  .btn-purge-data {
    color: #ff6464;
  }
}

// >>> .el-dialog__header {
// padding: 0;
// }

// >>> .section-title {
// margin-top: 10px;
// }
.section-box{
  padding 0
  .title {
    color: rgba(0, 0, 0, 0.8);
    padding 16px 24px
    font-size: 18px;
    font-weight: 600;
    border-bottom: 1px solid #eeeeee;
    text-align left

    .tips {
      font-size: 14px;
      color: #f5222d;
      margin-left: 16px;
    }
  }
}
.page-wrapper {
  padding-bottom: 84px;
}

.progressdis {
  width: 90%;
  margin: 2px auto 16px;
  margin-bottom: 0;
}

.btn-right-none {
  margin-right: 6px !important;
}

.report-method {
  margin-top: 16px;
}

.nomargin {
  margin: 0 30px;
  padding: 0;
}

.title-content {
  // width: 1260px;
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 0px 0px rgba(238, 238, 238, 1);
}

>>> .bread-crumb-wrapper {
  margin-top: 0;
}

.mainHeader {
  position: relative;
  display: flex;
  justify-content: space-between;

  .tip {
    position: absolute;
    top: -4px;
    left: 60px;
    background: transparent;

    .btn {
      padding: 0 3px;
      margin: 0 3px;
      color: #2f54eb;
      font-weight: bold;
    }
  }
}

.text-status {
  width: 10%;
}
.tab-wrapper{
  border-bottom 1px solid #d9d9d9;
}
.uploadMethodText {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 24px;
  align-items center
  justify-content center
}

.uploadMethodItem {
  padding 0px 50px
  height: 49px;
  line-height: 49px;
  text-align: center;
  background: #fff;
  border-radius: 18px 18px 0 0;
  display flex
  cursor: pointer;
  font-size: 17px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
}

.zqLocation {
  color: #2F54EB;
  font-weight 600
  border: 1px solid #D9D9D9;
  border-bottom 0 none
}

.fix-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  background: rgba(255, 255, 255, 1);
  border-bottom: 1px solid rgba(217, 217, 217, 1);
  margin-bottom: 24px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.fix-flexs {
  display: flex;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(47, 84, 235, 1);
}

.fix-flexs1 {
  margin-left: 24px;
}

.byHand {
  text-align: center;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(47, 84, 235, 1);
  line-height: 22px;
  margin-bottom: 20px;
  cursor: pointer;
  margin-top: 24px;
}

.drog_texts {
  padding-top: 10px;
  padding-bottom: 14px;
  width: 304px;
}

.upload-text {
  display: flex;
  width: 100%;
  overflow: hidden;
  box-sizing border-box
}

.table-lists {
  flex: 1;
  margin-top: 0;
  padding-top: 0px;
}


// 余额表
.tables {
  position: absolute;
  top: 40%;
  left: 50%;
  margin-left: -550px;
  margin-top: -300px;
  width: 1158px;
  height: 700px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 2000;
  padding-bottom: 30px;
  overflow-y: auto;
}

.content {
  width: 1136px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 0 auto;
}

.title {
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  text-align: center;
  margin: 24px 0;
}

.closes {
  position: absolute;
  right: 25px;
  top: 17px;
  font-size: 32px;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.fix-flexsL {
  width: 25%;
  padding-right: 32px;
}

.fix-flexsR {
  width: 75%;
}

.fix-flexsLr {
  width: 100%;
  // max-width: auto;
}

.flex-max-widh {
  max-width: 304px;
}

.totip {
  position: absolute;
  left: 390px;
  top: 8px;
}

.noPadding {
  padding-right: 0;
}

.progressUpload {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 2022;
}

.progressBox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 638px;
  height: 406px;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.processText {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #2F54EB;
  line-height: 28px;
  margin-top: 25px;
}

.processTexted {
  color: #52C41A;
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  line-height: 28px;
  margin-top: 25px;
}

>>> .el-progress--circle .el-progress__text, .el-progress--dashboard .el-progress__text {
  top: 80%;
}

.undraw_upload {
  position: absolute;
  width: 180px;
  height: 132px;
  top: 90px;
}

.processTextedNo {
  visibility: hidden;
}

.baseVersion {
  height: 36px;
  line-height: 36px;
  padding: 0 6px;
  background: #2F54EB;
  margin: 4px 6px 0 0;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}

.fetchBaseUrl {
  width: 100%;
  height: 676px;
  border: none;
}
</style>
