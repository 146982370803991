<template>
  <div class="financialRisk-h5-container">
    <div class="financialRisk-h5">
      <div class="company-baseName">
        <div class="company-tip"></div>
        <div class="company-tip-base">上下游客户分布</div>
      </div>
    </div>
    <div>
      <!-- <div class="toolbar toolbar-h5">
        <el-radio-group v-model="activeIndex">
          <el-radio-button label="上游客户"></el-radio-button>
          <el-radio-button label="下游客户"></el-radio-button>
        </el-radio-group>
      </div> -->
      <div class="up-down">
        <div class="up" :class="{ down: activeIndex == 1 }" @click="activeIndex = 1">
          上游客户
        </div>
        <div class="up" :class="{ down: activeIndex == 2 }" @click="activeIndex = 2">
          下游客户
        </div>
      </div>
      <div v-if="activeIndex == '1'">
        <ChinaCityTable :data="data" :type="'2'" :key="2"></ChinaCityTable>
        <!-- <div v-if="!data" class="processedTable" v-loading="!data"></div> -->
      </div>
      <div v-if="activeIndex == '2'">
        <ChinaCityTable :data="data" :type="'1'" :key="1"></ChinaCityTable>
      </div>
    </div>
    <div class="PurchaseAndSale-h5-title">*进入PC端查看更多风险详情及解决方案。</div>
  </div>
</template>

<script>
import ChinaCityTable from "@/pages/Result/H5FinancialAnalysis/ChinaCityTable";
import { getCompanyAreaList } from "@/api/threeYears";

export default {
  name: "UpDownCustomerDistribution",
  components: {
    ChinaCityTable,
  },
  data() {
    return {
      activeIndex: "1",
      upStreamfilter: {
        time: [],
        limit: 15,
      },
      data: null,
    };
  },
  async mounted() {
    await this.loadCompanyAreaList();
  },
  methods: {
    async loadCompanyAreaList() {
      const res = await getCompanyAreaList({
        programId: this.$route.params.uuid,
      });
      this.data = res.data;
    },
  },
};
</script>

<style lang="stylus" scoped>
.toolbar
  // margin-top 24px
  display flex
  justify-content space-between
  align-items center



>>> .el-radio-button {
  margin-right 10px

  .el-radio-button__inner {
    border-radius: 2px;
    padding 6px 16px
    border 0 none;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #86909C;
    box-shadow none;
  }
}

>>> .el-radio-button.is-active {
  .el-radio-button__inner {
    background: rgba(22, 93, 255, 0.1);
    color: #165DFF;
    font-weight bold
  }
}

.financialRisk-h5-container
  border: 2px solid #6AA1FF;
  border-style: dashed;
  margin: 0.4rem;
  padding: 0.24rem;
  border-radius: 4px;

.financialRisk-h5
  .company-baseName
    display: flex;
    align-items: center;
    color: #1D2129;
    font-size: 0.32rem;
    font-weight: 600;
    line-height: 0.33rem;
    margin-bottom: 0.16rem;

    .company-tip
      width: 0.12rem;
      height: 0.24rem;
      background: #2F54EB;
      margin-right: 0.1rem;

    .company-tip-base
      line-height: 0.33rem;

.toolbar-h5
  >>> .el-radio-button .el-radio-button__inner
    width: 0.97rem;
    height: 0.4rem;
    line-height: 0.4rem;
    border-radius: 2px;
    font-size: 0.24rem;
  >>> .el-radio-button__inner, .el-radio-group {
    display: inline;
  }

.processedTable {
  width: 6.2rem;
  margin: 0 auto;
  height: 500px;
}

.up-down {
  display: flex;
  align-items: center;
  font-size: 0.24rem;
  margin: 0.16rem 0 0.16rem 0.24rem;

  .up {
    width: 1.12rem;
    height: 0.4rem;
    line-height: 0.4rem;
    background: #F7F8FA;
    border-radius: 2px;
    margin-right: 0.24rem;
    text-align: center;
    padding: 2px 4px;
  }

  .down {
    color: #4080FF;
    background: #E8F3FF;
    font-weight: 600
  }
}

.PurchaseAndSale-h5-title
  font-size: 0.2rem;
  color: #F53F3F;
  line-height: 0.3rem;
  margin: 0.16rem 0 0 0.24rem;
</style>
