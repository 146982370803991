<template>
  <div>
    <!-- <img src="@/assets/result/contact-us-fixed.png" class="contact-us-fixed" @click="dialogVisible=true"> -->
    <el-dialog
      title="咨询解答"
      :visible.sync="dialogVisible"
      width="576px"
      :close-on-click-modal="false"
    >
      <div class="contact-us-wrapper">
        <el-form
          ref="form"
          :model="form"
          label-width="100px"
          class="form"
          :rules="rules"
        >
          <el-form-item label="姓名：" prop="name">
            <el-input v-model="form.name" placeholder="请输入您的姓名"></el-input>
          </el-form-item>
          <el-form-item label="联系方式：" prop="contact">
            <el-input v-model="form.contact" placeholder="请输入您的联系方式"></el-input>
          </el-form-item>
          <el-form-item label="问题描述：" prop="detail">
            <el-input v-model="form.detail" type="textarea"
                      :rows="5" placeholder="请输入您的内容…"></el-input>
          </el-form-item>
        </el-form>
        <div class="right-box" v-if="!customization">
          <img src="https://shuian-file.oss-cn-hangzhou.aliyuncs.com/image/qrcode_for_gh_cc017f82c39a_258.jpg" alt="" class="qr" />
          <div class="text">扫码关注公众号，获取免费检测体验</div>
          <div class="text" style="margin-top: 80px;">官方电话：</div>
          <div class="text" style="margin-top: 10px">15381189712</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
      <button
        class="btn btn-primary"
        type="primary"
        @click="handleDialogFormClicked"
      >
        确 定
      </button>
    </span>
    </el-dialog>
  </div>
</template>

<script>
import {addContactMsg} from "@/api/api";
import {mapState} from "vuex";

export default {
  name: "ContactUs",
  data() {
    return {
      dialogVisible: false,
      form: {
        name: "",
        contact: "",
        detail:""
      },
      rules: {
        name: [{required: true, message: "请输入姓名", trigger: "blur"}],
        contact: [
          {required: true, message: "请输入联系方式", trigger: "blur"},
        ],
        detail: [
          {required: true, message: "请输入问题描述", trigger: "blur"},
        ],
      },
    }
  },
  methods: {
    handleDialogFormClicked() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          addContactMsg({
            name: this.form.name,
            phone: this.form.contact,
            detail: this.form.detail,
          }).then((res) => {
            if (res.code == 200) {
              this.$alert(res.message, "提示", {
                type: "success",
              });
              this.dialogVisible = false;
            } else {
              this.$alert(res.message, "提示", {
                type: "error",
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

  },
  computed: {
    ...mapState(["customization"]),
  },
}
</script>

<style lang="stylus" scoped>
.contact-us-wrapper{
  display flex
}
.contact-us-wrapper .right-box{
  width 120px
  padding-left 45px
  .qr{
    width 100%
  }
}
.contact-us-fixed {
  width 40px
  position fixed
  right 0
  top 50%;
  cursor pointer
}

>>> .el-dialog__body {
  padding  24px
}

.form {
  width 360px

  >>> .el-form-item__label {
    text-align left
    display block
    float none
    font-weight bold
  }

  >>> .el-form-item__content {
    width 100% !important
    margin-left 0 !important
  }


}
.text{
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
}


</style>
