<template>
  <div>
    <!-- <bread-crumb>
      <el-breadcrumb-item :to="{ name: 'goods' }">产品中心</el-breadcrumb-item>
      <el-breadcrumb-item>我的订单</el-breadcrumb-item>
    </bread-crumb> -->
    <div class="msg-title">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ name: 'homePage' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>我的订单</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <div class="qymc">订单记录</div>
    </div>
    <!-- <div class="submit-title">订单记录</div> -->
    <div class="record-select">
      <div class="record-state">订单状态：</div>
      <el-select
        v-model="orderStatus"
        placeholder="请选择"
        @change="optionSelect(orderStatus)"
        clearable
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <div class="record-time">创建时间：</div>
      <el-date-picker
        v-model="timeArr"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        @change="timeChange"
      ></el-date-picker>
    </div>
    <div class="record-form">
      <el-table
        v-loading="loading"
        :data="tableData"
        element-loading-text="加载中..."
        style="width: 100%"
        :default-sort="{ prop: 'date', order: 'descending' }"
      >
        <el-table-column width="24"></el-table-column>
        <el-table-column
          min-width="12%"
          prop="createTime"
          label="创建时间"
          sortable
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          min-width="18%"
          prop="orderNo"
          label="订单编号"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          min-width="12%"
          prop="companyName"
          label="购买企业"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          min-width="10%"
          prop="goodsName"
          label="版本"
        ></el-table-column>
        <el-table-column min-width="10%" label="价格">
          <template slot-scope="scope">
            <span>{{ scope.row.price && scope.row.price.toFixed(2) }}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="10%" prop="status" label="订单状态">
          <template slot-scope="scope">
            <div v-if="scope.row.orderStatus == 2">
              <div class="icons">
                <span class="icon icongreen"></span>
                <span>已支付</span>
              </div>
            </div>
            <div class="icons" v-if="scope.row.orderStatus == -1">
              <span class="icon iconred"></span>
              <span>已取消</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          min-width="10%"
          class="blue"
          align="center"
          prop="payment_method"
          label="支付方式"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.payType == '0'">支付宝</span>
            <span v-if="scope.row.payType == '1'">微信</span>
          </template>
        </el-table-column>
        <el-table-column prop="discount" label="操作" align="right" width="80">
          <template slot-scope="scope">
            <span class="discount-btn" @click="viewDetail(scope.row)"
              >查看</span
            >
          </template>
        </el-table-column>
        <el-table-column width="20"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import { allRecord } from "../../api/api";
export default {
  components: {
    BreadCrumb,
  },
  data() {
    return {
      options: [
        {
          value: "-1",
          label: "已取消",
        },
        {
          value: "2",
          label: "已支付",
        },
      ],
      orderStatus: "",
      timeArr: "",
      tableData: [],
      firstTime: "2018-11-13 00:00:00",
      lastTime: "2099-12-30 00:00:00",
      status: "",
      loading: "true",
      pageNo: 0,
      pageSize: 1000,
    };
  },
  created() {
    this.allrecord(this.firstTime, this.lastTime, this.status);
  },
  methods: {
    // 查询所有订单记录
    allrecord() {
      let params = {
        token: JSON.parse(localStorage.getItem("token")).token,
        status: this.status,
        createStartTime: this.firstTime,
        createEndTime: this.lastTime,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      };
      allRecord(params).then((res) => {
        this.tableData = res.data.list;
        this.loading = false;
      });
    },
    optionSelect(val) {
      this.status = val;
      this.allrecord();
    },
    timeChange(val) {
      if (!val) {
        this.firstTime = "2018-11-13 00:00:00";
        this.lastTime = "2099-12-30 00:00:00";
      } else {
        this.firstTime = this.formatDateTes(val[0]);
        this.lastTime = this.formatDateTes(val[1]);
      }
      this.allrecord(this.firstTime, this.lastTime, this.status);
    },
    // 查看订单
    viewDetail(val) {
      this.$router.push({
        path: "/product/info",
        query: {
          id: val.orderNo,
          status: val.status,
        },
      });
    },
    formatDateTes(timestamp) {
      var date = new Date(timestamp);
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + " " + h + m + s;
    },
  },
};
</script>
<style lang="stylus" scoped>
.submit-title {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.8);
  padding: 8px 0 0 30px;
  font-weight: 500;
}

.record-select {
  display: flex;
  align-items: center;
  height: 84px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 24px 30px 0 30px;
  color: rgba(0, 0, 0, 0.75);

  .record-state {
    margin-left: 40px;
    margin-right: 8px;
  }

  .record-time {
    margin-left: 48px;
    margin-right: 8px;
  }
}

.record-form {
  position: relative;
  bottom: 20px;
  margin: 36px 30px 0 30px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  overflow: hidden;

  .discount-btn {
    cursor: pointer;
    color: rgba(47, 84, 235, 1);
    font-size: 14px;
  }

  .icon {
    display: inline-block;
    width: 6px;
    height: 6px;
    background: rgba(87, 197, 66, 1);
    border-radius: 50%;
    margin-right: 8px;
  }

  .icons {
    display: flex;
    align-items: center;
  }

  .iconred {
    background: rgba(246, 108, 111, 1);
  }

  .icongreen {
    background: rgba(87, 197, 66, 1);
  }

  .icongary {
    background: rgba(217, 217, 217, 1);
  }
}

>>>.el-select {
  width: 240px;
  height: 36px;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  box-sizing: border-box;
}

>>>.el-input .el-input--suffix {
  width: 240px;
  height: 36px;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  box-sizing: border-box;
}

>>>.el-input__inner {
  height: 36px;
  line-height: 36px;
}

>>>.el-input__icon {
  line-height: 36px;
}

>>>.el-date-editor .el-range-separator {
  width: 8%;
}

>>>.el-input__icon {
  line-height: 28px;
}

>>>.el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
  width: 260px;
}

>>>.el-date-editor .el-range-separator {
  line-height: 28px;
}

>>>.el-date-editor .el-range__close-icon {
  line-height: 30px;
}

>>>.el-table__row {
  height: 52px !important;
}

>>>.el-table .cell {
  line-height: 1.1;
}

>>>.el-table tr td:last-child {
  margin-left: 50px;
}

.msg-title {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 0px 0px rgba(238, 238, 238, 1);

  .qymc {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    // font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    line-height: 28px;
    margin: 8px 0 0 30px;
  }
}

>>>.bread-crumb-wrapper {
  margin-top: 0;
}
</style>