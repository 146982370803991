var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-box"},[_c('div',{staticClass:"title"},[_vm._v("使用流程图")]),_c('div',{staticClass:"steps"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),(_vm.versionPurchased>3)?_c('div',{staticClass:"step"},[_c('img',{staticClass:"pic",attrs:{"src":require("@/assets/home/step2@2x.png")}}),_c('div',{staticClass:"title"},[_vm._v("发票数据采集")]),_c('div',{staticClass:"desc"},[_vm._v("录入企业发票数据")])]):_vm._e(),(_vm.versionPurchased>3)?_c('div',{staticClass:"step-arrow"},[_c('img',{staticClass:"pic",attrs:{"src":require("@/assets/home/steps_arrow.png")}})]):_vm._e(),_vm._m(4),_vm._m(5),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step"},[_c('img',{staticClass:"pic",attrs:{"src":require("@/assets/home/step0@2x.png")}}),_c('div',{staticClass:"title"},[_vm._v("模型匹配")]),_c('div',{staticClass:"desc"},[_vm._v("自动匹配风险模型库")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-arrow"},[_c('img',{staticClass:"pic",attrs:{"src":require("@/assets/home/steps_arrow.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step"},[_c('img',{staticClass:"pic",attrs:{"src":require("@/assets/home/step1@2x.png")}}),_c('div',{staticClass:"title"},[_vm._v("财税数据采集")]),_c('div',{staticClass:"desc"},[_vm._v("录入企业财税数据")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-arrow"},[_c('img',{staticClass:"pic",attrs:{"src":require("@/assets/home/steps_arrow.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step"},[_c('img',{staticClass:"pic",attrs:{"src":require("@/assets/home/step3@2x.png")}}),_c('div',{staticClass:"title"},[_vm._v("预警报告")]),_c('div',{staticClass:"desc"},[_vm._v("在线报告/PDF报告")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-arrow"},[_c('img',{staticClass:"pic",attrs:{"src":require("@/assets/home/steps_arrow.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step"},[_c('img',{staticClass:"pic",attrs:{"src":require("@/assets/home/step4@2x.png")}}),_c('div',{staticClass:"title"},[_vm._v("税务筹划")]),_c('div',{staticClass:"desc"},[_vm._v("在线人工/专业税务师")])])
}]

export { render, staticRenderFns }