<template>
  <div class="page-wrapper" :key="actualZq" v-if="actualZq">
    <div class="title-content">
      <bread-crumb>
        <el-breadcrumb-item>风险检测</el-breadcrumb-item>
        <el-breadcrumb-item>开始检测</el-breadcrumb-item>
        <el-breadcrumb-item>数据读取</el-breadcrumb-item>
      </bread-crumb>
      <div class="main-top">
        <main-header>
          <template slot="title">余额表上传</template>
        </main-header>
        <div class="processContainer">
          <span>
            <img class="processLImg" src="@/pages/Test/Invoice/images/1.png" alt />
          </span>
          <span class="process1">申报数据读取</span>
          <span class="process-status"></span>
          <span>
            <img class="processLImg" src="@/pages/Test/Invoice/images/2.png" alt />
          </span>
          <span class="process1">余额表上传</span>
          <span class="process-status process-statusr"></span>
          <span>
            <img class="processLImg" src="@/pages/Test/Invoice/images/4.png" alt />
          </span>
          <span class="process2">发票数据采集</span>
        </div>
        <div class="rightProcess"></div>
      </div>
    </div>

    <div class="content-body">
      <div>
        <div>
          <div class="content-body-flex">
            <div class="content-top-1">余额表</div>
            <a
              href="//shuian-file.oss-cn-hangzhou.aliyuncs.com/user/YEB.xlsx"
              class="btn-download fix-flexs1"
              download
              >下载模板</a
            >
          </div>
          <div class="onUpload-flex">
            <uploaderb
              class="onUpload-flex-item onUpload-flex-iteml"
              @onUpload="onUpload"
              :uuid="uuid"
              v-if="actualZq"
              :zq="zq"
              :zaNameValue="zaNameValue"
            ></uploaderb>
            <uploaders
              class="onUpload-flex-item"
              @onUpload="onUpload"
              :uuid="uuid"
              v-if="actualZq"
              :zq="zq"
              :zaNameValue="zaNameValue"
            ></uploaders>
          </div>
        </div>

        <table-lists
          :uuid="uuid"
          @deletes="deletes"
          class="table-lists"
          :balanceData="balanceData"
          :zqLocation="zq"
        ></table-lists>
      </div>

      <sa-footer>
        <div class="footer">
          <template>
            <router-link
              :to="{
                name: 'upload-table',
                query: { deleteDataType: this.$route.query.deleteDataType },
              }"
              class="btn btn-default btn-next btn-right-none"
              >上一步</router-link
            >
            <button class="btn btn-primary btn-next" @click="handleBtnNext">
              下一步
            </button>
          </template>
        </div>
      </sa-footer>
    </div>

    <!--上传错误提示弹出框-->
    <el-dialog
      :visible.sync="correctDialogVisible"
      width="80%"
      :show-close="true"
      class="correctDialog"
      top="1vh"
      @close="handleCorrectDialogClose"
    >
      <SectionTitle>报表匹配</SectionTitle>
      <div class="modify-table-wrapper">
        <modify-table></modify-table>
      </div>
    </el-dialog>
    <!--/上传错误提示弹出框-->
    <!--纠错弹出框-->
    <el-dialog
      :visible.sync="uploadErrorDialogVisible"
      width="800px"
      :show-close="true"
      top="25vh"
      title="抱歉,以下表无法解析"
    >
      <el-table :data="uploadErrors" style="width: 100%">
        <el-table-column prop="userFileName" label="表名"></el-table-column>
        <el-table-column prop="reason" label="异常原因"></el-table-column>
      </el-table>
    </el-dialog>
    <!--/纠错弹出框-->

    <!--数据上传组件-->
    <TestDataUploader
      ref="testDataUploader"
      :bankId="bankId"
      :productID="productID"
      :productName="productName"
      :uuid="uuid"
      :zq="zq"
      :version="version"
    ></TestDataUploader>
    <!--/数据上传组件-->

    <loading
      :active.sync="isLoading"
      backgroundColor="#000"
      :can-cancel="true"
      :is-full-page="fullPage"
      loader="Dots"
      color="#2f54eb"
    ></loading>
  </div>
</template>

<script>
import { getBalanceInfo, addReportValue, genTestReport } from "@/api/api";
import { cloneDeep } from "lodash";
import BreadCrumb from "@/components/BreadCrumb";
import Uploaders from "./components/Uploaders";
import Uploaderb from "./components/Uploaderb";
import TableLists from "./components/TableLists";
import ModifyTable from "./components/ModifyTable";
import SectionTitle from "@/components/SectionTitle";
import AcknowledageTab from "./components/AcknowledageTab";
import SDSFilter from "./components/SDSFilter";
import MainHeader from "@/pages/components/MainHeader";
import ProgressDiagram from "@/pages/components/ProgressDiagram";
import Footer from "../ChooseTable/components/Footer";
import TestDataUploader from "../Components/TestDataUploader";
import { getUserTables, clearUploadedTableforTest, deleteTable } from "@/api/table";

import { mapState } from "vuex";
import { zq2name, previousZq } from "@/utils/helper";
import licen from "./components/licen-dialog";

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  data() {
    return {
      tables: {},
      fullPage: true,
      isLoading: false,
      correctDialogVisible: false, //控制手动修正的表的弹窗
      uploadErrorDialogVisible: false, //控制无法识别的表的弹窗
      uploadErrors: [], //错误的表名及原因
      hasItemsInList: false,
      actualZq: 0,
      zqs: [],
      balanceData: [
        { index: 0, name: "", status: 1 },
        { index: 1, name: "", status: 1 },
      ],
      zaNameValue: [],
    };
  },
  components: {
    Loading,
    BreadCrumb,
    Uploaders,
    Uploaderb,
    TableLists,
    SectionTitle,
    ModifyTable,
    AcknowledageTab,
    MainHeader,
    ProgressDiagram,
    saFooter: Footer,
    TestDataUploader,
    SDSFilter,
    licen,
  },
  props: {
    zq: [Number, String],
    productName: String,
    productID: [Number, String],
    zqLocation: [Number, String],
    bankId: String,
    uuid: String,
    dataPeriod: [Number, String],
    version: [Number, String],
    nsrsbhIndex: [Number, String],
    qykjzdIndex: [Number, String],
    selectvalueIndex: [Number, String],
    grantIndex: [Number, String],
  },
  computed: {
    ...mapState(["companyInfo", "authorization"]),
    ...mapState("UploadTable", ["permissionID", "SDSSelection"]),
  },
  async mounted() {
    await this.init();
    await this.zqNameValue();
  },
  methods: {
    // 拉表走check接口
    checkOld() {
      let data = this.$store.state;
      let getDataWay = sessionStorage.getItem("method") == 2 ? 1 : 2;
      if (this.version == 5) {
        getDataWay = 1;
      }
      genTestReport({
        appId: localStorage.getItem("appid_3rd") || "",
        token: data.authorization,
        nsrsbh: data.companyInfo.nsrsbm,
        tag: this.version,
        uuid: this.uuid,
        zq: this.zq,
        invoiceNsrsbh: this.nsrsbhIndex,
        getDataWay: getDataWay,
        deleteDataType: this.$route.query.deleteDataType,
        nameless:sessionStorage.getItem('isAnonymous')==='true'
      }).then((res) => {
        if (res.msgCode == 1) {
          this.$store.commit("UploadTable/setPermissionID", {
            permissionID: "",
          });
          this.$router.push({
            name: "test-InvoiceUpload",
          });
        } else if (res.msgCode == -1) {
          this.$alert(res.msgInfo, "错误", {
            confirmButtonText: "确定",
            type: "error",
            callback: (action) => {
              this.$router.push({ name: "homePage" });
            },
          });
        } else {
          this.$message({
            message: res.msgInfo,
            type: "error",
            duration: 5000,
            callback: (action) => {
              alert("dasdsa");
            },
          });
        }
      });
    },
    zqNameValue() {
      let zaNameValue = [];
      let zqLast = this.zq.substr(this.zq.length - 1, 1);

      if (zqLast == 1) {
        let nextMonth = "";
        let nextYear = "";
        if (this.zq.slice(4, 6) == "01") {
          nextMonth = "12";
          nextYear = Number(this.zq.slice(0, 4)) - 1;
        } else {
          nextMonth = (Number(this.zq.slice(4, 6)) - 1).toString();
          nextYear = this.zq.slice(0, 4);
        }
        if (nextMonth.length == 1) {
          nextMonth = "0" + nextMonth;
        }
        zaNameValue[0] = this.zq.slice(0, 4) + "年" + this.zq.slice(4, 6) + "月";
        zaNameValue[1] = nextYear + "年" + nextMonth + "月";
        this.zaNameValue = zaNameValue;
      }

      if (zqLast == 4) {
        let nextYear = this.zq.slice(0, 4);
        zaNameValue[0] = this.zq.slice(0, 4) + "年";
        zaNameValue[1] = Number(nextYear) - 1 + "年";
        this.zaNameValue = zaNameValue;
      }

      if (zqLast == 2) {
        let year = this.zq.slice(0, 4);
        let quer = this.zq.slice(5, 6);
        let nextQuer;
        let nextYear;
        if (quer == 1) {
          nextYear = year - 1;
          nextQuer = 4;
        } else {
          nextYear = year;
          nextQuer = quer - 1;
        }

        zaNameValue[0] = year + "年" + quer + "季度";
        zaNameValue[1] = nextYear + "年" + nextQuer + "季度";
        this.zaNameValue = zaNameValue;
      }

      this.balanceData[0].name = this.zaNameValue[0] + "余额表";
      this.balanceData[1].name = this.zaNameValue[1] + "余额表";
    },
    deletes(val) {
      this.handleBtnPurgeDataClicker(val.zq);
    },

    //本期的下一步按钮
    handleBtnNext() {
      let params = {
        nsrsbh: this.$store.state.companyInfo.nsrsbm,
        zq: this.zq,
        uuid: this.uuid,
      };
      const loading = this.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      addReportValue(params).then((res) => {
        loading.close();
        if (res.code != 200) {
          this.$message.error(res.message);
          return;
        }
        // 如果不是山东的自动取数，就得再去发票页面
        if (this.$route.query.grantIndex != 3) {
          this.$router.push({
            name: "test-invoice",
            params: { userName: this.taxSiteUsername },
            query: { deleteDataType: this.$route.query.deleteDataType },
            // 将账号带过去
          });
        } else {
          this.checkOld();
        }
      });
    },

    getbalanceInfo() {
      let params = {
        uuid: this.uuid,
        zq: this.zq,
      };
      getBalanceInfo(params).then((res) => {
        this.balanceData[0].status = res.data.bq.status;
        this.balanceData[1].status = res.data.sq.status;
      });
    },

    async init() {
      this.isLoading = true;

      if (this.dataPeriod == 1) {
        //上个征期
        this.actualZq = previousZq(this.zq);
        this.zqs.push(this.zq);
        this.zqs.push(this.actualZq);
      } else if (this.dataPeriod == 0) {
        //当前征期
        this.actualZq = this.zq;
      }

      this.$store.commit("UploadTable/setZq", { zq: this.actualZq });
      this.$store.commit("UploadTable/setZqList", { zq: this.zq });

      await this.$store.cache.dispatch("loadCompanyInfo");
      await this.loadList();
      this.$store.commit("UploadTable/setProduct", {
        name: this.productName,
        id: this.productID,
        bankID: this.bankId,
      });

      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    },

    listChanged() {
      this.loadList();
    },
    //清空所有已经上传的报表
    handleBtnPurgeDataClicker(zq) {
      // console.log(zq);
      this.$confirm(`所有文件将被删除, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const result = await deleteTable(
          this.companyInfo.nsrsbm,
          zq,
          "YEB",
          this.authorization,
          this.uuid
        );

        if (result.code == 200) {
          this.$emit("listChanged");
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.loadList();
        } else {
          this.$message.error(result.message);
        }
      });
    },

    //修正表格的弹出框
    showCorrectDialog() {
      this.correctDialogVisible = true;
    },
    //错误原因弹出框
    showUploadErrorDialog() {
      this.uploadErrorDialogVisible = true;
    },
    async loadList() {
      //bankId, qykjzd, nsrsbh, zq
      const tables = await getUserTables(
        this.bankId,
        this.version,
        this.companyInfo.nsrsbm,
        this.actualZq,
        this.uuid,
        false
      );
      // AI事务所;
      await this.getbalanceInfo();

      //过滤未上传的表格
      this.tables = {};

      for (const grpName in tables.data) {
        const grpData = tables.data[grpName];
        const newGrp = [];
        for (const table of grpData.tableInfo) {
          //if (table.uploadStatus != "未上传") {
          const {
            tableNameCn,
            tableNameEn: templateId,
            uploadStatus: status,
            tableNum,
          } = table;

          const item = {
            name: `${tableNameCn}`,
            status,
            templateId,
            tableNum,
          };

          newGrp.push(item);
          //}
        }

        if (newGrp.length > 0) {
          this.tables[grpName] = {
            list: newGrp,
            totalNum: grpData.totalNum,
            uploadNum: grpData.uploadNum,
          };
        }
      }
      if (Object.keys(this.tables).length > 0) {
        this.hasItemsInList = true;
      } else {
        this.hasItemsInList = false;
      }
    },
    async onUpload(file, data) {
      //如果有错误的表格则弹出弹出框
      if (data.data.errorData.length > 0) {
        this.uploadErrors = data.data.errorData;
        this.showUploadErrorDialog();
      }

      const groups = data.data.succData;

      //根据上传的excel构建本地对应的数组

      //上传文件后需要纠错的表格信息
      const tables4correction = [];

      for (const groupName in groups) {
        const tables = groups[groupName]; //分组里包含的表

        for (const table of tables) {
          const status = table.uploadStatus;
          const { templateId, sysFileName, userFileName } = table;

          if (status === "待修改") {
            table.data.templateId = templateId;
            table.data.groupName = groupName;
            table.data.sysFileName = sysFileName;
            table.data.userFileName = userFileName;

            tables4correction.push(table.data);
          }
        }
      }
      if (tables4correction.length > 0) {
        this.$store.commit("UploadTable/setCorrectionTableData", {
          tables4correctionData: tables4correction,
        });
        this.showCorrectDialog();
      }
      this.loadList();
    },
    handleCorrectDialogClose() {
      this.loadList();
    },
  },
  watch: {
    $route: function (to, from) {
      window.scrollTo(0, 0);
      this.init();
    },
  },
};
</script>

<style lang="stylus" scoped>
.modify-table-wrapper {
  margin-top: 30px;
}

.correctDialog {
  padding: 0;

  >>> .el-dialog__body {
    padding: 0;
    overflow: hidden;
  }
}

.content-body {
  margin: 20px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  padding: 20px;
  padding-top: 0;
  margin-top: 16px;
}

.footer {
  margin-top: 20px;
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;

  .btn {
    margin: 0 10px;
  }

  .btn-purge-data {
    color: #ff6464;
  }
}

.page-wrapper {
  padding-bottom: 84px;
}

.btn-right-none {
  margin-right: 6px !important;
}

.main-top {
  display: flex;
  justify-content: space-between;
}

.content-body-flex {
  display: flex;
  align-item: center;
  justify-content: space-between;
  height: 54px;
  line-height: 54px;
  border-bottom: 1px solid rgba(217, 217, 217, 1);
  margin-bottom: 24px;
  background: rgba(255, 255, 255, 1);
}

.content-top-1 {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.onUpload-flex {
  display: flex;
}

.onUpload-flex .onUpload-flex-item {
  flex: 1;
}

.onUpload-flex-iteml {
  margin-right: 16px;
}

.table-lists {
  margin-top: 24px;
}

.process1 {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.process2 {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
}

.processLImg {
  position: relative;
  bottom: 1px;
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.processContainer {
  display: flex;
  align-item: center;
  position: relative;
  top: 14px;
}

.process-status {
  width: 120px;
  height: 2px;
  background: rgba(0, 0, 0, 0.8);
  position: relative;
  top: 8px;
  margin: 0 24px;
}

.process-statusr {
  background: rgba(217, 217, 217, 1);
}

.mainheader {
  display: flex;
  justify-content: space-between;
}

.rightProcess {
  width: 10%;
  margin-left: 100px;
}

.title-content {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
}

>>>.bread-crumb-wrapper {
  margin-top: 0;
}

.fix-flexs1 {
  color: #409EFF;
}
</style>
