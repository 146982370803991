<template>
  <div class="mdfpassword">
    <el-card class="usercard usercardx">
      <el-form :model="form"
               ref="form" class="form">
        <div class="checkPhone">修改密码</div>
        <div class="box box1 checkPhoneb" v-if="hasPwd">
          <div class="qyname">原密码：</div>
          <div class="name">
            <el-input placeholder="请输入原密码" type="password" v-model="form.oldupwd"></el-input>
          </div>
        </div>
        <div class="box box1">
          <div class="qyname">新密码：</div>
          <div class="name">
            <el-input placeholder="6-14位，字母/数字/标点符号至少2种组合" type="password"
                      v-model="form.newupwd"></el-input>
          </div>
        </div>
        <div class="box box1">
          <div class="qyname">确认新密码：</div>
          <div class="name">
            <el-input placeholder="请再次输入新密码" type="password" v-model="form.newupwd2"></el-input>
          </div>
        </div>
        <el-button type="primary" class="save" @click="changeupwd">保存</el-button>
      </el-form>

    </el-card>
  </div>
</template>
<script>
import {changepw, changepwnew} from "@/api/api";
import {getUserPwd} from "@/api/auth";
import {logoutAndClear} from "@/utils/helper";

export default {
  props: {
    type: String
  },
  data() {
    return {
      form: {
        oldupwd: "",
        newupwd: "",
        newupwd2: "",
      },
    };
  },
  computed: {
    hasPwd() {
      return this.type === 'change'
    }
  },
  methods: {
    changeupwd() {
      if (this.hasPwd) {
        if (!this.submit()) return;
        let token = JSON.parse(localStorage.getItem("token")).token;
        let params = {
          token: token,
          passwdOld: this.form.oldupwd,
          passwd: this.form.newupwd,
        };
        changepw(params).then((data) => {
          if (data.msgCode !== "-1") {
            this.$message.success("修改密码成功,请重新登录");
            logoutAndClear(this.$store)
            this.$router.push("/passport/login");
          } else {
            //  this.$message.error(data.msgInfo);
          }
        });
      } else {
        if (!this.submit1()) return;
        let token = JSON.parse(localStorage.getItem("token")).token;
        let params = {
          token: token,
          password: this.form.newupwd,
        };
        changepwnew(params).then((data) => {
          if (data.msgCode !== "-1") {
            this.$message.success("设置密码成功,请重新登录");
            this.$router.push("/passport/login");
          } else {
            this.$message.error(data.msgInfo);
          }
        });
      }
    },
    submit() {

      if (!/(?!^\d+$)(?!^[A-Za-z]+$)(?!^[^A-Za-z0-9]+$)(?!^.*[\u4E00-\u9FA5].*$)^\S{6,14}$/.test(this.form.newupwd)) {
        this.$message.error("请输入6-14位，字母/数字/标点符号至少2种组合的密码");
        return false;
      }
      if (!this.form.oldupwd) {
        this.$message.error("请输入旧密码!");
        return false;
      }
      if (!this.form.newupwd) {
        this.$message.error("请输入新密码!");
        return false;
      }
      if (!this.form.newupwd2) {
        this.$message.error("请再次输入密码!");
        return false;
      }
      if (this.form.newupwd != this.form.newupwd2) {
        this.$message.error("两次输入的密码不一致");
        return false;
      }
      return true;
    },
    submit1() {
      if (!/(?!^\d+$)(?!^[A-Za-z]+$)(?!^[^A-Za-z0-9]+$)(?!^.*[\u4E00-\u9FA5].*$)^\S{6,14}$/.test(this.form.newupwd)) {
        this.$message.error("请输入6-14位，字母/数字/标点符号至少2种组合的密码");
        return false;
      }
      if (!this.form.newupwd) {
        this.$message.error("请输入新密码!");
        return false;
      }
      if (!this.form.newupwd2) {
        this.$message.error("请再次输入密码!");
        return false;
      }
      if (this.form.newupwd != this.form.newupwd2) {
        this.$message.error("两次输入的密码不一致");
        return false;
      }
      return true;
    },
  },
};
</script>
<style src="@/styles/index.styl" lang="stylus" scoped></style>
<style lang="stylus" scoped>
.usercardx {
  padding: 0;
}

.usercard >>> .el-card__body {
  padding: 0;
  height: auto;
  padding-bottom: 54px;
}

.checkPhone {
  height: 54px;
  line-height: 54px;
  background: rgba(255, 255, 255, 1);
  border-bottom: 1px solid rgba(217, 217, 217, 1);
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  padding-left: 24px;
}

.checkPhoneb {
  padding-top: 20px;
}
</style>
