<template>
  <div>
    <div class="msg-title">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ name: 'homePage' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>个税计算器</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <div class="title">个税计算器</div>
    </div>
    <div class="counter flex-space">
      <div class="counter-item">
        <div class="counter-item-t">
          <div class="block-t block-input city-cascader">
            <span class="demonstration block-input-name">所在城市：</span>
            <el-cascader
              v-model="city"
              :options="options"
              :props="{ expandTrigger: 'hover' }"
              @change="handleChange"
              filterable
              clearable
              separator="-"
              placeholder="浙江省-杭州"
            ></el-cascader>
          </div>
          <div class="block-t block-input block-city">
            <span class="block-tip">请选择房租抵扣地区，不同城市抵扣额度不同</span>
          </div>
          <div class="block-b block-input">
            <span class="block-input-name">税前年度工资薪金所得：</span>
            <div class="block-append">
              <el-input
                placeholder="请输入税前年度工资薪金所得"
                v-model="salary"
                oninput="value=value.replace(/[^1-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g, '$1')"
              >
                <template slot="append">元</template>
              </el-input>
            </div>
          </div>
          <div class="block-b block-input">
            <span class="block-input-name">年终奖：</span>
            <div class="block-append">
              <el-input
                placeholder="请输入年终奖"
                v-model="annualBonus"
                oninput="value=value.replace(/[^1-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g, '$1')"
              >
                <template slot="append">元</template>
              </el-input>
            </div>
          </div>
          <div class="block-b block-input">
            <span class="block-input-name">年度已预缴个税：</span>
            <div class="block-append">
              <el-input
                placeholder="请输入年度已预缴个税"
                v-model="beforehandTax"
                oninput="value=value.replace(/[^1-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g, '$1')"
              >
                <template slot="append">元</template>
              </el-input>
            </div>
          </div>
          <div class="block-b block-input">
            <span class="block-input-name">劳务报酬所得：</span>
            <div class="block-append">
              <el-input
                placeholder="请输入劳务报酬所得"
                v-model="laborSalary"
                oninput="value=value.replace(/[^1-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g, '$1')"
              >
                <template slot="append">元</template>
              </el-input>
            </div>
          </div>
          <div class="block-b block-input">
            <span class="block-input-name">稿酬所得：</span>
            <div class="block-append">
              <el-input
                placeholder="请输入稿酬所得"
                v-model="royaltiesSalary"
                oninput="value=value.replace(/[^1-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g, '$1')"
              >
                <template slot="append">元</template>
              </el-input>
            </div>
          </div>
          <div class="block-b block-input">
            <span class="block-input-name">特许权使用费所得：</span>
            <div class="block-append">
              <el-input
                placeholder="请输入特许权使用费所得"
                v-model="concessionSalary"
                oninput="value=value.replace(/[^1-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g, '$1')"
              >
                <template slot="append">元</template>
              </el-input>
            </div>
          </div>
        </div>
        <div class="counter-item-b">
          <div class="item-t-container">
            <div
              class="item-title item-title1"
              :class="{ act: isAct == 1 }"
              @click="isAct = 1"
            >
              社保公积金
            </div>
            <div
              class="item-title item-title2"
              :class="{ act: isAct == 2 }"
              @click="isAct = 2"
            >
              专项扣除
            </div>
          </div>
          <div v-show="isAct == 1" class="counter-center">
            <div class="block-t block-dis block-total">
              <span></span>
              <span class="subMonth">扣除月数</span>
            </div>
            <div class="flex-end input-item">
              <span class="counter-name">个人承担社保部分：</span>
              <div class="block-append-b">
                <el-input
                  placeholder="输入每月缴交社保"
                  v-model="socialTotal"
                  oninput="value=value.replace(/[^1-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                >
                  <template slot="append">元/月</template>
                </el-input>
              </div>
              <div class="add-content">
                <img @click="subSocial" src="@/assets/util/sub51.png" alt="" />
                <div class="add-month">{{ socialMonths }}</div>
                <img @click="addSocial" src="@/assets/util/add51.png" alt="" />
              </div>
            </div>
            <div class="flex-end input-item">
              <span class="counter-name">个人承担公积金部分：</span>
              <div class="block-append-b">
                <el-input
                  placeholder="输入每月缴交公积金"
                  v-model="publicTotal"
                  oninput="value=value.replace(/[^1-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                >
                  <template slot="append">元/月</template>
                </el-input>
              </div>
              <div class="add-content">
                <img @click="subPublic" src="@/assets/util/sub51.png" alt="" />
                <div class="add-month">{{ publicMonths }}</div>
                <img @click="addPublic" src="@/assets/util/add51.png" alt="" />
              </div>
            </div>
          </div>
          <div v-show="isAct == 2">
            <div class="block-t block-dis block-total">
              <span>专项扣除总额：{{ totalDiscount }}元</span>
              <span class="subMonth">扣除月数</span>
            </div>
            <div class="block-t block-dis">
              <span class="demonstration">住房租金：</span>
              <el-cascader
                v-model="rent"
                :options="rentList"
                :props="{ expandTrigger: 'hover' }"
                @change="rentChange"
                filterable
                clearable
                separator="-"
                placeholder="无住房租金专项"
                :disabled="houseLoan != 0"
              ></el-cascader>
              <div class="add-content">
                <img @click="subRentMonth" src="@/assets/util/sub51.png" alt="" />
                <div class="add-month">{{ rentMonth }}</div>
                <img @click="addRentMonth" src="@/assets/util/add51.png" alt="" />
              </div>
            </div>
            <div class="block-t block-dis">
              <span class="demonstration">房贷利息：</span>
              <el-cascader
                v-model="houseLoan"
                :options="houseLoanList"
                :props="{ expandTrigger: 'hover' }"
                @change="houseLoanChange"
                filterable
                clearable
                separator="-"
                placeholder="无房贷利息专项"
                :disabled="rent != 0"
              ></el-cascader>
              <div class="add-content">
                <img @click="subHouseLoan" src="@/assets/util/sub51.png" alt="" />
                <div class="add-month">{{ houseLoanMonth }}</div>
                <img @click="addHouseLoan" src="@/assets/util/add51.png" alt="" />
              </div>
            </div>
            <div class="block-t block-dis" title="选择扣除方式和子女数量">
              <span class="demonstration">子女教育：</span>
              <el-cascader
                v-model="ChildEdu"
                :options="childEduList"
                :props="{ expandTrigger: 'hover' }"
                @change="childEduChange"
                filterable
                clearable
                separator="-"
                placeholder="无子女教育专项"
              ></el-cascader>
              <div class="add-content">
                <img @click="subChildEdu" src="@/assets/util/sub51.png" alt="" />
                <div>{{ ChildEduMonth }}</div>
                <img @click="addChildEdu" src="@/assets/util/add51.png" alt="" />
              </div>
            </div>
            <div class="block-t block-dis">
              <span class="demonstration">继续教育：</span>
              <el-cascader
                v-model="conEdu"
                :options="conEduList"
                :props="{ expandTrigger: 'hover' }"
                @change="conEduChange"
                filterable
                clearable
                separator="-"
                placeholder="无继续教育专项"
              ></el-cascader>
              <div class="add-content" v-if="conEdu != 300">
                <img @click="subConEdu" src="@/assets/util/sub51.png" alt="" />
                <div class="add-month">{{ conEduMonth }}</div>
                <img @click="addConEdu" src="@/assets/util/add51.png" alt="" />
              </div>
            </div>
            <div class="block-t block-dis">
              <span class="demonstration">赡养老人：</span>
              <el-cascader
                v-model="supElderly"
                :options="supElderlyList"
                :props="{ expandTrigger: 'hover' }"
                @change="supElderlyChange"
                filterable
                clearable
                separator="-"
                placeholder="无赡养老人专项"
              ></el-cascader>
              <div class="add-content">
                <img @click="subSupElderly" src="@/assets/util/sub51.png" alt="" />
                <div class="add-month">{{ supElderlyMonth }}</div>
                <img @click="addSupElderly" src="@/assets/util/add51.png" alt="" />
              </div>
            </div>
            <transition name="slide-fade">
              <div class="block-t block-dis" v-show="supElderly == 1">
                <span class="demonstration block-demonstration">输入金额：</span>
                <div class="block-append block-appends block-price">
                  <el-input
                    placeholder="输入分摊金额，最高1000元"
                    v-model="supElderlyExcess"
                    @input="changeExcess"
                    oninput="value=value.replace(/[^1-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                  >
                    <template slot="append">元</template>
                  </el-input>
                </div>
              </div>
            </transition>
            <div class="block-t block-dis">
              <span class="demonstration">大病医疗：</span>
              <div class="block-append block-appends block-med">
                <el-input
                  placeholder="输入大病医疗扣除项金额"
                  v-model="medicalCosts"
                  @input="medicalChange"
                  oninput="value=value.replace(/[^1-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                >
                  <template slot="append">元</template>
                </el-input>
              </div>
            </div>
          </div>
          <!--  -->
          <div v-if="this.taxType == 1" class="item-btn ease" @click="calByAlone">
            马上计算
          </div>
          <div v-else class="item-btn ease" @click="calculate">马上计算</div>
        </div>
      </div>
      <div class="counter-item" v-show="resultPage">
        <div class="counter-data">
          <div class="circle-container">
            <div class="circle" id="circle"></div>
            <div class="circle-bottom">
              <div class="circle-text">
                <span class="circle-span"></span>
                <span class="circle-mine"
                  >税后工资：{{ resultPage && toThousands(afterSalary) }}元</span
                >
              </div>
              <div class="circle-text">
                <span class="circle-span circle-span1"></span>
                <span class="circle-mine"
                  >个人所得税：{{ resultPage && toThousands(totalTax) }}元</span
                >
              </div>
              <div class="circle-text">
                <span class="circle-span circle-span2"></span>
                <span class="circle-mine"
                  >五险一金：{{
                    resultPage && toThousands(socialYear + publicYear)
                  }}元</span
                >
              </div>
            </div>
          </div>
          <div class="item-t-container item-t-right">
            <div
              class="item-title item-title1"
              :class="{ act: isActShow == 1 }"
              @click="isActShow = 1"
            >
              社保公积金
            </div>
            <div
              class="item-title item-title2"
              :class="{ act: isActShow == 2 }"
              @click="isActShow = 2"
            >
              专项扣除
            </div>
          </div>
          <div v-show="isActShow == 1">
            <div class="social">
              <div class="item">
                <span>年终奖计税方式</span>
                <span>
                  <el-select v-model="taxType" placeholder="请选择" @change="typeChange">
                    <el-option
                      v-for="item in taxTypeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option> </el-select
                ></span>
              </div>
              <div class="item">
                <span>年度税前收入</span>
                <span>{{ resultPage && toThousands(totalSalary) }}元</span>
              </div>
              <div class="item">
                <span>年度个人承担社保</span>
                <span>{{ resultPage && toThousands(socialYear) }}元</span>
              </div>
              <div class="item">
                <span>年度个人承担公积金</span>
                <span>{{ resultPage && toThousands(publicYear) }}元</span>
              </div>
              <div class="item">
                <span>年度应缴个税</span>
                <span>{{ resultPage && toThousands(totalTax) }}元</span>
              </div>
              <div class="item">
                <span>年度已预缴个税</span>
                <span>{{ resultPage && toThousands(beforehandTax) }}元</span>
              </div>
              <div class="item">
                <span
                  >应<span>{{ addOrDecTax > 0 ? "补" : "退" }}</span
                  >税额</span
                >
                <span>{{ resultPage && toThousands(Math.abs(addOrDecTax)) }}元</span>
              </div>
              <div class="result">
                <span class="result-txt weight">税后收入</span>
              </div>
              <div class="result">
                <span class="result-price"
                  >{{ resultPage && toThousands(afterSalary) }}元</span
                >
              </div>
            </div>
          </div>
          <div v-show="isActShow == 2">
            <div class="social">
              <div class="item">
                <span>住房租金</span>
                <span>{{ resultPage && toThousands(rentDiscount) }}元</span>
              </div>
              <div class="item">
                <span>房贷利息</span>
                <span>{{ resultPage && toThousands(loanDiscount) }}元</span>
              </div>
              <div class="item">
                <span>子女教育</span>
                <span>{{ resultPage && toThousands(childDiscount) }}元</span>
              </div>
              <div class="item">
                <span>继续教育</span>
                <span>{{ resultPage && toThousands(conEduDiscount) }}元</span>
              </div>
              <div class="item">
                <span>赡养老人</span>
                <span>{{ resultPage && toThousands(supElderlyDiscount) }}元</span>
              </div>
              <div class="item">
                <span>大病医疗</span>
                <span>{{ resultPage && toThousands(medicalDiscount) }}元</span>
              </div>
              <div class="item">
                <span class="weight">合计扣除（元）</span>
                <span>{{ resultPage && toThousands(totalDiscount) }}元</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import echarts from "echarts";
export default {
  components: {
    BreadCrumb,
  },
  data() {
    return {
      rateB: 0.03,
      rateNumB: 0,
      //
      taxType: "0",
      taxTypeList: [
        {
          value: "0",
          label: "全部并入综合所得计税",
        },
        {
          value: "1",
          label: "单独计税",
        },
      ],
      //
      addOrDecTax: "", //是否补税
      totalSalary: "", // 总收入
      afterSalary: "", // 税后收入
      city: [],
      totalDiscount: 0, //总专项扣除
      subBaseRate: 0.03,
      subBasePrice: 0,
      totalTax: "",

      // 收入项
      salary: "", //税前工资
      annualBonus: "", //年终奖
      laborSalary: "", //劳务报酬所得
      royaltiesSalary: "", //稿费所得
      concessionSalary: "", //特许权使用费所得
      beforehandTax: "", //预缴个税
      // 收入项

      // 社保公积金
      socialTotal: "",
      socialMonths: 12, //社保月份
      socialYear: "",
      publicTotal: "",
      publicMonths: 12, //公积金月份
      publicYear: "",
      // 社保公积金

      // 扣除项
      rent: "",
      rentMonth: 12,
      houseLoan: "",
      houseLoanMonth: 12,
      ChildEdu: "",
      ChildEduMonth: 12,
      conEdu: "",
      conEduMonth: 12,
      supElderly: "",
      supElderlyMonth: 12,
      supElderlyExcess: "",
      medicalCosts: "",
      // 扣除项

      //
      rentDiscount: 0,
      loanDiscount: 0,
      childDiscount: 0,
      conEduDiscount: 0,
      supElderlyDiscount: 0,
      medicalDiscount: 0,
      //

      resultPage: false,
      haveMedical: false,
      havePer: true,
      quickNum: "",
      Percentage: "",
      socialbase: "",
      social: 0,
      accumulation: 0,
      salarybase: "",
      income: 0,
      result: 0,
      medical: null,
      rate1: 8,
      rate2: 2,
      rate3: 0.5,
      rate4: 0,
      rate5: 0,
      rate6: 12,
      base1: 0,
      base2: 0,
      base3: 0,
      base4: 0,
      base5: 0,
      base6: 0,
      baseAll: 0,
      result: 0,
      isAct: 1,
      isActShow: 1,
      discount1: 1500,
      discount2: 0,
      discount3: 0,
      discount4: 0,
      discount5: 0,
      discount5: 0,
      discount6: 0,
      // 绑定数据
      discount11: 1500,
      houseLoan: 0,
      ChildEdu: 0,
      conEdu: 0,
      supElderly: 0,
      discount16: "",
      discountShow1: 0,
      discountShow2: 0,
      discountShow3: 0,
      discountShow4: 0,
      discountShow5: 0,
      discountShow5: 0,
      discountShow6: 0,
      discountAll: 0,
      input1: 0.08,
      haveCity: [],
      input: "",
      options: [
        {
          value: "北京市",
          label: "北京市",
          children: [
            {
              value: "北京市",
              label: "北京市",
            },
          ],
        },
        {
          value: "天津市",
          label: "天津市",
          children: [
            {
              value: "天津市",
              label: "天津市",
            },
          ],
        },
        {
          value: "上海市",
          label: "上海市",
          children: [
            {
              value: "上海市",
              label: "上海市",
            },
          ],
        },
        {
          value: "重庆市",
          label: "重庆市",
          children: [
            {
              value: "重庆市",
              label: "重庆市",
            },
          ],
        },
        {
          value: "广东省",
          label: "广东省",
          children: [
            {
              value: "广州市",
              label: "广州市",
            },
            {
              value: "深圳市",
              label: "深圳市",
            },
            {
              value: "东莞市",
              label: "东莞市",
            },
            {
              value: "中山市",
              label: "中山市",
            },
            {
              value: "潮州市",
              label: "潮州市",
            },
            {
              value: "揭阳市",
              label: "揭阳市",
            },
            {
              value: "云浮市",
              label: "云浮市",
            },
            {
              value: "云浮市",
              label: "云浮市",
            },
            {
              name: "珠海市",
              label: "珠海市",
            },
            {
              name: "汕头市",
              label: "汕头市",
            },
            {
              name: "韶关市",
              label: "韶关市",
            },
            {
              name: "佛山市",
              label: "佛山市",
            },
            {
              name: "江门市",
              label: "江门市",
            },
            {
              name: "湛江市",
              label: "湛江市",
            },
            {
              name: "茂名市",
              label: "茂名市",
            },
            {
              name: "肇庆市",
              label: "肇庆市",
            },
            {
              name: "惠州市",
              label: "惠州市",
            },
            {
              name: "梅州市",
              label: "梅州市",
            },
            {
              name: "汕尾市",
              label: "汕尾市",
            },
            {
              name: "河源市",
              label: "河源市",
            },
            {
              name: "阳江市",
              label: "阳江市",
            },
            {
              name: "清远市",
              label: "清远市",
            },
          ],
        },
        {
          value: "浙江省",
          label: "浙江省",
          children: [
            {
              value: "杭州市",
              label: "杭州市",
            },
            {
              value: "宁波市",
              label: "宁波市",
            },
            {
              value: "温州市",
              label: "温州市",
            },
            {
              value: "嘉兴市",
              label: "嘉兴市",
            },
            {
              value: "湖州市",
              label: "湖州市",
            },
            {
              value: "绍兴市",
              label: "绍兴市",
            },
            {
              value: "金华市",
              label: "金华市",
            },
            {
              value: "衢州市",
              label: "衢州市",
            },
            {
              value: "舟山市",
              label: "舟山市",
            },
            {
              value: "台州市",
              label: "台州市",
            },
            {
              value: "丽水市",
              label: "丽水市",
            },
          ],
        },
        {
          value: "江苏省",
          label: "江苏省",
          children: [
            {
              value: "南京市",
              label: "南京市",
            },
            {
              value: "苏州市",
              label: "苏州市",
            },
            {
              value: "无锡市",
              label: "无锡市",
            },
            {
              value: "常州市",
              label: "常州市",
            },
            {
              value: "镇江市",
              label: "镇江市",
            },
            {
              value: "南通市",
              label: "南通市",
            },
            {
              value: "泰州市",
              label: "泰州市",
            },
            {
              value: "扬州市",
              label: "扬州市",
            },
            {
              value: "盐城市",
              label: "盐城市",
            },
            {
              value: "连云港市",
              label: "连云港市",
            },
            {
              value: "徐州市",
              label: "徐州市",
            },
            {
              value: "淮安市",
              label: "淮安市",
            },
            {
              value: "宿迁市",
              label: "宿迁市",
            },
          ],
        },
        {
          value: "福建省",
          label: "福建省",
          children: [
            {
              value: "福州市",
              label: "福州市",
            },
            {
              value: "厦门市",
              label: "厦门市",
            },
            {
              value: "莆田市",
              label: "莆田市",
            },
            {
              value: "三明市",
              label: "三明市",
            },
            {
              value: "泉州市",
              label: "泉州市",
            },
            {
              value: "漳州市",
              label: "漳州市",
            },
            {
              value: "南平市",
              label: "南平市",
            },
            {
              value: "龙岩市",
              label: "龙岩市",
            },
            {
              value: "宁德市",
              label: "宁德市",
            },
          ],
        },
        {
          value: "河北省",
          label: "河北省",
          children: [
            {
              value: "石家庄市",
              label: "石家庄市",
            },
            {
              value: "唐山市",
              label: "唐山市",
            },
            {
              value: "秦皇岛市",
              label: "秦皇岛市",
            },
            {
              value: "邯郸市",
              label: "邯郸市",
            },
            {
              value: "邢台市",
              label: "邢台市",
            },
            {
              value: "保定市",
              label: "保定市",
            },
            {
              value: "张家口市",
              label: "张家口市",
            },
            {
              value: "承德市",
              label: "承德市",
            },
            {
              value: "沧州市",
              label: "沧州市",
            },
            {
              value: "廊坊市",
              label: "廊坊市",
            },
            {
              value: "衡水市",
              label: "衡水市",
            },
          ],
        },
        {
          value: "河南省",
          label: "河南省",
          children: [
            {
              value: "郑州市",
              label: "郑州市",
            },
            {
              value: "开封市",
              label: "开封市",
            },
            {
              value: "洛阳市",
              label: "洛阳市",
            },
            {
              value: "平顶山市",
              label: "平顶山市",
            },
            {
              value: "安阳市",
              label: "安阳市",
            },
            {
              value: "鹤壁市",
              label: "鹤壁市",
            },
            {
              value: "新乡市",
              label: "新乡市",
            },
            {
              value: "焦作市",
              label: "焦作市",
            },
            {
              value: "濮阳市",
              label: "濮阳市",
            },
            {
              value: "许昌市",
              label: "许昌市",
            },
            {
              value: "漯河市",
              label: "漯河市",
            },
            {
              value: "三门峡市",
              label: "三门峡市",
            },
            {
              value: "南阳市",
              label: "南阳市",
            },
            {
              value: "商丘市",
              label: "商丘市",
            },
            {
              value: "信阳市",
              label: "信阳市",
            },
            {
              value: "周口市",
              label: "周口市",
            },
            {
              value: "驻马店市",
              label: "驻马店市",
            },
            {
              value: "焦作市",
              label: "焦作市",
            },
          ],
        },
        {
          value: "山西省",
          label: "山西省",
          children: [
            {
              value: "太原市",
              label: "太原市",
            },
            {
              value: "大同市",
              label: "大同市",
            },
            {
              value: "阳泉市",
              label: "阳泉市",
            },
            {
              value: "长治市",
              label: "长治市",
            },
            {
              value: "晋城市",
              label: "晋城市",
            },
            {
              value: "朔州市",
              label: "朔州市",
            },
            {
              value: "忻州市",
              label: "忻州市",
            },
            {
              value: "吕梁市",
              label: "吕梁市",
            },
            {
              value: "晋中市",
              label: "晋中市",
            },
            {
              value: "临汾市",
              label: "临汾市",
            },
            {
              value: "运城市",
              label: "运城市",
            },
          ],
        },
        {
          value: "山东省",
          label: "山东省",
          children: [
            {
              value: "济南市",
              label: "济南市",
            },
            {
              value: "青岛市",
              label: "青岛市",
            },
            {
              value: "淄博市",
              label: "淄博市",
            },
            {
              value: "枣庄市",
              label: "枣庄市",
            },
            {
              value: "东营市",
              label: "东营市",
            },
            {
              value: "烟台市",
              label: "烟台市",
            },
            {
              value: "潍坊市",
              label: "潍坊市",
            },
            {
              value: "济宁市",
              label: "济宁市",
            },
            {
              value: "泰安市",
              label: "泰安市",
            },
            {
              value: "威海市",
              label: "威海市",
            },
            {
              value: "日照市",
              label: "日照市",
            },

            {
              value: "莱芜市",
              label: "莱芜市",
            },
            {
              value: "临沂市",
              label: "临沂市",
            },
            {
              value: "德州市",
              label: "德州市",
            },
            {
              value: "聊城市",
              label: "聊城市",
            },
            {
              value: "滨州市",
              label: "滨州市",
            },
            {
              value: "菏泽市",
              label: "菏泽市",
            },
          ],
        },
        {
          value: "辽宁省",
          label: "辽宁省",
          children: [
            {
              value: "沈阳市",
              label: "沈阳市",
            },
            {
              value: "大连市",
              label: "大连市",
            },
            {
              value: "鞍山市",
              label: "鞍山市",
            },
            {
              value: "抚顺市",
              label: "抚顺市",
            },
            {
              value: "本溪市",
              label: "本溪市",
            },
            {
              value: "丹东市",
              label: "丹东市",
            },
            {
              value: "锦州市",
              label: "锦州市",
            },
            {
              value: "营口市",
              label: "营口市",
            },
            {
              value: "阜新市",
              label: "阜新市",
            },
            {
              value: "辽阳市",
              label: "辽阳市",
            },
            {
              value: "盘锦市",
              label: "盘锦市",
            },

            {
              value: "铁岭市",
              label: "铁岭市",
            },
            {
              value: "朝阳市",
              label: "朝阳市",
            },
            {
              value: "葫芦岛市",
              label: "葫芦岛市",
            },
          ],
        },
        {
          value: "吉林省",
          label: "吉林省",
          children: [
            {
              value: "长春市",
              label: "长春市",
            },
            {
              value: "吉林市",
              label: "吉林市",
            },
            {
              value: "四平市",
              label: "四平市",
            },
            {
              value: "辽源市",
              label: "辽源市",
            },
            {
              value: "通化市",
              label: "通化市",
            },
            {
              value: "白山市",
              label: "白山市",
            },
            {
              value: "松原市",
              label: "松原市",
            },
            {
              value: "白城市",
              label: "白城市",
            },
            {
              value: "延边朝鲜族自治州",
              label: "延边朝鲜族自治州",
            },
          ],
        },
        {
          value: "黑龙江省",
          label: "黑龙江省",
          children: [
            {
              value: "哈尔滨市",
              label: "哈尔滨市",
            },
            {
              value: "齐齐哈尔市",
              label: "齐齐哈尔市",
            },
            {
              value: "鹤岗市",
              label: "鹤岗市",
            },
            {
              value: "双鸭山市",
              label: "双鸭山市",
            },
            {
              value: "鸡西市",
              label: "鸡西市",
            },
            {
              value: "大庆市",
              label: "大庆市",
            },
            {
              value: "伊春市",
              label: "伊春市",
            },
            {
              value: "牡丹江市",
              label: "牡丹江市",
            },
            {
              value: "佳木斯市",
              label: "佳木斯市",
            },
            {
              value: "七台河市",
              label: "七台河市",
            },
            {
              value: "黑河市",
              label: "黑河市",
            },
            {
              value: "绥化市",
              label: "绥化市",
            },
            {
              value: "大兴安岭地区市",
              label: "大兴安岭地区市",
            },
          ],
        },
        {
          value: "陕西省",
          label: "陕西省",
          children: [
            {
              value: "西安市",
              label: "西安市",
            },
            {
              value: "铜川市",
              label: "铜川市",
            },
            {
              value: "宝鸡市",
              label: "宝鸡市",
            },
            {
              value: "咸阳市",
              label: "咸阳市",
            },
            {
              value: "渭南市",
              label: "渭南市",
            },
            {
              value: "延安市",
              label: "延安市",
            },
            {
              value: "汉中市",
              label: "汉中市",
            },
            {
              value: "榆林市",
              label: "榆林市",
            },
            {
              value: "安康市",
              label: "安康市",
            },
            {
              value: "商洛市",
              label: "商洛市",
            },
          ],
        },
        {
          value: "安徽省",
          label: "安徽省",
          children: [
            {
              value: "合肥市",
              label: "合肥市",
            },
            {
              value: "芜湖市",
              label: "芜湖市",
            },
            {
              value: "蚌埠市",
              label: "蚌埠市",
            },
            {
              value: "淮南市",
              label: "淮南市",
            },
            {
              value: "马鞍山市",
              label: "马鞍山市",
            },
            {
              value: "淮北市",
              label: "淮北市",
            },
            {
              value: "铜陵市",
              label: "铜陵市",
            },
            {
              value: "安庆市",
              label: "安庆市",
            },
            {
              value: "黄山市",
              label: "黄山市",
            },
            {
              value: "滁州市",
              label: "滁州市",
            },
            {
              value: "阜阳市",
              label: "阜阳市",
            },
            {
              value: "宿州市",
              label: "宿州市",
            },
            {
              value: "巢湖市",
              label: "巢湖市",
            },
            {
              value: "六安市",
              label: "六安市",
            },
            {
              value: "亳州市",
              label: "亳州市",
            },
            {
              value: "池州市",
              label: "池州市",
            },
            {
              value: "宣城市",
              label: "宣城市",
            },
          ],
        },
        {
          value: "江西省",
          label: "江西省",
          children: [
            {
              value: "南昌市",
              label: "南昌市",
            },
            {
              value: "景德镇市",
              label: "景德镇市",
            },
            {
              value: "萍乡市",
              label: "萍乡市",
            },
            {
              value: "九江市",
              label: "九江市",
            },
            {
              value: "新余市",
              label: "新余市",
            },
            {
              value: "鹰潭市",
              label: "鹰潭市",
            },
            {
              value: "赣州市",
              label: "赣州市",
            },
            {
              value: "吉安市",
              label: "吉安市",
            },
            {
              value: "宜春市",
              label: "宜春市",
            },
            {
              value: "抚州市",
              label: "抚州市",
            },
            {
              value: "上饶市",
              label: "上饶市",
            },
          ],
        },
        {
          value: "湖北省",
          label: "湖北省",
          children: [
            {
              value: "武汉市",
              label: "武汉市",
            },
            {
              value: "黄石市",
              label: "黄石市",
            },
            {
              value: "十堰市",
              label: "十堰市",
            },
            {
              value: "荆州市",
              label: "荆州市",
            },
            {
              value: "宜昌市",
              label: "宜昌市",
            },
            {
              value: "襄樊市",
              label: "襄樊市",
            },
            {
              value: "鄂州市",
              label: "鄂州市",
            },
            {
              value: "荆门市",
              label: "荆门市",
            },
            {
              value: "孝感市",
              label: "孝感市",
            },
            {
              value: "黄冈市",
              label: "黄冈市",
            },
            {
              value: "咸宁市",
              label: "咸宁市",
            },

            {
              value: "随州市",
              label: "随州市",
            },
            {
              value: "恩施土家族苗族自治州",
              label: "恩施土家族苗族自治州",
            },
            {
              value: "仙桃市",
              label: "仙桃市",
            },
            {
              value: "天门市",
              label: "天门市",
            },
            {
              value: "潜江市",
              label: "潜江市",
            },
            {
              value: "神农架林区市",
              label: "神农架林区市",
            },
          ],
        },
        {
          value: "湖南省",
          label: "湖南省",
          children: [
            {
              value: "长沙市",
              label: "长沙市",
            },
            {
              value: "株洲市",
              label: "株洲市",
            },
            {
              value: "湘潭市",
              label: "湘潭市",
            },
            {
              value: "衡阳市",
              label: "衡阳市",
            },
            {
              value: "邵阳市",
              label: "邵阳市",
            },
            {
              value: "岳阳市",
              label: "岳阳市",
            },
            {
              value: "常德市",
              label: "常德市",
            },
            {
              value: "张家界市",
              label: "张家界市",
            },
            {
              value: "益阳市",
              label: "益阳市",
            },
            {
              value: "郴州市",
              label: "郴州市",
            },
            {
              value: "永州市",
              label: "永州市",
            },

            {
              value: "怀化市",
              label: "怀化市",
            },
            {
              value: "娄底市",
              label: "娄底市",
            },
            {
              value: "湘西土家族苗族自治州",
              label: "湘西土家族苗族自治州",
            },
          ],
        },
        {
          value: "四川省",
          label: "四川省",
          children: [
            {
              value: "成都市",
              label: "成都市",
            },
            {
              value: "自贡市",
              label: "自贡市",
            },
            {
              value: "攀枝花市",
              label: "攀枝花市",
            },
            {
              value: "泸州市",
              label: "泸州市",
            },
            {
              value: "德阳市",
              label: "德阳市",
            },
            {
              value: "绵阳市",
              label: "绵阳市",
            },
            {
              value: "广元市",
              label: "广元市",
            },
            {
              value: "遂宁市",
              label: "遂宁市",
            },
            {
              value: "内江市",
              label: "内江市",
            },
            {
              value: "乐山市",
              label: "乐山市",
            },
            {
              value: "南充市",
              label: "南充市",
            },

            {
              value: "眉山市",
              label: "眉山市",
            },
            {
              value: "宜宾市",
              label: "宜宾市",
            },
            {
              value: "广安市",
              label: "广安市",
            },

            {
              value: "达州市",
              label: "达州市",
            },
            {
              value: "雅安市",
              label: "雅安市",
            },
            {
              value: "巴中市",
              label: "巴中市",
            },
            {
              value: "资阳市",
              label: "资阳市",
            },
            {
              value: "阿坝藏族羌族自治州",
              label: "阿坝藏族羌族自治州",
            },
            {
              value: "甘孜藏族自治州",
              label: "甘孜藏族自治州",
            },
            {
              value: "凉山彝族自治州",
              label: "凉山彝族自治州",
            },
          ],
        },
        {
          value: "贵州省",
          label: "贵州省",
          children: [
            {
              value: "贵阳市",
              label: "贵阳市",
            },
            {
              value: "六盘水市",
              label: "六盘水市",
            },
            {
              value: "遵义市",
              label: "遵义市",
            },
            {
              value: "安顺市",
              label: "安顺市",
            },
            {
              value: "铜仁地区市",
              label: "铜仁地区市",
            },
            {
              value: "毕节地区市",
              label: "毕节地区市",
            },
            {
              value: "黔西南布依族苗族自治州",
              label: "黔西南布依族苗族自治州",
            },
            {
              value: "黔东南苗族侗族自治州",
              label: "黔东南苗族侗族自治州",
            },
            {
              value: "黔南布依族苗族自治州",
              label: "黔南布依族苗族自治州",
            },
          ],
        },
        {
          value: "云南省",
          label: "云南省",
          children: [
            {
              value: "昆明市",
              label: "昆明市",
            },
            {
              value: "曲靖市",
              label: "曲靖市",
            },
            {
              value: "玉溪市",
              label: "玉溪市",
            },
            {
              value: "保山市",
              label: "保山市",
            },
            {
              value: "昭通市",
              label: "昭通市",
            },
            {
              value: "丽江市",
              label: "丽江市",
            },
            {
              value: "普洱市",
              label: "普洱市",
            },
            {
              value: "临沧市",
              label: "临沧市",
            },
            {
              value: "德宏傣族景颇族自治州",
              label: "德宏傣族景颇族自治州",
            },

            {
              value: "怒江傈僳族自治州",
              label: "怒江傈僳族自治州",
            },
            {
              value: "迪庆藏族自治州",
              label: "迪庆藏族自治州",
            },
            {
              value: "大理白族自治州",
              label: "大理白族自治州",
            },
            {
              value: "楚雄彝族自治州",
              label: "楚雄彝族自治州",
            },
            {
              value: "红河哈尼族彝族自治州",
              label: "红河哈尼族彝族自治州",
            },
            {
              value: "文山壮族苗族自治州",
              label: "文山壮族苗族自治州",
            },
            {
              value: "西双版纳傣族自治州",
              label: "西双版纳傣族自治州",
            },
          ],
        },
        {
          value: "广西省",
          label: "广西省",
          children: [
            {
              value: "南宁市",
              label: "南宁市",
            },
            {
              value: "柳州市",
              label: "柳州市",
            },
            {
              value: "桂林市",
              label: "桂林市",
            },
            {
              value: "梧州市",
              label: "梧州市",
            },
            {
              value: "北海市",
              label: "北海市",
            },
            {
              value: "防城港市",
              label: "防城港市",
            },
            {
              value: "钦州市",
              label: "钦州市",
            },
            {
              value: "贵港市",
              label: "贵港市",
            },
            {
              value: "玉林市",
              label: "玉林市",
            },

            {
              value: "百色市",
              label: "百色市",
            },
            {
              value: "贺州市",
              label: "贺州市",
            },
            {
              value: "河池市",
              label: "河池市",
            },
            {
              value: "来宾市",
              label: "来宾市",
            },
            {
              value: "崇左市",
              label: "崇左市",
            },
          ],
        },
        {
          value: "海南省",
          label: "海南省",
          children: [
            {
              value: "海口市",
              label: "海口市",
            },
            {
              value: "三亚市",
              label: "三亚市",
            },
            {
              value: "五指山市",
              label: "五指山市",
            },
            {
              value: "琼海市",
              label: "琼海市",
            },
            {
              value: "儋州市",
              label: "儋州市",
            },
            {
              value: "文昌市",
              label: "文昌市",
            },
            {
              value: "万宁市",
              label: "万宁市",
            },
            {
              value: "东方市",
              label: "东方市",
            },
            {
              value: "澄迈县",
              label: "澄迈县",
            },

            {
              value: "定安县",
              label: "定安县",
            },
            {
              value: "屯昌县",
              label: "屯昌县",
            },
            {
              value: "临高县",
              label: "临高县",
            },
            {
              value: "白沙黎族自治县",
              label: "白沙黎族自治县",
            },
            {
              value: "昌江黎族自治县",
              label: "昌江黎族自治县",
            },
            {
              value: "乐东黎族自治县",
              label: "乐东黎族自治县",
            },
            {
              value: "陵水黎族自治县",
              label: "陵水黎族自治县",
            },
            {
              value: "保亭黎族苗族自治县",
              label: "保亭黎族苗族自治县",
            },
            {
              value: "琼中黎族苗族自治县",
              label: "琼中黎族苗族自治县",
            },
          ],
        },
        {
          value: "甘肃省",
          label: "甘肃省",
          children: [
            {
              value: "兰州市",
              label: "兰州市",
            },
            {
              value: "嘉峪关市",
              label: "嘉峪关市",
            },
            {
              value: "金昌市",
              label: "金昌市",
            },
            {
              value: "白银市",
              label: "白银市",
            },
            {
              value: "天水市",
              label: "天水市",
            },
            {
              value: "武威市",
              label: "武威市",
            },
            {
              value: "酒泉市",
              label: "酒泉市",
            },
            {
              value: "张掖市",
              label: "张掖市",
            },
            {
              value: "庆阳市",
              label: "庆阳市",
            },

            {
              value: "平凉市",
              label: "平凉市",
            },
            {
              value: "定西市",
              label: "定西市",
            },
            {
              value: "陇南市",
              label: "陇南市",
            },
            {
              value: "临夏回族自治州",
              label: "临夏回族自治州",
            },
            {
              value: "甘南藏族自治州",
              label: "甘南藏族自治州",
            },
          ],
        },
        {
          value: "青海省",
          label: "青海省",
          children: [
            {
              value: "西宁市",
              label: "西宁市",
            },
            {
              value: "海东地区市",
              label: "海东地区市",
            },
            {
              value: "海北藏族自治州",
              label: "海北藏族自治州",
            },
            {
              value: "海南藏族自治州",
              label: "海南藏族自治州",
            },
            {
              value: "黄南藏族自治州",
              label: "黄南藏族自治州",
            },
            {
              value: "果洛藏族自治州",
              label: "果洛藏族自治州",
            },
            {
              value: "玉树藏族自治州",
              label: "玉树藏族自治州",
            },
            {
              value: "海西蒙古族藏族自治州",
              label: "海西蒙古族藏族自治州",
            },
          ],
        },
        {
          value: "内蒙古",
          label: "内蒙古",
          children: [
            {
              value: "呼和浩特市",
              label: "呼和浩特市",
            },
            {
              value: "包头市",
              label: "包头市",
            },
            {
              value: "乌海市",
              label: "乌海市",
            },
            {
              value: "赤峰市",
              label: "赤峰市",
            },
            {
              value: "呼伦贝尔市",
              label: "呼伦贝尔市",
            },
            {
              value: "兴安盟市",
              label: "兴安盟市",
            },
            {
              value: "通辽市",
              label: "通辽市",
            },
            {
              value: "锡林郭勒盟市",
              label: "锡林郭勒盟市",
            },
            {
              value: "乌兰察布盟市",
              label: "乌兰察布盟市",
            },
            {
              value: "伊克昭盟市",
              label: "伊克昭盟市",
            },
            {
              value: "巴彦淖尔盟市",
              label: "巴彦淖尔盟市",
            },
            {
              value: "阿拉善盟市",
              label: "阿拉善盟市",
            },
          ],
        },
        {
          value: "宁夏",
          label: "宁夏",
          children: [
            {
              value: "银川市",
              label: "银川市",
            },
            {
              value: "石嘴山市",
              label: "石嘴山市",
            },
            {
              value: "吴忠市",
              label: "吴忠市",
            },
            {
              value: "固原市",
              label: "固原市",
            },
            {
              value: "中卫市",
              label: "中卫市",
            },
          ],
        },
        {
          value: "西藏",
          label: "西藏",
          children: [
            {
              value: "拉萨市",
              label: "拉萨市",
            },
            {
              value: "那曲地区市",
              label: "那曲地区市",
            },
            {
              value: "昌都地区市",
              label: "昌都地区市",
            },
            {
              value: "林芝地区市",
              label: "林芝地区市",
            },
            {
              value: "山南地区市",
              label: "山南地区市",
            },
            {
              value: "日喀则地区市",
              label: "日喀则地区市",
            },
            {
              value: "阿里地区市",
              label: "阿里地区市",
            },
          ],
        },
        {
          value: "新疆",
          label: "新疆",
          children: [
            {
              value: "乌鲁木齐市",
              label: "乌鲁木齐市",
            },
            {
              value: "克拉玛依市",
              label: "克拉玛依市",
            },
            {
              value: "吐鲁番地区市",
              label: "吐鲁番地区市",
            },
            {
              value: "哈密地区市",
              label: "哈密地区市",
            },
            {
              value: "和田地区市",
              label: "和田地区市",
            },
            {
              value: "阿克苏地区市",
              label: "阿克苏地区市",
            },
            {
              value: "喀什地区市",
              label: "喀什地区市",
            },
            {
              value: "克孜勒苏柯尔克孜自治州",
              label: "克孜勒苏柯尔克孜自治州",
            },
            {
              value: "巴音郭楞蒙古自治州",
              label: "巴音郭楞蒙古自治州",
            },
            {
              value: "昌吉回族自治州",
              label: "昌吉回族自治州",
            },

            {
              value: "博尔塔拉蒙古自治州",
              label: "博尔塔拉蒙古自治州",
            },
            {
              value: "石河子市",
              label: "石河子市",
            },
            {
              value: "阿拉尔市",
              label: "阿拉尔市",
            },
            {
              value: "图木舒克市",
              label: "图木舒克市",
            },
            {
              value: "五家渠市",
              label: "五家渠市",
            },
            {
              value: "伊犁哈萨克自治州",
              label: "伊犁哈萨克自治州",
            },
          ],
        },
        {
          value: "台湾",
          label: "台湾",
        },
        {
          value: "澳门",
          label: "澳门",
        },
        {
          value: "香港",
          label: "香港",
        },
      ],
      rentList: [
        {
          value: "1",
          label: "当前城市扣除" + this.input1,
        },
      ],
      houseLoanList: [
        {
          value: "1000",
          label: "住房贷款利息扣除1000元/月",
        },
      ],
      childEduList: [
        {
          value: "0.5",
          label: "双方各扣50%",
          children: [
            {
              value: "1000",
              label: "1个子女,500元/月",
            },
            {
              value: "2000",
              label: "2个子女,1000元/月",
            },
            {
              value: "3000",
              label: "3个子女,1500元/月",
            },
            {
              value: "4000",
              label: "4个子女,2000元/月",
            },
            {
              value: "5000",
              label: "5个子女,2500元/月",
            },
          ],
        },
        {
          value: "1",
          label: "一方扣除100%",
          children: [
            {
              value: "1000",
              label: "1个子女,1000元/月",
            },
            {
              value: "2000",
              label: "2个子女,2000元/月",
            },
            {
              value: "3000",
              label: "3个子女,3000元/月",
            },
            {
              value: "4000",
              label: "4个子女,4000元/月",
            },
            {
              value: "5000",
              label: "5个子女,5000元/月",
            },
          ],
        },
      ],
      conEduList: [
        {
          value: "400",
          label: "学历继续教育扣除400元/月",
        },
        {
          value: "300",
          label: "职业资格继续教育扣除3600元/年",
        },
      ],
      supElderlyList: [
        {
          value: "2000",
          label: "独生子女扣除2000元/月",
        },
        {
          value: "1000",
          label: "两位子女平均分摊扣除1000元/月",
        },
        {
          value: "1",
          label: "赡养人约定分摊金额",
        },
      ],
      options6: [
        {
          value: "15000",
          label: "本年度医疗自费超过1.5万元金额，超出部分填写在右侧",
        },
      ],
    };
  },
  mounted() {
    this.rentList = [
      {
        value: "1",
        label: "当前城市扣除" + this.discount1 + "元/月",
      },
    ];
  },
  watch: {
    discount1(newVal, oldVal) {
      this.rentList = [
        {
          value: "1",
          label: "当前城市扣除" + newVal + "元/月",
        },
      ];
      if (this.haveCity.length) {
        this.discountShow1 = newVal;
      }
    },
  },
  methods: {
    typeChange() {
      console.log(this.taxType);
      if (this.taxType == 1) {
        this.calByAlone();
      } else {
        this.calculate();
      }
    },
    //
    addSocial() {
      if (this.socialMonths >= 12) return;
      this.socialMonths += 1;
    },
    subSocial() {
      if (this.socialMonths <= 1) return;
      this.socialMonths -= 1;
    },
    addPublic() {
      if (this.publicMonths >= 12) return;
      this.publicMonths += 1;
    },
    subPublic() {
      if (this.publicMonths <= 1) return;
      this.publicMonths -= 1;
    },
    addRentMonth() {
      if (this.rentMonth >= 12) return;
      this.rentMonth += 1;
      if (this.rent.length == 0 || this.houseLoan.length > 0) return;
      this.rentDiscount = Number(this.discount1) * this.rentMonth;
      this.changeSubItem();
    },
    subRentMonth() {
      if (this.rentMonth <= 1) return;
      this.rentMonth -= 1;
      if (this.rent.length == 0 || this.houseLoan.length > 0) return;
      this.rentDiscount = Number(this.discount1) * this.rentMonth;
      this.changeSubItem();
    },
    addHouseLoan() {
      if (this.houseLoanMonth >= 12) return;
      this.houseLoanMonth += 1;
      if (this.houseLoan.length == 0 || this.rent.length > 0) return;
      this.loanDiscount = 1000 * this.houseLoanMonth;
      this.changeSubItem();
    },
    subHouseLoan() {
      if (this.houseLoanMonth <= 1) return;
      this.houseLoanMonth -= 1;
      if (this.houseLoan.length == 0 || this.rent.length > 0) return;
      this.loanDiscount = 1000 * this.houseLoanMonth;
      this.changeSubItem();
    },
    addChildEdu() {
      if (this.ChildEduMonth >= 12) return;
      this.ChildEduMonth += 1;
      this.childDiscount = this.discountShow3 * this.ChildEduMonth;
      this.changeSubItem();
    },
    subChildEdu() {
      if (this.ChildEduMonth <= 1) return;
      this.ChildEduMonth -= 1;
      this.childDiscount = this.discountShow3 * this.ChildEduMonth;
      this.changeSubItem();
    },
    addConEdu() {
      if (this.conEduMonth >= 12) return;
      this.conEduMonth += 1;
      this.conEduDiscount = this.discountShow4 * this.conEduMonth;
      this.changeSubItem();
    },
    subConEdu() {
      if (this.conEduMonth <= 1) return;
      this.conEduMonth -= 1;
      this.conEduDiscount = this.discountShow4 * this.conEduMonth;
      this.changeSubItem();
    },
    addSupElderly() {
      if (this.supElderlyMonth >= 12) return;
      this.supElderlyMonth += 1;
      if (this.supElderly == 1) {
        this.supElderlyDiscount = this.supElderlyExcess * this.supElderlyMonth;
      } else {
        this.supElderlyDiscount = this.discountShow5 * this.supElderlyMonth;
      }

      this.changeSubItem();
    },
    subSupElderly() {
      if (this.supElderlyMonth <= 1) return;
      this.supElderlyMonth -= 1;
      if (this.supElderly == 1) {
        this.supElderlyDiscount = this.supElderlyExcess * this.supElderlyMonth;
      } else {
        this.supElderlyDiscount = this.discountShow5 * this.supElderlyMonth;
      }
      this.changeSubItem();
    },
    //

    calculate() {
      if (!this.salary || this.salary == 0) {
        this.$message({
          message: "请输入税前工资!",
          type: "warning",
        });
        return;
      }

      if (this.supElderly == 1) {
        if (!this.supElderlyExcess || this.supElderlyExcess == 0) {
          this.$message({
            message: "赡养老人选择分摊方式，请输入金额!",
            type: "warning",
          });
          return;
        }
      }

      // 总收入
      this.totalSalary =
        Number(this.salary) +
        Number(this.annualBonus) +
        Number(this.laborSalary) +
        Number(this.royaltiesSalary) +
        Number(this.concessionSalary);

      // 用于计算的总收入
      let calTotalSalary =
        Number(this.salary) +
        Number(this.annualBonus) +
        Number(this.laborSalary * 0.8) +
        Number(this.royaltiesSalary * 0.56) +
        Number(this.concessionSalary * 0.8);

      // 年社保+年公积金
      let socialAndPublic =
        Number(this.socialTotal * this.socialMonths) +
        Number(this.publicTotal * this.publicMonths);

      this.socialYear = Number(this.socialTotal * this.socialMonths);
      this.publicYear = Number(this.publicTotal * this.publicMonths);

      // 减掉 (社保公积金 +  扣除项 + 60000)
      var baseSubtractSocail =
        Number(calTotalSalary) -
        Number(socialAndPublic) -
        Number(this.totalDiscount) -
        60000;

      // 费率
      this.dudRateCal(baseSubtractSocail);

      // 总个税
      var totalTax;
      if (baseSubtractSocail <= 0) {
        totalTax = 0;
      } else {
        totalTax =
          Number(baseSubtractSocail * this.subBaseRate) - Number(this.subBasePrice);
      }

      this.totalTax = totalTax;

      // 税后收入
      this.afterSalary = this.totalSalary - socialAndPublic - totalTax;

      // 是否补税
      this.addOrDecTax = totalTax - this.beforehandTax;
      this.showMap();
      this.resultPage = true;
    },

    toThousands(num) {
      if (!num) return "0.00";
      num = Number(num).toFixed(2);
      var result = [],
        counter = 0;
      num = (num || 0).toString().split("");
      for (var i = num.length - 1; i >= 0; i--) {
        counter++;
        result.unshift(num[i]);
        if (!(counter % 3) && i != 0) {
          if (num[i] != ".") {
            result.unshift(",");
          }
        }
      }
      return result.join("");
    },

    calByAlone() {
      if (!this.annualBonus) return;
      // 单独计算奖金的个税
      // 总收入
      this.totalSalary =
        Number(this.salary) +
        Number(this.annualBonus) +
        Number(this.laborSalary) +
        Number(this.royaltiesSalary) +
        Number(this.concessionSalary);

      // 用于计算的总收入
      let calTotalSalary =
        Number(this.salary) +
        Number(this.laborSalary * 0.8) +
        Number(this.royaltiesSalary * 0.56) +
        Number(this.concessionSalary * 0.8);

      // 年社保+年公积金
      let socialAndPublic =
        Number(this.socialTotal * this.socialMonths) +
        Number(this.publicTotal * this.publicMonths);

      this.socialYear = Number(this.socialTotal * this.socialMonths);
      this.publicYear = Number(this.publicTotal * this.publicMonths);

      // 减掉 (社保公积金 +  扣除项 + 60000)
      var baseSubtractSocail =
        Number(calTotalSalary) -
        Number(socialAndPublic) -
        Number(this.totalDiscount) -
        60000;

      // 费率
      this.dudRateCal(baseSubtractSocail);

      // 个税A + 个税B
      var totalTaxA, totalTaxB;

      // 个税A
      if (baseSubtractSocail <= 0) {
        totalTaxA = 0;
      } else {
        totalTaxA =
          Number(baseSubtractSocail * this.subBaseRate) - Number(this.subBasePrice);
      }

      // 个税B
      this.dudRateCalB(this.annualBonus / 12);
      totalTaxB = this.annualBonus * this.rateB - this.rateNumB;

      this.totalTax = totalTaxA + totalTaxB;

      // 税后收入
      this.afterSalary = this.totalSalary - socialAndPublic - this.totalTax;

      // 是否补税
      this.addOrDecTax = this.totalTax - this.beforehandTax;
    },

    dudRateCalB(rate) {
      if (rate <= 3000) {
        this.rateNumB = 0;
        this.rateB = 0.03;
      }
      if (rate > 3000 && rate <= 12000) {
        this.rateNumB = 210;
        this.rateB = 0.1;
      }
      if (rate > 12000 && rate <= 25000) {
        this.rateNumB = 1410;
        this.rateB = 0.2;
      }
      if (rate > 25000 && rate <= 35000) {
        this.rateNumB = 2660;
        this.rateB = 0.25;
      }
      if (rate > 35000 && rate <= 55000) {
        this.rateNumB = 4410;
        this.rateB = 0.3;
      }
      if (rate > 55000 && rate <= 80000) {
        this.rateNumB = 7160;
        this.rateB = 0.35;
      }
      if (rate > 80000) {
        this.rateNumB = 15160;
        this.rateB = 0.45;
      }
    },

    dudRateCal(rate) {
      if (rate <= 36000) {
        this.subBaseRate = 0.03;
        this.subBasePrice = 0;
      }
      if (rate > 36000 && rate <= 144000) {
        this.subBaseRate = 0.1;
        this.subBasePrice = 2520;
      }
      if (rate > 144000 && rate <= 300000) {
        this.subBaseRate = 0.2;
        this.subBasePrice = 16920;
      }
      if (rate > 300000 && rate <= 420000) {
        this.subBaseRate = 0.25;
        this.subBasePrice = 31920;
      }
      if (rate > 420000 && rate <= 660000) {
        this.subBaseRate = 0.3;
        this.subBasePrice = 52920;
      }
      if (rate > 660000 && rate <= 960000) {
        this.subBaseRate = 0.35;
        this.subBasePrice = 85920;
      }
      if (rate > 960000) {
        this.subBaseRate = 0.45;
        this.subBasePrice = 181920;
      }
    },

    handleChange(value) {
      this.searchCity(value[1]);
    },
    changeSubItem() {
      this.totalDiscount =
        this.rentDiscount +
        this.loanDiscount +
        this.childDiscount +
        this.conEduDiscount +
        this.supElderlyDiscount +
        this.medicalDiscount;
    },
    medicalChange() {
      if (this.medicalCosts <= 15000) {
        this.medicalDiscount = 0;
      }
      if (this.medicalCosts > 15000 && this.medicalCosts <= 95000) {
        this.medicalDiscount = this.medicalCosts - 15000;
      }
      if (this.medicalCosts > 95000) {
        this.medicalDiscount = 80000;
      }
      this.changeSubItem();
    },
    changeExcess() {
      if (this.supElderlyExcess > 1000) {
        this.$message({
          message: "不得超过1000!",
          type: "warning",
        });
        this.supElderlyExcess = "";
      }
      this.supElderlyDiscount = this.supElderlyExcess * this.supElderlyMonth;
      this.changeSubItem();
    },
    rentChange(value) {
      this.haveCity = value;
      if (value.length) {
        this.discountShow1 = Number(this.discount1);
        this.rentDiscount = Number(this.discount1) * this.rentMonth;
      } else {
        this.discountShow1 = 0;
        this.rentDiscount = 0;
      }
      this.changeSubItem();
    },
    houseLoanChange(value) {
      if (value.length) {
        this.discountShow2 = Number(value[0]);
        this.loanDiscount = 1000 * this.houseLoanMonth;
      } else {
        this.discountShow2 = 0;
        this.loanDiscount = 0;
      }
      this.changeSubItem();
    },
    childEduChange(value) {
      if (value.length) {
        this.discountShow3 = Number(value[0] * value[1]);
        this.childDiscount = Number(value[0] * value[1]) * this.ChildEduMonth;
      } else {
        this.discountShow3 = 0;
        this.childDiscount = 0;
      }
      this.changeSubItem();
    },
    conEduChange(value) {
      if (value.length) {
        if (value[0] == "400") {
          this.conEduDiscount = Number(value[0]) * this.conEduMonth;
        }
        if (value[0] == "300") {
          this.conEduDiscount = 3600;
        }
        this.discountShow4 = Number(value[0]);
      } else {
        this.discountShow4 = 0;
        this.conEduDiscount = 0;
      }
      this.changeSubItem();
    },
    supElderlyChange(value) {
      if (value.length) {
        this.discountShow5 = Number(value[0]);
        if (value[0] == 1) {
          this.supElderlyDiscount = this.supElderlyExcess * this.supElderlyMonth;
        } else {
          this.supElderlyDiscount = Number(value[0]) * this.supElderlyMonth;
        }
      } else {
        this.discountShow5 = 0;
        this.supElderlyDiscount = 0;
      }
      this.changeSubItem();
    },

    // 查找租房折扣金额
    searchCity(name) {
      let city1 = [
        "北京市",
        "天津市",
        "石家庄市",
        "太原市",
        "呼和浩特",
        "沈阳市",
        "大连市",
        "长春市",
        "哈尔滨市",
        "上海市",
        "南京市",
        "杭州市",
        "宁波市",
        "合肥市",
        "福州市",
        "厦门市",
        "南昌市",
        "济南市",
        "青岛市",
        "郑州市",
        "长沙市",
        "广州市",
        "深圳市",
        "南宁市",
        "海口市",
        "重庆市",
        "成都市",
        "贵阳市",
        "昆明市",
        "拉萨市",
        "西安市",
        "兰州市",
        "西宁市",
        "银川市",
        "乌鲁木齐市",
      ];
      let city2 = [
        "唐山市",
        "秦皇岛市",
        "邯郸市",
        "保定市",
        "张家口市",
        "大同市",
        "包头市",
        "赤峰市",
        "鞍山市",
        "抚顺市",
        "盘锦市",
        "吉林市",
        "齐齐哈尔市",
        "大庆市",
        "无锡市",
        "徐州市",
        "常州市",
        "苏州市",
        "南通市",
        "连云港市",
        "淮安市",
        "盐城市",
        "扬州市",
        "镇江市",
        "泰州市",
        "宿迁市",
        "温州市",
        "湖州市",
        "绍兴市",
        "台州市",
        "芜湖市",
        "蚌埠市",
        "淮南市",
        "淮北市",
        "阜阳市",
        "宿州市",
        "六安市",
        "毫州市",
        "莆田市",
        "泉州市",
        "龙岩市",
        "赣州市",
        "宜春市",
        "抚州市",
        "上饶市",
        "淄博市",
        "枣庄市",
        "东营市",
        "烟台市",
        "廊坊市",
        "济宁市",
        "泰安市",
        "威海市",
        "日照市",
        "莱芜市",
        "临沂市",
        "德州市",
        "聊城市",
        "滨州市",
        "菏泽市",
        "开封市",
        "洛阳市",
        "平顶山市",
        "安阳市",
        "新乡市",
        "漯河市",
        "南阳市",
        "商丘市",
        "信阳市",
        "十堰市",
        "宜昌市",
        "襄阳市",
        "鄂州市",
        "荆州市",
        "衡阳市",
        "岳阳市",
        "常德市",
        "益阳市",
        "永州市",
        "珠海市",
        "汕头市",
        "佛山市",
        "江门市",
        "湛江市",
        "茂名市",
        "肇庆市",
        "惠州市",
        "阳江市",
        "清远市",
        "东莞市",
        "中山市",
        "潮州市",
        "揭阳市",
        "柳州市",
        "桂林市",
        "钦州市",
        "贵港市",
        "玉林市",
        "贺州市",
        "来宾市",
        "自贡市",
        "泸州市",
        "绵阳市",
        "遂宁市",
        "内江市",
        "乐山市",
        "南充市",
        "眉山市",
        "宜宾市",
        "广安市",
        "达州市",
        "巴中市",
        "资阳市",
        "六盘水市",
        "遵义市",
        "安顺市",
        "毕节市",
        "曲靖市",
        "宝鸡市",
        "咸阳市",
        "渭南市",
        "安康市",
        "天水市",
        "武威市",
      ];
      if (!this.city[0]) {
        name = "杭州市";
      }
      var user;
      user = city1.find((user) => {
        return user === name;
      });
      var user2;
      user2 = city2.find((user2) => {
        return user2 === name;
      });
      if (user) {
        this.discount1 = 1500;
      } else if (user2) {
        this.discount1 = 1100;
      } else {
        this.discount1 = 800;
      }
    },
    // 计算速算扣除数
    quick(num) {
      if (num <= 3000) {
        (this.quickNum = 0), (this.Percentage = 0.03);
      } else if (num > 3000 && num <= 12000) {
        (this.quickNum = 210), (this.Percentage = 0.1);
      } else if (num > 12000 && num <= 25000) {
        (this.quickNum = 1410), (this.Percentage = 0.2);
      } else if (num > 25000 && num <= 35000) {
        (this.quickNum = 2660), (this.Percentage = 0.25);
      } else if (num > 35000 && num <= 55000) {
        (this.quickNum = 4410), (this.Percentage = 0.3);
      } else if (num > 55000 && num <= 80000) {
        (this.quickNum = 7160), (this.Percentage = 0.35);
      } else if (num > 80000) {
        (this.quickNum = 15160), (this.Percentage = 0.45);
      }
    },

    // 生成饼图
    //echart
    showMap() {
      this.chartColumn = echarts.init(document.getElementById("circle"));
      this.chartColumn.setOption({
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        toolbox: {
          show: true,
          feature: {
            magicType: {
              show: true,
              type: ["pie", "funnel"],
              option: {
                funnel: {
                  x: "25%",
                  width: "50%",
                  funnelAlign: "left",
                  max: 1548,
                },
              },
            },
          },
        },
        calculable: true,
        series: [
          {
            name: "个税计算器",
            type: "pie",
            radius: "80%",
            center: ["50%", "50%"],
            color: ["rgba(82, 196, 26, 1)", "#FAAD14", "rgba(245, 34, 45, 1)"],
            label: {
              normal: {
                position: "inner",
                show: false,
              },
            },
            data: [
              {
                value: this.afterSalary ? this.afterSalary.toFixed(2) : 0,
                name: "税后工资",
              },
              {
                // value: this.list3.taxableIncome ? this.list3.taxableIncome : 0,
                value: this.totalTax ? this.totalTax.toFixed(2) : 0,
                name: "个人所得税",
              },
              {
                // value: this.list3.taxableAmount ? this.list3.taxableAmount : 0,
                value: (this.socialYear + this.publicYear).toFixed(2),
                name: "五险一金",
              },
            ],
          },
        ],
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.title {
  font-size: 24px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  margin: 24px 0 0 30px;
}

.flex-space {
  display: flex;
  justify-content: space-between;
}

.counter {
  min-width: 1070px;
  margin: 16px 16px 28px 16px;
}

.counter-item {
  position: relative;
  width: 49.5%;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}

.item-t-container {
  margin-top: 12px;
  margin-bottom: 12px;
}

.counter-item-t {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #D9D9D9;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.75);
  box-sizing: border-box;
  padding: 8px;
}

.counter-item-b {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;
}

.block-t {
  // padding: 24px 0 0 30px;
}

.block-b {
  // padding: 10px 0 0 30px;
}

.city-cascader >>>.el-cascader {
  width: 280px;
}

>>>.el-cascader {
  width: 255px;
  height: 32px;
  line-height: 32px;
}

>>>.el-input__inner {
  height: 32px;
}

>>>.el-input__icon {
  line-height: 32px;
}

>>>.el-input-group__append, .el-input-group__prepend {
  padding: 0 9px;
}

.block-append {
  display: inline-block;
  width: 280px;
}

.block-appends {
  width: 255px;
  margin-left: 16px;
}

.block-price {
  margin-left: 0;
  width: 240px;
}

.block-append-b {
  display: inline-block;
  width: 230px;
}

.counter-name {
  width: 150px;
  text-align: right;
}

.item-title {
  width: 102px;
  height: 36px;
  line-height: 34px;
  text-align: center;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px 0px 0px 4px;
  border: 1px solid rgba(217, 217, 217, 1);
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  display: inline-block;
  cursor: pointer;
  box-sizing: border-box;
}

.item-title1 {
  border-right: 1px solid rgba(255, 255, 255, 1);
}

.item-title2 {
  border-radius: 0px 4px 4px 0px;
  border-left: 1px solid rgba(255, 255, 255, 1);
}

.counter-center {
  // width: 510px;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.input-item {
  margin-bottom: 12px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
}

.item-btn {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 54px;
  line-height: 54px;
  background: rgba(98, 97, 242, 1);
  border-radius: 0px 0px 8px 8px;
  font-size: 16px;
  color: #ffffff;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}

.act {
  background: rgba(67, 70, 211, 0.08);
  border: 1px solid rgba(98, 97, 242, 1);
  color: #6261F2;
}

.circle {
  position: relative;
  left: 20px;
  width: 150px;
  height: 150px;
  background: #fff;
}

.circle-text {
  display: flex;
  align-item: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 12px;
}

.counter-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 24px;
}

.item-t-right {
  margin-top: 24px;
}

.social {
  width: 330px;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 400;
}

.weight {
  font-weight: 600;
}

.result {
  width: 340px;
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 400;
}

.item-border {
  padding-bottom: 16px;
  border-bottom: 1px solid #D9D9D9;
  margin-bottom: 10px;
}

.result-txt {
  margin-bottom: 7px;
}

.result-price {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(98, 97, 242, 1);
}

.block-dis {
  display: flex;
  align-items: center;
  padding: 0 0 10px 0;
}

.slide-fade-enter-active {
  transition: all 0.5s ease;
}

.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(15px);
  opacity: 0;
}

.item-btn:hover {
  background: rgba(98, 150, 255, 1);
}

.ease {
  transition: all 0.3s;
}

.circle-span {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: rgba(82, 196, 26, 1);
  margin-right: 8px;
  border-radius: 3px;
}

.circle-span1 {
  background: #FAAD14;
}

.circle-span2 {
  background: rgba(245, 34, 45, 1);
}

.circle-container {
  display: flex;
  align-item: center;
  font-size: 0;
  margin-top: 24px;
}

.circle-bottom {
  position: relative;
  left: 20px;
  margin-top: 40px;
  margin-left: 24px;
}

.circle-mine {
  display: inline-block;
  width: 180px;
}

.msg-title {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 0px 0px rgba(238, 238, 238, 1);
}

>>>.bread-crumb-wrapper {
  margin-top: 0;
}

.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  // font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 28px;
  margin: 8px 0 0 30px;
}

.block-input {
  padding: 4px 20px;
}

.block-tip {
  margin-left: 180px;
  color: #6261f2;
  font-size: 14px;
}

.add-content {
  display: flex;
  align-items: center;
  margin-left: 12px;
  color: #6261f2;
}

.add-content img {
  width: 12px;
  padding: 12px;
  margin: 0 2px;
  cursor: pointer;
  // background: #DCDFE6;
  border-radius: 8px;
}

.block-demonstration {
  color: transparent;
}

.block-med {
  margin-left: 0;
}

.add-month {
  width: 20px;
  text-align: center;
}

.block-total {
  display: flex;
  justify-content: space-between;
  color: #6261f2;
}

.block-input-name {
  display: inline-block;
  width: 180px;
  text-align: right;
}

.subMonth {
  margin-right: 15px;
}

.block-city {
  padding-top: 0;
}
</style>
