<template>
  <div>
    <div class="risk-problem-wrapper">
      <div class="editable-wrapper" v-if="!isEditable || isReadOnly">
        <el-tooltip
          :popper-class="popperClass"
          :manual="true"
          :value="isInputModified || isInputModifiedByMyself"
          content="此内容已修改"
          placement="top-end"
        >
          <div
            :class="{
              isModified: isInputModified,
              isModifiedByMyself: isInputModifiedByMyself,
            }"
            class="textarea textarea-text"
          >
            <div>
              {{ ruleRiskStatement }}
            </div>
            <div class="risk-text" v-if="ruleAdvise&&isForceShowDetail||data.userData==1">
              <div class="title">解决方案:</div>
              <div class="text">
                {{ ruleAdvise }}
              </div>
            </div>
            <div class="risk-text" v-if="caseText&&isForceShowDetail||data.userData==1">
              <div class="title">案例:</div>
              <div class="text">
                {{ caseText }}
              </div>
            </div>
            <div class="risk-text" v-if="policy&&isForceShowDetail||data.userData==1">
              <div class="title">法规:</div>
              <div class="text">
                {{ policy }}
              </div>
            </div>
          </div>
        </el-tooltip>
      </div>
      <div class="editable-wrapper" v-else>
        <div class="desc">
          <div class="item">
            <div class="title">风险说明</div>
            <div class="textarea-wrapper">
              <el-input
                type="textarea"
                placeholder="请输入内容"
                v-model="ruleRiskStatement"
                maxlength="99999"
                show-word-limit
                class="textarea"
                :disabled="!isEditable"
                :autosize="{ minRows: 3, maxRows: 10 }"
              ></el-input>
            </div>
          </div>
          <div class="item">
            <div class="title blue">解决方案</div>
            <div class="textarea-wrapper">
              <el-input
                type="textarea"
                placeholder="请输入内容"
                v-model="ruleAdvise"
                maxlength="99999"
                show-word-limit
                class="textarea"
                :disabled="!isEditable"
                :autosize="{ minRows: 3, maxRows: 10 }"
              ></el-input>
            </div>
          </div>
          <div class="item">
            <div class="title blue">案例</div>
            <div class="textarea-wrapper">
              <el-input
                type="textarea"
                placeholder="请输入内容"
                v-model="caseText"
                maxlength="99999"
                show-word-limit
                class="textarea"
                :disabled="!isEditable"
                :autosize="{ minRows: 3, maxRows: 10 }"
              ></el-input>
            </div>
          </div>
          <div class="item">
            <div class="title blue">法规</div>
            <div class="textarea-wrapper">
              <el-input
                type="textarea"
                placeholder="请输入内容"
                v-model="policy"
                maxlength="99999"
                show-word-limit
                class="textarea"
                :disabled="!isEditable"
                :autosize="{ minRows: 3, maxRows: 10 }"
              ></el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="chart-wrapper" v-if="showChart">
        <RiskProblemEchart
           :list="pdfData.ruleParamsTemplete"
              ref="child"
              v-if="pdfData.ruleParamsTemplete"
        ></RiskProblemEchart>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { cloneDeep } from "lodash";
import RiskProblemEchart from "@/pages/Result/components/RiskProblemList/RiskProblemEchart.vue";
import mixin from "@/pages/ResultEditor/mixin.js";

export default {
  mixins: [mixin],
  components: {
    RiskProblemEchart,
  },
  props: {
    indexes: Array,
    isReadOnly: Boolean,
    isForceShowDetail: Boolean,
  },
  mounted() {
    //如果是用户添加的内容 则不显示图表
    if (this.data.userData) {
      this.showChart = false;
    } else {
      //如果没有强制显示图表 则不显示
      if (!this.isForceShowDetail) {
        this.showChart = false;
      }
    }
  },
  data() {
    return {
      showChart: true,
    };
  },
  computed: {
    ...mapGetters("ResultEditor", ["findTheNodeByIndexes"]),
    data() {
      return this.findTheNodeByIndexes(this.indexes);
    },
    popperClass() {
      let ret = "";
      if (this.isInputModifiedByMyself) {
        ret = "modified-tooltip-red";
      } else if (this.isInputModified) {
        ret = "modified-tooltip-yellow";
      }
      return ret;
    },
    isInputModified() {
      let ret = false;

      if (this.$route.params.action == "edit") {
        ret = this.data.lastCensorSnapshot
          ? this.data.lastCensorSnapshot.includes("TextChange")
          : false;
      } else if (this.$route.params.action == "censor") {
        ret = this.data.lastEditSnapshot
          ? this.data.lastEditSnapshot.includes("TextChange")
          : false;
      }
      return ret;
    },
    isInputModifiedByMyself() {
      let ret = false;

      if (this.$route.params.action == "edit") {
        ret = this.data.editSnapshot
          ? this.data.editSnapshot.includes("TextChange")
          : false;
      } else if (this.$route.params.action == "censor") {
        ret = this.data.censorSnapshot
          ? this.data.censorSnapshot.includes("TextChange")
          : false;
      }
      return ret;
    },
    node() {
      return this.findTheNodeByIndexes(this.indexes);
    },

    pdfData() {
      return JSON.parse(this.node.pdfData);
    },
    ruleRiskStatement: {
      get() {
        return this.pdfData.ruleRiskStatement;
      },
      set(value) {
        const nodeData = cloneDeep(this.node);
        const pdfData = cloneDeep(this.pdfData);
        pdfData.ruleRiskStatement = value;
        nodeData.pdfData = JSON.stringify(pdfData);

        this.setTreeDataByIndexes({
          data: nodeData,
          indexes: this.indexes,
        });
      },
    },
    ruleAdvise: {
      get() {
        return this.pdfData.ruleAdvise;
      },
      set(value) {
        const nodeData = cloneDeep(this.node);
        const pdfData = cloneDeep(this.pdfData);
        pdfData.ruleAdvise = value;
        nodeData.pdfData = JSON.stringify(pdfData);

        this.setTreeDataByIndexes({
          data: nodeData,
          indexes: this.indexes,
        });
      },
    },
    caseText: {
      get() {
        return this.pdfData.caseText;
      },
      set(value) {
        const nodeData = cloneDeep(this.node);
        const pdfData = cloneDeep(this.pdfData);
        pdfData.caseText = value;
        nodeData.pdfData = JSON.stringify(pdfData);

        this.setTreeDataByIndexes({
          data: nodeData,
          indexes: this.indexes,
        });
      },
    },
    policy: {
      get() {
        return this.pdfData.policy;
      },
      set(value) {
        const nodeData = cloneDeep(this.node);
        const pdfData = cloneDeep(this.pdfData);
        pdfData.policy = value;
        nodeData.pdfData = JSON.stringify(pdfData);

        this.setTreeDataByIndexes({
          data: nodeData,
          indexes: this.indexes,
        });
      },
    },
  },
  methods: {
    ...mapMutations("ResultEditor", ["setTreeDataByIndexes"]),
  },
};
</script>

<style lang="stylus" scoped>
.risk-problem-wrapper {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 8px 8px 0px 0px;
  background: #fff;
  padding: 15px;

  .chart-wrapper {
    display: flex;
  }

  .textarea-text {
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    margin-bottom: 0px;

    .risk-text {
      margin-top: 10px;

      .title {
        font-weight: bold;
      }

      .text {
        text-indent: 1em;
      }
    }
  }

  .desc {
    .title {
      font-size: 14px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
    }

    .textarea-wrapper {
      margin: 16px 0;

      .textarea {
        >>> textarea {
          box-sizing: border-box;
          font-size: 14px;
          line-height: 30px;
        }

        >>> .el-textarea__inner {
          border-radius: 16px;
          background: #fbfbfb;
        }

        width: auto;
        display: block;
        margin: 0;
        margin-left: 0;
        border-radius: 16px;
        box-sizing: border-box;
        line-height: 1.5;
        color: rgba(0, 0, 0, 0.8);
        font-size: 14px;
        flex-grow: 1;
      }
    }
  }

  .isModified.textarea-text {
    padding: 5px;
    box-sizing: border-box;
    border: 1px solid #faad14 !important;
    border-color: #faad14 !important;
  }

  .isModifiedByMyself.textarea-text {
    padding: 5px;
    box-sizing: border-box;
    border: 1px solid rgba(245, 34, 45, 1) !important;
    border-color: rgba(245, 34, 45, 1) !important;
  }

  .isModified.textarea {
    >>> .el-textarea__inner {
      padding: 5px;
      box-sizing: border-box;
      border: 1px solid #faad14 !important;
      border-color: #faad14 !important;
    }

    border-radius: 4px;
  }

  .isModifiedByMyself.textarea {
    >>> .el-textarea__inner {
      padding: 5px;
      box-sizing: border-box;
      border: 1px solid rgba(245, 34, 45, 1) !important;
      border-color: rgba(245, 34, 45, 1) !important;
    }

    border-radius: 4px;
  }
}
</style>