<template>
  <div ref="mountNode" class="mountNode" :style="{ height: height }"></div>
</template>

<script>
import * as G6 from "@antv/g6";
import "./CompanyRisk";

export default {
  name: "NetworkChart",
  props: {
    data: [Object],
    height: {
      type: String,
      default: "5.74rem",
    },
  },
  data() {
    return {
      graph: null,
    };
  },
  mounted() {
    // const minimap = new G6.Minimap({
    //   size: [100, 100],
    //   className: 'minimap',
    //   type: 'delegate',
    // });
    // 创建 G6 图实例
    if (this.graph) {
      this.graph.destory();
    }

    this.graph = new G6.Graph({
      container: this.$refs["mountNode"],
      defaultNode: {
        type: "rect",
        size: [45, 25],
      },
      defaultEdge: {
        type: "cubic-horizontal",
        // 其他配置
      },
      // fitCenter: true,
      // fitView: true,
      // 画布宽高
      width: this.$refs["mountNode"].offsetWidth,
      height: this.$refs["mountNode"].offsetHeight,
      // animate:true,
      // layout: {
      //   // Object，可选，布局的方法及其配置项，默认为 random 布局。
      //   type: 'force', // 指定为力导向布局
      //   preventOverlap: true, // 防止节点重叠
      //   // nodeSize: 30        // 节点大小，用于算法中防止节点重叠时的碰撞检测。由于已经在上一节的元素配置中设置了每个节点的 size 属性，则不需要在此设置 nodeSize。
      // },
      modes: {
        // 支持的 behavior
        default: ["drag-canvas", "zoom-canvas"],
        //edit: ['click-select'],
      },
    });
  },
  watch: {
    data() {
      this.render();
    },
    deep: true,
  },
  methods: {
    render() {
      if (this.data == null) {
        return;
      }

      const rectHeight = 50;
      const yBegin = 360 - (this.data.topTenSupplierList.length / 2) * rectHeight;

      const leftNodes = this.data.topTenSupplierList.map((v, index) => {
        return {
          id: `node${index}_left`,
          x: 200,
          y: yBegin + rectHeight * index,
          type: "companyRisk",

          style: {
            //textAlign:'left',
            fillOpacity: 0,
            opacity: 0,
            lineWidth: 0,
          },
          companyName: v.name,
          risk: v.riskList.join(","),
          textAlign: "right",
        };
      });

      const leftEdges = this.data.topTenSupplierList.map((v, index) => {
        return {
          source: `node${index}_left`,
          target: "nodeSelf",
          label: v.totalAmout + "万元",
          labelCfg: {
            position: "start",
            autoRotate: true,
            refX: 10,
          },
          style: {
            endArrow: true,
            startArrow: false,
          },
        };
      });

      const rightNodes = this.data.topTenCustomerList.map((v, index) => {
        return {
          id: `node${index}_right`,
          x: 1000,
          y: yBegin + rectHeight * index,
          type: "companyRisk",
          companyName: v.name,
          risk: v.riskList.join(","),
          textAlign: "left",
          style: {
            fillOpacity: 0,
            opacity: 0,
            lineWidth: 0,
          },
        };
      });

      const rightEdges = this.data.topTenCustomerList.map((v, index) => {
        return {
          source: `node${index}_right`,
          target: "nodeSelf",
          label: v.totalAmout + "万元",
          labelCfg: {
            position: "start",
            autoRotate: true,
            refX: 10,
          },
          style: {
            endArrow: false,
            startArrow: true,
          },
        };
      });

      const centerNode = {
        id: "nodeSelf",
        x: 600,
        y: 360,
        type: "rect",
        label: this.data.companyName,
        style: {
          width: 200,
        },
      };

      // 定义数据源
      const data = {
        // 点集
        nodes: [centerNode, ...leftNodes, ...rightNodes],
        // 边集
        edges: [...leftEdges, ...rightEdges],
      };
      // 读取数据
      this.graph.data(data);
      // 渲染图
      this.graph.render();
    },
  },
};
</script>

<style lang="stylus" scoped>
.mountNode
  height 5.74rem
  width: 6rem
</style>
