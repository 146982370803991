import { render, staticRenderFns } from "./abnormal_tax.vue?vue&type=template&id=d6349f96&scoped=true"
import script from "./abnormal_tax.vue?vue&type=script&lang=js"
export * from "./abnormal_tax.vue?vue&type=script&lang=js"
import style0 from "@/styles/index.styl?vue&type=style&index=0&id=d6349f96&prod&lang=stylus&scoped=true&external"
import style1 from "./abnormal_tax.vue?vue&type=style&index=1&id=d6349f96&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6349f96",
  null
  
)

export default component.exports