import { render, staticRenderFns } from "./buy.vue?vue&type=template&id=d5ac88d4&scoped=true"
import script from "./buy.vue?vue&type=script&lang=js"
export * from "./buy.vue?vue&type=script&lang=js"
import style0 from "./buy.vue?vue&type=style&index=0&id=d5ac88d4&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5ac88d4",
  null
  
)

export default component.exports