import axios,{get,post} from "@/axios";
const base =process.env.VUE_APP_API_ROOT
const basePlat = `${base}/plat`;
/****
 * 手工填表
 *
 */
//上传表格中的数据
export const uploadTableData = async (nsrsbh, zq, templateId,uuid, data) => {
  const url = `${basePlat}/common/webupload/${nsrsbh}/${zq}/${templateId}?uuid=${uuid}`;
  const res = await post(url, { ...data });
  return res.data;
};

//手工报表上传oss接口
export const uploadWebTableExcel = async ({ tableLabel, nsrsbh, templeteId, zq, uuid }) => {
  const url = `${basePlat}/common/uploadWebTableExcel`;
  const res = await post(url, { tableLabel, nsrsbh, templeteId, zq, uuid });
  return res.data;
};

//读取表格总体结构
export const loadTableStructure = async data => {
  const { templateId,zq } = data;
  const url = `${base}/admin/reportData/loadByTempIdAndZqAndVersion`;
  const res = await get(url, { templateId: templateId , zq });
  return res.data;
};

//读取上传的表格的列表
// export const loadTablesStatusList = async (nsrsbh, zq, bankId, qykjzd) => {
//   const url = `${basePlat}/common/getHistoryTable`;
//   const res = await get(url, { params: { nsrsbh, zq, bankId, qykjzd } });
//   return res.data;
// };
//删除单个文件
export const deleteTable = async (nsrsbh, zq, templateId, token,uuid) => {
  const url = `${basePlat}/model/cancel/upload/${token}`;
  const res = await post(url, { nsrsbh, zq, template: templateId,uuid });
  return res.data;
};

export const uploadForTestUrl = (nsrsbh, zq, uuid, qykjzd, balance) => {
  return `${basePlat}/common/uploadExcelBatch?nsrsbh=${nsrsbh}&zq=${zq}&uuid=${uuid}&qykjzd=${qykjzd}&balance=${balance}`;
};

export const uploadForTestUrlTag = (nsrsbh, zq, uuid, qykjzd, balance, tag) => {
  return `${basePlat}/common/uploadExcelBatch?nsrsbh=${nsrsbh}&zq=${zq}&uuid=${uuid}&qykjzd=${qykjzd}&balance=${balance}&tag=${tag}`;
};

export const uploadContExcel = () => {
  return `${base}/admin/contract/uploadContExcel`;
};

//获取合同分类汇总结果
export const exportContract = async data => {
  const { programId,summaryData } = data;
  const url = `${base}/admin/contract/exportContract`;
  const res = await post(url, { programId,summaryData },true,{responseType: "blob"});

  return res;
};
//获取合同分类汇总结果
export const getContSummaryList = async data => {
  const { programId } = data;
  const url = `${base}/admin/contract/getContSummaryList`;
  const res = await get(url, { programId });

  return res.data;
};

//清空所有上传的表格
export const clearUploadedTableforTest = async (nsrsbh, zq, token,uuid) => {
  const url = `${basePlat}/model/clean/${token}`;
  const res = await get(url, { nsrsbh, zq,uuid });
  return res.data;
};

//获取所有表格
export const getUserTables = async (
  bankId,
  tag,
  nsrsbh,
  zq,
  uuid,
  onUploaded = false
) => {
  const url = `${basePlat}/common/getListV2`;

  //todo uuid暂时先写死
  // uuid = "fb4d26c248874dc8ab1917369dadcca5";
  // tag = 1036;

  const data = { zq,programId:uuid,tag };
  if (onUploaded) {
    data.type = "1";
  }
  const res = await get(url, data);
  return res.data;
};


//读取table数据
export const loadTableData = async ( zq, templateId, token,uuid) => {
  const url = `${basePlat}/model/user/excel/null/${zq}/${templateId}/${token}?uuid=${uuid}`;
  const res = await get(url);
  if (res.data.code == 200 && res.data.data) {
    res.data.data.dataJson = JSON.parse(res.data.data.dataJson);
  }
  return res.data;
};

//本期上期数据读取
export const getPreviousTableFill = async (nsrsbh, zq, templeteId) => {
  const url = `${basePlat}/fill/getFillInData`;
  const res = await post(url, { nsrsbh, zq, templeteId });
  return res.data;
};


