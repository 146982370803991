import { render, staticRenderFns } from "./setUserCompany.vue?vue&type=template&id=b3983ede&scoped=true"
import script from "./setUserCompany.vue?vue&type=script&lang=js"
export * from "./setUserCompany.vue?vue&type=script&lang=js"
import style0 from "@/styles/index.styl?vue&type=style&index=0&id=b3983ede&prod&lang=stylus&scoped=true&external"
import style1 from "./setUserCompany.vue?vue&type=style&index=1&id=b3983ede&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3983ede",
  null
  
)

export default component.exports