<template>
  <div>
      <div class="userAgreement">
        <div slot="title" class="bigtitle">
          税安风控系统服务条款
        </div>
        <div class="agreementmain">
          <div
            class="fontBold"
          >提示：为使用税安科技（杭州）有限公司（以下简称“本网站）的服务您应当阅读并遵守《用户服务协议》（以下简称“本协议”）。在您注册成为本网站用户前请务必仔细阅读以下条款。若您一旦注册，则视为您接受本网站的服务并受以下条款的约束。若您不接受以下条款，请您停止注册。</div>
          <div>本协议内容包括以下条款及已经发布的或将来可能发布的各类规则。所有规则为与本协议不可分割的一部分，与本协议正文具有同等法律效力。本协议是由您与本网站共同签订的，适用于您在本网站的全部活动。</div>
          <div>
            <span
              class="fontBold"
            >在您注册成为用户时，视为您已经阅读、理解并接受本协议的全部条款及各类规则。在您开始使用网站，并成为网站的注册用户时，本协议即产生法律效力。</span>
            <span>您承诺合法使用本网站提供的服务，不得利用本网站提供的服务 从事任何违反或可能违反所有适用法律、法规、政府规章和命令（包括但不限于中国的法律、 法规、政府规章和命令）的行为，亦不得利用本网站提供的服务从事任何侵犯或可能侵犯其他主体任何权利、权益的行为。</span>
            <span class="fontBold">本网站有权根据需要不时地制定、修改本协议或各类规则，如本协议及规则有任何变更，一切变更以本网站最新公布条例为准。</span>
            <span>经修订的协议、规则一经在本网站公布，即自动生效或在该等协议、规则指定的时间生效。您应不时地注意本协议及附属规则地变更，若您不同意相关变更，应当在本协议及附属规则变更后7日内按照本网站公开的联系方式提出异议，逾期未提出异议的视为您接收变更。本网站保留修改或中断服务的权利。对于任何网络服务的修改、中断或终止而造成的任何损失，无需对用户或第三方承担任何责任。但该修改、中断或终止行为并不能豁免您此前在本网站已经进行的交易下所应承担的义务。</span>
          </div>
          <div>
            <span>您确认使用本协议后，本协议即在您和本网站之间产生法律效力。</span>
            <span
              class="fontBold"
            >您只要在“我已阅读并且同意的使用协议及隐私条款”前的选择框里打钩并按照本网站规定的注册程序成功注册为用户，您的行为即表示同意并签署了本使用协议。</span>
            <span>本使用协议不涉及您与本网站的其他用户之间因网上交易而产生的法律关系及法律纠纷，但您在此同意将全面接受和履行与本网站其他用户在本网站签订的任何电子法律文本，并承诺按该法律文本享有和/或放弃相应的权利、承担和/或豁免相应的义务。</span>
          </div>
          <div>
            <div class="title">一、使用限制</div>
            <div>1、本网站中的全部内容的著作权、版权及其他知识产权均属于本网站所有，该等内容包括但不限于文本、数据、文章、设计、源代码、软件、图片、照片及其他全部信息（以下简称“网站内容”）。网站内容受中华人民共和国著作权法及各国际版权公约的保护。未经本网站事先书面同意，您承诺不以任何方式、不以任何形式复制、模仿、传播、出版、公布、展示网站内容，包括但不限于电子的、机械的、复印的、录音录像的方式和形式等。您承认网站内容是属于本网站的财产。未经本网站书面同意，您亦不得将本网站包含的资料等任何内容镜像到任何其他网站或者服务器。任何未经授权对网站内容的使用均属于违法行为，本网站将追究您的法律责任。</div>
            <div>2、您承诺合法使用本网站提供的服务及网站内容。禁止在本网站从事任何可能违反中华人民共和国现行的法律、法规、规章和政府规范性文件的行为或者任何未经授权使用本网站的行为，包括但不限于擅自进入本网站的未公开的系统、不正当的使用密码和网站的任何内容、窃取他人的账号和密码、窃取他人的个人隐私信息等。您承诺合法使用本网站提供的服务及网站内容。禁止在本网站从事任何可能违反中华人民共和国现行的法律、法规、规章和政府规范性文件的行为或者任何未经授权使用本网站的行为，如擅自进入本网站的未公开的系统、不正当的使用密码和网站的任何内容、窃取他人的账号和密码、窃取他人的个人隐私信息等。</div>
            <div>3、如果您未满18周岁，或者不具备完全民事行为能力，请在法定监护人的陪同下阅读本协议，并特别注意未成年人使用条款。否则本网站对于该等后续注册、使用网站服务等行为而对您所发生的不利后果不承担责任，并有权在知晓该等情况后解除双方间的服务协议。</div>
            <div>4、如因您向本网站提供虚假信息或承诺而导致本网站蒙受任何损失，您将承担全部责任并赔偿相关损失。</div>
            <div>
              5、
              <span class="fontBold">您注册成功后，不得将本网站的用户名转让给第三方使用。</span>
              <span>您确认，您用您的用户名和密码登录本网站后在本网站的一切行为均代表您本身作出的行为并由您承担相应的法律后果。任何利用您的用户名以及密码登录本网站并从事交易等行为均将被视为您的行为。因此，您有义务非常谨慎的保管自己的用户名以及密码。</span>
              <span
                class="fontBold"
              >本网站对您的用户名和密码的遗失或被盗取所产生的后果不承担任何责任。本网站托管账户收到的任何来自您在本网站关联的个人银行账户（含网银、第三方支付账户等，下同）的资金均被视为您自有资金。本网站托管账户支付到您在本网站关联的个人银行账户均视为已经向您完成支付。您有义务非常谨慎地保管自己的银行账户。本网站对您的银行账户被盗用所产生的后果不承担任何责任。</span>
            </div>
            <div>6、您有义务在注册时提供自己的真实资料，并保证诸如电子邮件地址、联系电话、联系地址、邮政编码等内容的有效性及安全性。如您因网上交易与其他用户产生诉讼的，其他用户有权通过司法部门要求网站提供相关资料。</div>
            <div>
              <span>7、经国家生效法律文书或行政处罚决定确认您存在违法行为，或者本网站有足够事实依据可以认定您存在违法或违反本使用协议的行为，或者您违反与本网站其他用户的协议借款逾期未还的，本网站有权在本网站及互联网络上公布您的违法、违约行为，并有权将该内容记入任何与您相关的信用资料和档案。</span>
              <span
                class="fontBold"
              >提醒注意：上述公布可能会公开您的部分个人信息，例如姓名、部分号位身份证号码。您签署本协议即视为理解并接受上述措施，永久性不可撤销地授权本网站公开相关个人信息</span>
            </div>
          </div>
          <div>
            <div class="title">二、涉及第三方网站</div>
            <div>本网站的网站内容可能涉及由第三方所有、控制或者运营的其他网站的内容或链接（以下简称“第三方网站”）。本网站不能保证也没有义务保证第三方网站上的信息的真实性和有效性。对于该等内容或链接，您确认按照第三方网站的使用协议确定相关权利义务，而不是按照本协议。第三方网站的内容、产品、广告和其他任何信息均由您自行判断并承担风险，而与本网站无关。</div>
          </div>
          <div>
            <div class="title">三、风险提示</div>
            <div>除非本网站或其合作方在特定投资交易成立后的电子协议书中明示承担责任外，本网站提供的服务中不带有对本网站中的任何用户、任何交易的任何保证，因此本网站及其股东、创建人、高级职员、董事、代理人、关联公司、母公司、子公司和雇员（以下称“本网站方”）不保证由其他用户或机构提供的信息内容的真实性、充分性、及时性、可靠性、完整性和有效性，并且不承担任何由此引起的法律责任。</div>
            <div class="fontBold">以下为风险提示条款，请您特别注意阅读：</div>
            <div class="info">
              <div>1、本网站为您提供的税务测评服务。本公司所做的测评结果仅供您参考，您应根据自身经营情况依法报税，自行承担风险。</div>
              <div>2、对于本网站所给您提供的税务测评服务，目前我国尚为对此制定专门法律及行政法规。如果未来国家出台相应的法律、规章、条例或出台相应的行业标准。规范，本网站为您提供的服务进行相应的调整。这种调整可能给您带来一定的不便或损失。</div>
              <div>3、互联网是全球公共网络，并不受任何一个机构所控制。数据在互联网上传输的途径不是完全确定的，可能会受到非法干扰或侵入。在互联网上传输的数据有可能被某些未经许可的个人、团体或机构通过某种渠道获得或篡改。</div>
              <div>4、互联网上的数据传输可能因通信繁忙出现延迟，或因其他原因出现中断、停顿或数据不完全、数据错误等情况，从而使交易出现错误、延迟、中断或停顿。</div>
              <div>5、因地震、火灾、台风及其他各种不可抗力因素引起的停电、网络系统故障、电脑故障等原因可能造成您的经济损失。</div>
              <div>6、互联网上发布的各种信息（包括但不限于分析、预测性的参考资料）可能出现错误并误导您。</div>
              <div>7、您的网上交易身份可能会被泄露、仿冒或因您自身疏忽造成账号或密码泄露，可能给您造成损失。</div>
              <div>8、您使用的计算机可能因存在性能缺陷、质量问题、计算机病毒、硬件故障及其他原因，而对您的交易登录、交易时间或交易数据产生影响，给您造成损失。</div>
              <div>9、上述风险所导致的损失或责任，均应由您自行承担，本网站对此不承担任何责任。您一经在本网站注册，即视为您已经完全了解并理解在本网站进行交易的风险，并且能够承担可能带来的风险或损失。尽管如此，本着对客户负责的态度，本网站将采取先进的网络产品和技术措施，最大限度地保护客户资料和交易活动的安全。</div>
            </div>
            <div class="fontBold">本网站在此郑重提醒用户，以上各条款均为免责条款，本网站系统不承担与此相关的任何损失和法律责任。</div>
          </div>
          <div>
            <div class="title">四、责任限制</div>
            <div>在任何情况下，本网站方对您使用本网站服务而产生的任何形式的直接或间接损失均不承担法律责任，包括但不限于资金损失、利润损失、营业中断损失等。因为本网站或者涉及的第三方网站的设备、系统问题或者因为计算机病毒造成的损失，本网站均不负责赔偿，您的补救措施只能是与本网站终止本协议并停止使用本网站。但是，中华人民共和国现行法律、法规另有规定的除外。</div>
          </div>
          <div>
            <div class="title">五、网站内容监测</div>
            <div>本网站没有义务监测网站内容，但是您确认并同意本网站有权不时地根据法律、法规、政府要求透露、修改、屏蔽或者删除必要的、合适的信息，以便更好地运营本网站并保护本网站的用户的合法权益。</div>
          </div>
          <div>
            <div class="title">六、用户信息的使用</div>
            <div>本网站对于您提供的、自行收集到的、经认证的用户信息将按照本协议以及本网站的隐私条款予以保护、使用或者披露。</div>
          </div>
          <div>
            <div class="title">七、补偿</div>
            <div>就任何第三方提出的，由于您违反本协议及纳入本协议的条款和规则或您违反任何法律、法规或侵害第三方的权利而产生或引起的每一种类和性质的任何索赔、要求、诉讼、损失和损害（实际的、特别的及有后果的），无论是已知或未知的，包括合理的律师费，您同意就此对本网站和本网站的母公司、子公司、关联公司、高级职员、董事、代理人和雇员（如适用）进行补偿并使其免受损害。</div>
          </div>
          <div>
            <div class="title">八、服务的中止与终止</div>
            <div>1、除非本网站终止本协议或者您申请终止本协议及注销相应用户账户且经本网站同意，否则本协议始终有效。在您违反了本协议、相关规则，或在相关法律法规、政府部门的要求下，本网站有权通过电子邮件告知方式终止本协议、关闭您的账户或者限制您使用本网站。但本网站的终止行为不能免除您根据本协议或在本网站生成的其他协议项下的还未履行完毕的义务。</div>
            <div>
              2、发生下列情形之一的，本网站有权随时中止或终止向用户提供服务：
              (1) 对于网络设备进行必要的保养及施工时；
              (2) 出现突发性的网络设备故障时；
              (3) 本网站所使用的网络通信设备由于不明原因停止，无法提供服务时；
              (4) 由于不可抗力因素致使本网站无法提供线上服务时；
              (5) 相关政府机构的要求。
            </div>
            <div>
              3、发生下列情形之一的，本网站有权随时中止或终止向用户提供服务而无需通知用户：
              (1) 用户提供的个人资料不准确，不真实，不合法有效；
              (2) 用户使用任何第三方程序进行登录或使用服务；
              (3) 用户滥用所享受的权利；
              (4) 用户有损害其他用户的行为；
              (5) 用户有违背社会风俗和社会道德的行为；
              (6) 用户有违反本协议中相关规定的行为。
            </div>
            <div>4、如因系统维护或升级的需要而需暂停网络服务，本网站将尽可能事先进行通告。</div>
            <div>5、终止用户服务后，用户使用本网站服务的权利立即终止。从终止用户服务即刻起，本网站不再对用户承担任何责任和义务。</div>
          </div>
          <div>
            <div class="title">九、适用法律和管辖</div>
            <div>因本网站提供服务所产生的争议均适用中华人民共和国法律，并由税安科技（杭州）有限公司工商注册所在地杭州的人民法院管辖。</div>
          </div>
          <div>
            <div class="title">十、附加条款</div>
            <div>在本网站的某些部分或页面中、或者您使用本网站相关服务产品的过程中，可能存在除本协议以外的单独的附加服务条款或协议，当这些服务条款或协议与本协议条款及规则存在冲突时，则前述本协议条款和规则以外的服务条款或协议将优先适用。</div>
          </div>
          <div>
            <div class="title">十一、条款的独立性</div>
            <div>若本协议的部分条款被认定为无效或者无法实施时，本协议中的其他条款仍然有效。</div>
          </div>
          <div>
            <div class="title">十二、投诉与咨询</div>
            <div>若您在使用本网站的过程中有任何的疑问、投诉和咨询，可随时致电或发送电子邮件咨询。</div>
          </div>
          <div>
            <div class="title">十三、用户数据安全</div>
            <div>用户可选择对所上传的信息进行留存，以作为今后使用时数据对比的资料，本网站将对用户的数据信息进行保密，绝不会泄露给其他任何机构平台。如用户选择销毁数据，那么所上传的信息将不会留存。请放心使用。</div>
          </div>
        </div>
      </div>

  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {}
};
</script>

<style scoped lang='stylus' >
.bigtitle{
  font-size: 21px;
  text-align: center;
  font-weight: bold;
  padding-bottom: 22px;
  border-bottom: 1px solid #C6C6C6;
  margin-bottom: 30px;
  color: #000000;
}
.userAgreement{
  line-height: 25px;
  text-align: left;
}
.agreementmain{
  height: 550px;
  color: #282828;
  overflow-y: scroll;
}
.userAgreement .title{
text-align: left;
    margin-bottom: 10px;
    text-align: left;
    margin-top: 30px;
    font-size: 20px;
    font-weight: bold;
}
</style>
