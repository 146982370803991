<template>
  <div
    class="append-wrapper"
    v-loading="isLoading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="append-btns" v-if="!isShowAppender">
      <button class="btn btn-primary" @click="isShowAppender = !isShowAppender">
        <i class="iconfont icontianjia"></i> 添加风险项
      </button>
    </div>
    <div
      class="section-wrapper-2"
      :class="{ highlighted: isShowAppender }"
      v-else
    >
      <div class="head-level-2">
        <div class="editable-title">
          <div class="label">标题：</div>
          <input type="text" class="input" v-model="titleInput" />
          <div class="warningLevelSelector-wrapper">
            <div class="label">风险程度:</div>
            <WarningLevelSelector
              @change="handleWarningLevelSelectorChanged"
              :selectedValue="selectedWarningLevelId"
            ></WarningLevelSelector>
          </div>
        </div>
        <div class="btns-wrapper">
          <button class="btn btn-primary" @click="handleBtnSaveClick">
            保存
          </button>
          <button class="btn btn-default" @click="handleBtnCancelClick">
            取消
          </button>
        </div>
      </div>
      <transition name="slide">
        <div class="section-body">
          <div class="editable-wrapper">
            <div class="label">结果:</div>

            <el-input
              type="textarea"
              :placeholder="'请输入内容'"
              v-model="dataText"
              maxlength="99999"
              show-word-limit
              class="textarea"
              :autosize="{ minRows: 4, maxRows: 6 }"
            ></el-input>
          </div>
        </div>
      </transition>
    </div>
    <div class="masker" v-if="isShowAppender"></div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { addNode } from "@/api/shiwusuo";
import WarningLevelSelector from "./WarningLevelSelector";
export default {
  components: {
    WarningLevelSelector,
  },
  methods: {
    ...mapActions("ResultEditor", ["submit"]),
    handleBtnCancelClick() {
      this.isShowAppender = !this.isShowAppender;
    },
    async handleBtnSaveClick() {
      this.isLoading = true;
      const res = await addNode({
        titleName: this.titleInput,
        parentId: this.node.id,
        uuid: this.$route.params.uuid,
        saNsrsbh: this.$route.params.nsrsbh,
        oldDataText: this.dataText,
        dataText: this.dataText,
        warningLevelId:this.selectedWarningLevelId,
        isSimple: 1,
      });

      if (res.msgCode == 1) {
        this.submit();
        setTimeout(() => {
          this.isShowAppender = false;
          this.isLoading = false;
        }, 2000);
      } else {
        this.isLoading = false;
      }
    }, //当风险等级选择器选择时
    handleWarningLevelSelectorChanged(id) {
      this.selectedWarningLevelId = id;
    },
  },
  data() {
    return {
      isLoading: false,
      titleInput: "",
      dataText: "",
      selectedWarningLevelId: 3,
      isShowAppender: false,
    };
  },
  props: {
    node: Object
  },
};
</script>

<style lang="stylus" scoped>
.section-wrapper-2 {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  box-sizing: border-box;
  margin: 10px 0;
  overflow: hidden;
}

.head-level-2, .head-level-3 {
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 16px;
}

.head-level-2 {
  box-sizing: border-box;
  padding: 0px;
  font-weight: normal;
  flex-wrap: nowrap;
}


.btns-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.warningLevelSelector-wrapper {
  margin-left: 32px;
  display: flex;
  align-items: center;

  .label {
    margin-right: 8px;
  }
}

.editable-title {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  flex-wrap: nowrap;
  flex-shrink: 0;

  .label {
    margin-right: 5px;
    font-weight: bold;
    font-size: 16px;
  }

  .input {
    background: #FBFBFB;
    border-radius: 16px;
    border: 1px solid #D9D9D9;
    width: 490px;
    height: 32px;
    line-height: 32px;
    padding: 0 17px;
    font-size: 16px;
    box-sizing: border-box;
  }

  .warningLevelSelector-wrapper {
    margin-left: 32px;
    display: flex;
    align-items: center;

    .label {
      margin-right: 8px;
    }
  }
}

.editable-wrapper {
  padding-left: 0px;
  display: flex;
  align-items: flex-start;
  width: 100%;
  color: rgba(0, 0, 0, 0.6);

  .label {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
    margin-right: 5px;
    margin-left: 16px;
    margin-top: 20px;
  }

  .textarea {
    >>> textarea {
      box-sizing: border-box;
      font-size: 14px;
      line-height: 30px;
    }

    >>> .el-textarea__inner {
      border-radius: 16px;
    }

    width: auto;
    display: block;
    margin: 16px;
    margin-left: 15px;
    border-radius: 16px;
    box-sizing: border-box;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    flex-grow: 1;
  }
}

.textarea-text {
  padding: 0 5px;
}

>>> .el-textarea__inner {
  height: 100px;
}

.isModified.textarea-text {
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid #faad14 !important;
  border-color: #faad14 !important;
}

.isModifiedByMyself.textarea-text {
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid rgba(245, 34, 45, 1) !important;
  border-color: rgba(245, 34, 45, 1) !important;
}

.isModified.textarea {
  >>> .el-textarea__inner {
    padding: 5px;
    box-sizing: border-box;
    border: 1px solid #faad14 !important;
    border-color: #faad14 !important;
  }

  border-radius: 4px;
}

.isModifiedByMyself.textarea {
  >>> .el-textarea__inner {
    padding: 5px;
    box-sizing: border-box;
    border: 1px solid rgba(245, 34, 45, 1) !important;
    border-color: rgba(245, 34, 45, 1) !important;
  }

  border-radius: 4px;
}

.section-body {
  display: flex;
}

.highlighted {
  position: relative;
  z-index: 10;
  border: 2px solid #2F54EB;
}

.masker {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 9;
}
</style>