<template>
  <div class="h5-financial">
    <Summary></Summary>
    <financialRisk></financialRisk>
    <KeyFinanceRatio></KeyFinanceRatio>
    <UpDownCustomer></UpDownCustomer>
    <PurchaseAndSale></PurchaseAndSale>
    <FinanceAndDuban></FinanceAndDuban>
    <HistoryTaxCondition></HistoryTaxCondition>
    <template>
      <!--      <ShowShareCodeMobile :isMobile="true" ref="ShowShareCodeMobile" :companyName="data.companyName"-->
      <!--                           v-if="!$route.name.includes('share')&&!isDesensitizationMode"-->
      <!--                           :uuid="uuid"></ShowShareCodeMobile>-->
      <!-- <img
        src="@/assets/result/mobile-share-report.png"
        class="mobileCodeBtnShare shareCodeBtn"
        @click="handleShareCodeBtnWrapperClick"
        v-if="!isInShare"
      /> -->

      <img
        src="@/assets/result/mobile-detail-report.png"
        class="mobileCodeBtnShare"
        @click="handleDetailReportClick"
      />
    </template>
    <ThreeYearShowShareCode
      ref="ThreeYearShowShareCode"
      :uuid="$route.params.uuid"
    ></ThreeYearShowShareCode>
  </div>
</template>

<script>
import Summary from "@/pages/Result/H5FinancialAnalysis/Summary";
import "@/pages/Result/ThreeYearResult/global.styl";
import financialRisk from "@/pages/Result/H5FinancialAnalysis/FinancialRisk";
import KeyFinanceRatio from "@/pages/Result/H5FinancialAnalysis/KeyFinanceRatio";
import UpDownCustomer from "@/pages/Result/H5FinancialAnalysis/UpDownCustomer";
import PurchaseAndSale from "@/pages/Result/H5FinancialAnalysis/purchaseAndSale/PurchaseAndSale";
import FinanceAndDuban from "@/pages/Result/H5FinancialAnalysis/financeAndDuban/FinanceAndDuban";
import HistoryTaxCondition from "@/pages/Result/H5FinancialAnalysis/historyTaxCondition/HistoryTaxCondition";
import { getAutoRoutePushTo } from "@/utils/helper";
import ThreeYearShowShareCode from "@/pages/Result/ThreeYearResult/components/ThreeShowShareCodeDialog";
import { isInShare } from "@/utils/share";

export default {
  components: {
    HistoryTaxCondition,
    Summary,
    financialRisk,
    KeyFinanceRatio,
    UpDownCustomer,
    PurchaseAndSale,
    FinanceAndDuban,
    ThreeYearShowShareCode,
  },
  name: "FinancialAnalysis",
  mounted() {
    if (this.$route.query.token) {
      localStorage.setItem("authorization", this.$route.query.token);
    }
  },
  computed: {
    detailRouterObject() {
      return getAutoRoutePushTo(this.uuid, this.$route, 0);
    },
    isInShare() {
      return isInShare(this.$route);
    },
  },
  methods: {
    handleDetailReportClick() {
      this.$router.push({
        path: `../h5/result/${this.$route.params.uuid}`,
        query: {
          uuid: this.$route.params.uuid,
          openMode: "threeyears",
          ...this.$route.query,
        },
      });
      //this.$router.push(this.detailRouterObject)
    },
    handleShareCodeBtnWrapperClick() {
      this.$refs["ThreeYearShowShareCode"].dialogVisible = true;
    },
  },
};
</script>

<style lang="stylus" scoped>
.h5-financial {
  width: 100%;
  overflow: hidden;
}

.mobileCodeBtnShare {
  position absolute;
  right 8px;
  top 50px;
  width 54px
  cursor pointer;
  display flex;
  justify-content center;
  align-items center;
  flex-direction column
  color #2F54EB
  font-weight bold;
  font-size 16px
  z-index 9
}

.shareCodeBtn {
  top 50px
}
</style>
