<template>
  <div class="container">
    <div class="item itemL">
      <div class="title">报税体检</div>
      <div class="content">
        <div class="info info-left">
          <div class="icon">
            <img src="./image/index-right.png" alt />
          </div>
        </div>
        <div class="info infoL">
          <div>
            <span class="info-gray">可用次数:</span>
            <span
              class="number"
              v-if="versioNum&&versioNum[0]"
            >{{versioNum[0].surplusNumber}}</span>次
          </div>
          <div class="number-top">
            <span class="info-gray">已用次数:</span>
            <span
              class="number"
              v-if="versioNum&&versioNum[0]"
            >{{versioNum[0].usedNumber}}</span>次
          </div>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="title">综合体检</div>
      <div class="content">
        <div class="info info-left">
          <div class="icon">
            <img src="./image/index-left.png" alt />
          </div>
        </div>
        <div class="info infoL">
          <div>
            <span class="info-gray">可用次数:</span>
            <span
              class="number"
              v-if="versioNum&&versioNum[1]"
            >{{versioNum[1].surplusNumber}}</span>次
          </div>
          <div class="number-top">
            <span class="info-gray">已用次数:</span>
            <span
              class="number number-top"
              v-if="versioNum&&versioNum[1]"
            >{{versioNum[1].usedNumber}}</span>次
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    versioNum: [Object, Array],
  },
  data() {
    return {};
  },
  methods: {
    toBuy() {
      this.$router.push({
        name: "goods",
      });
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
}

.item {
  flex: 1;
  height: 280px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.title {
  padding-left: 24px;
  border-bottom: 1px solid rgba(238, 238, 238, 1);
  line-height: 54px;
}

.content {
  display: flex;
  align-items: center;
}

.itemL {
  margin-right: 16px;
}

.info {
  flex: 1;
  color: rgba(0, 0, 0, 0.8);
}

.infoL {
  margin-top: 70px;
}

.icon {
  width: 240px;
  height: 180px;
  /* background: #f0f0f0; */
  margin: 24px 0 0 24px;
}

.icon img {
  width: 100%;
  height: 100%;
}

.info-gray {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  line-height: 20px;
}

.number {
  font-size: 32px;
  font-family: HelveticaNeue;
  color: rgba(0, 0, 0, 0.8);
  line-height: 38px;
  margin-right: 4px;
  margin-left: 16px;
}

.number-top {
  margin-top: 8px;
}

.toBuy {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(47, 84, 235, 1);
  line-height: 20px;
  margin-top: 20px;
  cursor: pointer;
}

.info-left {
  margin-right: 50px;
}
</style>