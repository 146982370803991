<template>
  <div class="container">
    <el-carousel
      type="card"
      height="420px"
      width="480px"
      :autoplay="false"
      indicator-position="none"
      arrow="never"
    >
      <el-carousel-item>
        <div class="title1 title-flex">
          <div class="name" v-if="verList">{{verList[0].name}}</div>
          <div v-if="verList">{{verList[0].remark}}</div>
        </div>
        <div class="content">
          <div class="discount">限时折扣</div>
          <div class="price" v-if="verList">
            ￥
            <span class="prices">{{verList[0].price}}</span>元/次
          </div>
          <div class="increase">
            <el-input-number v-model="num" :min="1" :max="10" label="描述文字" @change="changeNum"></el-input-number>
          </div>
          <div class="btn" @click="toBuy">立即支付 {{total}} 元</div>
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <div class="title2 title-flex">
          <div class="name" v-if="verList">{{verList[1].name}}</div>
          <div v-if="verList">{{verList[1].remark}}</div>
        </div>
        <div class="content content-center">
          <div class="content-com">企业定制</div>
          <div class="content-combtn">联系我们</div>
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <div class="title3 title-flex">
          <div class="name">优惠政策</div>
          <div>相关优惠政策匹配及推送</div>
        </div>
        <div class="content content-center">
          <div class="content-reduce">敬请期待</div>
          <div class="content-btn">了解详情</div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <table-detail></table-detail>
    <pay
      class="pay"
      v-if="showPay"
      @closePays="closePays"
      :num="num"
      :OldPrice="OldPrice"
      :totalAmount="totalAmount"
      :wetch_imgs="wetch_imgs"
      @changePay="changePay"
      @changeWe="changeWe"
    ></pay>
  </div>
</template>

<script>
import tableDetail from "./components/tableDetail";
import pay from "./components/pay";
import {
  getVersion,
  getCompanyId,
  createOrder,
  openOrder,
} from "../../api/api";

export default {
  components: {
    tableDetail,
    pay,
  },
  data() {
    return {
      num: 1,
      verList: null,
      price: 0,
      total: 0,
      versionList: {},
      showPay: false,
      OldPrice: 0,
      totalAmount: 0,
      wetch_imgs: "",
      payId: "",
    };
  },
  mounted() {
    this.allversion();
  },
  methods: {
    closePays() {
      this.showPay = false;
    },
    toBuy() {
      this.checkAuth();
    },
    allversion() {
      let params = {
        token: JSON.parse(localStorage.getItem("token")).token,
      };
      getVersion(params).then((res) => {
        if (res && res.content) {
          this.verList = res.content;
          this.price = res.content[0].price;
          this.OldPrice = res.content[0].priceOld;
          this.total = this.num * res.content[0].price;
          this.versionList.edition = res.content[0].edition;
          this.versionList.faceImg = res.content[0].faceImg;
          this.versionList.goodId = res.content[0].id;
          this.versionList.goodName = res.content[0].name;
          this.versionList.price = res.content[0].price;
          this.versionList.referenceType = 2;
          this.versionList.useDate = res.content[0].useDate;
          this.versionList.useNumber = res.content[0].useNumber;
        } else {
          this.$message({
            message: "未知错误",
            type: "error",
          });
        }
      });
    },
    changeNum() {
      this.total = this.num * this.price;
    },
    checkAuth() {
      let token = JSON.parse(localStorage.getItem("token")).token;
      getCompanyId(token).then(async (data) => {
        if (data.msgCode == -1) {
          this.$confirm("请先完善企业信息", "提示", {
            confirmButtonText: "完善信息",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$router.push({ name: "companyApply" });
            })
            .catch(() => {});
        } else {
          // 创建订单
          const loading = this.$loading({
            lock: true,
            text: "正在创建订单，跳转支付...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });

          let params = {
            token: JSON.parse(localStorage.getItem("token")).token,
            buyGoodsList: new Array(this.num).fill(this.versionList),
            quantity: this.num,
            name: "测试",
            useDiscountId: null,
          };
          createOrder(params).then((res) => {
            loading.close();
            if (res.msgCode == 1) {
              this.showPay = true;
              this.totalAmount = res.data.totalAmount;
              this.payId = res.data.id;
              this.openMethod("wx_pub_qr");
            } else {
              loading.close();
              this.$message({
                type: "info",
                message: "创建订单失败，请检查网络或咨询客服！",
              });
            }
          });
        }
      });
    },
    openMethod(method) {
      let params = {
        orderId: this.payId,
        channel: method,
        imageWidth: 200,
        imageHeight: 200,
      };
      openOrder(params).then((res) => {
        if (res.data) {
          this.wetch_imgs = res.data.image;
        } else {
          this.$message({
            type: "info",
            message: "生成二维码失败，请检查网络或咨询客服！",
          });
        }
      });
    },
    changePay() {
      this.openMethod("wx_pub_qr");
    },
    changeWe() {
      this.openMethod("alipay_qr");
    }
  },
};
</script>

<style scoped>
.container {
  padding: 30px 40px 0 40px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
}

.title1 {
  height: 110px;
  background: #2f54eb;
  background: linear-gradient(to right, #2f54eb, #4ebcfa);
}

.title2,
.title3 {
  height: 33%;
  background: red;
}

.title2 {
  background: #736dff;
  background: linear-gradient(to right, #736dff, #a97fff);
}

.title3 {
  background: #ff8886;
  background: linear-gradient(to right, #ff8886, #f7c598);
}

.title-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
}

.name {
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  line-height: 33px;
  padding-bottom: 8px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.discount {
  width: 64px;
  height: 20px;
  line-height: 20px;
  background: rgba(245, 34, 45, 1);
  border-radius: 2px;
  color: #fff;
  margin-top: 32px;
  font-size: 12px;
  text-align: center;
}

.price {
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(47, 84, 235, 1);
  line-height: 20px;
}

.prices {
  font-size: 57px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(47, 84, 235, 1);
  line-height: 60px;
  margin-right: 8px;
}

.increase {
  margin-top: 45px;
  margin-bottom: 16px;
}

.btn {
  width: 320px;
  height: 57px;
  line-height: 57px;
  text-align: center;
  background: linear-gradient(
    134deg,
    rgba(47, 84, 235, 1) 0%,
    rgba(78, 188, 250, 1) 100%
  );
  border-radius: 4px;
  color: #fff;
}

.content-combtn {
  width: 320px;
  height: 57px;
  line-height: 57px;
  text-align: center;
  background: linear-gradient(
    311deg,
    rgba(169, 127, 255, 1) 0%,
    rgba(115, 109, 255, 1) 100%
  );
  border-radius: 4px;
  color: #fff;
  margin-top: 43px;
}

.content-reduce {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.2);
  line-height: 33px;
}

.content-com {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 33px;
}

.content-btn {
  width: 168px;
  height: 57px;
  line-height: 57px;
  text-align: center;
  color: #fff;
  background: linear-gradient(
    311deg,
    rgba(247, 197, 152, 1) 0%,
    rgba(255, 136, 134, 1) 100%
  );
  border-radius: 4px;
  margin-top: 43px;
}

.content-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 67%;
}

>>> .el-input-number {
  width: 145px;
  height: 36px;
  line-height: 36px;
}

>>> .el-input__inner {
  height: 36px;
  line-height: 36px;
}

>>> .el-input-number__decrease,
>>> .el-input-number__increase {
  height: 34px;
  line-height: 34px;
}
</style>
