<template>
  <div class="register_box">
    <div class="register_left">
      <img src="@/assets/login/left3.png" alt />
    </div>
    <el-form
      :model="ruleForm3"
      :rules="rules3"
      ref="ruleForm3"
      label-width="0"
      class="demo-ruleForm register-container"
    >
      <el-form-item class="zctop">注册</el-form-item>
      <el-form-item prop="phone" style="margin-bottom:0">
        <el-input
          type="text"
          v-model="ruleForm3.phone"
          @blur="blurmobile"
          auto-complete="off"
          placeholder="请输入手机号码"
          class="registerwidth usernameinput1"
        ></el-input>
        <!-- <img src="../../../assets/login/phone.png" alt class="baseloginicon" /> -->
        <i class="baseloginicon iconshouji iconfont"></i>
      </el-form-item>
      <el-form-item prop="password" style="margin-bottom:0">
        <el-input
          type="password"
          show-password
          v-model="ruleForm3.password"
          auto-complete="off"
          placeholder="请输入密码"
          class="registerwidth usernameinput1"
        ></el-input>
        <!-- <img src="../../../assets/login/phone.png" alt class="baseloginicon" /> -->
        <i class="baseloginicon iconyanzhengma iconfont"></i>
      </el-form-item>
      <!-- 滑块验证 -->
      <div class="huakuai" v-if="VueHuakuai == false">
        <VerifySlider
          :class="[sildesucc ? 'active' : '', 'drag']"
          @success="successHandler"
          tips="按住滑块，拖到最右边"
        ></VerifySlider>
      </div>
      <el-form-item prop="phonepwd" v-if="VueHuakuai == true" style="margin-bottom:32px !important">
        <el-input
          type="text"
          v-model="ruleForm3.phonepwd"
          @blur="blurphonepwd"
          auto-complete="off"
          placeholder="短信验证码"
          class="registerwidth usernameinput1 phoneyzm"
        ></el-input>
        <!-- <img src="../../../assets/login/email.png" alt class="baseloginicon" /> -->
        <i class="baseloginicon iconyanzhengma iconfont"></i>
        <el-button
          :class="[canClick ? '' : 'active', 'btnsendcode']"
          @click="getCode('ruleForm3')"
        >{{content}}</el-button>
      </el-form-item>

      <div class="userId1">
        <span class="userCheck">
          <el-form-item prop="UserChecked" class="useryuedu" style="margin-bottom: 0px !important;">
            <el-checkbox label="我已阅读并接受" class="rememberpw" checked v-model="ruleForm3.UserChecked"></el-checkbox>
            <span class="userxy" @click="dialogVisible = true">《用户协议》</span>
          </el-form-item>
        </span>
      </div>
      <el-form-item class="loginitem clearfix register_item" style="margin-bottom: 0px !important;">
        <el-button class="loginbtn1" @click.native.prevent="register" :loading="logining">注册</el-button>
      </el-form-item>
      <div class="loginmeg">{{newmsg}}</div>
      <div class="loginitemzc">
        <span>有账号，</span>
        <router-link :to="{name:'login'}">
          去登录
          <img src="@/assets/login/nextright.png" alt />
        </router-link>
      </div>
      <!-- <el-form-item>
         <div class="loginmeg">{{newmsg}}</div>
      </el-form-item>-->
    </el-form>
    <el-dialog :visible.sync="dialogVisible" width="80%" center :show-close="true">
      <agruments></agruments>
    </el-dialog>
  </div>
</template>
<script>
import { VerifySlider } from "vue-verify-slider";
import agruments from "./agreement.vue";
import { mapMutations } from "vuex";
import {
  requestLogin,
  getRegisterCode,
  register,
  newrequestLogin
} from "../../../api/api";
export default {
  data: function() {
    return {
      newmsg: "",
      activeName: "first",
      seeytpe: "password",
      ruleForm3: {
        user: "",
        password: "",
        password2: "",
        phone: "",
        phonepwd: "",
        UserChecked: []
      },
      sildesucc: false,
      VueHuakuai: false,
      canClick: true,
      content: "获取验证码",
      totalTime: 60,
      logining: false,
      dialogVisible: false,
      rules3: {
        UserChecked: [
          {
            type: "array",
            required: true,
            message: "请勾选用户协议",
            trigger: "change"
          }
        ],
        password:[{
          validator:(rule, value, callback) => {
            if (/(?!^\d+$)(?!^[A-Za-z]+$)(?!^[^A-Za-z0-9]+$)(?!^.*[\u4E00-\u9FA5].*$)^\S{6,14}$/.test(value)) {
              callback();
            } else {
              callback(new Error('请输入6-14位，字母/数字/标点符号至少2种组合的密码'));

            }
          }, trigger: 'blur'
        }]
      }
    };
  },
  components: {
    VerifySlider,
    agruments
  },
  methods: {
    ...mapMutations(["changeLogin"]),
    blurmobile() {
      if (!/^1[3|4|5|6|7|8|9]\d{9}$/.test(this.ruleForm3.phone)) {
        // this.$message.error("请输入正确的手机号码");return;
        this.newmsg = "请输入正确的手机号码";
      } else {
        this.newmsg = "";
      }
    },
    blurphonepwd() {
      if (this.ruleForm3.phonepwd) {
        this.newmsg = "";
      }
    },
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    seepwd() {
      this.seeytpe = "text";
    },
    hidepwd() {
      this.seeytpe = "password";
    },
    openagreement: function() {
      this.showarggment = true;
    },
    //验证手机号码
    checkTel: function(tel) {
      var mobile = /^1[3|4|5|6|7|8|9]\d{9}$/,
        phone = /^0\d{2,3}-?\d{7,8}$/;
      return mobile.test(tel) || phone.test(tel);
    },
    getCode: function() {
      if (!this.isgetCode()) return;
      //如果验证码不能按直接跳过
      if (!this.canClick) return;
      //验证码发送的请求
      let param = { phone: this.ruleForm3.phone };
      if (!this.checkTel(this.ruleForm3.phone)) {
        this.newmsg = "请输入正确的手机号码";
        //  this.$message.error("请输入正确的手机号码");
        return;
      }
      this.newmsg = "";
      getRegisterCode(param.phone).then(data => {
        if (data.msgCode !== "1") {
          this.newmsg = data.msgInfo;
          //  this.$message.error(data.msgInfo);

          return;
        }
      });
      //获取验证码倒计时
      this.canClick = false;
      this.content = this.totalTime + "s后重发";
      let clock = window.setInterval(() => {
        this.totalTime--;
        this.content = this.totalTime + "s后重发";
        if (this.totalTime < 0) {
          window.clearInterval(clock);
          this.content = "重发验证码 ";
          this.totalTime = 60;
          this.canClick = true;
        }
      }, 1000);
    },
    //获取手机验证码
    isgetCode: function() {
      if (!this.ruleForm3.phone) {
        this.newmsg = "请输入手机号码";
        //  this.$message.error("请输入手机号码");

        return false;
      }
      if (!this.ruleForm3.password) {
        this.newmsg = "请输入密码";
        //  this.$message.error("请先滑动滑块至最右侧，再获取验证码");
        return false;
      }
      if (!this.VueHuakuai) {
        this.newmsg = "请先滑动滑块至最右侧，再获取验证码";
        //  this.$message.error("请先滑动滑块至最右侧，再获取验证码");
        return false;
      }
      if (!this.checkTel(this.ruleForm3.phone)) {
        this.newmsg = "请输入正确的手机号码";
        //  this.$message.error("请输入正确的手机号码");

        return false;
      }
      return true;
    },
    //滑动成功回调
    successHandler() {
      this.VueHuakuai = true;
      this.sildesucc = true;
    },
    //自动登录
    selflogin() {
      let mobile = this.$route.params.mobile;
      let password = this.$route.params.password;
      let loginParams = { mobile: mobile, password: password };
      if (mobile != undefined || password != undefined) {
        requestLogin(loginParams).then(data => {
          this.logining = false;
          //NProgress.done();
          if (data.msgCode !== "1") {
            this.newmsg = data.msgInfo;
            //  this.$message.error(data.msgInfo);
          } else {
            let result = data.data;
            let user = result.user;
            let token = result.token;
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("token", JSON.stringify(token));
            this.$router.push({ path: "/homePage" });
          }
        });
      }
    },
    //注册
    register: function() {
      if (!this.isgetCode()) {
        return;
      }
      let regCode = "";
      if (this.$route.query.regCode == undefined) {
        regCode = "";
      } else {
        regCode = this.$route.query.regCode;
      }
      this.$refs.ruleForm3.validate(valid => {
        if (valid) {
          let param = {
            regCode: regCode,
            code: this.ruleForm3.phonepwd,
            userName: this.ruleForm3.phone,
            mobile: this.ruleForm3.phone,
            password: this.ruleForm3.password
          };
          register(param).then(data => {
            if (data.msgCode == "-1") {
              this.newmsg = data.msgInfo;
              // this.$message.error(data.msgInfo)
            } else {
              let loginParams = {
                mobile: this.ruleForm3.phone,
                code: this.ruleForm3.phonepwd
              };
              newrequestLogin(loginParams).then(data => {
                if (data.msgCode == 1) {
                  let result = data.data;
                  let user = result.user;
                  let token = result.token;
                  localStorage.setItem("user", JSON.stringify(user));
                  localStorage.setItem("token", JSON.stringify(token));
                  localStorage.setItem("hasPwd", false);
                  // 将用户token保存到vuex中
                  this.changeLogin({ authorization: token.token });
                  this.$router.push({ path: "/companyApply" });
                } else {
                  // this.$message.error(data.msgInfo);
                  this.newmsg = data.msgInfo;
                }
              });
            }
          });
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style  lang="stylus" scoped>
.phoneyzm {
  width: 210px !important;
}

.huakuai {
  // margin-bottom:48px;
  margin: 0 auto 32px auto;
  width: 320px;
}

>>> .drag {
  background: #fafafa;
  width: 320px !important;
  border-radius: 10px !important;
  height: 40px;

  &.active {
    .drag_text {
      color: #fff !important;
    }
  }

  .drag_bg {
    background: #2F54EB;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    height: 40px;
  }

  .drag_text {
    font-size: 12px;
    color: #808080;
    border-color: #e6e6e6;
    border: none !important;
    height: 40px;
  }

  .handler_bg {
    background-image: url('../../../assets/login/loginhk.png');
  }

  .handler_ok_bg {
    height: 40px;
    background-image: url('../../../assets/login/loginright.png');
  }

  .handler {
    width: 60px;
    height: 40px;
  }

  .handler_ok_bg ~ .drag_text {
    color: #fff !important;
  }
}

>>>.rememberpw {
  .el-checkbox__inner {
    border-color: #D9D9D9;

    &::after {
    }
  }

  .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #2F54EB;
    border-color: #2F54EB;
  }

  .el-checkbox__label {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
  }
}

.register_box {
  display: flex;
  justify-content: space-evenly;
  width: 960px;
  position: relative;
  box-sizing border-box;
  height: 560px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 0 auto;
  padding-top: 80px;

  .register_left {
    width: 560px;

    img {
      width: 464px;
      margin-left: 48px;
    }
  }
}

>>> .el-input__inner {
  padding-left: 30px !important;
}

#login_tab {
  .el-tabs__header {
    margin: 0;

    .el-tabs__nav-wrap {
      .el-tabs__nav-scroll {
        .el-tabs__nav {
          width: 80%;
          margin: 0 5% 30px 5%;

          .el-tabs__item {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(47, 84, 235, 1);
            text-align: center;
            width: 50%;
          }

          .el-tabs__item.is-active {
            color: #2F54EB;
          }

          .el-tabs__active-bar {
            // display: none;
            background-color: #2F54EB;
          }
        }
      }
    }

    .el-tabs__nav-wrap::after {
      display: none;
    }
  }
}

.regsttitle {
  font-size: 24px;
  color: #1a1a1a;
  text-align: center;
  margin-bottom: 60px;
  font-weight: bold;
}

.useryuedu {
  >>> .el-form-item__content {
    height: 17px;
    line-height: 17px;
    margin-bottom: 16px;
  }

  .el-checkbox {
    margin-right: 0;
  }

  .userxy {
    cursor: pointer;
    color: #2F54EB;
  }
}

.register-container {
  width: 400px;
  margin: 0 auto;

  .zctop {
    text-align: center;

    >>> .el-form-item__content {
      margin: 29px 0 0px 0;
      height: 33px;
      font-size: 24px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
      line-height: 33px;
    }
  }

  >>>.el-form-item {
    margin-bottom: 24px !important;
    width: 80%;
    margin: 0 auto;

    .el-form-item__error {
      margin-bottom 10px
    }
  }

  .registerwidth {
    >>>input {
      padding-left: 30px;
      background-repeat: no-repeat;
      background-position: center left;
    }
  }

  .usernameinput1 {
    width: 100%;
    position: relative;

    >>> .el-input__inner {
      height: 40px;
      background: rgba(255, 255, 255, 1);
      border-radius: 4px;
      border: 1px solid rgba(217, 217, 217, 1);
      text-indent: 10px;
    }

    &+.baseloginicon {
      position: absolute;
      color: #979797;
      left: 16px;
      // top: 12px;
      width: 16px;
      height: 16px;
      font-size: 14px;
    }
  }

  .pwdinput {
    position: relative;

    &+.baseloginicon {
      position: absolute;
      left: 0px;
      top: 15px;
    }
  }

  .yzminput {
    position: relative;

    &+.baseloginicon {
      position: absolute;
      left: 0px;
      top: 15px;
    }

    input {
      // background-image: url(../../../assets/yzmicon.png);
    }
  }
}

.btnsendcode {
  position: absolute;
  right: 0;
  width: 102px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #2F54EB;
  color: #2F54EB;
  padding: 12px 16px;

  &:hover, &:focus, &:active {
    background: #fff;
    border-color: #fff;
  }

  &.active {
    // background: #D1D1D1;
    // color: #E6E6E6;
    // border-color: #D1D1D1;
  }
}

.loginmeg {
  margin-top: 8px;
  text-align: center;
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(245, 34, 45, 1);
  line-height: 17px;
}

.registermain {
  span {
    color: #2644f7;
  }
}

.verifslider {
  margin-top: 30px;

  &.active {
    .drag_text {
      color: #fff !important;
    }
  }
}

.seepwd {
  position: absolute;
  right: 0px;
  top: 15px;
  width: 24px;
}

.register {
  span {
    color: #2644F7;
  }
}

.loginitemzc {
  margin-top: 23px;
  text-align: center;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(47, 84, 235, 1);
  line-height: 22px;

  span {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: rgba(0, 0, 0, 0.6);
  }

  img {
    width: 24px;
    margin-left: 8px;
  }

  a {
    color: #2F54EB;
  }
}

.loginbtn1 {
  float: left;
  background: #2F54EB;
  width: 100%;
  height: 40px;
  padding: 0;
  color: #fff;
  font-size: 16px;
  border-radius: 4px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
}
</style>
