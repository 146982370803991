<template>
  <div>
    <div
      :class="`section-wrapper-${level}`"
      class="box-wrapper"
      :id="'node' + node.id"
    >
      <div :class="`head-level-${level}`">
        <div class="head-level-2">
          {{ node.titleName }}

          <div class="btns-wrapper" v-if="isEditable">
            <button
              class="btn btn-default"
              @click="handleBtnResetClick"
              v-if="
                !isDiscountPolicy &&
                (!node.children ||
                  node.children.length == 0 ||
                  this.isOverallNode)
              "
            >
              重置
            </button>
            <button
              class="btn btn-default"
              @click="handleBtnDeleteClick"
              v-if="isUserDiscountPolicy"
            >
              删除
            </button>
          </div>
        </div>
      </div>
      <transition name="slide">
        <div class="section-body" v-if="isDisplay">
          <template v-if="isEditable">
            <SectionNodeMask
              :show="!showEditForm"
              @edit="handleMaskEditClick"
              @reset="handleBtnResetClick"
              @hide="handleBtnDisplayToggleClick"
              ref="sectionNodeMask"
            >
            </SectionNodeMask>
            <template v-if="showEditForm">
              <slot name="edit"></slot>
              <div class="footer">
                <button class="btn btn-primary" @click="handleBtnSaveClick">
                  保存
                </button>
              </div>
            </template>
            <template v-else>
              <slot name="view"></slot>
            </template>
          </template>
          <template v-else>
            <slot name="view"></slot>
          </template>
        </div>
        <div class="section-body box-wrapper" v-else>
          <div class="one-line-limitation">
            <slot name="view"></slot>
          </div>
          <div class="one-line-limitation-show-more">
            <div>...</div>

            <button
              class="btn btn-default btn-toggle-show"
              @click="handleBtnDisplayToggleClick"
            >
              <i
                class="iconfont"
                :class="isDisplay ? 'iconxianshi' : 'iconyincang'"
              ></i>
              {{ toggleBtnLabel }}
            </button>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import SectionNodeMask from "./SectionNodeMask";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import { cloneDeep } from "lodash";
import WarningBadge from "@/pages/SimpleResultEditor/components/WarningBadge.vue";
import { getRevokePdfCatalogData, delectPdfCatalogData } from "@/api/shiwusuo";
import mixin from "@/pages/ResultEditor/mixin.js";
export default {
  mixins: [mixin],
  props: {
    title: String,
    level: Number, //当前节点在treeData中第几层
    indexes: {
      //当前组件所在treeData中的索引位置,如果是综述节点值就是overall
      type: Array,
      required: false,
    },
    target: {
      default: "treeData",
      type: String,
    },
  },

  computed: {
    //是否是的优惠政策
    isDiscountPolicy() {
      return this.node.type == 5;
    },
    //是否是用户定义的优惠政策
    isUserDiscountPolicy() {
      return this.node.type == 5 && this.node.userData == 1;
    },
    //是否是系统自带的优惠政策
    isSystemDiscountPolicy() {
      return this.node.type == 5 && this.node.userData != 1;
    },
    //显示已经修改
    isModifiedDiscountPolicy() {
      let ret = false;
      const action = this.$route.params.action;
      if (action == "edit") {
        ret = this.node.lastCensorSnapshot
          ? this.node.lastCensorSnapshot.length > 0
          : false;
      } else if (action == "censor") {
        ret = this.node.lastEditSnapshot
          ? this.node.lastEditSnapshot.length > 0
          : false;
      }
      return ret;
    },
    isModifiedDiscountPolicyByMyself() {
      let ret = false;
      const action = this.$route.params.action;
      if (action == "edit") {
        ret = this.node.editSnapshot
          ? this.node.editSnapshot.length > 0
          : false;
      } else if (action == "censor") {
        ret = this.node.censorSnapshot
          ? this.node.censorSnapshot.length > 0
          : false;
      }
      return ret;
    },
    isInputModified() {
      let ret = false;

      if (this.$route.params.action == "edit") {
        ret = this.node.lastCensorSnapshot
          ? this.node.lastCensorSnapshot.includes("TextChange")
          : false;
      } else if (this.$route.params.action == "censor") {
        ret = this.node.lastEditSnapshot
          ? this.node.lastEditSnapshot.includes("TextChange")
          : false;
      }
      return ret;
    },
    isInputModifiedByMyself() {
      let ret = false;

      if (this.$route.params.action == "edit") {
        ret = this.node.editSnapshot
          ? this.node.editSnapshot.includes("TextChange")
          : false;
      } else if (this.$route.params.action == "censor") {
        ret = this.node.censorSnapshot
          ? this.node.censorSnapshot.includes("TextChange")
          : false;
      }
      return ret;
    },
    //是否是综述节点
    isOverallNode() {
      return this.indexes == null;
    },
    ...mapState("ResultEditor", ["treeData", "overall", "discountPolicyTree"]),
    ...mapGetters("ResultEditor", ["findTheNodeByIndexes"]),
    node() {
      //如果没有提供indexes则是overall
      if (this.isOverallNode) {
        return this.overall;
      } else {
        //如果有则是可以排序的节点
        return this.findTheNodeByIndexes(this.indexes, this.target);
      }
    },
    //是否隐藏
    isDisplay() {
      let ret = false;
      ret = !!this.node.isDisplay;
      return ret;
    },
    //按钮上的文字
    toggleBtnLabel() {
      return this.isDisplay ? "隐藏" : "显示";
    },
  },
  data: function () {
    return {
      showEditForm: false, //是否展示编辑用的表单
      titleInput: "",
      toolTipVisible: false,
    };
  },
  components: {
    WarningBadge,
    SectionNodeMask,
  },

  methods: {
    ...mapMutations("ResultEditor", [
      "setTreeDataByIndexes",
      "refineOrder",
      "setDiscountPolicyTree",
    ]),
    ...mapActions("ResultEditor", ["refresh", "submit"]),
    handleMaskEditClick() {
      this.showEditForm = true;
    },
    //重置
    async handleBtnResetClick() {
      const pdfId = this.node.id;
      const resData = await getRevokePdfCatalogData({ pdfId });
      const nodeRemoteData = resData.data;

      if (resData.msgCode == "1") {
        if (this.isOverallNode) {
          //如果当前节点是综述节点
          const nativeTreeData = cloneDeep(this.node);
          // nativeTreeData.titleName = nodeRemoteData.titleName;
          nativeTreeData.pdfData = nodeRemoteData.pdfData;
          this.$store.commit("ResultEditor/setOverall", {
            data: nativeTreeData,
          });
        } else {
          //如果当前节点是树状目录中的节点
          const nodeData = cloneDeep(this.node);
          //nodeData.titleName = this.titleInput; //给节点设置新的titleName
          nodeData.pdfData = nodeRemoteData.pdfData;
          nodeData.dataText = nodeRemoteData.dataText;

          this.setTreeDataByIndexes({
            data: nodeData,
            indexes: this.indexes,
            target: this.target,
          });
        }
        this.$message({
          message: `${this.title}的数据已经被重置`,
          type: "info",
        });
      } else {
        this.$message.error(resData.msgInfo);
      }
    },
    //显示隐藏
    handleBtnDisplayToggleClick() {
      //如果当前节点是综述节点
      if (this.isOverallNode) {
        const node = cloneDeep(this.node);
        node.isDisplay = this.node.isDisplay == "1" ? 0 : 1;
        this.$store.commit("ResultEditor/setOverall", { data: node });
        // this.$message({
        //   message:
        //     `${this.title}将在PDF中` + (node.isDisplay ? "隐藏" : "显示"),
        //   type: "info"
        // });
      } else {
        const nodeData = cloneDeep(this.node);
        //如果当前节点是树状目录中的节点
        nodeData.isDisplay = this.node.isDisplay == "1" ? 0 : 1;

        this.setTreeDataByIndexes({
          data: nodeData,
          indexes: this.indexes,
          target: this.target,
        });

        this.refineOrder({ target: this.target });

        // this.$message({
        //   message:
        //     `${this.title}将在PDF中` + (this.node.isDisplay ? "隐藏" : "显示"),
        //   type: "info"
        // });
      }
    },
    async handleBtnDeleteClick() {
      const nodeId = this.node.id;
      const discountPolicyTree = cloneDeep(this.discountPolicyTree);

      this.$confirm("此操作将删除该优惠政策, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        discountPolicyTree[0].children = discountPolicyTree[0].children.filter(
          (v) => {
            return v.id !== nodeId;
          }
        );

        this.setDiscountPolicyTree({ data: discountPolicyTree });
      });
    },
    handleBtnSaveClick() {
      this.toolTipVisible = false;

      //如果是综述则修改综述里的titleName
      if (this.isOverallNode) {
        const node = cloneDeep(this.node);
        node.titleName = this.titleInput;
        this.$store.commit("ResultEditor/setOverall", { data: node });
      } else {
        //设置vuex中的titleName
        const nodeData = cloneDeep(this.node);
        //如果当前节点是树状目录中的节点
        nodeData.titleName = this.titleInput;

        this.setTreeDataByIndexes({
          data: nodeData,
          indexes: this.indexes,
          target: this.target,
        });
      }

      this.submit();
      this.showEditForm = false;
    },
    handleTooltipShow() {
      this.titleInput = this.node.titleName;
    },
  },
  watch: {
    title: {
      handler() {
        this.titleInput = this.title;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="stylus" scoped>
.one-line-limitation {
  height: 50px;
  overflow: hidden;
}

.label-box {
  margin-left: 10px;
  font-weight: normal;
  display: inline-block;
  padding: 1px 3px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background: rgba(255, 255, 255, 1);
  border-radius: 2px;
  border: 1px solid rgba(47, 84, 235, 1);
  font-size: 12px;
  color: rgba(47, 84, 235, 1);
}





.form-control-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .input-wrapper {
    flex-grow: 1;
  }

  .btn-wrapper {
    margin-left: 10px;
    display: flex;

    .btn {
      margin: 0 5px;
    }
  }
}

.title-wrapper {
  display: flex;
  align-items: center;
}

.icon {
  color: rgba(47, 84, 235, 1);
  margin-left: 8px;
  font-weight: normal;
  cursor: pointer;
}

.slide-enter-active {
  transition-duration: 0.3s;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
  max-height: 200px;
  overflow: hidden;
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.bold {
  font-weight: bold;
}

.removed {
  text-decoration: line-through;
  color: #999;
}

.head-level-1 {
  width: 100%;
  padding: 0 0px 0;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.head-level-2, .head-level-3 {
  color: rgba(0, 0, 0, 0.8);
  line-height: 24px;
  font-weight: normal;
  width: 100%;
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
}

.section-wrapper-2, .section-wrapper-3 {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  margin: 10px 0;
}

.section-wrapper-1 {
  box-sizing: border-box;
  margin: 32px 31px;
}


.btn.gray {
  background: rgba(217, 217, 217, 1);
  color: #fff;
}

.btn.disabled {
  background: rgba(217, 217, 217, 1);
  cursor: not-allowed;
}

.tooltip {
  padding: 0;
  border: 0 none;
  margin: 0 0 0 8px;

  .icon {
    color: #2F54EB;
    font-size: 18px;
  }
}

.tooltip:hover {
  background: none;
}

.section-body {
  position: relative;
}

.badge {
  font-weight: normal;
  padding: 3px 8px;
  color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  display: inline-block;
  font-size: 14px;
  margin-left: 16px;
}

.badge.red {
  background: rgba(245, 34, 45, 1);
  color: #fff;
  border: 1px solid rgba(245, 34, 45, 1);
}

.badge.yellow {
  color: rgba(250, 173, 20, 1);
  border: 1px solid rgba(250, 173, 20, 1);
  background: rgba(250, 173, 20, 0.08);
}

.box-wrapper {
  background: rgba(255, 255, 255, 1);
  box-sizing: border-box;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  overflow: hidden;
  position: relative;

  .one-line-limitation-show-more {
    padding: 16px 0px 16px 35px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;

    .btn-toggle-show {
      border: none;
      background: none !important;
      font-weight: bold;
    }

    .btn-toggle-show:hover {
      background: none !important;
      color: #2F54EB !important;
    }
  }
}
</style>