<template>
  <div class="Login">
    <!-- <div id="particles-js"></div> -->
    <!-- //背景图 -->
    <mynav class="nav"></mynav>
    <div class="loginmain">
      <div class="bglogo"></div>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import mynav from "../../components/mynav";
// require('particles.js')
export default {
  data() {
    return {
      loginmain: true,
      regCode: "",
      dialogVisible: false,
      result: null,
      page: true,
      isshowimg: true
    };
  },
  components: {
    mynav
  },
  methods: {},
  mounted() {
    // particlesJS('particles-js',config)
  }
};
</script>
<style lang="stylus" scoped>

// .bglogo{
//   height: 128px;
// }
// #particles-js{
//       position: absolute;
//       width: 100%;
//       height: 100%;
//       background-color: #F0F3F7;
//       background-repeat: no-repeat;
//       background-size: cover;
//       background-position: 50% 50%;
// }
.loginl {
  width: 50%;
  height: 100%;
  float: left;

  img {
    width: 100%;
    height: 100%;
  }
}

.Login {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: linear-gradient(180deg, rgba(89, 126, 247, 1) 0%, rgba(133, 165, 255, 1) 100%);
}

//用户须知弹窗的样式
.userAgreement {
  color: #000;

  .pLeft {
    padding-left: 10px;
  }

  .info {
    color: rgb(34, 34, 34);
    font-style: italic;
    font-family: "PingFang SC";
  }

  .fontBold {
    font-weight: 600;
  }

  .bigtitle {
    text-align: center;
  }

  .title {
    font-weight: 700;
    font-size: 17px;
    color: #333;
    margin-top: 8px;
    margin-bottom: 3px;
  }
}

.userxieyi {
  color: #7492e1;
  text-decoration: none;
}

.register-container {
  z-index: 1000;
  position: absolute;
  left: 60%;
  top: 50px;
  /*box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);*/
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  background-clip: padding-box;
  margin: -20px auto;
  width: 300px;
  padding: 35px 35px 15px 35px;
  background: rgba(0, 0, 0, 0.5);

  .btn {
    // background: linear-gradient(to right,#235ca9,#3bafe5);
    background: -moz-linear-gradient(right, #235ca9 0%, #3bafe5 100%);
    background: -webkit-gradient(
      linear,
      right top,
      left bottom,
      color-stop(0%, #235ca9 0%),
      color-stop(100%, #3bafe5)
    );
    background: -webkit-linear-gradient(right, #235ca9 0%, #3bafe5 100%);
    background: -o-linear-gradient(right, #235ca9 0%, #3bafe5 100%);
    background: -ms-linear-gradient(right, #235ca9 0%, #3bafe5 100%);
    background: linear-gradient(to right, #235ca9 0%, #3bafe5 100%);
    color: #fff;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    -moz-border-radius: 20px;
  }

  .title {
    margin: 0px auto 40px auto;
    text-align: center;
    color: #aaa;
    font-size: 16px;
  }

  .registerwidth {
    width: 100%;
  }
}

.rememberpw {
  color: #eee;
}

.user1 {
  margin-top: 10px;

  a {
    color: #7492e1;
    text-decoration: none;
  }
}

.user > a {
  color: #7492e1;
  text-decoration: none;
}

.separated {
  color: #eee;
}

.register {
  color: #7492e1;
  cursor: pointer;
}

.logoimg {
  text-align: center;

  img {
    margin-bottom: 30px;
    width: 100px;
    height: 30px;
  }
}

.sunan {
  width: 35%;
  color: #eee;
  position: absolute;
  margin: 100px 100px 0 100px;
  min-width: 500px;

  .sunanbig {
    margin-bottom: 30px;

    p {
      color: #fff;
      font-size: 40px;
    }
  }

  .sunansmall {
    float: right;

    > div {
      display: inline-block;
      width: 45%;
    }

    .server {
      float: right;
      height: 208px;

      > div {
        height: 160px;
      }
    }
  }
}


.login-container {
  z-index: 1000;
  position: absolute;
  left: 60%;
  top: 150px;
  /*box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);*/
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  background-clip: padding-box;
  margin: -20px auto;
  width: 300px;
  padding: 35px 35px 15px 35px;
  background: rgba(0, 0, 0, 0.5);
  /* border: 1px solid #eaeaea; */
  /* box-shadow: 0 0 25px #cac6c6; */

  .title {
    margin: 0px auto 40px auto;
    text-align: center;
    color: #aaa;
    font-size: 16px;
  }

  .remember {
    margin: 0px 0px 35px 0px;
  }
}

.userId {
  margin-bottom: 20px;

  .user {
    margin-left: 40px;
  }
}

.userId1 {
  margin-top: -6px;
  margin-bottom: 5px;

  .userCheck {
    display: inline-block;
    width: 130px;
  }

  .yonhuxuzhi {
    color: #7492e1;
    margin-left: -24px;
    cursor: pointer;
  }
}

.loginmain {
  margin-top: 128px;
  width: 100%;
  display: flex;
  padding-bottom: 30px;
  background: url('../../assets/login/bj.png') no-repeat center;
  background-size: 100% 100%;
  border-radius: 10px;
  box-sizing: border-box;

  .register {
    text-align: center;

    a {
      display: inline-block;
      font-size: 14px;
      color: #1a1a1a;
      margin-top: 20px;

      &:hover {
        transition: all 0.3s ease-in-out;
        color: #2644f7;
      }

      img {
        vertical-align: middle;
      }
    }
  }


}

:deep(.loginmain) {


  .el-form-item.is-success .el-input__inner,
  .el-form-item.is-success .el-input__inner:focus,
  .el-form-item.is-success .el-textarea__inner,
  .el-form-item.is-success .el-textarea__inner:focus {
    border-color: #000;
  }

  .el-form-item.is-error .el-input__inner,
  .el-form-item.is-error .el-input__inner:focus,
  .el-form-item.is-error .el-textarea__inner,
  .el-form-item.is-error .el-textarea__inner:focus,
  .el-message-box__input input.invalid,
  .el-message-box__input input.invalid:focus {
    border-color: #f32c34;
  }

  .el-input__inner {
    height: 55px;
    border: none;
    border-bottom: 1px solid #d1d1d1;
    border-radius: 0px;
    font-size: 14px;
    color: #1a1a1a;

    &::focus {
      border-color: #000;
    }

    &::placeholder {
      font-size: 14px;
      color: #d1d1d1;
    }
  }

  .el-form-item__error {
    padding-top: 10px;
    color: #f32c34;
  }

}
</style>
