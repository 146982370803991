<template>
  <div>
    <div ref="chartColumn" class="chartColumn"></div>
  </div>
</template>

<script>
//进销项
import {mapState} from "vuex";
import echarts from "echarts";

export default {
  props: {
    data: Object
  },
  async mounted() {
    const data = this.data;
    this.xLableKey = data.xLabel
    //X轴标签
    this.xLabels = data.body.map(item => {
      return item[this.xLableKey];
    });

    const newBody = data.body.map(v => {
      const copiedRow = {...v}
      delete copiedRow[this.xLableKey] //去除掉月份的列
      return copiedRow;
    })

    let keys = Object.keys(newBody[0]);
    const colors = ["#2F54EB", "#FAAD14", "#52C41A"];
    for (const i in keys) {
      const key = keys[i]
      const theSeries = {
        name: key,
        type: "line",
        data: newBody.map(v => v[key]),
        color: [colors[i]],
        symbol: "circle",
        symbolSize: 7
      }
      this.series.push(theSeries)

    }


    this.showMap();
  },
  data() {
    return {
      xLableKey: '', //xLabel的key值
      xLabels: [],
      jxjes: [],
      deductedJxjes: [],
      xxjes: [],
      series: []
    };
  },
  methods: {
    //echart
    showMap() {
      this.$refs["chartColumn"].style.width = "819px"; //window.innerWidth * 0.5 + "px";
      this.chartColumn = echarts.init(this.$refs["chartColumn"]);
      const option = {
        tooltip: {
          trigger: "axis"
        },
        legend: {
          x: "center",
          bottom: "0%",
          data: ["进项税额", "进项税额(已抵扣)", "销项税额"]
        },
        grid: {
          left: "3%",
          right: "5%",
          top: "10%",
          bottom: "10%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.xLabels,
          splitLine: {
            show: true,
            lineStyle: {
              color: "#EEEEEE",
              width: 1,
              type: "solid"
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#666"
            }
          },
          axisLabel: {
            // rotate: 35,
            interval: 0,
            textStyle: {
              fontSize: 11,
              fontFamily: "PingFangSC-Regular,PingFang SC",
              color: "rgba(0,0,0,0.6)"
            }
          }
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: true,
            lineStyle: {
              color: "#666"
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#EEEEEE",
              width: 1,
              type: "dashed"
            }
          },
          axisLabel: {show: true, formatter: "{value}"}
        },
        series: this.series
      };
      console.log(option)
      this.chartColumn.setOption(option);
    }
  },
  computed: {
    ...mapState(["Result"])
  }
};
</script>

<style lang="stylus" scoped>
.chartColumn {
  width: 200%;
  height: 435px;
}
</style>
