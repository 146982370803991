<template>
  <div class="financialRisk-h5-score">
    <div class="h5-finaceAnalyze-score">
      <div class="name">综合评分</div>
      <el-rate
        :value="data.score / 20"
        :max="max"
        disabled
        text-color="#FF8023"
        :colors="['#FF8023', '#FF8023', '#FF8023']"
      >
      </el-rate>
      <div class="score">{{ data.score }}分</div>
    </div>
    <div class="radarData">
      <RadarChart :data="radarData"></RadarChart>
    </div>
    <div class="table-wrapper table-first">
      <BigPowerTable class="table" :data="data.data"></BigPowerTable>
      <div class="sc" @click="switchScreen">
        <img src="@/assets/util/hScreen.png" alt="" />
      </div>
    </div>
    <div class="full-sc">
      <el-drawer :visible.sync="horizontalScreen" direction="btt" size="100%">
        <div class="full-tab">
          <div class="table-wrapper">
            <BigPowerTable :data="data.data" class="table"></BigPowerTable>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import RadarChart from "@/pages/Result/H5FinancialAnalysis/components/RadarChart";

export default {
  name: "FinanceAnalyze",
  components: {
    RadarChart,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      currentAbilityIndex: null,
      max: 5,
      value: 3.7,
      horizontalScreen: false,
    };
  },
  computed: {
    radarData() {
      return this.data.data.extendedData.map((v) => {
        return {
          name: v.name,
          value: v.score,
        };
      });
    },
  },
  methods: {
    switchScreen() {
      this.$router.push({ path: "../financeAnalyzeHp/"+this.$route.params.uuid });
    },
  },
};
</script>

<style lang="stylus" scoped>
.h5-finaceAnalyze-score
  display: flex;
  align-items: center;
  font-size: 0.28rem;
  color: #86909C;
  line-height: 0.28rem;
  margin-bottom: 0.16rem;
  font-weight: 600;

  .name
    margin: 0 0.1rem 0 0;

  .score
    margin-left: 0.04rem;
    color: #1D2129;

.radarData
  margin: 0 auto;

.table-wrapper
  background: #FFFFFF;
  // margin 20px 0
  // margin-bottom 0
  // padding-bottom 24px
  position: relative;

  .sc {
    position: absolute;
    right: 0px;
    bottom: 0px;
    padding: 5px;

    img {
      width: 0.25rem;
      height: 0.25rem;
      background: #FFFFFF;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
      border-radius: 0.08rem;
    }
  }

  .table {
    >>> .bigPowerTable {
      max-height 10rem
      overflow auto
    }
  }

.financialRisk-h5-score
  >>> table.table th, >>> table.table td {
    font-size: 0.2rem;
  }

  >>> .table-wrapper .fake {
    height: 0.52rem !important;
  }

.full-sc {
  .full-tab {

    .t-w {
      display: flex;
      align-items: center;
    }

    transform-origin: center center;
    transform: rotateZ(90deg);

    .titleName {
      font-size: 0.28rem;
      font-weight: 600;
      color: #2B2F58;
      line-height: 0.36rem;
    }

    .dataText {
      font-size: 0.28rem;
      color: #40496A;
      line-height: 0.36rem;
      margin: 12px 0;
    }
  }
  >>> .el-drawer__header {
    padding: 0;
  }
  >>> .el-drawer__body {
    overflow: hidden;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  >>> .el-drawer__open .el-drawer.btt {
    border-radius: 0;
  }

  >>> .el-drawer__close-btn {
    font-size: 30px;
    position: relative;
    right: 10px;
    top: 10px;
  }

  // display: flex;
  // align-items: center;
  // justify-content: center;
  // flex-direction: column;

  .tab-s {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // flex-direction: column;
    // height: 100%;

    max-height: 220px;
    max-width: 500px;
    overflow: auto;

    .tablefirst {
      width: 100%;
      height: 100%;
      overflow: auto;
    }

    .tablefirst tr:nth-child(2n-1) td {
      background: #fff;
    }

    .tablefirst thead tr > th:first-child,
    .tablefirst tbody tr > td:first-child {
      position: -webkit-sticky;
      position: sticky;
      left: 0;
    }

    .table-screen-b {
      td, th {
        min-width: 80px;
        // max-width: 200px;
      }
    }
  }

  .table {
    >>> .bigPowerTable {
      max-height 5.04rem
      max-width 85vh
      overflow auto
    }
  }
}

.table-first {
 >>> table.table th:first-child, >>> table.table td:first-child {
   min-width: 60px;
 }
}
</style>
