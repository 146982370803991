<template>
  <div>
    <el-dialog
      title="授权协议"
      width="820px"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
    >
      <div class="content">
        <div>
          本《授权协议》适用于我们提供的所有服务，在使用各项服务前，请您务必仔细阅读并透彻理解本《授权协议》，在确认充分理解并同意后再开始使用。当您使用我们的服务时，您已同意本《授权协议》。请注意我们会不时地检查并更新我们的政策，因此有关的措施会随之变化。我们恳请您定期光顾本页面，以确保对我们《授权协议》最新版本始终保持了解。
        </div>
        <div class="title">我们如何收集和使用信息？</div>
        <div>
          您通过下载插件，并通过插入金税盘与输入金税盘账号密码的形式，授权我们采集您使用服务期间的财务数据，包括但不限于进销项发票数据。您通过填写电子税务局账号密码的方式，授权我们采集您使用服务期间的纳税申报表数据，包含但不限于所得税纳税申报表、增值税纳税申报表、财务报表。
        </div>
        <div class="title-1">1、采集您的信息</div>
        <div>
          （1）采集您的进项发票数据，将作为您所在的企业数据展示使用及风险数据评估使用。
          <br />（2）采集您的销项发票数据，将作为您所在的企业数据展示使用及风险数据评估使用。
          <br />（3）采集您的财务数据，数据内容包括但不限于您通过财务软件获取的数据、导入Excel表格所载明的财务数据、通过HTML获取的财务数据、通过手动填写的财务数据或者通过填写电子税务局账号密码的方式，将作为您所在的企业数据展示使用及风险数据评估使用。
          <br />（4）您提交的纳税申报数据（包含所有所得税纳税申报表与增值税纳税申报表），包括但不限于您通过财务软件获取的数据、导入Excel表格所载明的财务数据、通过HTML获取的财务数据、通过手动填写的财务数据或者通过填写电子税务局账号密码的方式，将作为您所在的企业数据展示使用及风险数据评估使用。
          <br />（5）在您授权的情况下，本产品数据可应用于平台其他产品或平台合作第三方合作伙伴。在您未授权的情况下，平台其他产品或平台合作第三方合作伙伴将无权使用您的数据。我们将在符合相关法律法规规定，并依据与关联方或第三方合作伙伴的约定、确信其提供的信息来源合法的前提下，收集并使用您的这些信息，但仍需您同意第三方合作伙伴的相关授权协议。
        </div>
        <div class="title-1">2、信息安全</div>
        <div>
          我们为您的信息提供相应的安全保障，以防止信息的丢失、不当使用、未经授权访问或披露。
          <br />（1）我们严格遵守法律法规保护您的通信秘密。
          <br />（2）我们将在合理的安全水平内使用各种安全保护措施以保障信息的安全。例如，我们使用加密技术（例如，TLS、SSL）、匿名化处理等手段来保护您的信息。
          <br />（3）我们建立专门的管理制度、流程和组织确保信息安全。例如，我们严格限制访问信息的人员范围，要求他们遵守保密义务，并进行审查。
          <br />（4）若发生信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大，并以推送通知、公告等形式告知您。
        </div>
        <div class="title-1">3、免责声明</div>
        <div>
          （1）您理解并同意，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害以及社会事件如战争、动乱、政府行为等。出现上述情况时，我们将努力在第一时间与相关单位配合，及时进行修复，但是由此给您造成的损失我们在法律允许的范围内免责。
          <br />（2）您理解并同意，您使用的产品中的部分服务由第三方独立开发并提供，我们已将此类应用予以标识，在您选择使用此类服务之前，请您关注其中可能存在的风险，并遵守第三方制定的相应使用规则。我们依据本协议约定获得处理违法违规内容的权利，该权利不构成我们的义务或承诺，我们不能保证及时发现违法行为或进行相应处理。
          <br />（3）在任何情况下，我们及我们的董事、高级职员、雇员、关联公司、代理、承包商或许可方均不对由您对有关服务的使用或无法使用所引起的任何间接、附带、惩罚性、特殊或后果性的不可预期的损害赔偿承担责任。您已了解，您应自担服务使用风险，并且我们将按“现状”提供服务，服务“可能存在各种缺陷”且只提供“目前可用功能”。我们不保证服务所提供的信息的准确性或及时性。
        </div>
        <div class="title-1">4、其他</div>
        <div>
          （1）本协议自您同意之日起生效，有效期至您停止使用我们提供的服务之日止，如您未同意本协议，将无法正常使用我们提供的服务。
          <br />（2）我们承诺，在您使用服务期间所采集的信息，您停止使用服务后我们也将及时删除或销毁此信息，不会将您的信息挪作他用。
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    openDialog() {
      this.dialogVisible = true;
    },
  },
};
</script>

<style scoped>
.content {
  /* width: 1200px;
  height: 1089px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px; */
  /* margin: 30px;
  padding: 22px 352px 22px 48px;
  box-sizing: border-box; */
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  line-height: 1.5;
}

.title {
  font-size: 18px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  margin: 24px 0 8px 0;
}

.title-1 {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  margin: 16px 0 8px 0;
}
</style>
