<template>
  <div class="rela-c">
    <div class="container">
      <div class="title">申报数据采集</div>
      <div class="flex-conetent">
        <div class="user">
          <span class="left">电子税务局账号：</span>
          <el-input class="input" v-model.trim="name" placeholder="请输入"></el-input>
        </div>
        <div class="user">
          <span class="left">电子税务局密码：</span>
          <el-input
            class="input"
            type="password"
            v-model.trim="password"
            placeholder="请输入"
            :show-password="true"
          ></el-input>
        </div>

        <div class="user">
          <span class="left">委托人手机号/身份证号码/操作员代码：</span>
          <el-input class="input" v-model.trim="czydm" placeholder="请输入"></el-input>
        </div>

        <div class="read">
          <el-checkbox v-model="active">我已阅读并同意</el-checkbox
          ><span class="active" @click="openLicense">《授权协议》</span>
        </div>
      </div>
      <licen ref="opendialog"></licen>
    </div>
    <div class="btn btn-primary report" @click="report">授权</div>
  </div>
</template>

<script>
import { breezeOuterSD, breezeOuterSDCheck } from "@/api/api";
import licen from "./components/licen-dialog";

export default {
  components: {
    licen,
  },
  data() {
    return {
      // 公用参数
      // 省份信息
      province: "山东",
      //省份信息
      name: "", //账号，如果有用税号取数成功过，自动带进来
      password: "", // 密码
      // 公用参数

      // 山东参数
      SDMethod: false, // 山东授权方式
      czydm: "", // 操作员代码
      // 山东参数

      //授权协议
      active: false,
      //授权协议
      uuid: this.$route.query.uuid,
      appId: this.$route.query.appId,
      callBackUrl: this.$route.query.callBackUrl,
    };
  },
  methods: {
    openLicense() {
      this.$refs.opendialog.openDialog();
    },
    report() {
      if ([this.name, this.password, this.czydm].includes("")) {
        this.$message({
          message: "信息不能为空！",
          type: "warning",
        });
        return;
      }
      if (!this.active) {
        this.$message({
          message: "请阅读并勾选授权协议",
          type: "warning",
        });
        return;
      }
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        taxSiteUsername: this.name,
        taxSitePassword: this.password,
        czydm: this.czydm,
        uuid: this.uuid, // 路径上获取
      };
      breezeOuterSD(params).then((res) => {
        this.loading.close();
        if (res.code == 200) {
          this.getData();
        } else {
          this.$alert(res.msg, "温馨提示", {
            confirmButtonText: "确定",
          });
          return false;
        }
      });
    },
    getData() {
      let params = {
        appId: this.appId, // 路径上获取
        companyTaxNo: this.name,
        programId: this.uuid,
      };
      breezeOuterSDCheck(params).then((res) => {
        if (res.code == 200) {
          window.location.href = this.callBackUrl;
        } else {
          this.$alert(res.message, "温馨提示", {
            confirmButtonText: "确定",
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="stylus">
.rela-c {
  position: relative;

  .report {
    position: absolute;
    right: 30px;
    top: 330px;
    padding: 8px 30px;
  }

  .container {
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    padding-bottom: 48px;

    .flex-conetent {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .title {
      color: rgba(0, 0, 0, 0.8);
      padding-left: 16px;
      line-height: 54px;
      font-size: 18px;
      font-weight: 600;
      border-bottom: 1px solid #eeeeee;
      text-align: center;
    }

    .user {
      margin: 16px 0 0 60px;

      .input {
        width: 600px;
      }

      .left {
        display: inline-block;
        width: 270px;
        text-align: right;
      }
    }

    .read {
      display: flex;
      align-items: center;
      margin: 20px 0 0 180px;

      .active {
        color: #2f54eb;
        cursor: pointer;
      }
      >>> .el-checkbox__input.is-checked + .el-checkbox__label {
        color: #2f54eb;
      }
    }

    .ease {
      transition: all 0.3s;
    }
  }
}
</style>
