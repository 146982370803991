<template>
  <div>
    <bread-crumb>
      <el-breadcrumb-item :to="{ name: 'goods' }">产品中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: 'goods' }">服务订购</el-breadcrumb-item>
      <el-breadcrumb-item>确认订单</el-breadcrumb-item>
      <el-breadcrumb-item>支付订单</el-breadcrumb-item>
    </bread-crumb>
    <div class="submit-title">支付订单</div>
    <div v-if="price">
      <div class="record-select">
        <div>
          <div class="price-c-1 price-c">订单提交成功</div>
          <div class="price-c-2 price-c">请您在3个小时内完成支付，否则订单会被自动取消</div>
          <div class="price-c-3 price-c">订单编号：{{orderNum}}</div>
        </div>
        <div>
          <div>
            应付金额：
            <span class="addred">{{Number(totalPrice).toFixed(2)}}</span>元
          </div>
        </div>
      </div>
      <div class="price-container">
        <div class="price-item" :class="{addAct:act==0}" @click="act = 0">
          <img src="./img/wetchat_pay.png" />微信支付
        </div>
        <div class="price-item" :class="{addAct:act==1}" @click="payal">
          <img src="./img/al_pay.png" />支付宝支付
        </div>
      </div>
      <div class="price-pay" v-if="act==0">
        <div class="price-img">
          <img :src="wetch_imgs" alt />
        </div>
        <div>扫描上方二维码支付...</div>
      </div>
      <div class="price-pay" v-else>
        <div class="price-img">
          <img :src="pay_imgs" alt />
        </div>
        <div>扫描上方二维码支付...</div>
      </div>
    </div>
    <div v-else class="price-cancel">
      <div class="cancel-icon">
        <span class="cancel-icons">✔</span>
      </div>
      <div>支付成功</div>
      <div class="backresult" v-if="backResult">{{resultNum}}秒后返回检测结果...</div>
      <div v-if="!backResult">
        <span class="cancel-btn ease" @click="searchOrder">查看订单</span>
        <span class="cancel-btn ease" @click="goCheck">立即检测</span>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import { openOrder } from "../../api/api";
import { setInterval } from "timers";

export default {
  components: {
    BreadCrumb
  },
  data() {
    return {
      act: 0,
      orderIds: this.$route.query.id,
      pay_imgs: "",
      wetch_imgs: "",
      price: true,
      orderNum: this.$route.query.num,
      resultNum: 3,
      backResult: false,
      thisNum: 0,
      totalPrice: 0,
      locked: 1
    };
  },
  created() {
    if (localStorage.getItem("totalPrice")) {
      this.totalPrice = localStorage.getItem("totalPrice");
      localStorage.removeItem("totalPrice");
    }
    window.addEventListener("beforeunload", () => {
      localStorage.setItem("totalPrice", this.totalPrice);
    });
    this.pingOrder("wx_pub_qr");
    // 轮询
    var that = this;
    let n = setTimeout(function cb() {
      let params = {
        token: JSON.parse(localStorage.getItem("token")).token,
        ordernumber: that.orderNum
      };
      orderStatus(params).then(res => {
        if (res.msgCode == -1) {
          that.$message({
            type: "error",
            message: "未知网络错误，请检查网络或咨询客服！",
            duration: 5000
          });
          that.$router.push({
            path: "/"
          });
          return;
        }
        if (
          (res.msgCode == 1 && res.data.status == 4) ||
          (res.msgCode == 1 && res.data.status == 5)
        ) {
          that.price = false;
          const mynames = sessionStorage.getItem("redirectedToAfterPaid");
          if (mynames) {
            that.backResult = true;
            setInterval(() => {
              that.resultNum--;
              if (that.resultNum == 0) {
                that.$router.push({
                  path: mynames
                });
              }
            }, 2000);
          }
        } else {
          that.price = true;
          if (that.locked) {
            n = setTimeout(cb, 2000);
          }
        }
      });
    }, 2000);
  },
  mounted() {
    history.pushState(null, null, document.URL);
    window.addEventListener("popstate", function() {
      history.pushState(null, null, document.URL);
    });
  },
  methods: {
    goCheck() {
      this.$router.push({
        path: "/"
      });
    },
    searchOrder() {
      this.$router.push({
        path: "/product/record"
      });
    },
    statusMethods() {},
    payal() {
      this.act = 1;
      this.pingOrderpay("alipay_qr");
    },
    pingOrder(method) {
      let params = {
        orderId: this.orderIds,
        channel: method,
        imageWidth: 200,
        imageHeight: 200
      };
      openOrder(params).then(res => {
        if (res.data) {
          this.wetch_imgs = res.data.image;
        } else {
          this.$message({
            type: "info",
            message: "生成二维码失败，请检查网络或咨询客服！"
          });
        }
      });
    },
    pingOrderpay(method) {
      let params = {
        orderId: this.orderIds,
        channel: method,
        imageWidth: 200,
        imageHeight: 200
      };
      openOrder(params).then(res => {
        if (res.data) {
          this.pay_imgs = res.data.image;
        } else {
          this.$message({
            type: "info",
            message: "生成二维码失败，请检查网络或咨询客服！"
          });
        }
      });
    }
  },
  destroyed() {
    this.locked = 0;
    const myname = sessionStorage.setItem("redirectedToAfterPaid", "");
  }
};
</script>
<style lang="stylus" scoped>
.submit-title {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.8);
  padding: 8px 0 0 30px;
  font-weight: 500;
}

.record-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // min-width: 1100px;
  height: 129px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  margin: 24px 30px 0 30px;
  color: rgba(0, 0, 0, 0.75);
  padding-left: 24px;
  padding-right: 48px;
  box-sizing: border-box;
}

.price-c {
  line-height: 1.8;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
}

.price-c-1 {
  color: rgba(0, 0, 0, 0.8);
  font-size: 18px;
  font-weight: 500;
}

.addred {
  color: red;
}

.price-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // min-width: 1100px;
  margin: 24px 30px 0 30px;
}

.price-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 49%;
  height: 48px;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  border: 1px solid rgba(98, 97, 242, 1);
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
}

.price-item img {
  width: 32px;
  height: 32px;
  padding-right: 8px;
}

.price-pay {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // min-width: 1100px;
  height: 475px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  margin: 16px 30px 0 30px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
}

.addAct {
  background: rgba(98, 97, 242, 0.08);
}

.price-img {
  width: 200px;
  height: 200px;
  background: #f0f0f0;
  margin-bottom: 16px;
  border-radius: 8px;
}

.price-img img {
  width: 100%;
  height: 100%;
}

.price-cancel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 24px 30px 50px 30px;
  // min-width: 1200px;
  height: 680px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  color: rgba(0, 0, 0, 0.6);
}

.cancel-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: rgba(82, 196, 26, 1);
  border-radius: 50%;
  margin-bottom: 8px;
}

.cancel-icons {
  color: #fff;
  font-size: 32px;
  transform: rotate(15deg);
}

.cancel-btn {
  display: inline-block;
  width: 104px;
  height: 36px;
  line-height: 36px;
  border-radius: 4px;
  border: 1px solid rgba(47, 84, 235, 1);
  color: rgba(47, 84, 235, 1);
  text-align: center;
  margin-top: 48px;
  margin-right: 12px;
  font-size: 14px;
  cursor: pointer;
}

.cancel-btn:hover {
  background: rgba(47, 84, 235, 1);
  color: #fff;
}

// .cancel-btn2 {
// background: rgba(47, 84, 235, 1);
// border-radius: 4px;
// color: #fff;
// margin-left: 12px;
// }
.ease {
  transition: all 0.3s;
}

.price-check {
  width: 104px;
  height: 36px;
  line-height: 36px;
  background: rgba(47, 84, 235, 1);
  border-radius: 4px;
  text-align: center;
  color: #fff;
  margin-top: 48px;
  cursor: pointer;
}

.price-check:hover {
  background: rgba(98, 97, 242, 1);
}

.ease {
  transition: all 0.3s;
}

.backresult {
  margin-top: 10px;
}
</style>