<template>
  <div>
    <div class="header-wrapper">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ name: 'homePage' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>风险检测</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>

      <main-header>
        <template slot="title">风险检测</template>
        <!--        <template slot="desc">平台针对不同类型/规模/行业/地区的企业设置多种风险库供企业选择，企业可选择符合自身企业性质的风险库进行税务风险检测</template>-->
      </main-header>
    </div>
    <div class="empty-wrapper" v-if="tables.length==0">
      <img src="@/assets/test/empty.png" class="empty"/>
      <router-link :to="{name:'goods'}" class="btn btn-primary btn-buy">前往购买</router-link>
    </div>
    <div class="cards" v-loading="isLoading" element-loading-text="拼命加载中" v-else>

      <evaluate-card class="card" v-for="table in tables" v-bind="table" :key="table.id"
                     @click="handleEvaluateCardClick"
                     :isShanDong="isShanDong"
                     :class="{'selected':selectedEvaluateCard&&(table.id ==selectedEvaluateCard.id)}"
                     :data="table"></evaluate-card>

    </div>


    <saFooter v-if="tables.length>0">
      <!-- <router-link
        v-if="zqInfoLoaded&&versionRadio>0"
        :to="{ name: 'upload-table', params: { id:tableCards[0].id,zq:tableCards[0].zq,zqLocation:zqs[0],dataPeriod:0,version:this.versionRadio,user:this.userNum}}"
        class="btn btn-primary btn-next"
      >下一步</router-link> -->
      <div
        class="btn btn-primary btn-next"
        @click="handleBtnNextCLick"
      >
        下一步
      </div>

    </saFooter>

    <DialogOfIncludeInvoice ref="DialogOfIncludeInvoice" @startCheck="handleDialogStartCheck" :selectedEvaluateCard="selectedEvaluateCard" @startCheckSd="startCheckSd"
                            :features="features"></DialogOfIncludeInvoice>
  </div>
</template>

<script>
import EvaluateCard from "./components/EvaluateCard";

import {findBuyEdition, requestTableInfo} from "@/api/api.js";

import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "../../components/MainHeader";
import Footer from "@/pages/Test/ChooseTable/components/Footer";
import DialogOfIncludeInvoice from "@/pages/Test/MyTest/DialogOfIncludeInvoice";


export default {
  components: {
    EvaluateCard,
    BreadCrumb,
    MainHeader,
    SaFooter: Footer,
    DialogOfIncludeInvoice
  },
  data() {
    return {
      isShanDong: 0,
      tables: [],
      isLoading: true,
      features: ["申报数据/余额表采集", "发票数据采集"], // 0:没有发票 1:只有发票 2:包含发票
      selectedEvaluateCard: null, //当前选中的产品
    };
  },
  async mounted() {
    sessionStorage.removeItem("isShowBase");
    let token = JSON.parse(localStorage.getItem("token")).token;


    //产品信息
    const res = await findBuyEdition({
      token
    });

    this.isLoading = false;
    this.tables = res.data;

  },
  methods: {
    handleEvaluateCardClick(card) {
      this.selectedEvaluateCard = card;
    },
    //点击下一步按钮
    handleBtnNextCLick() {
      sessionStorage.removeItem("basicConfig")
      if (!this.selectedEvaluateCard) {
        this.$msgbox.alert("请先选择一个产品")
      }

      if (this.selectedEvaluateCard.data.edition == 5) { //发票版

        this.features = ["发票数据采集"];
        //this.$refs['DialogOfIncludeInvoice'].open();
        this.$router.push(this.selectedEvaluateCard.routes);
      } else if (this.selectedEvaluateCard.data.edition == 11) { // 运营版
        this.features = ["申报数据/余额表采集"]
        //this.$refs['DialogOfIncludeInvoice'].open();
        this.$router.push(this.selectedEvaluateCard.routes);
      } else if (this.selectedEvaluateCard.data.edition == 9) { // 运营版
        this.features = []
        this.$router.push(this.selectedEvaluateCard.routes);
      } else {
        this.features = ["申报数据/余额表采集", "发票数据采集"];
        //this.$refs['DialogOfIncludeInvoice'].open();
        this.$router.push(this.selectedEvaluateCard.routes);
      }
    },
    handleDialogStartCheck() {
      this.$router.push(this.selectedEvaluateCard.routes);
    },
    startCheckSd() {
      this.selectedEvaluateCard.routes.params.isShanDong = 1;
      this.$router.push(this.selectedEvaluateCard.routes);
    }
  },

};
</script>

<style lang="stylus" scoped>
.header-wrapper {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 0px 0px rgba(238, 238, 238, 1);

  .title {
    font-weight: bold;
  }
}

.empty-wrapper {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  background: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 30px;
  padding: 160px 0 279px;

  .empty {
    width: 470px;
    height: 269px;
    margin: 0px auto;

  }

  .btn-buy {
    width: 112px;
    height: 33px;
    line-height: 33px;
    font-size: 16px;
    margin-top: 24px;
  }

}


.cards {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  background: #FFFFFF;
  display: flex;
  flex-wrap: wrap;
  margin: 30px;
  justify-content: flex-start;
  padding: 9px 8px 0;


  .card {
    width: 270px;
    height: 140px;
    padding: 24px;
    box-sizing: border-box;
    margin: 8px;
    border: 1px solid #fff;
    transition: 0.3s all;
  }

  .card:hover {
    background: rgba(47, 84, 235, 0.04);
    // border:1px solid rgba(47,84,235,0.48);
  }

  .card.selected {
    border: 1px solid blue;
  }

  .placeholder {
    width: 288px;
    box-sizing: border-box;
    margin: 20px 0px;
  }

}

>>> .bread-crumb-wrapper {
  margin-top: 0;
}
</style>
