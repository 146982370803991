<template>
  <div class="main-wrapper">
    <header-info
      :score="score"
      :companyInfo="companyInfo"
      @checkCompanyClick="checkCompanyClick"
      :haveAuth="haveAuth"
      :hyMcName="hyMcName"
      ref="mychild"
    ></header-info>
    <!-- <div class="good-Container">
      <goods :versioNum="versioNum"></goods>
    </div> -->
    <!-- 试用提示 -->
    <!-- <sa-tip class="tip" v-if="versionPurchased<3&&versionPurchased!=-1"></sa-tip> -->
    <!-- 试用提示 结束 -->

    <!-- 使用流程图 -->
    <!-- <sa-steps :versionPurchased="versionPurchased"></sa-steps> -->
    <!-- 使用流程图 结束 -->

    <!-- 风险检测开始 -->
    <div class="fix-section-box section-box">
      <div class="fix-title">风险检测</div>
      <div class="detection">
        <img
          class="icon"
          src="@/assets/home/undraw_programming_2svr@2x.png"
          alt=""
        />
        <div class="right-wrapper">
          <div class="inner">
            <div class="text">
              一键智能检测企业的税务风险，帮助企业提前发现<br />涉税隐患，降低涉税风险，同时提供解决方案。
            </div>
            <div class="button ease" @click="getGoods">立即检测</div>
          </div>
        </div>
      </div>
    </div>
    <tools class="tools"></tools>
    <!-- 风险检测结束 -->

    <!-- 快捷入口开始 -->
    <!-- <Sa-Shortcut :utilList="utilList"></Sa-Shortcut> -->
    <!-- 快捷入口结束 -->
    <!--广告弹出-->
    <sa-advertisement></sa-advertisement>
    <!--/广告弹出-->
    <!--新手指引-->
    <!-- <SideFixedBar></SideFixedBar> -->
    <!--/新手指引-->
    <!-- 切换企业弹框 -->
    <el-dialog :visible.sync="checkCompany" id="auto2dialog" :show-close="true">
      <div class="check_company">切换企业</div>
      <div class="check_companybg test-1">
        <el-radio-group
          v-model="filters.companyvalue"
          class="auto_radio"
          v-for="(item, index) in companyList"
          :key="index"
        >
          <el-radio :label="item.companyId">
            <span>{{ item.companyId }}</span>
            {{ item.companyName }}
          </el-radio>
        </el-radio-group>
      </div>
      <div class="autotabbtn">
        <el-button type="primary" @click="changecomp">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  homemsg,
  checkProductPermission,
  getUUID,
  mateGoods,
  getCompanyId,
  fetchCompanyInfo,
  changecompanyselect,
  changecompany,
  findhydm,
} from "@/api/api";

import { getHighestVersionPurchased } from "@/utils/helper";

import Tip from "./components/Tip";
import Shortcut from "./components/Shortcut";
import Steps from "./components/Steps";
import Advertisement from "./components/Advertisement";
import headerInfo from "./components/headerCompany";
import goods from "./components/goods";
import tools from "./components/tools";

import SideFixedBar from "@/pages/components/SideFixedBar";
import { regionData } from "element-china-area-data";
import { area } from "./components/limit.js";

export default {
  components: {
    SaTip: Tip,
    SaShortcut: Shortcut,
    SaSteps: Steps,
    SideFixedBar,
    SaAdvertisement: Advertisement,
    headerInfo,
    goods,
    tools,
  },
  data() {
    return {
      msgList: [],
      comList: [],
      utilList: [],
      imgUrl: "",
      versionPurchased: -1,
      score: null,
      companyInfo: {},
      companyID: "",
      versioNum: {},
      conversionArea: "",
      city: regionData,
      checkCompany: false,
      companyList: null,
      filters: {},
      haveAuth: false,
      loading: "",
      hyMcName: "",
    };
  },
  async mounted() {
    this.getcompanyId();
    // const { theHighestVersion } = await getHighestVersionPurchased();

    // this.versionPurchased = theHighestVersion;
    //console.log(this.versionPurchased);

    // let tables;
    // try {
    //   tables = await requestTableInfo({
    //     token,
    //     isIndex: "1"
    //   });
    //   this.comList = [...tables.data];
    //   debugger;
    //   //console.log(this.comList);
    // } catch (error) {
    //   //console.log(error);
    // }
    //快捷入口

    // this.getShortcuts();

    //企业信息
    await this.$store.cache.dispatch("loadCompanyInfo");
  },
  methods: {
    //企业下拉框
    changecompanyselect() {
      let user = JSON.parse(localStorage.getItem("user")).id;
      let params = {
        id: user,
      };
      changecompanyselect(params).then((res) => {
        if (res.msgCode == -1) {
          this.$message({
            showClose: true,
            message: res.msgInfo,
            type: "error",
          });
          return;
        }
        this.companyList = res.data;
      });
    },
    //确认切换企业
    changecomp() {
      if (!this.filters.companyvalue) {
        this.$message.error({
          message: "请选择需要切换的企业",
        });
        return;
      }
      let params = {
        companyId: this.filters.companyvalue,
        userId: JSON.parse(localStorage.getItem("user")).id,
      };
      changecompany(params).then(async (res) => {
        if (res.msgCode == "1") {
          this.$refs.mychild.getCount();
          this.$message.success({
            message: "切换企业成功",
            duration: 1500,
          });
          this.getcompanyId();
          // this.FormInfo.companyName = this.companyvalue;
          this.checkCompany = false;
          localStorage.removeItem("taxSiteUsername");
        } else {
          this.$message.success({
            message: res.msgInfo,
            duration: 1500,
          });
          return;
        }
      });
    },
    //点击切换企业显示弹框
    checkCompanyClick() {
      this.checkCompany = true;
      this.changecompanyselect();
    },

    getcompanyId() {
      getCompanyId(this.$store.state.authorization).then((res) => {
        if (res.msgCode == 1) {
          this.haveAuth = true;
          this.companyID = res.data.companyId;
          localStorage.setItem("hasPwd", res.data.hasPwd);
          let params = {
            companyID: this.companyID,
          };
          fetchCompanyInfo(params).then(async (res) => {
            this.companyInfo = res.data;
            const user = JSON.parse(localStorage.getItem("user"));
            user.channalId = res.data.channalId;
            user.channalUserId = res.data.channalUserId;
            user.companyId = res.data.id;
            user.nsrsbm = res.data.nsrsbm;
            localStorage.setItem("user", JSON.stringify(user));
            await this.deleteCache();
            // this.indexscoresNum();
            // this.indexversionNum();
            if (res.data.dq) {
              let dq = res.data.dq.split(",");
              this.conversionArea = area([dq[0], dq[1], dq[2]], this.city);
              this.companyInfo.area = this.conversionArea;
            } else {
              this.companyInfo.area = null;
            }
            let params = {
              hyDm: res.data.hy,
            };
            if (res.data.hy) {
              findhydm(params).then((res) => {
                this.filters.hy1 = res.data.mlhyDm;
                this.hyMcName = res.data.hyMc;
              });
            }
          });
        } else {
          this.haveAuth = false;
        }
      });
    },

    async deleteCache() {
      this.$store.cache.delete("loadCompanyRenzheng"); //删除认证缓存
      this.$store.cache.delete("loadCompanyInfo"); //删除企业信息
      await this.$store.cache.dispatch("loadCompanyRenzheng");
      await this.$store.cache.dispatch("loadCompanyInfo");
    },

    async getGoods(name) {
      sessionStorage.setItem("method", 1);

      const permission = await checkProductPermission(
        this.$store.state.authorization,
        this.$store.state.goods.id,
        // buyStatus == "Y" ? null : "onTrain" //如果已经购买 则不传onTrain
        null //如果已经购买 则不传onTrain
      );

      if (permission && permission.msgCode == 1) {
        //设置permissionID
        this.$store.commit("UploadTable/setPermissionID", {
          permissionID: permission.data.id,
        });
        // await this.getUUID();
        this.$router.push({
          name: "test-hub",
        });
      } else if (permission.msgCode == "1002") {
        this.$store.commit("UploadTable/setPermissionID", {
          permissionID: "",
        });
        this.$confirm("请先完善企业信息", "提示", {
          confirmButtonText: "完善信息",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$router.push({ name: "companyApply" });
        });
      } else if (permission.msgCode == "1007") {
        this.$confirm(permission.msgInfo, {
          confirmButtonText: "前往完善",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push({
              path: "/setUserCompany",
            });
          })
          .catch(() => {});
      } else if (permission.msgCode == "1028") {
        this.$confirm(permission.msgInfo, {
          confirmButtonText: "前往完善",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push({
              path: "/setUserCompany",
            });
          })
          .catch(() => {});
      } else {
        this.$store.commit("UploadTable/setPermissionID", {
          permissionID: "",
        });
        this.$alert(permission.msgInfo, "错误", {
          type: "error",
        });
      }
    },

    tocalc(row) {
      this.$router.push(row);
    },
    toindiv() {
      this.$router.push("/util/individual_tax");
    },
    tonormal() {
      this.$router.push("/util/abnormal_tax");
    },
    getShortcuts() {
      homemsg().then((res) => {
        for (var i in res.data.data.MsWhPlant) {
          const v = res.data.data.MsWhPlant[i];
          const index = Math.floor(i / 4);
          if (!this.msgList[index]) {
            this.msgList[index] = [];
          }
          this.msgList[index].push(v);
        }

        this.utilList = res.data.data.TaxUtilPlant;
        this.imgUrl = res.data.data.BannerPlant[0].imgUrl;
      });
    },
  },

  destroyed() {
    this.loading && this.loading.close();
  },
};

//import "./style.stylus";
</script>
// <style src="@/styles/index.styl" lang="stylus" scoped></style>
<style lang="stylus" scoped>
.main-wrapper {
  background: #fbfbfb;
  padding: 0 0 30px 0;

  .fix-section-box {
    padding: 0;

    .fix-title {
      background: rgba(255, 255, 255, 1);
      border-radius: 8px 8px 0px 0px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
      padding-left: 24px;
      padding-top: 24px;
      line-height: 25px;
    }

    .detection {
      display: flex;
      justify-content: center;
      padding: 24px 24px;

      .icon {
        width: 576px;
        height: 308px;
      }

      .left-wrapper {
        text-align: center;
        width: 50%;
      }

      .right-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50%;

        .inner {
          .text {
            width: 100%;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.6);
            line-height: 22px;
          }

          .button {
            margin-top: 24px;
            display: block;
            text-align: center;
            line-height: 97px;
            color: #fff;
            width: 365px;
            height: 97px;
            background: linear-gradient(180deg, #5C8DF7 0%, #2F54EB 100%);
            border-radius: 77px;
            font-size: 20px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .tools {
    margin: 24px 30px 30px 30px;
  }
}

.ease {
  transition: all 0.3s;
}

>>>#auto2dialog {
  .el-dialog {
    width: 480px;
    border-radius: 10px;

    .el-dialog__header {
      // display: none;
      border: none;
    }

    .el-dialog__body {
      padding: 0;
    }
  }
}

.check_company {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  height: 92px;
  line-height: 92px;
  margin-top: -30px;
  text-align: center;
}

.autotabbtn {
  height: 92px;
  width: 100%;

  .el-button--text {
    color: #007AFF;
  }

  .el-button--primary {
    width: 100%;
    height: 54px;
    font-size: 16px;
    background-color: #2F54EB;
    margin-top: 40px;
    border-radius: 0px 0px 8px 8px;
    border: none;
  }
}

.check_companybg {
  max-height: 450px;
  overflow-y: auto;
}

.test-1::-webkit-scrollbar {
  /* 滚动条整体样式 */
  width: 10px; /* 高宽分别对应横竖滚动条的尺寸 */
  height: 1px;
}

.test-1::-webkit-scrollbar-thumb {
  /* 滚动条里面小方块 */
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.4);
}

.test-1::-webkit-scrollbar-track {
  /* 滚动条里面轨道 */
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #ededed;
}
</style>
