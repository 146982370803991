<template>
  <div>
    <div class="whiteStyle"></div>
    <div class="h5-wrapper">
      <van-tabs v-model="active">
        <van-tab :title="v.name" v-for="(v, k) of imgData" :key="k">
          <div class="invoice-list">
            <!-- <img :src="a.invoiceCode" v-for="(a,b) of v.invoiceList" :key="b"
               alt="" class="invoice-item" @click="handlePicClick(a.invoiceCode)"> -->
            <fapiao
              :data="a"
              v-for="(a, b) of v.invoiceList"
              :key="b"
              class="invoice-item"
            ></fapiao>
            <div>
              <BtnOrder></BtnOrder>
            </div>
          </div>
        </van-tab>
        <!-- <van-tab title="零税额开票">
        <img :src="v.invoiceCode" v-for="(v,k) of imgData[1].invoiceList" :key="k"
               alt="" class="invoice-item" @click="handlePicClick(v.invoiceCode)">
      </van-tab> -->
      </van-tabs>
      <van-overlay :show="show" @click="show = false">
        <img :src="currentPic" alt="" class="invoice-item-horizontal" />
      </van-overlay>
    </div>
  </div>
</template>

<script>
import { getSpecialInvoiceDetail } from "@/api/bossWatchReport";
import fapiao from "./components/fapiao";
import BtnOrder from "../component/BtnOrder.vue";

export default {
  name: "SpecialInvoiceDetail",
  components: { fapiao, BtnOrder },
  data() {
    return {
      active: 0,
      show: false,
      currentPic: "",
      imgData: [],
    };
  },
  watch: {
    $route(val) {
      console.log(val);
      if (val.params.txType) {
        this.getImgData();
      }
    },
  },
  mounted() {
    this.getImgData();
  },
  methods: {
    async getImgData() {
      let params = {
        uuid: this.$route.params.uuid,
        txType: this.$route.params.txType,
      };
      let res = await getSpecialInvoiceDetail(params);
      if (res.data) {
        this.imgData = res.data;
      }
      console.log(res.data);
    },
    handlePicClick() {
      this.currentPic =
        "https://p9.itc.cn/q_70/images01/20210426/fd86da2d027a48718b22795affefb2e8.jpeg";
      this.show = true;
    },
  },
};
</script>

<style lang="stylus" scoped>
.h5-wrapper {
  padding 0px
}


:deep(.van-tabs__wrap) {
  margin-bottom 25px
  position sticky
  margin-top 20px
  top 0
}

:deep(.van-tab) {
  font-size: 16px;
  font-weight: 400;
  color: #4E5969;
}

:deep(.van-tab.van-tab--active) {
  font-weight: 600
  color: #1677FF;
  line-height: 24px;
}

:deep(.van-tabs__line) {
  background-color #1677FF
}


.invoice-list {
  margin: 0 auto
  width: 100%
  height: 85vh;
  overflow: auto;
  .invoice-item {
    width: 100%
    height: 530px;
    margin-bottom 20px
    >.invoicMain{

    }
  }
}

.invoice-item-horizontal {
  transform: translate(-50%, -50%) rotate(90deg);
  position fixed;
  top 50%;
  left 50%;
  max-width 100vh
  max-height 100vw
}
@media screen and (min-width:1300px) and (max-width: 2000px){
  .whiteStyle{
   height 40px;
  }
  .h5-wrapper {
    padding 00px
    width: 375px;
    margin:0px auto;
    height:800px;
    overflow: auto;
    padding-bottom 0px
    box-shadow: 3px 2px 11px 2px #dedede;

    >>>.btn-order{
      top: 827px;
    }
  }
}
@media screen and (min-width:600px) and (max-width: 1300px){
  .whiteStyle{
   height 10px;
  }
  .h5-wrapper {
    padding 00px
    width: 375px;
    margin:0px auto;
    height:600px;
    overflow: auto;
    padding-bottom 0px
    box-shadow: 3px 2px 11px 2px #dedede;

    >>>.btn-order{
      top: 596px;
    }
  }
}
@media screen and (min-width:300px) and (max-width: 600px){
  >>>.btn-order{
    left: -9px;
  }

}
</style>
