<template>
  <div class="wrapper" v-if="companyInfo">
    <div>
      <PurchaseAndSaleTabItem
        :zq="companyInfo.zq"
        :taxNo="companyInfo.taxNo"
        :companyType="activeIndex"
      ></PurchaseAndSaleTabItem>
    </div>
  </div>
</template>

<script>
import "@/pages/Result/ThreeYearResult/global.styl";
import PurchaseAndSaleTabItem from "@/pages/Result/H5FinancialAnalysis/purchaseAndSale/PurchaseAndSaleTabItem";
import { getCompanyInfo } from "@/api/threeYears";

export default {
  name: "PurchaseAndSaleTab",
  components: {
    PurchaseAndSaleTabItem,
  },
  props: {
    taxNo: String,
  },
  data() {
    return {
      activeIndex: "2",
      companyInfo: null,
    };
  },
  async mounted() {
    await this.loadCompanyInfo();
  },
  methods: {
    async loadCompanyInfo() {
      const res = await getCompanyInfo({ programId: this.$route.params.uuid });
      this.companyInfo = res.data;
    },
    /**
     * 顶部菜单点击
     * @param {string} key
     * @param {array<string>}keyPath
     */
    handleTopMenuSelect(key, keyPath) {
      this.activeIndex = key;
    },
  },
};
</script>

<style lang="stylus" scoped>
.wrapper
  margin-top 40px
  position relative

  .section-menu-bar
    border-bottom 1px solid #eee;
</style>
