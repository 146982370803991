<template>
  <div class="ops-wrapper">
    <div class="left-wrapper">
      <div class="ops-box white-box">
        <img src="@/assets/result/index.png" class="ops-box-l"/>
        <div class="ops-box-r">
          <div class="text">调用指标</div>
          <div class="number">{{ reportCover.ruleNumber }}</div>
        </div>
      </div>
      <div class="risk-box white-box">
        <div class="title">风险命中数量</div>
        <SolidPie :data="risksHit"></SolidPie>
      </div>
      <div class="risk-box white-box">
        <div class="title">风险占比</div>
        <SolidRing :data="risksPercentage"></SolidRing>
      </div>
    </div>
    <div class="center-wrapper">
      <div class="value-box white-box">
        <div class="title">综合风险评估</div>
        <div class="text">
          根据所检测到的财税风险点数量对贵公司经营中的涉税风险进行评估，主要检测出以下风险：
        </div>
        <ul class="risk-list">
          <li v-for="(text, key) in reportCover.classInformation" :key="key">
            {{ text }}
          </li>
        </ul>
      </div>

      <Qiu360
        class="qiu360-box white-box"
        :level="reportCover.grade - 1"
      ></Qiu360>
      <div class="summary-box white-box">
        <div class="title">
          <img src="@/assets/result/title_l.png" class="title-l"/>总结<img
          src="@/assets/result/title_r.png"
          class="title-r"
        />
        </div>
        <div class="risk-list-wrapper" v-if="isFromAdmin!=1">
          <ul class="risk-list" :class="{ disableScroll: !isBought }">
            <li v-for="(text, key) in summary" :key="key">
              {{ text }}
            </li>
          </ul>
          <div class="masker" v-if="!isBought">
            <button class="btn btn-primary btn-buy" @click="handleBtnBuy">
              购买付费版
            </button>
          </div>
        </div>
        <div class="risk-list-wrapper" v-else>
          <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="summaryInput"
            show-word-limit
            class="summaryTextarea"
            rows="8"
          >
          </el-input>
          <button class="btn btn-primary btn-summary-click" @click="handleBtnSummaryClick">保存</button>
        </div>
      </div>
    </div>
    <div class="right-wrapper">
      <div
        class="lv-box white-box"
        v-for="(card, index) in reportCover.ruleOfCardResultList"
        :key="index"
      >
        <div class="title">{{ card.modelName }}</div>
        <LvChart class="chart" :node="card.ruleOfCardData"></LvChart>
      </div>
    </div>
    <!--兑换key的对话框-->
    <BuyDialog ref="BuyDialog"></BuyDialog>
    <!--/兑换key的对话框-->
    <!--解锁方式-->
    <BuyApproach ref="BuyApproach" @click="handleBuyApproachClick"></BuyApproach>
    <!--/解锁方式-->

    <!--购买-->
    <pay-toast
      v-if="payToast"
      @close="payToast = false"
      :buyItem="buyItem"
      :orderId="orderId"
      :silent="true"
      @bought="handlePayToastBought"
    ></pay-toast>
    <!--/购买-->
  </div>
</template>

<script>

import {mapState} from "vuex";
import SolidPie from "./SolidPie/SolidPie.vue";
import SolidRing from "./SolidRing/SolidRing.vue";
import Qiu360 from "./Qiu360";
import LvChart from "./LvChart.vue";
import BuyDialog from "./BuyDialog";
import {addSummaryByTag, boGoodsEditionLoadOne, createBuyOrder, unlockReport} from "@/api/api";
import BuyApproach from "@/pages/ResultEditor/components/OpsSection/BuyApproach";
import payToast from "@/pages/product/components/payToast";

export default {
  computed: {
    ...mapState("ResultEditor", ["tag", "maskStatus", "reportCover"]),
    //总结
    summary() {

      const summary = this.reportCover.summary ? this.reportCover.summary.split("\n") : [];


      if (this.isBought) {


        return summary;
      } else {
        return [summary[0]];
      }

    },
    //是否是从管理后台打开的报告
    isFromAdmin() {
      return this.$route.query['fromAdmin'];
    },
    //是否已解锁了
    isBought() {
      //return this.reportCover.unlocking || this.isFromAdmin == 1;
      return true;
    },
    //风险命中数量
    risksHit() {
      const data = [
        {
          name: "高",
          value: this.reportCover.highRisk,
        },
        {
          name: "中",
          value: this.reportCover.middleRisk,
        },
        {
          name: "低",
          value: this.reportCover.lowRisk,
        },
      ];

      return data;
    },
    //风险占比
    risksPercentage() {
      const data = Object.entries(this.reportCover.riskProportion).map(
        ([name, value]) => {
          return {
            value,
            name,
          };
        }
      );

      return data;
    },
  },
  components: {
    payToast,
    SolidPie,
    SolidRing,
    Qiu360,
    LvChart,
    BuyDialog,
    BuyApproach
  },
  data() {
    return {
      summaryInput: "",
      payToast:"",
      orderId:null,
      buyItem:null//1022的产品信息
    };
  },
  mounted() {

    //获取1022的产品信息
    boGoodsEditionLoadOne({
      id:1022
    }).then(res=>{
      if(res.msgCode==1){
        this.buyItem = res.data
      }

    })
    this.summaryInput = this.reportCover.summary;
  },
  methods: {
    //购买了
    handlePayToastBought(){
      this.$msgbox.alert("购买成功,再次点击解锁")
      this.payToast = false;
    },
    //购买
    openBuy() {
      let params = {
        goodsCount: 1,
        goodsId: 1022,
        token: JSON.parse(localStorage.getItem("token")).token,
      };
      createBuyOrder(params).then((res) => {
        if (res.msgCode != 1) return;
        this.orderId = res.data;
        this.payToast = true;
      });
    },
    //解锁方式选择
    handleBuyApproachClick(val) {
      this.$refs["BuyApproach"].dialogVisible = false;
      if (val == 0) {
        //购买
        this.openBuy()
      } else {
        //兑换
        //弹出兑换key的对话框
        this.$refs['BuyDialog'].dialogVisible = true
      }

    },
    //总结保存按钮点击
    handleBtnSummaryClick() {
      //let token = JSON.parse(localStorage.getItem("token")).token;
      addSummaryByTag({
        nsrsbh: this.$route.query.nsrsbh,
        summary: this.summaryInput,
        tag: this.tag,
        //token,
        userId: this.$route.query['adminUserid'],
        uuid: this.$route.params.uuid
      }).then(res => {
        this.$alert(res.msgInfo)
      })
    },
    //处理解锁按钮点击
    handleBtnBuy() {
      let token = JSON.parse(localStorage.getItem("token")).token;
      this.$confirm('此操作将解锁报告, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        //解锁报告
        unlockReport({
          nsrsbh: this.$route.query.nsrsbh,
          uuid: this.$route.params.uuid,
          token,
          tag: this.tag,
        }).then(res => {

          if (res.msgCode == 1) {
            //解锁成功
            location.reload()
          } else {
            //弹出购买方式对话框
            this.$refs["BuyApproach"].dialogVisible = true;

          }

        });
      })

    },
  },
};
</script>

<style lang="stylus" scoped>
.ops-wrapper {
  display: flex;
  margin-bottom: 36px;
  align-items: flex-start;

  .white-box {
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
    background: #fff;
    border-radius: 16px;
    margin-bottom: 16px;
    box-sizing: border-box;
  }

  .white-box:last-child {
    margin-bottom: 0;
  }

  .left-wrapper {
    margin-right: 24px;

    .ops-box {
      display: flex;
      padding: 24px 48px;
      align-items: center;
      flex-basis: 360px;
      flex-shrink: 0;
      width: 360px;

      .ops-box-l {
        width: 80px;
        margin-right: 24px;
      }

      .text {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.6);
        line-height: 21px;
      }

      .number {
        font-size: 40px;
        color: #F7C122;
        line-height: 48px;
      }
    }

    .risk-box {
      padding: 24px;

      .title {
        font-size: 20px;
      }

      .qiu360 {
      }
    }
  }

  .center-wrapper {
    .value-box {
      padding: 24px 40px;

      .title {
        font-size: 32px;
        color: #2B2F58;
        text-align: center;
        margin-bottom: 16px;

        .title-l {
          width: 144px;
          margin-right: 24px;
        }

        .title-r {
          width: 144px;
          margin-left: 24px;
        }
      }

      .risk-list {
        height: 158px;
        padding-left: 0;
        margin-left: 0;
        margin-top: 16px;
        overflow-y: auto;
        margin-bottom: 0;

        li {
          box-sizing: border-box;
          width: 100%;
          list-style: none;
          background: rgba(234, 108, 91, 0.08);
          border-radius: 8px;
          border: 1px solid #EA6C5B;
          color: #EA6C5B;
          line-height: 24px;
          font-size: 16px;
          padding: 8px 16px;
          margin: 8px 0;
        }
      }

      .text {
        font-size: 16px;
        color: #40496A;
        line-height: 1.5;
      }
    }

    .summary-box {
      padding: 24px 40px;
      margin-bottom: 0;

      .title {
        font-size: 32px;
        color: #2B2F58;
        text-align: center;
        margin-bottom: 16px;

        .title-l {
          width: 144px;
          margin-right: 24px;
        }

        .title-r {
          width: 144px;
          margin-left: 24px;
        }
      }

      .risk-list-wrapper {
        position: relative;

        .btn-summary-click {
          margin 0;
          margin-top 25px
          width 100%;


        }

        .risk-list {
          margin-top: 16px;
          height: 220px;
          overflow-y: scroll;
          padding-left: 0;

          li {
            box-sizing: border-box;
            width: 100%;
            list-style: none;
            background: rgba(98, 147, 248, 0.08);
            border-radius: 8px;
            border: 1px solid #6293F8;
            color: #40496A;
            line-height: 24px;
            font-size: 16px;
            padding: 8px 16px;
            margin: 8px 0;
          }
        }

        .masker {
          position: absolute;
          left: 0;
          bottom: 0;
          top: 52px;
          right: 0;
          background url("~@/assets/result/mist.png") 50% 0%;
          background-size 110%;


          .btn-buy {
            position: absolute;
            top: 50%;
            z-index: 2;
            transform: translate(-50%, -50%);
            left: 50%;
          }
        }
      }

      .text {
        font-size: 16px;
        color: #40496A;
        line-height: 1.5;
      }
    }

    align-items: center;
    width: 560px;
  }

  .right-wrapper {
    margin-left: 24px;
    width: 360px;

    .lv-box {
      padding: 24px;

      .chart {
        margin-top: 24px;
      }

      .title {
        font-size: 20px;
      }
    }
  }
}

.disableScroll {
  overflow-y hidden !important
}
</style>
