<template>
  <div class="financialRisk-h5-container">
    <div class="financialRisk-h5">
      <div class="company-baseName">
        <div class="company-tip"></div>
        <div class="company-tip-base">上下游风险企业</div>
      </div>
    </div>
    <div class="PurchaseAndSale-h5">
      <div class="text">
        {{ this.rangeTimes.start }}至{{
          this.rangeTimes.end
        }}，累计交易金额排名前15的上下游客户，如果风险企业不超过15家，以实际数量为准。
      </div>
      <UpDownCustomerNetWork type="2"></UpDownCustomerNetWork>
    </div>
  </div>
</template>

<script>
import "@/pages/Result/ThreeYearResult/global.styl";
import UpDownCustomerNetWork from "@/pages/Result/H5FinancialAnalysis/UpDownCustomerNetWork";
import UpDownCustomerDistribution from "@/pages/Result/H5FinancialAnalysis/UpDownCustomerDistribution";

import { getInvoiceSection } from "@/api/api";

const moment = require("moment");

export default {
  name: "UpDownCustomer",
  components: {
    UpDownCustomerNetWork,
    UpDownCustomerDistribution,
  },

  props: {
    uuid: [String, Number],
  },

  data() {
    return {
      activeIndex: "1",
      rangeTimes: {
        start: "",
        end: "",
      },
    };
  },
  mounted() {
    this.getRange();
  },
  methods: {
    /**
     * 顶部菜单点击
     * @param {string} key
     * @param {array<string>}keyPath
     */
    handleTopMenuSelect(key, keyPath) {
      this.activeIndex = key;
    },
    toCompany() {},
    toCustomer() {},
    getRange() {
      getInvoiceSection(this.uuid).then((res) => {
        if (!res.data) return;
        this.rangeTimes.start = res.data.startDate
          ? moment(res.data.startDate).format("YYYY-MM-DD")
          : "";
        this.rangeTimes.end = res.data.endDate
          ? moment(res.data.endDate).format("YYYY-MM-DD")
          : "";
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.financialRisk-h5-container
  border: 2px solid #6AA1FF;
  border-style: dashed;
  margin: 0.4rem;
  padding: 0.24rem;
  border-radius: 4px;

.financialRisk-h5
  .company-baseName
    display: flex;
    align-items: center;
    color: #1D2129;
    font-size: 0.32rem;
    font-weight: 600;
    line-height: 0.33rem;
    margin-bottom: 0.16rem;

    .company-tip
      width: 0.12rem;
      height: 0.24rem;
      background: #2F54EB;
      margin-right: 0.1rem;

    .company-tip-base
      line-height: 0.33rem;

.PurchaseAndSale-h5 {
//   border: 1px solid #e5e6eb;

  .title {
    font-size: 0.28rem;
    color: #1D2129;
    line-height: 0.3rem;
    margin: 0.24rem 0 0 0.24rem;
    font-weight 600
  }

  .text {
    font-size: 0.28rem;
    color: #4E5969;
    line-height: 1.4;
    margin: 0.16rem 0 0 0.24rem;
    text-align: justify;
  }

  .chaseAndBuy {
    display: flex;
    align-items: center;
    line-height: 0.78rem;
    border-top: 1px solid #E5E6EB;
    color: #1D2129;
    font-size: 0.28rem;
    justify-content: space-between;
    padding: 0 0.24rem;

    .el-icon-arrow-right {
      font-size: 0.32rem;
    }
  }
}
</style>
