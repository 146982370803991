<template>
  <div>
    <div class="name" v-if="isShowName">{{ data.name }}</div>
    <BigPowerTable :data="data.data"></BigPowerTable>
  </div>
</template>

<script>
import { boolean } from 'yargs';

export default {
  name: "TaxCreditLevel",
  props: {
    data: [Object],
    isShowName:{
      default:true,
    }
  },
};
</script>

<style lang="stylus" scoped>
.name
  font-size: 0.28rem;
  color: #1D2129;
  line-height: 0.3rem;
  margin: 0.4rem 0 0.16rem 0.24rem;
  font-weight 600
</style>
