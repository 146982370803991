<template>
  <div class="section-5" v-if="loaded">
    <div class="title-wrapper">
      <div class="title">企业财税分析报告</div>
      <div class="version" v-if="reportInfo">
        {{ reportInfo.reportType }}-{{ reportInfoType }}-{{ reportInfo.zqMc }}
      </div>
      <div class="release-date">报告时间：{{ data.exeDate }}</div>
    </div>
    <div class="company-infos">
      <div class="company-baseName">
        <div class="company-tip"></div>
        <div class="company-tip-base">基本信息</div>
      </div>
      <div class="company-name">
        {{ data.companyName }}
      </div>
      <div class="basic-infos">
        <div class="basic-info-item">
          <div class="label">纳税人识别号：</div>
          <div class="value">{{ data.taxNo }}</div>
        </div>
        <div class="basic-info-item">
          <div class="label">所属行业：</div>
          <div class="value">{{ data.hymc }}</div>
        </div>
        <div class="basic-info-item">
          <div class="label">纳税人资质：</div>
          <div class="value">{{ data.taxpayerQualificationType }}</div>
        </div>
        <div class="basic-info-item">
          <div class="label">参保人数：</div>
          <div class="value">{{ data.socialStaffNum }}</div>
        </div>
        <div class="basic-info-item">
          <div class="label">注册资本：</div>
          <div class="value">{{ data.regCapital }}</div>
        </div>
        <div class="basic-info-item">
          <div class="label">实收资本：</div>
          <div class="value">{{ data.actualCapital }}</div>
        </div>
        <div class="basic-info-item">
          <div class="label">进出口信用：</div>
          <div class="value">{{ data.managementCategory }}</div>
        </div>
        <div class="basic-info-item">
          <div class="label">成立日期：</div>
          <div class="value">{{ data.estiblishTime }}</div>
        </div>
        <div class="basic-info-item">
          <div class="label">核准日期：</div>
          <div class="value">{{ data.approvedTime }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="main section" v-else>
    <BigPowerLoading height="210px"></BigPowerLoading>
  </div>
</template>

<script>
import "@/pages/Result/ThreeYearResult/global.styl";
import { getCompanyInfo, getReportPara } from "@/api/threeYears";

export default {
  name: "Summary",
  data() {
    return {
      data: {},
      loaded: false,
      reportInfo: "",
      reportInfoType: "",
    };
  },
  async mounted() {
    this.loaded = false;
    await this.loadCompanyInfo();
    this.loaded = true;
    this.getgetReportPara();
  },
  methods: {
    async loadCompanyInfo() {
      const res = await getCompanyInfo({
        programId: this.$route.params.uuid,
        desensitizationStatus: this.$route.query.desensitization,
      });
      this.data = res.data;
    },
    getgetReportPara() {
      getReportPara({ programId: this.$route.params.uuid }).then((res) => {
        this.reportInfo = res.data;
        let type = this.reportInfo.zqId.slice(-1);
        if (type == 4) {
          this.reportInfoType = "年度";
        }
        if (type == 2) {
          this.reportInfoType = "季度";
        }
        if (type == 1) {
          this.reportInfoType = "月度";
        }
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.section-5
  margin-top: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  color: #1D2129;

  .title-wrapper
    text-align: center;

    .title
      font-size: 0.36rem;
      padding-top: 0.58rem;
      text-align: center;


    .version
      font-size: 0.28rem;
      line-height: 0.28rem;
      margin-top: 0.24rem;
      text-align: center;


    .release-date
      font-size: 0.24rem;
      line-height: 0.22rem;
      color: #4E5969;
      margin-top: 0.2rem;
      text-align: center;


  .company-infos
    border: 2px solid #6AA1FF;
    border-style: dashed;
    margin: 0.4rem;
    padding: 0.24rem;
    border-radius: 4px;

    .company-baseName
      display: flex;
      align-items: center;
      color: #1D2129;
      font-size: 0.32rem;
      font-weight: 600;
      line-height: 0.33rem;
      margin-bottom: 0.16rem;

      .company-tip
        width: 0.12rem;
        height: 0.24rem;
        background: #2F54EB;
        margin-right: 0.1rem;

      .company-tip-base
        line-height: 0.33rem;

    .company-name
      line-height: 0.28rem;
      color: #1D2129;
      font-size: 0.28rem;
      font-weight: 600;

    .basic-info-item
      display: flex;
      align-items: center;
      margin-top: 0.16rem;
      color: #4E5969;
      line-height: 0.28rem;
      font-size: 0.28rem;
</style>
