import { render, staticRenderFns } from "./WechatBind.vue?vue&type=template&id=10fdd788&scoped=true"
import script from "./WechatBind.vue?vue&type=script&lang=js"
export * from "./WechatBind.vue?vue&type=script&lang=js"
import style0 from "@/styles/index.styl?vue&type=style&index=0&id=10fdd788&prod&lang=stylus&scoped=true&external"
import style1 from "./WechatBind.vue?vue&type=style&index=1&id=10fdd788&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10fdd788",
  null
  
)

export default component.exports