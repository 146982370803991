import {getReportResult, getInvoiceBillData} from "@/api/api";

function initialState() {
  return {
    //测评报告信息
    resultData: {},
    showResultMask: false,
    isFromUpload: false, //是否经由上传流程进入的评测结果页面
    resultPolicy: null, //优惠政策
    invoice: {
      InvoiceText: {},
      InvoiceData: {}
    }, //发票
    resultHeadLegends: {},//可视化报告的图表的legend
  };
}

export const state = {
  ...initialState()
};
import {cloneDeep} from "lodash";

export const mutations = {
  initResultHeadLegends(state, {groupName, size}) {
    const resultHeadLegends = cloneDeep(state.resultHeadLegends)
    if (!resultHeadLegends[groupName]) {
      resultHeadLegends[groupName] = []

      for (let i = 0; i < size; i++) {
        resultHeadLegends[groupName][i] = true
      }

    }
    state.resultHeadLegends = resultHeadLegends;
  },
  toggleResultHeadLegends(state, {groupName, index}) {
    const resultHeadLegends = cloneDeep(state.resultHeadLegends)
    resultHeadLegends[groupName][index] = !resultHeadLegends[groupName][index];
    state.resultHeadLegends = resultHeadLegends;
  },
  setResultHeadLegends(state, {groupName, index, value}) {
    const resultHeadLegends = cloneDeep(state.resultHeadLegends)
    resultHeadLegends[groupName][index] = value;
    state.resultHeadLegends = resultHeadLegends;
  },
  showOnlyResultHeadLegends(state, {groupName, index}) {
    const resultHeadLegends = cloneDeep(state.resultHeadLegends)
    for (const i in resultHeadLegends[groupName]) {
      resultHeadLegends[groupName][i] = false
    }
    resultHeadLegends[groupName][index] = true;
    state.resultHeadLegends = resultHeadLegends;
  },

  setShowResultMask(state, isShow) {
    state.showResultMask = isShow;
  },
  setResultData(state, resultData) {
    state.resultData = resultData;
  },
  setIsFromUpload(state, isFromUpload) {
    state.isFromUpload = isFromUpload;
  },
  setInvoice(state, data) {
    state.invoice = data;
  },
  reset(state) {
    // acquire initial state
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },

  // 优惠政策、排序、点赞
  setPolicy(state, resultData) {
    state.resultPolicy = resultData;
  },
  sortSt(state, index) {
    let item = state.resultPolicy;
    if (index < 90) {
      if (item[index.substring(0, 1)].btnActive == index.slice(-1)) {
        item[index.substring(0, 1)].btnActive = 2;
        item.sort((a, b) => {
          return b.btnActive - a.btnActive;
        });
        return;
      }
      item[index.substring(0, 1)].btnActive = index.slice(-1);
      item.sort((a, b) => {
        return b.btnActive - a.btnActive;
      });
    } else {
      if (item[index.substring(0, 2)].btnActive == index.slice(-1)) {
        item[index.substring(0, 2)].btnActive = 2;
        item.sort((a, b) => {
          return b.btnActive - a.btnActive;
        });
        return;
      }
      item[index.substring(0, 2)].btnActive = index.slice(-1);
      item.sort((a, b) => {
        return b.btnActive - a.btnActive;
      });
    }
  }
};

/**
 * 载入公司认证信息
 * 调用方法:
 * 多次调用只调用一次:await this.$store.cache.dispatch("loadCompanyRenzheng");
 * 删除调用的缓存,使上面的方法可以再次调用 this.$store.cache.delete('loadCompanyRenzheng');
 * 普通调用(多次调用调用多次): await this.$store.dispatch("loadCompanyRenzheng");
 */

export const actions = {
  //载入测评报告信息
  async loadResultData({commit, rootState}, {uuid, bankId, nsrsbh, zq}) {
    const token = rootState.authorization;
    let data;
    try {
      data = await getReportResult(uuid, token, bankId, nsrsbh, zq);
    } catch (error) {
      throw error;
    }

    if (data.msgCode == 1) {
      commit("setResultData", data.data);
    } else {
      // eslint-disable-next-line no-console
      console.error(data);
    }

    // 优惠政策有缓存取缓存
    if (JSON.parse(localStorage.getItem("policy"))) {
      // 取缓存
      commit("setPolicy", JSON.parse(localStorage.getItem("policy")));
    } else {
      // 模拟按钮状态以及点赞总数
      data.data.policyAreaList.forEach(item => {
        item.btnActive = 2;
        item.number = 100;
        item.isTrue = 0;
      });
      commit("setPolicy", data.data.policyAreaList);
    }
  },

  //载入测评发票文本与数据
  async loadInvoice({commit, rootState}, {uuid, bankId, nsrsbh, zq}) {
    try {
      const res = await getInvoiceBillData({nsrsbh, uuid, zq, bankId});
      if (res.msgCode == "1") {
        // this.InvoiceData = res.data.InvoiceData;
        // this.InvoiceText = res.data.InvoiceText;
        commit("setInvoice", res.data);
      } else {
        commit("setInvoice", {
          InvoiceText: {},
          InvoiceData: {}
        });
      }
    } catch (error) {
      throw error;
    }
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
