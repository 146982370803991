<template>
    <div class="testReport">
       <el-tabs v-model="activeName" type="card" @tab-click="handleClick" id="testReport">
            <el-tab-pane label="风险预警概述" name="first">
                <test-risk></test-risk>
            </el-tab-pane>
            <el-tab-pane label="高风险指标" name="second">高风险指标</el-tab-pane>
            <el-tab-pane label="中低风险指标" name="third">角色管理</el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import TestRisk from './components/Risk'
  export default {
      components:{
          TestRisk
      },
    data() {
      return {
        activeName: 'first'
      };
    },
    methods: {
      handleClick(tab, event) {
      }
    }
  };
</script>
<style lang="stylus" scoped>
.testReport{
    #testReport{
        .el-tabs__header{
           border-bottom: none;
           margin: 0;
           background: #0E0F40;
           .el-tabs__nav-wrap{
               .el-tabs__nav-scroll{
                   .el-tabs__nav{
                       border: none;
                       float: right;
                       .el-tabs__item{
                           height: 100px;
                           padding: 0;
                           font-size:20px;
                            font-family:Microsoft YaHei;
                            font-weight:400;
                            color:rgba(125,140,212,1);
                            line-height: 100px;
                            border: none;
                            margin: 0 32px;
                       }
                       .el-tabs__item.is-active{
                           color: #fff;
                           border-bottom: 4px solid #fff;
                       }
                   }
               }
           }
        }
        .el-tabs__content{
           background: #0E0F40;
           color: #fff;
        }
    }
}

</style>
