<template>
  <div v-if="loaded">
    <div class="financialRisk-h5-container">
      <div class="financialRisk-h5">
        <div class="company-baseName">
          <div class="company-tip"></div>
          <div class="company-tip-base">财务分析</div>
        </div>
        <FinanceAnalyze :data="data.data[0].data"></FinanceAnalyze>
      </div>
    </div>

    <div class="financialRisk-h5-container">
      <div class="financialRisk-h5">
        <div class="company-baseName">
          <div class="company-tip"></div>
          <div class="company-tip-base">杜邦分析</div>
        </div>
        <DubanAnalyze :data="data.data[1].data" :zq="companyInfo.zq"></DubanAnalyze>
      </div>
    </div>
  </div>
</template>

<script>
import "@/pages/Result/ThreeYearResult/global.styl";
import PurchaseAndSaleMoney from "@/pages/Result/H5FinancialAnalysis/purchaseAndSale/PurchaseAndSaleMoney";
import { getCompanyInfo, getDupont } from "@/api/threeYears";
import PurchaseAndSaleCategoryCompare from "@/pages/Result/H5FinancialAnalysis/purchaseAndSale/PurchaseAndSaleCategoryCompare";
import FinanceAnalyze from "@/pages/Result/H5FinancialAnalysis/financeAndDuban/FinanceAnalyze";
import DubanAnalyze from "@/pages/Result/H5FinancialAnalysis/financeAndDuban/DubanAnalyze";

export default {
  components: {
    FinanceAnalyze,
    DubanAnalyze,
  },
  name: "FinanceAndDuban",
  data() {
    return {
      activeIndex: "1",
      loaded: false,
      data: null,
      companyInfo: null,
    };
  },
  async mounted() {
    this.loaded = false;
    await this.loadDupont();
    await this.loadCompanyInfo();
    this.loaded = true;
  },
  methods: {
    async loadCompanyInfo() {
      const res = await getCompanyInfo({ programId: this.$route.params.uuid });
      this.companyInfo = res.data;
    },
    async loadDupont() {
      const res = await getDupont({ programId: this.$route.params.uuid });
      this.data = res.data;
    },
    /**
     * 顶部菜单点击
     * @param {string} key
     * @param {array<string>}keyPath
     */
    handleTopMenuSelect(key, keyPath) {
      this.activeIndex = key;
    },
  },
};
</script>

<style lang="stylus" scoped>
.financialRisk-h5-container
  border: 2px solid #6AA1FF;
  border-style: dashed;
  margin: 0.4rem;
  padding: 0.24rem;
  border-radius: 4px;

.financialRisk-h5
  .company-baseName
    display: flex;
    align-items: center;
    color: #1D2129;
    font-size: 0.32rem;
    font-weight: 600;
    line-height: 0.33rem;
    margin-bottom: 0.16rem;

    .company-tip
      width: 0.12rem;
      height: 0.24rem;
      background: #2F54EB;
      margin-right: 0.1rem;

    .company-tip-base
      line-height: 0.33rem;
</style>
