<template>
  <div class="company_msg dali-style" v-loading="showCompany">
    <el-form :model="form" :rules="rules" ref="form" class="usercardx" label-width="158px">
      <div class="usercard-title">企业信息</div>
      <div class="box box-company">
        <div class="item">
          <el-form-item label="企业名称：" prop="companyName">
            <el-input placeholder="请输入" v-model="form.companyName"
                      :disabled="companyInfo.status==1" class="dali-style"></el-input>
          </el-form-item>
        </div>
        <div class="btns">
          <el-button type="text" class="code_btn" @click="checkCompanyClick">
            <i class="el-icon-refresh"></i>切换企业
          </el-button>
          <el-button type="text" class="before" @click="addCompany">
            <i class="el-icon-plus"></i>
            新增企业
          </el-button>
        </div>
      </div>
      <div class="box box-company">
        <el-form-item label="纳税人识别号：" prop="nsrsbh">
          <el-input placeholder="请输入" v-model="form.nsrsbh"
                    :disabled="companyInfo.status==1" class="dali-style"></el-input>
        </el-form-item>
      </div>
      <div class="box">
        <el-form-item label="所属区域："  prop="dq" >
          <el-cascader
            class="dali-style"
            filterable
            :options="areaOptions"
            v-model="form.dq"
          ></el-cascader>
        </el-form-item>
      </div>
      <div class="box">
        <el-form-item label="所属行业：" prop="hy">
          <el-select v-model="form.hy"  placeholder="请选择" filterable class="dali-style">
            <el-option

              v-for="item in hyListData"
              :key="item.hyDm"
              :label="item.hymc"
              :value="item.hyDm"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="boxC boxSave">
        <el-button type="primary" class="save" @click="handleBtnSaveClick"
                  >保存
        </el-button>
      </div>
    </el-form>
    <!-- 切换企业弹框 -->
    <el-dialog :visible.sync="checkCompany" id="auto2dialog" :show-close="true">
      <div class="check_company">切换企业</div>
      <div class="check_companybg test-1">
        <el-radio-group
          v-model="form.companyvalue"
          class="auto_radio"
          v-for="(item, index) in companyList"
          :key="index"
        >
          <el-radio :label="item.companyId">
            <span>{{ item.companyId }}</span>
            {{ item.companyName }}
          </el-radio>
        </el-radio-group>
      </div>
      <div class="autotabbtn">
        <el-button type="primary" @click="changecomp">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCompanyId,
  getbasicinfo,
  saveCompanyInfo,
  changecompanyselect,
  changecompany,
  getTradeList,
  areaList, getCompanyMsgByTaxNo,
} from "@/api/api";
import {regionData} from "@/components/dependencies/element-china-area-data.js";
import Loading from "vue-loading-overlay";
import {cloneDeep} from "lodash";
import {mapState} from "vuex";
import {getLocalAbilities} from "@/utils/abilities";

export default {
  components:{
  },
  data() {
    return {
      hyListData: null,
      hyListDataShow: null,
      city: regionData,
      companyList: [],
      form: {
        companyName: "",
        nsrsbh: "",
        dq: [],
        hy: "",
        companyvalue: "",
        qyStatusValue: "",
      },

      checkCompany: false,
      hasPwd: false,
      FormInfo: {},
      rules: {
        nsrsbh: [
          {
            required: true,
            message: '请输入纳税人识别号',
            trigger: 'blur',
          },
          // {
          //   validator: async (rule, value, callback) => {
          //
          //     if (getLocalAbilities(this.$route.params.version).includes("报税表检测流程") && this.form.nsrsbh == '匿名') {
          //       callback()
          //       return;
          //     }
          //     const res = await getCompanyMsgByTaxNo({
          //       taxNo: this.form.nsrsbh
          //     })
          //     if (res.data && res.data.name) {
          //       this.form.companyName = res.data.name
          //     } else {
          //       this.form.companyName = ""
          //     }
          //
          //     if (res.msgInfo) {
          //       if (res.msgInfo == "成功") {
          //         callback()
          //       } else {
          //         callback(res.msgInfo)
          //       }
          //
          //     }
          //   },
          //   trigger: 'blur'
          // }
        ],

        companyName: [
          {required: true, message: '请输入企业名称', trigger: 'blur'},
        ],
        dq: [
          {required: true, message: '请选择所属地区', trigger: 'blur'}
        ],
        hy: [
          {required: true, message: '请选择所属行业', trigger: 'blur'}
        ],
      },
      hyDm: "",
      res: [],
      showCompany: false,
      areaTree: [],
    };
  },
  mounted() {
    this.basicinfo();
    this.gettradeList();
    this.getAreaList();
  },
  computed: {
    ...mapState(["companyInfo", "authorization"]),
    areaOptions: function () {
      if (!this.areaTree) {
        return [];
      }

      const ret = cloneDeep(this.areaTree);

      function walk(data) {
        data.value = data.code;
        data.label = data.name;

        if (data.childNode && data.childNode.length > 0) {
          data.children = data.childNode;
          for (const child of data.children) {
            walk(child);
          }
        }
        delete data.childNode;
      }

      for (const areaTree of ret) {
        walk(areaTree);
      }

      return [...ret];

      // return [
      //   {
      //     value: -9999999,
      //     label: "地区",
      //     children: [...ret],
      //   },
      // ];
    },
  },
  methods: {
    // 获取地区
    getAreaList() {
      areaList().then((res) => {
        // console.log(res);
        this.areaTree = res.data;
      });
    },
    // 获取行业
    gettradeList() {
      getTradeList().then((res) => {
        if (res.msgCode == 1) {
          let hyList = JSON.parse(res.data);
          let dataList = {};
          for (let i = 0; i < hyList.length; i++) {
            hyList[i].hymc = hyList[i].hyDm + hyList[i].hymc;
            dataList[hyList[i].hyDm] = hyList[i].hymc;
          }
          this.hyListDataShow = dataList;
          this.hyListData = hyList;
        } else {
          this.$message.error(res.msgInfo);
        }
      });
    },
    async deleteCache() {
      this.$store.cache.delete("loadCompanyRenzheng"); //删除认证缓存
      this.$store.cache.delete("loadCompanyInfo"); //删除企业信息
      await this.$store.cache.dispatch("loadCompanyRenzheng");
      await this.$store.cache.dispatch("loadCompanyInfo");
    },
    addCompany() {
      this.$router.push("/companyApply?addnewcompanystatus=true");
    },
    //获取企业相关信息
    basicinfo() {
      this.showCompany = true;
      let token = JSON.parse(localStorage.getItem("token")).token;
      getCompanyId(token).then(async (data) => {
        if (data.msgCode == 1) {
          this.res = data.data;
          localStorage.setItem("hasPwd", data.data.hasPwd);
          this.hasPwd = data.data.hasPwd;
          if (this.res) {
            getbasicinfo(this.res.companyId).then(async (data) => {
              this.showCompany = false;
              //修改localstorage里的user里的内容
              const user = JSON.parse(localStorage.getItem("user"));

              // 这些不知道什么意思,但是功能正常
              user.channalId = data.data.channalId;
              user.channalUserId = data.data.channalUserId;
              user.companyId = data.data.id;
              user.nsrsbm = data.data.nsrsbm;
              localStorage.setItem("user", JSON.stringify(user));
              await this.deleteCache();
              // 这些不知道什么意思,但是功能正常

              this.FormInfo = data.data;
              this.form.companyName = data.data.companyName
              this.form.nsrsbh = data.data.nsrsbh
              this.form.dq = this.FormInfo.dq.split(",");
              this.form.qyStatusValue = this.FormInfo.scale;
              this.form.hy = this.FormInfo.hy;
            });
          } else {
            //console.log("未认证");
          }
        }
        // } else {
        //   this.$router.push("/companyApply");
        //   this.showCompany = false;
        // }
      });
    },
    //企业下拉框
    changecompanyselect() {
      let user = JSON.parse(localStorage.getItem("user")).id;
      let params = {
        id: user,
      };
      changecompanyselect(params).then((res) => {
        if (res.msgCode == "1") {
          this.companyList = res.data;
        }
      });
    },
    //点击切换企业显示弹框
    checkCompanyClick() {
      this.checkCompany = true;
      this.changecompanyselect();
    },
    //确认切换企业
    changecomp() {
      if (!this.form.companyvalue) {
        this.$message.error({
          message: "请选择需要切换的企业",
        });
        return;
      }
      let params = {
        companyId: this.form.companyvalue,
        userId: JSON.parse(localStorage.getItem("user")).id,
      };
      changecompany(params).then(async (res) => {
        if (res.msgCode == "1") {
          this.$message.success({
            message: "切换企业成功",
            duration: 1500,
          });

          this.basicinfo();
          this.FormInfo.companyName = this.companyvalue;
          this.checkCompany = false;
          localStorage.removeItem("taxSiteUsername");
        } else {
          this.$message.success({
            message: res.msgInfo,
            duration: 1500,
          });
          return;
        }
      });
    },
    //保存
    handleBtnSaveClick() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          let token = JSON.parse(localStorage.getItem("token")).token;
          let para = {
            companyId: this.res.companyId,
            companyName:this.form.companyName,
            taxNo:this.form.nsrsbh,
            kjzd: this.form.kjzd,
            hyDm: this.form.hy,
            dq: this.form.dq.toString(),
            userToken: token,
            type: 0,
            scale: 112,
          };
          saveCompanyInfo(para).then((res) => {
            if (res.msgCode == 1) {
              this.$message.success({
                message: "修改成功",
                duration: 1500,
              });
              this.basicinfo();
            } else {
              this.$message.error({
                message: res.msgInfo,
                duration: 1500,
              });
            }
          });
        }
      })

    },
  },
};
</script>
<style src="@/styles/index.styl" lang="stylus" scoped></style>

<style lang="stylus" scoped>
.autotabbtn {
  height: 92px;
  width: 100%;

  .el-button--text {
    color: #007AFF;
  }

  .el-button--primary {
    width: 100%;
    height: 54px;
    font-size: 16px;
    background-color: #2F54EB;
    margin-top: 40px;
    border-radius: 0px 0px 8px 8px;
    border: none;
  }
}

>>> #auto2dialog {
  .el-dialog {
    width: 480px;
    border-radius: 10px;

    .el-dialog__header {
      // display: none;
      border: none;
    }

    .el-dialog__body {
      padding: 0;
    }
  }
}

.company_msg {
  .usercardx {
    border: none;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    padding-bottom 48px

    .usercard-title {
      margin-bottom 48px
      height: 54px;
      line-height: 54px;
      background: rgba(255, 255, 255, 1);
      border-bottom: 1px solid rgba(217, 217, 217, 1);
      border-radius: 8px 8px 0px 0px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
      padding-left: 24px;
    }

    .box {
      display: flex;
      align-items center


    }

    .box.box-company {
      .btns {
        display flex
        position relative
        top -8px
      }
    }

    .boxSave {
      align-items: center;
      margin-top: 16px;
    }

    .radio {
      color: rgba(0, 0, 0, 0.8);

      /deep/ .el-radio__input.is-checked {
        .el-radio__inner {
          border-color: #2f54eb;
          background: #2f54eb;
        }
      }

      /deep/ .el-radio__input.is-checked + .el-radio__label {
        color: #2f54eb;
      }
    }

    /deep/ .el-card__body {
      height: 724px;
      padding: 36px 20px 20px 20px;
    }

    .save {
      height: 36px;
      background: rgba(47, 84, 235, 1);
      border-radius: 4px;
      border-color: rgba(47, 84, 235, 1);
      line-height: 36px;
      margin-left: 140px;
      padding: 0 20px;
    }

    .before {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(47, 84, 235, 1);
      margin-left: 24px;
    }

    .box {
      flex: 1;

      .qyname {
        width: 133px;
        display: inline-block;
        text-align: right;
      }

      .el-icon-refresh {
        margin-left: 16px;
        margin-right: 6px;
      }

      .field:before {
        content: '*';
        color: red;
        position: relative;
        right: 4px;
        top: 1px;
      }

      .savedata {
        width: 400px;
        height: 40px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.4);
        line-height: 20px;
      }

      .name {
        padding: 0 0 0 8px;
        display: inline-block;
      }
    }
  }

  >>> .date_selet_dq .el-input .el-input__suffix {
    top: 5px;

    i:before {
      position: relative;
      top: -4px;
    }
  }

  .check_company {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    height: 92px;
    line-height: 92px;
    margin-top: -30px;
    text-align: center;
  }

  .check_companybg {
    max-height: 450px;
    overflow-y: auto;
  }
}

>>> #partdialog .el-dialog {
  width: 400px;
  height: 304px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

>>> #partdialog .el-dialog__header {
  padding: 0;
}

>>> #partdialog .el-dialog__body {
  padding: 0;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
}

.ease {
  transition: all 0.3s;
}

>>> .el-button--text {
  color: #2F54EB;
}

>>> .el-button {
  padding: 0;
}

>>> .el-input__icon {
  line-height: 36px;
}

.test-1::-webkit-scrollbar {
  /* 滚动条整体样式 */
  width: 10px; /* 高宽分别对应横竖滚动条的尺寸 */
  height: 1px;
}

.test-1::-webkit-scrollbar-thumb {
  /* 滚动条里面小方块 */
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.4);
}

.test-1::-webkit-scrollbar-track {
  /* 滚动条里面轨道 */
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #ededed;
}
</style>
