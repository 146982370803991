<template>
  <div>
    <div>
      <div class="msg-title">
        <bread-crumb>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ name: 'homePage' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>兑换记录</el-breadcrumb-item>
          </el-breadcrumb>
        </bread-crumb>
        <div class="qymc">兑换记录</div>
      </div>
    </div>
    <div class="boxContent">
      <div class="boxCenter">
        <div class="box-title">兑换码</div>
        <div class="box-input">
          <input type="text" placeholder="请输入兑换码" v-model="input" />
          <div class="agent-btn ease" @click="exchanges">兑换</div>
          <div v-show="invalid" class="invalid">{{exchangeInfo}}</div>
        </div>
        <div class="gilf">
          <img class="gilfImg" src="./img/gift.png" alt />
        </div>
      </div>
    </div>
    <div class="record-select">
      <div class="record-time">兑换时间：</div>
      <div class="block">
        <el-date-picker
          v-model="value1"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="timeChange"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </div>
    </div>
    <div class="record-form">
      <el-table
        v-loading="loading"
        :data="tableData"
        element-loading-text="加载中..."
        style="width: 100%"
        :default-sort="{prop: 'date', order: 'descending'}"
      >
        <el-table-column width="24"></el-table-column>
        <el-table-column prop="changeTime" label="兑换时间" sortable :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="version" label="版本" :show-overflow-tooltip="true"></el-table-column>
        <!-- <el-table-column width="180" prop="vtimeCount" label="版本限制条件"></el-table-column> -->
        <!-- <el-table-column prop="riskName" label="风险库" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column width="180" prop="rtimeCount" label="风险库限制条件"></el-table-column>-->
        <el-table-column prop="expireTime" label="到期时间" sortable :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="count" label="兑换次数" width="80" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column width="20"></el-table-column>
      </el-table>
    </div>
    <div class="record-page">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="10"
        layout="prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <dialog-success
      ref="theDialogSuccess"
      :activationData="activationData"
      @clearInput="clearInput"
    ></dialog-success>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import { agentRecord, exchange } from "../../api/api";
import DialogSuccess from "./components/DialogSuccess";
export default {
  components: {
    BreadCrumb,
    DialogSuccess,
  },
  data() {
    return {
      value1: "",
      tableData: [],
      firstTime: "",
      lastTime: "",
      status: "8",
      loading: "true",
      total: 0,
      currentPage: 1,
      pageNo: 0,
      pageSize: 10,
      input: "",
      invalid: false,
      exchangeInfo: "",
      activationData: null,
    };
  },
  created() {
    this.agentrecord();
  },
  methods: {
    clearInput() {
      this.invalid = false;
      this.exchangeInfo = "";
      this.input = "";
      this.agentrecord();
    },

    exchanges() {
      if (!this.input || !this.input.trim()) {
        this.invalid = true;
        this.exchangeInfo = "请输入有效兑换码";
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "正在兑换",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let token = JSON.parse(localStorage.getItem("token")).token;
      exchange(token, this.input).then((res) => {
        loading.close();
        if (res.msgCode != 1) {
          this.exchangeInfo = res.msgInfo;
          this.invalid = true;
        } else {
          this.$refs.theDialogSuccess.dialogVisible = true;
          this.activationData = res;
        }
      });
    },
    handleSizeChange(val) {
      this.pageNo = val - 1;
      this.agentrecord();
    },
    handleCurrentChange(val) {
      this.pageNo = val - 1;
      this.agentrecord();
    },
    // 查看兑换记录
    agentrecord() {
      let params = {
        userId: JSON.parse(localStorage.getItem("token")).userId,
        startTime: this.firstTime,
        endTime: this.lastTime,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      };
      agentRecord(params).then((res) => {
        this.loading = false;
        if (res.msgCode == 1) {
          this.tableData = res.data.content;
          this.total = res.data.totalElements;
        } else {
          this.$message({
            type: "info",
            message: res.msgInfo,
          });
        }
      });
    },
    timeChange(val) {
      if (!val) {
        this.firstTime = "";
        this.lastTime = "";
      } else {
        [this.firstTime, this.lastTime] = [val[0], val[1]];
      }
      this.agentrecord();
    },
  },
};
</script>
<style lang="stylus" scoped>
.submit-title {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.8);
  padding: 8px 0 0 30px;
  font-weight: 500;
}

.record-select {
  display: flex;
  align-items: center;
  // min-width: 1100px;
  height: 84px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 24px 30px 0 30px;
  color: rgba(0, 0, 0, 0.75);
}

>>>.el-select {
  width: 240px;
  height: 36px;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  box-sizing: border-box;
}

>>>.el-input .el-input--suffix {
  width: 240px;
  height: 36px;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  box-sizing: border-box;
}

>>>.el-input__inner {
  height: 36px;
  line-height: 36px;
}

>>>.el-input__icon {
  line-height: 36px;
}

>>>.el-date-editor .el-range-separator {
  width: 8%;
}

>>>.el-input__icon {
  line-height: 28px;
}

>>>.el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
  width: 260px;
}

.record-state {
  margin-left: 40px;
  margin-right: 8px;
}

.record-time {
  margin-left: 48px;
  margin-right: 8px;
}

.record-form {
  position: relative;
  bottom: 20px;
  // min-width: 1100px;
  margin: 36px 30px 0 30px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  overflow: hidden;
}

>>>.el-date-editor .el-range-separator {
  line-height: 28px;
}

>>>.el-date-editor .el-range__close-icon {
  line-height: 30px;
}

.icon {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: rgba(87, 197, 66, 1);
  border-radius: 50%;
  margin-right: 8px;
}

>>>.el-table__row {
  height: 52px !important;
}

>>>.el-table .cell {
  line-height: 1.1;
}

>>>.el-table tr td:last-child {
  margin-left: 50px;
}

.discount-btn {
  cursor: pointer;
  color: rgba(47, 84, 235, 1);
  font-size: 14px;
}

.discount-btn1 {
  margin-left: 12px;
}

.discount-btn2 {
  display: inline-block;
  background: rgba(47, 84, 235, 1);
  border-radius: 4px;
  color: #fff;
  padding: 6px 10px;
}

.record-hyName {
  padding-right: 30px;
}

.record-page {
  float: right;
  padding-bottom: 30px;
  padding-right: 30px;
}

.msg-title {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 0px 0px rgba(238, 238, 238, 1);
}

>>>.bread-crumb-wrapper {
  margin-top: 0;
}

.qymc {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  // font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 28px;
  margin: 8px 0 0 30px;
}

.boxContent {
  position: relative;
  height: 242px;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  margin: 55px 30px 60px 30px;
}

.boxCenter {
  position: absolute;
  width: 522px;
  height: 308px;
  background: rgba(240, 245, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
  border-radius: 26px;
  left: 50%;
  margin-left: -261px;
  top: -36px;
}

.box-title {
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  // font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  margin: 32px 0 0 24px;
}

.agent-btn {
  width: 96px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  color: #fff;
  background: rgba(47, 84, 235, 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
}

.agent-btn:hover {
  background: rgba(47, 54, 235, 1);
}

.ease {
  transition: all 0.3s;
}

input {
  background: none;
  outline: none;
  border: none;
  width: 294px;
  height: 49px;
  background: rgba(255, 255, 255, 1);
  padding-left: 16px;
}

input:focus {
  border: none;
}

input::-webkit-input-placeholder {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.box-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 0 24px;
  margin-top: 38px;
}

.invalid {
  position: absolute;
  top: 62px;
  left: 39px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(245, 34, 45, 1);
}

.gilf {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 49px;
  background: rgba(214, 228, 255, 1);
  margin-top: 78px;
}

.gilfImg {
  width: 40px;
}
</style>