<template>
  <div :class="classObject">
    <div class="header-icon">
      <img src="./image/Bitmap@2x.png" alt />
    </div>
    <div class="header-con">
      <div class="header-title">
        <span class="header-name" v-if="companyInfo.companyName">{{
          companyInfo.companyName
        }}</span>
        <span class="header-name" v-else>未添加企业</span>
        <span
          class="header-check cursion-item"
          @click="checkCompanyClicks"
          v-if="haveAuth"
        >
          <i class="header-icons el-icon-refresh"></i>切换企业
        </span>
        <span class="cursion-item" @click="toSet" v-if="haveAuth">
          <i class="header-icons el-icon-edit-outline"></i>前往编辑
        </span>
        <span class="cursion-item" @click="toAuth" v-if="!haveAuth">
          <i class="header-icons el-icon-circle-plus-outline"></i>新增企业
        </span>
      </div>
      <!-- <div class="header-item">
        <div class="header-items">
          <span>纳税人识别号：</span>
          <span class="info">暂无</span>
        </div>
      </div> -->
      <div class="header-item">
        <div class="header-items">
          <span>所在地区：</span>
          <span class="info" v-if="companyInfo.dq">{{
            companyInfo.dq | capitalize
          }}</span>
          <span class="info" v-else>暂无地区信息</span>
        </div>
      </div>
      <div class="header-item">
        <div class="header-items">
          <span>所属行业：</span>
          <span class="info" v-if="hyMcName">{{ hyMcName }}</span>
          <span class="info" v-else>暂无行业信息</span>
        </div>
      </div>
    </div>
    <div>
      <div class="header-title">
        <span class="header-name">我的检测</span>
      </div>
      <div class="header-wrap">
        <div class="header-items test" v-for="item in countData" :key="item.id">
          <span>{{ item.name }}：</span>
          <span>{{ item.surplusNumber ? item.surplusNumber : 0 }}</span>
        </div>
      </div>
      <span class="tobuy" @click="toBuy">前往购买</span>
    </div>
  </div>
</template>

<script>
let that;
import { regionData } from "element-china-area-data";
import { area } from "./limit.js";
import { getCountGoodsTimes } from "@/api/api";

export default {
  props: {
    score: [Object, Array],
    companyInfo: [Object, Array],
    haveAuth: [Boolean],
    hyMcName: [String],
  },
  data() {
    return {
      city: regionData,
      classObject: {
        container: true,
      },
      countData: null,
    };
  },
  beforeCreate: function () {
    that = this;
  },
  mounted() {
    this.getCount();
  },
  methods: {
    toBuy() {
      this.$router.push({ name: "goods" });
    },
    getCount() {
      let user = JSON.parse(localStorage.getItem("user")).id;
      let params = {
        token: JSON.parse(localStorage.getItem("token")).token,
        userId: user,
      };
      getCountGoodsTimes(params).then((res) => {
        this.countData = res.data.content;
      });
    },
    checkCompanyClicks() {
      this.$emit("checkCompanyClick");
    },
    toSet() {
      this.$router.push({
        name: "setUserCompany",
      });
    },
    toAuth() {
      this.$router.push({ name: "companyApply" });
    },
  },

  filters: {
    capitalize: function (value) {
      if (!value) return "";
      let dq = value.split(",");
      value = area([dq[0], dq[1], dq[2]], that.city);
      return value;
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
};
</script>

<style lang="stylus" scoped>
.container {
  display: flex;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
  padding: 24px 0;
  box-sizing: border-box;
  box-shadow: 0px 1px 0px 0px rgba(238, 238, 238, 1);

  .header-icon {
    width: 72px;
    height: 72px;
    margin: 0 24px 0 30px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .header-title {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(47, 84, 235, 1);
    margin-top: 8px;

    .header-name {
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      line-height: 28px;
      margin-right: 17px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 400px;
      display: inline-block;
    }

    .header-check {
      margin-right: 24px;
    }

    .header-icons {
      margin-right: 4px;
    }
  }

  .header-item {
    display: flex;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.4);
    line-height: 20px;
    margin-top: 8px;

    .header-items {
      display: inline-block;
      width: 354px;
      margin-right: 80px;

      .info {
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }

  .header-wrap {
    display: flex;
    width: 400px;
    flex-wrap: wrap;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.4);
    line-height: 20px;

    .test {
      width: 110px;
      margin-right: 16px;
      margin-top: 8px;
    }
  }

  .tobuy {
    font-size: 14px;
    color: #2F54EB;
    line-height: 20px;
    margin-top: 8px;
    cursor: pointer;
    display: inline-block;
  }
}

.cursion-item {
  cursor: pointer;
}

.header-con {
  margin-right: 30px;
}
</style>