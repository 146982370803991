<template>
  <div class="tools">
    <div class="title">快捷入口</div>
    <div class="load">
      <div class="item" @click="toCompany">
        <i class="icon iconfont iconyichangnashuiqiye"></i>
        <div>异常纳税企业</div>
      </div>
      <div class="item" @click="toCalculator">
        <i class="icon iconfont icongeshuijisuanqi"></i>
        <div>个税计算器</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toCompany() {
      this.$router.push({ name: "abnormal_tax" });
    },
    toCalculator() {
      this.$router.push({ name: "calculator_tax" });
    },
  },
};
</script>

<style lang="stylus" scoped>
.tools {
  height: 121px;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  overflow: hidden;

  .title {
    margin: 24px 0 0 24px;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.8);
    line-height: 25px;
    font-weight: bold;
  }

  .load {
    display: flex;
    margin: 24px 0 0 24px;

    .item {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.8);
      line-height: 20px;
      margin-right: 100px;
      cursor: pointer;

      .iconyichangnashuiqiye {
        margin-right: 11px;
        font-size: 20px;
        color: #FF8383;
      }

      .icongeshuijisuanqi {
        margin-right: 11px;
        font-size: 20px;
        color: #FFCF11;
      }
    }
  }
}
</style>