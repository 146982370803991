<template>
  <div class="company_apply">
    <div class="msg-title">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ name: 'homePage' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>企业信息</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <div class="qymc">企业信息</div>
    </div>
    <div class="setuser">
      <el-card class="usercardx">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px"
                 class="ruleForm">
          <div class="qymc-title">企业信息</div>
          <div class="boxC box1">
            <el-form-item class="box box1" label="企业名称：" prop="name">
              <div class="name">
                <!-- <el-input placeholder="请输入" v-model="ruleForm.name"></el-input> -->
                <el-select
                  ref="optionRef"
                  v-model="comName"
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请输入关键词"
                  @change="optionRefChange"
                  :remote-method="remoteMethod">
                  <el-option
                    v-for="(item, index) in cityOptions"
                    :key="item.creditCode"
                    :label="item.name"
                    :value="index">
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
          </div>
          <div class="boxC">
            <div class="box box1">
              <el-form-item class="box box1" label="纳税人识别号：" prop="nsrsbh">
                <div class="name">
                  <el-input placeholder="请输入" v-model="ruleForm.nsrsbh"></el-input>
                </div>
              </el-form-item>
            </div>
          </div>
          <div class="boxC">
            <div class="box box1">
              <el-form-item class="box box1" label="所属地区：" prop="diqu">
                <div class="name">
                  <el-cascader
                    :options="areaOptions"
                    v-model="ruleForm.diqu"
                    @change="handleChange"
                    filterable
                  ></el-cascader>
                </div>
              </el-form-item>
            </div>
          </div>
          <div class="boxC">
            <div class="box box1">
              <el-form-item class="box box1" label="所属行业：" prop="morehy">
                <div class="name">
                  <el-select v-model="ruleForm.morehy" placeholder="请选择" filterable>
                    <el-option
                      v-for="item in hyListData"
                      :key="item.hyDm"
                      :label="item.hymc"
                      :value="item.hyDm"
                    ></el-option>
                  </el-select>
                </div>
              </el-form-item>
            </div>
          </div>
          <div class="boxC">
            <el-button type="primary" class="save" @click="rzconfirm">保存</el-button>
          </div>
        </el-form>
      </el-card>

    </div>

  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import {
  testaddcompany,
  renzhengApply,
  getCompanyrz,
  getTradeList,
  areaList,
  businessDataFilter
} from "@/api/api";
import {regionData} from "@/components/dependencies/element-china-area-data.js";
import {cloneDeep} from "lodash";

export default {
  components: {
    BreadCrumb,
  },
  props: ["addnewcompanystatus"],
  data() {
    return {
      hyListData: null,
      hyListDataShow: null,
      checkList: [],
      city: regionData,
      areaTree: [],
      ruleForm: {
        name: "",
        nsrsbh: '',
        diqu: '',
        morehy: ''
      },
      rules: {
        name: [
          {required: true, message: '请输入企业名称', trigger: 'blur'},
        ],
        nsrsbh: [
          {required: true, message: '请输入纳税人识别号', trigger: 'blur'}
        ],
        diqu: [
          {required: true, message: '请选择所属地区', trigger: 'blur'}
        ],
        morehy: [
          {required: true, message: '请选择所属行业', trigger: 'blur'}
        ],
      },
      cityOptions: [],
      comName: ""
    };
  },
  computed: {
    areaOptions: function () {
      if (!this.areaTree) {
        return [];
      }

      const ret = cloneDeep(this.areaTree);

      function walk(data) {
        data.value = data.code;
        data.label = data.name;

        if (data.childNode && data.childNode.length > 0) {
          data.children = data.childNode;
          for (const child of data.children) {
            walk(child);
          }
        }
        delete data.childNode;
      }

      for (const areaTree of ret) {
        walk(areaTree);
      }

      return [...ret];

      // return [
      //   {
      //     value: -9999999,
      //     label: "地区",
      //     children: [...ret],
      //   },
      // ];
    },
  },
  mounted() {
    this.gettradeList();
    this.getAreaList();
  },
  methods: {
    optionRefChange() {
      // if(this.comName == "") return;
      this.ruleForm.nsrsbh = this.cityOptions[this.comName].creditCode;
      this.ruleForm.name = this.cityOptions[this.comName].name;
    },
    remoteMethod(query) {
      if(query.length < 2) return;
      businessDataFilter(query).then(res=> {
        this.cityOptions = res.list;
      })
    },
    // 获取地区
    getAreaList() {
      areaList().then((res) => {
        // console.log(res);
        this.areaTree = res.data;
      });
    },
    // 获取行业
    gettradeList() {
      getTradeList().then((res) => {
        if (res.msgCode == 1) {
          let hyList = JSON.parse(res.data);
          let dataList = {};
          for (let i = 0; i < hyList.length; i++) {
            hyList[i].hymc = hyList[i].hyDm + hyList[i].hymc;
            dataList[hyList[i].hyDm] = hyList[i].hymc;
          }
          this.hyListDataShow = dataList;
          this.hyListData = hyList;
        } else {
          this.$message.error(res.msgInfo);
        }
      });
    },
    //省市触发
    handleChange(value) {
      this.province1_code = value[0];
      for (let key in value) {
        for (let k in this.city) {
          if (this.city[k].value == value[key]) {
            this.province1 = this.city[k].value;
          }
          for (let k1 in this.city[k].children) {
            if (this.city[k].children[k1].value == value[key]) {
              this.city1 = this.city[k].children[k1].value;
            }
          }
        }
      }
    },

    rzconfirm() {
      const _this = this;

      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let token = JSON.parse(localStorage.getItem("token")).token;
          let params = {
            type: 0,
            name: this.ruleForm.name,
            dq: this.ruleForm.diqu.toString(),
            hy: this.ruleForm.morehy,
            nsrsbh: this.ruleForm.nsrsbh,
            token: token,
            scale: 112,
          };
          // var aa = [this.province1, this.city1];
          // params.dq = aa.join();
          if (this.$route.query.addnewcompanystatus) {
            testaddcompany(params).then((res) => {
              if (res.msgCode == 1) {
                this.$alert("添加企业成功", "提示", {
                  type: "success",
                }).then(async () => {
                  //修改localstorage里的user里的内容
                  const user = JSON.parse(localStorage.getItem("user"));
                  user.channalId = res.data.channalId;
                  user.channalUserId = res.data.channalUserId;
                  user.companyId = res.data.id;
                  user.nsrsbm = res.data.nsrsbm;
                  localStorage.setItem("user", JSON.stringify(user));

                  this.$store.cache.delete("loadCompanyRenzheng"); //删除认证缓存
                  this.$store.cache.delete("loadCompanyInfo"); //删除企业信息
                  await this.$store.cache.dispatch("loadCompanyRenzheng");
                  await this.$store.cache.dispatch("loadCompanyInfo");

                  //如果有跳转url 则跳转
                  let redirectToURL = _this.$route.query.redirectToURL;
                  console.log(redirectToURL, "redirectToURL");
                  redirectToURL = decodeURIComponent(redirectToURL);
                  if (redirectToURL && redirectToURL != "undefined") {
                    this.$router.push({path: redirectToURL});
                  } else {
                    this.$router.push({path: "/"});
                  }
                });
              } else {
                this.$message.error({
                  message: res.msgInfo,
                  duration: 1000,
                });
              }
            });
          } else {
            //申请认证
            renzhengApply(params).then(async (res) => {
              if (res.msgCode == 1) {
                let params = {
                  id: res.data.id,
                  keyWord: res.data.nsrsbm,
                  userName: this.ruleForm.name,
                  mobile: JSON.parse(localStorage.getItem("user")).mobile,
                };

                //修改localstorage里的user里的内容
                const user = JSON.parse(localStorage.getItem("user"));
                user.channalId = res.data.channalId;
                user.channalUserId = res.data.channalUserId;
                user.companyId = res.data.id;
                user.nsrsbm = res.data.nsrsbm;
                localStorage.setItem("user", JSON.stringify(user));

                //自动审核
                //console.log(params);
                getCompanyrz(params).then(async (data) => {
                  this.$store.cache.delete("loadCompanyRenzheng"); //删除认证缓存
                  this.$store.cache.delete("loadCompanyInfo"); //删除企业信息
                  await this.$store.cache.dispatch("loadCompanyRenzheng");
                  await this.$store.cache.dispatch("loadCompanyInfo");

                  //如果有跳转url 则跳转
                  const redirectURL = _this.$route.query.redirectTo
                    ? decodeURIComponent(_this.$route.query.redirectTo)
                    : null;
                  if (redirectURL && redirectURL != undefined) {
                    this.$router.push({path: redirectURL});
                  } else {
                    //否则跳转
                    let redirectToURL = this.$route.query.redirectToURL;

                    redirectToURL = decodeURIComponent(redirectToURL);
                    if (redirectToURL && redirectToURL != "undefined") {
                      this.$router.push({path: redirectToURL});
                    } else {
                      this.$router.push({path: "/"});
                    }
                  }
                });
              } else {
                this.$message.error({
                  message: res.msgInfo,
                  duration: 1500,
                });
              }
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });


    },
  },
};
</script>
<style src="@/styles/index.styl" lang="stylus" scoped></style>
<style lang="stylus" scoped>
.compant_mdf {
  min-height: 80px;

  .mdf_c1 {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

.top_left {
  margin: 10px 0;
}

.setuser {
  width: 97%;
  margin: 30px;
  margin-top: 16px;
  // padding: 20px;
  box-sizing: border-box;
  color: #062340;

  >>> .date_selet_dq .el-input .el-input__suffix {
    top: 5px;

    i:before {
      position: relative;
      top: -4px;
    }
  }

  .com_input {
    margin-left: 25px !important;
    width: 28% !important;
  }

  .date1 {
    margin-top: 15px;
    width: 100%;
    margin-left: 99px;

    .date_selet {
      width: 28%;
    }

    .company_dq {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(107, 120, 151, 1);
      margin-right: 5px;
    }

    .company_hy {
      margin-right: 10px;
    }
  }

  .savebtn {
    margin: 50px auto 50px auto;
  }
}

.nameleft {
  margin-left: 80px;
}

.nameleftP {
  position: relative;
  left: 42px;
}

.field:before {
  content: '*';
  color: red;
  position: relative;
  right: 4px;
}

.el-icon-refresh {
  margin-right: 6px;
}

.name-logo {
  position: relative;
  top: 5px;
  margin-right: 4px;
}

.name-right {
  margin-right: 9px;
}

>>> #partdialog .el-dialog {
  width: 400px;
  height: 304px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

>>> #partdialog .el-dialog__header {
  padding: 0;
}

>>> #partdialog .el-dialog__body {
  padding: 0;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
}

.ease {
  transition: all 0.3s;
}

.msg-title {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 0px 0px rgba(238, 238, 238, 1);
}

>>> .bread-crumb-wrapper {
  margin-top: 0;
}

.qymc {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  // font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 28px;
  margin: 8px 0 0 30px;
}

.usercardx {
  border: none;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);

  .radio {
    color: rgba(0, 0, 0, 0.8);

    /deep/ .el-radio__input.is-checked {
      .el-radio__inner {
        border-color: #2f54eb;
        background: #2f54eb;
      }
    }

    /deep/ .el-radio__input.is-checked + .el-radio__label {
      color: #2f54eb;
    }
  }

  /deep/ .el-card__body {
    // height: 724px;
    // padding: 36px 20px 20px 20px;
  }

  .save {
    height: 36px;
    background: rgba(47, 84, 235, 1);
    border-radius: 4px;
    border-color: rgba(47, 84, 235, 1);
    line-height: 36px;
    margin-left: 140px;
    margin-top: 10px;
    padding: 0 20px;
    margin-bottom: 24px;
  }

  .before {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(47, 84, 235, 1);
    margin-left: 24px;
  }


  .box {

    .qyname {
      width: 133px;
      display: inline-block;
      text-align: right;
    }

    .savedata {
      width: 400px;
      height: 40px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.4);
      line-height: 20px;
    }

    .name {
      padding: 0 0 0 0px;
      display: inline-block;

      /deep/ .el-input {
        width: 370px;

        .el-input__inner {
          height: 36px;
          line-height: 36px;
          border: 1px solid #d9d9d9;
        }
      }

      .el-cascader {
        height: 36px;
        line-height: 36px;

        /deep/ .el-input {
          width: 370px;

          .el-input__inner {
            height: 36px;
            line-height: 36px;
            border: 1px solid #d9d9d9;
          }
        }
      }

      .el-select {
        /deep/ .el-input {
          width: 370px;

          .el-input__inner {
            height: 36px;
            line-height: 36px;
            border: 1px solid #d9d9d9;
          }
        }
      }
    }
  }
}

>>> .el-card__body {
  padding: 0;
}

.qymc-title {
  height: 54px;
  line-height: 54px;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px 8px 0px 0px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  padding-left: 24px;
  border-bottom: 1px solid rgba(217, 217, 217, 1);
  margin-bottom 30px
}

.box-user {
  margin-top: 10px;
}

.boxC {
  display: flex;
  align-items: center;
}

>>> .el-input__icon {
  line-height: 36px;
}

.box-company {
  margin-bottom: 16px;
}

.boxTotal >>> .el-input-group__append, .el-input-group__prepend {
  padding: 0 13px;
}
</style>
