<template>
  <div>
    <div class="wrapper">
      <draggable
        v-model="currentNode"
        @update="handleDragMoved"
        :disabled="!isEditable"
      >
        <transition-group>
          <div
            v-for="(item, index) in data"
            :key="item.id"
            class="item"
            v-if="item.floor <= 2"
          >
            <div class="title-wrapper">
              <div
                v-if="
                  item.children && item.children.length > 0 && item.isDisplay
                "
                @click="handleBtnArrowCLick($event, orderNumber(index))"
              >
                <template v-if="item.floor <= 1">
                  <div
                    class="el-icon-caret-bottom icon"
                    v-if="!item.hidden"
                  ></div>
                  <div class="el-icon-caret-right icon" v-else></div>
                </template>
              </div>
              <div class="blank-icon" v-else></div>
              <div
                :class="{
                  title1: item.children && item.children.length > 0,
                  removed: !item.isDisplay,
                  isModified: isMenuItemChanged(index),
                  dragable: isEditable,
                }"
                class="title"
                @click="handleScrollTo(item.id)"
              >
                {{ item.orderNumber }} {{ item.titleName }}
              </div>
            </div>

            <MenuTree
              :target="target"
              v-if="item.children && !item.hidden && item.isDisplay"
              :data="item.children"
              :previousOrderNumber="orderNumber(index)"
              :level="level + 1"
            ></MenuTree>
          </div>
        </transition-group>
      </draggable>
    </div>
  </div>
</template>

<script>
import { cloneDeep, throttle, debounce } from "lodash";
import { mapState, mapMutations, mapGetters,mapActions } from "vuex";
import draggable from "vuedraggable";
import mixin from "@/pages/ResultEditor/mixin.js";
export default {
  mixins: [mixin],
  name: "MenuTree",
  components: {
    draggable,
  },
  props: {
    target: String, //判断菜单是显示优惠政策，还是显示综合风险评估
    data: [Object, Array],
    previousOrderNumber: {
      type: String,
      default: "",
    },
    level: {
      //当前是第几层
      type: Number,
      default: 0,
    },
  },
  watch: {},
  methods: {
    ...mapActions("ResultEditor", ["refresh", "submit","reload"]),
    ...mapMutations("ResultEditor", [
      "setTreeDataByIndexes",
      "refineOrder",
      "setTree"
    ]),
    //这个菜单对应的节点是否被修改过(标黄)
    isMenuItemChanged(index) {
      let ret = false;
      let indexes;
      if (this.previousOrderNumber) {
        indexes = this.previousOrderNumber
          .split(".")
          .map((v) => parseInt(v - 1))
          .concat(index);
      } else {
        indexes = [index];
      }

      const node = this.findTheNodeByIndexes(indexes, this.target);

      return this.isInputModified(node) || this.isInputModifiedByMyself(node);
    },
    isInputModified(node) {
      let ret = false;

      if (this.$route.params.action == "edit") {
        ret = node.lastCensorSnapshot
          ? node.lastCensorSnapshot.length > 0
          : false;
      } else if (this.$route.params.action == "censor") {
        ret = node.lastEditSnapshot ? node.lastEditSnapshot.length > 0 : false;
      }
      return ret;
    },
    isInputModifiedByMyself(node) {
      let ret = false;

      if (this.$route.params.action == "edit") {
        ret = node.editSnapshot ? node.editSnapshot.length > 0 : false;
      } else if (this.$route.params.action == "censor") {
        ret = node.censorSnapshot ? node.censorSnapshot.length > 0 : false;
      }
      return ret;
    },
    handleScrollTo(id) {
      id = "#node" + id;
      this.$scrollTo(id, 1000);
    },
    //计算前面的序号
    orderNumber(index) {
      return this.previousOrderNumber
        ? this.previousOrderNumber + "." + (index + 1)
        : (index + 1).toString();
    },
    //左边的小箭头点击折叠
    handleBtnArrowCLick(e, indexes) {
      //获得索引
      indexes = indexes.split(".").map((v) => v - 1);

      //获得当前节点
      this.draggingNode = this.findTheNodeByIndexes(indexes, this.target);

      //根据索引更新数据
      this.setTreeDataByIndexes({
        data: {
          hidden: !this.draggingNode.hidden,
        },
        indexes,
        target: this.target,
        silent: true,
      });
    },

    handleDragMoved(e) {
      const index = e.newIndex;

      const indexes = this.previousOrderNumber
        ? this.previousOrderNumber
            .split(".")
            .map((v) => parseInt(v - 1))
            .concat(index)
        : [index];
      // debugger;
      //标识这个节点位置变过
      this.setTreeDataByIndexes({
        data: {
          updateSort: 1,
        },
        indexes,
        target: this.target,
      });

      this.submit();

      // this.$message({
      //   message: `${this.draggingNode.titleName} 与 ${this.targetNode.titleName} 位置已经交换`,
      //   type: "info"
      // });
    },
  },
  computed: {
    ...mapGetters("ResultEditor", ["findTheNodeByIndexes"]),

    getTitleClass() {
      return "title" + this.level;
    },
    ...mapState("ResultEditor", ["treeData", "discountPolicyTree"]),
    node() {
      return this.findTheNodeByIndexes(this.indexes, this.target);
    },
    currentNode: {
      get() {
        return this.data;
      },
      set(value) {
        let nativeTreeData = cloneDeep(this[this.target]);

        if (this.previousOrderNumber) {
          //如果不是父节点
          let indexes = this.previousOrderNumber.split(".");
          //获得索引
          indexes = indexes.map((v) => v - 1);

          //获得当前节点
          this.targetNode = this.findTheNodeByIndexes(indexes, this.target);

          const children = cloneDeep(value).map((item, index) => {
            item.sort = index;
            return item;
          });

          this.setTreeDataByIndexes({
            data: {
              children,
            },
            indexes,
            target: this.target,
          });
        } else {
          //如果是父节点
          const data = cloneDeep(value).map((item, index) => {
            item.sort = index;
            return item;
          });

          this.setTree({ data, target: this.target });

          // nativeTreeData = cloneDeep(value).map((item, index) => {
          //   item.sort = index;
          //   return item;
          // });
        }

        this.refineOrder({ target: this.target });

        // nativeTreeData = this.refineOrder(nativeTreeData);

        // if (this.target == "discountPolicyTree") {
        //   this.$store.commit("ResultEditor/setDiscountPolicyTree", {
        //     data: nativeTreeData
        //   });
        // } else {
        //   this.$store.commit("ResultEditor/setTreeData", {
        //     data: nativeTreeData
        //   });
        // }
      },
    },
  },
  data: function () {
    return {
      nativeTreeData: [],
      draggingNode: null,
      targetNode: null,
    };
  },
};
</script>

<style lang="stylus" scoped>
.sortable-ghost {
  opacity: 0 !important;
}

.wrapper {
  color: #666;
  line-height: 1.5;
  font-size: 14px;
  display: flex;
  flex-direction: column;

  .item {
    padding: 4px 0;
    padding-left: 16px;

    .title-wrapper {
      display: flex;
      align-items: center;

      .blank-icon {
        padding-right: 0px;
        width: 17px;
      }

      .icon {
        cursor: pointer;
        padding-right: 4px;
        width: 17px;
        box-sizing: border-box;
      }

      .title {
        cursor: pointer;
      }

      .title.dragable {
        cursor: grab;
      }

      .title:hover {
        color: #2F54EB;
      }

      .title0 {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 600;
      }

      .title1 {
        color: rgba(0, 0, 0, 0.8);
        font-weight: 600;
      }

      .removed {
        text-decoration: line-through;
        color: #999;
      }

      .isModified {
        color: rgba(250, 173, 20, 1) !important;
      }
    }
  }
}
</style>