<template>
  <div class="risk_left">
    <div class="fxfz">
      <div class="title">风险分值</div>
      <div class="fz_t">
        <img
          :src="'https://node.ai-tax.cn/riskoverall?format=png&progress='+resultData.finalScore+'&textcolor=fff&scale=1'"
          class="ybp_t"
          alt
        />
      </div>
      <div class="fxgs">
        <div class="title1">分析</div>
        <!-- <div v-if="fxflCountSorted.length!=0" class="jielun1 jielun3">
              <b>根据您上传数据分析得出：</b>
        </div>-->
        <div v-for="(grp,key) in fxflCountSorted" :key="key">
          <div class="jielun1">
            <span v-if="grp[0]">
              {{grp[0].key}}
              <b>{{grp[0].v}}</b>个,
            </span>
            <span v-if="grp[1]">
              {{grp[1].key}}
              <b>{{grp[1].v}}</b>个,
            </span>
          </div>
        </div>
        <div class="jielun1 jielun2">
          <b>结论</b>
        </div>
        <div class="jielun jielun1">
          根据您提供的数据进行分析检测后，当前贵公司涉税风险处于
          <b>{{resultData.fxDesc}}</b> 状态
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("Result", ["resultData"]),
    fxflCountSorted() {
      let ret = [];
      if (this.resultData.fxflCount) {
        Object.entries(this.resultData.fxflCount).forEach(([key, v], k) => {
          const theKey = Math.floor(k / 2);
          if (k % 2 === 0) {
            ret[theKey] = [{ key, v }];
          } else {
            ret[theKey].push({ key, v });
          }
        });
      }

      return ret;
    }
  }
};
</script>

<style lang="stylus" scoped>
.risk_left {
  width: 100%;
  min-width: 750px;
  margin: 0px 0px 16px 0px;
  position: relative;

  .fxfz {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    // height: 294px;
    // padding-top:24px;
    // background-size: 100% 100%;
    // background-color : #6261F2;
    // box-shadow:0px 4px 8px 0px rgba(0,0,0,0.04);
    // border-radius:8px;
    border-radius: 8px;
    height: 318px;
    background-color: #2F54EB;
    background-position: center;
    background-image: url('../../../assets/result/riskgaybj.png');
    background-size: 100% 100%;

    .title {
      font-size: 18px;
      position: absolute;
      color: #fff;
      line-height: 25px;
      margin-left: 24px;
      margin-top: 29px;
    }

    .fz_t {
      text-align: center;
      margin: 30px 50px 40px;
      flex-basis: 550px;

      .ybp_t {
        width: 306px;
      }

      .number_t {
        font-size: 40px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #fff;
        margin-top: -180px;

        span {
          display: block;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 600;
          color: #fff;
          margin-top: 10px;
        }
      }
    }

    .fxgs {
      width: 39%;
      margin-top: 74px;

      .title1 {
        font-size: 14px;
        color: #fff;
        line-height: 22px;
        font-weight: 600;
      }

      .jielun {
        line-height: 1.2;
      }

      .jielun2 {
        margin-top: 44px;
        opacity: 1;

        b {
          font-size: 14px !important;
        }
      }

      .jielun3 {
        margin-bottom: 14px;
      }

      .jielun1 {
        font-size: 14px;
        line-height: 20px;
        color: rgba(255, 255, 255, 0.8);
        font-weight: 400;

        b {
          // font-size: 22px;
          font-weight: bold;
          color: #fff;
        }
      }
    }
  }

  .risk_left_b {
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 30px;

    .top_title {
      height: 84px;
      line-height: 84px;

      .top_left {
        font-size: 18px;
        color: #062340;
        display: inline-block;
      }
    }
  }
}
</style>