<template>
  <div class="result-wrapper">
    <div v-if="$route.query.show=='all'">
      <AwaitButton class="btn btn-primary" style="position: fixed;right: 0">重新生成PDF</AwaitButton>
      <AwaitButton class="btn btn-default" style="position: fixed;right: 0px;top: 50px">直接下载PDF</AwaitButton>
    </div>

    <div class="result">
      <div v-if="dataLoaded">
        <FirstPage v-if="$route.query.show=='cover'||$route.query.show=='all'"></FirstPage>
        <TableOfContent v-if="$route.query.show=='toc'||$route.query.show=='all'"></TableOfContent>
        <template v-if="$route.query.show=='main'||$route.query.show=='all'">
          <div class="box-wrapper">
            <div class="title">
              <img
                src="@/assets/result/title_l.png"
                class="title-l"
              />　综合风险评估　<img
              src="@/assets/result/title_r.png"
              class="title-r"
            />
            </div>
            <TaxSummary
            ></TaxSummary>
          </div>
          <template v-for="(aTreeData, index) in this.treeData">
            <div :key="index" class="box-wrapper">
              <div class="title" :class="{displayNone: aTreeData.titleName==''}">
                <img src="@/assets/result/title_l.png" class="title-l"/>　{{aTreeData.orderNumber}}. {{
                  aTreeData.titleName
                }}　<img src="@/assets/result/title_r.png" class="title-r"/>
              </div>
              <TabContent
                :key="`a_${index}_${aTreeData.titleName}`"
                :node="aTreeData"
              ></TabContent>


            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";
import TabContent from "./components/TabContent";
import TaxSummary from "./components/TaxSummary";
import FirstPage from "./components/components/FirstPage";
import TableOfContent from "./components/TableOfContent";
import AwaitButton from "await-button";
import PdfDetailResult from "@/pages/pdfFrontGenerator/PdfDetailResult";


export default {
  name: "PdfResult",
  components: {
    TableOfContent,
    TabContent,
    FirstPage,
    TaxSummary,
    AwaitButton
  },
  data() {
    return {
      resultTabName: "综合风险评估",
    };
  },
  props: {
    uuid: String,
  },
  beforeCreate() {

  },
  async mounted() {
    await this.loadData();
    setTimeout(()=>{
      window.tableOfContent = this.getTableOfContent();
      window.renderFinished = true
    },1000)

  },
  activated() {
    const anchor = this.$route.query.anchor
    this.$scrollTo(document.querySelector("#node" + anchor), 1000);
  },
  computed: {
    ...mapState(["companyInfo", "authorization"]),
    ...mapState("ResultEditor", [
      "tag",
      "maskStatus",
      "treeData",
      "discountPolicyTree",
      "downloadUrl"
    ]),

    dataLoaded() {
      //加载完成
      return Object.keys(this.treeData).length > 0;
    },
  },
  methods: {
    //为PDF准备树状标题
    getTableOfContent(){
      const ret = [];

      /**
       * 递归整理目录中的单次处理
       * @param data 要整理的数据的当前节点
       * @param tmp 整理后数据的当前节点
       */
      function walk(data,level) {
        const node = {}
        node.level = level;
        node.title = data.orderNumber+'. '+data.titleName
        node.pageNo = "";
        ret.push(node)


        if (data.children && data.children.length > 0) {
          level++
          for (const i in data.children) {
            const child = data.children[i]
            walk(child,level);
          }
        }
      }

      for (const i in this.treeData) {
        const child = this.treeData[i]
        walk(child,0);
      }
      return ret;
    },
    handleDialogApply() {
      this.$refs["contactDialog"].dialogVisible = true;
    },

    //初始化加载数据
    async loadData() {
      await this.$store.dispatch("ResultEditor/loadPDFEditorData", {
        nsrsbh: this.$route.params.nsrsbh,
        uuid: this.$route.params.uuid,
        action: this.$route.params.action,
      });
    },

  },

};
</script>
<style lang="stylus" scoped>
@import '~@/styles/result.styl';

.result-wrapper {
  background #fff;
  background-size: 100%;
  overflow-y: hidden;
}

>>>.chart-wrapper,>>>canvas{
  display block;
  page-break-inside:avoid;
}

.result-wrapper-color {
  background-image: url('~@/assets/result/bg.png');
  background-color: #2F54EB;
}

.box-wrapper {
  background: #fff;
  border-radius: 16px;
  margin-bottom: 32px;
  overflow: hidden;
  page-break-inside:avoid;

  .title {
    font-size: 32px;
    text-align: center;
    color: #030852;
    line-height: 42px;
    margin: 40px 0 32px;

    .title-l {
      width: 144px;
      margin-right: 24px;
    }

    .title-r {
      width: 144px;
      margin-left: 24px;
    }
  }
}

.floatSlogan {
  position: fixed;
  bottom: 70px;
  width: 308px;
  right: 28px;
  cursor: pointer;
}

.result {
  width: 790px;
  margin: 0px auto 0;

  >>> .bread-crumb-wrapper {
    padding-left: 0;
  }
}

>>> .el-tabs__header {
  border-bottom: none;
  margin: 24px 0 16px;

  .el-tabs__nav-scroll {
  }

  .el-tabs__item {
    border: 0 none;
    line-height: 36px;
    height: 36px;
    margin-right: 16px;
  }

  .el-tabs__item.is-active {
    background: rgba(47, 84, 235, 1);
    border-radius: 18px;
    color: #fff;
  }

  .el-tabs__nav {
    border: 0 none;
  }
}

>>> .bread-crumb-wrapper {
  margin-top: 0;
}

.page-title-wrapper {
  padding: 0;
  padding-left: 30px;
  line-height: 28px;
}

.qymc {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  // font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 28px;
  margin: 8px 0 0 30px;
}

.qymc-title {
  display: flex;
}

.qyImg {
  width: 40px;
  height: 40px;
  margin-left: 24px;
}

.btn-download {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #2F54EB;
  cursor: pointer;
}

.floatDownloadPdf {
  width 84px;
  position fixed;
  right 57px;
  top 100px
  cursor pointer
}
</style>
