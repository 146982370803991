<template>
  <div>
    <div>
      <!-- <el-radio-group v-model="activeIndex" @change="change">
        <el-radio-button
          :value="index"
          :label="index"
          v-for="(item, index) in data"
          :key="index"
          >{{ item.name }}
        </el-radio-button>
      </el-radio-group> -->
      <!-- <button class="btn-link btn-fullscreen" @click="handleFullscreen">全屏</button> -->
      <div class="yearsOption">
        <div
          class="yearsOption-items"
          :class="{ 'yearsOption-items-active': activeIndex == index }"
          v-for="(item, index) in data"
          @click="changeYearsOption(index)"
        >
          <div>{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="svg-container">
      <DubunSvg
        class="svg"
        v-if="data[activeIndex]"
        :data="data[activeIndex].data"
      ></DubunSvg>
      <div class="sc" @click="switchScreen" v-if="data[activeIndex]">
        <img src="@/assets/util/hScreen.png" alt="" />
      </div>
    </div>

    <el-dialog
      :visible.sync="fullScreenDialogVisible"
      :fullscreen="true"
      :modal="false"
      title="杜邦分析"
    >
      <div class="svg-wrapper">
        <DubunSvg
          class="svg"
          v-if="data[activeIndex]"
          :data="data[activeIndex].data"
        ></DubunSvg>
      </div>
    </el-dialog>
    <div class="full-sc">
      <el-drawer :visible.sync="horizontalScreen" direction="btt" size="100%">
        <div class="full-tab">
          <div class="table-wrapper">
            <DubunSvgs
              class="svg"
              v-if="data[activeIndex]"
              :data="data[activeIndex].data"
            ></DubunSvgs>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import DubunSvg from "@/pages/Result/H5FinancialAnalysis/financeAndDuban/DubunSvg";
import DubunSvgs from "@/pages/Result/H5FinancialAnalysis/components/DubunSvg";

export default {
  components: {
    DubunSvg,
    DubunSvgs,
  },
  name: "DubanAnalyze",
  props: {
    zq: String,
    data: Array,
  },
  async mounted() {
    this.activeIndex = 0;
  },
  data() {
    return {
      activeIndex: "",
      fullScreenDialogVisible: false,
      horizontalScreen: false,
    };
  },
  computed: {
    theYear() {
      return parseInt(this.zq.replace("004"));
    },
    /**
     * 可选的年份
     * @returns {(number|number)[]}
     */
    yearsOption() {
      const zq = parseInt(this.zq.replace("004"));
      const ret = [zq, zq - 1, zq - 2];
      return ret;
    },
  },
  methods: {
    changeYearsOption(val) {
      this.activeIndex = val;
    },
    switchScreen() {
      this.$router.push({path: "../dubangHp/" + this.$route.params.uuid});
    },
    change() {},
    handleFullscreen() {
      this.fullScreenDialogVisible = true;
    },
  },
};
</script>

<style lang="stylus" scoped>
.toolbar
  margin-top 24px
  display flex
  justify-content space-between
  align-items center
  .btn-fullscreen {
    //position absolute
    //right 0;
    //top 0

  }

>>> .el-radio-button {
  margin-right 10px

  .el-radio-button__inner {
    border-radius: 2px;
    padding 6px 16px
    border 0 none;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #86909C;
    box-shadow none;
  }
}

>>> .el-radio-button.is-active {
  .el-radio-button__inner {
    background: rgba(22, 93, 255, 0.1);
    color: #165DFF;
    font-weight bold
  }
}

.svg-wrapper {
  display flex
  align-items center
  justify-content center
}

.svg{
  margin-top 21px
  overflow: hidden
}

.toolbar-h5
  >>> .el-radio-button .el-radio-button__inner
    width: 0.97rem;
    height: 0.4rem;
    line-height: 0.4rem;
    border-radius: 2px;
    font-size: 0.24rem;
  >>> .el-radio-button__inner, .el-radio-group {
    display: inline;
  }

.svg-container {
  position: relative;

  .sc {
    position: absolute;
    right: 0px;
    bottom: 0px;
    padding: 5px;

    img {
      width: 0.25rem;
      height: 0.25rem;
      background: #FFFFFF;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
      border-radius: 0.08rem;
    }
  }
}

.full-sc {
  .full-tab {

    .svg {
      max-height: 5.04rem;
      max-width: 85vh;
      overflow auto
      margin-top: 0;
    }

    // .h5-svg {
    //   width: 16rem;
    //   max-height: 6rem;
    // }

    .t-w {
      display: flex;
      align-items: center;
    }

    transform-origin: center center;
    transform: rotateZ(90deg);

    .titleName {
      font-size: 0.28rem;
      font-weight: 600;
      color: #2B2F58;
      line-height: 0.36rem;
    }

    .dataText {
      font-size: 0.28rem;
      color: #40496A;
      line-height: 0.36rem;
      margin: 12px 0;
    }
  }
  >>> .el-drawer__header {
    padding: 0;
  }
  >>> .el-drawer__body {
    overflow: hidden;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  >>> .el-drawer__open .el-drawer.btt {
    border-radius: 0;
  }

  >>> .el-drawer__close-btn {
    font-size: 30px;
    position: relative;
    right: 10px;
    top: 10px;
  }

  // display: flex;
  // align-items: center;
  // justify-content: center;
  // flex-direction: column;

  .tab-s {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // flex-direction: column;
    // height: 100%;

    max-height: 220px;
    max-width: 500px;
    overflow: auto;

    .tablefirst {
      width: 100%;
      height: 100%;
      overflow: auto;
    }

    .tablefirst tr:nth-child(2n-1) td {
      background: #fff;
    }

    .tablefirst thead tr > th:first-child,
    .tablefirst tbody tr > td:first-child {
      position: -webkit-sticky;
      position: sticky;
      left: 0;
    }

    .table-screen-b {
      td, th {
        min-width: 80px;
        // max-width: 200px;
      }
    }
  }

  .table {
    >>> .bigPowerTable {
      max-height 5.04rem
      max-width 85vh
      overflow auto
    }

    >>> table.table th, >>> table.table td {
      font-size: 0.2rem;
    }
  }
}

.yearsOption {
  display: flex;
  align-items: center;

  .yearsOption-items {
    width: 1.1rem;
    height: 0.49rem;
    line-height: 0.49rem;
    background: #F7F8FA;
    border-radius: 2px;
    text-align: center;
    font-size: 0.24rem;
    font-weight: 600;
    color: #4E5969;
    margin-right: 0.24rem;
  }

  .yearsOption-items-active {
    color: #165DFF;
    background: rgba(22,93,255,0.1);
  }
}
</style>
