<template>
  <div>
    <RiskOverallBanner v-if="treeData.tag == 3"></RiskOverallBanner>
    <div class="box-wrapper">
      <!-- <div class="h1">
        <template v-if="treeData.tag==3">根据贵公司提供及授权的相关财税数据，经过税安科技的</template>
        <template v-else>根据贵公司提供及授权的发票及相关财税数据，经过税安科技的</template>
        <strong>{{bankName}}</strong>
        相关模型进行运算检测后，得出综合风险检测结果。
      </div> -->
      <div class="list" v-if="$route.query['fromAdmin'] != 1">
        <div class="item" v-html="summaryText"></div>
      </div>
      <div class="list" v-else>
        <el-input
          type="textarea"
          placeholder="请输入内容"
          v-model="summaryTextInput"
          show-word-limit
          class="summaryTextarea"
          rows="8"
        >
        </el-input>
        <div class="btn-summary-click-wrapper">
          <button
            class="btn btn-primary btn-summary-click"
            @click="handleBtnSummaryClick"
          >
            保存
          </button>
        </div>
      </div>
      <!-- <div class="h1">
        <template v-if="treeData.tag==3">综上所述，贵公司近2年财税存在重大风险，需要额外注意。</template>
        <template v-else>综上所述，贵公司近2年财税及发票方面存在重大风险，需要额外注意。</template>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import RiskOverallBanner from "./RiskOverallBanner";
import { addSummaryByTag, updateZS } from "@/api/api";
export default {
  props: ["bankName"],
  components: {
    RiskOverallBanner,
  },
  computed: {
    ...mapState("ResultEditor", ["overall", "treeData"]),
    summaryText() {
      return this.overall.pdfData && this.overall.pdfData.replace(/\n/g, "<br/>");
    },
  },
  data() {
    return {
      summaryTextInput: "",
    };
  },
  mounted() {
    this.summaryTextInput = this.overall.pdfData;
  },
  methods: {
    switchTab(tabName) {
      this.$emit("switchTab", tabName);
    },
    getLabel(typeId) {
      switch (typeId) {
        case "RST_1":
        case "RST_2":
        case "RST_3":
        case "RST_4":
          return "增值税专用发票风险评估";
          break;
        case "SWFXPG":
          return "税务风险评估";
          break;
        case "CWFXPG":
        case "CWFXPG1":
          return "财务风险评估";
          break;
        case "AREA_TEXT":
          return "优惠政策提醒";
          break;
        default:
          return "";
      }
    },
    handleBtnSummaryClick() {
      const { contrastKey, dataText, id, orderNumber, sort, titleName } = this.overall;

      updateZS({
        contrastKey,
        dataText,
        id,
        orderNumber,
        pdfData: this.summaryTextInput,
        sort,
        titleName,
      }).then((res) => {
        this.$alert(res.msgInfo);
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.btn-summary-click-wrapper{
  text-align right;
  .btn-summary-click{
    margin-top 15px;
  }
}

.box-wrapper {

  border-radius: 8px;
  padding:0  24px 0;
  padding-bottom: 50px;
  background: #fff;
  margin-bottom: 0px;

  .h1 {
    color: #666;
    font-size: 18px;
    line-height: 1.5;
    font-weight: bold;
  }

  strong {
    color: #2F54EB;
  }

  .list {
    // margin: 30px 0 30px;

    .item {
      color: #333;
      background: rgba(47, 84, 235, 0.04);
      padding: 16px;
      font-size: 14px;
      line-height: 2;
      margin: 8px 0;

      .link {
        color: #2F54EB;
        cursor: pointer;
      }

      .link:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
