<template>
  <div class="wrapper">
    <el-popover placement="bottom" trigger="click" ref="popover">
      <div class="el-picker-panel__body">
        <!---->
        <div class="el-date-picker__header el-date-picker__header--bordered">
          <button
            type="button"
            aria-label="前一年"
            class="el-picker-panel__icon-btn el-date-picker__prev-btn el-icon-d-arrow-left"
            @click="nextYear()"
            :disabled="!showPreBtn"
          ></button>
          <span role="button" class="el-date-picker__header-label"
            >{{ PickerDialog.year }} 年</span
          >
          <button
            type="button"
            aria-label="后一年"
            class="el-picker-panel__icon-btn el-date-picker__next-btn el-icon-d-arrow-right"
            :disabled="!showNextBtn"
            @click="prevYear()"
          ></button>
        </div>
        <div class="el-picker-panel__content">
          <table class="el-month-table" style>
            <tbody>
              <tr>
                <td
                  :class="{ today: isToday(index) }"
                  v-for="(label, index) in quanterList"
                  :key="label"
                >
                  <div>
                    <a class="cell" @click="handleLabelClick(index)">{{ label }}</a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <el-input
        placeholder="请选择季度"
        prefix-icon="el-icon-date"
        :value="inputValue"
        slot="reference"
      ></el-input>
    </el-popover>
  </div>
</template>

<script>
export default {
  mounted() {
    const currentDate = new Date();
    if (this.value.year) {
      this.PickerDialog.year = this.value.year;
      this.PickerDialog.quanter = this.value.quanter;
    } else {
      this.PickerDialog.year = currentDate.getFullYear();
      this.PickerDialog.quanter = Math.ceil((currentDate.getMonth() + 1) / 4);
    }

    this.maxYear = currentDate.getFullYear();
    if ((this.maxYear = 2023)) {
      this.quanterList = ["一季度", "二季度"];
    }
  },
  computed: {
    //input框里显示用的值
    inputValue() {
      if (this.value.year && this.value.quanter) {
        return this.value.year + "年第" + this.value.quanter + "季度";
      } else {
        return null;
      }
    },
    showPreBtn() {
      return this.PickerDialog.year > this.minYear;
    },
    showNextBtn() {
      return this.PickerDialog.year < this.maxYear;
    },
  },
  props: {
    value: Object,
  },
  data() {
    return {
      PickerDialog: {
        year: null,
        quanter: null,
      },
      quanterList: ["一季度", "二季度", "三季度", "四季度"],
      minYear: 2018,
      maxYear: -1,
    };
  },
  methods: {
    nextYear() {
      this.PickerDialog.year--;
      if (this.PickerDialog.year == 2023) {
        this.quanterList = ["一季度", "二季度"];
      } else {
        this.quanterList = ["一季度", "二季度", "三季度", "四季度"];
      }
    },
    prevYear() {
      this.PickerDialog.year++;
      if (this.PickerDialog.year == 2023) {
        this.quanterList = ["一季度", "二季度"];
      } else {
        this.quanterList = ["一季度", "二季度", "三季度", "四季度"];
      }
    },
    isToday(index) {
      return this.year === this.PickerDialog.year && this.quanter === index + 1;
    },
    handleLabelClick(index) {
      this.PickerDialog.quanter = ++index;
      this.$emit("input", {
        year: this.PickerDialog.year,
        quanter: this.PickerDialog.quanter,
      });
      this.$refs["popover"].doClose();
    },
  },
};
</script>

<style lang="stylus" scoped>
.wrapper {
  display: inline-block;
}

>>> .el-popover--plain {
  padding: 0 !important;
}

.el-month-table td .cell {
  font-size: 14px;
}

.el-date-picker__header {
  margin: 5px 0;
}

.el-picker-panel__content {
  margin: 15px 0;
}

>>> .el-month-table .today .cell {
  color: #409eff;
  font-weight: 700;
}
</style>
