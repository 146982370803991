<template>
  <div>
    <button class="btn btn-order" @click="payment">查看更多财务分析内容</button>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";

export default {
  name: "BtnOrder",

  data() {
    return {
      res: "",
    };
  },
  mounted() {
    // console.log(this.isNotFiexd);
  },
  methods: {
    payment() {
      wx.miniProgram.switchTab({
        url: "/pages/buy/buy",
        success: (res) => {
          this.res = res;
        },
        fail: (err) => {},
      });
      // this.$router.push("/");
    },
  },
};
</script>
<style lang="stylus" scoped>
.btn-order {
  //width: 200px;
  width: 100%;
  height: 34px;
  background: #FFA616;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 18px;
  display block
  //margin 44px auto 34px;
  position: fixed;
  bottom 0px;
  margin-top 20px;
  z-index: 999
}

@media screen and (min-width:500px) and (max-width: 2000px){

  .btn-order {
    width: 375px;
    height: 34px;
    background: #FFA616;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 18px;
    display block
    position: fixed;
    margin 0 auto
    bottom 0px;
    top 673px
    z-index: 999
  }
}
</style>
