<template>
  <div>
    <div class="msg-title">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ name: 'homePage' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>企业信息</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <div class="qymc">企业信息</div>
    </div>
    <company-msg class="company-msg"></company-msg>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import SaveDate from "./components/Savedate";
import MdfPassword from "./components/mdfPassword";
import SafePhone from "./components/safePhone";
import companyMsg from "./components/companyMsgc";
import modifyPhone from "./components/modifyPhone";
import WechatBind from "./components/WechatBind/WechatBind";
import {getCompanyId} from "@/api/api";

export default {
  components: {
    BreadCrumb,
    SaveDate,
    MdfPassword,
    SafePhone,
    companyMsg,
    modifyPhone,
    WechatBind,
  },
  data() {
    return {
      activeName: "first",
      hasPwd: false,
      isShow: true,
    };
  },
  mounted() {
    this.hasPwd = localStorage.getItem("hasPwd");
    const bindwx = this.$route.query.bindwx;
    if (bindwx) {
      this.activeName = "fourth";
    }
    this.getcompanyId();
  },
  methods: {
    handleClick(tab, event) {
      //console.log(tab, event);
    },
    magphone(e) {
      this.isShow = false;
    },

    getcompanyId() {
      getCompanyId(this.$store.state.authorization).then((res) => {
        if (res.msgCode != 1) {
          this.$router.push({
            name: "companyApply", query: {
              addnewcompanystatus: true
            }
          });
          // this.$confirm("请先完善企业信息", "提示", {
          //   confirmButtonText: "完善信息",
          //   cancelButtonText: "取消",
          //   type: "warning",
          // })
          //   .then(() => {
          //     this.$router.push({ name: "companyApply" });
          //   })
          //   .catch(() => {
          //     this.$router.push({ name: "homePage" });
          //   });
        }
      });
    },
  },
};
</script>
<style src="@/styles/index.styl" lang="stylus" scoped></style>
<style lang="stylus" scoped>
.qymc {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  // font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 28px;
  margin: 8px 0 0 30px;
}

>>> .setuser {
  margin: 0px auto 30px auto;
  padding: 10px;
  box-sizing: border-box;
  width: 96%;

  .code_btn {
    margin-left: 20px;

    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(47, 84, 235, 1);
    }
  }

  .el-tabs .el-tabs__header {
    margin: 0;

    .el-tabs__nav-wrap::after {
      background-color: none;
      height: 0;
    }

    .el-tabs__nav-wrap {
      .el-tabs__nav-scroll {
        .el-tabs__nav {
          height: 67px;
          line-height: 67px;

          .el-tabs__active-bar {
            background: #2F54EB;
            height: 36px;
            bottom: 15px;
            border-radius: 18px;
            width: 88px !important;
          }

          .el-tabs__item {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.6);
          }

          .el-tabs__item.is-active {
            margin-left: 16px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(255, 255, 255, 1);
            // transition :  all .3s;
            z-index: 3;
          }
        }
      }
    }
  }
}

>>> .mdf_content {
  width: 100%;
  margin-left: 99px;
  min-height: 400px;

  .mdf_c1 {
    margin-bottom: 20px;

    span {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(107, 120, 151, 1);
    }

    .mdf_input {
      display: inline-block;
      width: 28%;
      margin-left: 52px;

      .el-input__inner {
        height: 40px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(118, 180, 255, 1);
        border-radius: 4px;
      }
    }

    .mdf_input1 {
      margin-left: 20px;
    }
  }
}

.company-msg {
  margin: 30px;
  margin-top: 16px;
}

.msg-title {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 0px 0px rgba(238, 238, 238, 1);
}

>>> .bread-crumb-wrapper {
  margin-top: 0;
}
</style>
