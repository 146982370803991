<template>
  <section class="app-main">
    <transition
      name="fade"
      mode="out-in">
      <!-- <router-view :key="key"></router-view> -->
      <router-view />
    </transition>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
};
</script>
<style lang="stylus" scoped>
.app-main{
  height: 100%;
  padding-top: 60px;
}
</style>
