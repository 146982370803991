<template>
  <div class="wrapper">
    <div v-for="(grp,index) in node.children" :key="grp.id" :class="{'page-break-inside':index>0}">
      <div class="grp" :id="'node'+grp.id">
        <div class="grp-title" :class="{displayNone: grp.titleName==''}">
          <span class="red">{{grp.orderNumber}}. {{ grp.titleName }}</span>
        </div>
        <div v-for="(node,index) in grp.children" :key="node.id" class="node" :class="{'page-break-inside':index>0}">
          <!--发票-->
          <DropToggle v-if="node.type == 1" :id="'node'+node.id">
            <template v-slot:title>
              　{{node.orderNumber}}. {{ node.titleName }}　

              <WarningBadge :id="node.riskLevel"></WarningBadge>
            </template>
            <template v-slot:body>
              <InvoiceIndex :node="node"></InvoiceIndex>
            </template>
          </DropToggle>
          <!--/发票-->
          <!--税务-->
          <DropToggle v-if="node.type == 2" :id="'node'+node.id">
            <template v-slot:title>
              　{{node.orderNumber}}. {{ node.titleName }}　

              <WarningBadge :id="node.riskLevel"></WarningBadge>
            </template>
            <template v-slot:body>
              <RiskProblemList :node="node"></RiskProblemList>
            </template>
          </DropToggle>
          <!--/税务-->
          <!--财务-->
          <DropToggle v-if="node.type == 4" :id="'node'+node.id">
            <template v-slot:title>
              　{{node.orderNumber}}. {{ node.titleName }}　

              <WarningBadge :id="node.riskLevel"></WarningBadge>
            </template>
            <template v-slot:body>
              <TaxIndex :node="node"></TaxIndex>
            </template>
          </DropToggle>
          <!--/财务-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RiskProblemList from "@/pages/PdfResult/components/RiskProblemList";
import TaxIndex from "@/pages/PdfResult/components/TaxIndex/TaxIndex";
import InvoiceIndex from "./InvoiceIndex/InvoiceIndex";
import DropToggle from "./DropToggle";
import WarningBadge from "./compents/WarningBadge";
export default {
  components: {
    DropToggle,
    InvoiceIndex,
    WarningBadge,
    RiskProblemList,
    TaxIndex,
  },
  props: {
    node: Object,
  },
};
</script>

<style lang="stylus" scoped>
.wrapper{
  background #fff;

}

.grp {
  margin-bottom: 0;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  display block;


  .grp-title {
    font-size: 18px;
    padding: 16px 24px;
    font-weight: bold;
    color: #333;
    border-bottom: 1px solid rgb(217, 217, 217);
    border-radius: 8px 8px 0px 0px;
    background: #fff;
  }
  //.node{
  //  display block;
  //  page-break-inside:avoid;
  //}
}
</style>
