<template>
  <div>
    <div v-if="mode=='pdf'">
      <div class="chart-wrapper">
        <template
          v-for="(chart, index) in data.data.ruleOfInvoiceImageList"
        >
          <ThreeEchart
            :key="index"
            :data="chart"
            v-if="chart.imageType == 'histogram' || chart.imageType == 'twoHistogram'"
            class="chart"
            height="321px"
            :width="1020/data.data.ruleOfInvoiceImageList.length+'px'"
          ></ThreeEchart>
        </template>
      </div>
      <div class="table-wrapper">
        <BigPowerTable :data="data.data" class="table"></BigPowerTable>
      </div>
    </div>
    <div v-else>
      <div class="chart-wrapperFirst" v-if="data.name == '关键税负率'">
      <template v-for="(chart, index) in data.data.ruleOfInvoiceImageList">
        <ThreeEchart
          :key="index"
          :data="chart"
          v-if="chart.imageType == 'histogram' || chart.imageType == 'twoHistogram'"
          class="chart"
          height="321px"
          :width="1020 / data.data.ruleOfInvoiceImageList.length + 'px'"
        ></ThreeEchart>
      </template>
    </div>
    <div class="chart-wrapper" v-if="data.name == '关键财务比率'">
      <template v-for="(chart, index) in data.data.ruleOfInvoiceImageList">
        <ThreeEchart
          :key="index"
          :data="chart"
          v-if="chart.imageType == 'histogram' || chart.imageType == 'twoHistogram'"
          class="chart"
          height="321px"
          :width="1020 / data.data.ruleOfInvoiceImageList.length + 'px'"
        ></ThreeEchart>
      </template>
    </div>
      <div class="table-wrapper">
        <BigPowerTable :data="data.data" class="table"></BigPowerTable>
      </div>
    </div>
  </div>


</template>

<script>
import ThreeEchart from "@/pages/Result/ThreeYearResult/financialAnalysis/ThreeEchart";
import LineChart from "@/pages/Result/components/chats/LineChart";
import ColumnChart from "@/pages/Result/components/chats/ColumnChart";
// import BigPowerTable from "big-power-table"
import BigPowerTable from "@/components/bigPowerTable/BigPowerTable.vue";

export default {
  name: "KeyTaxRate",
  props: {
    data: Object,
    mode: String
  },
  components: {
    ThreeEchart,
    BigPowerTable
  },
  data() {
    return {}
  },
  methods: {},
  mounted(){
    console.log(this.data)
  },
}
</script>

<style lang="stylus" scoped>

.chart-wrapper
  display flex;
  flex-wrap: wrap;
.chart-wrapperFirst
  display flex;
  justify-content: space-around

//表格样式
.table {
  >>>table.table th {
    background: #F1F6FE;
    padding: 13px 12px;
    font-weight: bold;
  }
  >>>table.table td{
    padding: 13px 12px;
    font-size:14px;
    color: #1D2129;
    font-weight: 500;
  }
}
>>>.el-icon-warning:before {
  //content: "\e7bc";
}
</style>
